import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import {gettext as __} from "../../../../utils/Trans";

class ExpertList extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
          speaker,
          whitelabel
        } = this.props;

    let topics = _.get(speaker, ["topics", _.get(whitelabel, ["bid"])], _.get(speaker, ["topics", 0], _.get(speaker, ["topics"], [])));

    if (_.size(topics) === 0) {
      return null;
    }

    let topiclist = _.map(topics, function (item, i) {
      if (_.isArray(item)) {
        return _.map(item, (subitem, k) => {
          return subitem;
        });
      } else {
        return item;
      }
    });
    topiclist = _.uniq(topiclist); //prevent duplicates

    return (
      <div>
        <h5 className="header legend">{__("Expert On")}</h5>
        <ul>
          {_.map(topiclist, function (item, i) {
            return (
              <li key={i}>{item}</li>
            );
          })}
        </ul>
      </div>
    );
  }
}

ExpertList.propTypes = {
  speaker: PropTypes.object.isRequired,
  whitelabel: PropTypes.object.isRequired
};

export default ExpertList;