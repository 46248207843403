import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import SpeakerSummary from "../components/SpeakerSummary";
import CollaboratorSummary from "../components/CollaboratorSummary";
import { gettext as __ } from "../../../utils/Trans";
import { BureauPickMeButton, DismissSpeakerButton, MakeOfferButton } from "../components/Buttons";

class SpeakerStep extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            whitelabel,
            event,
            speaker,
            shortlist_speaker,
            event_has_an_accepted_offer,
            display_message,
            user
        } = this.props;

        return (
            <div>
                <div style={{
                    marginBottom: "30px",
                    paddingBottom: "30px",
                    borderBottom: "1px solid #EAEAEA"
                }}>
                    <SpeakerSummary
                        speaker={speaker}
                        speaker_has_an_accepted_offer={_.get(speaker, ["offer_accepted"])}
                        event_has_an_accepted_offer={event_has_an_accepted_offer}
                        display_message={display_message}
                        {...this.props}
                    />

                    <div className="row">
                        <div className="col-md-9 col-md-offset-3 col-lg-9 col-lg-offset-3 col-sm-9 col-sm-offset-3 col-xs-12">
                            <Link to={("/profile/" + _.get(speaker, ["sid"]))} className="hidden-lg hidden-md btn btn-default btn-flat btn-block-xs" target="_blank">
                                {__("View Profile")}
                            </Link>
                            {" "}
                            {speaker && event && user && !event_has_an_accepted_offer && !_.get(whitelabel, ["is_bureau_mode"], false) &&
                            <MakeOfferButton
                                speakername={_.get(speaker, ["name"])}
                                event={event}
                                speaker={speaker}
                                whitelabel={whitelabel}
                                user={user}
                            />
                            }
                            {" "}
                            {speaker && event && user && _.get(whitelabel, ["is_bureau_mode"], false) && <BureauPickMeButton speakername={_.get(speaker, ["name"])} event={event} speaker={speaker} {...this.props}  />}
                            {" "}
                            {speaker && event && user && shortlist_speaker && <DismissSpeakerButton speakername={_.get(speaker, ["name"])} shortlist_speaker={shortlist_speaker} event={event} speaker={speaker} user={user} {...this.props} />}
                        </div>
                    </div>

                    <hr />

                    <CollaboratorSummary
                        speaker={speaker}
                        {...this.props}
                    />
                </div>
            </div>
        );
    }
}

export default SpeakerStep;
