import _ from "lodash";
import convertBalboa3EventToJSEvent from "../../utils/convertBalboa3EventToJSEvent";
import Balboa from "./Balboa";

export default function(params){

    params = params || {};

    return function(dispatch, getState) {
        var key = ["BuyerEvents"];
        return Balboa(dispatch, getState).post(key, "/event/buyersevents", params, {cache_ttl: 30 * 60 * 1000})
            .then(function(response) {
                // Save the events to the state
                dispatch({
                    type: "BUYER_EVENT_LOAD",
                    data: _.mapValues(_.get(response, ["data"]), convertBalboa3EventToJSEvent) // convert events
                });
                return response;
            })
            .then(function(response) {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            });
    };
};
