import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Redirect, Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { sessionService } from "redux-react-session";
import toMpURL from "./utils/toMpURL";
import toShortlistURL from "./utils/toShortlistURL";
import Whitelabel from "./actions/Global/Whitelabel";
import Language from "./actions/Global/Language";
import Sitelists from "./actions/Global/Sitelists";
import Analytics from "./utils/Analytics";
import LoadingIndicator from "./components/LoadingIndicator";
import WhitelabelStyle from "./components/WhitelabelStyle";
import PollMessages from "./actions/Polling/PollMessages";
import PollNUnread from "./actions/Polling/PollNUnread";
import Constants from "./utils/Constants";
import LoadingBar from "react-redux-loading-bar";
import { loadLocale } from "./utils/Trans";
import "./App.scss";

// Site layouts
import CollabLayoutRoute from "./containers/layout/Routes/CollabLayoutRoute";
import ErrorLayoutRoute from "./containers/layout/Routes/ErrorLayoutRoute";
import HeadlessLayoutRoute from "./containers/layout/Routes/HeadlessLayoutRoute";
import SandboxedLayoutRoute from "./containers/layout/Routes/SandboxedLayoutRoute";
import StandardFullLayoutRoute from "./containers/layout/Routes/StandardFullLayoutRoute";
import StandardLayoutRoute from "./containers/layout/Routes/StandardLayoutRoute";

// Site pages
// import HomePage from "./containers/HomePage";
import AboutUsPage from "./containers/AboutUsPage";
import MyEventsPage from "./containers/MyEventsPage";
import ErrorPage from "./containers/ErrorPage";
import ShortlistPage from "./containers/ShortlistPage";
import PostAnAffiliateJobPage from "./containers/PostAnAffiliateJobPage";
import PostAJobPage from "./containers/PostAJobPage";
import CollabBillingPage from "./containers/CollabBillingPage";
import CollabShortlistPage from "./containers/CollabShortlistPage";
import CollabReferralPage from "./containers/CollabReferralPage";
import BookMeNowPage from "./containers/BookMeNowPage";
import PostAPublicJobPage from "./containers/PostAPublicJobPage";
import PostAPrivateJobPage from "./containers/PostAPrivateJobPage";
import PostAnExistingJobPage from "./containers/PostAnExistingJobPage";
import ProfilePage from "./containers/ProfilePage";
import SearchLocalPage from "./containers/SearchLocalPage";
import SearchPage from "./containers/SearchPage";
import SearchVirtualPage from "./containers/SearchVirtualPage";
import MessagesPage from "./containers/MessagesPage";
import UserPage from "./containers/UserPage";
import SignInPage from "./containers/SignInPage";
import OauthCallbackPage from "./containers/OauthCallbackPage";
import TokenCallbackPage from "./containers/TokenCallbackPage";
import SignUpPage from "./containers/SignUpPage";
import TopicsPage from "./containers/TopicsPage";
import ForgotPasswordPage from "./containers/ForgotPasswordPage";
import ResetPasswordPage from "./containers/ResetPasswordPage";
import SignInMpiPage from "./containers/SignInMpiPage";
import SpeakerDirectMessagePage from "./containers/SpeakerDirectMessagePage";
import IndustryPage from "./containers/IndustryPage";
import VerifyEmailPage from "./containers/VerifyEmailPage";
import SearchTrendingPage from "./containers/SearchTrendingPage";
import SearchShowcasePage from "./containers/SearchShowcasePage";
import SearchResumePage from "./containers/SearchResumePage";
import SearchNearbyPage from "./containers/SearchNearbyPage";
import MlkPage from "./containers/MlkPage";
import VideoPlayerPage from "./containers/VideoPlayerPage";

export class App extends React.Component {
    onLogout = () => {
        return sessionService
            .deleteUser()
            .then(() => {
                return sessionService.deleteSession();
            })
            .then(() => {
                this.props.history.push("/signin");
            });
    };

    _pollNUnread = () => {
        let is_logged_in =
            _.get(this.props.user, ["role"], "guest") !== "guest";
        if (is_logged_in) {
            var self = this;
            self.props.checkForNewNotifications().then(response => {
                self.nunread_timer = _.delay(self._pollNUnread, 300000);
                return response;
            });
        }
    }; // throttle messages to keep it from stacking up too much

    _pollMessages = () => {
        let is_logged_in =
            _.get(this.props.user, ["role"], "guest") !== "guest";
        let bid = _.get(this.props.whitelabel, ["bid"], false);
        let self = this;
        if (is_logged_in && bid) {
            self.props.checkForNewMessages(bid).then(response => {
                self.msg_timer = _.delay(self._pollMessages, 60000);
                return response;
            });
        } else {
            // we aren't loaded yet, so delay for 5 more seconds
            self.msg_timer = _.delay(self._pollMessages, 5000);
        }
    }; // throttle messages to keep it from stacking up too much
    //
    // _pollData = () => {
    //     let is_logged_in = (_.get(this.props.user, ["role"], "guest") !== "guest");
    //     if (is_logged_in) {
    //         var self = this;
    //         self.props.checkForNewAgreements()
    //             .then((response) => {
    //                 self.agreement_timer = _.delay(self._pollData, 600000);
    //                 return response;
    //             });
    //     }
    // } // throttle messages to keep it from stacking up too much

    componentDidMount() {
        // Load the whitelabel from Balboa
        if (!this.props.whitelabel) {
            this.props.loadWhitelabel().then(resp => {
                Analytics.initialize(_.get(resp, ["data"]));
            });
        }
        if (!this.props.sitelists) {
            this.props.loadSitelists();
        }
        if (!this.props.language) {
            this.props.loadLanguage();
        }
        let is_logged_in =
            _.get(this.props.user, ["role"], "guest") !== "guest";
        let is_manager =
            _.get(this.props.user, ["role"], "guest") === "publisher";
        if (is_logged_in && !is_manager) {
            var self = this;
            self.agreement_timer = _.delay(function () {
                // self._pollData();
                self._pollMessages();
                // self._pollNUnread();
            }, 5000); // events are loaded initially, so no need to poll more data for a few minutes
        }
    }

    componentWillUnmount = () => {
        if (!_.isUndefined(this.agreement_timer) && window) {
            window.clearInterval(this.agreement_timer);
        }
        if (!_.isUndefined(this.msg_timer) && window) {
            window.clearInterval(this.msg_timer);
        }
        if (!_.isUndefined(this.nunread_timer) && window) {
            window.clearInterval(this.nunread_timer);
        }
    };

    render() {
        let { whitelabel, sitelists, user, authenticated } = this.props;

        let is_espeakers =
            whitelabel && _.get(whitelabel, ["is_espeakers"], false);
        let is_logged_in =
            user && _.get(user, ["role"], "guest") !== "guest" && authenticated;

        let signin_redirect = (
            <Redirect
                to={{
                    pathname: "/signin",
                    state: _.get(this.props.location, ["state"])
                }}
            />
        );
        let signedin_redirect = <Redirect to={{ pathname: "/event" }} />;

        let logPageView = () => {
            Analytics.logPageView();
            return null;
        };

        if (!whitelabel || !sitelists) {
            return (
                <div>
                    <LoadingBar style={{ zIndex: 100000 }} />
                    <LoadingIndicator />
                </div>
            );
        }

        // Redirect to the whitelabel domain if we have one
        if (true) {
            // quick flag to disable this if it gets wonky
            if (
                whitelabel &&
                _.get(whitelabel, ["whitelabel_domain"]) &&
                _.get(whitelabel, ["whitelabel_domain"]) !== "" &&
                _.toInteger(
                    _.get(whitelabel, ["bid"], Constants.TRUNK_BUREAU_ID)
                ) !== Constants.TRUNK_BUREAU_ID
            ) {
                let hostname = window.location.hostname;
                if (_.includes(_.get(whitelabel, ["whitelabel_domain"]), ":")) {
                    // custom domain contains a port, so include that in the check
                    hostname += ":" + window.location.port;
                }
                if (_.get(whitelabel, ["whitelabel_domain"]) !== hostname) {
                    window.location.href = toMpURL(
                        window.location.pathname,
                        whitelabel,
                        true,
                        true
                    );
                    return null;
                }
            }
        }

        try {
            return (
                <BrowserRouter
                    basename={toShortlistURL(
                        "/" + (this.props.lang === "en" ? "" : this.props.lang),
                        whitelabel,
                        false,
                        false
                    )}>
                    <div>
                        <Route component={logPageView} />
                        <LoadingBar style={{ zIndex: 100000 }} />
                        <WhitelabelStyle whitelabel={whitelabel} />
                        <Switch>
                            {/* These pages can only be accessed if the user is NOT logged in */}
                            <StandardLayoutRoute
                                {...this.props}
                                path="/signin"
                                render={props =>
                                    !is_logged_in ? (
                                        <SignInPage
                                            {...this.props}
                                            {...props}
                                        />
                                    ) : (
                                        signedin_redirect
                                    )
                                }
                            />
                            <StandardLayoutRoute
                                {...this.props}
                                path="/mpi-signin"
                                render={props =>
                                    !is_logged_in &&
                                    _.get(whitelabel, ["is_mpi"], false) ? (
                                        <SignInMpiPage
                                            {...this.props}
                                            {...props}
                                        />
                                    ) : (
                                        signedin_redirect
                                    )
                                }
                            />
                            <StandardLayoutRoute
                                {...this.props}
                                path="/signup"
                                render={props =>
                                    !is_logged_in ? (
                                        <SignUpPage
                                            {...this.props}
                                            {...props}
                                        />
                                    ) : (
                                        signedin_redirect
                                    )
                                }
                            />
                            <StandardLayoutRoute
                                {...this.props}
                                path="/forgot-password"
                                render={props =>
                                    !is_logged_in ? (
                                        <ForgotPasswordPage
                                            {...this.props}
                                            {...props}
                                        />
                                    ) : (
                                        signedin_redirect
                                    )
                                }
                            />
                            <HeadlessLayoutRoute
                                {...this.props}
                                path="/oauth-callback/:service_id"
                                render={props => (
                                    <OauthCallbackPage
                                        {...this.props}
                                        {...props}
                                    />
                                )}
                            />
                            <HeadlessLayoutRoute
                                {...this.props}
                                path="/token-callback"
                                render={props => (
                                    <TokenCallbackPage
                                        {...this.props}
                                        {...props}
                                    />
                                )}
                            />

                            {/* These pages can only be accessed if the user is logged in */}
                            <StandardLayoutRoute
                                {...this.props}
                                path="/event"
                                render={props =>
                                    is_logged_in ? (
                                        <MyEventsPage
                                            {...this.props}
                                            {...props}
                                        />
                                    ) : (
                                        signin_redirect
                                    )
                                }
                            />
                            <StandardLayoutRoute
                                {...this.props}
                                path="/user"
                                render={props =>
                                    is_logged_in ? (
                                        <UserPage {...this.props} {...props} />
                                    ) : (
                                        signin_redirect
                                    )
                                }
                            />
                            <StandardLayoutRoute
                                {...this.props}
                                path="/reset-password"
                                render={props =>
                                    is_logged_in ? (
                                        <ResetPasswordPage
                                            {...this.props}
                                            {...props}
                                        />
                                    ) : (
                                        <ForgotPasswordPage
                                            {...this.props}
                                            {...props}
                                        />
                                    )
                                }
                            />
                            <HeadlessLayoutRoute
                                {...this.props}
                                path="/verify-email"
                                render={props => (
                                    <VerifyEmailPage
                                        {...this.props}
                                        {...props}
                                    />
                                )}
                            />
                            <StandardLayoutRoute
                                {...this.props}
                                path="/postanexistingjob"
                                render={props =>
                                    is_logged_in ? (
                                        <PostAnExistingJobPage
                                            {...this.props}
                                            {...props}
                                        />
                                    ) : (
                                        <Redirect
                                            to={{
                                                pathname: "/signup",
                                                state: {
                                                    onboard: "postajob",
                                                    redirect: "/postajob"
                                                }
                                            }}
                                        />
                                    )
                                }
                            />
                            <StandardLayoutRoute
                                {...this.props}
                                path="/postajob/:onboard?"
                                render={props =>
                                    is_logged_in ? (
                                        <PostAJobPage
                                            {...this.props}
                                            {...props}
                                        />
                                    ) : (
                                        <Redirect
                                            to={{
                                                pathname: "/signup",
                                                state: {
                                                    onboard: _.get(
                                                        props.match,
                                                        ["params", "onboard"],
                                                        "postajob"
                                                    ),
                                                    redirect: "/postajob"
                                                }
                                            }}
                                        />
                                    )
                                }
                            />
                            <StandardLayoutRoute
                                {...this.props}
                                path="/shortlist/:eid/:sid?"
                                render={props =>
                                    is_logged_in ? (
                                        <ShortlistPage
                                            {...this.props}
                                            {...props}
                                        />
                                    ) : (
                                        signin_redirect
                                    )
                                }
                            />
                            <StandardLayoutRoute
                                {...this.props}
                                path="/messages/:eid?/:sid?"
                                render={props => (
                                    <MessagesPage {...this.props} {...props} />
                                )}
                            />
                            <StandardLayoutRoute
                                {...this.props}
                                path="/messages"
                                render={props => (
                                    <MessagesPage {...this.props} {...props} />
                                )}
                            />

                            {/* These pages can be accessed whether the user is logged in or not */}
                            <StandardLayoutRoute
                                {...this.props}
                                path="/postapublicjob"
                                render={props => (
                                    <PostAPublicJobPage
                                        {...this.props}
                                        {...props}
                                    />
                                )}
                            />
                            <StandardLayoutRoute
                                {...this.props}
                                path="/postaprivatejob"
                                render={props => (
                                    <PostAPrivateJobPage
                                        {...this.props}
                                        {...props}
                                    />
                                )}
                            />

                            {/* These pages can be accessed by a collaborator */}
                            <CollabLayoutRoute
                                {...this.props}
                                path="/collab/collab-billing/:eid"
                                render={props => (
                                    <CollabBillingPage
                                        {...this.props}
                                        {...props}
                                    />
                                )}
                            />
                            <CollabLayoutRoute
                                {...this.props}
                                path="/collab/collab-shortlist/:id"
                                render={props => (
                                    <CollabShortlistPage
                                        {...this.props}
                                        {...props}
                                    />
                                )}
                            />
                            <CollabLayoutRoute
                                {...this.props}
                                path="/collab/collab-referral/:id"
                                render={props => (
                                    <CollabReferralPage
                                        {...this.props}
                                        {...props}
                                    />
                                )}
                            />
                            <CollabLayoutRoute
                                {...this.props}
                                path="/collab/affiliate-paj/:id"
                                render={props => (
                                    <PostAnAffiliateJobPage
                                        {...this.props}
                                        {...props}
                                    />
                                )}
                            />
                            <CollabLayoutRoute
                                {...this.props}
                                path="/collab/postajob/:onboard?"
                                render={props =>
                                    is_logged_in ? (
                                        <PostAJobPage
                                            {...this.props}
                                            {...props}
                                        />
                                    ) : (
                                        <Redirect
                                            to={{
                                                pathname: "/collab/signup",
                                                state: _.assign(
                                                    {},
                                                    _.get(props.location, [
                                                        "state"
                                                    ]),
                                                    {
                                                        onboard: "affiliate",
                                                        redirect:
                                                            "/collab/postajob"
                                                    }
                                                )
                                            }}
                                        />
                                    )
                                }
                            />
                            <CollabLayoutRoute
                                {...this.props}
                                path="/collab/signin"
                                use_panel={false}
                                render={props =>
                                    !is_logged_in ? (
                                        <SignInPage
                                            {...this.props}
                                            {...props}
                                        />
                                    ) : (
                                        signedin_redirect
                                    )
                                }
                            />
                            <CollabLayoutRoute
                                {...this.props}
                                path="/collab/signup"
                                render={props =>
                                    !is_logged_in ? (
                                        <SignUpPage
                                            {...this.props}
                                            {...props}
                                        />
                                    ) : (
                                        signedin_redirect
                                    )
                                }
                            />
                            <CollabLayoutRoute
                                {...this.props}
                                path="/collab/forgot-password"
                                render={props =>
                                    !is_logged_in ? (
                                        <ForgotPasswordPage
                                            {...this.props}
                                            {...props}
                                        />
                                    ) : (
                                        signedin_redirect
                                    )
                                }
                            />

                            <ErrorLayoutRoute
                                {...this.props}
                                path="/error"
                                render={props => (
                                    <ErrorPage {...this.props} {...props} />
                                )}
                            />

                            {/* These are legacy urls that we can remove at a future version */}

                            {/*<Route path="/v3/shortlist/:eid/:sid" render={({match}) => (<Redirect to={`/shortlist/${match.params.eid}/${match.params.sid}`}/>)}/>*/}
                            {/*<Route path="/v3/shortlist/:eid" render={({match}) => (<Redirect to={`/shortlist/${match.params.eid}`}/>)}/>*/}
                            {/*<Route path="/v3/manage/:sub_view?" render={({match}) => (<Redirect to={`/manage/${match.params.sub_view}`}/>)}/>*/}
                            {/*<Route path="/v3/admin/:sub_view?" render={({match}) => (<Redirect to={`/admin/${match.params.sub_view}`}/>)}/>*/}
                            {/*<Route path="/v3/oauth-callback/:service_id" render={({match}) => (<Redirect to={`/oauth-callback/${match.params.service_id}`}/>)}/>*/}
                            {/*<Route path="/v3/profile/:sid/simple" render={({match}) => (<Redirect to={`/profile/${match.params.sid}/simple`}/>)}/>*/}
                            {/*<Route path="/v3/profile/:sid/bookmenow" render={({match}) => (<Redirect to={`/profile/${match.params.sid}/bookmenow`}/>)}/>*/}
                            {/*<Route path="/v3/profile/:sid/:name" render={({match}) => (<Redirect to={`/profile/${match.params.sid}/${match.params.name}`}/>)}/>*/}
                            {/*<Route path="/v3/profile/:sid" render={({match}) => (<Redirect to={`/profile/${match.params.sid}`}/>)}/>*/}
                            {/*<Route path="/v3/direct_message/:sid" render={({match}) => (<Redirect to={`/direct_message/${match.params.sid}`}/>)}/>*/}
                            {/*<Route path="/v3/bookmenow/:sid/:token?" render={({match}) => (<Redirect to={`/bookmenow/${match.params.sid}/${match.params.token}`}/>)}/>*/}
                            {/*<Route path="/v3/collab/collab-billing/:eid" render={({match}) => (<Redirect to={`/collab/collab-billing/${match.params.eid}`}/>)}/>*/}
                            {/*<Route path="/v3/collab/collab-shortlist/:id" render={({match}) => (<Redirect to={`/collab/collab-billing/${match.params.id}`}/>)}/>*/}
                            {/*<Route path="/v3/search" render={(props) => (<Redirect to={`/search${props.location.search}`}/>)}/>*/}
                            {/*<Route path="/v3/:url" render={({match}) => (<Redirect to={`${match.params.url}`}/>)}/>*/}

                            {/* These routes have been moved to marketplace-next and should only be used for whitelabels */}
                            {!is_espeakers && (
                                <Switch>
                                    <StandardFullLayoutRoute
                                        {...this.props}
                                        path="/search"
                                        render={props => {
                                            return (
                                                <SearchPage
                                                    {...this.props}
                                                    {...props}
                                                />
                                            );
                                        }}
                                    />
                                    <StandardFullLayoutRoute
                                        {...this.props}
                                        path="/search/scroll-to-sid/:sid"
                                        render={props => (
                                            <SearchPage
                                                {...this.props}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <StandardFullLayoutRoute
                                        {...this.props}
                                        path="/search/setup/:last_search"
                                        render={props => (
                                            <SearchPage
                                                {...this.props}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <StandardLayoutRoute
                                        {...this.props}
                                        exact={true}
                                        path="/speaker/profile/:sid"
                                        render={props => (
                                            <ProfilePage
                                                {...this.props}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <StandardLayoutRoute
                                        {...this.props}
                                        exact={true}
                                        path="/speaker/profile/:sid/:name?"
                                        render={props => (
                                            <ProfilePage
                                                {...this.props}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <StandardLayoutRoute
                                        {...this.props}
                                        exact={true}
                                        path="/profile/:sid"
                                        render={props => (
                                            <ProfilePage
                                                {...this.props}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <StandardLayoutRoute
                                        {...this.props}
                                        exact={true}
                                        path="/profile/:sid/:name?"
                                        render={props => (
                                            <ProfilePage
                                                {...this.props}
                                                {...props}
                                            />
                                        )}
                                    />
                                    {/* <StandardFullLayoutRoute {...this.props} path="/search/:location" render={(props) => {return (<SearchPage {...this.props} {...props} />);}}/> */}
                                    <SandboxedLayoutRoute
                                        {...this.props}
                                        exact={true}
                                        path="/profile/:sid/preview"
                                        render={props => (
                                            <ProfilePage
                                                {...this.props}
                                                {...props}
                                                is_preview_mode={true}
                                            />
                                        )}
                                    />
                                    <SandboxedLayoutRoute
                                        {...this.props}
                                        exact={true}
                                        path="/profile/:sid/simple"
                                        render={props => (
                                            <ProfilePage
                                                {...this.props}
                                                {...props}
                                                simple_site_mode={true}
                                            />
                                        )}
                                    />
                                    <SandboxedLayoutRoute
                                        {...this.props}
                                        path="/profile/:sid/bookmenow"
                                        render={props => (
                                            <BookMeNowPage
                                                {...this.props}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <SandboxedLayoutRoute
                                        {...this.props}
                                        exact={false}
                                        path="/profile/:sid/bookmenow_full_profile"
                                        render={props => (
                                            <ProfilePage
                                                {...this.props}
                                                {...props}
                                                book_me_now_mode={true}
                                            />
                                        )}
                                    />
                                    <SandboxedLayoutRoute
                                        {...this.props}
                                        exact={true}
                                        path="/profile/:sid/headless"
                                        render={props => (
                                            <ProfilePage
                                                {...this.props}
                                                {...props}
                                                headless_mode={true}
                                            />
                                        )}
                                    />
                                    <StandardLayoutRoute
                                        {...this.props}
                                        path="/direct_message/:sid/"
                                        render={props => (
                                            <SpeakerDirectMessagePage
                                                {...this.props}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <SandboxedLayoutRoute
                                        {...this.props}
                                        path="/bookmenow/:sid/:token?"
                                        render={props => (
                                            <BookMeNowPage
                                                {...this.props}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <Route
                                        {...this.props}
                                        path="/index/videoplayer"
                                        render={props => (
                                            <VideoPlayerPage
                                                {...this.props}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <StandardFullLayoutRoute
                                        {...this.props}
                                        path="/localsearch/:location"
                                        render={props => (
                                            <SearchLocalPage
                                                {...this.props}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <StandardFullLayoutRoute
                                        {...this.props}
                                        path="/search-resume"
                                        render={props => {
                                            return (
                                                <SearchResumePage
                                                    {...this.props}
                                                    {...props}
                                                />
                                            );
                                        }}
                                    />
                                    <StandardFullLayoutRoute
                                        {...this.props}
                                        path="/search-nearby"
                                        render={props => {
                                            return (
                                                <SearchNearbyPage
                                                    {...this.props}
                                                    {...props}
                                                />
                                            );
                                        }}
                                    />
                                    <StandardFullLayoutRoute
                                        {...this.props}
                                        path="/search-virtual"
                                        render={props => {
                                            return (
                                                <SearchVirtualPage
                                                    {...this.props}
                                                    {...props}
                                                />
                                            );
                                        }}
                                    />
                                    <StandardFullLayoutRoute
                                        {...this.props}
                                        path="/topics"
                                        render={props => (
                                            <TopicsPage
                                                {...this.props}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <StandardFullLayoutRoute
                                        {...this.props}
                                        path="/expertise"
                                        render={props => (
                                            <IndustryPage
                                                {...this.props}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <StandardFullLayoutRoute
                                        {...this.props}
                                        path="/trending-speakers"
                                        render={props => (
                                            <SearchTrendingPage
                                                {...this.props}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <StandardFullLayoutRoute
                                        {...this.props}
                                        path="/speaker-showcase"
                                        render={props => (
                                            <SearchShowcasePage
                                                {...this.props}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <StandardLayoutRoute
                                        {...this.props}
                                        path="/about"
                                        render={props => (
                                            <AboutUsPage
                                                {...this.props}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <StandardLayoutRoute
                                        {...this.props}
                                        path="/ihaveadream"
                                        render={props => (
                                            <MlkPage
                                                {...this.props}
                                                {...props}
                                            />
                                        )}
                                    />
                                    <StandardFullLayoutRoute
                                        {...this.props}
                                        render={props => (
                                            <SearchPage
                                                {..._.assign(
                                                    {},
                                                    this.props,
                                                    props
                                                )}
                                            />
                                        )}
                                    />
                                </Switch>
                            )}
                            {/* Default route when none can be found */}
                            {is_espeakers && (
                                <StandardLayoutRoute
                                    {...this.props}
                                    render={props => (
                                        <SignInPage
                                            {...this.props}
                                            {...props}
                                        />
                                    )}
                                />
                            )}
                        </Switch>
                    </div>
                </BrowserRouter>
            );
        } catch (err) {
            if (_.get(err, ["name"]) === "AuthorizationException") {
                return (
                    <BrowserRouter
                        basename={toShortlistURL(
                            "/" +
                                (this.props.lang === "en"
                                    ? ""
                                    : this.props.lang),
                            whitelabel,
                            false,
                            false
                        )}>
                        <StandardLayoutRoute
                            {...this.props}
                            render={props => (
                                <ErrorPage {...this.props} {...props} />
                            )}
                        />
                    </BrowserRouter>
                );
            } else {
                throw err;
            }
        }
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

const mapStateToProps = function (state, ownProps) {
    let whitelabel = _.get(state, ["Whitelabel", "whitelabel"]);
    let sitelists = _.get(state, ["Whitelabel", "sitelists"]);
    let language = _.get(state, [
        "Whitelabel",
        "language",
        _.get(ownProps.match, ["params", "lang"], "en")
    ]);
    let user = _.get(state, ["sessionReducer", "user"]);
    let authenticated = _.get(
        state,
        ["sessionReducer", "authenticated"],
        false
    );
    let errors = _.get(state, ["App", "errors"], []);

    return {
        errors: errors,
        whitelabel: whitelabel,
        user: user,
        sitelists: sitelists,
        authenticated: authenticated,
        language: language
    };
};

const mapDispatchToProps = function (dispatch, ownProps) {
    return {
        loadWhitelabel: function () {
            return dispatch(
                Whitelabel.get(
                    _.get(
                        ownProps.match,
                        ["params", "bcode"],
                        _.get(
                            window.location,
                            ["hostname"],
                            Constants.TRUNK_BUREAU_ID
                        )
                    )
                )
            );
        },
        loadSitelists: function () {
            return dispatch(
                Sitelists({
                    bcode: _.get(
                        ownProps.match,
                        ["params", "bcode"],
                        _.get(
                            window.location,
                            ["hostname"],
                            Constants.TRUNK_BUREAU_ID
                        )
                    )
                })
            );
        },
        loadLanguage: function (language) {
            return dispatch(
                Language({
                    bcode: _.get(
                        ownProps.match,
                        ["params", "bcode"],
                        _.get(
                            window.location,
                            ["hostname"],
                            Constants.TRUNK_BUREAU_ID
                        )
                    ),
                    lang: ownProps.lang
                })
            ).then(locale => {
                loadLocale(locale);
            });
        },
        checkForNewNotifications: function () {
            return dispatch(PollNUnread());
        },
        checkForNewMessages: function (bid) {
            return dispatch(PollMessages(bid));
        }
        // checkForNewAgreements: function () {
        //     return dispatch(PollAgreements());
        // }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
