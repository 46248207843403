import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { change, formValueSelector } from "redux-form";
import { Collapse, Fade } from "react-bootstrap";
import { gettext as __ } from "../../utils/Trans";
import Speaker from "../../actions/Speaker/Speaker";
import AffiliateEvent from "../../actions/Affiliate/AffiliateEvent";
import EmbeddedShortlist_remove from "../../actions/ShortList/EmbeddedShortlist_remove";
import CustomerService from "../../components/CustomerService";
import InfoBox from "../../components/InfoBox";
import PostAffiliateJobForm from "./components/PostAffiliateJobForm";
import SpeakerAvailabilitySection from "./components/SpeakerAvailabilitySection";
import LoadingIndicator from "../../components/LoadingIndicator";
import CookiePostAJob from "../../utils/CookiePostAJob";
import moment from "moment";
import "./PostAnAffiliateJobPage.scss";


class PostAnAffiliateJobPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            step: (_.get(this.props, ["speaker_availability_searched"], false) ? 1 : 0),
            speaker_availability_searched: _.get(this.props, ["speaker_availability_searched"], false),
            selected_sids: _.get(this.props, "shortlist_sids", [])
        };
    }

    componentDidMount() {
        if (_.has(this.props.match, ["params", "id"])) {
            this.props.loadReferral(_.get(this.props.match, ["params", "id"]))
                .then((event) => {
                    if (_.get(event, ["startdate"])) {
                        return this.props.onChangeDate(_.get(event, ["startdate"]));
                    }
                });
        }
        window.scrollTo(0, 0);
    }

    __onChangeDate = (date) => {
        return this.props.onChangeDate(date);
    }

    __onChangeShortlist = (speaker) => {
        let selected_sids = _.compact(this.props.selected_sids);
        if (_.includes(selected_sids, _.get(speaker, ["sid"]))) {
            selected_sids = _.without(selected_sids, _.get(speaker, ["sid"]));
        } else {
            selected_sids = _.concat(selected_sids, [_.get(speaker, ["sid"])]);
        }
        return this.props.onChangeShortlist(selected_sids);
    }

    __onDateSearched = (params) => {
        this.props.onChangeDate(_.get(params, ["startdate"]));
        this.props.onChangeShortlist(_.get(params, ["shortlist_sids"]));

        this.setState({
            speaker_availability_searched: true,
            step: 1
        });
    }

    __onShowStep = () => {
        // let el = document.getElementById("step" + this.state.step);
        let el = document.getElementById("speaker_availability_table");
        if (el) {
            el.scrollIntoView({block: "start", inline: "nearest", behavior: "smooth"});
        }
    }

    render() {
        let {
            is_loading,
            shortlist,
            shortlist_sids,
            selected_sids,
            startdate,
            whitelabel,
            speakers,
            sitename,
            tiptexts,
            affiliate_event,
            onRemoveSpeakerFromShortlist
        } = this.props;

        let name = _.get(affiliate_event, ["organization"], "Shared Event");
        let session = _.get(affiliate_event, ["meeting_name"], "");

        if (is_loading) {
            return (<LoadingIndicator/>);
        }

        return (
            <div className="paj-affiliate-page">
                <div className="hidden-xs hidden-sm" style={{marginTop: "10px"}}>
                    <h3 className="text-muted">{__("Request Free Information")}</h3>
                    <h1>
                        {name} <span style={{fontSize: "0.5em"}}>{session}</span>
                    </h1>
                </div>
                <div className="visible-xs visible-sm">
                    <h3 className="text-muted">{__("Request Free Information")}</h3>
                    <h1>{name}<br/><span style={{fontSize: "0.5em", display: "block"}}>{session}</span></h1>
                </div>
                <hr/>
                <div className="row">
                    <div className="col-md-3 hidden-sm hidden-xs">
                        <div className="hidden-bureaumode">
                            <strong>{__("This is easy!")}</strong>
                            {" "}
                            {(_.size(shortlist_sids) > 1)
                                ? __("These presenters will see your job information and will let you know if they are available and how they can help. We'll send you an email with their responses.")
                                : __("This presenter will see your job information and will let you know if they are available and how they can help. We'll send you an email with their response.")
                            }
                            <p style={{marginTop: "2em"}}><strong>{__("There is never an obligation.")}</strong></p>
                            <CustomerService whitelabel={whitelabel}/>
                            <InfoBox path="\mp\postajob\left" tiptexts={tiptexts} sitename={sitename} whitelabel={whitelabel} startexpanded={true}/>
                        </div>
                    </div>


                    <div className="col-sm-12 col-md-8 col-md-offset-1">
                        {false && affiliate_event && <Collapse in={true} appear={true}>
                            <div className="alert alert-warning">{__("We filled in some of the event details from information provided by one of your collaborators.")}</div>
                        </Collapse>}
                        <div>
                            {(_.size(shortlist_sids) > 0) &&
                            <div>
                                <SpeakerAvailabilitySection
                                    date_range={startdate}
                                    speakers={speakers}
                                    shortlist_sids={shortlist_sids}
                                    selected_sids={selected_sids}
                                    onSearchSuccess={this.__onDateSearched}
                                    onChangeDate={this.__onChangeDate}
                                    onChangeShortlist={this.__onChangeShortlist}
                                    onRemove={onRemoveSpeakerFromShortlist}
                                />
                            </div>
                            }

                            <Fade in={(this.state.step === 1)} onEntered={this.__onShowStep}>
                                <div className="row">
                                    <div className="col-md-12">
                                        {affiliate_event && shortlist &&
                                        <PostAffiliateJobForm {...this.props} shortlist_sids={selected_sids} stepnumberstart={1}/>
                                        }
                                    </div>
                                </div>
                            </Fade>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {

    let id = _.get(ownProps.match, ["params", "id"]);

    let pending_job = CookiePostAJob.get();
    let date_range = _.get(pending_job, ["date_range"]);

    // By default, the postanaffiliatejob form will have it's startdate
    // set to the date range of the pending job
    let selector = formValueSelector("postanaffiliatejob");
    let selected_sids = selector(state, "shortlist_sids");
    let startdate = selector(state, "startdate");

    let affiliate_event = _.get(state, ["AffiliateEvent", _.toNumber(id)]);
    let shortlist = _.get(affiliate_event, ["shortlist"]);

    let speakers = _.get(state, ["Speakers"]);

    let shortlist_sids = _.get(state, ["EmbeddedShortlist", "sids"]);

    return {
        is_loading: (_.isUndefined(shortlist) || _.isUndefined(affiliate_event)),
        // event objects
        shortlist: shortlist,
        shortlist_sids: shortlist_sids,
        selected_sids: selected_sids,
        startdate: (startdate || date_range),
        date_range: date_range,
        affiliate_event: affiliate_event,
        speakers: speakers,
        speaker_availability_searched: _.get(pending_job, ["date_searched"], false)
    };
};

var mapDispatchToProps = function (dispatch, ownProps) {
    return {
        onChangeDate: function (date) {
            let startdate, enddate;
            if (_.isString(date)) {
                startdate = moment(_.trim(_.head(_.split(date, "-"))), "MM/DD/YYYY");
                enddate = moment(_.trim(_.last(_.split(date, "-"))), "MM/DD/YYYY");
            } else if (_.isArray(date)) {
                startdate = moment().isMoment(_.head(date)) ? _.head(date) : moment(_.head(date), "MM/DD/YYYY");
                enddate = moment().isMoment(_.last(date)) ? _.last(date) : moment(_.last(date), "MM/DD/YYYY");
            }
            let value = _.join([startdate.format("M/D/Y"), enddate.format("M/D/Y")], " - ");
            if (startdate.isSame(enddate)) {
                value = startdate.format("M/D/Y");
            }

            dispatch(change("paj_affiliate_speaker_availability_form", "startdate", value))
            return dispatch(change("postanaffiliatejob", "startdate", value));
        },
        onChangeShortlist: function (sids) {
            dispatch(change("paj_affiliate_speaker_availability_form", "shortlist_sids", sids))
            dispatch(change("postanaffiliatejob", "shortlist_sids", sids));
        },
        onChangeDateSearched: function () {
            return dispatch(change("postanaffiliatejob", "date_searched", true));
        },
        loadReferral: function (id) {
            return dispatch(AffiliateEvent.get(id))
                .then((data) => {
                    let shortlist = _.get(data, ["data", "shortlist"]);

                    let active_speakers = _.filter(_.get(shortlist, ["speakers"]), function (speaker) {
                        return _.get(speaker, ["active"], 0) > 0;
                    });
                    let sids = _.map(active_speakers, function (spkr) {
                        return _.get(spkr, ["speaker_id"]);
                    });
                    if (!_.isEmpty(sids)) {
                        dispatch({
                            type: "EMBEDDED_SHORTLIST_ADD_ALL",
                            sids: sids
                        });
                        dispatch(Speaker.find({
                            ids: sids,
                            nocache: false
                        }));
                    }
                    return _.get(data, ["data"]);
                });
        },
        onRemoveSpeakerFromShortlist: function (sid, new_sids) {
            return new Promise((resolve) => {
                return resolve(dispatch(EmbeddedShortlist_remove(sid)));
            }).then((resp) => {
                return dispatch(change("postanaffiliatejob", "shortlist_sids", new_sids));
            });
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostAnAffiliateJobPage));

