import {gettext as __} from "../../utils/Trans";
import Balboa from "../Global/Balboa";

export default function(params) {
    return (dispatch, getState) => {
        var key = ["bureaumodePickMe", params.event_id, params.speaker_id];
        return Balboa(dispatch, getState)
            .post(key, "/mpagreement/bureaumodepickme", params, {cache_ttl: -1})
            .then(function(response) {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            })
            .then(function (response) {
                dispatch({
                    type: "NOTIFICATION_CREATE",
                    message: __("Great! Expect to hear from someone shortly to complete the process.")
                });
                return response;
            });
    };

};
