import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";

class About extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            profile_language = "en",
            speaker,
            topic_id
        } = this.props;

        let topic_programs;
        let speakername = _.get(speaker, ["name"]);

        let primary_bio = _.first(_.filter(_.get(speaker, ["bios"], []), function(item) {
            return (
                (_.get(item, ["lang"], "en") === profile_language) &&
                (_.get(item, ["contents"], "") !== "") &&
                (topic_id < 0 || (topic_id > 0 && _.get(item, ["topic"]) === topic_id))
            );
        }));
        if (!primary_bio) {
            primary_bio = _.first(_.filter(_.get(speaker, ["bios"], []), function(item) {
                return (
                    (_.isNull(_.get(item, ["lang"])) || _.get(item, ["lang"], "en") === "en") &&
                    (_.get(item, ["contents"], "") !== "")
                );
            }));
        }

        let programs = _.orderBy(_.filter(_.get(speaker, ["programs"]), function(item) {
            return (
                (_.isNull(_.get(item, ["lang"])) || _.get(item, ["lang"], "en") === profile_language) &&
                (_.get(item, ["description"], "") !== "")
            );
        }), ['vieworder', 'id'], ['desc', 'asc']);


        if (_.isEmpty(programs)) {
            programs = _.orderBy(_.filter(_.get(speaker, ["programs"], []), function(item) {
                return (
                    (_.isNull(_.get(item, ["lang"])) || _.get(item, ["lang"], "en") === "en") &&
                    (_.get(item, ["description"], "") !== "")
                );
            }), ['vieworder', 'id'], ['desc', 'asc']);
        }

        
        if (topic_id >= 0) {
            topic_programs = _.orderBy(_.filter(programs, function(item) {
                return (_.get(item, ["topic"]) === topic_id);
            }), ['vieworder', 'id'], ['desc', 'asc']);


            // If we have topic programs, then use them, otherwise show everything
            if (!_.isEmpty(topic_programs)) {
                topic_programs = _.map(topic_programs, function (program, i) {
                    let tags = _.get(program, ["tags"]);
                    return (
                        <div key={i}>
                            <div className="programInfoWrap langsensitive" data-lang={_.get(program, ["lang"])}>
                                <h4>{_.get(program, ["title"])}</h4>
                                <div className="expandable-readmore">
                                    <div style={{display: "block"}}
                                         dangerouslySetInnerHTML={{__html: _.get(program, ["description"])}}/>
                                    <div className="row-fluid grayLabels">
                                        {_.map(tags, function (tag, k) {
                                            return (
                                                <span key={k}>
                                                    <span><span
                                                        className="label label-default">{_.get(tag, ["DESCRIPTION"])}</span>{" "}</span>
                                                </span>
                                            );
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                });
            }
        }

        programs = _.map(programs, function(program, i) {
            let tags = _.get(program, ["tags"]);
            return (
                <div key={i}>
                    <div className="programInfoWrap langsensitive" data-lang={_.get(program, ["lang"])}>
                        <h4>{_.get(program, ["title"])}</h4>
                        <div className="expandable-readmore">
                            <div style={{display: "block"}} dangerouslySetInnerHTML={{__html: _.get(program, ["description"])}} />
                            <div className="row-fluid grayLabels">
                                {_.map(tags, function(tag, k) {
                                    return (
                                        <span key={k}>
                                            <span><span className="label label-default">{_.get(tag, ["DESCRIPTION"])}</span>{" "}</span>
                                        </span>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        if (!primary_bio) {
            return null;
        }

        return (
            <div>
                <h3 className="header">{__("About")} {speakername}</h3>
                <div className="langsensitive" data-lang={_.get(primary_bio, ["lang"], "en")} dangerouslySetInnerHTML={{__html: _.get(primary_bio, ["contents"])}} />
                <br/>
                <br/>
                {topic_programs &&
                    <div>
                        <h4 className="header">{__("Program Information")}</h4>
                        {topic_programs}
                    </div>
                }
                {!topic_programs && programs &&
                    <div>
                        <h4 className="header">{__("Program Information")}</h4>
                        {programs}
                    </div>
                }
            </div>
        );
    }
}

About.propTypes = {
    speaker: PropTypes.object.isRequired,
    profile_language: PropTypes.string
};

export default About;