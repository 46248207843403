import React from "react";
import { Route } from "react-router";
import CollabLayout from "../CollabLayout";

export default class CollabLayoutRoute extends React.Component {

    render() {
        return (
            <CollabLayout {...this.props}>
                <Route {...this.props} />
            </CollabLayout>
        );
    }

}