import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";

import "./MessageScrollListItem.scss";

export default class MessageScrollListItem extends React.Component {

    render() {
        let {
            message
        } = this.props;

        let sid = _.get(message, ["speaker", "id"]);
        let eid = _.get(message, ["event", "id"]) > 0 ? _.get(message, ["event", "id"]) : _.get(message, ["event_id"]);
        let sender_name = _.get(message, ["speaker", "name"], "Speaker");
        let event_name = _.get(message, ["event", "name"], "Event");
        let sent_date = moment(_.get(message, ["created"], moment().format("MM/DD/YYYY"))).calendar(null, {
            sameDay: 'h:mm a',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[Yesterday]',
            lastWeek: '[Last] dddd',
            sameElse: 'MMM D, YYYY'
        });
        let is_unread = !_.get(message, ["receiver_opened"], false);
        let is_selected = _.get(message, ["is_selected"], false);
        let content = _.truncate(_.get(message, ["content"], "- no content -"), {length: 100});
        if (content === "") {
            content = "- no content -";
        }

        return (
            <div className={"mp-message-scroll-list-item " + (is_unread ? "unread " : "") + (is_selected ? "selected " : "")}>
                <Link to={"/messages/" + eid + "/" + sid}>
                    {is_unread &&
                    <span style={{position: "absolute", left: "-15px"}}><i
                        className="fa fa-circle text-primary"/></span>
                    }
                    <div className="text-left">
                        <b className="message-sender">{sender_name}</b>
                        <span className="pull-right text-muted message-date">{sent_date}</span>
                    </div>
                    <div className="text-left message-event">{event_name}</div>
                    <div className="text-left text-muted message-content">{content}</div>
                </Link>
            </div>
        );
    }

}

MessageScrollListItem.propTypes = {
    message: PropTypes.object
};