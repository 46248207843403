import React from "react";

export default class FlipBack extends React.Component {

    render() {
        let {
            children
        } = this.props;

        return (
            <div className="back">
                {children}
            </div>
        );
    }
}