import _ from "lodash";

export default function(state = {}, action){
    switch(action.type){
        case "POSTAJOBPAGE_TOGGLE_NEW_EVENT":
            return _.assign({}, state, {
                choose_existing_event: !state.choose_existing_event
            });
        case "POSTAJOBPAGE_EXISTING_EVENT":
            return _.assign({}, state, {
                eid: action.eid
            });
        case "POSTAJOBPAGE_ADD_SPEAKERS_TO_EVENT":
            return _.assign({}, state, {
                pending: {
                    eid: action.eid
                }
            });
        case "POSTAJOBPAGE_SAVEPENDINGJOB":
            return _.assign({}, state, {
                pending: action.data
            });
        case "POSTAJOBPAGE_REMOVEPENDINGJOB":
            return _.assign({}, _.omit(state, ["pending"]));
        case "POSTAJOBPAGE_SENDNOTIFICATIONS":
            return _.assign({}, state, {
                message: action.message,
                eid: action.eid,
                date_tentative: action.date_tentative || false,
                post_public: action.post_public || false
            });
        default:
            return state;
    }
}