import _ from "lodash";
import React from "react";

import "./PaymentAcceptedSprite.scss";

class PaymentAcceptedSprite extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            frame: 0
        };
        this.MAX_FRAMES = 81;
    }

    componentDidMount() {
        this.interval = window.setTimeout(this.drawSprite, 20);
    }

    componentWillUnmount() {
        if (this.interval) {
            window.clearTimeout(this.interval);
        }
    }

    drawSprite = () => {
        if (this.state.frame < this.MAX_FRAMES) {
            let new_frame = this.state.frame + 1;
            this.setState({frame: new_frame});
            this.interval = window.setTimeout(this.drawSprite, 20);
        } else {
            if (this.interval) {
                window.clearTimeout(this.interval);
            }
            if (_.isFunction(this.props.onComplete)) {
                return this.props.onComplete();
            }
        }
    }

    render() {
        let class_frame = "payment-accepted-frame payment-accepted-frame-" + this.state.frame;
        return (
            <div className={class_frame}></div>
        );
    }
}

export default PaymentAcceptedSprite;