import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonGroup } from "react-bootstrap";

import "./EventTabs.scss";

export default class EventTabs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            selected_tab: "pending"
        };
    }

    __onChangeTab = (selected_tab) => {
        this.setState({
            selected_tab: selected_tab
        }, () => {
            if (_.isFunction(this.props.onChange)) {
                this.props.onChange(this.state.selected_tab);
            }
        })
    }

    render() {
        return (
            <div className="event-tabs">
                <div className="wrap">
                    <ButtonGroup>
                        <Button active={this.state.selected_tab === "pending"} onClick={() => this.__onChangeTab("pending")}>Pending Events</Button>
                        <Button active={this.state.selected_tab === "complete"} onClick={() => this.__onChangeTab("complete")}>Completed Events</Button>
                        <Button active={this.state.selected_tab === "all"} onClick={() => this.__onChangeTab("all")}>All Events</Button>
                    </ButtonGroup>
                </div>
            </div>
        );
    }
}

EventTabs.propTypes = {
    onChange: PropTypes.func
};