import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { gettext as __ } from "../../utils/Trans";
import LocalSearchLocation from "../../actions/SearchPage/LocalSearchLocation";
import mapmarker from "./map-marker.gif";
import queryString from "query-string";

export class SearchLocalPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            location: null,
            error: null
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        this.props.loadLocalSearch()
            .then((response) => {
                let local_search = _.get(response, ["data"], {});
                if (local_search === false) {
                    // If we can't find the local search location, then just use whatever was passed in
                    let url_location = _.get(this.props.match, ["params", "location"]);
                    this.setState({
                        location: {
                            location: url_location
                        }
                    });
                } else {
                    // we found a location, so parse it out and redirect to the search page
                    let location = [];
                    if (local_search.city) {
                        location.push(local_search.city);
                    }
                    if (local_search.state) {
                        location.push(local_search.state);
                    }
                    if (local_search.country_name) {
                        location.push(local_search.country_name);
                    }
                    this.setState({
                        location: {
                            location: _.join(location, ", "),
                            city: local_search.city,
                            state: local_search.state,
                            country: local_search.country_name,
                            lat: local_search.latitude,
                            lng: local_search.longitude
                        }
                    });
                }
            })
            .catch((error) => {
                this.setState({
                    location: null,
                    error: error
                });
            });
    }

    render() {
        let {
            qs,
            local_search
        } = this.props;

        if (!this.state.location || _.isUndefined(local_search)) {
            return (
                <div className="text-center">
                    <div style={{margin: "15vh auto"}}>
                        <h2>Finding local speakers...</h2>
                        <img src={mapmarker} alt="map marker" className="img-responsive center-block" />
                    </div>
                </div>
            );
        }

        if (this.state.location && _.has(local_search, ["slug"])) { // redux store saved last search
            return (<Redirect to={"/search/" + _.get(local_search, ["slug"]) + "?location=" + JSON.stringify(_.assign({}, qs, this.state.location))}/>);
        } else if (this.state.location && local_search === false) {
            return (<Redirect to={"/search?location=" + JSON.stringify(_.assign({}, qs, this.state.location))}/>);
        } else if (this.state.error) {
            return (
                <div className="text-center">
                    <div style={{margin: "15vh auto"}}>
                        <h2>Oops! Looks like there was a problem finding your location!</h2>
                        <img src={mapmarker} alt="missing speaker" className="img-responsive center-block"/>
                        <div>
                            <div className="text-danger">
                                {this.state.error}
                            </div>
                            <div>
                                <p>{__("Oops!  Looks like there was a problem finding local speakers!")}</p>
                                <a href="/marketplace/search"><b>{__("Let's just go to the search page")}</b></a>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function(state, ownProps) {

    let qs = queryString.parse(_.get(ownProps.location, ["search"]), {arrayFormat: "bracket"});
    let location = _.get(ownProps.match, ["params", "location"]);
    let local_search = _.get(state, ["SearchPage", "local", location]);

    return {
        is_loading: _.isUndefined(local_search),
        qs: qs,
        local_search: local_search
    };
};

var mapDispatchToProps = function(dispatch, ownProps){
    let location = _.get(ownProps.match, ["params", "location"]);
    return {
        loadLocalSearch: function() {
            return dispatch(LocalSearchLocation.get(location));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchLocalPage);