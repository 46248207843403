import or_img from "../../containers/SignInPage/or.png";
import _ from "lodash";
import React from "react";
import { gettext as __ } from "../../utils/Trans";
import { Facebook, Google, LinkedIn, Mpi } from "../../actions/Login/Oauth";

const makeOauthURL = function(service_id, whitelabel, redir, lang) {
    if (service_id === "google") {
        return Google.initiate(redir, whitelabel, lang);
    } else if (service_id === "facebook") {
        return Facebook.initiate(redir, whitelabel, lang);
    } else if (service_id === "linkedin") {
        return LinkedIn.initiate(redir, whitelabel, lang);
    } else if (service_id === "mpi") {
        return Mpi.initiate(redir, whitelabel, lang);
    }
};

class OauthButtons extends React.Component {

    render() {
        let {
            lang,
            whitelabel,
            location
        } = this.props;

        let redir = _.get(location, ["state", "redirect"], "/event");
        let is_mpi = _.get(whitelabel, ["is_mpi"], false);

        let btns = _.map([
            ["google", "googleplus", "Google/Gmail"],
            // ["linkedin", "linkedin", "LinkedIn"],
            ["facebook", "facebook", "Facebook"]
        ], function(a, i){
            return <div key={i} style={{marginBottom: "1em", color: "#fff"}}>
                <a
                    href={makeOauthURL(a[0], whitelabel, redir, lang)}
                    className={"zocial " + a[1]}
                    style={{display: "block"}}
                >
                    {a[2]}
                </a>
            </div>;
        });

        return (
          <>
            <div className="text-center">
              <img
                src={or_img}
                alt="or"
                style={{
                    height: "3em", width: "auto", marginTop: 40, marginBottom: 40
                }}
              />
            </div>
            <div>
                {btns}
                {is_mpi
                    ? <div style={{marginBottom: "1em"}}>
                        <a
                            href={makeOauthURL("mpi", whitelabel, redir, lang)}
                            className={"zocial"}
                            style={{
                                display: "block",
                                backgroundColor: "#5D5D5D"
                            }}
                        >
                            {__("Meeting Professionals Intl")}
                        </a>
                    </div>
                    : null
                }
            </div>
          </>
        );
    }

}

export default OauthButtons;