import _ from "lodash";
import React from "react";
import Register from "./onboard/default";
import PostAJob from "./onboard/postajob";
import PostAJobPrivate from "./onboard/postajobprivate";
import PostAJobAffiliate from "./onboard/affiliate";

export class SignUpPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        let {
            location
        } = this.props;

        let onboard = _.get(location, ["state", "onboard"], "default");
        
        if (onboard === "postajob") {
            return (
                <div>
                    <PostAJob {...this.props} />
                </div>
            );
        } else if (onboard === "postajobprivate") {
            return (
                <div>
                    <PostAJobPrivate {...this.props} />
                </div>
            );
        } else if (onboard === "affiliate") {
            return (
                <div>
                    <PostAJobAffiliate {...this.props} />
                </div>
            );
        } else {
            return (
                <div>
                    <Register {...this.props} />
                </div>
            );
        }
    }
}

export default SignUpPage;
