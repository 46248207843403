import _ from "lodash";

/**
 * Type coercion from anything to int or 0
 * @param str
 * NOTE By design this takes one argument (so it can be directly used in mapping functions)
 * @returns {*|number}
 * @deprecated use _.toInteger instead
 */
export default function(str){//NOTE By design this takes one argument (so it can be directly used in mapping functions)
    return _.parseInt(_.isString(str) ? str.replace(/[^\-0-9.]/g, "") : str, 10) || 0;
}
