import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Form, reduxForm, SubmissionError } from "redux-form";
import { Modal } from "react-bootstrap";
import { gettext as __ } from "../../../../../utils/Trans";
import formatCurrency from "../../../../../utils/formatCurrency";
import chargeBuyerDeposit from "../../../../../actions/ShortList/chargeBuyerDeposit";
import RefreshMPEvent from "../../../../../actions/Event/RefreshMPEvent";
import PaymentSpriteForm from "./PaymentSpriteForm";

class PayDepositForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch) {
        return dispatch(chargeBuyerDeposit(values))
            .then(function (response) {
                dispatch({
                    type: "NOTIFICATION_CREATE",
                    message: __("Your deposit has been approved and we have notified the speaker.")
                });
                return response;
            })
            .catch(function (err) {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
            handleSubmit,
            submitSucceeded,
            submitting,
            error,
            deposit_amount,
            balance_amount,
            event_date_str,
            last4,
            is_espeakers,
            onClose,
            onChangePaymentMethod,
            onComplete
        } = this.props;

        if (submitting || submitSucceeded || error) {
            return (
                <PaymentSpriteForm
                    is_payment_processing={submitting}
                    is_payment_received={submitSucceeded}
                    error={error}
                    onRetry={onChangePaymentMethod}
                    onClose={onComplete}
                />
            );
        }


        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("Pay Deposit")}</Modal.Title>
                </Modal.Header>
                <Form role="form" onSubmit={handleSubmit(this.handleSubmit)}>
                    <Modal.Body>
                        <h4>
                            <span className="pull-right">{formatCurrency(deposit_amount, 2)}</span>
                            {__("Amount Charged Today")}
                        </h4>
                        {(balance_amount > 0) &&
                            <h4>
                                <span className="pull-right">{formatCurrency(balance_amount, 2)}</span>
                                {__("Amount Due")}{" "}{event_date_str}
                            </h4>
                        }
                        <hr />
                        <div>
                            <p>
                                {__("I agree to let eSpeakers charge my payment method ending in ")}<b>***{last4}</b>{__(" for the deposit amount of ")}<b>{formatCurrency(deposit_amount, 2)}</b>{__(" today and the remaining balance of %s on ", formatCurrency(balance_amount, 2))}<b>{event_date_str}</b>.
                            </p>
                            <p>
                                {__("This is a binding contract between you (the buyer) and the chosen speaker. Once your deposit has been received, the speaker will reserve their calendar for your event. This transaction is covered by the ")}<b>
                                <a target="_blank" href="https://www.espeakers.com/legal/marketplace-terms-and-conditions/" rel="noopener noreferrer">{__("eSpeakers Marketplace Guarantee")}</a></b>.
                            </p>
                            <p>
                                {__("By clicking \"PAY NOW\", I agree to the above terms of service.")}
                            </p>
                            {(!is_espeakers) &&
                                <div className="help-block instruction">{__("Payment services are powered by eSpeakers.com.")}</div>
                            }
                        </div>
                    </Modal.Body>
                    <Modal.Footer>
                        <div className="visible-lg visible-md">
                            <button type="submit" className="btn btn-success btn-block-xs" disabled={submitting}>
                                {submitting ? <i className="fa fa-spin fa-circle-o-notch" /> : <i/>} {__("PAY NOW")}
                            </button>
                            {" "}
                            <div className="btn btn-default btn-block-xs" onClick={onChangePaymentMethod} disabled={submitting}>
                                {__("Change Payment Method")}
                            </div>
                        </div>
                        <div className="visible-sm visible-xs">
                            <button type="submit" className="btn btn-success btn-block-sm btn-lg" disabled={submitting}>
                                {submitting ? <i className="fa fa-spin fa-circle-o-notch" /> : <i/>} {__("PAY NOW")}
                            </button>
                            {" "}
                            <div className="btn btn-default btn-block-xs" onClick={onChangePaymentMethod} disabled={submitting}>
                                {__("Change Payment Method")}
                            </div>
                        </div>
                    </Modal.Footer>
                </Form>
            </Modal>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

PayDepositForm.propTypes = {
    event: PropTypes.object.isRequired,
    speaker: PropTypes.object.isRequired,
    event_date_str: PropTypes.string,
    description: PropTypes.string,
    last4: PropTypes.string,
    deposit_amount: PropTypes.number,
    balance_amount: PropTypes.number,
    is_espeakers: PropTypes.bool,
    is_loading: PropTypes.bool,
    onClose: PropTypes.func
};

function mapStateToProps(state, ownProps) {
    return {
        initialValues: {
            description: ownProps.description,
            amount: ownProps.deposit_amount,
            eid: _.get(ownProps.event, ["eid"]),
            sid: _.get(ownProps.speaker, ["sid"])
        }
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        onComplete: function() {
            return dispatch(RefreshMPEvent(_.get(ownProps.event, ["eid"]), true))
                .then((response) => {
                    if (_.isFunction(ownProps.onClose)) {
                        ownProps.onClose();
                    }
                    return response;
                });
        }
    };
}

PayDepositForm = reduxForm({
    form: "shortlist_page_pay_deposit"
})(PayDepositForm);

export default connect(mapStateToProps, mapDispatchToProps)(PayDepositForm);