import _ from "lodash";
import Balboa from "./Balboa";

export default function(params) {
    let key = "Sitelists_lang_" + _.get(params, ["bcode"]) + "_" + _.get(params, ["lang"], "en");

    return function(dispatch, getState) {
        return new Promise((resolve /*, reject */) => {
                // Attempt to load the sitelists from the localStorage first
                if (window.localStorage && _.has(window.localStorage, [key])) {
                    let translation;
                    try {
                        translation = JSON.parse(_.get(window.localStorage, [key]));
                        return resolve(translation);
                    } catch (e) { /* parse the local storage and return it early.  if it fails, just do a balboa call */}
                }
                return Balboa(dispatch, getState).get(key, "/marketplace/language", params, {cache_ttl: -1})
                    .then((response) => {
                        resolve(_.get(response, ["data"]));
                    });
            })
            .then((response) => {
                if (_.isString(response)) {
                    response = JSON.parse(response);
                }
                dispatch({
                    type: "SITELISTS_LANGUAGE_LOAD",
                    lang: _.get(params, ["lang"], "en"),
                    translation: response
                });

                _.set(response, ["messages", ""], {
                    "domain": "messages",
                    "lang":_.get(params, ["lang"], "en"),
                    "plural-forms": "nplurals=2; plural=(n !== 1);"
                });
                return response;
            })
            .then((response) => {
                // save the site lists into localstorage for faster retrieval
                if (window.localStorage) {
                    window.localStorage[key] = JSON.stringify(response);
                }
                return response;
            })
            .then((response) => {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            });
    };
};
