import _ from "lodash";

export default function(state = {}, action){
    switch(action.type){
        case "SPEAKER_SHORT_PROFILES_SEARCH_RESULTS": {
            let new_state = _.cloneDeep(state);
            _.forEach(_.get(action.data, ["entries"], []), function (item) {
                let key = _.get(item, ["sid"]);
                // Set basic speaker info from the shortprofile
                _.setWith(new_state, [key, "sid"], _.get(item, ["sid"]));
                _.setWith(new_state, [key, "name"], _.get(item, ["speakername"]));
                _.setWith(new_state, [key, "primaryemail"], _.get(item, ["primaryemail"]));
                _.setWith(new_state, [key, "phone"], _.get(item, ["phone"]));
                _.setWith(new_state, [key, "sortscore"], _.get(item, ["sortscore"]));
                _.setWith(new_state, [key, "fee_high"], _.get(item, ["fee_high"]));
                _.setWith(new_state, [key, "fee_low"], _.get(item, ["fee_low"]));
                _.setWith(new_state, [key, "bio_oneline"], _.get(item, ["bio_oneline"]));
                _.setWith(new_state, [key, "thumb_url"], _.get(item, ["thumb_url"]));
                _.setWith(new_state, [key, "profile_url"], _.get(item, ["url"]));
                _.setWith(new_state, [key, "address"], _.get(item, ["address"]));
                _.setWith(new_state, [key, "city"], _.get(item, ["city"]));
                _.setWith(new_state, [key, "state"], _.get(item, ["state"]));
                _.setWith(new_state, [key, "zip"], _.get(item, ["zip"]));
                _.setWith(new_state, [key, "country"], _.get(item, ["country"]));
                _.setWith(new_state, [key, "shortprofile"], item, Object);
            });
            return _.assign({}, state, new_state);
        }

        case "SPEAKER_SHORT_PROFILES_RESPONSE": {
            let new_state = _.cloneDeep(state);
            _.forEach(action.data, function (item, key) {
                // Set basic speaker info from the shortprofile
                _.setWith(new_state, [key, "sid"], _.get(item, ["sid"]));
                _.setWith(new_state, [key, "name"], _.get(item, ["speakername"]));
                _.setWith(new_state, [key, "primaryemail"], _.get(item, ["primaryemail"]));
                _.setWith(new_state, [key, "phone"], _.get(item, ["phone"]));
                _.setWith(new_state, [key, "sortscore"], _.get(item, ["sortscore"]));
                _.setWith(new_state, [key, "fee_high"], _.get(item, ["fee_high"]));
                _.setWith(new_state, [key, "fee_low"], _.get(item, ["fee_low"]));
                _.setWith(new_state, [key, "bio_oneline"], _.get(item, ["bio_oneline"]));
                _.setWith(new_state, [key, "thumb_url"], _.get(item, ["thumb_url"]));
                _.setWith(new_state, [key, "profile_url"], _.get(item, ["url"]));
                _.setWith(new_state, [key, "address"], _.get(item, ["address"]));
                _.setWith(new_state, [key, "city"], _.get(item, ["city"]));
                _.setWith(new_state, [key, "state"], _.get(item, ["state"]));
                _.setWith(new_state, [key, "zip"], _.get(item, ["zip"]));
                _.setWith(new_state, [key, "country"], _.get(item, ["country"]));
                _.setWith(new_state, [key, "shortprofile"], item, Object);
            });
            return _.assign({}, state, new_state);
        }
        case "SPEAKER_FULL_PROFILES_RESPONSE": {
            let new_state = _.cloneDeep(state);
            let sid = _.get(action, ["sid"]);
            // Set basic speaker info from the shortprofile
            _.setWith(new_state, [sid, "sid"], _.get(action.data, ["sid"]));
            _.setWith(new_state, [sid, "name"], _.get(action.data, ["name"]));
            _.setWith(new_state, [sid, "primaryemail"], _.get(action.data, ["primaryemail"]));
            _.setWith(new_state, [sid, "phone"], _.get(action.data, ["phone"]));
            _.setWith(new_state, [sid, "address"], _.get(action.data, ["address"]));
            _.setWith(new_state, [sid, "city"], _.get(action.data, ["city"]));
            _.setWith(new_state, [sid, "state"], _.get(action.data, ["state"]));
            _.setWith(new_state, [sid, "zip"], _.get(action.data, ["zip"]));
            _.setWith(new_state, [sid, "country"], _.get(action.data, ["country"]));
            _.setWith(new_state, [sid, "fee_high"], _.get(action.data, ["high_fee"]));
            _.setWith(new_state, [sid, "fee_low"], _.get(action.data, ["low_fee"]));
            _.setWith(new_state, [sid, "bio_oneline"], _.get(_.head(_.get(action.data, ["bios"])), ["oneline"]));
            _.setWith(new_state, [sid, "thumb_url"], _.get(action.data, ["mainPhotoURL"]));
            _.set(new_state, [sid, "profile"], action.data);
            return _.assign({}, state, new_state);
        }
        /*
         * SPEAKER CASES
         */
        case "SPEAKER_GET": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action.data, ["sid"]);
            _.setWith(new_state, [id], action.data, Object);
            return new_state;
        }
        case "SPEAKER_GET_ALL": {
            let new_state = _.cloneDeep(state);
            _.each(action.data, function(item) {
                let id = _.get(item, ["sid"]);
                _.setWith(new_state, [id], item, Object);
            });
            return new_state;
        }
        case "SPEAKER_DELETE": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            _.unset(new_state, [id]);
            return new_state;
        }
        case "SPEAKER_PUT": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action.data, ["sid"]);
            _.setWith(new_state, [id], action.data, Object);
            return new_state;
        }
        case "SPEAKER_POST": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action.data, ["sid"]);
            _.setWith(new_state, [id], action.data, Object);
            return new_state;
        }

        default:
            return state;
    }
}
