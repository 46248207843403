import _ from "lodash";
import React from "react";
import createReactClass from "create-react-class";
import PropTypes from "prop-types";

require("./index.css");

const HorizontalScroll = createReactClass({
    displayName: "HorizontalScroll",
    propTypes: {
        //set this if you want to automatically scroll to the bottom
        //when this key changes it will re-scroll to the bottom
        //i.e. use the database id of the thing you are rendering
        auto_scroll: PropTypes.number,
        auto_scroll_right_key: PropTypes.string,
        className: PropTypes.string
    },
    getInitialState: function(){
        return {
            show_hint_left: true,
            show_hint_right: true,
            auto_scroll_right_key: void 0
        };
    },
    UNSAFE_componentWillMount: function(){
        this.onScroll_direct = this.onScroll;
        this.onScroll = _.debounce(this.onScroll_direct, 100);
    },
    componentDidMount: function(){
        this.UNSAFE_componentWillUpdate(this.props);
        //initial setup of scroll hints
        this.onScroll_direct();
        // Initial scrolling to show the visible element
        if (!_.isUndefined(this.props.auto_scroll)) {
            if (this.props.auto_scroll < this.node.offsetWidth) {
                this.node.scrollLeft = 0;
            } else if (this.node.scrollWidth - this.props.auto_scroll < this.node.offsetWidth) {
                this.node.scrollLeft = this.node.scrollWidth;
            } else {
                this.node.scrollLeft = this.props.auto_scroll;
            }
        }
    },
    UNSAFE_componentWillUpdate: function(next_props){
        if(!next_props.auto_scroll_right_key){
            return;
        }
        var scroll_width = this.node.scrollWidth;

        var key = next_props.auto_scroll_right_key + " " + scroll_width;
        this.shouldScrollRight = false;
        if(this.state.auto_scroll_right_key !== key){
            this.setState({auto_scroll_right_key: key});
            this.shouldScrollRight = true;
        }
    },
    componentDidUpdate: function(){
        //only if scrollWidth changes
        if(this.shouldScrollRight){
            this.node.scrollLeft = this.node.scrollWidth;
        }
    },
    onScroll: function(){
        var is_left = (this.node.scrollLeft < this.node.offsetWidth);
        var is_right = (this.node.scrollLeft + this.node.offsetWidth) === this.node.scrollWidth;

        this.setState({show_hint_left: !is_left, show_hint_right: !is_right});
    },
    render: function(){
        // var show_hint_top = this.state.show_hint_top;
        // var show_hint_bottom = this.state.show_hint_bottom;
        var className = this.props.className;

        return (
            <div className={"HorizontalScroll " + className} onScroll={this.onScroll} ref={node => this.node = node}>
                {this.props.children}
            </div>
        );
    }
});

export default HorizontalScroll;