import _ from "lodash";
import React from "react";
import SpeakerRow from "../../../../components/SpeakerRow";
import { gettext as __ } from "../../../../utils/Trans";

export default class SharedShortlist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_collaborator_alert: true,
            rating_sort: "average"
        };
    }

    __onDismissCollaboratorAlert = () => {
        this.setState({
            show_collaborator_alert: false
        });
    }

    __onCreateJob = () => {
        if (_.isFunction(this.props.onCreateJob)) {
            return this.props.onCreateJob(this.props.affiliate_event);
        }
    }

    __onShare = () => {
        if (_.isFunction(this.props.onShare)) {
            return this.props.onShare();
        }
    }

    __onToggleRatingSort = (sort) => {
        this.setState({
            rating_sort: sort
        });
    }

    render() {
        let {
            // affiliate_event,
            collaborator,
            shortlist,
            speakers,
            awards,
            languages,
            onWatchVideo,
            onProfile,
            onReview,
            // onRemove,
            onViewRatings
        } = this.props;

        let collaborators = _.get(shortlist, ["collaborators"]);
        let ratings = _.keyBy(_.map(_.get(shortlist, ["speakers"]), (spkr) => {
            let ratings = _.map(_.get(spkr, ["ratings"]), (rating) => {
                let collaborator_id = _.get(rating, ["mp_collaborator_id"]);
                let collaborator = _.head(_.filter(collaborators, {id: collaborator_id}));
                return _.assign({}, rating, {collaborator: collaborator});
            });
            return _.assign({}, spkr, {ratings: ratings});
        }), "speaker_id");

        let shortlisted_speakers = _.reverse(_.sortBy(_.map(_.get(shortlist, ["speakers"]), (item) => {
            return _.assign({}, item, {
                my_rank: _.get(_.head(_.filter(_.get(item, ["ratings"]), {mp_collaborator_id: _.get(collaborator, ["id"])})), ["rating"], 0),
                rank: _.toInteger(_.mean(_.map(_.get(item, ["ratings"]), (rating) => {
                  return _.get(rating, ["rating"], 0);
                }))),
                rating: _.head(_.filter(_.get(item, ["ratings"]), {mp_collaborator_id: _.get(collaborator, ["id"])}))
            });
        }), (this.state.rating_sort === "average" ? "rank" : "my_rank")));

        if ((_.get(collaborator, ["permission"], 0) & 1) === 0) {
            // collaborator doesn't have permission to review speakers, so don't show that option
            onReview = false;
        }
        if ((_.get(collaborator, ["permission"], 0) & 4) === 0) {
            // collaborator doesn't have permission to remove speakers, so don't show that option
            // onRemove = false;
        }

        let shortlist_div = _.map(shortlisted_speakers, (spkr) => {
            let profile = _.get(speakers, [_.get(spkr, ["speaker_id"])]);
            let speaker = _.assign({}, profile, {
                ratings: _.get(ratings, [_.get(spkr, ["speaker_id"]), "ratings"]),
                my_rating: _.get(spkr, ["rating"]),
                shortlist: spkr
            });
            if (!profile) {
                return null;
            }
            return (
                <div className="row row-fluid"  key={_.get(spkr, ["id"])}>
                    <div className="col-xs-12">
                        <SpeakerRow
                            collaborator={_.get(collaborator, ["email"])}
                            rating_sort={this.state.rating_sort}
                            collaborators={collaborators}
                            awards={awards}
                            languages={languages}
                            speaker={speaker}
                            onProfile={onProfile}
                            onViewPrograms={() => {
                                return false;
                            }}
                            onViewSchedule={() => {
                                return false;
                            }}
                            onWatchVideo={onWatchVideo}
                            onAddComments={() => {
                                return false;
                            }}
                            onReview={onReview}
                            onViewRatings={onViewRatings}
                            onRemove={false}
                            is_debug={false}
                            is_shortlisted={true}
                        />
                    </div>
                </div>
            );
        });

        if (_.size(speakers) === 0) {
            return (
                <div className="row speaker-row-wrapper">
                    <div className="col-xs-12 col-sm-10 col-sm-offset-1">
                        <div className="text-center col-xs-12" style={{margin: "40px auto"}}>
                            <h3 className="text-muted">
                                <p>{__("Looks like there are no speakers on your shortlist.")}</p>
                                <p>{__("Ask the referrer to send you some suggestions.")}</p>
                            </h3>
                        </div>
                    </div>
                </div>
            );
        }

        if (_.isUndefined(speakers)) {
            shortlist_div = <div className="text-center col-xs-12" style={{margin: "40px auto"}}>
                <i className="fa fa-spin fa-circle-o-notch fa-4x"/>
                <div>{__("Loading speakers")}...</div>
            </div>;
        }

        return (
            <div>
                {((_.get(collaborator, ["permission"], 0) & 8) > 0) &&
                <div style={{marginTop: "2em"}}>
                    <div className="row text-left" style={{marginTop: "1em", marginBottom:"2em"}}>
                        <div className="col-sm-5 col-sm-offset-1">
                            <div className="btn btn-success btn-lg" onClick={this.__onCreateJob}>{__("Check availability / Inquire")}{" "}<i className="fa fa-chevron-right"/></div>
                        </div>
                        <div className="col-sm-5 text-muted text-left">
                            <b>{__("What to do next?")}</b> {__("Share this shortlist with your team to ")}<span style={{color:"#605ca8", textDecoration:"underline", cursor:"pointer", textUnderlinePosition:"under"}} onClick={this.__onShare}>{__("easily collect feedback.")}</span>
                        </div>
                    </div>
                </div>
                }
                {((_.get(collaborator, ["permission"], 0) & 8) === 0) && ((_.get(collaborator, ["permission"], 0) & 4) > 0) &&
                <div>
                    <div className="row text-center">
                        <div className="col-sm-12">
                            <b>{__("What to do next?")}</b> {__("Share this shortlist with your team to easily collect feedback.")}
                        </div>
                    </div>
                    <div className="row" style={{marginTop: "1em"}}>
                        <div className="col-sm-5 text-left col-sm-offset-1">
                            <div className="btn btn-success btn-lg" onClick={this.__onShare}>Share with Team{" "}<i
                                className="fa fa-chevron-right"/></div>
                        </div>
                        <div className="col-sm-6">
                            <b>{__("What to do next?")}</b> {__("Share this shortlist with your team to easily collect feedback.")}
                        </div>

                    </div>
                </div>
                }
                <div className="text-right small col-xs-10 col-xs-offset-1 hidden-sm hidden-xs">
                    {__("Sort by")}:{" "}
                    <span className={"link"} onClick={() => this.__onToggleRatingSort("me")}>
                        {this.state.rating_sort !== "average"
                            ? <b>{__("My Rank")}</b>
                            : <span>{__("My Rank")}</span>
                        }
                    </span>
                    {" / "}
                    <span className={"link"} onClick={() => this.__onToggleRatingSort("average")}>
                        {this.state.rating_sort === "average"
                            ? <b>{__("Team Rank")}</b>
                            : <span>{__("Team Rank")}</span>
                        }
                    </span>
                </div>
                <div className="row speaker-row-wrapper">
                    <div className="col-xs-12 col-sm-10 col-sm-offset-1">
                    {shortlist_div}
                    </div>
                </div>
                {_.size(shortlisted_speakers) > 2 && ((_.get(collaborator, ["permission"], 0) & 8) > 0) &&
                <div style={{marginTop: "2em"}}>
                    <div className="row text-left" style={{marginTop: "1em", marginBottom:"2em"}}>
                        <div className="text-center">
                            <div className="btn btn-success btn-lg" onClick={this.__onCreateJob}>{__("Check availability / Inquire")}{" "}<i className="fa fa-chevron-right"/></div>
                        </div>
                    </div>
                </div>
                }
            </div>
        );
    }
}