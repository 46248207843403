import React from "react";
import PropTypes from "prop-types";

export default class UserLink extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            label,
            is_selected,
            sub_view,
            onClick
        } = this.props;

        return (
            <li className={"UserLink  " + (is_selected ? "active" : "")}
                onClick={() => onClick(sub_view)}>{label}</li>
        );
    }
}

UserLink.propTypes = {
    label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    is_selected: PropTypes.bool,
    sub_view: PropTypes.string,
    onClick: PropTypes.func
};