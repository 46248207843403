import _ from "lodash";
import Promise from "es6-promise";

Promise.polyfill();

export default {
    /**
     * Retrieves a list of records.  Pass in search parameters in {params}
     * @param params
     * @returns {Function}
     */
    initiate: function(/* redirect_url, whitelabel */) {
        return "";
    },

    /**
     * Retrieves a single record.
     * @param params
     * @returns {Function}
     */
    callback: function(code /*, callback_url */) {
        let key = ["Oauth_Callback", code];
        let self = this;
        return function(dispatch) {
            return self.api(dispatch, key, new Promise());
        };
    },

    /**
     * Shortcut to either add or update a record based on whether the id is > 0
     * @param params
     * @returns {Function}
     */
    profile: function(access_token) {
        let key = ["Oauth_Profile", access_token];
        let self = this;
        return function(dispatch) {
            return self.api(dispatch, key, new Promise());
        };
    },

    /**
     * Utility function to catch errors in a Promise
     * @param dispatch
     * @param key
     * @param promise
     * @returns {Function}
     */
    api: function(dispatch, key, promise) {
        return promise
            .then(function (response) {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            })
            .catch(function (err) {
                if (err.name === "AuthorizationException") {
                    dispatch({
                        type: "ERROR_CREATE",
                        source: _.join(key, "_"),
                        error: err
                    });
                    throw err;
                } else {
                    dispatch({
                        type: "ERROR_CREATE",
                        source: _.join(key, "_"),
                        error: err
                    });
                    throw err;
                }
            });
    },

    getOauthState: function(bureau_id, redirect) {
        return "buyer[$]" + bureau_id + "[$]" + redirect;
    },

    getCallbackUrl: function(service_id, whitelabel, lang) {
        if (lang === "en" || _.isUndefined(lang)) {
            lang = "";
        } else {
            lang = "/" + lang;
        }
        if (_.get(whitelabel, ["is_espeakers"], false)) {
            return window.location.protocol + "//" + window.location.host + "/shortlist" + lang + "/oauth-callback/" + service_id + "/";
        } else if (!_.isNull(_.get(whitelabel, ["whitelabel_domain"]))) {
            return window.location.protocol + "//" + _.get(whitelabel, ["whitelabel_domain"]) + lang + "/oauth-callback/" + service_id + "/";
        } else {
            return window.location.protocol + "//" + window.location.host + "/shortlist" + lang + "/oauth-callback/" + service_id + "/";
        }
    }
};
