import _ from "lodash";
import React from "react";
import dateformat from "dateformat";
import eventToDailyTravelItinerary from "../../../../utils/eventToDailyTravelItinerary";
import { gettext as __ } from "../../../../utils/Trans";
import TravelLeg from "./TravelLeg";

class TravelPlans extends React.Component {

    render() {
        let {
            event,
            speaker
        } = this.props;

        let speaker_name = _.get(speaker, ["name"]);
        let itinerary_days = eventToDailyTravelItinerary(event);

        if (_.isEmpty(itinerary_days)) {
            return null;
        }

        let body = _.map(itinerary_days, function (day, i) {
            var legs = _.map(day.legs, function (leg, i) {
                return <TravelLeg key={i} leg={leg}/>;
            });
            return (
                <div key={i}>
                    <p className="text-muted" style={{fontWeight: "bold"}}>
                        {day.date
                            ? dateformat(day.date, "dddd, mmmm d, yyyy")
                            : __("no date set")}
                    </p>
                    {legs}
                </div>
            );
        });

        return (
            <div>
                <h4 style={{marginBottom: "20px"}}>
                    {__("%s's Travel plans", [speaker_name])}
                </h4>
                {body}
            </div>
        );
    }
}

export default TravelPlans;