import _ from "lodash";
import { gettext as __ } from "../../../../utils/Trans";
import React from "react";
import PropTypes from "prop-types";

class CareerQuickStats extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speaker
        } = this.props;

        let career_years = _.get(speaker, ["careerYears"], 0);
        let presentations_last_year = _.get(speaker, ["presentationsLastYear"], 0);

        return (
            <div className="quickStatsBox career text-center">
                <h5 className="header">{__("CAREER")}</h5>
                <div>
                    <div>{__("Speaking")}</div>
                    <div className="priceRange">{career_years} {__("yrs")}</div>
                </div>
                {presentations_last_year > 0 &&
                <div>
                    <br />
                    <div>{__("Last Year")}</div>
                    <div className="priceRange">{presentations_last_year}</div>
                    <div>{__("events")}</div>
                </div>
                }
            </div>
        );
    }
}

CareerQuickStats.propTypes = {
    speaker: PropTypes.object.isRequired
};

export default CareerQuickStats;