import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import SpeakerThumbnail from "../SpeakerThumbnail";
import { gettext as __ } from "../../utils/Trans";

import "./SpeakerAvailability.scss";

export default class SpeakerAvailability extends React.Component {

    __onRemove = () => {
        if (_.isFunction(this.props.onRemove)) {
            return this.props.onRemove(this.props.speaker);
        }
    }

    __onInquirySwitch = () => {
        if (_.isFunction(this.props.onInquirySwitch)) {
            return this.props.onInquirySwitch(this.props.speaker);
        }
    }

    render() {
        let {
                speaker,
                calendar,
                is_selected  = true,
                availability,
                is_searching = false,
            } = this.props;

        let availability_indicator = null;

        if (1 === availability) {
            availability_indicator = <span style={{background: "#3c763d", color: "white", padding: "2px 7px", borderRadius: "11px", marginTop: "1em", display: "block"}}>{__("Available")}</span>;
        } else if (2 === availability) {
            let unavailable_dates = _.map(_.pickBy(calendar, (date) => {
                return (_.get(date, ["status"]) === 2);
            }), (date, i) => {
                return i;
            });
            if (_.size(unavailable_dates) === 1) {
                availability_indicator = <span className="text-muted">
                    {__("Unavailable on %s", [moment(_.head(unavailable_dates), "Y-M-D").format("M/D/Y")])}
                </span>;
            } else {
                availability_indicator = <span className="text-muted">
                    {__("Unavailable on %s and %s other days", [moment(_.head(unavailable_dates), "Y-M-D").format("M/D/Y"), (_.size(unavailable_dates) - 1)])}
                </span>;
            }
        }

        if (is_searching) {
            availability_indicator = <i className="fa fa-circle-o-notch fa-spin"></i>;
        }

        return (
            <tr key={_.get(speaker, ["sid"])} className="speaker-availability d-flex">
                <td className="col-sm-1">
                    <button type="button" className="btn btn-link" onClick={this.__onInquirySwitch} id={"ratings_popover_" + _.get(speaker, ["sid"])}>{is_selected ? <i className="fa fa-2x fa-check-square-o fa-fw text-success"/> : <i className="fa fa-2x fa-square-o fa-fw text-success"/>}</button>
                </td>
                <td className="col-sm-2">
                    <SpeakerThumbnail speaker={speaker} dont_show_name_on_hover={true} dont_link_to_profile={true}/>
                </td>
                <td className="col-sm-8 speaker-name">
                    {_.get(speaker, ["name"])}
                </td>
                <td className="col-sm-1">
                    {availability_indicator}
                </td>
                {false && <td className="col-xs-1">
                    <button type="button" style={{visibility: "hidden"}} className="btn btn-link btn-default" onClick={this.__onRemove}><i className="fa fa-2x fa-trash"></i></button>
                </td>}
            </tr>
        );
    }
}

SpeakerAvailability.propTypes = {
    speaker: PropTypes.object,
    is_searching: PropTypes.bool,
    is_selected: PropTypes.bool,
    availability: PropTypes.number,
    onRemove: PropTypes.func,
    onInquirySwitch: PropTypes.func
};