import _ from "lodash";

export default function(state = {}, action){
    switch(action.type){
        case "SPEAKER_SHORT_PROFILES_SEARCH_RESULTS": {
            let new_state = _.cloneDeep(state);
            _.forEach(_.get(action.data, ["entries"], []), function (item) {
                _.setWith(new_state, _.get(item, ["sid"]), item, Object);
            });
            return _.assign({}, state, new_state);
        }
        case "SPEAKER_SHORT_PROFILES_RESPONSE":
            let new_state = _.cloneDeep(state);
            _.forEach(action.data, function (item, key) {
                _.setWith(new_state, [key], item, Object);
            });
            return _.assign({}, state, new_state);
        default:
            return state;
    }
}
