import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Modal } from "react-bootstrap";
import { DefaultPlayer as Video } from "react-html5video";
import VerticalScroll from "../../../../components/VerticalScroll";
import formatVideoUrl from "../../../../utils/formatVideoUrl";
import toMpURL from "../../../../utils/toMpURL";

import "./VideoPlayer.scss";

require ("../../../../../node_modules/react-html5video/dist/styles.css");

class VideoPlayer extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_share_link: false,
            video: this.props.video
        };
    }

    showShareLink = () => {
        this.setState({
            show_share_link: true
        });
    }

    __onVideoClick = (video) => {
        if (_.isFunction(this.props.onVideoClick)) {
            this.setState({
                video: video
            }, () => {
                return this.props.onVideoClick(video);
            });
        } else {
            this.setState({
                video: video
            });
        }
    }

    render() {
        let {
            speaker,
            whitelabel,
            onVideoClose
        } = this.props;

        let playlist = _.filter(_.get(speaker, ["videos"]), (o) =>( o.flags & 32) === 0);

        let selected_video = this.state.video;

        if (!selected_video) {
            selected_video = _.head(playlist);
        }

        let logo_url = _.get(whitelabel, ["mp_keyvalues", "LOGO_URL"], toMpURL("/whitelabel/" + _.get(whitelabel, ["id"]) + "/img/logo.png"));
        logo_url = _.replace(logo_url, "S3:", "https://streamer.espeakers.com/");

        let video_urls = formatVideoUrl(_.get(selected_video, ["url"]));

        let aid = _.get(selected_video, ["aid"]);
        let description = _.get(selected_video, ["description"]);
        let url = _.has(video_urls, ["mp4"]) ? _.get(video_urls, ["mp4"]) : _.has(video_urls, ["webm"]);

        let video_share_url = toMpURL("/profile/" + _.get(speaker, ["sid"]) + "?opentovideo=" + _.get(selected_video, ["aid"]), null, true);
        let video_poster = _.get(selected_video, ["img"]);


        let foreground = _.get(whitelabel, ["mp_keyvalues", "COLOR_HEADER_FOREGROUND"], "#FFF");
        let background = _.get(whitelabel, ["mp_keyvalues", "COLOR_HEADER_BACKGROUND"], "#000");

        return (
            <Modal show={true} bsSize="large" className="modal-video" style={{bottom: 0, top:0}}>
                <Modal.Header closeButton onHide={onVideoClose}>
                    <Modal.Title>{description}{" | "}<span className="text-muted small">{_.get(speaker, ["name"])}</span></Modal.Title>
                </Modal.Header>
                <div className="MP-VideoPlayer" style={{ color:foreground, backgroundColor:background }}>
                    <div className="row">
                        <div className="col-sm-12 col-md-9">
                            <Video autoPlay
                                   controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
                                   poster={video_poster}
                                   src={url}
                                   onCanPlayThrough={() => {
                                       // Do stuff
                                   }}>
                            </Video>
                            <div style={{padding:"0px 5px 5px 5px"}}>
                                {this.state.show_share_link
                                    ?
                                    <div>
                                        <i className="fa fa-facebook-square" /> Share this on Facebook.  Copy this url to share.
                                        <br />
                                        <input type="text" className="form-control" readOnly={true} value={video_share_url} />
                                    </div>
                                    :
                                    <span className={"link"} onClick={this.showShareLink}><i className="fa fa-facebook-square" /> Share this on Facebook</span>
                                }
                            </div>
                        </div>
                        <div className="hidden-sm hidden-xs col-md-3">
                            <div className="playlist-wrapper" style={{opacity:0.9, color:foreground}}>
                                <div className="playlist-head">
                                    <img className="logo img-responsive" alt="video thumbnail" src={logo_url} />
                                </div>
                                <VerticalScroll>
                                    <div className="playlist-body">
                                        <div className="items">
                                            {_.map(playlist, (item, i) => {
                                                return (
                                                    <span key={i} className="item" onClick={() => this.__onVideoClick(item)}>
                                                            <p>
                                                        {aid === _.get(item, ["aid"])
                                                            ?
                                                            <i className="fa fa-pause-circle fa-2x pull-left"></i>
                                                            :
                                                            <i className="fa fa-play-circle fa-2x pull-left"></i>
                                                        }
                                                                {_.get(item, ["description"])}
                                                        </p>
                                                    </span>
                                                );
                                            })}
                                        </div>
                                    </div>
                                </VerticalScroll>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
        );
    }
}

VideoPlayer.propTypes = {
    speaker: PropTypes.object.isRequired,
    video: PropTypes.object,
    whitelabel: PropTypes.object
};

export default VideoPlayer;