import _ from "lodash";

export default function(state = {}, action){
    switch(action.type) {
        case "SEARCHPAGE_RESULTS": {
            let new_state = _.cloneDeep(state);
            _.setWith(new_state, ["results"], _.get(action.data, ["entries"], []), Object);
            _.set(new_state, ["num_results"], _.get(action.data, ["meta", "total"], 0));
            _.set(new_state, ["page"],_.get(action.data, ["meta", "page"], 0));
            _.set(new_state, ["items_per_page"], _.get(action.data, ["meta", "items_per_page"], 4));

            return new_state;
        }
        case "SEARCHPAGE_MORE_RESULTS": {
            let new_state = _.cloneDeep(state);
            let new_results = _.concat(_.get(new_state, ["results"], []), _.get(action.data, ["entries"], []));
            _.setWith(new_state, ["results"], new_results, Object);
            _.set(new_state, ["num_results"], _.get(action.data, ["meta", "total"], 0));
            _.set(new_state, ["page"],_.get(action.data, ["meta", "page"], 0));
            _.set(new_state, ["items_per_page"], _.get(action.data, ["meta", "items_per_page"], 4));

            return new_state;
        }
        case "SPEAKERS_TRENDING_RESULTS": {
            let new_state = _.cloneDeep(state);
            _.setWith(new_state, ["trending", "results"], _.get(action.data, ["entries"], []), Object);
            _.set(new_state, ["trending", "num_results"], _.get(action.data, ["meta", "total"], 0));
            _.set(new_state, ["trending", "page"],_.get(action.data, ["meta", "page"], 0));
            _.set(new_state, ["trending", "items_per_page"], _.get(action.data, ["meta", "items_per_page"], 4));

            return new_state;
        }
        case "SPEAKERS_SHOWCASE_RESULTS": {
            let new_state = _.cloneDeep(state);
            _.setWith(new_state, ["showcase", "results"], _.get(action.data, ["entries"], []), Object);
            _.set(new_state, ["showcase", "num_results"], _.get(action.data, ["meta", "total"], 0));
            _.set(new_state, ["showcase", "page"],_.get(action.data, ["meta", "page"], 0));
            _.set(new_state, ["showcase", "items_per_page"], _.get(action.data, ["meta", "items_per_page"], 4));

            return new_state;
        }
        case "SEARCHPAGE_SAVE_HASH": {
            let new_state = _.cloneDeep(state);
            let hash = action.hash;
            _.set(new_state, ["search", hash], action.sids);
            return _.assign({}, state, new_state);
        }

        case "SEARCHPAGE_SAVE_LAST": {
            try {
                if (window.localStorage) {
                    window.localStorage["last_search_params"] = JSON.stringify(action.params);
                }
            } catch (e) {
                // silently ignore errors to save to localstorage (can happen in incognito mode)
                // https://stackoverflow.com/questions/21159301/quotaexceedederror-dom-exception-22-an-attempt-was-made-to-add-something-to-st
            }
            let new_state = _.cloneDeep(state);
            _.setWith(new_state, ["last", "hash"], action.hash);
            _.setWith(new_state, ["last", "params"], action.params, Object);
            _.setWith(new_state, ["last", "results"], _.get(action.data, ["entries"], []), Object);
            _.set(new_state, ["last", "num_results"], _.get(action.data, ["meta", "total"], 0));
            _.set(new_state, ["last", "page"],_.get(action.data, ["meta", "page"], 0));
            _.set(new_state, ["last", "items_per_page"], _.get(action.data, ["meta", "items_per_page"], 4));
            return new_state;
        }
        case "SEARCHPAGE_NEW_SEARCH": {
            return _.assign({}, state, {
                n_results_to_show: 0,
                last_search_params: action.search_params
            });
        }
        case "SEARCHPAGE_NEW_SHORTPROFILES": {
            return _.assign({}, state, {
                shortprofiles: {}
            });
        }
        case "SEARCHPAGE_SET_LAST_SEARCH_PARAMS": {
            try {
                window.localStorage["last_search_params"] = JSON.stringify(action.search_params);
            } catch (e) {
                // silently ignore errors to save to localstorage (can happen in incognito mode)
                // https://stackoverflow.com/questions/21159301/quotaexceedederror-dom-exception-22-an-attempt-was-made-to-add-something-to-st
            }
            let new_state = _.cloneDeep(state);
            _.set(new_state, ["last_search_params"], action.search_params);
            if (_.get(new_state, ["last_search_params", "available_between"]) !== _.get(action, ["search_params", "available_between"])) {
                // _.unset(new_state, ["shortprofiles"]); // clear out cached short profiles if we have a date because we need to query their availability again
            }
            if (_.get(new_state, ["last_search_params", "distance"]) !== _.get(action, ["search_params", "distance"])) {
                // _.unset(new_state, ["shortprofiles"]); // clear out cached short profiles if we have a location because we need to query their location again
            }
            return _.assign({}, state, new_state);
        }
        case "SEARCHPAGE_TOGGLE_SORD": {
            let new_state = _.cloneDeep(state);
            let old_sord = _.get(new_state, ["last_search_params", "order"], "fee_low:d");
            if (old_sord === "fee_low:d") {
                _.set(new_state, ["last_search_params", "order"], "fee_low:a");
            } else {
                _.set(new_state, ["last_search_params", "order"], "fee_low:d");
            }
            return _.assign({}, state, new_state);
        }
        case "SEARCHPAGE_SIDS": {
            return _.assign({}, state, {
                sids: action.sids
            });
        }
        case "SEARCHPAGE_ADD_MORE_SHORTPROFILES": {
            let orig = state.shortprofiles || {};
            return _.assign({}, state, {
                shortprofiles: _.assign({}, orig, action.data)
            });
        }
        case "SEARCHPAGE_SET_N_RESULTS_TO_SHOW": {
            return _.assign({}, state, {
                n_results_to_show: action.n
            });
        }
        case "LOCAL_SEARCH_LOCATION": {
            let new_state = _.cloneDeep(state);
            let slug = action.slug;
            _.set(new_state, ["local", slug], action.data);
            return _.assign({}, state, new_state);
        }
        case "SEARCHPAGE_BACKTOSEARCH": {
            let new_state = _.cloneDeep(state);
            _.set(new_state, ["backtosearch"], action.url);
            return _.assign({}, state, new_state);
        }
        case "GEO_CODE_LOAD": {
            let new_state = _.cloneDeep(state);
            _.set(new_state, ["geocode"], action.data);
            return _.assign({}, state, new_state);
        }
        default:
            return state;
    }
}
