import React from "react";
import { gettext as __ } from "../../../../utils/Trans";

export default class PaymentAddress extends React.Component {

    render() {
        return (
            <div style={{width:"100%", borderTop: "1px dashed #cfcfcf", borderBottom: "1px dashed #cfcfcf", margin: "10px 0px", padding: "10px"}}>
                <h6><b>{__("Make checks payable to: eSpeakers.com")}</b></h6>
                <h6>{__("Mail payments to:")}</h6>
                <div className="text-muted">
                    <div>eSpeakers Marketplace</div>
                    <div>55 Merchant St. #1129</div>
                    <div>American Fork, UT 84003</div>
                    <div>USA</div>
                </div>
            </div>
        );
    }
}