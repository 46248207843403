import React from "react";

export default class FlipFront extends React.Component {

    render() {
        let {
            children
        } = this.props;

        return (
            <div className="front">
                {children}
            </div>
        );
    }
}