import _ from "lodash";
import React from "react";
import {Redirect} from "react-router";
import {withRouter} from "react-router-dom";
import {connect} from "react-redux";
import Helmet from "react-helmet";
import queryString from "query-string";
import LoadingIndicator from "../../../components/LoadingIndicator";
import {getCurrentLanguage, gettext as __} from "../../../utils/Trans";
import toMpURL from "../../../utils/toMpURL";
import MetaTags from "../components/MetaTags";
import Profile from "../components/Profile";
import VideoPlayer from "../components/VideoPlayer";
import EmbeddedShortlist_add from "../../../actions/ShortList/EmbeddedShortlist_add";
import EmbeddedShortlist_refresh from "../../../actions/ShortList/EmbeddedShortlist_refresh";
import EmbeddedShortlist_remove from "../../../actions/ShortList/EmbeddedShortlist_remove";
import SpeakerProfile from "../../../actions/Speaker/SpeakerProfile";
import Analytics from "../../../utils/Analytics";
import Constants from "../../../utils/Constants";
import SpeakerMissingPage from "../../SpeakerMissingPage";

import "../ProfilePage.css";

export class ProfilePageA extends React.Component {

  constructor(props) {
    super(props);
    this.tabRef = React.createRef();
    this.state = {
      video: null,
      selected_tab: (_.get(window, ["location", "hash"]) !== "" ? _.replace(_.get(window, ["location", "hash"]), "#", "") : "about"),
      sid: null,
      show_missing_speaker: _.get(props, "show_missing_speaker", false)
    };
  }

  componentDidMount() {
    let sid = _.get(this.props.match, ["params", "sid"]);
    let query = queryString.parse(window.location.search);
    // Allow us to see the profile before it is on a directory by passing in a special flag
    let ignoresubscriptioncheck = (_.has(query, ["ignoresubscriptioncheck"]) || _.has(query, ["showunsubscribed"]));
    this.setState({
      sid: sid
    });
    // Load the speaker if we have it
    window.scrollTo(0, 0);

    if (sid && (sid !== _.get(this.props, ["speaker", "sid"]))) {
      this.props.loadProfile(sid, {bureau_id: _.get(this.props.whitelabel, ["bid"], Constants.TRUNK_BUREAU_ID), ignoresubscriptioncheck: ignoresubscriptioncheck})
      .catch((err) => {
        if (err.name.includes("SpeakerMissingException") || err.name.includes("NotFoundException")) {
          this.setState({show_missing_speaker: true});
        }
      });
    }
    if (this.props.speaker && this.props.open_to_video) {
      let video = _.head(_.filter(_.get(this.props.speaker, ["assets"]), {aid: this.props.open_to_video}));
      this.setState({
        video: video
      });
    }
    //this.props.saveProfileView(_.get(this.props.whitelabel, ["bid"])); //commented this because we were getting 2 saveProfileView calls per page load
    document.title = this.props.thispage_url;

    window.addEventListener("focus", this.__scrollListener);
  }

  componentDidUpdate(prevProps) {
    let old_sid = _.get(prevProps.match, ["params", "sid"]);
    let sid = _.get(this.props.match, ["params", "sid"]);
    if ((sid !== old_sid) && (sid !== _.get(this.props, ["speaker", "sid"]))) {
      let query = queryString.parse(this.props.location, ["search"]);
      let ignoresubscriptioncheck = (_.has(query, ["ignoresubscriptioncheck"]) || _.has(query, ["showunsubscribed"]));
      this.setState({
        sid: sid
      }, () => {
        // Load the speaker if we have it
        window.scrollTo(0, 0);
        this.props.loadProfile(this.state.sid, {bureau_id: _.get(this.props.whitelabel, ["bid"], Constants.TRUNK_BUREAU_ID), ignoresubscriptioncheck: ignoresubscriptioncheck})
        .catch((err) => {
          if (err.name.includes("SpeakerMissingException")) {
            this.setState({show_missing_speaker: true});
          } else if (err.name.includes("NotFoundException")) {
            this.setState({show_missing_speaker: true});
          }
        });
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("focus", this.__scrollListener);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.speaker && nextProps.open_to_video) {
      let video = _.head(_.filter(_.get(nextProps.speaker, ["assets"]), {aid: nextProps.open_to_video}));
      this.setState({
        video: video
      });
    }
  }

  __scrollListener = () => {
    if (_.isFunction(this.props.refreshEmbeddedShortlist)) {
      this.props.refreshEmbeddedShortlist();
    }
  };

  onVideoClick = (video) => {
    Analytics.sendEvent("Video", "play", _.get(video, ["aid"]), "profile");
    this.setState({
      video: video
    });

    let query = queryString.parse(window.location.search);
    query.opentovideo = _.get(video, ["aid"]);
    window.history.pushState(null, this.props.thispage_url, toMpURL(_.get(this.props.match, ["url"]) + "?" + queryString.stringify(query), this.props.whitelabel, true));
  };

  onVideoClose = () => {
    Analytics.sendEvent("Video", "stop", 0, "profile");
    this.setState({
      video: null,
    });

    let query = queryString.parse(window.location.search);
    _.unset(query, ["opentovideo"]);
    window.history.pushState(null, this.props.thispage_url, toMpURL(_.get(this.props.match, ["url"]) + "?" + queryString.stringify(query), this.props.whitelabel, true));
  };

  onUpdateLanguage = (lang) => {
    this.props.updateLanguage(lang);
  };

  onShowTab = (e, selected_tab, scroll_to_tab = false) => {
    let tab = _.get(e, ["target", "dataset", "tab"], selected_tab);
    Analytics.sendEvent("ProfileTab", "click " + tab, 0, "");
    // Scroll down to the tabs
    if (scroll_to_tab) {
      this.tabRef.current.scrollIntoView({
        behavior: 'smooth', block: 'start',
      });
    }
    this.setState({
      selected_tab: tab
    });
  };


  render() {
    let {
          is_loading,
          speaker,
          whitelabel,
          redirect_to_simple_site,
          redirect_to_headless,
          is_preview_mode,
          book_me_now_mode,
          simple_site_mode,
          headless_mode,
          is_shortlisted,
          addToShortlist,
          removeFromShortlist,
          onInquire,
          onUpdateLanguage,
          canonical_url
        } = this.props;

    let query = queryString.parse(window.location.search);

    if (this.state.show_missing_speaker) {
      return (<SpeakerMissingPage/>);
    }
    if (redirect_to_simple_site) {
      _.unset(query, ["simplesite"]);
      return (<Redirect to={"/profile/" + _.get(this.props.match, ["params", "sid"]) + "/simple?" + queryString.stringify(query) + window.location.hash}/>);
    } else if (redirect_to_headless) {
      _.unset(query, ["headless"]);
      return (<Redirect
        to={"/profile/" + _.get(this.props.match, ["params", "sid"]) + "/headless?" + queryString.stringify(query) + window.location.hash}/>);
    }

    let topic_id = _.get(query, ["topid"], -1);

    return (
      <div>
        <MetaTags
          speaker={speaker}
          whitelabel={whitelabel}
          canonical_url={canonical_url}
        />
        {speaker && <Profile
          speaker={speaker}
          topic_id={topic_id}
          is_preview_mode={is_preview_mode}
          book_me_now_mode={book_me_now_mode}
          simple_site_mode={simple_site_mode}
          headless_mode={headless_mode}
          is_shortlisted={is_shortlisted}
          onInquire={onInquire}
          onShortlistAdd={addToShortlist}
          onShortlistRemove={removeFromShortlist}
          onUpdateLanguage={onUpdateLanguage}
          onVideoClick={this.onVideoClick}
          selected_tab={this.state.selected_tab}
          onShowTab={this.onShowTab}
          tabRef={this.tabRef}
          {...this.props}
        />}
        {this.state.video && <div>
          <Helmet>
            <title>{_.get(speaker, ["name"]) + ": " + _.get(this.state.video, ["description"])}</title>
          </Helmet>
          <VideoPlayer
            video={this.state.video}
            speaker={speaker}
            whitelabel={whitelabel}
            onVideoClick={this.onVideoClick}
            onVideoClose={this.onVideoClose}
          />
        </div>}
        {is_loading && <LoadingIndicator/>}
      </div>
    );
  }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {

  let sid = _.toNumber(_.get(ownProps.match, ["params", "sid"]));

  let speaker = _.get(state, ["SpeakerFullProfiles", sid, "profile"], ownProps.speaker);
  let sl_sids = _.get(state, ["EmbeddedShortlist", "sids"], []);
  let is_shortlisted = _.includes(sl_sids, sid);

  let thispage_url = toMpURL("/profile/" + sid + "/", ownProps.whitelabel, true);
  let canonical_url = (ownProps.whitelabel.bureau_id !== Constants.TRUNK_BUREAU_ID) ? toMpURL("/profile/" + sid + "/", {bid: Constants.TRUNK_BUREAU_ID, whitelabel_domain: ""}, true) : ''; //if we're not trunk, put the canonical URL in the <head> linking to the trunk version
  let profile_language = _.get(state, ["ProfilePage", "profile_language"], getCurrentLanguage());

  let back_to_search = _.get(ownProps.location, ["state", "bts"], ("/search-resume?sid=" + sid));
  let back_to_search_text = _.get(ownProps.location, ["state", "btst"], __("BACK TO SEARCH"));

  let open_to_video = _.get(ownProps.qs, ["opentovideo"]);
  let redirect_to_simple_site = _.has(ownProps.qs, ["simplesite"], false);
  let book_me_now_mode = _.has(ownProps, "qs.bookmenow_full_profile") || _.get(ownProps, "book_me_now_mode", false);
  let redirect_to_headless = _.has(ownProps.qs, ["headless"], false);
  let back_to_search_close = (_.get(ownProps.qs, ["btsc"], "") === "1") ? () => {
    window.open("", "mp_search_results").focus();
    window.close();
  } : false;
  let directory_mode = _.get(ownProps.whitelabel, ["is_directory_mode"]);


  return {
    is_loading: (_.isUndefined(speaker)),
    speaker: speaker,
    is_shortlisted: is_shortlisted,
    thispage_url: thispage_url,
    canonical_url: canonical_url,
    profile_language: profile_language,
    back_to_search: back_to_search,
    back_to_search_text: back_to_search_text,
    redirect_to_simple_site: redirect_to_simple_site,
    book_me_now_mode: book_me_now_mode,
    redirect_to_headless: redirect_to_headless,
    back_to_search_close: back_to_search_close,
    directory_mode: directory_mode,
    open_to_video: open_to_video
  };
};

var mapDispatchToProps = function (dispatch, ownProps) {
  let sid = _.toNumber(_.get(_.get(ownProps.match, ["params", "sid"], "").match(/\d+/), [0], "")); //params.sid may contain something like "9426?topid=5"
  let book_me_now = _.get(ownProps, "book_me_now_mode", false);
  let simple_site = _.get(ownProps, "simple_site_mode", false);
  let norecord = _.get(ownProps, "norecord", _.includes(_.get(ownProps, "location.search"), Constants.NO_RECORD_HIT_STRING));
  let bid = _.get(ownProps, "whitelabel.bid", Constants.TRUNK_BUREAU_ID);

  return {
    loadProfile: function (sid, params) {
      return dispatch(SpeakerProfile.get(sid, _.assign({}, params, {nocache: true})))
      .then(() => {
          if (!norecord) {
            return dispatch(SpeakerProfile.saveProfileView({
              id: sid,
              bureau_id: bid,
              simple_site: simple_site,
              book_me_now: book_me_now,
              norecord: norecord
            }));
          } else {
            return;
          }
        }
      )
      .catch((err) => {
        throw err;
      });
    },
    addToShortlist: function () {
      Analytics.sendEvent("Shortlist", "add speaker", sid, "profile page");
      dispatch(EmbeddedShortlist_add(sid));
    },
    removeFromShortlist: function () {
      Analytics.sendEvent("Shortlist", "remove speaker", sid, "profile page");
      dispatch(EmbeddedShortlist_remove(sid));
    },
    onInquire: function () {
      Analytics.sendEvent("Shortlist", "add speaker", sid, "profile page");
      return new Promise((resolve) => {
        return resolve(dispatch(EmbeddedShortlist_add(sid)));
      }).then((resp) => {
        return ownProps.history.push("/postaprivatejob");
      });
    },
    saveProfileView: function (bid) {
      dispatch(SpeakerProfile.saveProfileView({
        id: sid,
        bureau_id: bid,
        book_me_now: book_me_now,
        simple_site: simple_site,
        norecord: norecord
      }));
    },
    onUpdateLanguage: function (lang) {
      return dispatch({
        type: "PROFILEPAGE_SET_LANGUAGE",
        lang: lang
      });
    },
    refreshEmbeddedShortlist: function () {
      return dispatch(EmbeddedShortlist_refresh());
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProfilePageA));

