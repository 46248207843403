import React from "react";
import { Route } from "react-router";
import StandardLayout from "../StandardLayout";

export default class StandardFullLayoutRoute extends React.Component {

    render() {
        return (
            <StandardLayout className={""} {...this.props}>
                <Route {...this.props} />
            </StandardLayout>
        );
    }

}