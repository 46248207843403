import React from "react";
import { gettext as __ } from "../../../../utils/Trans";

let img_gif = require("./sending_speakers.gif");

class SendingIndicator extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            is_ready_to_redirect
        } = this.props;
        return (
            <div>
                <div className="text-center">
                    <h2>{__("One Moment")}</h2>
                    <img src={img_gif} alt="sending" className="img-responsive center-block"/>
                    {is_ready_to_redirect ?
                        <h2>{__("All done, now let's take you to your event ...")}</h2>
                        :
                        <h2>{__("We are notifying your speakers about this event ...")}</h2>
                    }
                </div>
            </div>
        );
    }
}

export default SendingIndicator;