import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import SpeakerName from "../SpeakerName";
import Message from "../Message";

import "./Messages.scss";

export default class Messages extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            order = "asc",
            messages
        } = this.props;

        messages = _.sortBy(messages, "created");
        if (order === "desc") {
            messages = _.reverse(messages);
        }

        messages = _.map(messages, function (msg, i) {
            let name = null;
            if (msg.sender_speaker_id > 0 && !_.includes(_.get(msg, ["flags_as_array"], []), "system_log")) {
                name = <SpeakerName sid={msg.sender_speaker_id} />;
            }
            let is_marketplace_message = _.includes(_.get(msg, ["flags_as_array"], []), "system_log");
            let is_you = !name && !is_marketplace_message;
            let is_unread = !is_you && !msg.receiver_opened;

            return (
                <Message
                    key={_.get(msg, ["id"], i)}
                    sender_name={name}
                    is_you={is_you}
                    is_unread={is_unread}
                    message={msg}
                    timestamp={new Date(_.get(msg, ["created"]))}
                    content={_.get(msg, ["content"])}
                    is_marketplace_message={is_marketplace_message}
                />
            );
        });

        return (
            <div>
                <div className="MP-Messages">
                    <div style={{
                        position: "relative", /*fixes scroll hints*/
                    }}>
                        {messages}
                    </div>
                </div>
            </div>
        );
    }
}

Messages.propTypes = {
    order: PropTypes.string,
    messages: PropTypes.array
};