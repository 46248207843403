import _ from "lodash";


/**
 * Given a budget value from the event in dollars ("7500"), convert that into
 * a dropdown value in the form of "range_1", "range_7", etc.
 *
 * @param event
 * @param budget_ranges
 * @returns {*}
 */
export function getRangeIndexFromEvent(event, budget_ranges) {
    if (!_.has(event, "budget_min")) return null;

    let i = null;
    let budget_max = _.get(event, ["budget_max"], -1);
    let budget_min = _.get(event, ["budget_min"], -1);

    _.each(budget_ranges, function (range, ri) {
        if (null === i) {
            if ((-1 === range.min) && (-1 === budget_max)) { //special case of do not know
                i = ri;
            } else if ((0 === range.max) && (0 === budget_max) && (0 === budget_min)) { //special case of free
                i = ri;
            } else if (budget_min < range.max) {
                i = ri;
            }
        }
    });
    if (i !== null) {
        return "range_" + i;
    }

    return null;
}


/**
 * Convert a dropdown value in the form of "range_0", "range_1" (or 0 or 1) into
 * a min-max pair from the budget_ranges array.
 *
 * The value may also come in as "5" or "1".
 *
 * @param dropdownvalue
 */
export function setEventFromRangeIndex(dropdownvalue, budget_ranges) {

    if (_.isUndefined(dropdownvalue) || _.isNull(dropdownvalue)) {
        dropdownvalue = "range_0";
    }

    if (!_.isNumber(dropdownvalue)) {
        //extract "2" from  "range_2"
        dropdownvalue = _.parseInt(dropdownvalue.match(/\d+/)[0]);
    }

    let r = _.get(budget_ranges, dropdownvalue, {min: -1, max: -1});

    return r;

}