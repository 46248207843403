import React from "react";
import PropTypes from "prop-types";

import "./LoadingIndicator.scss";

/**
 * CSS is loaded from public/css/loader.css because this loader is used in php pages as well
 */
class LoadingIndicator extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    shouldComponentUpdate() {
        return false;
    }

    render() {
        let {
            is_modal = false,
            overlay = false,
            relative = false
        } = this.props;

        let overlay_class = "";

        if (overlay) {
            overlay_class = ("loader-overlay" + (is_modal ? "-modal" : ""));
        }

        return (
            <div className={overlay_class}>
                <div className={"loader" + (relative ? "-relative" : "")}>
                    <svg className="circular" viewBox="25 25 50 50">
                        <circle className="path" cx="50" cy="50" r="20" fill="none" strokeWidth="2" strokeMiterlimit="10"/>
                    </svg>
                </div>
            </div>
        );
    }
}

LoadingIndicator.propTypes = {
    is_modal: PropTypes.bool,
    overlay: PropTypes.bool,
    relative: PropTypes.bool
};

export default LoadingIndicator;