import _ from "lodash";
import phpEncode from "urlencode-for-php";
import fetch from "isomorphic-fetch";
import {
    TokenException,
    AuthorizationException,
    AuthenticationException,
    SpeakerMissingException,
    NotFoundException
} from "../exceptions";

/**
 * Balboa3 sends API requests to the Balboa server.  It can handle GET, POST, PUT, and DELETE or send a generic
 * request using balboa3.ajax(path, params, method)
 * Configuration options are:
 *   base_url:          Base Url to use (defaults to /balboa3)
 *   default_params:    Default parameters to add to every request (for authentication tokens, etc)
 */
export default function(cfg) {
    let config = cfg || {};

    let default_params = _.get(config, ["default_params"], {});

    let base_url = _.get(config, ["base_url"], _.get(default_params, ["base_url"], "/balboa3"));

    return {
        del: function(path, params) { // use "del" instead of "delete" because "delete" is a reserved javascript word
            return this.ajax(path, params, "DELETE");
        },
        put: function(path, params) {
            return this.ajax(path, params, "PUT");
        },
        get: function(path, params) {
            return this.ajax(path, params, "GET");
        },
        post: function(path, params) {
            return this.ajax(path, params, "POST");
        },
        ajax: function(path, params, method) {
            let options = {
                method: method,
                credentials: "include"
            };
            let headers = {};
            let body;
            let qs = "";
            if (_.isString(params)){
                body = _.assign({}, default_params, params);
            } else if (_.find(_.values(params), function(v){
                    return v instanceof File;
                })){
                body = new FormData();
                _.forEach(_.assign({}, default_params, params), function(val, key){
                    body.append(key, val);
                });
            } else {
                headers["Content-Type"] = "application/x-www-form-urlencoded";
                if (method === "POST" || method === "PUT") {
                    body = phpEncode(_.assign({}, default_params, params));
                } else {
                    body = null;
                    qs = ("?" + phpEncode(_.assign({}, default_params, params)));
                }
            }

            let url = base_url + path + qs;
            options.headers = headers;
            if (!_.isNull(body)) {
                options.body = body;
            }

            return fetch(url, options).then((response) => {
                    // Attempt to parse the response as JSON
                    if (response.headers.get("content-type") !== "application/json") {
                        return response.text().then((resp) => {
                            let err = new Error("Response is not properly formatted JSON: " + resp, path);
                            err.balboa3_data = resp;
                            err.name = _.get(resp, ["exception_type"], "Exception");
                            throw err;
                        });
                    }
                    return response.json();
                }).catch((resp) => {
                    console.error(resp, path);
                    return Promise.reject(resp);
                }).then((json) => {
                    // Look for errors in the response and parse them out
                    if(!_.has(json, "success") || (_.get(json, ["success"], false) === false)) {
                        let err_msgs = [];
                        if (_.has(json, ["error"])) {
                            err_msgs.push(_.get(json, ["error"]));
                        }
                        if (_.has(json, ["message"])) {
                            err_msgs.push(_.get(json, ["message"]));
                        }
                        if (_.has(json, ["data"])) {
                            _.each(_.get(json, ["data"]), function(err) {
                                err_msgs.push(_.get(err, ["message"]));
                            });
                        }
                        if (_.has(json, ["exception_type"])) {
                            if (_.get(json, ["exception_type"]) === "authenticationException") {
                                // throw new AuthenticationException("Your session has expired.  Please log in again.");
                                return Promise.reject(new AuthenticationException("Your session has expired.  Please log in again."));
                            } else if (_.get(json, ["exception_type"]) === "AuthenticationException") {
                                // throw new AuthenticationException(_.join(err_msgs, ", "));
                                return Promise.reject(new AuthenticationException(_.join(err_msgs, ", ")));
                            } else if (_.get(json, ["exception_type"]) === "AuthorizationException") {
                                // throw new AuthorizationException(_.join(err_msgs, ", "));
                                return Promise.reject(new AuthorizationException(_.join(err_msgs, ", ")));
                            } else if (_.get(json, ["exception_type"]) === "TokenException") {
                                // throw new TokenException("Your session has expired.  Please log in again.");
                                return Promise.reject(new TokenException("Your session has expired.  Please log in again."));
                            } else if (_.get(json, ["exception_type"]) === "SpeakerMissingException") {
                                // throw new SpeakerMissingException(_.join(err_msgs, ", "));
                                return Promise.reject(new SpeakerMissingException(_.join(err_msgs, ", ")));
                            } else if (_.get(json, ["exception_type"]) === "NotFoundException") {
                              // throw new NotFoundException(_.join(err_msgs, ", "));
                              return Promise.reject(new NotFoundException(_.join(err_msgs, ", ")));
                            } else if (_.get(json, ["exception_type"]) === "ES\\Exception\\NotFoundException") {
                              // throw new NotFoundException(_.join(err_msgs, ", "));
                              return Promise.reject(new NotFoundException(_.join(err_msgs, ", ")));
                            }
                        }
                        return Promise.reject(new Error(_.join(err_msgs, ", ")));
                        // throw new Error(_.join(err_msgs, ", "));
                    }
                    return json;
                });

        }
    };
}
