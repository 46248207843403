import _ from "lodash";
import React from "react";
import { gettext as __ } from "../../utils/Trans";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { Password } from "../../components/FormComponents";
import ResetUserPassword from "../../actions/User/ResetUserPassword";

export class ResetPasswordPage extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch, props)
    {
        return dispatch(ResetUserPassword(values))
            .then(() => {
                props.history.push("/user/edit");
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render()
    {
        let {
            handleSubmit,
            submitSucceeded,
            submitting,
            error
        } = this.props;

        return (
            <div>
                <div className="MP-User" style={{margin: "30px 0"}}>
                    <h1 className=" page-header">
                        {__("RESET PASSWORD")}
                    </h1>
                    <Form className="mp-style-form" onSubmit={handleSubmit(this.handleSubmit)}>
                        <div className="help-block">
                            {__("You have requested to reset your password on your account.  You can enter a new password below.  The new password needs to be at least 6 characters long and contain at least 1 number.")}
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <Field
                                    name="password"
                                    component={Password}
                                    label="New Password"
                                    help_text={__("Enter your new password here.  Your password should be at least 8 characters and contain a letter and number.")}
                                    placeholder="Password"
                                    autocomplete="on"
                                    autofill="on"
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-4 col-sm-12">
                                <Field
                                    name="password2"
                                    component={Password}
                                    label="Confirm Password"
                                    help_text={__("Confirm your new password here.  Your password should be at least 8 characters and contain a letter and number.")}
                                    placeholder="Confirm Password"
                                    autocomplete="on"
                                    autofill="on"
                                />
                            </div>
                        </div>

                        <div className="form-group">
                            <button type="submit" className="btn btn-success btn-lg" disabled={submitting}>
                                {submitting ? <i className="fa fa-spin fa-circle-o-notch" /> : <i/>} {__("Reset Password")}
                            </button>
                            {submitSucceeded && <span className="text-success"> <i className="fa fa-check" /> {__("Changes Saved")}</span>}
                            {error && <span className="text-danger"> <i className="fa fa-times" /> {_.get(error, ["message"])}</span>}
                        </div>
                    </Form>
                </div>
            </div>
        );
    }
}

ResetPasswordPage = reduxForm({
    form: "reset_password_page",
    validate: function(values) {
        const errors = {};
        if (!values.password) {
            errors.password = __("Please enter a new password.");
        }
        if (!values.password2) {
            errors.password2 = __("Please enter a new password.");
        }
        if (values.password !== values.password2) {
            errors.password2 = __("The passwords do not match, please check them.");
        }
        return errors;
    }
})(ResetPasswordPage);

////////////////////////////////////////////////////////////////////////////////
//Connect it up

function mapStateToProps(state, ownProps) {
    return {
        initialValues: {
            id: _.get(ownProps.user, ["id"])
        }
    };
}

function mapDispatchToProps() {
    return {

    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPasswordPage));