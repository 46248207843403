/**
 * Normally, you would extend a class like this:
 *
 * class ProfilePageB extends ProfilePageA {}
 *
 * But, we don't want to do that here because ProfilePageA needs to mount with
 * specific code that we don't want to duplicate every time.  So instead, we
 * wrap it in this Higher Order Component (HOC).  Then ProfilePageB calls it's
 * componentDidMount() and then renders ProfilePageA (which calls it's componentDidMount()).
 *
 * Note: ProfilePageA does NOT fire any analytics in it's componentDidMount because it is
 * really a base/default class.  If it were renamed, it would be like this:
 *
 * ProfilePageBase.js (currently called ProfilePageA.js)
 *  \- ProfilePageA.js (new file that looks like this one)
 *  \- ProfilePageB.js (currently called ProfilePageB.js)
 *
 * I haven't done this yet because there are other ways to handle A/B testing.  For refererence,
 * check out SearchPage and HomePage examples.
 */
import React from "react";
import ProfilePageA from "./ProfilePageA";

export default class ProfilePageB extends React.Component {
    render() {
        return (<ProfilePageA {...this.props} />);
    }
}