import _ from "lodash";
import React from "react";
import PlacesAutocomplete from "react-places-autocomplete";

import "./Location.scss";

class Location extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            address: _.get(props.input, ["value"], ""),
            errorMessage: ""
        };
    }

    handleChange = (address) => {
        this.setState({
            address
        });
    };

    handleSelect = (selected) => {
        if (this.props.input && _.isFunction(this.props.input.onChange)) {
            this.props.input.onChange(selected);
            this.setState({
                address: selected
            });
        }
    };

    handleCloseClick = () => {
        this.setState({
            address: ""
        });
    };

    handleError = (status, clearSuggestions) => {
        console.log("Error from Google Maps API", status); // eslint-disable-line no-console
        this.setState({ errorMessage: status }, () => {
            clearSuggestions();
        });
    };

    render() {
        let {
            input,
            label,
            help_text,
            meta: {
                touched,
                error,
                warning
            }
        } = this.props;
        let {
            address
        } = this.state;

        return (
            <div>
                <div className={"form-group " + (touched && error ? "has-error " : touched && warning ? "has-warning " : "")}>
                    {label && <label className="control-label">{label}</label>}
                    <div style={{position: "relative"}}>
                        <PlacesAutocomplete
                            onChange={this.handleChange}
                            value={(_.isEmpty(address)) ? "" : address}
                            onSelect={this.handleSelect}
                            onError={this.handleError}
                            shouldFetchSuggestions={(!_.isEmpty(address) && (address.length > 2))}
                        >
                            {({ getInputProps, suggestions, getSuggestionItemProps }) => {
                                return (
                                    <div className="Location__search-bar-container">
                                        <div className="Location__search-input-container">
                                            <input
                                                {...getInputProps({
                                                    placeholder: "Search Places...",
                                                    className: "form-control",
                                                })}
                                            />
                                            {!_.isEmpty(this.state.address) > 0 && (
                                                <button
                                                    className="Location__clear-button"
                                                    onClick={this.handleCloseClick}
                                                >
                                                    x
                                                </button>
                                            )}
                                        </div>
                                        {suggestions.length > 0 && (
                                            <div className="Location__autocomplete-container">
                                                {suggestions.map(suggestion => {
                                                    let className = "Location__suggestion-item";
                                                    if (suggestion.active) {
                                                        className += " Location__suggestion-item--active";
                                                    }
                                                    return (
                                                        /* eslint-disable react/jsx-key */
                                                        <div
                                                            {...getSuggestionItemProps(suggestion, { className })}
                                                        >
                                                            <strong>
                                                                {suggestion.formattedSuggestion.mainText}
                                                            </strong>{' '}
                                                            <small>
                                                                {suggestion.formattedSuggestion.secondaryText}
                                                            </small>
                                                        </div>
                                                    );
                                                    /* eslint-enable react/jsx-key */
                                                })}
                                                <div className="Location__dropdown-footer">
                                                    Powered by Google
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                );
                            }}
                        </PlacesAutocomplete>
                    </div>
                    {help_text && <div className="help-block">{help_text}</div>}
                    {touched &&
                    ((error && <div className="text-danger"><i className="fa fa-times" /> {error}</div>) ||
                        (warning && <div className="text-danger"><i className="fa fa-exclamation-triangle" /> {warning}</div>))}
                </div>
            </div>
        );
    }
}

export default Location;