import _ from "lodash";
import Balboa from "../Global/Balboa";

export default function(params){
    return (dispatch, getState) => {
        var key = ["readRecommendation", params.event_id, params.speaker_id];

        return Balboa(dispatch, getState)
            .post(key, "/speaker/recommendation/-1/read", {
                event_id:        params.event_id,
                speaker_id:      params.speaker_id,
                blank_on_not_found: true
            }, {cache_ttl: -1})
            .then(function(response) {
                return dispatch({
                    type: "BUYER_EVENT_REVIEW_LOAD",
                    eid: params.event_id,
                    sid: params.speaker_id,
                    data: _.get(response, ["data"])
                });
            })
            .then(function(response) {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            });
    };
};
