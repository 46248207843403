import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Form, reduxForm, SubmissionError } from "redux-form";
import PostAJob_SavePendingJob from "../../../../actions/PostAJobPage/PostAJob_SavePendingJob";
import SpeakerAvailabilityAction from "../../../../actions/Speaker/SpeakerAvailability";
import moment from "moment/moment";
import { Button } from "react-bootstrap";
import { gettext as __ } from "../../../../utils/Trans";
import SpeakerAvailabilityTable from "../SpeakerAvailabilityTable";

class SpeakerAvailabilityForm extends React.Component {

    handleSubmit = (values, dispatch) => {
        let dates = _.split(_.get(values, ["startdate"]), "-");
        let params = {
            ids: _.get(values, ["shortlist_sids"]),
            startdate: _.trim(_.head(dates)),
            enddate: _.trim(_.last(dates))
        };
        return dispatch(SpeakerAvailabilityAction.find(params))
            .then((response) => {
                return dispatch(PostAJob_SavePendingJob(params));
            })
            .then((response) => {
                if (_.isFunction(this.props.onSearchSuccess)) {
                    return this.props.onSearchSuccess(values);
                }
            }).catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    __onChangeDate = (e, value) => {
        // if (_.isFunction(this.props.onChangeDate)) {
        //     return this.props.onChangeDate(value);
        // }
    }

    render() {
        let {
            selected_sids,
            handleSubmit,
            submitting
        } = this.props;

        return (
            <Form onSubmit={handleSubmit(this.handleSubmit)}>
                <SpeakerAvailabilityTable {...this.props} />
                <div>
                    <Button bsSize="lg" bsStyle="success" type="submit" disabled={submitting || (_.size(selected_sids) === 0)}>{__("Write a message to %s", [(_.size(selected_sids) === 1) ? "this Speaker" : ("these " + _.size(selected_sids) + " Speakers")])}</Button>
                </div>
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {

    return {
        initialValues: {
            shortlist_sids: ownProps.shortlist_sids,
            startdate: ownProps.date_range
        }
    };
};

var mapDispatchToProps = function (/* dispatch, ownProps */) {
    return {};
};

SpeakerAvailabilityForm = reduxForm({
    form: "paj_affiliate_speaker_availability_form",
    validate: function (values) {
        const errors = {};
        if (!values.startdate) {
            errors.startdate = __("Please enter a valid date to check.");
        }
        // Check just the start date of the date range
        let startdate = _.head(_.split(values.startdate, " - "));
        if (!moment(startdate, "M/D/Y").isValid()) {
            errors.startdate = __("Please enter a valid date to check.");
        }
        if (moment(startdate, "M/D/Y").isValid() && (moment(startdate, "M/D/Y").isSameOrBefore(moment(), 'DAY'))) {
            errors.startdate = __("Please enter a date in the future to check.");
        }

        return errors;
    }
})(SpeakerAvailabilityForm);

export default connect(mapStateToProps, mapDispatchToProps)(SpeakerAvailabilityForm);

