import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import EditEventModal from "../../modals/EditEventModal";

class EventDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_edit_event_modal: false,
        };
    }

    __toggleEditEventModal = () => {
        this.setState({
            show_edit_event_modal: !this.state.show_edit_event_modal
        });
    }

    render() {
        let {
            event,
            allow_event_changes = false
        } = this.props;

        let organization = _.get(event, ["organization"]);
        let meeting_name = _.get(event, ["meetingname"]);
        let delivery_method = _.first(_.get(event, ["deliveryMethod"]));
        let venue = _.first(_.get(event, ["Venue"]));
        let city = _.get(venue, ["city"]);
        let state = _.get(venue, ["state"]);

        return (
            <div>
                <h1 className=" hidden-xs hidden-sm" style={{marginTop: "10px"}}>
                    <span className="small">{__("Shortlist for")}</span>
                    <div>{organization}</div>
                </h1>
                <h3 className=" visible-xs visible-sm">
                    <span className="small">{__("Shortlist for")}</span>
                    <div>{organization}</div>
                </h3>
                <div style={{paddingLeft: "10px", display: "block"}}>
                    <div><i className="fa fa-calendar-check-o"></i> {meeting_name}
                        {delivery_method === 1 &&
                        <p><i className="fa fa-map-marker"></i> {city}{", "}{state}</p>
                        }
                        {delivery_method === 2 &&
                        <p><i className="fa fa-video-camera"></i> {__("Via Video/Web")}</p>
                        }
                        {delivery_method === 3 &&
                        <p><i className="fa fa-phone"></i> {__("Via Phone")}</p>
                        }
                        {allow_event_changes && <span className="link" style={{marginLeft: "1em"}} onClick={this.__toggleEditEventModal}>{__("change details")}</span>}
                    </div>
                </div>
                {this.state.show_edit_event_modal && <EditEventModal event={event} {...this.props} onClose={this.__toggleEditEventModal} />}
            </div>
        );
    }
}

EventDetails.propTypes = {
    event: PropTypes.object.isRequired,
    allow_event_changes: PropTypes.bool
};

export default EventDetails;
