import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { AgreementText, EventInvoice } from "./components";
import LoadingIndicator from "../../components/LoadingIndicator";
import Whitelabel from "../../actions/Global/Whitelabel";
import Event from "../../actions/Event/Event";
import getMPEvent from "../../actions/Event/getMPEvent";
import getMPShortlist from "../../actions/ShortList/getMPShortlist";
import getMPAgreementsForEvent from "../../actions/Event/getMPAgreementsForEvent";
import getMPPaymentsForEvent from "../../actions/Event/getMPPaymentsForEvent";
import Message from "../../actions/Messages/Message";
import Speaker from "../../actions/Speaker/Speaker";
import GetStripeCustomer from "../../actions/User/GetStripeCustomer";

export class CollabBillingPage extends React.Component {

    componentDidMount() {
        if (!this.props.event) {
            let eid = _.get(this.props.match, ["params", "eid"]);

            this.props.loadData(eid);
        }
    }

    render() {
        let {
            is_loading,
            event,
            speaker,
            agreement,
            payment,
            user,
            is_espeakers
        } = this.props;

        return (
            <div>
                {!is_loading &&
                    <div>
                        <EventInvoice
                            event={event}
                            speaker={speaker}
                            user={user}
                            agreement={agreement}
                            payment={payment}
                            is_espeakers={is_espeakers}
                            {...this.props}
                        />
                        {_.isNull(_.get(agreement, ["file_hash"])) && <AgreementText/>}
                    </div>
                }
                {is_loading && <LoadingIndicator />}
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {

    let eid = _.toNumber(_.get(ownProps.match, ["params", "eid"]));
    // event objects
    let event = _.get(state, ["Events", "events", eid]);
    let sid = _.toNumber(_.get(event, ["sids", 0]));

    let payment = _.get(event, ["Services"]);
    let shortlist = _.get(event, ["Shortlists", 0]);

    // event/speaker objects
    let agreement = _.get(event, ["Agreements", sid]);
    let speaker = _.get(state, ["Speakers", sid]);

    return {
        is_loading: (_.isUndefined(event) || _.isUndefined(ownProps.user) || _.isUndefined(agreement) || _.isUndefined(payment) || _.isUndefined(shortlist) || _.isUndefined(speaker)),
        payment: payment,
        event: event,
        shortlist: shortlist,
        speaker: speaker,
        agreement: agreement,

        is_espeakers: !!(ownProps.whitelabel && _.get(ownProps.whitelabel, ["is_espeakers"]))
    };
};

var mapDispatchToProps = function (dispatch, ownProps) {
    return {
        loadStripeCustomer: function () {
            dispatch(GetStripeCustomer());
        },
        loadEvent: function (eid) {
            return dispatch(getMPEvent(eid));
        },
        loadBureau: function (bid) {
            return dispatch(Whitelabel.get(bid));
        },
        loadAgreements: function (eid) {
            return dispatch(getMPAgreementsForEvent(eid));
        },
        loadPayments: function (eid) {
            return dispatch(getMPPaymentsForEvent(eid));
        },
        loadMessages: function (eid) {
            return dispatch(Message.findByEvent(eid));
        },
        loadShortlist: function (eid) {
            return dispatch(getMPShortlist(eid))
                .then((response) => {
                    let sids = _.get(response, ["data", "sids"])
                    return dispatch(Speaker.find({
                        ids: sids
                    }));
                });
        },
        loadData: function (eid) {
            if (!_.isNull(_.get(ownProps.user, ["stripe_cust_token"], null))) {
                dispatch(GetStripeCustomer())
                    .catch(() => {});
            }
            return dispatch(Event.get(eid))
                .then((response) => {
                    let sids = _.get(response, ["data", "sids"]);
                    if (_.size(sids) > 0) {
                        return dispatch(Speaker.find({
                            ids: sids
                        }));
                    }
                });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollabBillingPage);