import _ from "lodash";
import React from "react";
import { gettext as __ } from "../../utils/Trans";
import AccountForm from "./AccountForm";
import PaymentForm from "./PaymentForm";
import ChangePasswordForm from "./ChangePasswordForm";
import UnreadBadge from "../../components/UnreadBadge";
import UserLink from "./UserLink";

import "./User.scss";

export class UserPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            tab: window.location.hash || "#edit"
        };
    }

    __onToggleTab = tab => {
        this.setState({
            tab: tab
        });
    };

    render() {
        let { sitelists, user } = this.props;

        let user_needs_attention =
            _.isNull(_.get(user, ["phone_number"])) ||
            _.isNull(_.get(user, ["email"]));

        let my_account_btn = (
            <span>
                {__("My Account")}{" "}
                {user_needs_attention && (
                    <span className="pull-right">
                        <UnreadBadge
                            n_unread={1}
                            style={{ position: "relative" }}
                        />
                    </span>
                )}
            </span>
        );

        return (
            <div>
                <div className="MP-User" style={{ margin: "30px 0" }}>
                    <h1 className=" page-header">{__("ACCOUNT SETTINGS")}</h1>
                    <div className="row">
                        <div className="col-sm-3">
                            <ul className="MP-UserList">
                                <UserLink
                                    label={my_account_btn}
                                    sub_view="#edit"
                                    is_selected={this.state.tab === "#edit"}
                                    onClick={this.__onToggleTab}
                                />
                                <UserLink
                                    label={__("Password Settings")}
                                    sub_view="#login"
                                    is_selected={this.state.tab === "#login"}
                                    onClick={this.__onToggleTab}
                                />
                                <UserLink
                                    label={__("Payment Methods")}
                                    sub_view="#payment"
                                    is_selected={this.state.tab === "#payment"}
                                    onClick={this.__onToggleTab}
                                />
                                {/*<UserLink label={__("Notifications")} sub_view="notifications" is_selected={this.state.tab === "notifications"} onClick={this.__onToggleTab} />*/}
                            </ul>
                        </div>
                        <div className="col-sm-9">
                            {this.state.tab === "#edit" && user && (
                                <AccountForm
                                    user={user}
                                    sitelists={sitelists}
                                />
                            )}
                            {this.state.tab === "#login" && user && (
                                <ChangePasswordForm user={user} />
                            )}
                            {this.state.tab === "#payment" && user && (
                                <PaymentForm user={user} />
                            )}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default UserPage;
