import _ from "lodash";
import React from "react";
import moment from "moment";
import { gettext as __ } from "../../../../utils/Trans";
import ShareShortlistModal from "../../modals/ShareShortlistModal";
import SpeakerReviewModal from "../../modals/SpeakerReviewModal";
import SpeakerRating from "../../../../components/SpeakerRating";

import "./CollaboratorSummary.scss";

export default class CollaboratorSummary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_share_modal: false,
            show_review_modal: false
        };
    }

    __toggleShareModal = () => {
        this.setState({
            show_share_modal: !this.state.show_share_modal
        });
    }

    __toggleReviewModal = () => {
        this.setState({
            show_review_modal: !this.state.show_review_modal
        });
    }

    render() {
        let {
            user,
            speaker,
            shortlist
        } = this.props;

        let shortlist_speaker = _.head(_.filter(_.get(shortlist, ["speakers"]), {speaker_id: _.get(speaker, ["sid"])}));

        let ratings = _.get(shortlist_speaker, ["ratings"]);
        let collaborators = _.get(shortlist, ["collaborators"]);
        let my_collaborator = _.head(_.filter(collaborators, (item) => {
            return _.get(item, ["email"]) === _.get(user, ["email"]);
        }));

        return (
            <div className="shortlist-colleague-rating">
                <h4>{__("See what your colleagues have said about %s", [_.get(speaker, ["name"], "this speaker")])}</h4>
                <div className="row">
                    <div className="col-sm-3">
                        <SpeakerRating
                            collaborator={my_collaborator}
                            shortlist_speaker={shortlist_speaker}
                            collaborators={collaborators}
                            onToggleReviewModal={this.__toggleReviewModal}
                        />
                    </div>
                    <div className="col-sm-9">
                        {!_.isEmpty(ratings) &&
                            <div className="row">
                                {!_.isEmpty(ratings) && _.map(ratings, (spkr_rating, i) => {
                                    let reviewer = _.head(_.filter(collaborators, {id: _.get(spkr_rating, ["mp_collaborator_id"])}));

                                    return (
                                        <div key={i} className="col-sm-6" style={{padding: "5px"}}>
                                            <div>
                                                {_.map([1, 2, 3, 4, 5], (rating_num, k) => {
                                                    return (<i key={k} className={"fa fa-star" + (rating_num <= _.get(spkr_rating, ["rating"]) ? "" : "-o")}/>);
                                                })}
                                            </div>
                                            <div>{_.get(spkr_rating, ["comments"])}</div>
                                            <div className="small">
                                                <div><i>{_.get(reviewer, ["email"])}</i></div>
                                                <div><i>{moment(_.get(spkr_rating, ["created_ISO8601"])).format("MMM D, YYYY")}</i></div>
                                            </div>
                                        </div>
                                    );
                                })}
                            </div>
                        }
                        {_.isEmpty(ratings) && collaborators && <div className="text-left text-muted">There are no reviews from your collaborators. Invite them to leave their comments on your speakers or invite additional people to collaborate with you.</div>}
                        {_.isEmpty(ratings) && !collaborators && <div className="text-left text-muted">You have no collaborators working with you. Invite others to review your shortlist and leave their thoughts about these speakers.</div>}
                        <br />
                        <div className="text-center">
                            <div className="btn btn-default" onClick={this.__toggleShareModal}>{__("Invite others to collaborate")}</div>
                        </div>
                    </div>
                </div>
                {this.state.show_share_modal && <ShareShortlistModal {...this.props} onClose={this.__toggleShareModal} />}
                {this.state.show_review_modal && <SpeakerReviewModal {...this.props} shortlist_speaker={shortlist_speaker} collaborator={my_collaborator} onClose={this.__toggleReviewModal} />}
            </div>
        );
    }

}