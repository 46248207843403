import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { gettext as __ } from "../../../../utils/Trans";
import LoginForm from "../../LoginForm";
import Analytics from "../../../../utils/Analytics";

let img = require("../../choose-speaker.jpg");

class PostAJobPrivate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    SignupOnclick() {
        Analytics.sendEvent("BuyerSignup", "click", 0, "signin_form");
    }

    render() {

        let {
            location
        } = this.props;

        return (
            <div>
                <div className="visible-xs-block" style={{marginTop:"4.5em"}}></div>
                <h1 className=" page-header" style={{fontSize: "52px", marginBottom: "40px"}}>
                    {__("You're almost there!")}
                    {" "}
                    <span className="small" style={{fontSize: "14px"}}>{__("Need an account?")}{" "}<Link
                        to={_.assign({}, location, {pathname: "/signup"})} onClick={this.SignupOnclick.bind(this)}>{__("Sign Up")}</Link></span>
                </h1>
                <div className="row hidden-xs hidden-sm">
                    <div className="col-md-5">
                        <img src={img} alt="choose speaker" className="img-responsive img-rounded hidden-sm hidden-xs"/>
                        <h2>{__("Get Results Fast!")}</h2>
                        <br/>
                        <h4 className="help-block hidden-bureaumode">
                            {__("Create an account and your job will be shown to")}{" "}<b>{__("OVER 10,000 SPEAKERS")}</b>{" "}{__("through our online marketplace!")}
                        </h4>
                        <br/>
                        <div className="help-block hidden-bureaumode">
                            <p>
                                {__("Enjoy the benefits of an eSpeakers Marketplace account today. Not only can you find the perfect speakers, but you are getting our industry-leading experience. Stay secure and let us handle all the arrangements through our easy to use meeting process.")}
                            </p>
                            <p>
                                {__("eSpeakers Marketplace offers secure payments made through our site.  Secure your chosen speaker with a small deposit today.  With our secure checkout, speakers are notified immediately.  You'll never have to hear \"The check is in the mail\" again.")}
                            </p>
                            <p>
                                {__("First time here?")}
                            </p>
                            <p>
                                {__("Create a")}{" "}<b><Link to={_.assign({}, location, {pathname: "/signup"})} onClick={this.SignupOnclick.bind(this)}>{__("new account")}</Link></b>{" "}{__("with us today.")}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-md-offset-1">
                        <LoginForm {...this.props} />

                        <div className="help-block instruction hidden-bureaumode">
                            <p>{__("Your call for speakers will be posted to the largest group of experts who speak in the world. You will receive up to 10 candidates to interview and decide which to hire.")}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PostAJobPrivate;
