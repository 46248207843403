import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { gettext as __ } from "../../../../utils/Trans";
import { Message } from "../../../../components/FormComponents";
import LoadingSubmit from "../../../../components/LoadingSubmit";
import InlineError from "../../../../components/InlineError";
import withdrawOffer from "../../../../actions/ShortList/withdrawOffer";
import { ProposeDifferentTermsButton } from "../../components/Buttons";

class WithdrawOfferForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch, props) {
        return dispatch(withdrawOffer(values))
            .then(() => {
                if (_.isFunction(props.onClose)) {
                    props.onClose();
                }
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
            onClose,
            handleSubmit,
            submitting,
            error
        } = this.props;
        
        return (
            <Form role="form" onSubmit={handleSubmit(this.handleSubmit)}>
                <div className="modal-body">
                    {error && <InlineError error={_.get(error, ["message"])} />}
                    <p>
                        {__("Before you withdraw your offer, did you know that you can always propose different terms to the speaker?")}
                    </p>
                    <div className="text-center">
                        <ProposeDifferentTermsButton {...this.props} disabled={submitting} className="btn btn-info btn-flat btn-block" />
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-sm-12">
                            <p>
                                {__("If you have made a mistake, you can withdraw your current offer from this speaker.  We ask that you notify the speaker and let them know why you are withdrawing your offer.")}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <Field
                                name="message"
                                type="textarea"
                                rows="4"
                                className="form-control"
                                placeholder={__("I am withdrawing my offer because ...")}
                                component={Message}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <LoadingSubmit is_saving={submitting} save_text={__("Withdrawing...")} className="btn-danger btn-flat btn-block-xs">
                        {__("Withdraw Offer")}
                    </LoadingSubmit>
                    {" "}
                    <div className="btn btn-default btn-block-xs" disabled={submitting} onClick={onClose}>
                        {__("Never Mind")}
                    </div>
                </div>
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

WithdrawOfferForm.propTypes = {
    event: PropTypes.object.isRequired,
    speaker: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

function mapStateToProps(state, ownProps) {
    return {
        initialValues: {
            message: "",
            event_id: _.get(ownProps.event, ["eid"]),
            speaker_id: _.get(ownProps.speaker, ["sid"]),
        }
    };
}

function mapDispatchToProps() {
    return {

    };
}

WithdrawOfferForm = reduxForm({
    form: "shortlist_page_withdraw_offer",
    validate: function(values) {
        const errors = {};
        if (!values.message) {
            errors.message = __("Please enter a message about why you are withdrawing your offer.");
        }
        return errors;
    }
})(WithdrawOfferForm);

export default connect(mapStateToProps, mapDispatchToProps)(WithdrawOfferForm);