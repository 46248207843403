import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import CancelEventModal from "../../modals/CancelEventModal";
import ActionButton from "../../../../components/ActionButton";

class CancelEventButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_modal: false
        };
    }

    __toggleModal = () => {
        this.setState({
            show_modal: !this.state.show_modal
        });
    }

    render() {
        let {
            event,
            className = "btn btn-default btn-flat"
        } = this.props;

        return (
            <div>
                <ActionButton
                    icon="trash-o"
                    btn_text={__("Cancel this Job")}
                    help_text={__("Not going to have the event after all? We will let the candidates know it is not happening.")}
                    onClick={this.__toggleModal}
                    className={className}
                />
                {this.state.show_modal && event && <CancelEventModal event={event} {...this.props} onClose={this.__toggleModal} />}
            </div>
        );
    }
}

CancelEventButton.propTypes = {
    event: PropTypes.object.isRequired,
    className: PropTypes.string
};

export default CancelEventButton;