import React from "react";

export default class Text extends React.Component {

    render() {
        let {
            input,
            disabled = false,
            placeholder,
            label,
            help_text,
            autoFocus,
            type,
            meta: {
                touched,
                error,
                warning
            }
        } = this.props;

        return (
            <div className={"form-group " + (touched && error ? "has-error " : touched && warning ? "has-warning " : "")}>
                {label && <label className="control-label">{label}</label>}
                <input {...input} autoFocus={autoFocus} disabled={disabled} placeholder={placeholder} type={type} className="form-control" />
                {help_text && <div className="help-block">{help_text}</div>}
                {touched &&
                ((error && <div className="text-danger"><i className="fa fa-times" /> {error}</div>) ||
                    (warning && <div className="text-danger"><i className="fa fa-exclamation-triangle" /> {warning}</div>))}
            </div>
        );
    }
}