import React from "react";

class InfoPair extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            label,
            children
        } = this.props;

        return (
            <div style={{marginBottom: "1em"}}>
                <div>
                    <b className="text-muted">
                        {label}
                    </b>
                </div>
                {children}
            </div>
        );
    }
}

export default InfoPair;