import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { gettext as __ } from "../../../utils/Trans";
import { Button } from "react-bootstrap";
import MarketplaceSearch from "../../MarketplaceSearch";

import "./NavbarSearch.scss";
import toMpURL from "../../../utils/toMpURL";

class NavbarSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            search: null
        };
    }

    __onChangeSearch = (search) => {
        this.setState({
            search: search
        }, () => {
            if (_.isFunction(this.props.input.onChange)) {
                this.props.input.onChange(search);
            }
        });
        if (!_.isUndefined(_.get(search, ["type"]))) {
            if (_.get(search, ["type"]) === "speaker") {
                window.open(toMpURL("/profile/" + _.replace(_.toString(_.get(search, ["value"])), "sid_", "") + "?btsc=1", this.props.whitelabel), "profile_" + _.replace(_.toString(_.get(search, ["value"])), "sid_", ""));
            } else {
                window.location.replace(toMpURL("/search?q=" + JSON.stringify(search)));
            }
        }
    }

    render() {
        let {
            input,
            style = {},
            className = "",
            placeholder = __("Search by name, topic, or expertise..."),
            large = false,
            whitelabel
        } = this.props;

        let default_style = {
            border: "none",
            background: "transparent",
            outline: "none",
            minWidth: "100%",
            height: "30px",
            lineHeight: "30px",
            boxShadow: "none",
            textAlign: "left"
        };

        return (
            <MarketplaceSearch
                input={input}
                multi={false}
                whitelabel={whitelabel}
                containerClassName="navbar-search"
                className={className}
                onChange={this.__onChangeSearch}
                onSelect={this.__onChangeSearch}
                large={large}
                style={_.assign({}, style, default_style)}
                placeholder={placeholder}
                button={<Button type="submit" className="btn-search"><i className="fa fa-search fa-fw" /></Button>}
            />
        );
    }
}

NavbarSearch.propTypes = {
    user: PropTypes.object,
    whitelabel: PropTypes.object,
    style: PropTypes.object,
    className: PropTypes.string,
    placeholder: PropTypes.string,
    large: PropTypes.bool
};

export default withRouter(NavbarSearch);