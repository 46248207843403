import _ from "lodash";
import Jed from "jed";

let i18n = new Jed({
    "domain" : "messages",
    locale_data : !_.isUndefined(window.JED_DATA) ? window.JED_DATA : {"messages":{"":{"domain":"messages","lang":"en","plural-forms":"nplurals=2; plural=(n !== 1);"}}}
});

export function loadLocale(jed_data) {
    i18n = new Jed({
        "domain" : "messages",
        locale_data : !_.isUndefined(jed_data) ? jed_data : {"messages":{"":{"domain":"messages","lang":"en","plural-forms":"nplurals=2; plural=(n !== 1);"}}}
    });
}

export function gettext(original, replace) {
    return i18n.translate(original).fetch(replace);
}

export function ngettext(original, plural, value, replace) {
    return i18n.translate(original).ifPlural(value, plural).fetch(replace);
}

export function pgettext(original, replace) {
    return i18n.translate(original).withContext("overwritable").fetch(replace);
}

export function pngettext(original, plural, value, replace) {
    return i18n.translate(original).withContext("overwritable").ifPlural(value, plural).fetch(replace);
}

export function getSpeakerLanguages() {
    return _.get((!_.isUndefined(window.TRANS_DATA) ? window.TRANS_DATA : {}), ["speaker_languages"], {en: "English"});
}

export function getLanguages() {
    return _.get((!_.isUndefined(window.TRANS_DATA) ? window.TRANS_DATA : {}), ["languages"], {en: "English"});
}

export function getCurrentLanguage() {
    return _.get((!_.isUndefined(window.TRANS_DATA) ? window.TRANS_DATA : {}), ["current_lang"], "en");
}
