import _ from "lodash";
import React from "react";
import Dropzone from "react-dropzone";

import "./FileUpload.scss";

class FileUpload extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            input: {
                value,
                onChange
            },
            label,
            name,
            placeholder,
            help_text,
            meta: {
                touched,
                invalid,
                error
            }
        } = this.props;

        return (
            <div className={"form-group " + (touched && invalid ? "has-error" : "")}>
                {label && <label htmlFor={name} className="control-label">{label}</label>}
                <Dropzone
                    className="drop-zone"
                    activeClassName="active-drop-zone"
                    onDrop={(acceptedFiles) => {
                        onChange(_.head(acceptedFiles));
                    }}
                    {...this.props}
                >
                    {value && _.isString(value) &&
                    <div>
                        {_.endsWith("pdf", value)
                            ?
                            <i className="fa fa-file-pdf-o" />
                            :
                            <img src={value} alt="drop files here" className="img-responsive" />
                        }
                    </div>
                    }
                    {value && _.isObject(value) &&
                    <div>
                        {value.type === "application/pdf"
                            ?
                            <i className="fa fa-file-pdf-o" />
                            :
                            <img src={value.preview} alt="drop files here" className="img-responsive" />
                        }
                        {" "}
                        {value.name}
                    </div>
                    }

                    {placeholder &&
                    <div><i className="fa fa-cloud-upload text-muted"></i> {placeholder}</div>
                    }
                </Dropzone>
                {touched && error && <div className="text-danger">{error}</div> }
                {help_text && <div className="help-block">{help_text}</div>}
            </div>
        );
    }
}

export default FileUpload;