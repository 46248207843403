import _ from "lodash";
import Balboa from "../Global/Balboa";

export default function (sid, checkThisDate) {
    return (dispatch, getState) => {
        var key = ["speakerAvailabilityForDate"];

        return Balboa(dispatch, getState)
            .post(key, "/speaker/calendarcheck/" + sid, {date: checkThisDate}, {cache_ttl: 0}) //no cache so they can check several dates
            .then(function(response) {
                // Save the user to the state
                return dispatch({
                    type: "BOOKMENOWPAGE_SPEAKER_AVAILABILITY_FOR_DATE",
                    data: _.get(response, ["data"])
                });
            });
    };
};
