import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import {Route, withRouter} from "react-router";
import Navbar from "../../../components/Navbar";
import NavFooter from "../../../components/NavFooter";
import SubAuthed from "../SubAuthed";
import {sessionService} from "redux-react-session";
import {connect} from "react-redux";
import EmbeddedShortlist_remove from "../../../actions/ShortList/EmbeddedShortlist_remove";
import toShortlistURL from "../../../utils/toShortlistURL";
import toMpURL from "../../../utils/toMpURL";

class StandardLayout extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  onLogout = () => {
    return sessionService.deleteUser()
    .then(() => {
      return sessionService.deleteSession();
    })
    .then(() => {
      this.props.history.push("/signin");
    });
  };

  render() {
    let {
      user,
      whitelabel,
      children,
      n_unread_messages,
      shortlist,
      errors,
      notifications,
      className = "container"
    } = this.props;

    return (
      <div>
        <Helmet>
          <title>The Right Speaker, Every Time | {_.get(whitelabel, ["bureauName"], "eSpeakers")}</title>
          {_.get(whitelabel, ["is_offer_customerservice"], false) && <script src={toShortlistURL("/scripts/zoho-salesiq.js", whitelabel)} />}
        </Helmet>
        <div>
          <Navbar
            show_brand={true}
            fluid={false}
            n_unread_messages={n_unread_messages}
            shortlist={shortlist}
            onLogout={this.onLogout}
            errors={errors}
            notifications={notifications}
            {...this.props}
          />
        </div>
        <div className="layout-wrapper">
          <SubAuthed user={user}/>
          <div className={className + (_.get(whitelabel, ["is_bureau_mode"], false) ? " bureaumode" : "")}>
            {children}
          </div>
        </div>
        <Route path="/" render={() => {
          //Analytics.logPageView(); //lots of multiple hits per page when we use this here
          // if (typeof window.ga === "function") {
          //     window.ga("set", "page", location.pathname + location.search);
          //     window.ga("send", "pageview");
          // }
          return null;
        }}/>
        <NavFooter whitelabel={whitelabel}/>
      </div>
    );
  }
}

StandardLayout.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node
};

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state) {
  let shortlist = _.get(state, ["EmbeddedShortlist"]);
  let n_unread_messages = _.size(_.filter(_.get(state, ["Messages", "messages", "all"], []), function (item) {
    return (!_.includes(_.get(item, ["flags_as_array"], []), "system_log") &&
      _.get(item, ["receiver_opened"], false) === false &&
      _.get(item, ["sender_buyer_id"], 0) === 0
    );
  }));
  let errors = _.get(state, ["App", "errors"], []);
  let notifications = _.get(state, ["App", "notifications"], []);

  return {
    shortlist: shortlist,
    n_unread_messages: n_unread_messages,
    errors: errors,
    notifications: notifications
  };
};

var mapDispatchToProps = function (dispatch, ownProps) {
  return {
    onRemoveFromShortlist: function (sid) {
      return dispatch(EmbeddedShortlist_remove(sid));
    },
    onProfile: function (sid) {
      ownProps.history.push(toMpURL("/profile/" + sid));
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StandardLayout));