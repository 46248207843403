import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import Login from "./onboard/default";


/* THIS_IS_FOR_MPI don't remove this comment, it's so one can quickly find all the MPI custom code */
/** Any changes to this visible to the consumer should be reported in advance to:
 * achild@mpiweb.org
 * mcrumrine@mpiweb.org
 */

export class SignInMpiPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    render() {
        let {
            onboard,
            redir,
            search
        } = this.props;

        return (
            <div>
                <Login onboard={onboard} redir={redir} search={search} />
            </div>
        );
    }

}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

let mapStateToProps = function(state){
    let onboard = _.get(state, ["hashbang", "query", "onboard"], "default");
    let redir = _.get(state, ["hashbang", "query", "redir"], "#/event");
    let search = _.get(state, ["hashbang", "search"], "");

    return {
        onboard: onboard,
        redir: redir,
        search: search
    };
};

let mapDispatchToProps = function(){
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SignInMpiPage);
