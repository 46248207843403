import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../utils/Trans";

import "./InfoBox.scss";

/**
 * This component displays the 'education' text blocks sprinkled throughout the site.
 * It draws data from a database, this is provided through the DisplayLists call.
 *
 * It is capable of injecting replacement text for formatted placeholders, like
 * {whitelabel_name}
 *
 */


class InfoBox extends React.Component {

    constructor(props) {
        super(props);
        let {
            path, tiptexts, message
        } = props;
        //if (_.isEmpty(message) && (_.isEmpty(path) || _.isEmpty(tiptexts))) return null;

        let tip = (!_.isEmpty(message)) ? {text: message, headline: message} : this.messageForPath(path, tiptexts);

        this.state = {
            show_more: _.get(props, ["startexpanded"], false),
            tip: tip
        };
        this.toggleMore = this.toggleMore.bind(this);
    }

    toggleMore(e) {
        e.preventDefault();
        e.stopPropagation();
        this.setState(function (old_state) {
            return {show_more: !old_state.show_more};
        });
    }

    /**
     * Given a path (ie \mp\onlineagreement\top-right), return the
     * text of the tip.
     *
     * @param path
     * @param tiptexts
     * @param placeholders
     * @param headline_version
     * @returns {string|*}
     */
    messageForPath(path, tiptexts) {

        let paths = path.split("\\"),
            i,
            testpath,
            tip   = null;

        if (!_.isEmpty(tiptexts)) {
            tiptexts = _.shuffle(tiptexts); //randomize matching entries so we don't get the same one each time

            //search backward along the path for a match (most specific -> least specific match)
            for (i = paths.length - 1; i > -1; i--) {
                testpath = paths.slice(0, i + 1).join("\\").toLowerCase();
                tip = _.find(tiptexts, {key: testpath});
                if (!_.isEmpty(tip)) break;
            }
        }

        if (_.isEmpty(tip)) {
            console.log("Missing tiptext for " + path);
            tip = {text: "", headline: ""};
        }

        return tip;

    }


    /**
     * Replace formatted placeholder text in the string.
     *
     * @param message
     * @param placeholders
     * @returns {*}
     */
    fillPlaceholders(message, placeholders) {
        _.forEach(placeholders, function (value, key) {
            message = message.replace(key, value);
        });

        return message;
    }


    render() {
        let {path, whitelabel, dont_use_more_btn} = this.props;

        //don't show these in bureaumode
        if (_.get(whitelabel, "is_bureau_mode", false)) return null;

        let message;
        let show_more = this.state.show_more;

        dont_use_more_btn = !!dont_use_more_btn;
        let onClick = (dont_use_more_btn) ? null : this.toggleMore;


        //a list of placeholder codes that you can use in the tip text or header
        let placeholders = {
            "{whitelabel_name}": this.props.sitename
        };

        if (_.isEmpty(path) && _.isEmpty(_.get(this.state, "tip.text", ""))) return null;


        if (show_more) {
            //expanded version

            message = this.fillPlaceholders(_.get(this.state, "tip.text", ""), placeholders);

            if (_.isEmpty(message)) return null;

            return (
                <div className="InfoBox alert alert-warning" role="alert" onClick={this.toggleMore}>
                    <div dangerouslySetInnerHTML={{__html: message}}/>
                    {/*<span className="icon pull-right">i</span>*/}
                </div>
            );
        } else {
            //headline version with expand button

            //TODO auto-detect if this more-btn is needed by making a component that inspects dom spacing etc...
            message = this.fillPlaceholders(_.get(this.state, "tip.headline", ""), placeholders);

            if (_.isEmpty(message)) return null;

            return (
                <div className="InfoBox alert alert-warning" role="alert" onClick={onClick} style={{
                    cursor: onClick ? "pointer" : "default"
                }}>
                    {!dont_use_more_btn ?
                        <button className="pull-right btn btn-warning btn-sm no-" onClick={onClick}>
                            {__("MORE")} <span className="caret"></span>
                        </button>
                        : null}
                    <p className="headline" dangerouslySetInnerHTML={{__html: message}}></p>
                </div>
            );
        }
    }
}

InfoBox.propTypes = {
    tiptexts: PropTypes.object,
    path: PropTypes.string,
    whitelabel: PropTypes.object,
    dont_use_more_btn: PropTypes.bool
};


export default InfoBox;
