import _ from "lodash";
import React from "react";
import { gettext as __ } from "../../../utils/Trans";
import toMpURL from "../../../utils/toMpURL";

export default class NavFooter extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let { whitelabel } = this.props;

        let contact_us_url = _.get(whitelabel, "contact_us_url");
        contact_us_url = _.isEmpty(contact_us_url)
            ? _.get(whitelabel, "mp_keyvalues.CONTACT_LINK")
            : contact_us_url;
        //console.log("contact_us_url", contact_us_url);
        return (
            <nav className="mp-footer">
                <div className="container">
                    <div className="row">
                        <div className="col-xs-5">
                            <div>
                                <a href={_.get(whitelabel, "website")}>
                                    {_.get(whitelabel, "name", __("Company"))}
                                </a>
                            </div>
                            {!_.isEmpty(contact_us_url) && (
                                <div>
                                    <a href={contact_us_url}>
                                        {__("Contact us")}
                                    </a>
                                </div>
                            )}
                        </div>
                        <div className="col-xs-6 col-xs-offset-1 text-right">
                            <div>
                                <div className="copyright">
                                    {__("powered by")}{" "}
                                    <a
                                        href="https://www.espeakers.com"
                                        rel="noopener noreferrer">
                                        eSpeakers.com
                                    </a>
                                </div>
                                {_.get(
                                    whitelabel,
                                    "is_offer_customerservice",
                                    false
                                ) && (
                                    <div>
                                        <i className="fa fa-phone" /> +1 (888)
                                        535-3367
                                    </div>
                                )}
                                <div>
                                    <a href={toMpURL("/en", whitelabel)}>en</a>{" "}
                                    |<a href={toMpURL("/es", whitelabel)}>es</a>{" "}
                                    |<a href={toMpURL("/pt", whitelabel)}>pt</a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        );
    }
}
