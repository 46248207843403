import React from "react";
import PropTypes from "prop-types";
import CounterV1 from "./CounterV1";
import CounterV2 from "./CounterV2";

class Counter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timer: false,
            incrementer: 0
        };
    }

    componentDidMount() {
        this.setState({
            timer: window.setInterval(this.incrementCounter, 50)
        });
    }

    componentWillUnmount() {
        if (this.state.timer) {
            window.clearInterval(this.state.timer);
        }
    }

    incrementCounter = () => {
        this.setState({
            incrementer: this.state.incrementer + 1231
        });
    }

    render() {
        let {
            num,
            version,
            className,
            style
        } = this.props;

        if (version === 2) {
            return <CounterV2 num={num} className={className} style={style} />;
        } else {
            return <CounterV1 num={num} className={className} style={style} />;
        }
    }
}

Counter.propTypes = {
    num: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    version: PropTypes.number,
    className: PropTypes.string,
    style: PropTypes.object
};

export default Counter;