import _ from "lodash";
import Balboa from "../Global/Balboa";
import {gettext as __} from "../../utils/Trans";

var key_prefix = "MPshortlist_email_invoice";

export default {
    send: function(params){
        return (dispatch, getState) => {
            var key = ["MPshortlist_email_invoice", params.event_id];
            return Balboa(dispatch, getState)
                .post(key, "/mpshortlist/emailinvoice/" + params.event_id, {
                        emails: JSON.stringify(params.emails),//so the api can parse single value arrays
                        message: params.message
                    }, {cache_ttl: -1})
                .then(function(response) {
                    dispatch({
                        type: "BALBOA3_CLEAR_KEY",
                        key: key
                    });
                    return response;
                })
                .then(function(response) {
                    dispatch({
                        type: "NOTIFICATION_CREATE",
                        message: __("An email was sent to") + " " + _.join(params.emails, ", ")
                    });
                    return response;
                });
        };
    },
    clear: function(){
        return function(dispatch, getState){//This is a `thunk` that "redux-thunk" handles
            var state = getState();
            _.each(state.balboa3, function(v, key){
                if(key.indexOf(key_prefix) === 0){
                    dispatch({
                        type: "BALBOA3_CLEAR_KEY",
                        key: key
                    });
                }
            });
        };
    }
};
