import React from "react";
import { Grid, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import { gettext as __ } from "../../../utils/Trans";
import toMpURL from "../../../utils/toMpURL";
import Analytics from "../../../utils/Analytics";

export default class NavFooter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    SignupOnclick() {
        Analytics.sendEvent("BuyerSignup", "click", 0, "footer");
    }

    LoginOnClick() {
        Analytics.sendEvent("BuyerLogin", "click", 0, "footer");
    }

    render() {
        let {
            whitelabel
        } = this.props;

        return (
            <nav className="mp-footer">
                <Grid>
                    <Row>
                        <Col sm={5} className="col-sm-4">
                            <Row className="text-left">
                                <Col sm={4}>
                                    <div><a href="/">{__("Home")}</a></div>
                                    {/*<div><a href="/blog">{__("Blog")}</a></div>*/}
                                    <div><a href="/topiclist">{__("Topics")}</a></div>
                                    <div><a href="/marketplace/about">{__("About Us")}</a></div>
                                </Col>
                                <Col sm={4}>
                                    <div><a href="/marketplace/search">{__("Virtual Speakers")}</a></div>
                                    <div><a href="/marketplace/search">{__("Local Speakers")}</a></div>
                                    <div><a href="/marketplace/search">{__("Speakers")}</a></div>
                                </Col>
                                <Col sm={4}>
                                    <div><Link to="/signup" onClick={this.SignupOnclick.bind(this)}>{__("Sign up Free")}</Link></div>
                                    <div><Link to="/signin" onClick={this.LoginOnClick.bind(this)}>{__("Log In")}</Link></div>
                                    <div><a href="/support">{__("Support")}</a></div>
                                    <div><a href="/about-us/contact/">{__("Contact Us")}</a></div>
                                </Col>
                            </Row>
                        </Col>
                        <Col sm={3} className="text-center">
                            <a href="https://www.facebook.com/espeakers/">
                                <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="fa fa-facebook fa-stack-1x fa-inverse"></i>
                                </span>
                            </a>
                            <a href="https://twitter.com/espeakers">
                                <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="fa fa-twitter fa-stack-1x fa-inverse"></i>
                                </span>
                            </a>
                            <a href="https://www.linkedin.com/company/espeakers">
                                <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="fa fa-linkedin fa-stack-1x fa-inverse"></i>
                                </span>
                            </a>
                            <a href="https://www.instagram.com/espeakers/">
                                <span className="fa-stack fa-lg">
                                    <i className="fa fa-circle fa-stack-2x"></i>
                                    <i className="fa fa-instagram fa-stack-1x fa-inverse"></i>
                                </span>
                            </a>
                        </Col>
                        <Col sm={4} className="text-right">
                            <div>
                                <div className="copyright">{"\u00A9" + (new Date().getFullYear()) + " eSpeakers, Inc. " + __("All rights reserved.")}</div>
                                <div><a href="/espeakers-privacy-policy.php">{__("Our concern for your privacy")}</a></div>
                                <div><i className="fa fa-phone"/> +1 (888) 535-3367</div>
                                <div>
                                    <a href={toMpURL("/en", whitelabel)}>en</a> |
                                    <a href={toMpURL("/es", whitelabel)}>es</a> |
                                    <a href={toMpURL("/pt", whitelabel)}>pt</a>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Grid>
            </nav>
        );
    }
}