import _ from "lodash";
import React from "react";
import toMpURL from "../../../../utils/toMpURL";

class WhitelabelLogo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            whitelabel
        } = this.props;

        let logo_url = _.get(whitelabel, ["mp_keyvalues", "LOGO_URL"], toMpURL("/whitelabel/" + _.get(whitelabel, ["id"]) + "/img/logo.png"));
        logo_url = _.replace(logo_url, "S3:", "https://streamer.espeakers.com/");

        return (
            <div className="text-center">
                <div style={{
                    backgroundColor: _.get(whitelabel, ["mp_keyvalues", "COLOR_HEADER_BACKGROUND"])
                }}>
                    <img src={logo_url} alt={_.get(whitelabel, ["name"])} style={{width: "200px"}} className="img-responsive text-left" />
                </div>
            </div>
        );
    }

}

export default WhitelabelLogo;