import getMPEvent from "./getMPEvent";
import Message from "../Messages/Message";
import getMPShortlist from "../ShortList/getMPShortlist";

export default function(eid) {
    return (dispatch) => {
        return dispatch(getMPEvent(eid, true))
            .then((response) => {
                dispatch(getMPShortlist(eid, true));
                return response;
            })
            .then((response) => {
                dispatch(Message.findByEvent(eid));
                return response;
            });
    };
};
