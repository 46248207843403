import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import CancelAgreementModal from "../../modals/CancelAgreementModal";

class CancelAgreementButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_modal: false
        };
    }

    __toggleModal = () => {
        this.setState({
            show_modal: !this.state.show_modal
        });
    }

    componentWillUnmount() {
        this.setState({
            show_modal: false
        });
    }

    render() {
        let {
            className = "btn btn-danger btn-flat btn-block-xs"
        } = this.props;

        return (
            <span>
                <div className={className} onClick={this.__toggleModal}>
                    {__("CANCEL AGREEMENT")}
                </div>
                {this.state.show_modal && <CancelAgreementModal {...this.props} onClose={this.__toggleModal} />}
            </span>
        );
    }
}

CancelAgreementButton.propTypes = {
    event: PropTypes.object.isRequired,
    speaker: PropTypes.object.isRequired,
    className: PropTypes.string
};

export default CancelAgreementButton;