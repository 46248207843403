import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { gettext as __ } from "../../../../utils/Trans";
import assertBalboa from "../../../../utils/assertBalboa";
import formatCurrency from "../../../../utils/formatCurrency";
import { Modal } from "react-bootstrap";
import sendInvoiceToBuyer from "../../../../actions/ShortList/sendInvoiceToBuyer";

class MailPaperCheckModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            is_sending: false,
            is_sent: false
        };
    }

    __onEmailInvoice = () => {
        this.setState({
            is_sending: true,
            is_sent: false
        }, () => {
            return this.props.sendInvoiceToBuyer()
                .then(() => {
                    this.setState({
                        is_sending: false,
                        is_sent: true
                    });
                });
        });
    }

    render() {
        let {
            event,
            speaker,
            payment,
            is_espeakers,
            onClose
        } = this.props;

        let {
            is_sending,
            is_sent
        } = this.state;

        let eid = _.get(event, ["eid"]);
        let selected_sid = _.get(speaker, ["sid"]);

        let deposit_amount = _.reduce(payment, function (sum, item) {
            if (_.get(item, ["flags_as_map", "is_deposit", "is_set"], false) && !_.get(item, ["paid"], false)) {
                return sum + parseFloat(item.fee);
            }
            return sum;
        }, 0);

        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("Mail Paper Check")}</Modal.Title>
                </Modal.Header>
                <div className="modal-body">
                    {(is_espeakers) || <div className="help-block instruction">{__("Payment services are powered by eSpeakers.com.")}</div>}
                    <p>{__("Pay your speaker with a paper check by sending it to the eSpeakers.com payment processing center.")}{" "}{__("For security, do")}{" "}<strong>{__("not")}</strong>{" "}{__("mail the check directly to the presenter.")}</p>
                    <p>{__("To mail in payment:")}</p>
                    <ol>
                        <li><strong>{__("Receive a printable invoice")}</strong>{" "}{__("for this job via email by clicking the button below.")}</li>
                        <li>{__("Mail a")}{" "}<strong>{__("signed checked")}</strong>{" "}{__("for the Deposit Amount of")}{" "}<b>USD {formatCurrency(deposit_amount, 2)}</b>{" "}{__("to the eSpeakers Payment Processing center (US address shown on invoice).")}</li>
                        <li>{__("Include")}{" "}<strong>"event #eid{eid}/{selected_sid}"</strong>{" "}{__("on the")}{" "}<strong>{__("memo line")}</strong>{" "}{__("of the check.")}</li>
                        <li>{__("Include a")}{" "}<strong>{__("printed copy of the invoice")}</strong>{" "}{__("in the envelope with the check.")}</li>
                    </ol>
                    <br />
                    <div className="btn btn-success btn-block" onClick={this.__onEmailInvoice}>
                        {is_sending ?
                            <span><i className="fa fa-spin fa-circle-o-notch"></i> {__("Sending Invoice...")}</span>
                            :
                            <span>{__("Email me the invoice")}</span>
                        }
                    </div>
                    <br />
                    {is_sent &&
                        <div className={"alert alert-success"}>{__("The invoice was sent to your email inbox!")}</div>
                    }
                    <p className="help-block instruction"><strong>{__("Your date is not confirmed until payment is received.")}</strong>{"  "}{__("Communicate with the presenter if you expect it to take longer than 10 days for your check to arrive.")}</p>
                </div>
                <div className="modal-footer">
                    {/*<div className="btn btn-default btn-block-xs" onClick={choosePaymentMethod}>*/}
                        {/*{__("Use Different Payment Method")}*/}
                    {/*</div>*/}
                    <div className="btn btn-default btn-block-xs" onClick={onClose}>
                        {__("Close")}
                    </div>
                </div>
            </Modal>
        );
    }
}

MailPaperCheckModal.propTypes = {
    event: PropTypes.object.isRequired,
    speaker: PropTypes.object.isRequired,
    payment: PropTypes.object.isRequired,
    onClose: PropTypes.func
};
////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function(state, ownProps){

    let error_msg = _.get(state, ["App", "errors", "sendInvoiceToBuyer", ownProps.event, ownProps.selected_sid]);

    let saving_ajax = assertBalboa(state, [
        ["sendInvoiceToBuyer", ownProps.eid, ownProps.selected_sid]
    ]);

    return {
        error_msg: error_msg,
        is_saving: saving_ajax.waiting,
        is_espeakers: !!(state.whitelabel && state.whitelabel.is_espeakers)
    };
};

var mapDispatchToProps = function(dispatch, ownProps){
    let eid = _.get(ownProps.event, ["eid"]);
    let sid = _.get(ownProps.speaker, ["sid"]);
    return {
        sendInvoiceToBuyer: function () {
            return dispatch(sendInvoiceToBuyer(eid, sid, true)); // sending true here will use the agreement fees instead of services
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MailPaperCheckModal);
