import _ from "lodash";
import Rest from "../Global/Rest";
import Balboa from "../Global/Balboa";

export default _.assign({}, Rest, {

    /**
     * Retrieves a single record.
     * @param params
     * @returns {Function}
     */
    getAsCollaborator: function(params) {
        let key = ["GetEventAsCollaborator"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/marketplace/event-as-collaborator/" + _.get(params, ["id"]), params)
                    .then(function (response) {
                        dispatch({
                            type: "EVENT_GET",
                            id: _.get(params, ["id"]),
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Retrieves a single record.
     * @param params
     * @returns {Function}
     */
    get: function(id) {
        let key = ["GetEvent"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/marketplace/event/" + id, {})
                    .then(function (response) {
                        dispatch({
                            type: "EVENT_GET",
                            id: id,
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Retrieves a single record.
     * @param params
     * @returns {Function}
     */
    find: function(params) {
        let key = ["GetEvents"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/marketplace/event", params)
                    .then(function (response) {
                        dispatch({
                            type: "EVENT_GET_ALL",
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Inserts a record.  Leave {params.id} 0, otherwise it will be updated
     * @param params
     * @returns {Function}
     */
    add: function(params) {
        let key = ["SaveEvent"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/marketplace/event", params)
                    .then(function (response) {
                        dispatch({
                            type: "EVENT_POST",
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Updates a record.  Pass in {params.id} to specify the record to update, otherwise it will be added
     * @param params
     * @returns {Function}
     */
    update: function(params) {
        let key = ["UpdateEvent"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .put(key, "/marketplace/event/" + _.get(params, ["id"]), params)
                    .then(function (response) {
                        dispatch({
                            type: "EVENT_PUT",
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Removes a record.  Pass in {params.id} to specify the record to remove
     * @param params
     * @returns {Function}
     */
    remove: function(id) {
        let key = ["RemoveEvent"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .del(key, "/marketplace/event/" + id, {})
                    .then(function (response) {
                        dispatch({
                            type: "EVENT_DELETE",
                            id: id,
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    }
});