import React from "react";
import { Modal } from "react-bootstrap";
import { gettext as __ } from "../../../../../utils/Trans";
import { ChoosePaymentMethodButton } from "../../../components/Buttons";

class NoDepositForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        let {
            total_fees,
            event_date_str,
            last4,
            onClose
        } = this.props;

        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("Confirm Payment")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <h4>
                            <span className="pull-right">{total_fees}</span>
                            {__("Amount Charged on %s", event_date_str)}
                        </h4>
                        <hr />
                        <div>
                            <p>
                                {__("I agree to let eSpeakers charge my payment method ending in")}{" "}<b>***{last4}</b>{" "}{__("for the amount of")}{" "}<b>{total_fees}</b>{" "}{__("on")}{" "}<b>{event_date_str}</b>.
                            </p>
                            <p>
                                {__("This is a binding contract between you (the buyer) and the chosen speaker. Once your deposit has been received, the speaker will reserve their calendar for your event. This transaction is covered by the")}{" "}<b>
                                <a target="_blank" href="https://www.espeakers.com/legal/marketplace-terms-and-conditions/" rel="noopener noreferrer">{__("eSpeakers Marketplace Guarantee")}</a></b>.
                            </p>
                            <p>
                                {__("By clicking \"I ACCEPT\", I agree to the above terms of service.")}
                            </p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="visible-lg visible-md">
                        <div className="btn btn-success btn-block-xs" onClick={onClose}>
                            {__("I ACCEPT")}
                        </div>
                        {" "}
                        <ChoosePaymentMethodButton {...this.props} />
                    </div>
                    <div className="visible-sm visible-xs">
                        <div className="btn btn-success btn-block-xs btn-lg" onClick={onClose}>
                            {__("I ACCEPT")}
                        </div>
                        {" "}
                        <ChoosePaymentMethodButton {...this.props} />
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default NoDepositForm;