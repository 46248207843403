import Balboa from "../Global/Balboa";
import {gettext as __} from "../../utils/Trans";

export default function(user){
    return (dispatch, getState) => {
        var key = ["resetUserPassword"];

        return Balboa(dispatch, getState)
            .post(key, "/authenticate/buyerresetpassword/",
                {
                    old_password: user.old_password,
                    password: user.password,
                    password2: user.password2
                },
                {cache_ttl: -1}
            )
            .then(function(response) {
                // Save the user to the state
                dispatch({
                    type: "USER_PAGE_PASSWORD_UPDATED"
                });
                return response;
            })
            .then(function(response) {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            })
            .then(function(response) {
                dispatch({
                    type: "NOTIFICATION_CREATE",
                    message: __("Your password has been updated successfully.")
                });
                return response;
            });
    };
};
