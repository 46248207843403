import Balboa from "../Global/Balboa";

export default function(sid, clear_cache = false) {
    return (dispatch, getState) => {
        var key = ["getSpeakerAvailability", sid];
        var cache_ttl = clear_cache ? -1 : 30 * 60 * 1000;
        return Balboa(dispatch, getState)
            .post(key, "/speaker/profilecalendar/" + sid, {}, {cache_ttl: cache_ttl});
    };
}
