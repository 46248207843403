import _ from "lodash";
import Rest from "../Global/Rest";
import Balboa from "../Global/Balboa";
import {sessionService} from "redux-react-session";

export default _.assign({}, Rest, {

    /**
     * Sends the reset password email to a user
     * @param params
     * @returns {Function}
     */
    forgotpassword: function(params) {
        let key = ["ForgotPassword"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/mpauth/buyerforgotpassword", params)
                    .then(function (response) {
                        return response;
                    })
            );
        };
    },

    /**
     * Resets a user's password
     * @param params
     * @returns {Function}
     */
    resetpassword: function(params) {
        let key = ["ResetUserPassword"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/mpauth/buyerresetpassword/", params)
                    .then(function (response) {
                        dispatch({
                            type: "USER_PAGE_PASSWORD_UPDATED"
                        });
                        return response;
                    })
                    .then(function (response) {
                        if (_.has(response, ["data", "token"])) {
                            sessionService.saveSession(_.get(response, ["data", "token"]));
                        }
                        if (_.has(response, ["data", "user"])) {
                            sessionService.saveUser(_.assign({}, _.get(response, ["data", "user"]), {token: _.get(response, ["data", "token"])}));
                        }
                        return response;
                    })
            );
        };
    },

    /**
     * Sends the verification email to a user.  This call will send the email as long as the buyer is not verified.
     * @param params
     * @returns {Function}
     */
    sendverifyemail: function(params) {
        let key = ["SendVerifyUserEmail", _.get(params, ["id"])];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/mpauth/sendverifyemail", params)
                    .then(function (response) {
                        return response;
                    })
            );
        };
    },

    /**
     * Verifies a users email address
     * @param params
     * @returns {Function}
     */
    verifyemail: function(params) {
        let key = ["VerifyUserEmail", _.get(params, ["id"])];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/mpauth/verifyemail", params)
                    .then(function (response) {
                        return response;
                    })
                    .then(function (response) {
                        if (_.has(response, ["data", "token"])) {
                            sessionService.saveSession(_.get(response, ["data", "token"]));
                        }
                        if (_.has(response, ["data", "user"])) {
                            sessionService.saveUser(_.assign({}, _.get(response, ["data", "user"]), {token: _.get(response, ["data", "token"])}));
                        }
                        return response;
                    })
            );
        };
    },

    /**
     * Signs a user in
     * @param params
     * @returns {Function}
     */
    signup: function(params) {
        let key = ["SignUp"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/marketplace/mp-auth/buyersignup", params)
                    .then(function (response) {
                        if (_.has(response, ["data", "token"])) {
                            sessionService.saveSession(_.get(response, ["data", "token"]));
                        }
                        if (_.has(response, ["data", "user"])) {
                            sessionService.saveUser(_.assign({}, _.get(response, ["data", "user"]), {token: _.get(response, ["data", "token"])}));
                        }
                        return response;
                    })
            );
        };
    },

    /**
     * Signs a user in
     * @param params
     * @returns {Function}
     */
    signin: function(params) {
        let key = ["SignIn"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/mpauth/buyersignin", params)
                    .then(function (response) {
                        if (_.has(response, ["data", "token"])) {
                            sessionService.saveSession(_.get(response, ["data", "token"]));
                        }
                        if (_.has(response, ["data", "user"])) {
                            sessionService.saveUser(_.assign({}, _.get(response, ["data", "user"]), {token: _.get(response, ["data", "token"])}));
                        }
                        return response;
                    })
            );
        };
    },

    /**
     * Signs a user in via Oauth
     * @param params
     * @returns {Function}
     */
    tokensignin: function(params) {
        let key = ["TokenSignIn"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/oauth2/signintoken", params)
                    .then(function (response) {
                        if (_.has(response, ["data", "token"])) {
                            sessionService.saveSession(_.get(response, ["data", "token"]));
                        }
                        if (_.has(response, ["data", "user"])) {
                            sessionService.saveUser(_.assign({}, _.get(response, ["data", "user"]), {token: _.get(response, ["data", "token"])}));
                        }
                        return response;
                    })
            );
        };
    },

    /**
     * Signs a user in via Oauth
     * @param params
     * @returns {Function}
     */
    oauthsignin: function(params) {
        let key = ["OauthSignIn"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/oauth2/signinoauth", params)
                    .then(function (response) {
                        if (_.has(response, ["data", "token"])) {
                            sessionService.saveSession(_.get(response, ["data", "token"]));
                        }
                        if (_.has(response, ["data", "user"])) {
                            sessionService.saveUser(_.assign({}, _.get(response, ["data", "user"]), {token: _.get(response, ["data", "token"])}));
                        }
                        return response;
                    })
            );
        };
    },

    /**
     * Retrieves a single record.
     * @param params
     * @returns {Function}
     */
    get: function(id) {
        let key = ["GetUser"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/mpauth/me/" + id, {})
                    .then(function (response) {
                        if (_.has(response, ["data", "token"])) {
                            sessionService.saveSession(_.get(response, ["data", "token"]));
                        }
                        if (_.has(response, ["data", "user"])) {
                            sessionService.saveUser(_.assign({}, _.get(response, ["data", "user"]), {token: _.get(response, ["data", "token"])}));
                        }
                        return response;
                    })
            );
        };
    },

    /**
     * Inserts a record.  Leave {params.id} 0, otherwise it will be updated
     * @param params
     * @returns {Function}
     */
    add: function(params) {
        return this.signup(params);
    },

    /**
     * Updates a record.  Pass in {params.id} to specify the record to update, otherwise it will be added
     * @param params
     * @returns {Function}
     */
    update: function(params) {
        let key = ["SaveUserProfile"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .put(key, "/mpauth/buyereditaccount", params)
                    .then(function (response) {
                        dispatch({
                            type: "USER_SAVE",
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
                    .then(function (response) {
                        if (_.has(response, ["data", "token"])) {
                            sessionService.saveSession(_.get(response, ["data", "token"]));
                        }
                        if (_.has(response, ["data", "user"])) {
                            sessionService.saveUser(_.assign({}, _.get(response, ["data", "user"]), {token: _.get(response, ["data", "token"])}));
                        }
                        return response;
                    })
            );
        };
    },

    /**
     * Updates a record.  Pass in {params.id} to specify the record to update, otherwise it will be added
     * @param params
     * @returns {Function}
     */
    updatepassword: function(params) {
        let key = ["SaveUserPassword"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/mpauth/buyerupdatepassword", params)
                    .then(function (response) {
                        dispatch({
                            type: "USER_PAGE_PASSWORD_UPDATED"
                        });
                        return response;
                    })
                    .then(function (response) {
                        if (_.has(response, ["data", "token"])) {
                            sessionService.saveSession(_.get(response, ["data", "token"]));
                        }
                        if (_.has(response, ["data", "user"])) {
                            sessionService.saveUser(_.assign({}, _.get(response, ["data", "user"]), {token: _.get(response, ["data", "token"])}));
                        }
                        return response;
                    })
            );
        };
    },

    /**
     * Removes a record.  Pass in {params.id} to specify the record to remove
     * @param params
     * @returns {Function}
     */
    remove: function(/* params */) {
        console.error("Not implemented yet");
    }

});