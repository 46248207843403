import React from "react";
import { Modal } from "react-bootstrap";
import { gettext as __ } from "../../../../../utils/Trans";
import { ViewInvoiceButton } from "../../../components/Buttons";

class NoBalanceForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        let {
            onClose
        } = this.props;

        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("You're all set")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div>
                            <p>
                                {__("You have no balance due on this event!  Your event is all set.  You should communicate with the speaker to coordinate the engagement, the topic, audience, and venue.")}
                            </p>
                            <p>
                                {__("If you need to print an invoice for your records, you can do that below.")}
                            </p>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="visible-lg visible-md">
                        <ViewInvoiceButton {...this.props} />
                        {" "}
                        <div className="btn btn-default btn-block-xs" onClick={onClose}>
                            {__("Close")}
                        </div>
                    </div>
                    <div className="visible-sm visible-xs">
                        <ViewInvoiceButton {...this.props} />
                        {" "}
                        <div className="btn btn-default btn-block" onClick={onClose}>
                            {__("Close")}
                        </div>
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default NoBalanceForm;