import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import ViewInvoiceModal from "../../modals/ViewInvoiceModal";

class ViewInvoiceButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_modal: false
        };
    }

    __toggleModal = () => {
        if (_.isFunction(this.props.onClose)) {
            // Close other modals first
            // this.props.onClose();
        }
        this.setState({
            show_modal: !this.state.show_modal
        });
    }

    render() {
        let {
            className = "btn btn-warning btn-flat btn-block-xs"
        } = this.props;

        return (
            <span>
                <div
                    className={className}
                    onClick={this.__toggleModal}
                >
                    {__("View Invoice")}
                </div>
                {this.state.show_modal && <ViewInvoiceModal {...this.props} onClose={this.__toggleModal} />}
            </span>
        );
    }
}

ViewInvoiceButton.propTypes = {
    event: PropTypes.object.isRequired,
    speaker: PropTypes.object.isRequired,
    className: PropTypes.string
};

export default ViewInvoiceButton;