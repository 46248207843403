import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

class AdSpeed extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rando: props.rando || _.random(10000,99999)
        };
    }

    componentDidMount() {
        let {
            ad_number,
            whitelabel
        } = this.props;

        let adspeed_zone = _.get(whitelabel, ["mp_keyvalues", "AD_INRESULT_ZONE"]);
        let rando = this.state.rando;

        if (typeof window.AdSpeed_div !== undefined && _.isFunction( window.AdSpeed_div) && ad_number >= 0) {
            // Delay until the component is actually rendered into Real DOM and not virtual DOM
            setTimeout(function () {
                window.requestAnimationFrame(() => {
                    window.AdSpeed_div("adspeed_" + rando + "_" + ad_number, adspeed_zone, ad_number);
                })
            }, 500);
        }
    }

    render() {
        let {
            whitelabel,
            ad_number
        } = this.props;

        let adspeed_zone = _.get(whitelabel, ["mp_keyvalues", "AD_INRESULT_ZONE"]);
        let adspeed_fetch = _.get(whitelabel, ["mp_keyvalues", "AD_INRESULT_FETCHQTY"]);

        if (adspeed_fetch < 0 || _.isUndefined(adspeed_zone)) {
            return <div />;
        }

        return (
            <div id={"adspeed_" + this.state.rando + "_" + ad_number} style={{
                paddingBottom: "30px",
                borderBottom: "1px solid #E4E4E4",
                marginBottom: "30px"
            }} />
        );
    }
}

AdSpeed.propTypes = {
  whitelabel: PropTypes.object,
  ad_number: PropTypes.number
};

export default AdSpeed;