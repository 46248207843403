import React from "react";
import _ from "lodash";
import { gettext as __ } from "../../utils/Trans";

import "./CustomerService.scss";

class CustomerService extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            whitelabel
        } = this.props;

        return (_.get(whitelabel, ["is_offer_customerservice"], false))
            ?
            <p className="customerservice help-block instruction">
                <strong>{__("Want a hand?")}</strong> {__("Give us a call at +1 (888) 535-3367 Monday-Friday 9am to 5pm MT.")}
            </p>
            :
            null;
    }
}

export default CustomerService;