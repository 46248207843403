import _ from "lodash";
import React from "react";
import { gettext as __ } from "../../utils/Trans";
import ErrorAlert from "./components/ErrorAlert";

export default class ErrorPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            error
        } = this.props;

        return (
            <div>
                <h1 className=" page-header text-center">
                    {__("We've encountered an error!")}
                </h1>
                <h3 className=" text-center text-muted">
                    {__("Don't worry, someone in the back room has been notified.")}
                </h3>
                <br />
                <br />
                <br />
                <br />
                <div className="row">
                    <div className="col-sm-6 col-sm-offset-3">
                        <ErrorAlert error={_.get(error, ["message"])} />
                    </div>
                </div>
            </div>
        );
    }
}