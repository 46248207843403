import _ from "lodash";
import React from "react";

class PopoverMenu extends React.Component {

    render() {
        let {
            style,
            placement,
            className,
            children
        } = this.props;

        let popover_style = {
            margin: "0 0",
            zIndex: "0",
            position: "relative",
            boxShadow: "0 5px 10px rgba(0, 0, 0, 0.2)"
        };

        if (placement === "top") {
            popover_style = _.assign({}, popover_style, {top: "1px"});
        } else if (placement === "bottom") {
            popover_style = _.assign({}, popover_style, {top: "-1px"});
        } else if (placement === "right") {
            popover_style = _.assign({}, popover_style, {left: "-1px"});
        } else if (placement === "left") {
            popover_style = _.assign({}, popover_style, {left: "1px"});
        }

        return (
            <div
                style={{
                    ...style,
                    position: "absolute",
                    border: "none",
                    display: "block",
                    padding: "0px",
                    boxShadow: "none",
                    backgroundColor: "transparent"
                }}
                className={"popover in " + placement + " " + className}
            >
                {(placement === "top" || placement === "bottom") &&
                    <div className="arrow" style={{left: "50%", zIndex: "1"}}/>
                }
                {(placement === "left" || placement === "right") &&
                    <div className="arrow" style={{top: "50%", zIndex: "1"}}/>
                }
                <div className="popover-content open" style={{ padding: "0px", zIndex: "0"}}>
                    <div className="dropdown-menu" style={popover_style}>
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

export default PopoverMenu;