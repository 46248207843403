import React from "react";
// import { Link } from "react-router-dom";
import { gettext as __ } from "../../utils/Trans";
import PostAJobForm from "./components/PostAJobForm";
import CustomerService from "../../components/CustomerService";

import "./PostAPublicJobPage.scss";

export class PostAPublicJobPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            whitelabel
        } = this.props;

        let img = require("./choose-speaker.jpg");

        return (
            <div className="post-public-job-page">
                <div>
                    <h1 className="page-header" style={{marginBottom: "40px"}}>
                        {__("Free Call For Speakers")}
                    </h1>
                    <div className="row ">
                        <div className="col-md-5">
                            <img src={img} alt="choose speaker" className="img-responsive img-rounded hidden-sm hidden-xs"/>
                            <h2>{__("Get Results Fast!")}</h2>
                            <br />
                            <h4 className="help-block hidden-bureaumode">
                                {__("Did you know that posting a job on our")}{" "}<b>{__("PUBLIC JOB BOARD")}</b>{" "}{__("instantly connects you to over 10,000 public speakers?")}
                            </h4>
                            <br />
                            <div className="help-block hidden-bureaumode">
                                <p>
                                    {__("Every day, we connect people just like you with a perfect candidate that delivers exactly what your next meeting, conference, or team-building activity needs.")}
                                </p>
                                <p>
                                    {__("Get started today by telling us about your meeting. Your new meeting will be added to our public job board and will be instantly visible to our full marketplace of world-class speakers.  Try to be as specific as possible to give speakers enough information to be able to provide you with an accurate offer.")}
                                </p>
                                <p>
                                    {__("Prefer to find speakers that match your criteria?")}
                                </p>
                                <p>
                                    <a href="/marketplace/search"><b>{__("Create a speaker shortlist")}</b></a>{" "}{__("and invite them directly from our marketplace.")}
                                </p>
                                <CustomerService whitelabel={whitelabel}/>
                            </div>
                        </div>
                        <div className="col-md-6 col-md-offset-1">
                            <div>
                                <PostAJobForm {...this.props} />
                            </div>

                            <div className="help-block instruction hidden-bureaumode">
                                <p>{__("Your call for speakers will be posted to the largest group of experts who speak in the world. You will receive up to 10 candidates to interview and decide which to hire.")}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PostAPublicJobPage;