import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import MessageScrollListItem from "../MessageScrollListItem";
import { gettext as __ } from "../../utils/Trans";

import "./MessageScrollList.scss";

export default class MessageScrollList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            search: ""
        };
    }

    __onSearch = (e) => {
        this.setState({
            search: e.target.value
        });
    }

    render() {
        let {
            search
        } = this.state;

        let {
            messages,
            use_threads = false
        } = this.props;

        messages = _.reverse(_.sortBy(messages, "created"));

        messages = _.filter(messages, function(item) {
            if (search === "") { return true; }
            return (search !== "" && (
                (_.toLower(_.get(item, ["organization"])).indexOf(_.toLower(search)) !== -1) ||
                (_.toLower(_.get(item, ["speaker", "name"])).indexOf(_.toLower(search)) !== -1) ||
                (_.toLower(_.get(item, ["content"])).indexOf(_.toLower(search)) !== -1)
            ));
        });

        if (use_threads) {
            messages = _.groupBy(messages, function(item) {
                return (_.get(item, ["event_id"]) + "|" + _.get(item, ["speaker", "id"]));
            });
            messages = _.map(messages, function (items) {
                items = _.reverse(_.sortBy(items, ["speaker.id", "created"]));
                let first_msg = _.head(items);
                // let eid = _.get(first_msg, ["event_id"]);
                // let sid = _.get(first_msg, ["sender_speaker_id"], 0) > 0 ? _.get(first_msg, ["sender_speaker_id"]) : _.get(first_msg, ["receiver_speaker_id"]);

                return (<MessageScrollListItem key={_.get(first_msg, ["id"])} message={first_msg} thread={items} />);
            });
        } else {
            messages = _.map(messages, function (item) {
                // let eid = _.get(item, ["event_id"]);
                // let sid = _.get(item, ["sender_speaker_id"], 0) > 0 ? _.get(item, ["sender_speaker_id"]) : _.get(item, ["receiver_speaker_id"]);

                return (<MessageScrollListItem key={_.get(item, ["id"])} message={item} />);
            });
        }

        return (
            <div className="mp-message-scroll-list">
                <div>
                    <input type="text" value={search} placeholder={__("Search...")} className="form-control small" onChange={this.__onSearch} />
                </div>
                {messages}
            </div>
        );
    }
}

MessageScrollList.propTypes = {
    messages: PropTypes.array,
    use_threads: PropTypes.bool
};