import React from "react";
import PropTypes from "prop-types";

require("./OnlineBadge.css");

class OnlineBadge extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            style,
            online,
            away,
            offline,
            children
        } = this.props;

        if (!style) { style = {}; }

        let status = online ? "badge-online" :
                     away ? "badge-away" :
                     offline ? "badge-offline" :
                     "";

        return (
            <span className={"OnlineBadge badge " + status} style={style}>{children}</span>
        );
    }
}

OnlineBadge.propTypes = {
    style: PropTypes.object,
    online: PropTypes.bool,
    away: PropTypes.bool,
    offline: PropTypes.bool
};

export default OnlineBadge;