import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { gettext as __ } from "../../../../utils/Trans";
import VerticalScroll from "../../../../components/VerticalScroll";
import HorizontalScroll from "../../../../components/HorizontalScroll";
import getEventOfferStatusFromState, { OFFER_STATII } from "../../../../utils/getEventOfferStatusFromState";
import TogglePublicPostingModal from "../../modals/TogglePublicPostingModal";
import Candidate from "./Candidate";
import Promise from "es6-promise";
import { withRouter } from "react-router";

import "./CandidatesList.scss";

class CandidatesList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_public_posting_modal: false
        };
    }

    __onTogglePublicPostingModal = () => {
        this.setState({
            show_public_posting_modal: !this.state.show_public_posting_modal
        });
    }

    render() {
        let {
            candidates,
            event,
            speaker,
            whitelabel,
            event_has_an_accepted_offer,
            onAddCandidate
        } = this.props;

        let n_candidates = _.size(candidates);
        let event_is_public = (true === _.get(event, ["flags_as_map", "mp_job_board", "is_set"], false));
        let is_at_least_one_speaker_selected = !_.isNull(speaker);
        let selected_speaker_index = _.findIndex(candidates, {is_selected: true});

        let candidate_list = _.map(candidates, function (c) {
            return (
                <Candidate
                    key={c.sid}
                    speaker={c}
                    is_at_least_one_speaker_selected={is_at_least_one_speaker_selected}
                    is_selected={c.is_selected}
                    n_unread={c.n_unread}
                    has_offer={c.has_offer}
                    offer_accepted={c.offer_accepted}
                    href={"/shortlist/" + _.get(event, ["eid"]) + "/" + c.sid}
                    event_has_an_accepted_offer={event_has_an_accepted_offer}
                />
            );
        });

        if (n_candidates === 0) {
            return (
                <div>
                    <h4 className=" text-muted" style={{padding: "15px", margin: 0}}>
                        {__("Waiting For Candidates")}
                    </h4>
                    {event_is_public &&
                    <div className="hidden-sm hidden-xs" style={{padding: "20px"}}>
                        {!_.get(whitelabel, ["mp_keyvalues", "MP_PAJ_HIDE_PUBLIC_JOB_BOARD"], "0") === "1" &&
                        <p>
                            <strong>{__("Your event is listed on our public job board!")}</strong> {__("Now just sit back and wait for great presenters to apply.")}
                        </p>
                        }
                        <p>{__("You can also")}{" "}<span className="link" style={{cursor:"pointer"}} onClick={onAddCandidate}>{__("add candidates")}</span>{" "}{__("from the catalog.")}</p>
                    </div>
                    }
                    {!event_is_public && !_.get(whitelabel, "is_bureau_mode") &&
                    <div className="hidden-sm hidden-xs" style={{padding: "10px 10px 5px 20px"}}>
                        {!_.get(whitelabel, ["mp_keyvalues", "MP_PAJ_HIDE_PUBLIC_JOB_BOARD"], "0") === "1" &&
                        <p>
                            <strong>{__("Let great presenters find you!")}</strong>{"  "}{__("Make your job public and speakers can find you.")}
                        </p>
                        }
                        <p>{__("You can also")}{" "}<span className="link" onClick={onAddCandidate}>{__("add candidates")}</span>{" "}{__("from the catalog.")}</p>
                    </div>
                    }
                    {!event_is_public && _.get(whitelabel, "is_bureau_mode") &&
                    <div className="hidden-sm hidden-xs" style={{padding: "10px 10px 5px 20px"}}>
                        <p>{__("You do not have any presenters yet!  You should")}{" "}<span className="link" onClick={onAddCandidate}>{__("add candidates")}</span>{" "}{__("from the catalog.")}</p>
                    </div>
                    }
                </div>
            );
        }


        return (
            <div>
                <h4 className=" text-muted" style={{padding: "15px", margin: 0}}>
                    {n_candidates === 1
                        ?
                        __("%s candidate", n_candidates)
                        :
                        __("%s candidates", n_candidates)
                    }
                </h4>

                <div className="MP-CandidatesList">
                    <div className="hidden-sm hidden-xs">
                        <VerticalScroll>
                            <ul className="vs-shortlist">
                                {candidate_list}
                            </ul>
                        </VerticalScroll>
                    </div>
                    <div className="hidden-md hidden-lg">
                        {is_at_least_one_speaker_selected ?
                            <HorizontalScroll className="HorizontalScroll" auto_scroll={(selected_speaker_index + 1) * 84}>
                                <ul className="hs-shortlist" style={{minWidth: (n_candidates * 130)}}>
                                    {candidate_list}
                                </ul>
                            </HorizontalScroll>
                            :
                            <ul className="hs-shortlist">{candidate_list}</ul>
                        }
                    </div>
                </div>

                <div>
                    {event_is_public &&
                    <div className="hidden-sm hidden-xs" style={{padding: "20px"}}>
                        {!_.get(whitelabel, ["mp_keyvalues", "MP_PAJ_HIDE_PUBLIC_JOB_BOARD"], "0") === "1" &&
                        <p>
                            <strong>{__("Your event is listed on our public job board!")}</strong> {__("Now just sit back and wait for great presenters to apply.")}
                        </p>
                        }
                        <p>{__("You can also")}{" "}<span className="link" onClick={onAddCandidate}>{__("add candidates")}</span>{" "}{__("from the catalog.")}</p>
                    </div>
                    }
                    {!event_is_public && !_.get(whitelabel, "is_bureau_mode") &&
                    <div className="hidden-sm hidden-xs" style={{padding: "10px 10px 5px 20px"}}>
                        {!_.get(whitelabel, ["mp_keyvalues", "MP_PAJ_HIDE_PUBLIC_JOB_BOARD"], "0") === "1" &&
                        <p><strong>{__("Let great presenters find you!")}</strong>{"  "}{__("Make your")}{" "}<span className="link"
                            onClick={this.__onTogglePublicPostingModal}>{__("job public")}</span>{" "}{__("and speakers can find you.")}
                        </p>
                        }
                        <p>{__("You can also")}{" "}<span className="link" onClick={onAddCandidate}>{__("add candidates")}</span>{" "}{__("from the catalog.")}</p>
                    </div>
                    }
                    {this.state.show_public_posting_modal && <TogglePublicPostingModal event={event} onClose={this.__onTogglePublicPostingModal} />}
                </div>
            </div>
        );
    }
}

CandidatesList.propTypes = {
    candidates: PropTypes.array,
    event: PropTypes.object,
    speaker: PropTypes.object
};


////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {
    let offer_status = getEventOfferStatusFromState(state, _.get(ownProps.event, ["eid"]));

    return {
        event_has_an_accepted_offer: _.indexOf(OFFER_STATII, offer_status) >= _.indexOf(OFFER_STATII, "offer accepted"),
    };
};

var mapDispatchToProps = function (dispatch, ownProps) {
    return {
        onAddCandidate: function() {
            return new Promise((resolve) => {
                return resolve(dispatch({
                    type: "POSTAJOBPAGE_ADD_SPEAKERS_TO_EVENT",
                    eid: _.get(ownProps.event, ["eid"])
                }));
            }).then(() => {
                if (_.get(ownProps.whitelabel, ["bcode"]) === 'mp') {
                    return window.location = `/marketplace/search`;
                } else {
                    return window.location = `/s/${_.get(ownProps.whitelabel, ["bcode"])}/search`;
                }
            });
        }

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CandidatesList));