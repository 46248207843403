import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import { Col, OverlayTrigger, Popover, Row } from "react-bootstrap";
import moment from "moment";

class ReferralDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    __onShare = () => {
        if (_.isFunction(this.props.onShare)) {
            this.props.onShare();
        }
    }

    __onViewCollaborators = () => {
        if (_.isFunction(this.props.onViewCollaborators)) {
            this.props.onViewCollaborators();
        }
    }

    render() {
        let {
                affiliate_event
            } = this.props;

        let name = _.get(affiliate_event, ["organization"], "Shared Event");
        let session = _.get(affiliate_event, ["meeting_name"], "");
        let notes = _.get(affiliate_event, ["meeting_goals"]);
        let location = _.get(affiliate_event, ["location"]);
        let startdate = _.get(affiliate_event, ["startdate"]);

        return (
            <div>
                <div className="hidden-xs hidden-sm pull-right" style={{marginTop: "20px"}}>
                    <div className="btn btn-sharing" style={{color: "#605ca8", borderColor: "#605ca8", backgroundColor: "#e2e1fd"}} onClick={this.__onShare}><i className="fa fa-users"/>{" "}Share with others</div>
                    <div className="small text-center"><span className="link" onClick={this.__onViewCollaborators}>Sharing with {_.size(_.get(affiliate_event, ["shortlist", "collaborators"]))} others</span></div>
                </div>
                <div className="hidden-xs hidden-sm" style={{marginTop: "10px"}}>
                    <h3 className="text-muted">{__("Speaker Recommendations")}</h3>
                    <h1>
                        {name} <span style={{fontSize: "0.5em"}}>{session}</span>
                    </h1>
                </div>
                <div className="visible-xs visible-sm">
                    <h3 className="text-muted">{__("Speaker Recommendations")}</h3>
                    <h1>{name}<br/><span style={{fontSize: "0.5em", display:"block"}}>{session}</span></h1>
                </div>
                <Row>
                    <Col sm={12}>
                        {_.isEmpty(location) ? (<i>{"- location not set -"}</i>) : <b>{location}</b>} {!moment(startdate, "MM/DD/YYYY").isValid() ? <i>{"- date not set -"}</i> : <b>{" on " + moment(startdate, "MM/DD/YYYY").format("MMMM DD, YYYY")}</b>}

                    </Col>
                </Row>
                <Row>
                    <Col sm={12}>
                        Meeting Goals: {_.isEmpty(notes) ? "- not entered -" : ("\"" + notes + "\"")}
                        <OverlayTrigger placement="bottom" overlay={
                            <Popover id="change_date_location_popover">
                                After you CONFIRM SPEAKER AVAILABILITY you will be able to change these details.
                            </Popover>
                        }>
                            <span className="link small" style={{marginLeft: "2em"}}><i className="fa fa-edit"></i> change</span>
                        </OverlayTrigger>
                    </Col>
                </Row>
                <Row className="visible-xs visible-sm text-center" style={{marginTop:"2em"}}>
                    <div className="btn btn-sharing" style={{color: "#605ca8", borderColor: "#605ca8", backgroundColor: "#e2e1fd"}} onClick={this.__onShare}><i className="fa fa-users"/>{" "}Share with others</div>
                    <div className="small text-center"><span className="link" onClick={this.__onViewCollaborators}>Sharing with {_.size(_.get(affiliate_event, ["shortlist", "collaborators"]))} others</span></div>
                </Row>
                <hr/>
            </div>
        );
    }
}

ReferralDetails.propTypes = {
    affiliate_event: PropTypes.object.isRequired,
    onShare: PropTypes.func,
    onViewCollaborators: PropTypes.func
};

export default ReferralDetails;
