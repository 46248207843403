import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, reduxForm, SubmissionError } from "redux-form";
import { Modal } from "react-bootstrap";
import { gettext as __ } from "../../../../utils/Trans";
import LoadingSubmit from "../../../../components/LoadingSubmit";
import InlineError from "../../../../components/InlineError";
import Event from "../../../../actions/Event/Event";

class TogglePublicPostingForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch, props) {
        return dispatch(Event.save(values))
            .then(() => {
                if (_.isFunction(props.onClose)) {
                    props.onClose();
                }
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
            handleSubmit,
            submitting,
            error,
            is_public,
            onClose
        } = this.props;

        return (
            <Form role="form" onSubmit={handleSubmit(this.handleSubmit)}>
                <Modal.Body>
                    {error && <InlineError error={_.get(error, ["message"])} />}
                    <p>
                        {is_public
                            ? __("This job is currently open on the job board so that other presenters can apply. Would you like to make it INVITE-ONLY?")
                            : __("This job is currently invite-only. Would you like to make it OPEN so that other presenters can see it and apply?")}
                    </p>
                </Modal.Body>
                <Modal.Footer>
                    <LoadingSubmit is_saving={submitting} save_text={__("Updating...")} className="btn-success btn-block-xs">
                        {is_public
                            ? __("Yes, make this job INVITE-ONLY")
                            : __("Yes, make this job OPEN")}
                    </LoadingSubmit>
                    {" "}
                    <button type="button" className="btn btn-default btn-block-xs" disabled={submitting} onClick={onClose}>
                        {__("Never Mind")}
                    </button>
                </Modal.Footer>
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

TogglePublicPostingForm.propTypes = {
    event: PropTypes.object.isRequired,
    onClose: PropTypes.func,
    is_public: PropTypes.bool
};

function mapStateToProps(state, ownProps) {
    return {
        initialValues: {
            id: _.get(ownProps.event, ["eid"]),
            event_json: JSON.stringify({
                FlagsAsMap: {
                    mp_job_board: {
                        is_set: !ownProps.is_public
                    }
                }
            })
        }
    };
}

function mapDispatchToProps() {
    return {

    };
}

TogglePublicPostingForm = reduxForm({
    form: "shortlist_page_toggle_public_posting"
})(TogglePublicPostingForm);

export default connect(mapStateToProps, mapDispatchToProps)(TogglePublicPostingForm);