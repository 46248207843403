import React from "react";
import { Link } from "react-router-dom";
import { gettext as __ } from "../../../../utils/Trans";
import CustomerService from "../../../../components/CustomerService";
import InfoBox from "../../../../components/InfoBox";

class Sidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            user,
            whitelabel,
            sitename,
            tiptexts
        } = this.props;

        return (
            <div className="MP-Job-Sidebar">
                <div className="hidden-sm hidden-xs">
                    {__("Need to add more speakers?")}<br />
                    <a href="/marketplace/search">{__("Click here")}</a>{" "}{__("to go back to the search results page.")}
                    <p />
                    <hr />
                    <p />
                    {user &&
                    <div>
                        {__("Add speaker(s)")}{" "}
                        <Link to="/postaprivatejob">{__("to a new job.")}</Link>
                    </div>
                    }
                    <p />
                    <p />
                </div>
                <CustomerService whitelabel={whitelabel} />
                <InfoBox path="\mp\postajob\left" tiptexts={tiptexts} sitename={sitename} whitelabel={whitelabel} startexpanded={true}/>
                <p />
            </div>
        );
    }
}

export default Sidebar;