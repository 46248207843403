import _ from "lodash";
import Constants from "../utils/Constants";

function formatWhitelabel(data) {
    let whitelabel = _.assign({}, data);
    let wl_flags = _.get(whitelabel, "flags");

    _.set(whitelabel, ["is_espeakers"], _.get(whitelabel, ["bid"]) === Constants.TRUNK_BUREAU_ID);
    _.set(whitelabel, ["is_mpi"], (_.includes(Constants.MPI_BUREAU_ID, _.get(whitelabel, ["bid"], Constants.TRUNK_BUREAU_ID))));

    _.set(whitelabel, ["contact_us_url"], _.get(whitelabel, ["mp_keyvalues", "CONTACT_LINK"]));

    _.set(whitelabel, ["is_directory_mode"], _.has(wl_flags, "MP_DIRECTORY_MODE") && !!wl_flags["MP_DIRECTORY_MODE"]);
    _.set(whitelabel, ["is_bureau_mode"], _.has(wl_flags, "MP_INTERCEPT_ALL_MSG_TO_SPEAKERS") && !!wl_flags["MP_INTERCEPT_ALL_MSG_TO_SPEAKERS"]);
    _.set(whitelabel, ["is_offer_customerservice"],
        !(_.has(wl_flags, "MP_DIRECTORY_MODE") && !!wl_flags["MP_DIRECTORY_MODE"]) &&
        !(_.has(wl_flags, "MP_INTERCEPT_ALL_MSG_TO_SPEAKERS") && !!wl_flags["MP_INTERCEPT_ALL_MSG_TO_SPEAKERS"]) &&
        !(_.has(wl_flags, "MP_INTERCEPT_ALL_MSG_TO_SPEAKERS") && !!wl_flags["MP_INTERCEPT_ALL_MSG_TO_SPEAKERS"]) &&
        !(_.has(wl_flags, "IS_CHAPTER") && !!wl_flags["IS_CHAPTER"]) &&
        !(_.has(wl_flags, "IS_ASSOCIATION") && !!wl_flags["IS_ASSOCIATION"])
    );
    return whitelabel;
}

export default function(state = {}, action){
    switch(action.type){
        /*
         * WHITELABEL CASES
         */
        case "WHITELABEL_GET": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            let whitelabel = formatWhitelabel(action.data);
            _.setWith(new_state, ["whitelabel"], whitelabel, Object);
            _.setWith(new_state, ["whitelabels", id], whitelabel, Object);
            return new_state;
        }
        case "WHITELABEL_GET_ALL": {
            let new_state = _.cloneDeep(state);
            if (!_.isEmpty(action.data)) {
                _.each(action.data, function (item) {
                    let id = _.get(item, ["id"]);
                    let whitelabel = formatWhitelabel(action.data);
                    _.setWith(new_state, ["whitelabels", id], whitelabel, Object);
                    if (_.get(new_state, ["whitelabel", "bid"]) === id) {
                        _.setWith(new_state, ["whitelabel"], whitelabel, Object);
                    }
                });
            } else {
                _.setWith(new_state, ["whitelabels"], {}, Object);
            }
            return new_state;
        }
        case "WHITELABEL_DELETE": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            let whitelabels = _.get(new_state, ["whitelabels"]);
            _.omit(whitelabels, id);
            _.setWith(new_state, ["whitelabels"], whitelabels, Object);
            if (_.get(new_state, ["whitelabel", "bid"]) === id) {
                _.setWith(new_state, ["whitelabel"], null, Object);
            }
            _.setWith(new_state, ["whitelabel"], null, Object);
            return new_state;
        }
        case "WHITELABEL_PUT": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            let whitelabel = formatWhitelabel(action.data);
            if (_.get(new_state, ["whitelabel", "bid"]) === id) {
                _.setWith(new_state, ["whitelabel"], whitelabel, Object);
            }
            _.setWith(new_state, ["whitelabels", id], whitelabel, Object);
            return new_state;
        }
        case "WHITELABEL_POST": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            let whitelabel = formatWhitelabel(action.data);
            if (_.get(new_state, ["whitelabel", "bid"]) === id) {
                _.setWith(new_state, ["whitelabel"], whitelabel, Object);
            }
            _.setWith(new_state, ["whitelabels", id], whitelabel, Object);
            return new_state;
        }


        case "WHITELABEL_STATS_LOAD": {
            let new_state = _.cloneDeep(state);
            _.setWith(new_state, ["wl_stats"], action.data, Object);
            return _.assign({}, state, new_state);
        }
        case "WHITELABEL_LOAD": {
            let new_state = _.cloneDeep(state);
            let whitelabel = formatWhitelabel(_.get(action.data, ["whitelabel"]));
            _.setWith(new_state, ["whitelabel"], whitelabel, Object);
            return _.assign({}, state, new_state);
        }
        case "SITELISTS_LOAD": {
            let new_state = _.cloneDeep(state);
            let sitelists = action.data;
            _.setWith(new_state, ["sitelists"], sitelists, Object);
            return _.assign({}, state, new_state);
        }
        case "SITELISTS_LANGUAGE_LOAD": {
            let new_state = _.cloneDeep(state);
            _.setWith(new_state, ["language", action.lang], action.translation, Object);
            return _.assign({}, state, new_state);
        }
        default:
            return state;
    }
}
