import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import formatCurrency from "../../../../utils/formatCurrency";
import classnames from "classnames";

const formatFee = (low, high) => {
    if (low === 0 && high > 0) {
        return (<div>{__("up to")}{" "}{formatCurrency(high)}</div>);
    } else if (low > 0 && high > 0) {
        return (<div>{formatCurrency(low)} - {formatCurrency(high)}</div>);
    } else {
        return (<div>{__("Contact for fees")}</div>);
    }
}



class PricingTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_pricing: false
        };
    }

    __togglePricingTable = () => {
        this.setState({show_pricing: !this.state.show_pricing});
    }

    render() {
        let {
            speaker
        } = this.props;

        let fees = _.orderBy(_.get(speaker, ["fees"], []), ['vieworder', 'id'], ['desc', 'asc']);
        let fee_low = _.get(speaker, ["low_fee"], 0);
        let fee_high = _.get(speaker, ["high_fee"], 0);
        let v_fee_low = _.get(speaker, ["v_low_fee"], 0);
        let v_fee_high = _.get(speaker, ["v_high_fee"], 0);
        let show_virtual_fees = (v_fee_low > 0 || v_fee_high > 0);
        return (
            <div className="speakerPricingTable">
                <div className="row">
                    {show_virtual_fees && <div className="col-sm-6">
                        <h5 className="header legend">{__("Virtual Fee range")}</h5>
                    </div>}
                    <div className={classnames(["text-right", {"col-sm-6": show_virtual_fees}, {"col-sm-12": !show_virtual_fees}])}>
                        <h5 className="header legend">{__("On-Site Fee range")}</h5>
                    </div>
                </div>
                <div className="row">
                    {show_virtual_fees && <div className="col-sm-6">
                        {formatFee(v_fee_low, v_fee_high)}
                    </div>}
                    <div className={classnames(["text-right", {"col-sm-6": show_virtual_fees}, {"col-sm-12": !show_virtual_fees}])}>
                        {formatFee(fee_low, fee_high)}
                    </div>
                </div>
                {_.size(fees) > 0 && this.state.show_pricing &&
                <div>
                    <label className="header legend">{__("Additional Offered Services")}</label>
                    <table className="table">
                        <tbody>
                        {_.map(fees, (fee, i) => (<tr key={i}>
                                <td><label>{_.get(fee, ["description"])}</label></td>
                                <td className="priceOption nowrap">{formatCurrency(_.parseInt(_.get(fee, ["fee"])))}</td>
                            </tr>))}
                        </tbody>
                    </table>
                </div>}
                {_.size(fees) > 0 &&
                <div className="labellike pull-right link expandTableToggle" onClick={this.__togglePricingTable}>
                    {!this.state.show_pricing ? <span>{__("View All")}{" "}{_.size(fees) > 0 ? _.size(fees) : ""}{" "}{__("Services")}</span> : <span>{__("Hide Additional Services")}</span>}
                     <i className="icon-caret-down"></i>
                </div>}
            </div>
        );
    }
}

PricingTable.propTypes = {
    speaker: PropTypes.object
};

export default PricingTable;