import Balboa from "../Global/Balboa";
import Event from "../Event/Event";
import {gettext as __} from "../../utils/Trans";

export default function (params) {
    return (dispatch, getState) => {
        var key = ["cancelOffer", params.event_id, params.speaker_id];

        return Balboa(dispatch, getState)
            .post(key, "/mpagreement/archivedbybuyer", params)
            .then(() => {
                return dispatch(Event.get(params.event_id));
            })
            .then((response) => {
                dispatch({
                    type: "NOTIFICATION_CREATE",
                    message: __("We have let the presenter know that the offer has been canceled.")
                });
                return response;
            })
            .then((response) => {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            });
    };
};
