import React from "react";
import { Modal } from "react-bootstrap";
import { gettext as __ } from "../../../../utils/Trans";
import SpeakerRemoveForm from "./SpeakerRemoveForm";

export default class SpeakerRemoveModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speaker,
            shortlist,
            affiliate_event,
            collaborator,
            onClose
        } = this.props;

        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("Remove Candidate")}</Modal.Title>
                </Modal.Header>
                <SpeakerRemoveForm
                    shortlist={shortlist}
                    speaker={speaker}
                    collaborator={collaborator}
                    affiliate_event={affiliate_event}
                    onClose={onClose}
                />
            </Modal>
        );
    }
}