import _ from "lodash";
import React from "react";
import ReactToggle from "react-toggle";

export default class ToggleJustified extends React.Component {

    render() {
        let {
            input,
            label,
            name,
            help_text,
            hide_errors,
            meta: {
                touched,
                error,
                warning
            }
        } = this.props;

        return (
            <div className={"form-group " + (touched && error ? "has-error " : touched && warning ? "has-warning " : "")}>
                <div className="row">
                    <div className="col-xs-8">
                        <label htmlFor={name} onClick={() => {input.onChange(!input.value);}}>
                            <b>{label}</b>
                            {touched && error && <div className="text-danger">{error}</div> }
                            <div className="help-block small">
                                {help_text}
                            </div>
                        </label>
                    </div>
                    <div className="col-xs-4">
                        <ReactToggle
                            {...input}
                            value={_.toString(input.value)}
                            checked={!!input.value}
                        />
                    </div>
                </div>
                {!hide_errors && touched &&
                ((error && <div className="text-danger"><i className="fa fa-times" /> {error}</div>) ||
                    (warning && <div className="text-danger"><i className="fa fa-exclamation-triangle" /> {warning}</div>))}
            </div>
        );
    }
}