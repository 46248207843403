import _ from "lodash";
import React from "react";
import {gettext as __} from "../../../../../utils/Trans";
import {Row, Table} from "react-bootstrap";
import moment from 'moment';

export default class VirtualCvpReport extends React.Component {

  state = {
    speaker: null
  }

  static getDerivedStateFromProps = (props) => ({
    speaker: props.speaker
  })

  render() {
    let {
          speaker
        } = this.state;

    return (
      <React.Fragment>
        <Row style={{padding: "20px"}}>
          <h4 className="header">
            {_.get(speaker, ["firstname"])}{_.get(speaker, ["firstname"], "") !== "" ? "'s" : null}{__(" Virtual Certification Report")}
            <span className={"small"}>{" "}(<a target="_blank" rel="noopener noreferrer" href={"/certified/virtual/" + _.get(speaker, ["sid"])}>see full report »</a>)</span>
          </h4>
          <p className="text-muted">
            These items were tested by an eSpeakers certifier during a live assessment on {moment(_.get(speaker, ["cvp", "timestamp"])).format("MM/DD/YYYY")}.
          </p>
          <Table>
            <thead>
            <tr>
              <th>Tested</th>
              <th>Result</th>
              <th>Notes</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td className={"text-left"}>
                <h5><b>Meeting Tool Tested</b></h5>
                <p className={"text-muted small"}>The tool the speaker chose to use to perform the certification. This is not necessarily the only tool they are comfortable with, but it is probably the one they are most comfortable with.</p>
              </td>
              <td className={"text-center"}><i className={"fa fa-check-circle fa-2x text-success"}/></td>
              <td className={"text-muted"}>{_.get(speaker, ["cvp", "tool"], "n/a")}</td>
            </tr>
            <tr>
              <td className={"text-left"}>
                <h5><b>Test Location</b></h5>
                <p className={"text-muted small"}>Where the presenter was located during the certification.</p>
              </td>
              <td className={"text-center"}><i className={"fa fa-check-circle fa-2x text-success"}/></td>
              <td className={"text-muted"}>{_.get(speaker, ["cvp", "tested_location"], "n/a")}</td>
            </tr>
            <tr>
              <td className={"text-left"}>
                <h5><b>No Technical Problems</b></h5>
                <p className={"text-muted small"}>Any connection, camera or microphone issues were resolved within the first 60 seconds of the call.</p>
              </td>
              <td className={"text-center"}><i className={"fa fa-check-circle fa-2x text-success"}/></td>
              <td className={"text-muted"}></td>
            </tr>
            <tr>
              <td className={"text-left"}>
                <h5><b>Video Background</b></h5>
                <p className={"text-muted small"}>The background visible behind the speaker is free from distraction and appears professional.</p>
              </td>
              <td className={"text-center"}>{_.get(speaker, ["cvp", "check_background"], false) ? <i className={"fa fa-check-circle fa-2x text-success"}/> : null}</td>
              <td className={"text-muted"}>{_.get(speaker, ["cvp", "note_background"], "n/a")}</td>
            </tr>
            <tr>
              <td className={"text-left"}>
                <h5><b>Video Composition</b></h5>
                <p className={"text-muted small"}>The presenter's face is the dominant feature on the screen, and is easily seen against the background.</p>
              </td>
              <td className={"text-center"}>{_.get(speaker, ["cvp", "check_composition"], false) ? <i className={"fa fa-check-circle fa-2x text-success"}/> : null}</td>
              <td className={"text-muted"}>{_.get(speaker, ["cvp", "note_composition"], "n/a")}</td>
            </tr>
            <tr>
              <td className={"text-left"}>
                <h5><b>Lighting</b></h5>
                <p className={"text-muted small"}>Presenter's face is well-lit and easy to see.</p>
              </td>
              <td className={"text-center"}>{_.get(speaker, ["cvp", "check_lighting"], false) ? <i className={"fa fa-check-circle fa-2x text-success"}/> : null}</td>
              <td className={"text-muted"}>{_.get(speaker, ["cvp", "note_lighting"], "n/a")}</td>
            </tr>
            <tr>
              <td className={"text-left"}>
                <h5><b>Clear Audio</b></h5>
                <p className={"text-muted small"}>It is easy to hear and understand the speaker.</p>
              </td>
              <td className={"text-center"}>{_.get(speaker, ["cvp", "check_audio"], false) ? <i className={"fa fa-check-circle fa-2x text-success"}/> : null}</td>
              <td className={"text-muted"}>{_.get(speaker, ["cvp", "note_audio"], "n/a")}</td>
            </tr>
            <tr>
              <td className={"text-left"}>
                <h5><b>Background Noise</b></h5>
                <p className={"text-muted small"}>No distracting noises heard (dogs, construction sounds, keyboards, etc.)</p>
              </td>
              <td className={"text-center"}>{_.get(speaker, ["cvp", "check_noise"], false) ? <i className={"fa fa-check-circle fa-2x text-success"}/> : null}</td>
              <td className={"text-muted"}>{_.get(speaker, ["cvp", "note_noise"], "n/a")}</td>
            </tr>
            <tr>
              <td className={"text-left"}>
                <h5><b>Network Break Recovery</b></h5>
                <p className={"text-muted small"}>Presenter disconnected from session and was able to re-connect and resume presentation within 60 seconds.</p>
              </td>
              <td className={"text-center"}>{_.get(speaker, ["cvp", "check_recovery"], false) ? <i className={"fa fa-check-circle fa-2x text-success"}/> : null}</td>
              <td className={"text-muted"}>{_.get(speaker, ["cvp", "note_recovery"], "n/a")}</td>
            </tr>
            <tr>
              <td className={"text-left"}>
                <h5><b>Connection Type</b></h5>
                <p className={"text-muted small"}>Whether the computer the presenter uses connects to the Internet with a wired or wireless connection.</p>
              </td>
              <td className={"text-center"}><i className={"fa fa-check-circle fa-2x text-success"}/></td>
              <td className={"text-muted"}>{_.get(speaker, ["cvp", "connection_type"], "n/a")}</td>
            </tr>
            <tr>
              <td className={"text-left"}>
                <h5><b>Upload Bandwidth (Mbps)</b></h5>
                <p className={"text-muted small"}>The speaker's tested bandwidth must be at least 1.5 Mbs for reliable video streaming.</p>
              </td>
              <td className={"text-center"}><i className={"fa fa-check-circle fa-2x text-success"}/></td>
              <td className={"text-muted"}>{_.get(speaker, ["cvp", "upload_mbps"], "n/a")}</td>
            </tr>
            <tr>
              <td className={"text-left"}>
                <h5><b>Network Latency (ms)</b></h5>
                <p className={"text-muted small"}>The speaker's tested network latency must be less than 100ms for reliable video streaming.</p>
              </td>
              <td className={"text-center"}><i className={"fa fa-check-circle fa-2x text-success"}/></td>
              <td className={"text-muted"}>{_.get(speaker, ["cvp", "network_latency"], "n/a")}</td>
            </tr>
            </tbody>
          </Table>
        </Row>

        <Row style={{padding: "20px"}}>
          <h4 className="header">{__("Additional Tests Performed (Optional)")}</h4>
          <p className="text-muted">
            This information was self-reported by the presenter and was not tested during the assessment. It is provided for your information.
          </p>
          <Table>
            <thead>
            <tr>
              <th>Self-reported information</th>
              <th>Notes</th>
            </tr>
            </thead>
            <tbody>
            {_.get(speaker, ["cvp", "check_daylight"], false) &&
            <tr>
              <td className={"text-left"}>
                <h5><b>Tested Daytime Lighting</b></h5>
                <p className={"text-muted small"}>The speaker was certified with daytime lighting in their room.</p>
              </td>
              <td className={"text-muted"}>
                {!_.isEmpty(_.get(speaker, ["cvp", "note_daylight"])) ? _.get(speaker, ["cvp", "note_daylight"]) : <i>No notes</i>}
              </td>
            </tr>
            }
            {_.get(speaker, ["cvp", "check_nighttime"], false) &&
            <tr>
              <td className={"text-left"}>
                <h5><b>Tested Nighttime Lighting</b></h5>
                <p className={"text-muted small"}>The speaker was certified at night, so their room was lit entirely with artificial light.</p>
              </td>
              <td className={"text-muted"}>
                {!_.isEmpty(_.get(speaker, ["cvp", "note_nighttime"])) ? _.get(speaker, ["cvp", "note_nighttime"]) : <i>No notes</i>}
              </td>
            </tr>
            }
            {_.get(speaker, ["cvp", "check_improvedmic"], false) &&
            <tr>
              <td className={"text-left"}>
                <h5><b>Improved Mic</b></h5>
                <p className={"text-muted small"}>Used a lav, headset or other dedicated mic for superior audio vs. computer built-in microphone.</p>
              </td>
              <td className={"text-muted"}>
                {!_.isEmpty(_.get(speaker, ["cvp", "note_improvedmic"])) ? _.get(speaker, ["cvp", "note_improvedmic"]) : <i>No Notes</i>}
              </td>
            </tr>
            }
            {_.get(speaker, ["cvp", "check_multicamera"], false) &&
            <tr>
              <td className={"text-left"}>
                <h5><b>Multi-camera Shoot</b></h5>
                <p className={"text-muted small"}>The presenter uses more than one camera and switches between them.</p>
              </td>
              <td className={"text-muted"}>
                {!_.isEmpty(_.get(speaker, ["cvp", "note_multicamera"])) ? _.get(speaker, ["cvp", "note_multicamera"]) : <i>No Notes</i>}
              </td>
            </tr>
            }
            {_.get(speaker, ["cvp", "check_visuals_used"], false) &&
            <tr>
              <td className={"text-left"}>
                <h5><b>Visual Aids</b></h5>
                <p className={"text-muted small"}>(Self-reported, not tested) - The presenter regularly uses images or pre-recorded videos along with their live image.</p>
              </td>
              <td className={"text-muted"}>
                {!_.isEmpty(_.get(speaker, ["cvp", "note_visuals_used"])) ? _.get(speaker, ["cvp", "note_visuals_used"]) : <i>No Notes</i>}
              </td>
            </tr>
            }
            {_.get(speaker, ["cvp", "check_qa"], false) &&
            <tr>
              <td className={"text-left"}>
                <h5><b>Uses Audience Q&A or Chat</b></h5>
                <p className={"text-muted small"}>(Self-reported, not tested) - The presenter uses audience chat or Q&A in some presentations.</p>
              </td>
              <td className={"text-muted"}>
                {!_.isEmpty(_.get(speaker, ["cvp", "note_qa"])) ? _.get(speaker, ["cvp", "note_qa"]) : <i>No Notes</i>}
              </td>
            </tr>
            }
            {_.get(speaker, ["cvp", "check_polls"], false) &&
            <tr>
              <td className={"text-left"}>
                <h5><b>Uses Polls and Questions</b></h5>
                <p className={"text-muted small"}>(Self-reported, not tested) - The presenter uses audience interactive polls and questions during some presentations.</p>
              </td>
              <td className={"text-muted"}>
                {_.get(speaker, ["cvp", "note_polls"], false) ? _.get(speaker, ["cvp", "note_polls"]) : <i>No Notes</i>}
              </td>
            </tr>
            }
            {_.get(speaker, ["cvp", "check_breakout"], false) &&
            <tr>
              <td className={"text-left"}>
                <h5><b>Uses Breakout Rooms</b></h5>
                <p className={"text-muted small"}>(Self-reported, not tested) - The presenter uses breakout rooms during some presentations.</p>
              </td>
              <td className={"text-muted"}>
                {_.get(speaker, ["cvp", "note_breakout"], false) ? _.get(speaker, ["cvp", "note_breakout"]) : <i>No Notes</i>}
              </td>
            </tr>
            }
            <tr>
              <td className={"text-left"}>
                <h5><b>Backup Internet Access</b></h5>
                <p className={"text-muted small"}>(Self-reported, not tested) - The presenter has a second, independent source of Internet access to use in case their primary connection goes down.</p>
              </td>
              <td className={"text-muted"}>
                {_.get(speaker, ["cvp", "backup_access"], false) ? _.get(speaker, ["cvp", "backup_access"]) : <i>No Notes</i>}
              </td>
            </tr>
            </tbody>
          </Table>
        </Row>
      </React.Fragment>
    );
  }
}