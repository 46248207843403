import _ from "lodash";

export default function(state = {}, action){
    switch(action.type){
        case "USER_STRIPE_LOAD": {
            let new_state = _.cloneDeep(state);
            if (_.isNull(_.get(new_state, ["stripe_cust_token"], null))) {
                // First time subscribers won't have a stripe_cust_token on their user, so set it
                _.set(new_state, ["stripe_cust_token"],
                    _.get(action, ["data", "id"]));
            }
            _.setWith(new_state, ["STRIPE"], _.get(action, ["data"]), Object);
            return _.assign({}, state, new_state);
        }
        case "USER_STRIPE_CLEAR": {
            let new_state = _.cloneDeep(state);
            _.set(new_state, ["stripe_cust_token"], null);
            _.set(new_state, ["STRIPE"], null);
            return _.assign({}, state, new_state);
        }
        default:
            return state;
    }
}
