import _ from "lodash";
import {gettext as __} from "./Trans";
import toBoolean from "./toBoolean";
import moment from "moment";
import {setEventFromRangeIndex} from "./BudgetRangeConverter";

export default function(data, feeranges){
    let event = {};
    event.eid = _.get(data, ["eid"], -1);

    if (event.eid === -1) { // only set values when we are posting a new event
        event.bureau_id = _.get(data, ["bureau_id"]);
        event.organization = _.get(data, ["name"]);
        event.language = _.get(data, ["language"]);
        event.deliveryMethod = _.get(data, ["delivery"]);

        // Make sure the start date is correctly formatted
        data.startdate = _.isDate(_.get(data, ["startdate"])) ? _.get(data, ["startdate"]) : moment(_.get(data, ["startdate"]), "MM/DD/YYYY").format('MM/DD/YYYY');

        if (/^Session on /i.test(event.meetingname)) {
            event.meetingname = __("Session on %s", moment(_.get(data, ["startdate"]), "MM/DD/YYYY").format('MMM Do, Y'));
        }

        let topic_id = _.parseInt(_.get(data, ["topic"]));
        if (!_.isNaN(topic_id) && topic_id > 0) {
            event.topic_ids = [topic_id];
        }
        event.budget_min = setEventFromRangeIndex(_.get(data, ["budget"]), feeranges).min;
        event.budget_max = setEventFromRangeIndex(_.get(data, ["budget"]), feeranges).max;

        event.Stagetime = [];
        let stagetime = {id: 0};
        stagetime.presenter_type = _.get(data, ["presenter_type"]);
        stagetime.starttime = _.get(data, ["startdate"]);
        stagetime.stoptime = _.get(data, ["startdate"]);
        if (/^Session on /i.test(event.meetingname)) {
            event.meetingname = __("Session on %s", moment(_.get(data, ["startdate"]), "MM/DD/YYYY").format('MMM Do, Y'));
        }
        event.Stagetime.push(stagetime);

        event.Venue = [];
        let venue = {id: 0};
        venue.city = data.city;
        venue.st = data.state;
        venue.country = data.country;
        event.Venue.push(venue);

        //event.date_tentative = data.date_tentative;
        _.set(event, ["flags_as_map", "date_tentative", "is_set"], _.get(data, "date_tentative", false));
    }
    event.affiliate_event_id = _.get(data, ["affiliate_event_id"], 0);
    event.message =  _.get(data, ["message"], "");
    _.set(event, "flags_as_map.mp_job_board.is_set", toBoolean(_.get(data, "post_public", false)));
    event.shortlist_sids = _.get(data, ["shortlist_sids"], []);
    event.meeting_goals = _.get(data, "meeting_goals", "");
    return event;
}
