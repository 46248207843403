import React from "react";

const Initials = ({input, placeholder, type, initial_name, initial_date, meta: { touched, error, warning }}) => (
    <div>
        <div className="row">
            <div className="col-xs-5">
                <div className="form-group">
                    <input type="text" readOnly={true} value={initial_name} className="form-control" />
                </div>
                <div className="text-muted" style={{borderTop:"1px solid #E8E8E8", padding: "5px", marginTop: "5px"}}>Name</div>
            </div>
            <div className="col-xs-3">
                <div className={"form-group " + (touched && error ? "has-error " : touched && warning ? "has-warning " : "")}>
                    <input {...input} placeholder={placeholder} type={type} className="form-control" />
                </div>
                <div className="text-muted" style={{borderTop:"1px solid #E8E8E8", padding: "5px", marginTop: "5px"}}>Initials</div>
            </div>
            <div className="col-xs-4">
                <div className="form-group">
                    <input type="text" readOnly={true} value={initial_date} className="form-control" />
                </div>
                <div className="text-muted" style={{borderTop:"1px solid #E8E8E8", padding: "5px", marginTop: "5px"}}>Date</div>
            </div>
        </div>
        {touched &&
        ((error && <div className="text-danger"><i className="fa fa-times" /> {error}</div>) ||
        (warning && <div className="text-danger"><i className="fa fa-exclamation-triangle" /> {warning}</div>))}
    </div>
);

export default Initials;