import {combineReducers} from "redux";
import {reducer as formReducer} from "redux-form";
import {sessionReducer} from "redux-react-session";
import { loadingBarReducer } from "react-redux-loading-bar";
/* Include app specific reducers */
import balboa3 from "./balboa3";
import Whitelabel from "./Whitelabel";
import dropdowns from "./dropdowns";
import App from "./App";
/* Include API specific reducers */
import AffiliateEvent from "./AffiliateEvent";
import Events from "./Events";
import BuyerEvents from "./BuyerEvents";
import GlobalLists from "./GlobalLists";
import ShortLists from "./ShortLists";
import Messages from "./Messages";
import EmbeddedShortlist from "./EmbeddedShortlist";
import User from "./User";
import Speakers from "./Speakers";
import SpeakerAvailability from "./SpeakerAvailability";
import SpeakerCourses from "./SpeakerCourses";
import SpeakerShortProfiles from "./SpeakerShortProfiles";
import SpeakerFullProfiles from "./SpeakerFullProfiles";
import SpeakerDirectMessage from "./SpeakerDirectMessage";
import Recaptcha from "./Recaptcha";
import Stripe from "./Stripe";
/* Include page specific reducers */
import SearchPage from "./SearchPage";
import ShortlistPage from "./ShortlistPage";
import PostAJobPage from "./PostAJobPage";
import ProfilePage from "./ProfilePage";
import UserPage from "./UserPage";
import BookMeNowPage from "./BookMeNowPage";

export default combineReducers({

    // Redux form reducer
    form: formReducer,

    // Session reducer
    sessionReducer: sessionReducer,

    // Loading Bar
    loadingBar: loadingBarReducer,

    // Global App reducers
    App: App,
    balboa3: balboa3,
    dropdowns: dropdowns,
    Whitelabel: Whitelabel,

    // API Specific reducers
    GlobalLists: GlobalLists,
    Events: Events,
    BuyerEvents: BuyerEvents,
    ShortLists: ShortLists,
    Messages: Messages,
    EmbeddedShortlist: EmbeddedShortlist,
    User: User,
    SpeakerAvailability: SpeakerAvailability,
    SpeakerCourses: SpeakerCourses,
    Speakers: Speakers,
    SpeakerShortProfiles: SpeakerShortProfiles,
    SpeakerFullProfiles: SpeakerFullProfiles,
    SpeakerDirectMessage: SpeakerDirectMessage,
    Recaptcha: Recaptcha,
    Stripe: Stripe,
    AffiliateEvent: AffiliateEvent,

    // Page Specific Reducers
    PostAJobPage: PostAJobPage,
    ProfilePage: ProfilePage,
    SearchPage: SearchPage,
    ShortlistPage: ShortlistPage,
    UserPage: UserPage,
    BookMeNowPage: BookMeNowPage
});
