import _ from "lodash";
import { gettext as __ } from "../../../../utils/Trans";
import React from "react";
import PropTypes from "prop-types";

class AwardsQuickStats extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speaker
        } = this.props;

        let awards = _.get(speaker, ["awards"], []);
        console.log(awards)
        return (
            <div className="quickStatsBox awards">
                <h5 className="header">{__("CERTIFICATIONS & AWARDS")}</h5>
                {_.map(awards, function (item, i) {
                    return (
                        <div key={i}>
                            <span title={_.get(item, ["alt"])}>
                                <img src={_.get(item, ["src"])} alt={_.get(item, ["alt"])} title={_.get(item, ["alt"])}/>
                            </span>
                            <br/>
                        </div>
                    );
                })}
            </div>
        );
    }
}

AwardsQuickStats.propTypes = {
    speaker: PropTypes.object.isRequired
};

export default AwardsQuickStats;