import _ from "lodash";
import Promise from "es6-promise";
import Balboa3 from "../../utils/Balboa3";
import BalboaToken from "../../utils/BalboaToken";
import {sessionService} from "redux-react-session";
import { showLoading, hideLoading } from "react-redux-loading-bar";

export default function(dispatch, getState) {
    if (!_.isFunction(getState)) throw new Error("Balboa() called with bad getState parameter.");

    let state = getState();
    let token = _.get(state, ["sessionReducer", "user", "token"]);
    if (_.isUndefined(token)) {
        token = BalboaToken(
            _.get(state, ["sessionReducer", "user", "username"]),
            _.get(state, ["sessionReducer", "user", "password"]),
            (_.get(state, ["sessionReducer", "user", "role"], "buyer") === "publisher" ? "UE" : "UB")
        );
    }

    let balboa3 = new Balboa3({
        default_params: {
            estoken: token,
            base_url: (process.env.NODE_ENV !== "production" ? "https://local.espeakers.com/balboa3" : "/balboa3")
        }
    });

    return {
        post: function(key, url, params, opts) {
            return this.api("POST", key, url, params, opts);
        },
        get: function(key, url, params, opts) {
            return this.api("GET", key, url, params, opts);
        },
        put: function(key, url, params, opts) {
            return this.api("PUT", key, url, params, opts);
        },
        del: function(key, url, params, opts) {
            return this.api("DELETE", key, url, params, opts);
        },
        api: function(method, key, url, params, opts) {
            // return function(dispatch, getState) {
            opts = opts || {};

            // Convert string keys to arrays for the Object Spread Operator
            // Otherwise the key "test" would become ["t", "e", "s", "t"]
            if (typeof key === "string") {
                key = [key];
            }

            var cache_ttl = _.isNumber(opts.cache_ttl) && !_.isNaN(opts.cache_ttl)
                ? opts.cache_ttl
                : (30 * 60 * 1000);

            let promise = balboa3.ajax(url, params, method)
                .then((response) => {
                    // Check for an updated token
                    if (_.has(response, ["estoken"])) {
                        sessionService.saveSession(_.get(response, ["estoken"]))
                            .then(() => {
                                return sessionService.loadUser();
                            })
                            .then((user) => {
                                return sessionService.saveUser(_.assign({}, user, {token: _.get(response, ["estoken"])}));
                            });
                    }
                    return response;
                })
                .then((response) => {
                    dispatch(hideLoading());
                    return response;
                })
                .then((response) => {
                    return dispatch({
                        type: "BALBOA3_ACTION_RESPONSE",
                        key: key,
                        data: _.get(response, ["data"], response), //prevent data.data
                        meta: _.get(response, ["meta"], response), //prevent data.data
                        timestamp: _.now()
                    });
                }).catch((err) => {
                    dispatch(hideLoading());
                    dispatch({
                        type: "BALBOA3_ACTION_RESPONSE",
                        key: key,
                        error: err,
                        data: [],
                        meta: {},
                        timestamp: _.now()
                    });
                    if (_.get(err, ["name"]) === "TokenException" || _.get(err, ["name"]) === "AuthenticationException") {
                        // Invalidate the session/force login screen
                        return sessionService.deleteSession()
                            .then(() => {
                                return sessionService.deleteUser();
                            })
                            .then(() => {
                                return Promise.reject(err);
                            });
                    } else if (_.get(err, ["name"]) === "AuthorizationException") {
                        // Handled on the each mapStateToProps with .catch
                        return Promise.reject(err);
                    } else if (_.get(err, ["name"]) === "SpeakerMissingException") {
                        // Handled on the profile page
                        return Promise.reject(err);
                    } else {
                        // Save the error and reject the promise
                        dispatch({
                            type: "ERROR_CREATE",
                            source: _.join(key, "_"),
                            error: err
                        });
                        return Promise.reject(err);
                    }
                });

            if (_.get(state, ["balboa3", ...key, "waiting"]) === true) {
                // already running
                let prev_promise = _.get(state, ["balboa3", ...key, "promise"]);
                return Promise.race([prev_promise, promise]);
            }
            if ((_.now() - _.get(state, ["balboa3", ...key, "timestamp_received"], 0)) < cache_ttl) {
                // if the cached copy has an error, then skip ahead and re-query it
                if (!_.isObject(_.get(state, ["balboa3", ...key, "error"], undefined))) {
                    // just leave what's in the cache
                    return Promise.resolve(_.get(state, ["balboa3", ...key]));
                }
            }

            dispatch(showLoading());


            dispatch({
                type: "BALBOA3_ACTION_WAITING",
                key: key,
                timestamp: _.now(),
                promise: promise
            });

            return promise;
        }
    };
}