import _ from "lodash";
import React from "react";
import MarketplaceNavbar from "./Navbars/Marketplace";
import WhitelabelNavbar from "./Navbars/Whitelabel";

export default class NavFooter extends React.Component {

  render() {
    let {
      whitelabel
    } = this.props;

    return (
      <div>
        {_.get(whitelabel, "is_espeakers", false)
          ? <MarketplaceNavbar {...this.props} />
          : <WhitelabelNavbar {...this.props} />
        }
      </div>
    );
  }
}