import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import HeroImage from "../../components/HeroImage";
import { gettext as __ } from "../../utils/Trans";

export default class IndustryPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // Scroll the page to the top
        window.scrollTo(0, 0);
    }

    render() {
        let {
            sitelists
        } = this.props;

        let topics = _.get(sitelists, ["industries"], []);

        return (
            <div className="industry-page">
                <div>
                    <HeroImage />
                </div>
                <div className="container">
                    <h1 className="text-center">{__("Browse By Expertise")}</h1>
                </div>
                <div className="container">
                    {_.map("ABCDEFGHIJKLMNOPQRSTUVWXYZ", function(alpha, i) {
                        let alpha_topics = _.filter(topics, function (item) {
                            return _.startsWith(item.name, alpha);
                        });
                        if (_.size(alpha_topics) === 0) {
                            return null;
                        }
                        return (
                            <div key={i}>
                                <h3>{alpha}</h3>
                                <div className="row">
                                    {_.map(alpha_topics, function(item, k) {
                                        return (
                                            <div key={k} className="col-sm-3">
                                                <a href={"/marketplace/search?q=" + JSON.stringify({label: _.replace(_.get(item, ["name"]), "&", "%26"), type: "industry", value: "industry_" + _.get(item, ["id"])})}>{_.get(item, ["name"])}</a>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

IndustryPage.propTypes = {
    sitelists: PropTypes.object
};