import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import PriceRangeQuickStats from "./PriceRangeQuickStats";
import RecommendationsQuickStats from "./RecommendationsQuickStats";
import AwardsQuickStats from "./AwardsQuickStats";
import CareerQuickStats from "./CareerQuickStats";
import LanguagesQuickStats from "./LanguagesQuickStats";
import WorkedInCountriesQuickStats from "./WorkedInCountriesQuickStats";
import AssociationsQuickStats from "./AssociationsQuickStats";
import SpecialOfferQuickStats from "./SpecialOfferQuickStats";
import LocationQuickStats from "./LocationQuickStats";
import MembersinceQuickStats from "./MembersinceQuickStats";

class QuickStats extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speaker,
            whitelabel,
            onShowTab
        } = this.props;

        return (
            <div id="quickStats" className="thumbnail text-center">
                <div className="caption">
                    <LocationQuickStats speaker={speaker} />
                    {!_.get(whitelabel, ["flags", "MP_HIDE_FEES"]) &&
                        <PriceRangeQuickStats speaker={speaker} />
                    }
                    {_.get(speaker, ["specialoffer"]) &&
                    <SpecialOfferQuickStats speaker={speaker} />
                    }
                    {(_.size(_.get(speaker, ["recommendations"], [])) > 0) &&
                    <RecommendationsQuickStats speaker={speaker} onShowTab={onShowTab} />
                    }
                    {_.get(speaker, ["awards"], []).length > 0 &&
                    <AwardsQuickStats speaker={speaker} />
                    }
                    {_.get(speaker, ["careerYears"]) > 0 &&
                    <CareerQuickStats speaker={speaker} />
                    }
                    {_.get(speaker, ["languages"], []).length > 0 &&
                    <LanguagesQuickStats speaker={speaker} />
                    }
                    {_.get(speaker, ["worked_in_countries"], []).length > 0 &&
                    <WorkedInCountriesQuickStats speaker={speaker} />
                    }
                    {_.get(speaker, ["associations"]) &&
                    <AssociationsQuickStats speaker={speaker} />
                    }
                    {_.get(speaker, "member_since") && _.get(whitelabel, ["flags", "MP_USE_MARKETPLACE_DIRECTORY"], false) &&
                    <MembersinceQuickStats speaker={speaker}/>
                    }
                </div>
            </div>
        );
    }
}

QuickStats.propTypes = {
    speaker: PropTypes.object.isRequired,
    whitelabel: PropTypes.object,
    onShowTab: PropTypes.func
};

export default QuickStats;