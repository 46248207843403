import _ from "lodash";

export default function(state = {}, action){
    switch(action.type){
        case "SPEAKER_AVAILABILITY": {
            let new_state = _.cloneDeep(state);
            let id = action.id;
            _.setWith(new_state, [id], action.data, Object);
            return new_state;
        }
        case "SPEAKER_AVAILABILITY_ALL": {
            let new_state = _.cloneDeep(state);
            _.each(action.data, function(item, sid) {
                let id = sid;
                _.setWith(new_state, [id], item, Object);
            });
            return new_state;
        }
        default:
            return state;
    }
}
