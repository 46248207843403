import _ from "lodash";
import React from "react";
import { Alert } from "react-bootstrap";
import md5 from "md5";
import Cookies from "js-cookie";

export default class SearchBanner extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            hash: md5(_.get(props.whitelabel, ["mp_keyvalues", "SEARCH_BANNER_HEADER"], "") || ""),
            show: !(Cookies.get("hide_search_banner_" + md5(_.get(props.whitelabel, ["mp_keyvalues", "SEARCH_BANNER_HEADER"], "") || "")) || _.has(props.qs, ["nobanner"]) || false)
        };
    }

    __handleDismiss = () => {
        Cookies.set("hide_search_banner_" + this.state.hash, true, {
            expires: 30, //days from now
        });
        this.setState({
            show: !this.state.show
        });
    }

    render() {
        let {
            show
        } = this.state;
        let {
            whitelabel
        } = this.props;

        let banner_variant = _.get(whitelabel, ["mp_keyvalues", "SEARCH_BANNER_VARIANT"]) || '';
        let banner_header = _.get(whitelabel, ["mp_keyvalues", "SEARCH_BANNER_HEADER"]) || '';
        let banner_text = _.get(whitelabel, ["mp_keyvalues", "SEARCH_BANNER_TEXT"]) || '';

        if (!show || banner_variant === '' || banner_text === '') {
            return null;
        }

        return (
            <Alert bsStyle={banner_variant} onDismiss={this.__handleDismiss}>
                <h4>{banner_header}</h4>
                <div className="text-left" dangerouslySetInnerHTML={{ __html: banner_text }}/>
            </Alert>
        );
    }
}