import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { gettext as __ } from "../../../../utils/Trans";
import { Message } from "../../../../components/FormComponents";
import LoadingSubmit from "../../../../components/LoadingSubmit";
import InlineError from "../../../../components/InlineError";
import declineOffer from "../../../../actions/ShortList/declineOffer";

class DeclineOfferForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch, props) {
        return dispatch(declineOffer(values))
            .then(() => {
                if (_.isFunction(props.onClose)) {
                    props.onClose();
                }
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
            event,
            speaker,
            handleSubmit,
            submitting,
            error,
            onClose
        } = this.props;

        return (
            <Form role="form" onSubmit={handleSubmit(this.handleSubmit)}>
                <div className="modal-body">
                    {error && <InlineError error={_.get(error, ["message"])} />}
                    <div className="row">
                        <div className="col-sm-12">
                            <p>
                                {__("Declining the offer will notify the speaker that the terms were not suitable.")}
                            </p>
                            <p>
                                {__("It is common to")}{" "}
                                <Link to={"/shortlist/" + _.get(event, ["eid"]) + "/" + _.get(speaker, ["sid"]) + "/propose-different-terms"}>{__("propose different terms")}</Link>
                                {" "}{__("rather than declining.")}
                            </p>
                        </div>
                    </div>
                    <Field
                        name="message"
                        type="textarea"
                        className="form-control"
                        placeholder={__("I am declining this offer because...")}
                        component={Message}
                    />
                </div>
                <div className="modal-footer">
                    <LoadingSubmit is_saving={submitting} save_text={__("Declining...")} className="btn-warning btn-block-xs">
                        {__("Decline Offer")}
                    </LoadingSubmit>
                    {" "}
                    <div className="btn btn-default btn-block-xs" disabled={submitting} onClick={onClose}>
                        {__("Not Yet")}
                    </div>
                </div>
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

DeclineOfferForm.propTypes = {
    event: PropTypes.object.isRequired,
    speaker: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

function mapStateToProps(state, ownProps) {
    return {
        initialValues: {
            message: "",
            event_id: _.get(ownProps.event, ["eid"]),
            speaker_id: _.get(ownProps.speaker, ["sid"])
        }
    };
}

function mapDispatchToProps() {
    return {

    };
}

DeclineOfferForm = reduxForm({
    form: "shortlist_page_decline_offer",
    validate: function(values) {
        const errors = {};
        if (!values.message) {
            errors.message = __("Please enter a message letting the speaker know why you are not accepting this offer.");
        }
        return errors;
    }
})(DeclineOfferForm);

export default connect(mapStateToProps, mapDispatchToProps)(DeclineOfferForm);