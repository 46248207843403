import _ from "lodash";

export default function(state = {}, action) {
    switch(action.type) {
        case "USER_SAVE": {
            let new_state = _.cloneDeep(state);
            let cust_data = action.data;
            // don't overwrite the stripe_cust_token if it is set
            if (_.isNull(_.get(cust_data, ["stripe_cust_token"], null))) {
                let old_stripe_cust_token = _.get(state, ["stripe_cust_token"], null);
                _.set(cust_data, ["stripe_cust_token"], old_stripe_cust_token);
            }
            return _.assign({}, new_state, cust_data);
        }
        case "USER_STRIPE_LOAD": {
            let new_state = _.cloneDeep(state);
            if (_.isNull(_.get(new_state, ["stripe_cust_token"], null))) {
                // First time subscribers won't have a stripe_cust_token on their user, so set it
                _.set(new_state, ["stripe_cust_token"], _.get(action, ["data", "id"]));
            }
            _.setWith(new_state, ["STRIPE"], _.get(action, ["data"]), Object);
            return _.assign({}, state, new_state);
        }
        default: {
            return state;
        }
    }
}
