import React from "react";
import dateformat from "dateformat";
import formatCurrency from "../../../../utils/formatCurrency";
import { gettext as __ } from "../../../../utils/Trans";

class FeeLine extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            fee
        } = this.props;


        let duedate = (fee.due ? new Date(fee.due) : new Date()) || new Date();
        if (duedate.getFullYear() === 1970) { // fix for timestamps that aren't in milliseconds
            duedate = (fee.due ? new Date(fee.due * 1000) : new Date()) || new Date();
        }

        let description = fee.description || __("No Description");
        let amount = fee.fee || 0.00;

        return (
            <tr>
                <td>
                    <div>{description}</div>
                    <div className="text-muted small">{__("Due on")}{" "}{dateformat(duedate, "mmm d, yyyy")}</div>
                </td>
                <td className="text-right">
                    {formatCurrency(amount, 2)}
                </td>
            </tr>
        );
    }
}

export default FeeLine;