import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { gettext as __ } from "../../../../utils/Trans";
import { Modal } from "react-bootstrap";
import UserStripe from "../../../../actions/User/UserStripe";
import assertBalboa from "../../../../utils/assertBalboa";
import StripeCheckout from "react-stripe-checkout";
import PlaidLink from "react-plaid-link";
import PaymentMethodBox from "../../../../components/PaymentMethodBox";
import { PLAID_API_KEY, PLAID_ENVIRONMENT, STRIPE_API_KEY } from "../../../../utils/ApiKeys";
import { MailPaperCheckButton, PayDepositButton } from "../../components/Buttons";


class ChoosePaymentMethodModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    onStripeToken(token) {
        if (_.isFunction(this.props.connectBuyerWithStripe)) {
            this.props.connectBuyerWithStripe(token);
        }
    }

    onPlaidToken(token, metadata) {
        if (_.isFunction(this.props.connectBuyerWithPlaid)) {
            this.props.connectBuyerWithPlaid(token, metadata.account_id);
        }
    }

    render() {
        let {
            user,
            is_saving,
            has_stripe,
            onClose
        } = this.props;

        let primary_payment_method = _.find(_.get(user, ["STRIPE", "sources", "data"], function(o) { return o.id === _.get(user, ["STRIPE","default_source"]); }));

        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("Choose Payment Method")}</Modal.Title>
                </Modal.Header>
                <div className="modal-body">
                    <div>
                        {primary_payment_method &&
                        <div>
                            <p>{__("Your current payment method on your account is shown below.")}</p>
                            <PaymentMethodBox stripe={primary_payment_method}/>
                        </div>
                        }
                        {is_saving ?
                            <div className="panel panel-default">
                                <div className="panel-body text-center"><i className="fa fa-spin fa-circle-o-notch fa-2x"></i></div>
                            </div>
                            :
                            <div className="help-block">
                                {__("eSpeakers Marketplace allows you to pay your speakers securely!  You have the choice of Credit Card, Bank Account, or even mailing a paper check.  To get started, simply choose your preferred method of payment.")}
                            </div>
                        }
                        <hr />
                        <div className="row">
                            <div className="col-xs-12 col-sm-4" style={{margin:"5px 0px"}}>
                                <StripeCheckout
                                    name="eSpeakers Marketplace"
                                    description={__("Connect Automatic Payments")}
                                    ComponentClass="div"
                                    panelLabel={__("Connect")}
                                    amount={0}
                                    token={(token) => (this.onStripeToken(token))}
                                    email={_.get(user, ["email"])}
                                    stripeKey={STRIPE_API_KEY}
                                >
                                    <div className="btn btn-default btn-block">{__("Add Credit Card")}</div>
                                </StripeCheckout>
                            </div>
                            <div className="col-xs-12 col-sm-4" style={{margin:"5px 0px"}}>
                                <PlaidLink
                                    publicKey={PLAID_API_KEY}
                                    product={["auth"]}
                                    apiVersion="v2"
                                    env={PLAID_ENVIRONMENT}
                                    selectAccount={true}
                                    clientName="eSpeakers Marketplace"
                                    onSuccess={(token, metadata) => (this.onPlaidToken(token, metadata))}
                                    className="btn btn-default btn-block"
                                    style={{}}
                                >{__("Add Bank Account")}</PlaidLink>
                            </div>
                            <div className="col-xs-12 col-sm-4" style={{margin:"5px 0px"}}>
                                <MailPaperCheckButton {...this.props} />
                            </div>
                        </div>
                    </div>
                </div>
                {has_stripe &&
                <div className="modal-footer">
                    <PayDepositButton {...this.props} />
                </div>
                }
            </Modal>
        );
    }
}

ChoosePaymentMethodModal.propTypes = {
    event: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    speaker: PropTypes.object,
    onClose: PropTypes.func
};

////////////////////////////////////////////////////////////////////////////////
//Connect it up

const mapStateToProps = function(state){
    let saving_ajax = assertBalboa(state, [
        ["connectBuyerWithStripe"],
        ["connectBuyerWithPlaid"]
    ]);

    return {
        is_saving: saving_ajax.waiting
    };
};

const mapDispatchToProps = function(dispatch){
    return {
        connectBuyerWithStripe: function(token) {
            dispatch(UserStripe.connectStripe(token));
        },
        connectBuyerWithPlaid: function(token, account_id) {
            dispatch(UserStripe.connectPlaid({
                public_token: token,
                account_id: account_id
            }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChoosePaymentMethodModal);
