import _ from "lodash";
import React from "react";
import VirtualCertified from "../VirtualCertified";

import no_photo from "./no-photo-male.jpg";

class SpeakerHeadshot extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speaker,
            onShowTab
        } = this.props;

        return (
            <div className="speaker-headshot">
                <img
                    className="img-responsive center-block thumbnail"
                    src={_.get(speaker, ["mainPhotoURL"])}
                    alt={_.get(speaker, ["speakername"])}
                    onError={function (e) {
                        e.target.src = no_photo;
                    }}
                />
                {_.get(speaker, ["flags_as_map", "virtual_certified", "is_set"]) && <VirtualCertified speaker={speaker} onShowTab={onShowTab} />}
            </div>
        );
    }
}

export default SpeakerHeadshot;