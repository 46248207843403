import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Popover } from "react-bootstrap";
import { gettext as __ } from "../../utils/Trans";
import formatPhone from "../../utils/formatPhone";
import isValidEmail from "../../utils/isValidEmail";
import User from "../../actions/User/User";
import {
    Field,
    Form,
    formValueSelector,
    reduxForm,
    SubmissionError,
} from 'redux-form';
import { Phone, Text, Select } from "../../components/FormComponents";

class AccountForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch)
    {
        return dispatch(User.update(values))
            .then(() => {
                return dispatch({
                    type: "NOTIFICATION_CREATE",
                    message: __("Your user information has been updated.")
                });
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
            handleSubmit,
            submitSucceeded,
            submitting,
            error,
            user,
            sitelists,
            sendVerificationEmail,
            selected_country
        } = this.props;

        let is_verified = _.get(user, ["email_verified"]);

        return (
            <div>
                <Form className="MP-UserForm mp-style-form" onSubmit={handleSubmit(this.handleSubmit)}>
                    <h3>{__("My Profile")}</h3>
                    { is_verified &&
                        <div>
                            <span className="label label-success">
                                <i className="fa fa-check"></i>{__(" Verified")}
                            </span>
                        </div>
                    }
                    {!is_verified &&
                    <div className="alert alert-warning">
                            <span className="label label-warning">
                                <i className="fa fa-times"></i>{__(" Not Verified")}
                            </span>
                        <div className="help-block">
                            <p>{__("You need to verify your email address before you can use the Marketplace. Check your inbox or resend the verification email")}</p>
                            <div className="btn btn-default" onClick={sendVerificationEmail}>{__("resend the verification email")}</div>
                        </div>
                    </div>
                    }
                    <hr />
                    <div className="row">
                        <div className="col-sm-6 col-md-4">
                            <Field
                                name="firstname"
                                component={Text}
                                label={__("First name")}
                                placeholder={__("First")}
                            />
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <Field
                                name="lastname"
                                component={Text}
                                label={__("Last name")}
                                placeholder={__("Last")}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-4">
                            <Field
                                name="company"
                                component={Text}
                                label={__("Company")}
                                placeholder={__("My Company")}
                                help_text={__("Enter your company name here.  If you don't have a company or this is a personal account, leave this field blank.")}
                            />
                        </div>
                        <div className="col-sm-6 col-md-4">
                            <Field
                                name="job_title"
                                component={Text}
                                label={__("Job Title")}
                                placeholder={__("Marketing Director")}
                                help_text={__("Enter your job title name here.  If you don't have a company or this is a personal account, leave this field blank.")}
                            />
                        </div>
                    </div>

                    <h3>{__("Contact Settings")}</h3>
                    <hr />
                    <div className="help-block">
                        {__("Speakers like to get to know you before committing to a speaking engagement.  Enter the best contact phone number here in case a speaker needs to contact you.")}
                    </div>
                    <div className="row">
                        <div className="col-sm-6 col-md-4">
                            <Field
                                name="phone_number"
                                component={Phone}
                                label={__("Phone")}
                                placeholder={__("(555) 555-5555")}
                                normalize={formatPhone}
                            />
                        </div>
                        <div className="col-sm-6 col-md-8">
                            {_.isNull(_.get(user, ["phone_number"])) &&
                            <div className="hidden-sm hidden-xs">
                                <Popover
                                    id="popover-phone-number"
                                    className="alert-warning"
                                    placement="right"
                                >
                                    {__("We need your phone number to complete your account profile so that speakers can contact you regarding your jobs.")}
                                </Popover>
                            </div>
                            }
                            {_.isNull(_.get(user, ["phone_number"])) &&
                            <div className="hidden-md hidden-lg alert alert-warning">
                                {__("We need your phone number to complete your account profile so that speakers can contact you regarding your jobs.")}
                            </div>
                            }
                        </div>
                    </div>

                    <h3>{__("Login Settings")}</h3>
                    <hr />
                    <div className="help-block">
                        {__("You will use your email address to login to your account.  We will also use this email address to communicate with you and let speakers contact you.")}
                    </div>

                    <div className="row">
                        <div className="col-sm-6 col-md-4">
                            <Field
                                name="email"
                                component={Text}
                                label={__("Email")}
                                placeholder={__("user@company.com")}
                            />
                        </div>
                        <div className="col-sm-6 col-md-8">
                            {_.isNull(_.get(user, ["email"])) &&
                                <div className="hidden-sm hidden-xs">
                                    <Popover
                                        id="popover-email"
                                        placement="right"
                                    >
                                        {__("We need your email address to complete your account profile so that speakers can contact you regarding your jobs.")}
                                    </Popover>
                                </div>
                            }
                            {_.isNull(_.get(user, ["email"])) &&
                                <div className="hidden-md hidden-lg alert alert-warning">
                                    {__("We need your email address to complete your account profile so that speakers can contact you regarding your jobs.")}
                                </div>
                            }
                        </div>
                    </div>

                    <h3>{__("Location")}</h3>
                    <hr />
                    <div className="help-block">
                        <p>{__("We use your country to help prevent fraud using industry standard verification practices.")}</p>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <Field
                                name="billing_country"
                                component={Select}
                                label={__("Country")}
                                placeholder={__("Country")}
                            >
                                <optgroup label={__("Common Countries")}>
                                    {_.map(_.uniqBy(_.filter(_.get(sitelists, ["universal", "countrylist"]), (country) => (!_.includes(["US", "UK", "Great Britain"], _.get(country, ["lc"])) && _.includes(["US", "CANADA", "UK"], _.get(country, ["sc"])))), "lc"), (country, i) => (
                                        <option key={i} value={_.get(country, ["sc"])}>{_.get(country, ["lc"])}</option>
                                    ))}
                                </optgroup>
                                <optgroup label={__("All Countries")}>
                                    {_.map(_.uniqBy(_.filter(_.get(sitelists, ["universal", "countrylist"]), (country) => (!_.includes(["US", "UK", "Great Britain"], _.get(country, ["lc"])) && !_.includes(["US", "CANADA", "UK"], _.get(country, ["sc"])))), "lc"), (country, i) => (
                                        <option key={i} value={_.get(country, ["lc"])}>{_.get(country, ["lc"])}</option>
                                    ))}
                                </optgroup>
                            </Field>
                        </div>
                    </div>
                    {_.toUpper(selected_country) === "INDIA" &&
                    <React.Fragment>
                        <p>
                            {__("Our payment processor, Stripe, requires you to enter your billing address because you are in India, per the government regulations regarding ")}
                            <a href={"https://stripe.com/docs/india-accept-international-payments"} target={"_blank"} rel="noopener noreferrer">International Payments located outside of India</a>.
                        </p>
                        <p>
                            {__("Additional help is available on the ")}<a href={"https://support.stripe.com/questions/india-faq"} target={"_blank"} rel="noopener noreferrer">India FAQs</a>.
                        </p>
                        <div className="row">
                            <div className="col-sm-6 col-md-8">
                                <Field
                                    name="billing_address"
                                    component={Text}
                                    label={__("Address")}
                                    placeholder={__("123 Street")}
                                />
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <Field
                                    name="billing_address2"
                                    component={Text}
                                    label={__("Apt #")}
                                    placeholder={__("Apt. 123")}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-6 col-md-4">
                                <Field
                                    name="billing_city"
                                    component={Text}
                                    label={__("City")}
                                    placeholder={__("City")}
                                />
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <Field
                                    name="billing_state"
                                    component={Text}
                                    label={__("State/Province")}
                                    placeholder={__("State")}
                                />
                            </div>
                            <div className="col-sm-6 col-md-4">
                                <Field
                                    name="billing_zip"
                                    component={Text}
                                    label={__("Postal Code")}
                                    placeholder={__("Postal Code")}
                                />
                            </div>
                        </div>
                    </React.Fragment>
                    }

                    <div className="form-group">
                        <button type="submit" className="btn btn-success" disabled={submitting}>
                            {submitting ? <i className="fa fa-spin fa-circle-o-notch" /> : <i/>} {__("Save My Profile")}
                        </button>
                        {submitSucceeded && <span className="text-success"> <i className="fa fa-check" /> {__("Changes Saved")}</span>}
                        {error && <span className="text-danger"> <i className="fa fa-times" /> {_.get(error, ["message"])}</span>}
                    </div>
                </Form>
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function(state, ownProps) {
    let selector = formValueSelector("account_edit_form");
    return {
        selected_country: selector(state, "billing_country"), // used to filter the states
        initialValues: {
            id: _.get(ownProps.user, ["id"]),
            firstname: _.get(ownProps.user, ["firstname"]),
            lastname: _.get(ownProps.user, ["lastname"]),
            email: _.get(ownProps.user, ["email"]),
            company: _.get(ownProps.user, ["company"]),
            job_title: _.get(ownProps.user, ["job_title"]),
            phone_number: formatPhone(_.get(ownProps.user, ["phone_number"])),
            billing_address: _.get(ownProps.user, ["billing_address"]),
            billing_address2: _.get(ownProps.user, ["billing_address2"]),
            billing_city: _.get(ownProps.user, ["billing_city"]),
            billing_state: _.get(ownProps.user, ["billing_state"]),
            billing_country: _.get(ownProps.user, ["billing_country"]),
            billing_zip: _.get(ownProps.user, ["billing_zip"])
        }
    };
};

var mapDispatchToProps = function(dispatch, ownProps) {
    return {
        sendVerificationEmail: function() {
            return dispatch(User.sendverifyemail())
                .then(function(response) {
                    // Save the user to the state
                    dispatch({
                        type: "NOTIFICATION_CREATE",
                        message: _.get(response, ["data", "message"])
                    });
                    return response;
                })
                .catch((err) => {
                    if (_.get(err, ["name"]) === "AuthorizationException") {
                        ownProps.history.push("/error");
                    }
                });
        }
    };
};

AccountForm = reduxForm({
    form: "account_edit_form",
    validate: function(values) {
        const errors = {};
        if (!values.firstname) {
            errors.firstname = __("Please enter your first name.");
        }
        if (!values.lastname) {
            errors.lastname = __("Please enter your last name.");
        }
        if (!values.phone_number) {
            errors.phone_number = __("Please enter your phone number.");
        }
        if (!values.email) {
            errors.email = __("Please enter your email address.");
        }
        if (!isValidEmail(values.email)) {
            errors.email = __("Please enter a valid email address.");
        }
        return errors;
    }
})(AccountForm);

export default connect(mapStateToProps, mapDispatchToProps)(AccountForm);