import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { gettext as __ } from "../../../../utils/Trans";
import RegisterForm from "../../RegisterForm";
import Analytics from "../../../../utils/Analytics";

let img = require("../../choose-speaker.jpg");

class PostAJobPrivate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    LoginOnClick() {
        Analytics.sendEvent("BuyerLogin", "click", 0, "signup page");
    }

    render() {

        let {
            location
        } = this.props;

        return (
            <div>
                <h1 className=" page-header" style={{fontSize: "52px", marginBottom: "40px"}}>
                    {__("You're almost there!")}
                    {" "}
                    <span className="small" style={{fontSize: "14px"}}>{__("Already have an account?")} <Link to={_.assign({}, location, {pathname: "/signin"})} onClick={this.LoginOnClick.bind(this)}>{__("Sign In")}</Link></span>
                </h1>
                <div className="row">
                    <div className="col-md-5">
                        <img src={img} alt="choose speaker" className="img-responsive img-rounded hidden-sm hidden-xs"/>
                        <h2>{__("Get Results Fast!")}</h2>
                        <br/>
                        <h4 className="help-block hidden-bureaumode">
                            {__("Create an account and your job will be shown to")}{" "}<b>{__("OVER 10,000 SPEAKERS")}</b>{" "}{__("through our online marketplace!")}
                        </h4>
                        <br/>
                        <div className="help-block hidden-bureaumode">
                            <p>
                                {__("Enjoy the benefits of an eSpeakers Marketplace account today. Not only can you find the perfect speakers, but you are getting our industry-leading experience. Stay secure and let us handle all the arrangements through our easy to use meeting process.")}
                            </p>
                            <p>
                                {__("eSpeakers Marketplace offers secure payments made through our site.  Secure your chosen speaker with a small deposit today.  With our secure checkout, speakers are notified immediately.  You'll never have to hear \"The check is in the mail\" again.")}
                            </p>
                            <p>
                                {__("Already have an account with us?")}
                            </p>
                            <p>
                                <b><Link to={_.assign({}, location, {pathname: "/signin"})} onClick={this.LoginOnClick.bind(this)}>{__("Sign in")}</Link></b>{" "}{__("to your account now.")}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 col-md-offset-1">
                        <RegisterForm {...this.props} />

                        <div className="help-block instruction hidden-bureaumode">
                            <p>{__("Your call for speakers will be posted to the largest group of experts who speak in the world. You will receive up to 10 candidates to interview and decide which to hire.")}</p>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PostAJobPrivate;