import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { MenuItem, OverlayTrigger } from "react-bootstrap";
import PopoverMenu from "../PopoverMenu";
import { gettext as __ } from "../../utils/Trans";
import formatNumber from "../../utils/formatNumber";
import HighlightTextMatches from "../../utils/HighlightTextMatches";
import { Flip, FlipBack, FlipFront } from "../../components/Flip";
import SpeakerRating from "../../components/SpeakerRating";

import "./SpeakerRow.scss";

const FeeDisplay = function (min, max) {
    if (min === 0 && max === 0) {
        return "Free";
    } else if (min === 0 && max > 0) {
        return "up to " + formatNumber(max);
    } else {
        // let min_precision = 0;
        // let max_precision = 0;
        // if (min % 1000 > 0) {
        //     min_precision = 1;
        // }
        // if (max % 1000 > 0) {
        //     max_precision = 1;
        // }
        return formatNumber(min) + " - " + formatNumber(max);
        // return _.round((min / 1000), min_precision) + "K - " + _.round((max / 1000), max_precision) + "K";
    }
};

export default class SpeakerRow extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            is_shortlisted: props.is_shortlisted,
            show_speaker_assets: false
        };
    }

    componentDidUpdate(prevProps) {
        if (this.state.is_shortlisted !== this.props.is_shortlisted) {
            this.setState({
                is_shortlisted: this.props.is_shortlisted
            });
        }
    }

    onWatchVideo = () => {
        if (this.props.onWatchVideo) {
            return this.props.onWatchVideo(this.props.speaker);
        }
    };

    onViewSchedule = () => {
        if (this.props.onViewSchedule) {
            return this.props.onViewSchedule(this.props.speaker);
        }
    };

    onViewPrograms = () => {
        if (this.props.onViewPrograms) {
            return this.props.onViewPrograms(this.props.speaker);
        }
    };

    onProfile = () => {
        if (this.props.onProfile) {
            return this.props.onProfile(this.props.speaker);
        }
    };

    onReview = () => {
        if (this.props.onReview) {
            return this.props.onReview(this.props.speaker);
        }
    };

    onViewRatings = () => {
        if (this.props.onViewRatings) {
            return this.props.onViewRatings(this.props.speaker);
        }
    };

    onMenu = e => {
        // Since the image is clickable to the profile, we stopPropagation when the menu opens
        // otherwise the menu would open AND we would redirect to the profile
        e.stopPropagation();
    };

    onFavorite = e => {
        this.setState(
            {
                is_shortlisted: !this.state.is_shortlisted
            },
            () => {
                if (this.props.onFavorite) {
                    this.props.onFavorite(
                        this.props.speaker,
                        this.state.is_shortlisted
                    );
                }
            }
        );
        // Since the image is clickable to the profile, we stopPropagation when the favorite
        // button is clicked otherwise the menu would open AND we would redirect to the profile
        e.stopPropagation();
    };

    onFeature = () => {
        if (this.props.onFeature) {
            this.props.onFeature(this.props.speaker);
        }
    };

    onRemove = () => {
        if (this.props.onRemove) {
            this.props.onRemove(this.props.speaker);
        }
    };

    __onFlipAssets = () => {
        this.setState({
            show_speaker_assets: !this.state.show_speaker_assets
        });
    };

    render() {
        let {
            collaborator,
            rating_sort,
            collaborators,
            awards,
            speaker,
            is_featured,
            is_showcased,
            hide_fees,
            free_form_text_tags,
            is_debug = false
        } = this.props;

        let rating = _.meanBy(_.get(speaker, ["ratings"]), "rating");
        if (_.isNaN(rating)) {
            rating = false;
        }

        let speaker_awards = [];
        _.forEach(awards, function (award) {
            if ((_.get(speaker, ["awards"], 0) & _.get(award, ["id"], 0)) > 0) {
                speaker_awards.push(award.tag);
            }
        });

        let Highlighter = (function () {
            let h = HighlightTextMatches(free_form_text_tags);
            return function (text) {
                return _.map(h(text), function (c, i) {
                    if (_.has(c, "highlight")) {
                        return (
                            <span
                                key={i}
                                style={{ backgroundColor: "#CDE69C" }}>
                                {c.highlight}
                            </span>
                        );
                    }
                    return c;
                });
            };
        })();

        // let bios = _.groupBy(_.get(speaker, ["bios"]), "lang");
        let bio_oneline = _.get(speaker, ["bio_oneline"]);
        // let bio_oneline = !_.isNull(_.get(speaker, ["bio_oneline_topic"], null))
        //     ? _.last(_.split(_.get(speaker, ["bio_oneline_topic"]), "|", 2))
        //     : _.get(speaker, ["bio_oneline"]);

        let assets_cols =
            12 /
            ((_.size(_.get(speaker, ["recommendations"])) > 0 ? 1 : 0) +
                (_.size(_.get(speaker, ["videos"])) > 0 ? 1 : 0));

        let fee_display = FeeDisplay(
            _.get(speaker, ["fee_low"]),
            _.get(speaker, ["fee_high"])
        ); //(_.get(speaker, ["fee_low"], 0) > 0) ? formatNumber(_.get(speaker, ["fee_low"], 0)) : (<span style={{fontSize: "0.85em"}}>{__("request")}</span>);

        let popover_menu = (
            <PopoverMenu>
                {_.isFunction(this.props.onProfile) && (
                    <MenuItem onClick={this.onProfile}>
                        <i className="fa fa-user" /> {__("View Full Profile")}
                    </MenuItem>
                )}
                {_.size(_.get(speaker, ["videos"])) > 0 &&
                    _.isFunction(this.props.onWatchVideo) && (
                        <MenuItem onClick={this.onWatchVideo}>
                            <i className="fa fa-youtube-play" />{" "}
                            {__("Watch Videos")}
                        </MenuItem>
                    )}
                {_.get(speaker, ["num_programs"], 0) > 0 &&
                    _.isFunction(this.props.onViewPrograms) && (
                        <MenuItem onClick={this.onViewPrograms}>
                            <i className="fa fa-list" /> {__("See Programs")}
                        </MenuItem>
                    )}
                {/*{_.get(speaker, ["has_calendar_data"], false) && _.isFunction(this.props.onViewSchedule) && <MenuItem onClick={this.onViewSchedule}><i className="fa fa-calendar"/>{" "}{__("See Schedule")}</MenuItem>}*/}
                {_.isFunction(this.props.onReview) && (
                    <MenuItem onClick={this.onReview}>
                        <i className="fa fa-star" /> {__("Review Speaker")}
                    </MenuItem>
                )}
                {_.isFunction(this.props.onFeature) && (
                    <MenuItem onClick={this.onFeature}>
                        <i className="fa fa-trash" /> {__("Remove Speaker")}
                    </MenuItem>
                )}
                {_.isFunction(this.props.onViewRatings) && (
                    <MenuItem onClick={this.onViewRatings}>
                        <i className="fa fa-commenting-o" />{" "}
                        {rating ? __("View Comments") : __("Add Comment")}
                    </MenuItem>
                )}
                {_.isFunction(this.props.onRemove) && (
                    <MenuItem onClick={this.onRemove}>
                        <i className="fa fa-trash" /> {__("Remove Speaker")}
                    </MenuItem>
                )}
            </PopoverMenu>
        );

        return (
            <div
                className="speaker-row row row-fluid"
                id={"sid" + _.get(speaker, ["sid"])}>
                <div
                    className="col-xs-12 col-sm-2 speaker-thumb"
                    style={{
                        cursor: "pointer",
                        backgroundImage:
                            'url("' +
                            _.get(this.props.speaker, ["thumb_url"]) +
                            '")'
                    }}
                    onClick={this.onProfile}>
                    <div className="row">
                        <div className="col-xs-8 text-left">
                            {_.get(speaker, ["available_on"]) && (
                                <div className="speaker-availability">
                                    {_.get(speaker, ["available_status"], 0) ===
                                        0 && (
                                        <span>
                                            <i className="fa fa-circle mp-tertiary" />{" "}
                                            {__("Call for availability")}
                                        </span>
                                    )}
                                    {_.get(speaker, ["available_status"], 0) ===
                                        1 && (
                                        <span>
                                            <i className="fa fa-circle text-danger" />{" "}
                                            {__("Not available")}{" "}
                                            {_.get(
                                                speaker,
                                                ["available_on"],
                                                ""
                                            )}
                                        </span>
                                    )}
                                    {_.get(speaker, ["available_status"], 0) ===
                                        2 && (
                                        <span>
                                            <i className="fa fa-circle mp-primary" />{" "}
                                            {__("Available")}{" "}
                                            {_.get(
                                                speaker,
                                                ["available_on"],
                                                ""
                                            )}
                                        </span>
                                    )}
                                </div>
                            )}
                            {_.get(speaker, ["distance"]) > -1 &&
                                _.get(speaker, ["distance"]) < 500 && (
                                    <div className="speaker-distance">
                                        {_.get(speaker, ["distance"], 0)}{" "}
                                        {__("miles away")}
                                    </div>
                                )}
                        </div>
                        <div className="col-xs-4 text-right speaker-top-actions">
                            {_.isFunction(this.props.onFavorite) && (
                                <i
                                    className={
                                        "fa fa-heart fa-fw " +
                                        (this.state.is_shortlisted
                                            ? "mp-color-secondary"
                                            : "")
                                    }
                                    onClick={this.onFavorite}
                                />
                            )}
                            <i
                                className="visible-xs fa fa-share fa-1x"
                                onClick={this.__onFlipAssets}
                            />
                            <OverlayTrigger
                                rootClose={true}
                                trigger="click"
                                placement="bottom"
                                overlay={popover_menu}
                                onClick={this.onMenu}>
                                <i className="fa fa-ellipsis-h fa-fw" />
                            </OverlayTrigger>
                        </div>
                    </div>
                </div>
                {is_featured && !is_showcased && (
                    <div className="speaker-featured">{__("Featured")}</div>
                )}
                {is_showcased && (
                    <div className="speaker-showcased">{__("Showcased")}</div>
                )}
                <div
                    className={
                        "col-xs-12 col-sm-8 speaker-details " +
                        (is_featured ? "featured " : "") +
                        (is_showcased ? "showcased " : "")
                    }>
                    <div className="speaker-name link" onClick={this.onProfile}>
                        {Highlighter(_.get(speaker, ["name"]))}
                    </div>
                    <div style={{ marginTop: "5px" }}>
                        <div className="speaker-awards pull-right">
                            {_.size(speaker_awards) > 0 && (
                                <i className="fa fa-trophy mp-tertiary-background" />
                            )}
                            {_.map(speaker_awards, function (item, i) {
                                return <span key={i}> {item}</span>;
                            })}
                        </div>

                        <div className="speaker-location pull-left">
                            <i className="fa fa-map-marker mp-tertiary-background" />{" "}
                            {!_.isNull(
                                _.get(speaker, ["travels_from_state"])
                            ) &&
                            !_.isUndefined(
                                _.get(speaker, ["travels_from_state"])
                            )
                                ? _.get(speaker, ["travels_from_state"])
                                : _.get(speaker, ["state"])}
                            {!_.isNull(
                                !_.isNull(
                                    _.get(speaker, ["travels_from_state"])
                                )
                                    ? _.get(speaker, ["travels_from_state"])
                                    : _.get(speaker, ["state"])
                            ) && <span>{", "}</span>}
                            {!_.isNull(
                                _.get(speaker, ["travels_from_country"])
                            ) &&
                            !_.isUndefined(
                                _.get(speaker, ["travels_from_country"])
                            )
                                ? _.get(speaker, ["travels_from_country"])
                                : _.get(speaker, ["country"])}
                        </div>
                    </div>
                    <div>
                        <Flip
                            flip={this.state.show_speaker_assets}
                            height="115px">
                            <FlipFront>
                                <div className="speaker-oneline text-left">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: bio_oneline
                                        }}
                                    />
                                </div>
                            </FlipFront>
                            <FlipBack>
                                <div className="speaker-assets">
                                    <div className="row">
                                        {_.size(
                                            _.get(speaker, ["recommendations"])
                                        ) > 0 && (
                                            <div
                                                className={
                                                    "col-xs-" + assets_cols
                                                }>
                                                <div className="speaker-asset">
                                                    <i className="fa fa-star mp-tertiary-background text-center" />{" "}
                                                    <span className="asset-value">
                                                        {_.size(
                                                            _.get(speaker, [
                                                                "recommendations"
                                                            ])
                                                        )}
                                                    </span>
                                                    <div className="asset-name">
                                                        {__("Reviews")}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {_.size(_.get(speaker, ["videos"])) >
                                            0 && (
                                            <div
                                                className={
                                                    "col-xs-" + assets_cols
                                                }>
                                                <div className="speaker-asset">
                                                    <i className="fa fa-youtube-play mp-tertiary-background text-center" />{" "}
                                                    <span className="asset-value">
                                                        {_.size(
                                                            _.get(speaker, [
                                                                "videos"
                                                            ])
                                                        )}
                                                    </span>
                                                    <div className="asset-name">
                                                        {__("Videos")}
                                                    </div>
                                                </div>
                                            </div>
                                        )}
                                        {!hide_fees && (
                                            <div className={"col-xs-6"}>
                                                {_.get(
                                                    speaker,
                                                    ["fee_low"],
                                                    0
                                                ) === 0 &&
                                                _.get(
                                                    speaker,
                                                    ["fee_high"],
                                                    0
                                                ) === 0 ? (
                                                    <div className="speaker-asset">
                                                        <span className="asset-value">
                                                            {__(
                                                                "Contact for fees"
                                                            )}
                                                        </span>
                                                        <div className="asset-name">
                                                            {__("Fee (USD)")}
                                                        </div>
                                                    </div>
                                                ) : (
                                                    <div className="speaker-asset">
                                                        <i
                                                            className="fa fa-usd mp-tertiary-background text-center"
                                                            style={{
                                                                marginRight: "0"
                                                            }}
                                                        />
                                                        <span className="asset-value">
                                                            {" "}
                                                            {fee_display}
                                                        </span>
                                                        <div className="asset-name">
                                                            {__("Fee (USD)")}
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    {is_debug && (
                                        <div className="row">
                                            <div className="col-xs-6 col-sm-6">
                                                <div className="speaker-asset">
                                                    <span className="asset-value">
                                                        {_.get(
                                                            speaker,
                                                            ["sortscore"],
                                                            0
                                                        )}
                                                    </span>
                                                    <div className="asset-name">
                                                        <i className="fa fa-sort-amount-desc mp-tertiary text-center" />{" "}
                                                        Sort Score
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xs-6 col-sm-6">
                                                <div className="speaker-asset">
                                                    <span className="asset-value">
                                                        {_.get(
                                                            speaker,
                                                            ["sid"],
                                                            0
                                                        )}
                                                    </span>
                                                    <div className="asset-name">
                                                        <i className="fa fa-user mp-tertiary text-center" />{" "}
                                                        SID
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </FlipBack>
                        </Flip>
                    </div>
                    <div className="speaker-actions hidden-xs">
                        <div className="row">
                            {_.size(_.get(speaker, ["videos"])) > 0 && (
                                <div className="col-xs-6 text-left">
                                    <div
                                        className="btn btn-flat btn-default small"
                                        onClick={this.onWatchVideo}
                                        style={{ fontSize: "8px" }}>
                                        <i className="fa fa-tv fa-1x" /> watch
                                        videos
                                    </div>
                                </div>
                            )}
                            <div
                                className={
                                    "text-right " +
                                    (_.size(_.get(speaker, ["videos"])) > 0
                                        ? "col-sm-6"
                                        : "col-sm-12")
                                }>
                                <div
                                    className="btn btn-flat btn-default small"
                                    onClick={this.__onFlipAssets}
                                    style={{ fontSize: "8px" }}>
                                    <i className="fa fa-share fa-1x" /> more
                                </div>
                            </div>
                            {false && _.isFunction(this.props.onProfile) && (
                                <div
                                    className={
                                        "text-center " +
                                        (_.isFunction(this.props.onFavorite)
                                            ? "col-xs-6"
                                            : "col-xs-6")
                                    }>
                                    <div
                                        className="btn btn-flat mp-primary"
                                        onClick={this.onProfile}>
                                        <span className="hidden-xs hidden-sm">
                                            {__("View Profile")}
                                        </span>
                                        <span className="visible-xs visible-sm">
                                            {__("Profile")}
                                        </span>
                                    </div>
                                </div>
                            )}
                            {false && _.isFunction(this.props.onFavorite) && (
                                <div
                                    className={
                                        "text-center " +
                                        (_.isFunction(this.props.onProfile)
                                            ? "col-xs-6"
                                            : "col-xs-6")
                                    }>
                                    {this.state.is_shortlisted ? (
                                        <div
                                            className="btn btn-flat mp-secondary"
                                            onClick={this.onFavorite}>
                                            <i className="fa fa-heart" />
                                            <span className="hidden-xs hidden-sm">
                                                {" "}
                                                {__("Favorite")}
                                            </span>
                                        </div>
                                    ) : (
                                        <div
                                            className="btn btn-flat btn-default"
                                            onClick={this.onFavorite}>
                                            <i className="fa fa-heart" />
                                            <span className="hidden-xs hidden-sm">
                                                {" "}
                                                {__("Favorite")}
                                            </span>
                                        </div>
                                    )}
                                </div>
                            )}
                            {false && _.isFunction(this.props.onReview) && (
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-center">
                                    <div
                                        className="btn btn-flat mp-secondary"
                                        onClick={this.onReview}>
                                        <i className="fa fa-star" />
                                        <span className="hidden-xs hidden-sm">
                                            {" "}
                                            {__("Review")}
                                        </span>
                                    </div>
                                </div>
                            )}
                            {false && _.isFunction(this.props.onFeature) && (
                                <div className="col-xs-6 col-sm-6 col-md-6 col-lg-6 text-center">
                                    <div
                                        className="btn btn-flat btn-danger"
                                        onClick={this.onFeature}>
                                        <i className="fa fa-trash" />
                                        <span className="hidden-xs hidden-sm">
                                            {" "}
                                            {__("Remove")}
                                        </span>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
                <div className="hidden-xs col-sm-2 speaker-primary-action">
                    <SpeakerRating
                        sort={rating_sort}
                        shortlist_speaker={speaker}
                        collaborators={collaborators}
                        collaborator={collaborator}
                        onToggleReviewModal={this.onViewRatings}
                    />
                </div>
            </div>
        );
    }
}

SpeakerRow.propTypes = {
    mpsitelists: PropTypes.object,
    speaker: PropTypes.object,
    is_featured: PropTypes.bool,
    is_shortlisted: PropTypes.bool,
    is_debug: PropTypes.bool,
    onFavorite: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    onProfile: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    onWatchVideo: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    onViewSchedule: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    onViewPrograms: PropTypes.oneOfType([PropTypes.func, PropTypes.bool]),
    onReview: PropTypes.oneOfType([PropTypes.func, PropTypes.bool])
};
