import Balboa from "../Global/Balboa";
import {gettext as __} from "../../utils/Trans";

export default function(params){
    return (dispatch, getState) => {
        let key = ["ForgotPassword"];

        return Balboa(dispatch, getState)
            .post(key, "/mpauth/buyerforgotpassword", params, {cache_ttl: -1})
            .then(function(response) {
                // Save the user to the state
                dispatch({
                    type: "NOTIFICATION_CREATE",
                    message: __("We sent you an email with a link to reset your password.  Check your inbox.")
                });
                return response;
            })
            .then(function(response) {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            });
    };
};
