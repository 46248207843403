import _ from "lodash";
import React from "react";
import { gettext as __ } from "../../../../utils/Trans";
import RecommendationForm from "./RecommendationForm";

class Recommendation extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speaker,
            recommendation
        } = this.props;

        if (!recommendation) {
            return (<RecommendationForm {...this.props} />);
        }

        let spkr_firstname = _.get(speaker, ["name"]);

        let thumb_icon;
        if (_.get(recommendation, ["flags_as_map", "mp_would_not_hire_again", "is_set"])) {
            thumb_icon = <i
                className="fa fa-thumbs-o-down text-danger"
                style={{fontSize: "2em"}}
            />;
        }
        if (_.get(recommendation, ["flags_as_map", "mp_would_hire_again", "is_set"])) {
            thumb_icon = <i
                className="fa fa-thumbs-o-up text-success"
                style={{fontSize: "2em"}}
            />;
        }

        return (
            <div>
                <h4>
                    {__("Your recommendation of %s", [spkr_firstname])}
                </h4>
                <div>
                    <h4 className="pull-left" style={{marginRight: "2em"}}>
                        {thumb_icon}
                    </h4>
                    <blockquote style={{overflow: "hidden"}}>
                        <p style={{whiteSpace: "pre-wrap"}}>
                            {_.get(recommendation, ["testimony"])}
                        </p>
                        <footer>
                            {_.get(recommendation, ["name"])}
                        </footer>
                    </blockquote>
                    <div className="clearfix"/>
                </div>
            </div>
        );
    }
}

export default Recommendation;