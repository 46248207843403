import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { gettext as __ } from "../../utils/Trans";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { Password } from "../../components/FormComponents";
import User from "../../actions/User/User";

class ChangePasswordForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch)
    {
        return dispatch(User.updatepassword(values))
            .then(() => {
                return dispatch({
                    type: "NOTIFICATION_CREATE",
                    message: __("Your password has been updated successfully.")
                });
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render()
    {
        let {
            handleSubmit,
            submitSucceeded,
            submitting,
            error
        } = this.props;

        return (
            <div>
                <Form className="mp-style-form" onSubmit={handleSubmit(this.handleSubmit)}>
                    <h3>{__("Change Password")}</h3>
                    <hr />
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <Field
                                name="old_password"
                                component={Password}
                                label={__("Current Password")}
                                help_text={__("Enter your current password here.")}
                                placeholder="Password"
                                autocomplete="on"
                                autofill="on"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <Field
                                name="password"
                                component={Password}
                                label={__("New Password")}
                                help_text={__("Enter your new password here.  Your password should be at least 8 characters and contain a letter and number.")}
                                placeholder="Password"
                                autocomplete="off"
                                autofill="off"
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-md-6 col-sm-12">
                            <Field
                                name="password2"
                                component={Password}
                                label={__("Confirm Password")}
                                help_text={__("Confirm your new password here.  Your password should be at least 8 characters and contain a letter and number.")}
                                placeholder="Confirm Password"
                                autocomplete="off"
                                autofill="off"
                            />
                        </div>
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-success" disabled={submitting}>
                            {submitting ? <i className="fa fa-spin fa-circle-o-notch" /> : <i/>} {__("Update Password")}
                        </button>
                        {submitSucceeded && <span className="text-success"> <i className="fa fa-check" /> {__("Changes Saved")}</span>}
                        {error && <span className="text-danger"> <i className="fa fa-times" /> {_.get(error, ["message"])}</span>}
                    </div>
                </Form>
            </div>
        );
    }
}

ChangePasswordForm = reduxForm({
    form: "change_password_page",
    validate: function(values) {
        const errors = {};
        if (!values.old_password) {
            errors.old_password = __("Please enter your current password.");
        }
        if (!values.password) {
            errors.password = __("Please enter a new password.");
        }
        if (!values.password2) {
            errors.password2 = __("Please enter a new password.");
        }
        if (values.password !== values.password2) {
            errors.password2 = __("The passwords do not match, please check them.");
        }
        return errors;
    }
})(ChangePasswordForm);

////////////////////////////////////////////////////////////////////////////////
//Connect it up

function mapStateToProps(state, ownProps) {
    return {
        initialValues: {
            id: _.get(ownProps.user, ["id"])
        }
    };
}

function mapDispatchToProps() {
    return {

    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordForm);