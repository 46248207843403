import React from "react";
import { Modal } from "react-bootstrap";
import { gettext as __ } from "../../../utils/Trans";

class PaperCheckModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            onClose
        } = this.props;

        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("Mailing a Paper Check")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {__("Mail a personal or business check to eSpeakers for processing. Do not mail payments directly to your presenter. Payment will be applied within 5 business days after receipt in the mail. Your invoice will contain the mailing address and other instructions.")}
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn btn-default" onClick={onClose}>{__("Close")}</div>
                </Modal.Footer>
            </Modal>
        );
    }
}

export default PaperCheckModal;