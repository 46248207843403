import _ from "lodash";
import Promise from "es6-promise";
import {sessionService} from "redux-react-session";

Promise.polyfill();

export default {
    /**
     * Retrieves a list of records.  Pass in search parameters in {params}
     * @param params
     * @returns {Function}
     */
    find: function(/* params */) {
        let key = ["Rest_Find"];
        let self = this;
        return function(dispatch) {
            return self.api(dispatch, key, new Promise());
        };
    },

    /**
     * Retrieves a single record.
     * @param params
     * @returns {Function}
     */
    get: function(id) {
        let key = ["Rest_Get", id];
        let self = this;
        return function(dispatch /*, getState */) {
            return self.api(dispatch, key, new Promise());
        };
    },

    /**
     * Shortcut to either add or update a record based on whether the id is > 0
     * @param params
     * @returns {Function}
     */
    save: function(params) {
        if (_.get(params, ["id"], 0) > 0) {
            return this.update(params);
        } else {
            return this.add(params);
        }
    },

    /**
     * Inserts a record.  Leave {params.id} 0, otherwise it will be updated
     * @param params
     * @returns {Function}
     */
    add: function(/* params */) {
        let key = ["Rest_Add"];
        let self = this;
        return function(dispatch /*, getState */) {
            return self.api(dispatch, key, new Promise());
        };
    },

    /**
     * Updates a record.  Pass in {params.id} to specify the record to update, otherwise it will be added
     * @param params
     * @returns {Function}
     */
    update: function(params) {
        let key = ["Rest_Update", _.get(params, ["id"])];
        let self = this;
        return function(dispatch /*, getState */) {
            return self.api(dispatch, key, new Promise());
        };
    },

    /**
     * Removes a record.  Pass in {params.id} to specify the record to remove
     * @param params
     * @returns {Function}
     */
    remove: function(params) {
        let key = ["Rest_Remove", _.get(params, ["id"])];
        let self = this;
        return function(dispatch /*, getState */) {
            return self.api(dispatch, key, new Promise());
        };
    },

    /**
     * Utility function to catch errors in a Promise
     * @param dispatch
     * @param key
     * @param promise
     * @returns {Function}
     */
    api: function(dispatch, key, promise) {
        return promise
            .then((response) => {
                // Check for an updated token
                if (_.has(response, ["estoken"])) {
                    sessionService.saveSession(_.get(response, ["estoken"]))
                        .then(() => {
                            return sessionService.loadUser();
                        })
                        .then((user) => {
                            return sessionService.saveUser(_.assign({}, user, {token: _.get(response, ["estoken"])}));
                        });
                }
                return response;
            })
            .then((response) => {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            })
            .catch(function (err) {
                if (_.get(err, ["name"]) === "TokenException" || _.get(err, ["name"]) === "AuthenticationException") {
                    // Invalidate the session/force login screen
                    return sessionService.deleteSession()
                        .then(() => {
                            return sessionService.deleteUser();
                        })
                        .then(() => {
                            throw err;
                        });
                } else if (_.get(err, ["name"]) === "AuthorizationException") {
                    // Handled on the each mapStateToProps with .catch
                    throw err;
                } else if (_.get(err, ["name"]) === "SpeakerMissingException") {
                    // Handled on the profile page
                    throw err;
                } else {
                    // Save the error and bubble up the error
                    dispatch({
                        type: "ERROR_CREATE",
                        source: _.join(key, "_"),
                        error: err
                    });
                    throw err;
                }
            });
    }
};
