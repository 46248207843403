import _ from "lodash";
import prevDflt from "wrap-prevent-default";

const OpenModal = function(view, replace = false) {
    return prevDflt(
        function() {
            // To open a modal, simply add to the last part of the url (which is the modal name)
            let path_parts;
            if (replace) {
                path_parts = _.concat(_.dropRight(_.split(window.location.hash, "/")), view);
            } else {
                path_parts = _.concat(_.split(window.location.hash, "/"), view);
            }
            window.location.hash = path_parts.join("/");
        }
    );
};

export default OpenModal;
