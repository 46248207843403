import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

class Quote extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
          speaker,
          profile_language = "en",
          topic_id
        } = this.props;


    let primary_bio = _.first(_.filter(_.get(speaker, ["bios"], []), function (item) {
      return (
        (_.get(item, ["lang"], "en") === profile_language) &&
        (_.get(item, ["contents"], "") !== "") &&
        (topic_id < 0 || (topic_id > 0 && _.get(item, ["topic"]) === topic_id))
      );
    }));
    if (!primary_bio) {
      primary_bio = _.first(_.filter(_.get(speaker, ["bios"], []), function (item) {
        return (
          (_.isNull(_.get(item, ["lang"])) || _.get(item, ["lang"], "en") === "en") &&
          (_.get(item, ["contents"], "") !== "")
        );
      }));
    }

    let oneline = _.get(primary_bio, "oneline", "");

    return (_.isEmpty(oneline))
      ? null
      : (
        <div className="quote">
          <i className="fa fa-quote-left fa-5x fa-fw"/>
          <span className="langsensitive" data-lang={_.get(primary_bio, ["lang"], "en")}><div dangerouslySetInnerHTML={{__html:oneline}} /></span>
          <i className="fa fa-quote-right fa-5x fa-fw"/>
        </div>
      );
  }
}

Quote.propTypes = {
  speaker: PropTypes.object
};

export default Quote;