import React from "react";
import PropTypes from "prop-types";
import AlertBadge from "../AlertBadge";

class UnreadBadge extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            style,
            n_unread
        } = this.props;

        if (!style) {
            style = {};
        }

        if (n_unread < 1) {
            return null;
        }
        return (
            <AlertBadge style={style}>{n_unread}</AlertBadge>
        );
    }
}

UnreadBadge.propTypes = {
    style: PropTypes.object,
    n_unread: PropTypes.number
};

export default UnreadBadge;