import _ from "lodash";
import Rest from "../Global/Rest";
import Balboa from "../Global/Balboa";
import md5 from "md5";

export default _.assign({}, Rest, {

    /**
     * Record a profile view for a speaker
     * @param params
     * @returns {function(*=, *=): *}
     */
    saveProfileView: function (params) {
        let key = ["speaker_save_profile_view", _.get(params, ["id"])];
        let self = this;
        return function (dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/speaker/recordprofileview/" + _.get(params, ["id"]), {
                        id: _.get(params, ["id"]),
                        simple_site: !!(_.get(params, "simple_site", false)),
                        book_me_now: !!(_.get(params, "book_me_now", false)),
                        norecord: !!(_.get(params, "norecord", false)),
                        bureau_id: _.get(params, ["bureau_id"]),
                    })
            );
        };
    },

    /**
     * Retrieves a list of records.  Pass in search parameters in {params}
     * @param params
     * @returns {Function}
     */
    search: function (params) {
        let key_hash = md5(JSON.stringify(params));
        let key = ["search", key_hash];
        let self = this;
        return function (dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/speaker/search", params)
                    .then(function (response) {
                        return _.get(response, ["data"]);
                    })
                    .then(function (data) {
                        dispatch({
                            type: "SPEAKER_SEARCH_RESULTS",
                            data: data,
                        });
                        return data;
                    })
                    .then((data) => {
                        return dispatch({
                            type: "SPEAKER_SAVE_HASH",
                            sids: data,
                            hash: key_hash
                        });
                    })
            );
        };
    },

    /**
     * Retrieves a list of records.  Pass in search parameters in {params}
     * @param params
     * @returns {Function}
     */
    find: function (params) {
        let key_hash = md5(JSON.stringify(params));
        let key = ["search", key_hash];
        let self = this;
        return function (dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/marketplace/speaker", params)
                    .then((resp) => {
                        dispatch({
                            type: "SPEAKER_GET_ALL",
                            data: _.get(resp, ["data"]),
                        });
                    })
                    .then((resp) => {
                        return dispatch({
                            type: "SPEAKER_SAVE_HASH",
                            sids: _.get(resp, ["data"]),
                            hash: key_hash
                        });
                    })
            );
        };
    },

    /**
     * Retrieves the short profiles for multiple speakers
     * @param params
     * @returns {Function}
     */
    shortprofiles: function (ids) {
        let key = ["getSpeakerShortProfiles"];
        let self = this;
        return function (dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/speaker/shortprofile/", {speaker_ids: ids.join(",")})
                    .then(function (response) {
                        dispatch({
                            type: "SPEAKER_SHORT_PROFILES_RESPONSE",
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
                    .catch(function (err) {
                        if (_.includes(_.get(err, ["name"]), "SpeakerMissingException")) {
                            throw err;
                        } else if (_.get(err, ["name"]) === "AuthorizationException") {
                            dispatch({
                                type: "ERROR_CREATE",
                                source: _.join(key, "_"),
                                error: err
                            });
                            throw err;
                        }
                    })
            );
        };
    },

    /**
     * Retrieves a single record.
     * @param params
     * @returns {Function}
     */
    shortprofile: function (id) {
        let key = ["getSpeakerShortProfile", id];
        let self = this;
        return function (dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/speaker/shortprofile/" + id)
                    .then(function (response) {
                        dispatch({
                            type: "SPEAKER_SHORT_PROFILES_RESPONSE",
                            sid: id,
                            data: _.get(response, ["data", "speaker"])
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Retrieves a single record.
     * @param params
     * @returns {Function}
     */
    get: function (id) {
        let key = ["getSpeaker", id];
        let self = this;
        return function (dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/marketplace/speaker/" + id)
                    .then((resp) => {
                        dispatch({
                            type: "SPEAKER_GET",
                            id: id,
                            data: _.get(resp, ["data"]),
                        });
                    })
            );
        };
    },

    /**
     * Inserts a record.  Leave {params.id} 0, otherwise it will be updated
     * @param params
     * @returns {Function}
     */
    add: function (/* params */) {
        throw new Error("Cannot add a speaker");
    },

    /**
     * Updates a record.  Pass in {params.id} to specify the record to update, otherwise it will be added
     * @param params
     * @returns {Function}
     */
    update: function (/* params */) {
        throw new Error("Cannot update a speaker");
    },

    /**
     * Removes a record.  Pass in {params.id} to specify the record to remove
     * @param params
     * @returns {Function}
     */
    remove: function (/* params */) {
        throw new Error("Cannot remove a speaker");
    }

});