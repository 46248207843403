import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

require("./index.css");

class VerticalScroll extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_hint_top: true,
            show_hint_bottom: true,
            auto_scroll_bottom_key: void 0
        };
    }

    UNSAFE_componentWillMount() {
        this.onScroll_direct = this.onScroll;
        this.onScroll = _.debounce(this.onScroll_direct, 100);
    }

    componentDidMount() {
        this.UNSAFE_componentWillUpdate(this.props);
        this.onScroll_direct();//initial setup of scroll hints
    }

    UNSAFE_componentWillUpdate = (next_props) => {
        if(!next_props.auto_scroll_bottom_key){
            return;
        }
        var scroll_height = this.node.scrollHeight;

        var key = next_props.auto_scroll_bottom_key + " " + scroll_height;

        this.shouldScrollBottom = false;
        if(this.state.auto_scroll_bottom_key !== key){
            this.setState({auto_scroll_bottom_key: key});
            this.shouldScrollBottom = true;
        }
    }

    componentDidUpdate() {
        //only if scrollHeight changes
        if(this.shouldScrollBottom){
            this.node.scrollTop = this.node.scrollHeight;
        }
    }

    onScroll = () => {

        var is_top = (this.node.scrollTop < this.node.offsetHeight);
        var is_bottom = (this.node.scrollTop + this.node.offsetHeight) === this.node.scrollHeight;

        this.setState({show_hint_top: !is_top, show_hint_bottom: !is_bottom});
    }

    render() {
        // var show_hint_top = this.state.show_hint_top;
        // var show_hint_bottom = this.state.show_hint_bottom;
        var className = this.props.className;

        // var renderHint = function(pos, show){
        //     var c = "VerticalScroll-scroll-hint-" + pos + (show ? " show" : "");
        //     return <div className={c} />;
        // };

        return (
            <div className={"VerticalScroll " + (className ? className : "")} onScroll={this.onScroll} ref={node => this.node = node}>
                {/*{renderHint("top", show_hint_top)}*/}
                {this.props.children}
                {/*{renderHint("bottom", show_hint_bottom)}*/}
            </div>
        );
    }
}

VerticalScroll.propTypes = {
    //set this if you want to automatically scroll to the bottom
    //when this key changes it will re-scroll to the bottom
    //i.e. use the database id of the thing you are rendering
    auto_scroll_bottom_key: PropTypes.string,
    className: PropTypes.string
};

export default VerticalScroll;