import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import { Modal } from "react-bootstrap";
import TogglePublicPostingForm from "./TogglePublicPostingForm";

class TogglePublicPostingModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            event,
            onClose
        } = this.props;

        let is_public = _.get(event, ["flags_as_map", "mp_job_board", "is_set"], false);

        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>
                        {
                            is_public
                            ? __("Make this job invite-only")
                            : __("Make this event open")
                        }
                    </Modal.Title>
                </Modal.Header>
                <TogglePublicPostingForm
                    event={event}
                    is_public={is_public}
                    onClose={onClose}
                />
            </Modal>
        );
    }
}

TogglePublicPostingModal.propTypes = {
    event: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

export default TogglePublicPostingModal;