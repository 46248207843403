import _ from "lodash";
import Balboa from "../Global/Balboa";
import Constants from "../../utils/Constants";

export default function (event) {
    return (dispatch, getState) => {
        let key = ["createMPEvent"];
        return Balboa(dispatch, getState)
            .post(key, "/event/newmpevent/", { event_json: JSON.stringify(event), bureau_id: _.get(event, ["bureau_id"], Constants.TRUNK_BUREAU_ID)  }, { cache_ttl: -1 })
            .then(function(response) {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            });
    };
};

