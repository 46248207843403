import _ from "lodash";
import React from "react";
import { Panel } from "react-bootstrap";
import { gettext as __ } from "../../../../utils/Trans";
import { InvoiceTitle, PaymentAddress, WhitelabelAddress, WhitelabelLogo } from "../";
import FeesAndPayment from "../../../ShortlistPage/components/FeesAndPayment";
import SpeakerSummary from "../../../ShortlistPage/components/SpeakerSummary";
import AgreementInfo from "../../../ShortlistPage/components/AgreementInfo";
import { PayBalanceButton, PayDepositButton, ChoosePaymentMethodButton } from "../../../ShortlistPage/components/Buttons";

class EventInvoice extends React.Component {

    render() {
        let {
            event,
            speaker,
            agreement,
            payment,
            whitelabel,
            user,
            is_espeakers
        } = this.props;

        let services = _.get(event, ["Services"], {});
        let total_due = _.reduce(services, function (amt, item) {
            return amt + _.get(item, ["fee"], false);
        }, 0);

        let total_paid = _.reduce(services, function (amt, item) {
            if (_.get(item, ["flags_as_map", "is_deposit", "is_set"], false)) {
                if (_.get(item, ["paid"], false)) {
                    return amt + _.get(item, ["fee"], false);
                }
            }
            if (_.get(item, ["flags_as_map", "balance", "is_set"], false)) {
                if (_.get(item, ["paid"], false)) {
                    return amt + _.get(item, ["fee"], false);
                }
            }
            return amt;
        }, 0);

        let is_deposit_paid = false;
        let is_balance_paid = false;

        if (total_due === total_paid) {
            is_deposit_paid = true;
            is_balance_paid = true;
        } else if (total_due > 0 && total_paid > 0) {
            is_deposit_paid = true;
            is_balance_paid = false;
        } else if (total_due > 0 && total_paid === 0) {
            is_deposit_paid = false;
            is_balance_paid = false;
        }

        return (
            <div>
                <p />
                <Panel>
                    <Panel.Body>
                        <div className="row">
                            <div className="col-sm-6 text-left">
                                <WhitelabelLogo whitelabel={whitelabel} />
                            </div>
                            <div className="col-sm-6 text-right">
                                <WhitelabelAddress whitelabel={whitelabel} />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12 text-left">
                                <PaymentAddress event={event} />
                            </div>
                        </div>
                        <InvoiceTitle event={event} />
                        <hr />
                        <div className="hidden-print">
                            <div>
                                <SpeakerSummary
                                    speaker={speaker}
                                    hide_profile_button={true}
                                />
                                <hr />
                            </div>
                        </div>
                        <AgreementInfo
                            dont_show_fees_button={true}
                            show_fees_in_area={false}
                            event={event}
                            speaker={speaker}
                            payment={payment}
                            agreement={agreement}
                            {...this.props}
                        />
                        <hr />
                        <FeesAndPayment
                            event={event}
                            speaker={speaker}
                            payment={payment}
                            agreement={agreement}
                            {...this.props}
                        />
                        <hr />
                        <div className="text-center">
                            {!is_deposit_paid &&
                                <PayDepositButton
                                    className="btn btn-success btn-lg"
                                    event={event}
                                    speaker={speaker}
                                    payment={payment}
                                    agreement={agreement}
                                    user={user}
                                    {...this.props}
                                />
                            }
                            {" "}
                            {is_deposit_paid && !is_balance_paid &&
                                <PayBalanceButton
                                    className="btn btn-success btn-lg"
                                    event={event}
                                    speaker={speaker}
                                    payment={payment}
                                    agreement={agreement}
                                    user={user}
                                    {...this.props}
                                />
                            }
                            {(!is_deposit_paid || !is_balance_paid) && <div style={{margin: "10px"}}>
                                <ChoosePaymentMethodButton
                                  className={"btn btn-default"}
                                  event={event}
                                  speaker={speaker}
                                  payment={payment}
                                  agreement={agreement}
                                  user={user}
                                  {...this.props}
                                />
                            </div>}
                            {is_deposit_paid && is_balance_paid &&
                                <div className="alert alert-success">
                                    {__("This invoice has been fully paid and nothing is currently due.  Now sit back and enjoy your event!")}
                                </div>
                            }
                        </div>
                    </Panel.Body>
                </Panel>
                {!is_espeakers &&
                    <div className="text-center text-muted">{__("Payment services are powered by eSpeakers.com.")}</div>
                }
                <div className="text-center text-muted">Secured by 128-bit SSL Encryption</div>
                <div className="text-center text-muted">
                    <i className="fa fa-cc-stripe fa-2x fa-fw"></i>{" "}
                    <i className="fa fa-cc-visa fa-2x fa-fw"></i>{" "}
                    <i className="fa fa-cc-mastercard fa-2x fa-fw"></i>{" "}
                    <i className="fa fa-cc-amex fa-2x fa-fw"></i>{" "}
                    <i className="fa fa-cc-discover fa-2x fa-fw"></i>{" "}
                    <i className="fa fa-cc-diners-club fa-2x fa-fw"></i>{" "}
                    <i className="fa fa-cc-jcb fa-2x fa-fw"></i>{" "}
                </div>
                <div className="hidden-print">
                    <br />
                    <br />
                    <br />
                    <br />
                    <br />
                </div>
            </div>
        );
    }
}

export default EventInvoice;