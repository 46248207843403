import _ from "lodash";
import React from "react";
import Helmet from "react-helmet";

export default class WhitelabelStyle extends React.Component {

  render() {
    let {
          whitelabel
        } = this.props;

    return (
      <Helmet>
        <style>{`
                    .mp-bg-header {
                        background-Color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_HEADER_BACKGROUND"], "#FFFFFF")} !important;
                        background-Image: linear-gradient(to bottom, ${_.get(whitelabel, ["mp_keyvalues", "COLOR_HEADER_GRADIENT_TOP"], "#F8F8F8")} 0, ${_.get(whitelabel, ["mp_keyvalues", "COLOR_HEADER_GRADIENT_BOTTOM"], "#FFFFFF")} 100%)
                    }

                    .mp-text-header > a, .navbar-search .Select-input > input, .navbar-search .Select-input > input:focus, .navbar-search .Select-value-label, .navbar-search .btn-search .fa {
                        color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_HEADER_FOREGROUND"], "#646464")} !important;
                    }

                    .mp-text-header:hover, .mp-text-header.open>a, .mp-text-header.open>a:focus, .mp-text-header.open>a:hover, .mp-text-header.active>a {
                        color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_HEADER_FOREGROUND_HIGHLIGHT"], "#333333")} !important;
                        background-Color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_HEADER_BACKGROUND_HIGHLIGHT"], "transparent")} !important;
                        background-Image: none;
                    }

                   .navbar-search .Select-placeholder {
                     color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_HEADER_FOREGROUND"], "#333333")} !important;
                     opacity:0.6;
                   }
                    .mp-text-header:hover > a {
                        color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_HEADER_FOREGROUND_HIGHLIGHT"], "#333333")} !important;
                    }

                    .mp-color-primary {
                        color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_PRIMARY"], "#FFFFFF")} !important;
                        background-Color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_PRIMARY_BACKGROUND"], "#80BB3D")} !important;
                    }

                    .mp-color-secondary {
                        color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_SECONDARY"], "#FFFFFF")} !important;
                        background-Color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_SECONDARY_BACKGROUND"], "#FAB033")} !important;
                    }

                    .mp-color-tertiary {
                        color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_TERTIARY"], "#FFFFFF")} !important;
                        background-color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_TERTIARY_BACKGROUND"], "#8BAED3")} !important;
                    }

                    .mp-primary {
                        color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_PRIMARY_BACKGROUND"], "#80BB3D")} !important;
                    }

                    .mp-secondary {
                        color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_SECONDARY_BACKGROUND"], "#FAB033")} !important;
                    }

                    .mp-tertiary {
                        color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_TERTIARY_BACKGROUND"], "#8BAED3")} !important;
                    }

                    .mp-bg-primary {
                        background-Color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_PRIMARY_BACKGROUND"], "#80BB3D")} !important;
                    }

                    .mp-bg-secondary {
                        background-Color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_SECONDARY_BACKGROUND"], "#FAB033")} !important;
                    }

                    .mp-bg-tertiary {
                        background-color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_TERTIARY_BACKGROUND"], "#8BAED3")} !important;
                    }

                    .btn.mp-primary {
                        color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_PRIMARY"], "#FFFFFF")} !important;
                        background-Color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_PRIMARY_BACKGROUND"], "#80BB3D")} !important;
                        background-Image: linear-gradient(0deg, ${_.get(whitelabel, ["mp_keyvalues", "COLOR_PRIMARY_BACKGROUND"], "#80BB3D")} 100%, ${_.get(whitelabel, ["mp_keyvalues", "COLOR_PRIMARY_BACKGROUND"], "#80BB3D")} 100%);
                        border-Color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_PRIMARY_BACKGROUND"], "#80BB3D")} !important;

                        &:hover {
                            background-Color:${_.get(whitelabel, ["mp_keyvalues", "COLOR_PRIMARY_BACKGROUND"], "#80BB3D")} !important;
                            background-Image: linear-gradient(0deg, ${_.get(whitelabel, ["mp_keyvalues", "COLOR_PRIMARY_BACKGROUND"], "#80BB3D")} 100%, ${_.get(whitelabel, ["mp_keyvalues", "COLOR_PRIMARY_BACKGROUND"], "#80BB3D")} 100%);
                        }
                    }

                    .btn.mp-secondary {
                        color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_SECONDARY"], "#FFFFFF")} !important;
                        background-Color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_SECONDARY_BACKGROUND"], "#FAB033")} !important;
                        background-Image: linear-gradient(0deg, ${_.get(whitelabel, ["mp_keyvalues", "COLOR_SECONDARY_BACKGROUND"], "#FAB033")} 100%, ${_.get(whitelabel, ["mp_keyvalues", "COLOR_SECONDARY_BACKGROUND"], "#FAB033")} 100%);
                        border-Color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_SECONDARY_BACKGROUND"], "#FAB033")} !important;

                        &:hover {
                            background-Color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_SECONDARY_BACKGROUND"], "#FAB033")} !important;
                            background-Image: linear-gradient(0deg, ${_.get(whitelabel, ["mp_keyvalues", "COLOR_SECONDARY_BACKGROUND"], "#FAB033")} 100%, ${_.get(whitelabel, ["mp_keyvalues", "COLOR_SECONDARY_BACKGROUND"], "#FAB033")} 100%);
                        }
                    }

                    .btn.mp-tertiary {
                        color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_TERTIARY"], "#8BAED3")} !important;
                        background-Color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_TERTIARY_BACKGROUND"], "#FFFFFF")} !important;
                        background-Image: linear-gradient(0deg, ${_.get(whitelabel, ["mp_keyvalues", "COLOR_TERTIARY_BACKGROUND"], "#FFFFFF")} 100%, ${_.get(whitelabel, ["mp_keyvalues", "COLOR_TERTIARY_BACKGROUND"], "#FFFFFF")} 100%);
                        border-Color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_TERTIARY_BACKGROUND"], "#80BB3D")} !important;

                        &:hover {
                            background-Color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_TERTIARY_BACKGROUND"], "#FFFFFF")} !important;
                            background-Image: linear-gradient(0deg, ${_.get(whitelabel, ["mp_keyvalues", "COLOR_TERTIARY_BACKGROUND"], "#FFFFFF")} 100%, ${_.get(whitelabel, ["mp_keyvalues", "COLOR_TERTIARY_BACKGROUND"], "#FFFFFF")} 100%);
                        }
                    }

                    hr.mp-primary {
                        border-color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_PRIMARY_BACKGROUND"], "#80BB3D")};
                    }

                    hr.mp-secondary {
                        border-color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_SECONDARY_BACKGROUND"], "#FAB033")};
                    }

                    hr.mp-tertiary {
                        border-color: ${_.get(whitelabel, ["mp_keyvalues", "COLOR_TERTIARY_BACKGROUND"], "#8BAED3")};
                    }
                `}</style>
      </Helmet>
    );
  }
}