import isBlank from "./isBlank";

export default function(venue){
    venue = venue || {};
    var parts = [];
    if(!isBlank(venue.address)){
        parts.push(venue.address);
    }
    //building
    if(!isBlank(venue.city)){
        parts.push(venue.city);
    }
    if(!isBlank(venue.st)){
        parts.push(venue.st);
    }
    if(!isBlank(venue.country)){
        parts.push(venue.country);
    }
    if(!isBlank(venue.zip)){
        parts.push(venue.zip);
    }
    return parts.join(", ");
}
