import _ from "lodash";
import React from "react";
import { gettext as __ } from "../../../utils/Trans";
import formatCurrency from "../../../utils/formatCurrency";
import AgreementInfo from "../components/AgreementInfo";
import SpeakerSummary from "../components/SpeakerSummary";
import PaymentRows from "../components/PaymentRows";
import PaymentRow from "../components/PaymentRow";
import { CancelAgreementButton, ConfirmPaymentMethodButton, PayDepositButton, ViewInvoiceButton } from "../components/Buttons";

class PayStep extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            event,
            speaker,
            agreement,
            payment,
            user,
            event_has_an_accepted_offer
        } = this.props;

        let speakername = _.get(speaker, ["name"]);
        let sid = _.get(speaker, ["sid"]);
        let services = _.get(event, ["Services"], {});
        let has_stripe = !_.isNull(_.get(user, ["stripe_cust_token"], null));

        //from services
        let total_fees = _.reduce(services, function (sum, item) {
            return sum + parseFloat(item.fee);
        }, 0);

        var total_deposit = _.reduce(services, function (sum, item) {
            if (_.get(item, ["flags_as_map", "is_deposit", "is_set"], false)) {
                return sum + parseFloat(_.get(item, "fee") - _.get(item, "total_paid"));
            }
            return sum;
        }, 0);


        //from payments
        let total_paid = _.reduce(payment, function (sum, item) {
            if (_.includes(_.get(item, "flags_array", []), "status_complete")) {
                return sum + parseFloat(item.amount);
            }
            return sum;
        }, 0);

        let total_pending = _.reduce(payment, function (sum, item) {
            if (!_.includes(_.get(item, "flags_array", []), "status_complete")) {
                return sum + parseFloat(item.amount);
            }
            return sum;
        }, 0);

        let display_message = (
            <div>
                <strong>{__("You and the presenter have a contract in place.")}{" "}</strong>
                {(total_deposit > 0) && !_.get(this.props, ["MPPaymentStatus", sid, "deposit"], false) &&
                <span>{__("Pay the deposit to lock the date on the presenter's calendar.")}</span>
                }
                {total_deposit === 0 &&
                <span>{__("Enter a payment method for travel and material costs to lock the date on the presenter's calendar.")}</span>
                }
            </div>
        );

        return (
            <div>
                <div style={{
                    marginBottom: "30px",
                    paddingBottom: "30px",
                    borderBottom: "1px solid #EAEAEA"
                }}>
                    <SpeakerSummary
                        speaker={speaker}
                        speaker_has_an_accepted_offer={_.get(speaker, ["offer_accepted"])}
                        event_has_an_accepted_offer={event_has_an_accepted_offer}
                        display_message={display_message}
                        {...this.props}
                    />

                    <div className="row">
                        <div className="col-md-9 col-md-offset-3 col-lg-9 col-lg-offset-3 col-sm-9 col-sm-offset-3 col-xs-12">
                            {total_deposit === 0 && <ConfirmPaymentMethodButton event={event} speaker={speaker} agreement={agreement} payment={payment} {...this.props} />}
                            {" "}
                            {total_deposit > 0 && <PayDepositButton show_choose_payment_method={true} event={event} speaker={speaker} agreement={agreement} payment={payment} {...this.props} />}
                            {" "}
                            <ViewInvoiceButton {...this.props} />
                        </div>
                    </div>
                </div>

                <AgreementInfo
                    agreement={agreement}
                    event={event}
                    speaker={speaker}
                    dont_show_fees_button={true}
                />

                <hr style={{margin: "2em 0"}}/>

                <h4>
                    {__("Payment")}
                </h4>
                <div className="text-muted" style={{margin: "20px 0 40px 33px"}}>
                    <PaymentRow
                        label={__("Total fees:")}
                        value={formatCurrency(total_fees, 2)}
                    />
                    <PaymentRow
                        label={__("Paid:")}
                        value={formatCurrency(total_paid, 2)}
                    />
                    <PaymentRow
                        label={__("Balance owed:")}
                        value={formatCurrency((total_fees - total_paid), 2)}
                    />
                    <div style={{color: "silver", marginTop: "1em"}}>
                        <PaymentRow
                            label={__("Pending:")}
                            value={formatCurrency(total_pending, 2)}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-7">
                        <PaymentRows
                            speakername={speakername}
                            deposit_amount={total_deposit}
                            amount_due_on_event={(total_fees - total_deposit - total_paid)}
                            event_due_date={_.get(event, ["Stagetimes", 0, "starttime"])}
                            has_stripe={has_stripe}
                            {...this.props}
                        />
                    </div>
                    <div className="col-xs-12 col-sm-5 text-right">
                        {total_deposit > 0 &&
                        <div>
                            <div className="help-block instruction right">
                                <strong>{__("Pay your presenter securely")}</strong> {__("with a credit card or bank account, or mail a paper check.")}
                            </div>
                            <PayDepositButton event={event} speaker={speaker} agreement={agreement} payment={payment} {...this.props} />
                        </div>
                        }
                        {total_deposit === 0 &&
                        <ConfirmPaymentMethodButton event={event} speaker={speaker} agreement={agreement} payment={payment} {...this.props} />
                        }
                    </div>
                </div>
                <div className="row" style={{margin: "6em 0 0 0"}}>
                    <div className="col-sm-6">
                        <ViewInvoiceButton {...this.props} />
                    </div>
                    <div className="col-sm-6 text-right">
                        <CancelAgreementButton {...this.props} />
                    </div>
                </div>
                <hr style={{margin: "2em 0"}}/>
            </div>
        );
    }
}

export default PayStep;
