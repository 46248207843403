import React from "react";

export default class Select extends React.Component {

    render() {
        let {
            input,
            placeholder,
            label,
            hide_errors,
            disabled = false,
            help_text,
            children,
            meta: {
                touched,
                error,
                warning
            }
        } = this.props;

        return (
            <div className={"form-group " + (touched && error ? "has-error " : touched && warning ? "has-warning " : "")}>
                {label && <label className="control-label">{label}</label>}
                <select {...input} disabled={disabled} placeholder={placeholder} className="form-control">
                    {children}
                </select>
                {help_text && <div className="help-block">{help_text}</div>}
                {!hide_errors && touched &&
                ((error && <div className="text-danger"><i className="fa fa-times" /> {error}</div>) ||
                    (warning && <div className="text-danger"><i className="fa fa-exclamation-triangle" /> {warning}</div>))}
            </div>
        );
    }
}