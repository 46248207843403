import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Navbar as BootstrapNavbar } from "react-bootstrap";
import Brand from "../../../Brand";
import Menu from "./components/Menu";
import NavShortlistMobile from "./components/NavShortlistMobile";
import NavShortlistCollapse from "./components/NavShortlistCollapse";
import NavbarSearchForm from "../../../NavbarSearchForm";
import AlertCollapse from "./components/AlertCollapse";
import { gettext as __ } from "../../../../utils/Trans";

import "./Navbar.scss";

export default class Navbar extends React.Component {


    render() {
        let {
            user,
            whitelabel,
            is_admin_or_manage = false,
            fluid = false,
            fixed = true,
            n_unread_messages = 0,
            errors = [],
            notifications = [],
            shortlist = [],
            onRemoveFromShortlist,
            onProfile,
            onCreateJob,
            onLogout
        } = this.props;

        let borderColor = _.get(whitelabel, ["mp_keyvalues", "COLOR_HEADER_BORDER"], "#EEE");
        let is_directory_mode = _.get(whitelabel, ["is_directory_mode"]);

        return (
            <BootstrapNavbar inverse={false} fluid={fluid} fixedTop={fixed} staticTop={true} collapseOnSelect={true} className="mp-bg-header" style={{marginTop:"0px"}}>
                <BootstrapNavbar.Header style={{borderRight: "1px solid " + borderColor, paddingRight: "20px"}}>
                    <BootstrapNavbar.Toggle />
                    {!is_directory_mode &&
                        <NavShortlistMobile shortlist={_.get(shortlist, ["sids"], [])} />
                    }
                    <BootstrapNavbar.Brand>
                        <Brand whitelabel={whitelabel} />
                    </BootstrapNavbar.Brand>
                </BootstrapNavbar.Header>
                <BootstrapNavbar.Collapse>
                    <Menu
                        borderColor={borderColor}
                        user={user}
                        whitelabel={whitelabel}
                        n_unread_messages={n_unread_messages}
                        is_admin_or_manage={is_admin_or_manage}
                        shortlist={_.get(shortlist, ["sids"], [])}
                        onRemoveFromShortlist={onRemoveFromShortlist}
                        onProfile={onProfile}
                        onCreateJob={onCreateJob}
                        onLogout={onLogout}
                        fluid={fluid}
                    />
                    {!is_admin_or_manage &&
                    <div className="input-group-addon" style={{padding: "0px", border: "none", background: "none"}}>
                        <NavbarSearchForm
                            user={user}
                            whitelabel={whitelabel}
                            className="hidden-xs hidden-sm"
                            large={true}
                            style={{
                                border: "none",
                                background: "transparent",
                                outline: "none",
                                minWidth: "100%",
                                height: "30px",
                                lineHeight: "30px",
                                boxShadow: "none"
                            }}
                            placeholder={__("Search by topic, name, or expertise...")}
                        />
                    </div>
                    }
                </BootstrapNavbar.Collapse>
                {!is_directory_mode &&
                    <NavShortlistCollapse shortlist={_.get(shortlist, ["sids"], [])}/>
                }
                <AlertCollapse alerts={errors} className="alert-danger" clearKey="ERROR_CLEAR" />
                <AlertCollapse alerts={notifications} clearKey="NOTIFICATION_CLEAR" />
            </BootstrapNavbar>
        );
    }
}

Navbar.propTypes = {
    user: PropTypes.object,
    whitelabel: PropTypes.object,
    shortlist: PropTypes.object,
    is_admin_or_manage: PropTypes.bool,
    n_unread_messages: PropTypes.number,
    fluid: PropTypes.bool,
    backgroundColor: PropTypes.string,
    textColor: PropTypes.string,
    messages: PropTypes.array,
    onRemoveFromShortlist: PropTypes.func,
    onProfile: PropTypes.func,
    onCreateJob: PropTypes.func
};
