import _ from "lodash";
import React from "react";
import { gettext as __ } from "../../utils/Trans";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import User from "../../actions/User/User";
import LoadingIndicator from "../../components/LoadingIndicator";

export class VerifyEmailPage extends React.Component
{
    constructor(props)
    {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (this.props.user) {
            this.props.verifyEmail();
        }
    }

    render()
    {
        return (
            <div>
                <div>
                    <h1 className="page-header">
                        {__("VERIFY EMAIL")}
                    </h1>
                    <LoadingIndicator />
                </div>
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

function mapStateToProps(/* state, ownProps */) {
    return {

    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        verifyEmail: function() {
            return dispatch(User.verifyemail({
                    id: _.get(ownProps.user, ["id"])
                }))
                .then((response) => {
                    dispatch({
                        type: "NOTIFICATION_CREATE",
                        message: __("Your email has been verified.")
                    });
                    return response;
                })
                .then(() => {
                    ownProps.history.push("/user/edit");
                })
                .catch((err) => {
                    if (_.get(err, ["name"]) === "AuthorizationException") {
                        ownProps.history.push("/error");
                    }
                });
        }
    };
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyEmailPage));