import _ from "lodash";
import React from "react";
import toShortlistURL from "../../utils/toShortlistURL";
import { gettext as __ } from "../../utils/Trans";

class SubAuthed extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            user
        } = this.props;

        if (!_.get(user, ["is_sub_authed"], false)) { return null; }

        return (
            <div className="alert alert-warning" style={{fontSize:"21px"}}>
                {__("You are now logged in as: %s", _.get(user, ["original_name"], ""))}
                <a href={toShortlistURL("/stats/unsubauth")} className="btn btn-sm btn-default pull-right">{__("Switch Back")}</a>
            </div>
        );
    }
}

export default SubAuthed;