import React from "react";
import "./MessageSendButton.scss";

export default class MessageSendButton extends React.Component {
    render () {
        let {
            is_sending
        } = this.props;

        is_sending = !!is_sending;

        let className = "btn btn-default btn-flat mp-message-send-button";

        if (is_sending) {
            className += " disabled";
        }

        return (
            <button type="submit" className={className}>
                <i className={"fa " + (is_sending ? "fa-circle-o-notch fa-spin" : "fa-send")}/>
                <span className="sr-only">Send</span>
            </button>
        );
    }
}