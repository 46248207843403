import CookieEmbeddedShortlist from "../../utils/CookieEmbeddedShortlist";

export default function() {
    return function(dispatch) {
        return dispatch({
            type: "EMBEDDED_SHORTLIST_LOAD",
            sids: CookieEmbeddedShortlist.getSIDs()
        });
    };
};
