import React from "react";
import PropTypes from "prop-types";
import MarketplaceSearch from "../../MarketplaceSearch";

import "./TopicSearch.scss";

class TopicSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            input,
            whitelabel
        } = this.props;

        return (
            <MarketplaceSearch
                input={input}
                containerClassName="topic-search"
                large={false}
                whitelabel={whitelabel}
            />
        );
    }

}

TopicSearch.propTypes = {
    whitelabel: PropTypes.object,
    input: PropTypes.object
};

export default TopicSearch;