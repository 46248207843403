import _ from "lodash";
import React from "react";
// import { Link } from "react-router-dom";
import { gettext as __ } from "../../../../utils/Trans";
import ProgressSteps from "../ProgressSteps";
import ShortlistMessages from "../../components/ShortlistMessages";
// Steps that can be shown in the Center Area
import SpeakerStep from "../../steps/SpeakerStep";
import OfferStep from "../../steps/OfferStep";
import PayStep from "../../steps/PayStep";
import PreEventStep from "../../steps/PreEventStep";
import PostEventStep from "../../steps/PostEventStep";
import "./index.scss";

let podium_img = require("./empty-podium.png");

class NoCandidates extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div className="no-candidates">
                <img src={podium_img} alt="no candidates"/>
                <div className="headline">
                    {__("No candidates have applied to this job yet...")}
                </div>
                <br/>
                <div className="hidden-lg hidden-md subheadline">
                    {__("You can ")}
                    <a href="/marketplace/search">{__("search our marketplace")}</a>
                    {__(" for speakers to invite to your event.")}
                </div>
            </div>
        );
    }
}

class SelectToGetStarted extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <div style={{margin: "100px 0 0 5em"}} className="hidden-sm hidden-xs">
                    <div style={{marginBottom: "20px"}}>
                        <i
                            className="fa fa-share text-success pull-left"
                            style={{
                                transform: "scale(-1, 1)",
                                fontSize: "3em"
                            }}
                        />
                        <div className="text-muted" style={{
                            fontSize: "24px",
                            width: "175px",
                            display: "inline-block",
                            paddingLeft: "1em"
                        }}>
                            {__("select a speaker to continue")}
                        </div>
                    </div>
                </div>
                <div className="visible-sm visible-xs" style={{marginTop: "3em"}}>
                    <div className="row">
                        <div className="col-xs-offset-1 col-xs-10">
                            <i
                                className="fa fa-angle-double-up pull-left text-muted"
                                style={{
                                    fontSize: "3em"
                                }}
                            /> {__("Touch a shortlisted speaker for details.")}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

class CenterArea extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    UNSAFE_componentWillMount() {
        // this.props.loadData();
    }

    render() {
        let {
            whitelabel,
            event,
            speaker,
            messages,
            current_step_i,
            n_candidates
        } = this.props;

        let sid = _.get(speaker, ["sid"], 0);

        if ((!sid || sid < 1) && n_candidates > 0) {
            return <SelectToGetStarted/>;
        } else if (n_candidates === 0) {
            return <NoCandidates/>;
        }

        let steps = [];
        steps = _.concat(steps, __("Speaker"));
        steps = _.concat(steps, __("Offer"));
        if (!_.get(whitelabel, ["flags", "MP_DISABLE_PAYMENT_AND_CONTRACT"], false)) {
            steps = _.concat(steps, __("Pay"));
        }
        steps = _.concat(steps, __("Pre Event"));
        steps = _.concat(steps, __("Post Event"));

        return (
            <div>
                <ProgressSteps
                    steps={steps}
                    selected_i={current_step_i}
                />
                <div style={{margin: "15px"}}>
                    {current_step_i === 4 &&
                    <PostEventStep event={event} speaker={speaker} {...this.props} />
                    }
                    {current_step_i === 3 &&
                    <PreEventStep event={event} speaker={speaker} {...this.props} />
                    }
                    {current_step_i === 2 && _.get(whitelabel, ["flags", "MP_DISABLE_PAYMENT_AND_CONTRACT"], false) &&
                    <PreEventStep event={event} speaker={speaker} {...this.props} />
                    }
                    {current_step_i === 2 && !_.get(whitelabel, ["flags", "MP_DISABLE_PAYMENT_AND_CONTRACT"], false) &&
                    <PayStep event={event} speaker={speaker} {...this.props} />
                    }
                    {current_step_i === 1 &&
                    <OfferStep event={event} speaker={speaker} {...this.props} />
                    }
                    {current_step_i === 0 &&
                    <SpeakerStep event={event} speaker={speaker} {...this.props} />
                    }
                    {event && speaker && <ShortlistMessages event={event} speaker={speaker} messages={messages} {...this.props} />}
                </div>
            </div>
        );
    }
}

export default CenterArea;
