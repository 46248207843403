import React from "react";
import { Modal } from "react-bootstrap";
import { gettext as __ } from "../../../../../utils/Trans";
import LoadingIndicator from "../../../../../components/LoadingIndicator";

export default class LoadingStripe extends React.Component {

    render() {
        let {
            onClose
        } = this.props;

        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("Please wait...")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{minHeight:"200px"}}>
                        <LoadingIndicator is_modal={true} />
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

}