import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";

class FeaturedVideo extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speaker,
            onVideoClick
        } = this.props;

        let assets_top_video = _.get(speaker, ["assets_top_video"], {});

        if (_.isUndefined(_.get(assets_top_video, ["url"]))) {
            return null;
        }
        // let img = _.join(_.dropRight(_.split(_.get(assets_top_video, ["url"]), '.')), '.') + '.jpg' 

        return (
            <div>
                <h5 className="header legend">{__("Featured Video")}</h5>
                <div className="profileVideo">
                    <span className="link playVideo videoLink" onClick={() => onVideoClick(assets_top_video)}>
                        <i className="fa fa-play-circle fa-5x"></i>
                        <img src={_.get(assets_top_video, ["img"])} alt="play video" className="img-responsive" />
                    </span>
                </div>
            </div>
        );
    }
}

FeaturedVideo.propTypes = {
    speaker: PropTypes.object,
    onVideoClick: PropTypes.func
};

export default FeaturedVideo;