import _ from "lodash";
import React from "react";
import { Field } from "redux-form";
import { Button, Modal } from "react-bootstrap";
import { gettext as __ } from "../../../../utils/Trans";
import { Message, Selectize } from "../../../../components/FormComponents";

export default class JobPublicDetailsModal extends React.Component {

    __onShowJobBoardModal = () => {
        if (_.isFunction(this.props.onShowJobBoardModal)) {
            return this.props.onShowJobBoardModal();
        }
    }

    __onShowJobConfirmModal = () => {
        if (_.isFunction(this.props.onShowJobConfirmModal)) {
            return this.props.onShowJobConfirmModal();
        }
    }

    render() {
        let {
            presenter_types,
            topics,
            budget_ranges,
            onHide
        } = this.props;


        return (
            <Modal show={true} onHide={onHide}>
                <Modal.Header>
                    <Modal.Title>{__("We need a little more information...")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div>
                        <div className="help-block">
                            <p>
                                {__("We just need a little more information about your job so we can find the best candidates.")}
                            </p>
                        </div>
                        <div className="row">
                            {presenter_types &&
                            <div className="col-sm-6 col-xs-12">
                                <Field
                                    name="presenter_type"
                                    className="form-control"
                                    label={__("Type of presentation")}
                                    component={Selectize}
                                    placeholder={__("Select a type of presentation...")}
                                    options={_.map(presenter_types, function (item) {
                                        return {label: _.get(item, ["description"]), value: _.get(item, ["id"])};
                                    })}
                                />
                            </div>
                            }
                            {budget_ranges &&
                            <div className="col-sm-6 col-xs-12">
                                <Field
                                    name="budget"
                                    className="form-control"
                                    label={__("Budget")}
                                    component={Selectize}
                                    placeholder={__("Select your budget...")}
                                    options={_.map(budget_ranges, function (item, i) {
                                        return {label: _.get(item, ["label"]), value: ("range_" + i)};
                                    })}
                                />
                            </div>
                            }
                        </div>
                        {topics && <div className="row">
                            <div className="col-sm-12">
                                <Field
                                    name="topic"
                                    className="form-control"
                                    label={__("Speaking On")}
                                    component={Selectize}
                                    multi={true}
                                    simpleValue={true}
                                    placeholder={__("Choose a topic...")}
                                    options={_.map(topics, function (item) {
                                        return { label: _.get(item, ["name"]), value: _.get(item, ["id"]) };
                                    })}
                                />
                            </div>
                        </div>
                        }
                        <div className="row">
                            <div className="col-sm-12">
                                <Field
                                    name="meeting_goals"
                                    className="form-control"
                                    label={__("Goals")}
                                    placeholder={__("What should the speaker's presentation accomplish for your audience?")}
                                    rows={6}
                                    component={Message}
                                />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={this.__onShowJobBoardModal}>{__("Back")}</Button>
                    <Button bsStyle="success" onClick={this.__onShowJobConfirmModal}>{__("Continue")}</Button>
                </Modal.Footer>
            </Modal>
        );
    }
}