import _ from "lodash";
import React from "react";
import { Modal } from "react-bootstrap";
import { gettext as __ } from "../../../../../utils/Trans";
import LoadingIndicator from "../../../../../components/LoadingIndicator";
import PaymentAcceptedSprite from "../../sprites/PaymentAcceptedSprite";
import PaymentDeclinedSprite from "../../sprites/PaymentDeclinedSprite";

class PaymentSpriteForm extends React.Component {

    render() {
        let {
            is_payment_processing,
            is_payment_received,
            error,
            onClose,
            onRetry
        } = this.props;

        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("Payment %s", (is_payment_processing ? __("Processing") : error ? __("Failed") : __("Successful")))}</Modal.Title>
                </Modal.Header>
                <Modal.Body style={{minHeight:"300px"}}>
                    {is_payment_processing && !is_payment_received &&
                        <LoadingIndicator is_modal={true} />
                    }
                    {is_payment_received &&
                    <PaymentAcceptedSprite onComplete={onClose}/>
                    }
                    {error &&
                    <PaymentDeclinedSprite error={_.get(error, ["message"])}/>
                    }
                </Modal.Body>
                {error &&
                <Modal.Footer>
                    <div className="btn btn-default btn-block-xs" onClick={onRetry}>{__("Try Again")}</div>
                </Modal.Footer>
                }
            </Modal>
        );
    }
}

export default PaymentSpriteForm;