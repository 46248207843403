import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ChoosePaymentMethodForm from "./forms/ChoosePaymentMethodForm";
import NoBalanceForm from "./forms/NoBalanceForm";
import PayBalanceForm from "./forms/PayBalanceForm";
import LoadingStripe from "./forms/LoadingStripe";
import ErrorLoadingStripe from "./forms/ErrorLoadingStripe";
import moment from "moment/moment";
import UserStripe from "../../../../actions/User/UserStripe";

class PayBalanceModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_choose_payment_method: false,
            error_loading: null
        };
    }

    componentDidMount() {
        if (!this.props.stripe) {
            return this.props.loadStripe().catch(e =>
                this.setState({
                    error_loading: _.get(e, ["message"])
                })
            );
        }
    }

    __onToggleChoosePaymentMethodPane = () => {
        this.setState({
            show_choose_payment_method: !this.state.show_choose_payment_method
        });
    };

    render() {
        let {
            is_loading,
            event,
            speaker,
            user,
            stripe,
            whitelabel,
            meeting_name,
            buyer_email,
            balance_amount,
            event_date_str,
            primary_payment_method,
            is_espeakers,
            onClose
        } = this.props;
        let { error_loading } = this.state;

        if (error_loading) {
            return (
                <ErrorLoadingStripe error={error_loading} onClose={onClose} />
            );
        }

        if (is_loading) {
            return <LoadingStripe onClose={onClose} />;
        }

        if (!primary_payment_method || this.state.show_choose_payment_method) {
            return (
                <ChoosePaymentMethodForm
                    onClose={onClose}
                    buyer_email={buyer_email}
                    stripe={stripe}
                    primary_payment_method={primary_payment_method}
                    user={user}
                    event={event}
                    whitelabel={whitelabel}
                    balance_amount={balance_amount}
                    onClickToPayNow={this.__onToggleChoosePaymentMethodPane}
                    {...this.props}
                />
            );
        }

        if (balance_amount === 0) {
            return <NoBalanceForm onClose={onClose} {...this.props} />;
        } else {
            return (
                <PayBalanceForm
                    event={event}
                    speaker={speaker}
                    event_date_str={event_date_str}
                    last4={_.get(primary_payment_method, ["last4"])}
                    balance_amount={balance_amount}
                    description={"Balance for " + meeting_name}
                    is_espeakers={is_espeakers}
                    onClose={onClose}
                    onChangePaymentMethod={
                        this.__onToggleChoosePaymentMethodPane
                    }
                    {...this.props}
                />
            );
        }
    }
}

PayBalanceModal.propTypes = {
    event: PropTypes.object.isRequired,
    speaker: PropTypes.object.isRequired,
    agreement: PropTypes.object.isRequired,
    payment: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onClose: PropTypes.func
};
////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {
    // Loading Properties
    let stripe = _.get(state, ["Stripe", "STRIPE"]);

    let default_source = _.get(stripe, ["default_source"]);
    let stripe_sources = _.get(stripe, ["sources", "data"]);

    let stripe_primary_pm = _.find(stripe_sources, function (o) {
        return o.id === default_source;
    });

    let total_fees = _.reduce(
        ownProps.payment,
        function (sum, item) {
            return sum + parseFloat(item.fee);
        },
        0
    );

    let paid_amount = _.reduce(
        ownProps.payment,
        function (sum, item) {
            if (_.get(item, ["paid"], false)) {
                return sum + parseFloat(item.fee);
            }
            return sum;
        },
        0
    );

    let balance_amount = total_fees - paid_amount;

    let event_date_str = moment(
        _.get(ownProps.event, ["Stagetimes", 0, "starttime"], new Date())
    ).format("MMMM D, Y");

    return {
        is_loading: !stripe,

        stripe: stripe,
        primary_payment_method: stripe_primary_pm,

        buyer_email: _.get(ownProps.user, ["email"]),
        meeting_name: _.get(ownProps.event, ["meetingname"]),

        total_fees: total_fees,
        balance_amount: balance_amount,
        paid_amount: paid_amount,
        event_date_str: event_date_str,

        is_espeakers: !!(
            ownProps.whitelabel && ownProps.whitelabel.is_espeakers
        )
    };
};

var mapDispatchToProps = function (dispatch, ownProps) {
    return {
        loadStripe: function () {
            return dispatch(UserStripe.get());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PayBalanceModal);
