import React from "react";
import { Experiment, Variant } from "react-ab";
import ProfilePageA from "./ab/ProfilePageA";
import ProfilePageB from "./ab/ProfilePageB";
import Analytics from "../../utils/Analytics";

export default class ProfilePage extends React.Component {

    __recordPageHit = (experiment, variant, index, was_retrieved) => {
        Analytics.sendEvent(experiment, "View", variant, was_retrieved);
        return true;
    }

    render() {
        return (
            <Experiment onChoice={(experiment, variant, index, was_retrieved) => {
                return this.__recordPageHit(experiment, variant, index, was_retrieved);
            }} name="profilepage_ab">
                <Variant name="default">
                    <ProfilePageA {...this.props} />
                </Variant>
                <Variant name="alternate">
                    <ProfilePageB {...this.props} />
                </Variant>
            </Experiment>
        );
    }
}