import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import FlashMessage from "../../components/FlashMessage";

class FlashError extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            errors,
            clearErrors
        } = this.props;

        let items = _.map(errors, function(error, i) {
            if (_.isString(error)) {
                return (<div key={i}>{error}</div>);
            } else if (_.has(error, ["message"])) {
                return (<div key={i}>{_.get(error, ["message"])}</div>);
            } else if (_.isObject(error)) {
                let error_msgs = [];
                error = _.reduce(error, function (err, error_msgs) {
                    if (_.isString(err)) {
                        return error_msgs.push(err);
                    } else if (_.has(err, ["message"])) {
                        return error_msgs.push(_.get(err, ["message"]));
                    }

                }, error_msgs);
                return (<div key={i}>{_.join(error_msgs, ", ")}</div>);
            }
        });

        return (
            <div>
                <FlashMessage className="alert-danger" onClick={clearErrors}>
                    {items}
                </FlashMessage>
            </div>
        );
    }

}

FlashError.propTypes = {
    source: PropTypes.array,
    errors: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    clearErrors: PropTypes.func
};

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function(state){
    let errors = _.get(state, ["App", "errors"], []);
    return {
        errors: errors
    };
};

var mapDispatchToProps = function(dispatch){
    return {
        clearErrors: function() {
            return dispatch({
                type: "ERROR_CLEAR"
            });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlashError);
