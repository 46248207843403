import _ from "lodash";
import React from "react";
import moment from "moment";
import Calendar from "../../../../components/Calendar";
import { gettext as __ } from "../../../../utils/Trans";

import "./EventSearch.scss";

export default class EventSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            sortedDataList: null,
            filter: null
        };
    }

    componentDidMount() {
        this.__onFilter();
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (nextProps.events !== this.props.events) {
            this.__onFilter();
        }
    }

    __updateFilter = (e) => {
        this.setState({
            filter: e.target.value
        });
        this.__onFilter();
    }

    __onFilter = _.debounce((/* props */) => {
        let {
            events
        } = this.props;

        let {
            filter
        } = this.state;

        if (events) {
            this.setState({
                sortedDataList: _.orderBy(
                    _.filter(events, function (item) {
                        return (
                            (
                                _.toLower(_.get(item, ["organization"], "")).indexOf(_.toLower(filter)) !== -1 ||
                                _.toLower(_.get(item, ["meetingname"], "")).indexOf(_.toLower(filter)) !== -1
                            )
                            &&
                            (moment.unix(_.get(item, ["Stagetimes", 0, "starttime"])).isSameOrAfter(new Date()))
                        );

                    }),
                    function(item) {
                        return _.get(item, ["Stagetimes", 0, "starttime"]);
                    },
                    [_.lowerCase("asc")]
                )
            });
        }
    }, 100)

    __onReloadEvents = () => {
        if (_.isFunction(this.props.onReload)) {
            this.props.onReload();
        }
    }

    render() {
        let {
            events,
            onChooseEvent
        } = this.props;

        if (_.isEmpty(events)) {
            return (
                <div className="center-block">
                    <i className="fa fa-spin fa-circle-o-notch fa-2x fa-fw" />
                </div>
            );
        }

        return (
            <div>
                {(_.size(this.props.events) > 3) &&
                <div style={{margin:"0 auto", width: "200px"}}>
                    <div className="input-group">
                        <input type="text" className="form-control input-sm" size="35" name="filter" placeholder={__("filter events")} defaultValue={this.state.filter} onChange={this.__updateFilter} style={{borderRadius:"60px"}} />
                        <span className="fa fa-search form-control-feedback" style={{zIndex:3}}></span>
                    </div>
                </div>
                }
                <br />
                <br />
                <div className="center-block">
                    <div className="row">
                        <div className="col-sm-10 col-sm-offset-1 text-left">
                            <div className="row paj-event-search-event-row">
                                {_.map(this.state.sortedDataList, function(event, i) {
                                    return (
                                        <div key={i} className="col-sm-3 paj-event-search-event" style={{cursor: "pointer", padding:"1em 1em"}} onClick={() => onChooseEvent(_.get(event, ["eid"]))}>
                                            <Calendar date={moment.utc(new Date(_.get(event, ["Stagetimes", 0, "starttime"], 0) * 1000)).toDate()} style={{margin: "0 auto"}}/>
                                            <br />
                                            <div>{_.get(event, ["organization"])}</div>
                                            <div className="text-muted small">{_.get(event, ["meeting_name"])}</div>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="text-center">
                    <i>Don't see your event listed?  <span className="link" onClick={this.__onReloadEvents}>Try reloading the list.</span></i>
                </div>
            </div>
        );
    }

}