import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { gettext as __ } from "../../../../utils/Trans";
import LoginForm from "../../LoginForm";
import Analytics from "../../../../utils/Analytics";

class Login extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    SignupOnclick() {
        Analytics.sendEvent("BuyerSignup", "click", 0, "signin page");
    }

    render() {
        let {
            location
        } = this.props;

        return (
            <div>
                <h1 className="hidden-xs page-header">
                    {__("Marketplace Sign in")}
                    {" "}
                    <span className="small" style={{fontSize: "14px"}}>{__("Need an account?")}{" "}<Link to={_.assign({}, location, {pathname: "/signup"})} onClick={this.SignupOnclick.bind(this)}>{__("Sign Up")}</Link></span>
                </h1>
                <div className="row">
                    <div className="col-sm-5">
                        <LoginForm {...this.props} />
                    </div>
                    <div className="col-sm-2" />
                    <div className="col-sm-4">
                        <div className="alert alert-warning" role="alert">
                            <h4>{__("Are you a presenter or speaker?")}</h4>
                            <hr />
                            <p>
                                {__("Event organizers search our Marketplace Directory every day looking for the perfect presenter.")}
                            </p>
                            <p>
                                {__("Manage your profile and events on your")}{" "}<a className="text-warning" href="/myevents/">{__(" EventCX Calendar.")}</a>
                            </p>
                            <br />
                            <div className="text-center">
                                <a className="btn btn-warning" href="/myevents/">Presenter Login</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-1" />
                </div>
                <br/>
                <br/>
            </div>
        );
    }
}

export default Login;
