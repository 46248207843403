import React from "react";

export default class TextWithInputGroup extends React.Component {

    render() {
        let {
            input,
            placeholder,
            label,
            input_group_before,
            input_group_after,
            help_text,
            type,
            meta: {
                touched,
                error,
                warning
            }
        } = this.props;

        return (
            <div>
                <div className={"form-group " + (touched && error ? "has-error " : touched && warning ? "has-warning " : "")}>
                    {label && <label className="control-label">{label}</label>}
                    <div className="input-group">
                        {input_group_before}
                        <input
                            {...input}
                            placeholder={placeholder}
                            type={type}
                            className="form-control"
                        />
                        {input_group_after}
                    </div>
                    {help_text && <div className="help-block">{help_text}</div>}
                    {touched &&
                    ((error && <div className="text-danger"><i className="fa fa-times" /> {error}</div>) ||
                        (warning && <div className="text-danger"><i className="fa fa-exclamation-triangle" /> {warning}</div>))}
                </div>
            </div>
        );
    }
}