import React from "react";
import "./Flip.scss";

export default class Flip extends React.Component {

    render() {
        let {
            flip,
            children,
            height = "100%"
        } = this.props;

        let style = {
            height: height
        };

        return (
            <div className={"flip-container " + ((flip) ? "hover" : "")} style={style}>
                <div className="flipper">
                    {children}
                </div>
            </div>
        );
    }
}