import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from "moment";
import { Field, Form, formValueSelector, reduxForm, SubmissionError } from "redux-form";
import { gettext as __ } from "../../../../utils/Trans";
import { DateRangePickerField, Message, Select, Selectize, Text } from "../../../../components/FormComponents";
import LoadingSubmit from "../../../../components/LoadingSubmit";
import InlineError from "../../../../components/InlineError";
import Event from "../../../../actions/Event/Event";
import convertJSEventToBalboa3Event from "../../../../utils/convertJSEventToBalboa3Event";
import { getRangeIndexFromEvent, setEventFromRangeIndex } from "../../../../utils/BudgetRangeConverter";
import "./EditEventForm.scss";

class EditEventForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = { };
    }

    handleSubmit(values, dispatch, props) {
        // Merge the form values into the existing event object and save it
        // let event = _.cloneDeep(props.event);
        let event = _.assign({}, values);
        event.id = values.eid;

        event.budget_min = setEventFromRangeIndex(values.budget, props.budget_ranges).min;
        event.budget_max = setEventFromRangeIndex(values.budget, props.budget_ranges).max;

        event.topic_ids = _.map(_.split(values.topic_ids, ","), (item) => {
            return _.toInteger(_.get(item, ["value"], item));
        });

        event.Stagetime = _.isArray(event.Stagetime) ? event.Stagetime : [];
        let stagetime = _.first(event.Stagetime);
        if (!_.isPlainObject(stagetime)) {
            stagetime = {id: 0};
            event.Stagetime.push(stagetime);
        }

        let event_dates = _.split(values.start_date, " - ");
        stagetime.presenter_type = _.toNumber(values.presenter_type);
        stagetime.starttime = _.get(event_dates, [0], values.start_date);
        stagetime.stoptime = _.get(event_dates, [1], stagetime.starttime);
        event.starttime = _.get(event_dates, [0], values.start_date);
        event.stoptime = _.get(event_dates, [1], event.starttime);
        if (/^Session on /i.test(event.meetingname)) {
            event.meetingname = __("Session on %s", moment(event.starttime, "MM/DD/Y").format("MMM Do, Y"));
        }

        event.Venue = _.isArray(event.Venue) ? event.Venue : [];
        let venue = _.first(event.Venue);
        if (!_.isPlainObject(venue)) {
            event.Venue.push({id: 0});
            venue = _.first(event.Venue);
        }
        venue.city = values.city;
        venue.st = values.st;
        venue.country = values.country;

        _.set(event, ["FlagsAsMap", "date_tentative", "is_set"], values.date_tentative);

        return dispatch(Event.update({
            id: _.get(event, ["eid"]),
            event_json: JSON.stringify(convertJSEventToBalboa3Event(event))
        }))
            .then(() => {
                if (_.isFunction(props.onClose)) {
                    props.onClose();
                }
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
            handleSubmit,
            submitting,
            error,
            presenter_types,
            delivery_methods,
            topics,
            budget_ranges,
            states,
            countries,
            is_onsite,
            is_virtual,
            onClose
        } = this.props;

        return (
            <Form role="form" className="MP-EventForm mp-style-form" onSubmit={handleSubmit(this.handleSubmit)}>
                <div className="modal-body">
                    {error && <InlineError error={_.get(error, ["message"])}/>}
                    <div className="help-block">{__("Who is the audience? Example: \"Winners Association Annual Conference\"")}</div>
                    <div className="row">
                        <div className="col-sm-7">
                            <Field
                                name="organization"
                                type="text"
                                className="form-control"
                                placeholder={__("Annual Sales Conference")}
                                label={__("Meeting Name")}
                                component={Text}
                            />
                        </div>
                        <div className="col-sm-5">
                            <Field
                                name="meetingname"
                                type="text"
                                className="form-control"
                                placeholder={__("Opening Keynote")}
                                label={__("Session")}
                                component={Text}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <Field
                                name="presenter_type"
                                type="select"
                                className="form-control"
                                placeholder={__("Annual Sales Conference")}
                                label={__("What I Need")}
                                component={Selectize}
                                options={_.map(presenter_types, function (item) {
                                    return {value: _.get(item, ["id"]), label: _.get(item, ["description"])};
                                })}
                            />
                        </div>
                        <div className="col-sm-6">
                            <Field
                                name="topic_ids"
                                type="text"
                                className="form-control"
                                placeholder={__("Annual Sales Conference")}
                                label={__("Speaking On")}
                                component={Selectize}
                                multi={true}
                                simpleValue={true}
                                options={_.map(topics, function (item) {
                                    return {value: _.get(item, ["id"]), label: _.get(item, ["name"])};
                                })}
                            />
                        </div>
                    </div>
                    <div className="help-block">{__("When do you need the presenter? If you aren't sure, choose the closest date you know and check the Tentative Date box.")}</div>
                    <div className="row">
                        <div className="col-sm-8">
                            <div className="row">
                                <div className="col-sm-10">
                                    <Field
                                        name="start_date"
                                        className="form-control"
                                        label={__("Event Dates")}
                                        placeholder="mm/dd/yyyy"
                                        component={DateRangePickerField}
                                        type="text"
                                        ranges={false}
                                        opens="center"
                                        singleDatePicker={false}
                                        autoApply={true}
                                        autoUpdateInput={true}
                                        showCustomRangeLabel={false}
                                        style={{width:"100%"}}
                                        help_text={<label>
                                            <Field
                                                name="date_tentative"
                                                type="checkbox"
                                                label={__("Tentative Date")}
                                                component="input"
                                            />
                                            {" "}
                                            {__("My dates are tentative and may change")}
                                        </label>}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <Field
                                name="deliveryMethod"
                                type="select"
                                className="form-control"
                                placeholder={__("on-site")}
                                label={__("Type of Event")}
                                component={Selectize}
                                options={_.map(delivery_methods, function (item) {
                                    return {value:_.get(item, ["id"]), label: _.get(item, ["description"])};
                                })}
                            />
                        </div>
                    </div>
                    {is_virtual &&
                    <React.Fragment>
                        <div className="help-block">{__("If your meeting will be virtual, enter the meeting url below.")}</div>
                        <Field
                            name="zoomUrl"
                            className="form-control"
                            label={__("Meeting URL")}
                            component={Message}
                            rows={3}
                            length={255}
                        />
                    </React.Fragment>}
                    {is_onsite &&
                        <div>
                            <div
                                className="help-block">{__("Please select where your event will take place so the speaker can make travel arrangements.")}</div>
                            <div className="row">
                                <div className="col-sm-4">
                                    <Field
                                        name="city"
                                        type="text"
                                        className="form-control"
                                        label={__("City")}
                                        component={Text}
                                    />
                                </div>
                                <div className="col-sm-4">
                                    {_.size(states) > 0 ?
                                        <Field
                                            name="st"
                                            className="form-control"
                                            label={__("State")}
                                            component={Selectize}
                                            options={_.map(states, function (item, i) {
                                                return {label: _.startCase(item), value: i};
                                            })}
                                        />
                                        :
                                        <Field
                                            name="st"
                                            className="form-control"
                                            label={__("State")}
                                            component={Text}
                                        />
                                    }
                                </div>
                                <div className="col-sm-4">
                                    <Field
                                        name="country"
                                        type="text"
                                        className="form-control"
                                        label={__("Country")}
                                        component={Select}
                                    >
                                        <optgroup label="Common Countries">
                                            {_.map(_.pickBy(countries, function (item) {
                                                return (
                                                    item === "United States" ||
                                                    item === "Canada" ||
                                                    item === "Mexico"
                                                );
                                            }), function (item, i) {
                                                return (
                                                    <option key={i} value={i}>{item}</option>
                                                );
                                            })}
                                        </optgroup>
                                        <optgroup label="All Countries">
                                            {_.map(_.pickBy(countries, function (item) {
                                                return (
                                                    item !== "United States" &&
                                                    item !== "Canada" &&
                                                    item !== "Mexico"
                                                );
                                            }), function (item, i) {
                                                return (
                                                    <option key={i} value={i}>{item}</option>
                                                );
                                            })}
                                        </optgroup>
                                    </Field>
                                </div>
                            </div>
                        </div>
                    }
                    <div className="help-block">{__("How much do you want to spend on the presenter? If you don't have a firm budget, guess high -- we may be able to find you a local speaker who will discount.")}</div>
                    <div className="row">
                        <div className="col-xs-12 col-sm-6">
                            <Field
                                name="budget"
                                type="select"
                                className="form-control"
                                label={__("Budget")}
                                component={Selectize}
                                options={_.map(budget_ranges, function (item, i) {
                                    return {value: ("range_" + i), label: _.get(item, ["label"])};
                                })}
                            />
                        </div>
                    </div>
                    <div className="help-block">{__("What are your goals for this meeting and what can the presenter do for you?")}</div>
                    <Field
                        name="meeting_goals"
                        className="form-control"
                        label={__("Meeting Goals")}
                        component={Message}
                        rows={5}
                    />
                </div>
                <div className="modal-footer">
                    <LoadingSubmit is_saving={submitting} save_text={__("Updating...")} className="btn-success btn-block-xs">
                        {__("Update Event")}
                    </LoadingSubmit>
                    {" "}
                    <div className="btn btn-default btn-block-xs" disabled={submitting} onClick={onClose}>
                        {__("Never Mind")}
                    </div>
                </div>
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

EditEventForm.propTypes = {
    event: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

function mapStateToProps(state, ownProps) {

    // selector to pull out form values
    let selector = formValueSelector("shortlist_page_edit_event");
    let selected_country = selector(state, "country"); // used to filter the states
    let selected_delivery_method = selector(state, "deliveryMethod"); // used to show/hide the location

    let venue = _.first(_.get(ownProps.event, ["Venues"])) || {};
    let stagetime = _.first(_.get(ownProps.event, ["Stagetimes"])) || {};
    let budget_ranges = _.get(state, ["Whitelabel", "sitelists", "feeranges"], []);
    let topics = _.get(state, ["Whitelabel", "sitelists", "tags"], []);
    let delivery_methods = _.get(state, ["Whitelabel", "sitelists", "universal", "deliverymethods"], []);
    let presenter_types = _.get(state, ["Whitelabel", "sitelists", "universal", "presenter_types"], []);

    //get the longest name for each country
    let countries = _.get(state, ["Whitelabel", "sitelists", "universal", "countrylist"], []);
    countries = _.mapValues(_.groupBy(countries, "sc"), function (list) {
        return _.last(_.sortBy(_.map(list, "lc"), _.size));
    });

    let states = _.get(state, ["Whitelabel", "sitelists", "universal", "statelist"], []);
    states = _.mapValues(_.groupBy(_.pickBy(states, function (item) {
        return item.sc === selected_country;
    }), "ss"), function (list) {
        return _.last(_.sortBy(_.map(list, "ls"), _.size));
    });

    // We have to convert from unix time to UTC, drop the time, then reparse just the date so we avoid timezone issues
    let event_date = moment(moment.utc(_.get(ownProps.event, ["Stagetimes", 0, "starttime"]) * 1000).format("MM/DD/YYYY"), "MM/DD/YYYY").format("MM/DD/YYYY");
    if (!moment(moment.utc(_.get(ownProps.event, ["Stagetimes", 0, "starttime"]) * 1000).format("MM/DD/YYYY"), "MM/DD/YYYY").isSame(moment(moment.utc(_.get(ownProps.event, ["Stagetimes", 0, "stoptime"]) * 1000).format("MM/DD/YYYY"), "MM/DD/YYYY"))) {
        event_date += (" - " + moment(moment.utc(_.get(ownProps.event, ["Stagetimes", 0, "stoptime"]) * 1000).format("MM/DD/YYYY"), "MM/DD/YYYY").format("MM/DD/YYYY"));
    }

    return {
        budget_ranges: budget_ranges,
        presenter_types: presenter_types,
        topics: topics,
        delivery_methods: delivery_methods,
        countries: countries,
        states: states,
        is_onsite: (_.toNumber(selected_delivery_method) === 1), // on-site
        is_virtual: (_.toNumber(selected_delivery_method) === 2), // virtual
        initialValues: {
            eid: _.get(ownProps.event, ["eid"]),
            organization: _.get(ownProps.event, ["organization"]),
            meetingname: _.get(ownProps.event, ["meetingname"]),
            start_date: event_date,
            date_tentative: _.get(ownProps.event, ["flags_as_map", "date_tentative", "is_set"], false),
            deliveryMethod: _.get(ownProps.event, ["deliveryMethod"]),
            city: _.get(venue, ["city"]),
            st: _.get(venue, ["st"]),
            country: _.get(venue, ["country"], "US"),
            budget: getRangeIndexFromEvent(ownProps.event, budget_ranges),
            topic_ids: _.get(ownProps.event, ["topic_ids"]),
            language: _.get(ownProps.event, ["language"], "en"),
            presenter_type: _.get(stagetime, ["presenter_type"], 1),
            event_id: _.get(ownProps.event, ["eid"]),
            meeting_goals: _.get(ownProps.event, ["Shortlists", 0, "notes"]),
            zoomUrl: _.get(ownProps.event, ["zoomUrl"], ""),
        }
    };
}

function mapDispatchToProps() {
    return {};
}

EditEventForm = reduxForm({
    form: "shortlist_page_edit_event",
    validate: function (values) {
        const errors = {};
        if (!values.name) {
            errors.name = __("Please enter a name for this meeting.");
        }
        if (!values.budget) {
            errors.budget = __("Please choose your budget.");
        }
        if (!values.start_date) {
            errors.start_date = __("Please select when your meeting will occur.");
        }
        if (!values.start_date) {
            errors.start_date = __("Please select when your meeting will occur.");
        } else {
            try {
                // Make sure we have a good date
                if (!moment(values.start_date, "M/D/Y").isValid()) {
                    errors.start_date = __("Please select when your meeting will occur.");
                }
            } catch (e) {
                errors.start_date = __("Please select when your meeting will occur.");
            }
        }
        if (values.delivery === 1) {
            if (!values.country) {
                errors.country = __("Please select the country.");
            }
            if (!values.city) {
                errors.city = __("Please enter the city.");
            }
        }
        return errors;
    }
})(EditEventForm);

export default connect(mapStateToProps, mapDispatchToProps)(EditEventForm);