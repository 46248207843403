import React from "react";
import PropTypes from "prop-types";

class ModalWrap extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            is_open: props.is_open
        };
    }

    // componentWillReceiveProps(newProps) {
    //     if (newProps.is_open !== this.props.is_open) {
    //         this.setState({is_open: newProps.is_open});
    //     }
    // }

    render() {
        let {
            children
        } = this.props;

        if (!this.state.is_open) {
            return null;
        }

        return (
            <div className="modal" style={{display: (this.state.is_open ? "block" : "none")}}>
                {children}
            </div>
        );
    }
}

ModalWrap.propTypes = {
    is_open: PropTypes.bool
};

export default ModalWrap;