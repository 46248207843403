import _ from "lodash";
import toInt from "./toInt";
import cleanupIDs from "./cleanupIDs";
import convertFromISO8601 from "./convertFromISO8601";

/**
 * Type coercion from anything to float or 0
 * @param str
 * NOTE By design this takes one argument (so it can be directly used in mapping functions)
 * @returns {*|number}
 */
var toFloat = function(str){//NOTE By design this takes one argument (so it can be directly used in mapping functions)
      return parseFloat(_.isString(str) ? str.replace(/[^\-0-9.]/g, "") : str) || 0;
};

var FIX_fromISO8601 = function(obj, fields){
    _.each(_.filter(_.flattenDeep([fields]), _.isString), function(field){
        if(_.has(obj, field + "_ISO8601") && _.has(obj, field)){
            obj[field] = obj[field] < 1 ? null : convertFromISO8601(obj[field + "_ISO8601"]);
        }
    });
    return obj;
};

var FIX_fromISO8601_or_DateConstructor = function(obj, fields){
    _.each(_.filter(_.flattenDeep([fields]), _.isString), function(field){
        if(_.has(obj, field + "_ISO8601") && _.has(obj, field)){
            obj[field] = obj[field] < 1 ? null : convertFromISO8601(obj[field + "_ISO8601"]);
        }else if(_.has(obj, field)){
            obj[field] = new Date(obj[field]);
        }
    });
    return obj;
};

var StoredFile_Balboa3toJS = function(StoredFile){
    StoredFile.id = toInt(StoredFile.id);
    StoredFile.eid = toInt(StoredFile.eid);
    StoredFile.sids = cleanupIDs(StoredFile.sids);
    StoredFile.postdate = StoredFile.postdate < 1 ? null : convertFromISO8601(StoredFile.postdate_iso8601);
    return StoredFile;
};

var fixMPcontractDates = function(MPcontract){
    _.each(["created", "buyer_signed", "speaker_signed"], function(field){
        if(MPcontract[field] !== false){
            MPcontract[field] = convertFromISO8601(MPcontract[field + "_ISO8601"]);
        }
    });
    return MPcontract;
};

var convertBalboa3ToJS = {
    Flight: function(flight){
        flight.id = toInt(flight.id);
        flight.eid = toInt(flight.eid);
        flight.cost = toFloat(flight.cost);
        flight.legs = _.map(flight.legs, function(leg){
            leg.id = toInt(leg.id);
            leg.legcost = toFloat(leg.legcost);
            return FIX_fromISO8601_or_DateConstructor(leg, ["legleave", "legarrive"]);
        });
        return flight;
    },
    Hotel: function(hotel){
        hotel.id = toInt(hotel.id);
        hotel.eid = toInt(hotel.eid);
        return FIX_fromISO8601(hotel, ["checkindate", "checkoutdate"]);
    },
    Ground: function(ground){
        ground.id = toInt(ground.id);
        ground.eid = toInt(ground.eid);
        ground.cost = toFloat(ground.cost);
        return FIX_fromISO8601(ground, "starttime");
    }
};

export default function(event){
    event.eid = toInt(event.eid);
    event.sids = cleanupIDs(event.sids);
    event.bureauID = toInt(event.bureauID);

    event.companyID = toInt(event.companyID);
    event.presentingproductid = toInt(event.presentingproductid);

    event.topics = _.map(event.topics, function(topic){
        return topic.replace(/\//g, " / ").trim();
    });

    if(event.MPcontract){
        event.MPcontract = fixMPcontractDates(event.MPcontract);
    }

    if(event.budget_min > 0 && event.budget_max === 0){
        event.budget_max = Infinity;
    }

    event = FIX_fromISO8601(event, "created");
    event = FIX_fromISO8601(event, "ccDateTime");
    event = FIX_fromISO8601(event, "modified");

    event.Stagetime = _.map(event.Stagetime, function(stagetime){
        stagetime.venueid = toInt(stagetime.venueid);
        return FIX_fromISO8601(stagetime, ["starttime", "stoptime", "avchecktime"]);
    });

    if (!_.isEmpty(event.Stagetime)) {
        event.starttime = _.min(event.Stagetime, function (stagetime) {
            return _.isDate(stagetime.starttime) ? stagetime.starttime.getTime() : 0;
        }).starttime;

        event.stoptime = _.max(event.Stagetime, function (stagetime) {
            return _.isDate(stagetime.stoptime) ? stagetime.stoptime.getTime() : 0;
        }).stoptime;
    }

    event.Note = _.map(event.Note, function(note){
        return FIX_fromISO8601(note, "datetime");
    });

    _.each(["Flight", "Hotel", "Ground"], function(key){
        event[key] = _.map(event[key], convertBalboa3ToJS[key]);
    });

    _.each(event.VideoCoaching, function(p){
        _.each(p.sessions, function(s) {
            FIX_fromISO8601(s, "startdate");
        });
    });

    event.EventTodo = _.map(event.EventTodo, function(todo){
        todo.id = toInt(todo.id);
        todo.eid = toInt(todo.eid);
        todo.status = toInt(todo.status);
        todo.vieworder = toInt(todo.vieworder);
        todo.from_list_id = toInt(todo.from_list_id);
        todo.from_list_sid = toInt(todo.from_list_sid);
        return FIX_fromISO8601(todo, "statusdate");
    });
    event.storedfiles = _.map(event.storedfiles, StoredFile_Balboa3toJS);
    event.todolistids = _.map(_.isString(event.todolistids) && event.todolistids.split(","), function(part){
        var split = part.split("|");
        return {sid: toInt(split[0]), listid: toInt(split[1])};
    });

    return event;
}
