import _ from "lodash";
import React from "react";
import SpeakerTile from "../../components/SpeakerTile";

export default class ShowcaseResults extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speakers,
            shortlist,
            awards,
            languages,
            is_debug = false,
            onProfile,
            onFavorite,
            onViewPrograms,
            onViewSchedule,
            onWatchVideo
        } = this.props;


        let results = _.map(speakers, function(speaker, i) {

            return (
                <div key={i} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                    <SpeakerTile
                        awards={awards}
                        languages={languages}
                        speaker={speaker}
                        onProfile={onProfile}
                        onViewPrograms={onViewPrograms}
                        onViewSchedule={onViewSchedule}
                        onWatchVideo={onWatchVideo}
                        onFavorite={onFavorite}
                        is_debug={is_debug}
                        is_showcased={true}
                        is_shortlisted={(_.indexOf(shortlist, _.get(speaker, ["sid"])) >= 0)}
                    />
                </div>
            );
        });

        return (
            <div className="container search-page-results">
                <div className="row speaker-tile-wrapper">
                    {results}
                </div>
            </div>
        );
    }
}