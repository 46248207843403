import React from "react";

/**
 * Used for BookMeNow, Simplesite -- pages linked to directly from the speaker's website.
 * We want to respect that and not provide any pathways to other speakers.
 *
 * @param props
 * @returns {XML}
 */
class SpeakerSandboxedLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            children
        } = this.props;

        return (
            <div>
                <div className="container" style={{margin: "10px"}}>
                    {children}
                </div>
            </div>
        );
    }
}

export default SpeakerSandboxedLayout;