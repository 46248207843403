import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { gettext as __ } from "../../utils/Trans";
import LoadingIndicator from "../../components/LoadingIndicator";
import queryString from "query-string";
import TokenSignin from "../../actions/Login/TokenSignin";
import { sessionService } from "redux-react-session";

export class TokenCallbackPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            oauth_error: null
        };
    }

    componentDidMount() {
        if (!this.props.authWithToken()) {
            this.setState({
               oauth_error: "Error signing in"
            });
        }
    }

    render() {
        return (
            <div>
                <h1 className="page-header">
                    {__("Please wait while we sign you in")}
                </h1>
                <div className="row">
                    {_.isNull(this.state.oauth_error) && <LoadingIndicator />}
                    {this.state.oauth_error && <div className="alert alert-danger">{this.state.oauth_error}</div>}
                </div>
            </div>
        );
    }

}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

let mapStateToProps = function(/* state, ownProps */){
    return {

    };
};

let mapDispatchToProps = function(dispatch, ownProps){
    return {
        authWithToken: function() {
            let qs = queryString.parse(window.location.search);
            let redir = _.get(qs, ["redir"], "/event");
            return dispatch(TokenSignin({ estoken: _.get(qs, ["code"]) }))
                .then(() => {
                    if (_.startsWith(redir, "http")) {
                        window.location.href = redir;
                        return true;
                    } else {
                        return ownProps.history.push(redir);
                    }
                })
                .catch((err) => {
                    if (_.get(err, ["name"]) === "TokenException" || _.get(err, ["name"]) === "AuthenticationException") {
                        // Invalidate the session/force login screen
                        return sessionService.deleteSession()
                            .then(() => {
                                return sessionService.deleteUser();
                            })
                            .then(() => {
                                return ownProps.history.push("/error", {error: "This link has expired.  Please sign in instead."});
                            });
                    } else if (_.get(err, ["name"]) === "AuthorizationException") {
                        // Handled on the each mapStateToProps with .catch
                        return ownProps.history.push("/error", {error: _.get(err, ["message"])});
                    } else if (_.get(err, ["name"]) === "SpeakerMissingException") {
                        // Handled on the profile page
                        return ownProps.history.push("/error", {error: _.get(err, ["message"])});
                    } else {
                        return ownProps.history.push("/error", {error: _.get(err, ["message"])});
                    }
                });
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TokenCallbackPage));
