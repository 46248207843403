import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { gettext as __ } from "../../../../utils/Trans";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { Username } from "../../../../components/FormComponents";
import isValidEmail from "../../../../utils/isValidEmail";
import ForgotPassword from "../../../../actions/Login/ForgotPassword";

class ForgotPasswordForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            has_been_sent: false
        };
    }

    handleSubmit = (values, dispatch) => {
        return dispatch(ForgotPassword(values))
            .then((response) => {
                dispatch({
                    type: "NOTIFICATION_CREATE",
                    message: __("We sent you an email with a link to reset your password.  Check your inbox.")
                });
                this.setState({has_been_sent: true});
                return response;
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
            handleSubmit,
            submitSucceeded,
            submitting,
            error,
            location
        } = this.props;

        if (this.state.has_been_sent){
            return (
                <div>
                    <h3 className="text-success">
                        {__("Sent!")}
                    </h3>
                    <p>
                        {__("Check your email inbox. An email has just been sent to you with your password.")}
                    </p>
                    <p>
                        <Link to={_.assign({}, location, {pathname: "/signin"})}>
                            &laquo; {__("Back to Sign in")}
                        </Link>
                    </p>
                </div>
            );
        }

        return (
            <div className="row">
                <div className="col-md-4 col-sm-12">
                    <p>
                        {__("Enter your email address and we'll email you a link to reset your password.")}
                    </p>
                    <Form className="mp-style-form" onSubmit={handleSubmit(this.handleSubmit)}>
                        <Field
                            name="username"
                            className="form-control"
                            label="Email address"
                            component={Username}
                        />

                        <div className="form-group">
                            <button type="submit" className="btn btn-success btn-lg" disabled={submitting}>
                                {submitting ? <i className="fa fa-spin fa-circle-o-notch" /> : <i/>} {__("SEND")}
                            </button>
                            {submitSucceeded && <span className="text-success"> <i className="fa fa-check" /> {__("An email has been sent to you with a link to reset your password")}</span>}
                            {error && <span className="text-danger"> <i className="fa fa-times" /> {_.get(error, ["message"])}</span>}
                        </div>
                    </Form>

                    <br/>
                    <br/>
                    <p>
                        <Link to={_.assign({}, location, {pathname: "/collab/signin"})}>
                            &laquo; {__("Back to Sign in")}
                        </Link>
                    </p>
                </div>
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

let mapStateToProps = function(state, ownProps){
    return {
        initialValues: {
            bureau_id: _.get(ownProps.whitelabel, ["bid"]),
            username: null
        }
    };
};

let mapDispatchToProps = function(){
    return {

    };
};

ForgotPasswordForm = reduxForm({
    form: "signinpage_forgotpassword",
    validate: function(values) {
        const errors = {};
        if (!values.email) {
            errors.email = __("Please enter your email address.");
        }
        if (!isValidEmail(values.email)) {
            errors.email = __("Please enter a valid email address.");
        }
        return errors;
    }
})(ForgotPasswordForm);

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPasswordForm);
