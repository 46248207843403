import _ from "lodash";
import Balboa from "../Global/Balboa";
import convertFromISO8601 from "../../utils/convertFromISO8601";

function formatPayment(data) {
    var retval = {};
    _.each(data, function(value, key) {
        if((key === "id" || /_id$/.test(key)) && _.isString(value)) {
            retval[key] = _.toInteger(value);
        } else if(/_ISO8601$/.test(key) && _.has(data.data, key.replace(/_ISO8601$/, ""))) {
            var base = key.replace(/_ISO8601$/, "");
            retval[base] = convertFromISO8601(value);
        } else if(key === "CREATED" && _.isString(value)) {
            retval[key] = parseInt(value, 10);
            retval[key + "_ISO8601"] = new Date(_.toInteger(value, 10) * 1000);
        } else if(key === "fee" && _.isString(value)) {
            retval[key] = _.toNumber(value);
        } else if(key === "amount" && _.isString(value)) {
            retval[key] = _.toNumber(value);
        } else if(key === "flags") {
            retval[key] = value;
        } else if(_.isObject(value)) {
            retval[key] = formatPayment(value);
        } else if(!_.has(retval, key)) {
            retval[key] = value;
        }
    });
    return retval;
}

export default function(eid, clear_cache = false){
    return (dispatch, getState) => {
        var key = ["MPPayments", eid];
        var cache_ttl = clear_cache ? -1 : 30 * 60 * 1000;
        return Balboa(dispatch, getState)
            .post(key, "/mppayment/read/" + eid, {
                    event_id: eid
                }, {cache_ttl: cache_ttl})
            .then((response) => {
                let data = formatPayment(_.get(response, ["data"], {}));
                // Save the user to the state
                return dispatch({
                    type: "BUYER_EVENT_PAYMENT_LOAD",
                    eid: eid,
                    data: data
                });
            })
            .then((response) => {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            });
    };
};
