import _ from "lodash";
import React from "react";
import formatCurrency from "../../../../../utils/formatCurrency";
import {gettext as __} from "../../../../../utils/Trans";


const formatFee = (low, high) => {
  if (low === 0 && high > 0) {
    return (<div>{__("up to")}{" "}{formatCurrency(high)} USD</div>);
  } else if (low > 0 && high > 0) {
    return (<div>{formatCurrency(low)} - {formatCurrency(high)} USD</div>);
  } else {
    return (<div>{__("Contact for fees")}</div>);
  }
};

export default class VirtualFees extends React.Component {
  state = {
    low_fee: 0,
    high_fee: 0,
    speaker: null
  }

  static getDerivedStateFromProps = (props) => ({
    speaker: props.speaker,
    low_fee: _.get(props.speaker, ["v_low_fee"], 0),
    high_fee: _.get(props.speaker, ["v_high_fee"], 0),
  })

  render() {
    let {
          low_fee,
          high_fee,
          // speaker,
        } = this.state;

    return (
      <React.Fragment>
        <h4 className="header legend">{__(" Virtual Fees")}</h4>
        <p className="text-muted"> Fees for remote presentations may be lower than for on-site. </p>
        <h5 style={{fontWeight: "bold"}} className={"text-bold"}>
          {formatFee(low_fee, high_fee)}
        </h5>
      </React.Fragment>
    );
  }
}