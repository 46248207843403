import React from "react";
import { Experiment, Variant } from "react-ab";
import SearchPageA from "./ab/SearchPageA";
import SearchPageB from "./ab/SearchPageB";
import Analytics from "../../utils/Analytics";

export default class SearchPage extends React.Component {

    __recordPageHit = (experiment, variant, index, was_retrieved) => {
        Analytics.sendEvent(experiment, "View", variant, was_retrieved);
        return true;
    }

    render() {

       // return (<SearchPageA {...this.props} />);

        return (
            <Experiment onChoice={(experiment, variant, index, was_retrieved) => { return this.__recordPageHit(experiment, variant, index, was_retrieved); }} name="searchpage_ab">
                <Variant name="SearchPageA">
                    <SearchPageA {...this.props} />
                </Variant>
                <Variant name="SearchPageB">
                    <SearchPageB {...this.props} />
                </Variant>
            </Experiment>
        );
    }
}