import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import { Modal } from "react-bootstrap";
import WithdrawOfferForm from "./WithdrawOfferForm";

class WithdrawOfferModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            event,
            speaker,
            onClose
        } = this.props;

        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("Withdraw Offer")}</Modal.Title>
                </Modal.Header>
                <WithdrawOfferForm
                    event={event}
                    speaker={speaker}
                    onClose={onClose}
                />
            </Modal>
        );
    }
}

WithdrawOfferModal.propTypes = {
    event: PropTypes.object.isRequired,
    speaker: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

export default WithdrawOfferModal;
