import _ from "lodash";

export default function (state = {}, action) {
    switch (action.type) {
        case "AFFILIATE_EVENT_GET": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action.data, ["id"]);
            _.setWith(new_state, [id], action.data, Object);
            return new_state;
        }
        case "AFFILIATE_EVENT_GET_ALL": {
            let new_state = _.cloneDeep(state);
            _.each(action.data, function(item) {
                let id = _.get(item, ["id"]);
                _.setWith(new_state, [id], item, Object);
            });
            return new_state;
        }
        case "AFFILIATE_EVENT_DELETE": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            _.unset(new_state, [id]);
            return new_state;
        }
        case "AFFILIATE_EVENT_PUT": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action.data, ["id"]);
            _.setWith(new_state, [id], action.data, Object);
            return new_state;
        }
        case "AFFILIATE_EVENT_POST": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action.data, ["id"]);
            _.setWith(new_state, [id], action.data, Object);
            return new_state;
        }
        /*
         * Other actions used to update the affiliate event
         */
        case "AFFILIATE_EVENT_COLLABORATOR_POST": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            if (_.has(action.data, ["id"])) {
                _.setWith(new_state, [id, "shortlist", "collaborators", _.get(action.data, ["id"])], action.data, Object);
            } else {
                _.each(action.data, (item) => {
                    _.setWith(new_state, [id, "shortlist", "collaborators", _.get(item, ["id"])], item, Object);
                });
            }
            return new_state;
        }
        case "AFFILIATE_EVENT_COLLABORATOR_DELETE": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            _.unset(new_state, [id, "shortlist", "collaborators", _.get(action.data, ["id"])]);
            return new_state;
        }
        case "AFFILIATE_EVENT_RATING_POST": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            let speaker_id = _.get(action.data, ["speaker_id"]);
            let mp_collaborator_id = _.get(action.data, ["id"]);
            _.setWith(new_state, [id, "shortlist", "speakers", speaker_id, "ratings", mp_collaborator_id], action.data, Object);

            return new_state;
        }
        case "AFFILIATE_EVENT_SPEAKER_DELETE": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            _.unset(new_state, [id, "shortlist", "speakers", _.get(action.data, ["id"])]);
            return new_state;
        }
        default:
            return state;
    }
}