import React from "react";
import { gettext as __ } from "../../../../utils/Trans";
import LoginForm from "./LoginForm";
import { Panel } from "react-bootstrap";

class PostAJobAffiliate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {

        return (
            <div className="row">
                <div className="col-md-6 col-md-offset-3">
                    <Panel>
                        <Panel.Body>
                            <div className="visible-xs-block" style={{marginTop:"4.5em"}}></div>
                            <h2>{__("You're almost there!")}</h2>
                            <p>
                                Please login to your account to finish sending your inquiry to the selected speakers.
                            </p>

                            <div className="row">
                                <div className="col-sm-10 col-sm-offset-1 col-xs-12">
                                    <LoginForm {...this.props} />
                                </div>
                            </div>

                            <div className="help-block instruction hidden-bureaumode">
                                <p>{__("Your inquiry will be sent to the speakers and the event will be added to your account after you sign in.")}</p>
                            </div>
                        </Panel.Body>
                    </Panel>
                </div>
            </div>
        );
    }
}

export default PostAJobAffiliate;
