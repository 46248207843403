import _ from "lodash";
import React from "react";
// import VirtualizedSelect from "react-virtualized-select";
import Select from "react-select";
// Be sure to include styles at some point, probably during your bootstrapping
import "../../../../node_modules/react-select/dist/react-select.css";
import "./Selectize.scss";

// import "../../../../node_modules/react-virtualized-select/styles.css";

class Selectize extends React.Component {

    render() {
        let {
            input,
            placeholder,
            label,
            disabled = false,
            hide_errors,
            help_text,
            options,
            multi,
            promptTextCreator,
            simpleValue = false,
            delimiter = ",",
            optionHeight,
            async,
            arrowRenderer,
            creatable = false,
            loadOptions,
            cache = false,
            autoload = false,
            meta: {
                touched,
                error,
                warning
            }
        } = this.props;

        optionHeight = optionHeight || 24;

        let inputProps = _.assign({}, input, {
            async: async,
            multi: multi,
            disabled: disabled,
            creatable: creatable,
            cache: cache,
            delimiter: delimiter,
            simpleValue: simpleValue,
            autoload: autoload,
            placeholder: placeholder,
            optionHeight: optionHeight,
            selectValue: (value) => {
                return input.onChange(_.get(value, ["value"], value));
            },
            onChange: (value) => {
                return input.onChange(_.get(value, ["value"], value));
            },
            onBlur: () => input.onBlur(input.value)
        });
        if (_.isFunction(promptTextCreator)) {
            inputProps.promptTextCreator = promptTextCreator;
        }
        if (_.isFunction(arrowRenderer)) {
            inputProps.arrowRenderer = arrowRenderer;
        }

        if (async) {
            inputProps.loadOptions = loadOptions;
        } else if (!_.isUndefined(options)) {
            inputProps.options = options;
        }

        return (
            <div>
                <div className={"form-group " + (touched && error ? "has-error " : touched && warning ? "has-warning " : "")}>
                    {label && <label className="control-label">{label}</label>}
                    {async && creatable &&
                    <Select.AsyncCreatable
                        {...inputProps}
                    />
                    }
                    {async && !creatable &&
                    <Select.Async
                        {...inputProps}
                    />
                    }
                    {!async && creatable &&
                    <Select.Creatable
                        {...inputProps}
                    />
                    }
                    {!async && !creatable &&
                    <Select
                        {...inputProps}
                    />
                    }
                    {help_text && <div className="help-block">{help_text}</div>}
                    {!hide_errors && touched &&
                    ((error && <div className="text-danger"><i className="fa fa-times"/> {error}</div>) ||
                    (warning &&
                    <div className="text-danger"><i className="fa fa-exclamation-triangle"/> {warning}</div>))}
                </div>
            </div>
        );
    }
}

export default Selectize;