import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import UnreadBadge from "../UnreadBadge";
import AlertBadge from "../AlertBadge";
import Calendar from "../Calendar";
import { gettext as __ } from "../../utils/Trans";
import moment from "moment";

class CalendarIcon extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            event,
            notifications,
            speaker_count,
            is_public
        } = this.props;

        let offer_status = (!_.isEmpty(_.get(event, "offer_status", null))) ?
            <div className="label label-info">
                {__(_.get(event, "offer_status", null))}
            </div> : null;

        let notification_badge = notifications > 0
            ?
            <UnreadBadge
                n_unread={notifications}
                style={{
                    position: "absolute",
                    minWidth: "25px",
                    fontSize: "14pt",
                    zIndex: 10000,
                    top: "-8px",
                    left: "85px"
                }}
            />
            :
            (!is_public && speaker_count === 0)
                ?
                <AlertBadge
                    style={{
                        position: "absolute",
                        minWidth: "25px",
                        fontSize: "14pt",
                        zIndex: 10000,
                        top: "-8px",
                        left: "85px"
                    }}
                    className="badge-warning"
                >
                    !
                </AlertBadge>
                :
                null;
        return (
            <div style={{padding:"3px"}}>
                <div className="text-center" style={{position: "relative"}}>
                    <Calendar date={moment(_.get(event, ["starttime"])).toDate()} />
                    {notification_badge}
                    <div className="hidden-lg hidden-md" style={{padding: "5px"}}>
                        {offer_status}
                    </div>
                </div>
            </div>
        );
    }
}

CalendarIcon.propTypes = {
    notifications: PropTypes.number,
    speaker_count: PropTypes.number,
    is_public: PropTypes.bool,
    event: PropTypes.object
};

export default CalendarIcon;