import React from "react";
import { gettext as __ } from "../../../../utils/Trans";
import ForgotPasswordForm from "../../ForgotPasswordForm";

export default class ForgotPasswordDefault extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <h1 className=" page-header">
                    {__("Forgot password")}
                </h1>
                <ForgotPasswordForm {...this.props} />
                <br/>
                <br/>
                <br/>
            </div>
        );
    }
}