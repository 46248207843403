import _ from "lodash";
import Rest from "../Global/Rest";
import Balboa from "../Global/Balboa";
import {gettext as __} from "../../utils/Trans";

export default _.assign({}, Rest, {

    /**
     * Take control of a shortlist by adding it to your account
     * @param params
     * @return {Function}
     */
    takeownership: function(params) {
        let key = ["MPShortlist_TakeOwnership", _.get(params, ["id"])];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/mpshortlist/takeownership/" + _.get(params, ["id"]), params)
                    .then((response) => {
                        return dispatch(self.get(_.get(params, ["id"])));
                    })
                    .then((response) => {
                        dispatch({
                            type: "NOTIFICATION_CREATE",
                            message: __("The shortlist has been added to your account")
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Add a collaborator to the shortlist
     * @param params
     * @return {Function}
     */
    addcollaborator: function(params) {
        let key = ["MPShortlist_AddCollaborator", _.get(params, ["id"])];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/mpshortlist/emailshortlist/" + _.get(params, ["id"]), params)
                    .then((response) => {
                        return dispatch(self.get(_.get(params, ["id"])));
                    })
                    .then((response) => {
                        dispatch({
                            type: "NOTIFICATION_CREATE",
                            message: __("The collaborator(s) were added to the shortlist")
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Add a speaker to the shortlist
     * @param params
     * @return {Function}
     */
    addspeaker: function(params) {
        let key = ["MPShortlist_AddSpeaker", _.get(params, ["id"])];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/mpshortlist/addspeaker/" + _.get(params, ["id"]), params)
                    .then((response) => {
                        return dispatch(self.get(_.get(params, ["mp_shortlist_id"])));
                    })
                    .then((response) => {
                        dispatch({
                            type: "NOTIFICATION_CREATE",
                            message: __("The speaker was added to the shortlist")
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Remove a speaker to the shortlist
     * @param params
     * @return {Function}
     */
    removespeaker: function(params) {
        let key = ["MPShortlist_RemoveSpeaker", _.get(params, ["id"])];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .del(key, "/marketplace/mp-shortlist-speaker/" + _.get(params, ["id"]), params)
                    .then((response) => {
                        return dispatch(self.get(_.get(params, ["mp_shortlist_id"])));
                    })
                    .then((response) => {
                        return dispatch({
                            type: "EVENT_SHORTLIST_DISMISS_SPEAKER",
                            eid: _.get(response, ["event_id"]),
                            data: response
                        });
                    })
                    .then((response) => {
                        dispatch({
                            type: "NOTIFICATION_CREATE",
                            message: __("The speaker was removed from the shortlist")
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Leaves a review on a speaker attached to a shortlist
     * @param params
     * @returns {Function}
     */
    shareeventshortlist: function(params) {
        let key = ["MPShortlist_Share", _.get(params, ["id"])];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/mpshortlist/emailshortlist/" + _.get(params, ["id"]), params)
                    .then((response) => {
                        dispatch({
                            type: "NOTIFICATION_CREATE",
                            message: __("An email was sent to") + " " + _.join(_.get(response, ["data", "sent_to_emails"]), ", ")
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Leaves a review on a speaker attached to a shortlist
     * @param params
     * @returns {Function}
     */
    review: function(params) {
        let key = ["MPShortlist_Review", _.get(params, ["id"])];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/mpshortlist/review/" + _.get(params, ["id"]) + "/save", params)
                    .then((response) => {
                        dispatch({
                            type: "NOTIFICATION_CREATE",
                            message: __("Thank you for your review.  We will send it to the collaborator.")
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Pauses a shortlist
     * @param params
     * @returns {Function}
     */
    resume: function(params) {
        let key = ["MPShortlist_Resume", _.get(params, ["eid"])];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .put(key, "/marketplace/mp-shortlist-resume/" + _.get(params, ["eid"]), params)
                    .then((response) => {
                        dispatch({
                            type: "SHORTLIST_UPDATE_RESUME_EVENT",
                            eid: _.get(params, ["eid"]),
                            data: _.get(response, ["data"])
                        });
                        return response;
                    }).then((response) => {
                        dispatch({
                            type: "NOTIFICATION_CREATE",
                            message: __("You have resumed your event.")
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Pauses a shortlist
     * @param params
     * @returns {Function}
     */
    pause: function(params) {
        let key = ["MPShortlist_Pause", _.get(params, ["eid"])];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .put(key, "/marketplace/mp-shortlist-pause/" + _.get(params, ["eid"]), params)
                    .then((response) => {
                        dispatch({
                            type: "SHORTLIST_UPDATE_PAUSE_EVENT",
                            eid: _.get(params, ["eid"]),
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
                    .then((response) => {
                        dispatch({
                            type: "NOTIFICATION_CREATE",
                            message: __("Your event has been paused and no speakers will see it until it is resumed.")
                        });
                        return response;
                    })
                    .catch((err) => {
                        if (err.message !== "Already paused") { // even if the event is already paused, update the state
                            dispatch({
                                type: "ERROR_CREATE",
                                source: _.join(key, "_"),
                                error: err
                            });
                        }
                        return err;
                    })
            );
        };
    },

    /**
     * Retrieves a single record.
     * @param params
     * @returns {Function}
     */
    get: function(id) {
        let key = ["MPShortlist", id];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/marketplace/mp-shortlist/" + id)
                    .then((response) => {
                        return _.get(response, ["data"]);
                    })
                    .then((data) => {
                        dispatch({
                            type: "SHORTLIST_LOAD",
                            data: {
                                [id]: data
                            }
                        });
                        return data;
                    })
            );
        };
    },

    /**
     * Retrieves a single record.
     * @param params
     * @returns {Function}
     */
    findShared: function(id) {
        let key = ["MPShortlist_Public", id];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/mpshortlist/readpublic/" + id)
                    .then((response) => {
                        return _.get(response, ["data"]);
                    })
                    .then((data) => {
                        dispatch({
                            type: "SHORTLIST_SHARED_LOAD",
                            data: data,
                            id: id
                        });
                        return data;
                    })
            );
        };
    },


});