import _ from "lodash";
import React from "react";
import Promise from "es6-promise";
import { connect } from "react-redux";
import { Prompt, Redirect, withRouter } from "react-router";
import { Field, Form, formValueSelector, reduxForm, SubmissionError, submit } from "redux-form";
import { gettext as __ } from "../../../../utils/Trans";
import CookiePostAJob from "../../../../utils/CookiePostAJob";
import { DateRangePickerField, Message, Select, Selectize, Text, Toggle } from "../../../../components/FormComponents";
import PostAJob_SavePendingJob from "../../../../actions/PostAJobPage/PostAJob_SavePendingJob";
import Analytics from "../../../../utils/Analytics";
import moment from "moment/moment";
import StepNumber from "../../../../components/StepNumber";
import Constants from "../../../../utils/Constants";
import JobBoardModal from "../../modals/JobBoardModal";
import JobPublicDetailsModal from "../../modals/JobPublicDetailsModal";

require("./index.css");

class PostNewJobForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            min_date: moment().subtract(1, "d").format("MM/DD/Y"),
            is_ready_to_submit: false,
            show_job_board_modal: false,
            show_confirmation_modal: false,
            show_job_details_modal: false
        };
    }

    handleSubmit = (values, dispatch) => {
        Analytics.sendEvent("Private Job", "submit", 0, "");
        return new Promise((resolve) => {
            let params = _.mapValues(values, function (item) {
                return _.get(item, ["value"], item);
            });
            resolve(dispatch(PostAJob_SavePendingJob(params)));
        }).then((response) => {
            this.setState({
                is_ready_to_submit: true
            });
            return response;
        }).catch((err) => {
            throw new SubmissionError({_error: err});
        });
    }

    __onToggleJobBoardModal = () => {
        this.setState({
          show_job_details_modal: false,
          show_confirmation_modal: false,
          show_job_board_modal: !this.state.show_job_board_modal
        });
    }

    __onToggleJobDetailsModal = () => {
        this.setState({
          show_job_board_modal: false,
          show_confirmation_modal: false,
          show_job_details_modal: !this.state.show_job_details_modal
        });
    }

    __onToggleConfirmationModal = () => {
        return this.props.onSubmit();
    }

    render() {
        let {
            handleSubmit,
            submitSucceeded,
            submitting,
            error,
            dirty,
            topics,
            countrylist,
            statelist,
            deliverymethods,
            presenter_types,
            budget_ranges,
            is_onsite,
            is_virtual,
            stepnumberstart = 2,
            hide_paj_modal
        } = this.props;

        return (
            <Form className="MP-PostAPublicJobForm mp-style-form" onSubmit={handleSubmit(this.handleSubmit)}>
                <Prompt
                    when={!this.state.is_ready_to_submit && dirty}
                    message={location =>
                        __("Are you sure you want to leave before posting your job?")
                    }
                />
                <Field
                    name="name"
                    className="form-control"
                    label={__("Speaking To")}
                    placeholder={__("Annual Training Conference")}
                    component={Text}
                />

                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="startdate"
                            className="form-control"
                            label={__("Event Date")}
                            placeholder="mm/dd/yyyy"
                            component={DateRangePickerField}
                            minDate={this.state.min_date}
                            type="text"
                            ranges={false}
                            opens="center"
                            singleDatePicker={true}
                            autoApply={true}
                            autoUpdateInput={true}
                            showCustomRangeLabel={false}
                            style={{width: "100%"}}
                        />
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <div style={{marginTop: "2.5em"}} className="hidden-xs" />
                        <Field
                            name="date_tentative"
                            className="form-control text-muted"
                            label={__("My date is tentative")}
                            component={Toggle}
                        />
                    </div>
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="budget"
                            className="form-control"
                            label={__("Budget")}
                            component={Selectize}
                            placeholder={__("Select your budget...")}
                            options={_.map(budget_ranges, function (item, i) {
                                return {label: _.get(item, ["label"]), value: ("range_" + i)};
                            })}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-4 col-xs-12">
                        <Field
                            name="delivery"
                            className="form-control"
                            label={__("Location")}
                            component={Selectize}
                            placeholder={__("Select type of event...")}
                            options={_.map(deliverymethods, function (item) {
                                return {label: _.get(item, ["description"]), value: _.get(item, ["id"])};
                            })}
                        />
                    </div>
                </div>
                {is_virtual &&
                <div className="row">
                    <div className="col-sm-12">
                        <Field
                            name="zoomUrl"
                            className="form-control"
                            label={__("Meeting URL")}
                            component={Message}
                            rows={3}
                        />
                    </div>
                </div>}
                {is_onsite &&
                <div className="row">
                    <div className="col-sm-12 col-md-10">
                        <div className="row">
                            <div className="col-xs-6 col-sm-4">
                                <Field
                                    name="city"
                                    className="form-control"
                                    label={__("City")}
                                    component={Text}
                                />
                            </div>
                            <div className="col-xs-6 col-sm-4">
                                {_.size(statelist) > 0 ?
                                    <Field
                                        name="state"
                                        className="form-control"
                                        label={__("State")}
                                        component={Selectize}
                                        options={_.map(statelist, function (item, i) {
                                            return {label: _.startCase(item), value: i};
                                        })}
                                    />
                                    :
                                    <Field
                                        name="state"
                                        className="form-control"
                                        label={__("State")}
                                        component={Text}
                                    />
                                }
                            </div>
                            <div className="col-xs-12 col-sm-4">
                                <Field
                                    name="country"
                                    className="form-control"
                                    label={__("Country")}
                                    component={Select}
                                >
                                    <optgroup label="Common Countries">
                                        {_.map(_.pickBy(countrylist, function (item) {
                                            return (
                                                item === "United States" ||
                                                item === "Canada" ||
                                                item === "Mexico"
                                            );
                                        }), function (item, i) {
                                            return (
                                                <option key={i} value={i}>{item}</option>
                                            );
                                        })}
                                    </optgroup>
                                    <optgroup label="All Countries">
                                        {_.map(_.pickBy(countrylist, function (item) {
                                            return (
                                                item !== "United States" &&
                                                item !== "Canada" &&
                                                item !== "Mexico"
                                            );
                                        }), function (item, i) {
                                            return (
                                                <option key={i} value={i}>{item}</option>
                                            );
                                        })}
                                    </optgroup>
                                </Field>
                            </div>
                        </div>
                    </div>
                </div>
                }


                <StepNumber n={stepnumberstart++}>
                    <h3 className="text-success text-left">
                        {__("Your meeting goals")}
                    </h3>
                </StepNumber>
                <div className="row">
                    <div className="col-sm-6">
                        <Field
                            name="presenter_type"
                            className="form-control"
                            label={__("Type of presentation")}
                            component={Selectize}
                            placeholder={__("Select a type of presentation...")}
                            options={_.map(presenter_types, function (item) {
                                return {label: _.get(item, ["description"]), value: _.get(item, ["id"])};
                            })}
                        />
                    </div>
                    <div className="col-sm-6">
                        <Field
                            name="topic"
                            className="form-control"
                            label={__("Speaking On")}
                            component={Selectize}
                            placeholder={__("Choose a topic...")}
                            options={_.map(topics, function (item) {
                                return {label: _.get(item, ["name"]), value: _.get(item, ["id"])};
                            })}
                        />
                    </div>
                </div>

                <Field
                    name="meeting_goals"
                    className="form-control"
                    label={__("Goals")}
                    placeholder={__("What should the speaker's presentation accomplish for your audience?")}
                    rows={3}
                    component={Message}
                />

                <StepNumber n={stepnumberstart++}>
                    <h3 className="text-success text-left">
                        {__("Invitation message from you")}
                    </h3>
                </StepNumber>


                <Field
                    name="message"
                    className="form-control"
                    rows={10}
                    component={Message}
                />
                <div className="form-group text-center">
                    <button type="button" className="btn btn-success btn-lg" disabled={submitting} onClick={hide_paj_modal ? this.__onToggleConfirmationModal : this.__onToggleJobBoardModal}>
                        {submitting ? <i className="fa fa-spin fa-circle-o-notch"/> : <i/>} {__("SEND FREE INQUIRY")}
                    </button>
                    {submitSucceeded && <span className="text-success"> <i className="fa fa-check"/> {__("Everything checks out!  One moment while we post your job and notify the speakers...")}</span>}
                    {this.state.is_ready_to_submit && submitSucceeded && <Redirect to={"/postajob/?onboard=postajob"}/>}
                    {error && <span className="text-danger"> <i className="fa fa-times"/> {_.get(error, ["message"])}</span>}
                </div>
                {this.state.show_job_board_modal && <JobBoardModal onShowJobDetailsModal={this.__onToggleJobDetailsModal} onShowJobConfirmModal={this.__onToggleConfirmationModal} onHide={this.__onToggleJobBoardModal} />}
                {this.state.show_job_details_modal && <JobPublicDetailsModal topics={topics} budget_ranges={budget_ranges} presenter_types={presenter_types} onShowJobBoardModal={this.__onToggleJobBoardModal} onShowJobConfirmModal={this.__onToggleConfirmationModal} onHide={this.__onToggleJobBoardModal} />}
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {

    // Pull out the pending job from the localStorage or Cookie (private browsing)
    let pending_job = CookiePostAJob.get();

    // Selectors to filter the form
    let selector = formValueSelector("postaprivatejob");
    let selected_country = selector(state, "country"); // used to filter the states
    let selected_delivery_method = selector(state, "delivery"); // used to show/hide the location
    // Page Properties
    let hide_paj_modal = (_.get(ownProps.whitelabel, ["mp_keyvalues", "MP_DISABLE_PUBLIC_JOB_BOARD"], false) || _.get(ownProps.whitelabel, ["mp_keyvalues", "MP_PAJ_HIDE_PUBLIC_JOB_BOARD"], false));
    let topics = _.get(ownProps.sitelists, ["topics"]);
    let deliverymethods = _.get(ownProps.sitelists, ["universal", "deliverymethods"]);
    let presenter_types = _.get(ownProps.sitelists, ["universal", "presenter_types"]);
    let budget_ranges = _.get(ownProps.sitelists, ["feeranges"]);

    //get the longest name for each country
    let countrylist = _.mapValues(_.groupBy(_.get(ownProps.sitelists, ["universal", "countrylist"], []), "sc"), function (list) {
        return _.last(_.sortBy(_.map(list, "lc"), _.size));
    });

    let statelist = _.mapValues(_.groupBy(_.pickBy(_.get(ownProps.sitelists, ["universal", "statelist"], []), function (item) {
        return item.sc === selected_country;
    }), "ss"), function (list) {
        return _.last(_.sortBy(_.map(list, "ls"), _.size));
    });

    return {
        is_loading: _.isUndefined(countrylist) || _.isUndefined(statelist) || _.isUndefined(deliverymethods) || _.isUndefined(presenter_types) || _.isUndefined(topics) || _.isUndefined(budget_ranges),
        hide_paj_modal: hide_paj_modal,
        topics: topics,
        countrylist: countrylist,
        statelist: statelist,
        deliverymethods: deliverymethods,
        presenter_types: presenter_types,
        budget_ranges: budget_ranges,
        is_onsite: (selected_delivery_method === 1), // on-site
        is_virtual: (selected_delivery_method === 2), // virtual
        initialValues: {
            bureau_id: _.get(ownProps.whitelabel, ["bid"], Constants.TRUNK_BUREAU_ID),
            shortlist_sids: ownProps.shortlist_sids,
            eid: -1,
            name: _.get(pending_job, ["name"], null),
            startdate: _.get(pending_job, ["startdate"], ""),
            date_tentative: false, // TODO get from event object
            delivery: _.get(pending_job, ["delivery"], 1),
            city: _.get(pending_job, ["city"]),
            state: _.get(pending_job, ["state"]),
            country: _.get(pending_job, ["country"], "US"),
            budget: _.get(pending_job, ["budget"]),
            topic: _.get(pending_job, ["topic"]),
            language: _.get(pending_job, ["language"], "en"),
            presenter_type: _.get(pending_job, ["presenter_type"], 1),
            meeting_goals: _.get(pending_job, ["meeting_goals"], ""),
            zoomUrl: _.get(pending_job, ["zoomUrl"], ""),
            message: _.get(pending_job, ["message"], __("Hello,") + "\n\n" + __("I'd like to invite you to apply for my speaking engagement.  Please review these details and let me know if you are available and would be a good match for our needs.")),
            post_public: !_.get(ownProps.whitelabel, ["mp_keyvalues", "MP_PAJ_HIDE_PUBLIC_JOB_BOARD"], false) // if we are hiding the job board, don't post to it by default
        }
    };
};

var mapDispatchToProps = function (dispatch, ownProps) {
  return {
    onSubmit: function() {
      return dispatch(submit("postaprivatejob"))
    }
  };
};

PostNewJobForm = reduxForm({
    form: "postaprivatejob",
    validate: function (values) {
        const errors = {};
        if (!values.name) {
            errors.name = __("Please enter a name for your event.");
        }
        if (!values.meeting_goals) {
            errors.meeting_goals = __("Please enter your Meeting Goals");
        }
        if (!values.message) {
            errors.message = __("Please enter a message to send to the speakers");
        }
        if (!values.startdate) {
            errors.startdate = __("Please enter a date for your event.");
        }
        if (!moment(values.startdate, "MM/DD/YYYY").isValid()) {
            errors.startdate = __("Please enter a date for your event.");
        }
        if (!values.budget) {
            errors.budget = __("Please choose your budget.");
        }
        if (_.isNull(values.delivery)) {
            errors.delivery = __("Please choose a delivery method.");
        }
        if (values.delivery === "1") {
            if (!values.city) {
                errors.city = __("Please enter a city.");
            }
            if (!values.country) {
                errors.country = __("Please enter a country.");
            }
        }

        return errors;
    }
})(PostNewJobForm);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostNewJobForm));
