import _ from "lodash";
import phpEncode from "urlencode-for-php";
import Oauth from "./Oauth";
import Balboa from "../../Global/Balboa";
import {sessionService} from "redux-react-session";
import Constants from "../../../utils/Constants";

const client_id_dev = "EhBtx24wGhPQjGYU";
const client_id = "EhBtx24wGhPQjGYU";
const secret = "fPkmjGVgbLMqjMFRNp2mtAe9gBbVuuqG";
const MPI_AUTH_URL = "https://auth.mpi.org";
const MPI_AUTH_URL_DEV = "https://authtest.mpi.org";
export default _.assign({}, Oauth, {

    signin: function(code, state, callback_url) {
        let self = this;
        return (dispatch, getState) => {
            let key = ["Mpi_Oauth"];
            return self.exchange(code, callback_url)
                .then((access_token) => {
                    return self.profile(access_token);
                })
                .then((profile) => {
                    let params = {
                        service: "mpi",
                        access_token: _.get(profile, ["access_token"]),
                        state: state,
                        id: _.get(profile, ["person_id"]),
                        first_name: _.get(profile, ["given_name"]),
                        last_name: _.get(profile, ["family_name"]),
                        email: _.get(profile, ["email"]),
                        gender: _.get(profile, ["gender"], ""),
                        photo_url: _.get(profile, ["picture"], ""),
                        profile_url: _.get(profile, ["link"], ""),
                        auth_only_user_type: 'buyer'
                    };

                    return Balboa(dispatch, getState)
                        .post(key, "/oauth2/signinoauth", params, {cache_ttl: -1})
                        .then(function(response) {
                            dispatch({
                                type: "BALBOA3_CLEAR_KEY",
                                key: key
                            });
                            return response;
                        })
                        .then(function(response) {
                            sessionService.saveSession(_.get(response, ["data", "token"]));
                            sessionService.saveUser(_.assign({}, _.get(response, ["data", "user"]), {token: _.get(response, ["data", "token"])}));
                            // return response;
                        });
                });
        };
    },

    /**
     * Convert the initiate token into an authorization token
     * @param params
     * @returns {string}
     */
    initiate: function(redirect_url, whitelabel) {
        let params = {
            response_type: "code",
            client_id: client_id,
            redirect_uri: this.getCallbackUrl("mpi", whitelabel),
            state: this.getOauthState(_.get(whitelabel, ["bid"], Constants.TRUNK_BUREAU_ID), redirect_url),
            scope: "openid profile email",
            noonce: ("es" + new Date().getTime() + _.random(10000, 99999))
        };
        if (process.env.NODE_ENV === "production") {
            return MPI_AUTH_URL + "/connect/authorize?" + phpEncode(params);
        } else {
            _.set(params, ["client_id"], client_id_dev);
            return MPI_AUTH_URL_DEV + "/connect/authorize?" + phpEncode(params);
        }
    },

    /**
     * Convert the initiate token into an authorization token
     * @param callback_url
     * @param code
     * @returns {Promise}
     */
    exchange: function(access_token, callback_url) {
        let default_params = {
            client_id: client_id,
            client_secret: secret,
            redirect_uri: callback_url,
            code: access_token,
            grant_type: "authorization_code"
        };

        let url = MPI_AUTH_URL + "/connect/token";
        if (process.env.NODE_ENV !== "production") {
            url = MPI_AUTH_URL_DEV + "/connect/token";
        }

        return fetch(
            url,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: phpEncode(default_params),
            })
            .then((resp) => {
                return resp.json();
            })
            .then((resp) => {
                if (_.has(resp, ["error"])) {
                    throw new Error(_.get(resp, ["error"], "Invalid response from Mpi.Oauth"));
                }
                if (!_.has(resp, ["access_token"])) {
                    throw new Error("Invalid token from Mpi.Oauth");
                }
                return _.get(resp, ["access_token"]);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    },

    /**
     * Shortcut to either add or update a record based on whether the id is > 0
     * @param params
     * @returns {Function}
     */
    profile: function(access_token) {
        let url = MPI_AUTH_URL;
        if (process.env.NODE_ENV !== "production") {
            url = MPI_AUTH_URL_DEV;
        }

        return fetch(
            url + "/connect/userinfo?access_token=" + access_token,
            {
                method: "GET",
                headers: {
                    // "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer " + access_token
                }
            })
            .then((resp) => {
                return resp.json();
            })
            .then((json) => {
                return _.assign({}, json, {access_token: access_token});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }
});