import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { gettext as __ } from "../../../../utils/Trans";
import { Message } from "../../../../components/FormComponents";
import LoadingSubmit from "../../../../components/LoadingSubmit";
import InlineError from "../../../../components/InlineError";
import cancelOffer from "../../../../actions/ShortList/cancelOffer";

class CancelAgreementForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch, props) {
        return dispatch(cancelOffer(values))
            .then(() => {
                if (_.isFunction(props.onClose)) {
                    props.onClose();
                }
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
            handleSubmit,
            submitting,
            error,
            onClose
        } = this.props;

        return (
            <Form role="form" onSubmit={handleSubmit(this.handleSubmit)}>
                <div className="modal-body">
                    {error && <InlineError error={_.get(error, ["message"])} />}
                    <div className="row">
                        <div className="col-sm-12">
                            <p>
                                {__("This offer has already been accepted by the speaker, and they were planning to perform it.")}
                            </p>
                            <p>
                                {__("If you cancel 60 days or more from the start of the engagement, your deposit will not be refunded, but you will not need to complete payment of the balance or any travel or material fees. If you cancel 60 days or less from the start of the engagement, you are responsible to complete payment of all fees.")}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <Field
                                name="message"
                                type="textarea"
                                className="form-control"
                                placeholder={__("I am canceling this agreement due to ...")}
                                component={Message}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <LoadingSubmit is_saving={submitting} save_text={__("Cancelling...")} className="btn-danger btn-block-xs">
                        {__("Cancel Agreement")}
                    </LoadingSubmit>
                    {" "}
                    <div className="btn btn-default btn-block-xs" disabled={submitting} onClick={onClose}>
                        {__("Never Mind")}
                    </div>
                </div>
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

CancelAgreementForm.propTypes = {
    event: PropTypes.object.isRequired,
    speaker: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

function mapStateToProps(state, ownProps) {
    return {
        initialValues: {
            message: "",
            event_id: _.get(ownProps.event, ["eid"]),
            speaker_id: _.get(ownProps.speaker, ["sid"])
        }
    };
}

function mapDispatchToProps() {
    return {

    };
}

CancelAgreementForm = reduxForm({
    form: "shortlist_page_cancel_agreement",
    validate: function(values) {
        const errors = {};
        if (!values.message) {
            errors.message = __("Please enter a message about why you are cancelling this agreement.");
        }
        return errors;
    }
})(CancelAgreementForm);

export default connect(mapStateToProps, mapDispatchToProps)(CancelAgreementForm);