import _ from "lodash";
import React from "react";

import "./PaymentDeclinedSprite.scss";

class PaymentDeclinedSprite extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            frame: 0
        };
        this.MAX_FRAMES = 87;
    }

    componentDidMount() {
        this.interval = window.setTimeout(this.drawSprite, 20);
    }

    componentWillUnmount() {
        if (this.interval) {
            window.clearTimeout(this.interval);
        }
    }

    drawSprite = () => {
        if (this.state.frame < this.MAX_FRAMES) {
            let new_frame = this.state.frame + 1;
            this.setState({frame: new_frame});
            this.interval = window.setTimeout(this.drawSprite, 20);
        } else {
            if (this.interval) {
                window.clearTimeout(this.interval);
            }
            if (_.isFunction(this.props.onComplete)) {
                return this.props.onComplete();
            }
        }
    }

    render() {
        let class_frame = "payment-declined-frame payment-declined-frame-" + this.state.frame;

        return (
            <div>
                <div>
                    { this.state.frame === this.MAX_FRAMES &&
                        <div className="text text-danger payment-declined-message">{this.props.error}</div>
                    }
                    <div className={class_frame}></div>
                </div>
            </div>
        );
    }
}

export default PaymentDeclinedSprite;