import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger } from "react-bootstrap";
import PopoverMenu from "../../PopoverMenu";
import { Range } from "rc-slider";

import "rc-slider/assets/index.css";

export default class SliderDropdown extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            label_value: props.placeholder,
            options: props.options,
            marks: props.marks,
            value: props.input.value,
            min: props.min || 0,
            max: props.max || 100
        };
    }

    componentDidMount() {
        this.updateValue(false);
    }

    componentDidUpdate(prevProps) {
        if (this.props.input.value !== prevProps.input.value) {
            let self = this;
            let new_value = this.props.input.value;
            if (_.isString(new_value)) {
                new_value = _.map(_.split(new_value, ","), function (val) {
                    return _.toNumber(val);
                });
            }
            if (_.isArray(new_value) && _.size(new_value) === 1) {
                let min = _.head(new_value);
                new_value = [_.toNumber(min), _.toNumber(min)];
            }
            if (!_.isArray(new_value) || _.size(new_value) !== 2) {
                new_value = [this.state.min, this.state.max];
            }
            this.setState({
                value: new_value
            }, () => {
                self.updateValue(false);
            });
        }
    }

    /**
     * Resets the selected property on all the fields.  Used by the clear all option
     */
    onClearOptions = () => {
        return this.setState({
            value: [this.state.min, this.state.max]
        }, () => {
            return this.updateValue(true);
        });
    }

    /**
     * Pass onChange up to the input
     */
    onChange = (value) => {
        if (_.isFunction(this.props.input.onChange)) {
            return this.props.input.onChange(_.join(value, ","));
        }
    }

    /**
     * Whenever a checkbox is checked, go here to check/uncheck it in the options
     */
    onSlide = (value) => {
        this.setState({
            value: value
        }, () => {
            return this.updateValue(false);
        });
    }

    /**
     * Whenever a checkbox is checked, go here to check/uncheck it in the options
     */
    onAfterSlide = () => {
        return this.updateValue(true);
    }

    /**
     * Updates the internal selected state, the display label, and the saved value
     */
    updateValue = (triggerChange) => {
        let label_value = this.state.label_value;
        let min = this.state.min;
        let max = this.state.max;
        if (_.isArray(this.state.value)) {
            min = _.head(this.state.value);
            max = _.last(this.state.value);
        }

        if (min === this.state.min && max === this.state.max) {
            label_value = this.props.placeholder;
        } else if (_.has(this.state.marks, [min]) && _.has(this.state.marks, [max])) {
            label_value = (_.get(this.state.marks, [min]) + " to " + _.get(this.state.marks, [max]));
        } else if (!_.has(this.state.marks, [min]) && _.has(this.state.marks, [max])) {
            label_value = _.get(this.state.marks, [max]);
        } else if (_.has(this.state.marks, [min]) && !_.has(this.state.marks, [max])) {
            label_value = _.get(this.state.marks, [min]);
        }

        this.setState({
            label_value,
        }, () => {
            if (triggerChange) {
                this.onChange(this.state.value);
            }
        });
    }

    render() {
        let {
            label,
            className = "",
            any_placeholder,
            trigger = "click",
            placement = "bottom",
            hide_errors,
            min = 0,
            max = 100,
            step = 1,
            dots = false,
            marks = {},
            meta: {
                touched,
                error,
                warning
            }
        } = this.props;

        let onClearOptions = this.onClearOptions;

        let overlay = <PopoverMenu>
            <div style={{padding: "10px"}}>
                {any_placeholder &&
                    <div className={className}>
                        <label> <input type="checkbox" value={0} checked={(_.get(this.state.value, [0], this.state.min) === this.state.min && _.get(this.state.value, [1], this.state.max) === this.state.max)} onChange={onClearOptions} />{" "}{any_placeholder}</label>
                        <hr style={{margin: "2px"}} />
                    </div>
                }
                <div style={{padding: "10px"}}>
                    <Range
                        value={this.state.value}
                        onChange={this.onSlide}
                        onAfterChange={this.onAfterSlide}
                        dots={dots}
                        step={step}
                        min={min}
                        max={max}
                        vertical={true}
                        style={{height:"350px"}}
                        allowCross={false}
                        pushable={true}
                        marks={marks}
                    />
                </div>
            </div>
        </PopoverMenu>;

        return (
            <div>
                <div className={className + " " + (touched && error ? "has-error " : touched && warning ? "has-warning " : "")}>
                    <label>
                        {label}
                        <OverlayTrigger rootClose={true} trigger={trigger} placement={placement} overlay={overlay}>
                            <div style={{borderBottom: "2px solid #C0C0C0"}}>{this.state.label_value}{" "}<i className="fa fa-caret-down" /></div>
                        </OverlayTrigger>
                    </label>
                    {!hide_errors && touched &&
                    ((error && <div className="text-danger"><i className="fa fa-times"/> {error}</div>) ||
                        (warning && <div className="text-danger"><i className="fa fa-exclamation-triangle"/> {warning}</div>))}
                </div>
            </div>
        );
    }
}

SliderDropdown.propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    hide_errors: PropTypes.bool,
    options: PropTypes.object,
    any_placeholder: PropTypes.string
};