import _ from "lodash";
import Rest from "../Global/Rest";
import Balboa from "../Global/Balboa";

export default _.assign({}, Rest, {

    /**
     * Retrieves a list of records.  Pass in search parameters in {params}
     * @param params
     * @returns {Function}
     */
    markAsRead: function(ids) {
        let key = ["markAsReadMPMessage", _.join(ids, ",")];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/mpmessage/ireadit", {
                        ids: ids
                    })
                    .then(function (response) {
                        dispatch({
                            type: "MESSAGE_MARK_AS_READ",
                            ids: ids
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Retrieves a list of records.  Pass in search parameters in {params}
     * @param params
     * @returns {Function}
     */
    findByShortlistAndSpeaker: function(shortlist, sid) {
        return this.find({
            mp_shortlist_id: shortlist,
            speaker_id: sid,
            items_per_page: 0
        });
    },

    /**
     * Retrieves a list of records.  Pass in search parameters in {params}
     * @param params
     * @returns {Function}
     */
    findByEventAndSpeaker: function(eid, sid) {
        return this.find({
            event_id: eid,
            speaker_id: sid,
            items_per_page: 0
        });
    },

    /**
     * Retrieves a list of records.  Pass in search parameters in {params}
     * @param params
     * @returns {Function}
     */
    findByEvent: function(eid) {
        return this.find({
            event_id: eid,
            items_per_page: 0
        });
    },

    /**
     * Retrieves a list of records.  Pass in search parameters in {params}
     * @param params
     * @returns {Function}
     */
    find: function(params) {
        let key = ["getMPMessages", _.get(params, ["event_id"], 0), _.get(params, ["speaker_id"], 0)];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/marketplace/mp-message", params)
                    .then(function (response) {
                        dispatch({
                            type: "MESSAGE_LOAD",
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Retrieves a single record.
     * @param params
     * @returns {Function}
     */
    get: function(id) {
        let key = ["getMPMessages", id];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/marketplace/mp-message/" + id)
                    .then(function (response) {
                        dispatch({
                            type: "MESSAGE",
                            id: _.get(response, ["data", "id"]),
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Inserts a record.  Leave {params.id} 0, otherwise it will be updated
     * @param params
     * @returns {Function}
     */
    add: function(params) {
        let key = ["addMPMessage"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/marketplace/mp-message", params)
                    .then(function (response) {
                        dispatch({
                            type: "MESSAGES_CREATE",
                            eid: params.event_id,
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Updates a record.  Pass in {params.id} to specify the record to update, otherwise it will be added
     * @param params
     * @returns {Function}
     */
    update: function(params) {
        let key = ["updateMPMessage", _.get(params, ["id"])];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .put(key, "/marketplace/mp-message/" + _.get(params, ["id"]), params)
                    .then(function (response) {
                        dispatch({
                            type: "MESSAGE_CREATE",
                            eid: params.event_id,
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Removes a record.  Pass in {params.id} to specify the record to remove
     * @param params
     * @returns {Function}
     */
    remove: function(params) {
        let key = ["removeMPMessage", _.get(params, ["id"])];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .del(key, "/marketplace/mp-message/" + _.get(params, ["id"]), {
                        id: [_.get(params, ["id"])]
                    })
                    .then(function (response) {
                        dispatch({
                            type: "MESSAGE_DELETE",
                            id: _.get(params, ["id"]),
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    }

});