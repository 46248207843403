import React from "react";

const Reason = ({input, label, hide_errors, type, meta: { touched, error, warning }}) => (
    <div>
        <div className={(touched && error ? "has-error " : touched && warning ? "has-warning " : "")}>
            <label>
                <input {...input} type={type} />
                {" "}
                {label}
            </label>
            {!hide_errors && touched &&
            ((error && <div className="text-danger"><i className="fa fa-times" /> {error}</div>) ||
            (warning && <div className="text-danger"><i className="fa fa-exclamation-triangle" /> {warning}</div>))}
        </div>
    </div>
);

export default Reason;