import _ from "lodash";
import React from "react";

class WhitelabelAddress extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            whitelabel
        } = this.props;

        return (
            <div>
                <h5>{_.get(whitelabel, ["name"])}</h5>
                <div className="text-muted">
                    {_.get(whitelabel, ["address"]) && <div>{_.get(whitelabel, ["address"])}</div>}
                    {_.get(whitelabel, ["address2"]) && <div>{_.get(whitelabel, ["address2"])}</div>}
                    {_.get(whitelabel, ["city"]) && _.get(whitelabel, ["state"]) && _.get(whitelabel, ["zip"]) &&
                        <div>{_.get(whitelabel, ["city"])}, {_.get(whitelabel, ["state"])} {_.get(whitelabel, ["zip"])}</div>
                    }
                    {_.get(whitelabel, ["phone"]) && <div>{_.get(whitelabel, ["phone"])}</div>}
                    {_.get(whitelabel, ["contact_us_url"]) && <div>{_.get(whitelabel, ["contact_us_url"])}</div>}
                </div>
            </div>
        );
    }

}

export default WhitelabelAddress;