import _ from "lodash";

export default function (num, num_decimals, usek = false) {
  num = _.isString(num) ? parseFloat(num.replace(/[^\d\\.]/g, "")) : num;
  if (!_.isNumber(num) || _.isNaN(num)) {
    return (0).toFixed(num_decimals);
  }
  if (!num_decimals || _.isNaN(num_decimals)) {
    num_decimals = 0;
  }

  if (usek) {
    let r = Math.floor(num / 1000) ;

    if (0 === r) {
      //number was < 1,000
      r = num.toLocaleString(undefined, {
        minimumFractionDigits: num_decimals,
        maximumFractionDigits: num_decimals
      });
    }

    if (num > 999) {
      r = r + "k";
    }
    return r;

  } else {

    return num.toLocaleString(undefined, {
      minimumFractionDigits: num_decimals,
      maximumFractionDigits: num_decimals
    }); //num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
}
