import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { MenuItem, NavDropdown } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import toManageURL from "../../../../../utils/toManageURL";
import { gettext as __ } from "../../../../../utils/Trans";
import UnreadBadge from "../../../../../components/UnreadBadge";

export default class AuthControl extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            user,
            whitelabel,
            n_unread_messages,
            onLogout,
            is_admin_or_manage
        } = this.props;

        let is_admin = (_.get(user, ["role"], "guest") === "admin");
        let backstage_user_that_can_manage_this_bid = _.get(user, ["backstage_user_that_can_manage_this_bid"], false);

        return (
            <NavDropdown title={__("Hi, ") + _.get(user, ["firstname"])} id="nav-auth-dropdown" className="mp-text-header">
                {is_admin_or_manage && backstage_user_that_can_manage_this_bid && <MenuItem href="/account-menu-publisher/"><i className="fa fa-user fa-fw text-tertiary" />{" "}{__("My Account")}</MenuItem>}
                {!is_admin_or_manage && <LinkContainer to="/user"><MenuItem><i className="fa fa-user fa-fw text-tertiary" />{" "}{__("My Account")}</MenuItem></LinkContainer>}
                {!is_admin_or_manage &&
                <LinkContainer to="/messages">
                    <MenuItem>
                        {n_unread_messages > 0 &&
                        <span className="pull-right"><UnreadBadge
                            n_unread={n_unread_messages}
                            style={{position: "relative", fontSize: "xx-small"}}
                        />
                        </span>
                        }
                        <i className="fa fa-envelope fa-fw text-tertiary" />{" "}{__("Messages")}
                    </MenuItem>
                </LinkContainer>}
                <MenuItem href="/wiki/Event_organizers_and_meeting_professionals"><i className="fa fa-life-ring fa-fw mp-text-tertiary" />{" "}{__("Support")}</MenuItem>
                {(backstage_user_that_can_manage_this_bid || is_admin) &&
                <MenuItem href={toManageURL("/dashboard", whitelabel)}><i className="fa fa-gear fa-fw mp-text-tertiary" />{" "}{__("Manage")}</MenuItem>
                }
                {(backstage_user_that_can_manage_this_bid || is_admin) &&
                <MenuItem divider/>
                }
                {(is_admin) &&
                <MenuItem href={"/admin/dashboard"}><i className="fa fa-gear fa-fw mp-text-tertiary" />{" "}{__("Admin")}</MenuItem>
                }
                {(is_admin) &&
                <MenuItem divider/>
                }
                <MenuItem onClick={onLogout}><i className="fa fa-sign-out fa-fw text-danger" />{" "}{__("Sign Out")}</MenuItem>
            </NavDropdown>
        );
    }
}

AuthControl.propTypes = {
    user: PropTypes.object.isRequired,
    onLogout: PropTypes.func
};