import _ from "lodash";
import Balboa from "../Global/Balboa";

export default function(eid) {
    return (dispatch, getState) => {
        var key = ["getsharedinvoicelink", eid];
        return Balboa(dispatch, getState)
            .post(key, "/mpauth/getsharedinvoicelink/" + eid, {}, {cache_ttl: -1})
            .then(function (response) {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            })
            .then(function (response) {
                dispatch({
                    type: "BUYER_EVENT_INVOICE_LINK",
                    eid: eid,
                    data: _.get(response, ["data"])
                });
                return response;
            });
    };
}
