import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import formatNumber from "../../../utils/formatNumber";

class CounterV2 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timer: false,
            incrementer: 0
        };
    }

    componentDidMount() {
        this.setState({
            timer: window.setInterval(this.incrementCounter, 50)
        });
    }

    componentWillUnmount() {
        if (this.state.timer) {
            window.clearInterval(this.state.timer);
        }
    }

    incrementCounter = () => {
        this.setState({
            incrementer: this.state.incrementer + 1231
        });
    }

    render() {
        let {
            num,
            className,
            style
        } = this.props;

        let new_style = _.assign({}, {
            fontSize: "36px",
            lineHeight: "40px",
            color: "#80BB3D"
        } , style);

        let digits = this.state.incrementer < _.toInteger(num) ? _.padStart(this.state.incrementer, _.size(_.split(num, "")), "0") : num;

        return (
            <div className={className} style={new_style}>
                {formatNumber(digits)}
            </div>
        );
    }
}

CounterV2.propTypes = {
    num: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    style: PropTypes.object
};

export default CounterV2;