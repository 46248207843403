import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../utils/Trans";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Form, reduxForm } from "redux-form";
import Search from "../../../actions/Search/Search";
import queryString from "query-string";
import LoadMoreOnScrollBottom from "../../../components/LoadMoreOnScrollBottom";
import Constants from "../../../utils/Constants";

class SearchMore extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidUpdate(prevProps) {
        if (JSON.stringify(this.props.qs) !== JSON.stringify(prevProps.qs)) {
            let new_location = _.get(this.props.qs, ["location"], {});
            let old_location = _.get(prevProps.qs, ["location"], {});

            try {
                if (_.isString(old_location)) {
                    // Try parsing the q as a json object
                    old_location = JSON.parse(old_location);
                }
            } catch (e) {
                // nothing to do here
            }
            try {
                if (_.isString(new_location)) {
                    // Try parsing the q as a json object
                    new_location = JSON.parse(new_location);
                }
            } catch (e) {
                // Nothing to do here
            }

            let new_q = _.get(this.props.qs, ["q"], []);
            let old_q = _.get(prevProps.qs, ["q"], []);

            try {
                if (_.isString(old_q)) {
                    // Try parsing the q as a json object
                    old_q = JSON.parse(old_q);
                }
            } catch (e) {
                // nothing to do here
            }
            try {
                if (_.isString(new_q)) {
                    // Try parsing the q as a json object
                    new_q = JSON.parse(new_q);
                }
            } catch (e) {
                // Nothing to do here
            }

            if (!_.isUndefined(_.get(this.props.qs, ["items_per_page"])) && _.get(this.props.qs, ["items_per_page"]) !== _.get(prevProps.qs, ["items_per_page"])) {
                this.props.change("items_per_page", _.toInteger(_.get(this.props.qs, ["items_per_page"], 12)));
            }
            if (!_.isUndefined(_.get(this.props.qs, ["distance"])) && _.get(this.props.qs, ["distance"]) !== _.get(prevProps.qs, ["distance"])) {
                this.props.change("distance", _.toInteger(_.get(this.props.qs, ["distance"], 100)));
            }
            if (!_.isUndefined(_.get(this.props.qs, ["language"])) && _.get(this.props.qs, ["language"]) !== _.get(prevProps.qs, ["language"])) {
                this.props.change("language", _.get(this.props.qs, ["language"]));
            }
            if (!_.isUndefined(_.get(this.props.qs, ["require"])) && _.get(this.props.qs, ["require"]) !== _.get(prevProps.qs, ["require"])) {
                this.props.change("require", _.get(this.props.qs, ["require"]));
            }
            if (!_.isUndefined(_.get(this.props.qs, ["awards"])) && _.get(this.props.qs, ["awards"]) !== _.get(prevProps.qs, ["awards"])) {
                this.props.change("awards", _.get(this.props.qs, ["awards"]));
            }
            if (!_.isUndefined(_.get(this.props.qs, ["budget"])) && _.get(this.props.qs, ["budget"]) !== _.get(prevProps.qs, ["budget"])) {
                this.props.change("budget", _.get(this.props.qs, ["budget"]));
            }
            if (JSON.stringify(old_location) !== JSON.stringify(new_location)) {
                this.props.change("location", new_location);
            }
            if (!_.isUndefined(_.get(this.props.qs, ["available_on"])) && _.get(this.props.qs, ["available_on"]) !== _.get(prevProps.qs, ["available_on"])) {
                this.props.change("available_on", _.get(this.props.qs, ["available_on"]));
            }
            if (JSON.stringify(old_q) !== JSON.stringify(new_q)) {
                this.props.change("q", new_q);
            }
            if (!_.isUndefined(_.get(this.props.qs, ["sort"])) && _.get(this.props.qs, ["sort"]) !== _.get(prevProps.qs, ["sort"])) {
                this.props.change("sort", _.get(this.props.qs, ["sort"]));
            }
            if (!_.isUndefined(_.get(this.props.qs, ["page"])) && _.get(this.props.qs, ["page"]) !== _.get(prevProps.qs, ["page"])) {
                // this.setState({
                //     page: _.toInteger(_.get(prevProps.qs, ["page"], 1))
                // });
                this.props.change("page", (_.toInteger(_.get(this.props.qs, ["page"], 1)) + 1));
            }
        }
    }

    showMore = () => {
        // Page was already incremented in componentWillReceiveProps
        this.props.submit();
    }

    handleSubmit(values, dispatch, props) {
        let params = _.assign({}, values);
        params.q = JSON.stringify(params.q);
        params.location = JSON.stringify(params.location);
        if (_.isArray(params.budget)) {
            params.budget = _.join(params.budget, ",");
        }
        props.history.push(_.get(props.match, ["url"]) + "?" + queryString.stringify(params));
        let impression_speaker_ids = _.get(props, "result_speaker_ids", []).slice(_.get(params, "page", 0) * _.get(params, "items_per_page", 12), (_.get(params, "page", 0) + 1) * _.get(params, "items_per_page", 12));

        if (!_.get(values, "norecord", false)) {
            dispatch(Search.saveProfileImpressions({
              norecord: false,
              bureau_id: _.get(props.whitelabel, ["bid"]),
              speaker_ids: impression_speaker_ids
            }));
        }
        // We preload 15 pages each time, so only retrieve more results if we are at page 15, 30, 45, etc
        if ((params.page * params.items_per_page >= props.num_results) && (props.num_results <= props.total)) {
            return dispatch(Search.loadMore(params));
        } else {
            return dispatch({
                type: "SEARCHPAGE_SAVE_LAST",
                params: params
            });
        }
    }

    render() {
        let {
                handleSubmit,
                submitting,
                is_loading,
                use_infinity_scroll = false
            } = this.props;

        return (
            <div className="text-center">
                <Form onSubmit={handleSubmit(this.handleSubmit)}>
                    {use_infinity_scroll &&
                    <LoadMoreOnScrollBottom buffer={3000} fetch={this.showMore}/>
                    }
                    <div className="btn btn-default btn-lg search-more" onClick={this.showMore} disabled={(submitting || is_loading)}>
                        {(submitting || is_loading)
                            ?
                            <i className="fa fa-spin fa-circle-o-notch"/>
                            :
                            __("Show More")
                        }
                    </div>
                </Form>
            </div>
        );
    }
}

SearchMore.propTypes = {
    is_loading: PropTypes.bool
};

////////////////////////////////////////////////////////////////////////////////
//Connect it up

SearchMore = reduxForm({
    form: "search_more_form",
})(SearchMore);

let mapStateToProps = function (state, ownProps) {
    let q = _.get(ownProps.qs, ["q"], []);
    try {
        if (_.isString(q)) {
            q = JSON.parse(q);
        }
    } catch (e) { /* Nothing to do here */
    }

    let location = _.get(ownProps.qs, ["location"], []);
    try {
        if (_.isString(location)) {
            location = JSON.parse(location);
        }
        if (_.get(location, ["location"], "") === "") {
            // set to empty string, setting to null breaks "search more" button
            location = "";
        }
    } catch (e) { /* Nothing to do here */
    }

    let norecord = _.get(ownProps, 'norecord', _.includes(_.get(ownProps, 'location.search'), Constants.NO_RECORD_HIT_STRING));
    return {
        result_speaker_ids: _.map(_.get(state, ["SearchPage", "results"], []), "sid"), //for saveProfileImpressions
        num_results: _.size(_.get(state, ["SearchPage", "results"], [])),
        total: _.get(state, ["SearchPage", "num_results"], 0),
        initialValues: {
            norecord: norecord,
            items_per_page: _.get(ownProps.qs, ["items_per_page"], 12),
            page: (_.toInteger(_.get(ownProps.qs, ["page"], 0)) + 1),
            bureau_id: _.get(ownProps.whitelabel, ["bid"]),
            language: _.get(ownProps.qs, ["language"], ""),
            require: _.get(ownProps.qs, ["require"], ""),
            awards: _.get(ownProps.qs, ["awards"], ""),
            budget: _.get(ownProps.qs, ["budget"], ""),
            available_on: _.get(ownProps.qs, ["available_on"], ""),
            sort: _.get(ownProps.qs, ["sort"], "sortscore"),
            distance: _.get(ownProps.qs, ["distance"], 100),
            nt: _.get(ownProps.qs, ["nt"], 0),
            location: location,
            q: q
        }
    };
};

let mapDispatchToProps = function (/* dispatch, ownProps */) {
    return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchMore));