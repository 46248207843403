import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SpeakerAvailability from "../../../../components/SpeakerAvailability";
import { gettext as __ } from "../../../../utils/Trans";
import moment from "moment";
import DateRangePicker from "react-bootstrap-daterangepicker";

class SpeakerAvailabilityTable extends React.Component {

    __onShowChangeDates = () => {
        if (_.isFunction(this.props.onShowChangeDates)) {
            return this.props.onShowChangeDates();
        }
    }

    __onChangeShortlist = (sids) => {
        if (_.isFunction(this.props.onChangeShortlist)) {
            return this.props.onChangeShortlist(sids);
        }
    }

    __onChangeDate = (e, picker) => {
        let value = picker.startDate.format("M/D/Y");
        if (!picker.startDate.isSame(picker.endDate)) {
            value = picker.startDate.format("M/D/Y") + " - " + picker.endDate.format("M/D/Y");
        }

        if (_.isFunction(this.props.onChangeDate)) {
            return this.props.onChangeDate(value);
        }
    }

    render() {
        let {
            submitting = false,
            speakers,
            selected_sids,
            shortlist_sids,
            availability,
            date_range
        } = this.props;

        let dates = _.map(_.split(date_range, "-"), (date) => {
            return moment.isMoment(date) ? date : moment(date, "MM/DD/YYYY");
        });
        let startdate = _.head(dates);
        let enddate = _.last(dates);

        return (
            <div>
                <table className="table table-hover mt-4">
                    <thead>
                        <tr>
                            <th colSpan="4">
                                <h4>
                                    <span>
                                        {__("Inquire for")}
                                        {" "}
                                        <DateRangePicker
                                            startDate={startdate}
                                            endDate={enddate}
                                            onApply={this.__onChangeDate}
                                            autoApply={true}
                                        >
                                            <span className="text-success" style={{cursor:"pointer", fontSize: "1.2em", fontWeight: "bold", textDecoration: "underline"}}>
                                                {startdate.isSame(enddate)
                                                    ? startdate.format("MMM D, Y")
                                                    : _.join([startdate.format("MMM D, Y"), enddate.format("MMM D, Y")], " - ")
                                                }
                                            </span>
                                        </DateRangePicker>
                                    </span>

                                    {/*{startdate.isSame(enddate)*/}
                                        {/*? <span>{__("Inquire for %s", startdate.format("M/D/Y"))}{" "}</span>*/}
                                        {/*: <span>{__("Inquire for %s", _.join([startdate.format("M/D/Y"), enddate.format("M/D/Y")], " - "))}{" "}</span>*/}
                                    {/*}*/}
                                    {/*<OverlayTrigger trigger="click" placement="bottom" overlay={*/}
                                        {/*<Popover id="change_date_location_popover">*/}
                                            {/*<DateRangePickerField*/}
                                                {/*type="text"*/}
                                                {/*startDate={startdate}*/}
                                                {/*endDate={enddate}*/}
                                                {/*ranges={false}*/}
                                                {/*onChange={this.__onChangeDate}*/}
                                                {/*dateFormat={"MM/DD/YYYY"}*/}
                                                {/*autoApply={true}*/}
                                            {/*/>*/}
                                        {/*</Popover>*/}
                                    {/*}>*/}
                                        {/*<span className="small" onClick={this.__onShowChangeDates} style={{cursor: "pointer", textDecoration: "underline"}}>({__("change dates")})</span>*/}
                                    {/*</OverlayTrigger>*/}
                                </h4>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                    {_.map(shortlist_sids, (sid, i) => {
                        let speaker_available_uniq = _.uniq(_.map(_.get(availability, [sid]), function (item) {
                            return _.get(item, ["status"]);
                        }));
                        let speaker_available = (_.size(speaker_available_uniq) === 1) ? _.head(speaker_available_uniq) : ((_.size(speaker_available_uniq) === 0) ? 0 : 2); // 2 means mixed results

                        // If the speaker is not selected, then clear it's availability status
                        if (!_.includes(selected_sids, sid)) {
                            speaker_available = 0;
                        }
                        return (
                            <SpeakerAvailability key={sid}
                                                 date_range={date_range}
                                                 speaker={_.get(speakers, [sid])}
                                                 availability={speaker_available}
                                                 calendar={_.get(availability, [sid])}
                                                 is_searching={submitting}
                                                 is_selected={_.includes(selected_sids, sid)}
                                                 // onRemove={this.__onRemove}
                                                 onInquirySwitch={this.__onChangeShortlist}
                                                 // onContactSpeakers={this.__onContactSpeakers}
                            />
                        );
                    })}
                    </tbody>
                </table>
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

SpeakerAvailabilityTable.propTypes = {
    shortlist_sids: PropTypes.array,
    availability: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onChangeShortlist: PropTypes.func,
    onChangeDate: PropTypes.func,
    onShowChangeDates: PropTypes.func,
    date_range: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
}

var mapStateToProps = function (state, ownProps) {

    let availability = _.get(state, ["SpeakerAvailability"]);

    return {
        availability: availability
    };
};

var mapDispatchToProps = function (/* dispatch, ownProps */) {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SpeakerAvailabilityTable);