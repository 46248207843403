import _ from "lodash";
import toInt from "./toInt";

export default function(phone){
    if (!_.isString(phone)) { return ""; }

    phone = phone.replace(/[^0-9]/g, "");
    // US phone numbers cannot have a 1 as the area code
    if (_.isString(phone) && phone.length === 11 && phone.charAt(0) === "1") {
        // This is a US phone number prefixed with a 1
        return phone.replace(/(\d)(\d{3})(\d{3})(\d{4})/, "$1 ($2) $3-$4");
    } else if (_.isString(phone) && phone.length < 11 && toInt(phone.charAt(0)) > 1) {
        // This is a US phone number without the country code
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
    } else {
        // This is an international phone number
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, "+$1$2$3");
    }
}
