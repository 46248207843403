import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

class InlineError extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            children,
            error
        } = this.props;

        if (_.isEmpty(error) && _.isEmpty(children)) {
            return null;
        }

        return (
            <div style={{display:"inline"}}>
                {children}
                {error &&
                    <div className="text-danger"><i className="fa fa-times" />{" "}{error}</div>
                }
            </div>
        );
    }
}

InlineError.propTypes = {
    children: PropTypes.node,
    error: PropTypes.string
};

export default InlineError;
