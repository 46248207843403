import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
// import { Link } from "react-router-dom";
import { gettext as __ } from "../../utils/Trans";
import SpeakerThumbnail from "../../components/SpeakerThumbnail";
import ThumbnailSquare from "../../components/ThumbnailSquare";
import SpeakerName from "../../components/SpeakerName";
import EmbeddedShortlist_remove from "../../actions/ShortList/EmbeddedShortlist_remove";

import "./index.scss";

export class ShortlistSidebar extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            max_boxes: 4
        };
    }

    updateDimensions = _.throttle(function() {
        // Calculate the # of boxes based on whether we are desktop, tablet or phone size
        let e = document.getElementById("sidebar_short_list_wrapper");
        if (e) {
            let max_boxes = _.clamp(Math.floor((e.offsetWidth - 130 - 12) / 60), 2, 100); // 130 = width of shortlist, 12 = width of padding + border of container
            this.setState({max_boxes: max_boxes});
        }
    }, 1000);

    componentDidMount() {
        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions();
    }

    componentWillUnmount() {
        window.removeEventListener("resize", this.updateDimensions);
    }

    render() {
        let {
            sids,
            removeSid
        } = this.props;

        let url = "/postaprivatejob";
        if (_.isEmpty(sids)) {
            return null;
        }

        let containerClasses = ["MP-SidebarShortlist"];

        let max_boxes = _.get(this.state, ["max_boxes"], 4);

        let sids_shown = sids;
        let n_more = null;
        if (_.size(sids_shown) > max_boxes) {
            sids_shown = _.take(sids, max_boxes - 1);
            n_more = <li key={-1} className="MP-SidebarShortlist-item">
                <ThumbnailSquare href={url}>
                    {__("+ %s more", [_.size(sids) - _.size(sids_shown)])}
                </ThumbnailSquare>
            </li>;
        }

        let list = _.map(sids_shown, function (sid) {
            let rm_btn;
            if (removeSid) {
                rm_btn = <span
                    className="link MP-SidebarShortlist-btn-rm text-danger"
                    onClick={() => removeSid(sid)}
                >
                    <span className="fa-stack">
                        <i className="fa fa-circle fa-stack-2x text-danger"></i>
                        <i className="fa fa-times fa-stack-1x"></i>
                    </span>
                </span>;
            }
            return <li key={sid} className="MP-SidebarShortlist-item">
                <SpeakerThumbnail sid={sid} style={{verticalAlign:"middle"}} />
                {rm_btn}
                <div className="visible-lg visible-md"><SpeakerName sid={sid} /></div>
            </li>;
        });


        let list_lg = _.map(sids, function (sid) {
            let rm_btn;
            if (removeSid) {
                rm_btn = <span
                    className="link MP-SidebarShortlist-btn-rm text-danger"
                    onClick={() => removeSid(sid)}
                >
                    <span className="fa-stack">
                        <i className="fa fa-circle fa-stack-2x text-danger"></i>
                        <i className="fa fa-times fa-stack-1x"></i>
                    </span>
                </span>;
            }
            return <li key={"lg-" + sid} className="MP-SidebarShortlist-item list-group-item">
                    <div className="pull-left">
                        <SpeakerThumbnail sid={sid} dont_show_name_on_hover={true} className="img-responsive" size={40}/>
                    </div>
                    {rm_btn}
                    {" "}
                    <div className="pull-left">
                        <SpeakerName sid={sid} style={{lineHeight:"40px", paddingLeft: "15px", verticalAlign:"top"}} />
                    </div>
                    <div className="clearfix" />
                </li>;
        });

        return (
            <div className="sidebar_short_list_wrapper" style={{width:"100%"}}>
                <div id="sidebar_short_list_div" className={containerClasses.join(" ")}>
                    <div className="visible-sm visible-xs">
                        <div className="MP-SidebarShortlist-left">
                            <ul className="MP-SidebarShortlist-ul">
                                {list}
                                {n_more}
                            </ul>
                        </div>
                        <div style={{float: "right", width: 120, marginLeft: 10}}>
                            <h4 className=" text-center" style={{marginTop: 4}}>
                                {__("Shortlist")}
                            </h4>
                            <a href="/marketplace/search" className="btn btn-success btn-xs btn-block  visible-sm">
                                {__("Add More Candidates")}
                            </a>
                            <a href="/marketplace/search" className="btn btn-success btn-xs btn-block  visible-xs">
                                {__("Add More")}
                            </a>
                        </div>
                        <div className="clearfix"/>
                    </div>
                    <div className="visible-md visible-lg">
                        <h3 style={{marginTop: 4}}>
                            {__("Candidates")}
                        </h3>
                        <div className="MP-SidebarShortlist-left">
                            <ul className="MP-SidebarShortlist-ul list-group">
                                {list_lg}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

let mapStateToProps = function (state) {
    let sids = _.get(state, ["EmbeddedShortlist", "sids"], []);
    return {
        sids: sids
    };
};

let mapDispatchToProps = function (dispatch) {
    return {
        removeSid: function (sid) {
            dispatch(EmbeddedShortlist_remove(sid));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortlistSidebar);
