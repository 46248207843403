import React from "react";

class LoadingBar extends React.Component {


    render() {
        let img = require("./loading-dots-trans.gif");

        return (
            <img src={img} alt="loading" />
        );
    }

}

export default LoadingBar;
