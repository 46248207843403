import _ from "lodash";

export const OFFER_STATII = [null, "offer pending", "offer accepted", "deposit paid", "paid in full", "event completed", "reviewed"]; //order of priority is important

export default function (event, agreement, payment) {

    let has_an_offer = false; // check that we have at least one offer on a speaker
    let has_an_accepted_offer = false; // check that we have at least an accepted offer on a speaker
    let deposit_paid = false; // used to check if the deposit has been paid

    let offerstatus = 0; //0=no offer, 1=offer pending, 2=offer accepted, 3=deposit paid, 4=completely paid, etc.. (in order of OFFER_STATII)

    // DEPOSIT PAID
    // Payments determine if the buyer has accepted an offer and paid the deposit to the speaker
    let deposits = _.filter(_.get(payment, ["services"]), function (item) {
        return _.get(item, ["flags_as_map", "is_deposit", "is_set"], false);
    });

    deposit_paid = _.reduce(deposits, function (deposit_paid, item) {
        return deposit_paid || !!_.get(item, ["paid"], false);
    }, deposit_paid);

    let all_paid = _.get(payment, ["allpaid"], false);

    let shortlist_speakers = _.get(event, ["MPshortlist", "speaker_ids"], {});
    _.forEach(shortlist_speakers, function (sid) {
        if (sid > 0) {

            // Check if we have at least one pending offer
            if (null !== _.get(agreement, [sid], null)) {
                has_an_offer = true;
                offerstatus = _.max([offerstatus, 1]);
            } //if there _is_ an agreement, we're at least offer-pending

            // Check if we have at least one accepted offer
            if (true === _.get(agreement, [sid, "is_accepted"], false)) {
                has_an_accepted_offer = true;
                offerstatus = _.max([offerstatus, 2]);
            }
        }
    });

    if (has_an_offer) {
        offerstatus = 1;
    }

    // Only perform these checks if we have an accepted offer
    if (has_an_accepted_offer) {
        // Check if the deposit has been paid
        if (deposit_paid) {
            offerstatus = _.max([offerstatus, 3]);
        }

        // Check if everything has been paid in full
        if (all_paid) {
            offerstatus = _.max([offerstatus, 4]);
        }

        // Check if something has been paid and the event has already happened
        if ((deposit_paid || all_paid) && _.get(event, ["Stagetimes", 0, "starttime"]) < new Date()) {
            offerstatus = _.max([offerstatus, 5]);
        }
    }

    //TODO: detect reviewed
    return OFFER_STATII[offerstatus];
}