import _ from "lodash";
import React from "react";
import {connect} from "react-redux";
import {withRouter} from "react-router";
import {gettext as __} from "../../utils/Trans";
import EmbeddedShortlist_add from "../../actions/ShortList/EmbeddedShortlist_add";
import EmbeddedShortlist_remove from "../../actions/ShortList/EmbeddedShortlist_remove";
import Search from "../../actions/Search/Search";
import SearchPageA from "../SearchPage/ab/SearchPageA";
import ResultSection from "./components/ResultSection";
import {Col, Row} from "react-bootstrap";
import videocall from "./videocall.png";

class SearchVirtualPage extends SearchPageA {

  state = {
    virtual: null,
    certified: null,
    video: null,
    showcased: null
  }

  componentDidMount() {
    // Scroll the page to the top
    window.scrollTo(0, 0);
    if (!this.props.results) {
      return this.props.searchVirtual()
      .then((resp) => (
        this.setState({
          virtual: _.slice(_.get(resp, ["virtual"]), 0, 8),
          certified: _.slice(_.get(resp, ["certified"]), 0, 8),
          video: _.slice(_.get(resp, ["video"]), 0, 8),
          showcased: _.slice(_.get(resp, ["showcased"]), 0, 8),
        })
      ));
    }
  }

  render() {
    let {
          virtual,
          certified,
          video,
          showcased
        } = this.state;

    return (
      <React.Fragment>
        <div className="container text-center">
          <Row style={{margin: "60px 0px"}}>
            <Col md={8} className={"text-left"}>
              <h1>
                <div className={"text-muted small"}>Find the best</div>
                <b>{__("Virtual Speakers")}</b>
                <div className={"text-muted small"}>Don't let COVID-19 stop you.</div>
              </h1>
              <br/>
              <p>
                <p className={"lead"}>Moving your meeting online? Don't let that stop you from giving your attendees a first-class event.</p>
                <p>Many of our presenters have <strong>specialized online presentations</strong> that can be highly interactive with groups from 5 to 50,000.
                   Our presenters bring that extra energy your audience needs right now.</p>
                <p>We have tagged our presenters with <strong>programs that are tailored to work remotely</strong>. Want to make sure your meeting isn't delayed by technical glitches?
                   Look for Certified Virtual Presenters who have passed a <strong>rigorous check of their virtual meeting equipment and skill</strong>.</p>
                <p>Take the next step in your meeting, conference, or graduation: select the perfect speaker and have a great time...virtually!</p>
              </p>
            </Col>
            <Col md={4} className={"text-center"}>
              <img src={videocall} alt={"icon made by freepik from flaticons"} height={320} style={{padding: "20px", marginTop: "4rem"}}/>
            </Col>
          </Row>
          <div className="container search-page-results" style={{marginTop: "-80px"}}>
            {_.size(certified) > 0 && certified && <ResultSection
              title={"Certified Virtual Speakers"}
              subtitle={"Find speakers that have earned our virtual certification"}
              see_more_link={"/search?require=virtual&presenter_type=virtual"}
              speakers={certified}
            />}
            {_.size(showcased) > 0 && showcased && <ResultSection
              title={"Trending Speakers"}
              subtitle={"You are guaranteed a great presentation with these speakers"}
              see_more_link={"/search?is_showcased=true&presenter_type=virtual"}
              speakers={showcased}
            />}
            <ResultSection
              title={"Speakers with Virtual Programs"}
              subtitle={"Need a remote speaker for your next program?  Look no further!"}
              see_more_link={"/search?presenter_type=virtual"}
              speakers={virtual}
            />
            <ResultSection
              title={"Speaker with Video Previews"}
              subtitle={"Watch videos of these speakers' past programs"}
              see_more_link={"/search?require=video&presenter_type=virtual"}
              speakers={video}
            />
            <ResultSection
              title={"How to cope with the new reality"}
              subtitle={"Resources that can help you put on great remote meetings"}
              see_more_link={"https://www.espeakers.com/blog/"}
              speakers={[
                {
                  thumb_url: "https://slackhq.com/wp-content/uploads/2018/11/2018-11_DeBara_RemoteWork_FINAL-01_920.jpg?w=1840",
                  speakername: "Ultimate Guide to Remote Meetings",
                  external_link: "https://slackhq.com/ultimate-guide-remote-meetings"
                },
                {
                  thumb_url: "https://www.pugh.co.uk/wp-content/uploads/2017/08/Microsoft-BLOG-1.png",
                  speakername: "Making the Switch to Remote",
                  external_link: "https://www.microsoft.com/en-us/microsoft-365/blog/2020/03/18/making-the-switch-to-remote-work-5-things-weve-learned/"
                },
                {
                  thumb_url: "https://remote.co/wp-content/uploads/2019/01/remote-presentation.png",
                  speakername: "Captivating a Remote Audience",
                  external_link: "https://remote.co/tips-captivating-remote-presentation/"
                },
                {
                  thumb_url: "https://www.espeakers.com/wp-content/uploads/2020/04/Local-City-Facebook-Instagram-10.png",
                  speakername: "Why I'm Still Happy with eSpeakers",
                  external_link: "https://www.espeakers.com/blog/"
                }
              ]}
            />
          </div>
        </div>
      </React.Fragment>
    );
  }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

const mapStateToProps = function (state) {

  let feeranges = _.get(state, ["Whitelabel", "sitelists", "feeranges"], []);
  let awards = _.get(state, ["Whitelabel", "sitelists", "awards_to_display_on_results"], []);
  let languages = _.get(state, ["Whitelabel", "sitelists", "universal", "languages"], []);
  let topics = _.get(state, ["Whitelabel", "sitelists", "topics"], []);
  let shortlist = _.get(state, ["EmbeddedShortlist"], {});
  let num_results = _.get(state, ["SearchPage", "num_results"], 0);

  return {
    awards: awards,
    languages: languages,
    feeranges: feeranges,
    topics: topics,
    num_results: num_results,
    shortlist: shortlist,
  };
};

const mapDispatchToProps = function (dispatch, ownProps) {
  return {
    searchVirtual: function () {
      return dispatch(Search.searchvirtual({
        bureau_id: _.get(ownProps.whitelabel, ["bid"])
      }));
    },
    onProfile: function (speaker) {
      if (_.has(speaker, ["sid"])) {
        ownProps.history.push("/profile/" + _.get(speaker, ["sid"]));
      }
    },
    onViewPrograms: function (speaker) {
      if (_.has(speaker, ["sid"])) {
        ownProps.history.push("/profile/" + _.get(speaker, ["sid"]) + "#programs");
      }
    },
    onViewSchedule: function (speaker) {
      if (_.has(speaker, ["sid"])) {
        ownProps.history.push("/profile/" + _.get(speaker, ["sid"]) + "#availability");
      }
    },
    onWatchVideo: function (speaker) {
      if (_.has(speaker, ["sid"]) && _.has(speaker, ["video_id"])) {
        ownProps.history.push("/profile/" + _.get(speaker, ["sid"]) + "?opentovideo=" + _.get(speaker, ["video_id"]));
      }
    },
    onFavorite: function (speaker, add_or_remove) {
      if (_.has(speaker, ["sid"])) {
        if (add_or_remove) {
          return dispatch(EmbeddedShortlist_add(speaker));
        } else {
          return dispatch(EmbeddedShortlist_remove(speaker));
        }
      }
    }
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchVirtualPage));