import React from "react";
import _ from "lodash";
import SpeakerReview from "../../../../../components/SpeakerReview";
import {gettext as __} from "../../../../../utils/Trans";


export default class VirtualReviews extends React.Component {

  state = {
    virtual_reviews: [],
    speaker: null,
    show_reviews: false
  }

  static getDerivedStateFromProps = (props) => ({
    speaker: props.speaker,
    virtual_reviews: _.filter(_.get(props.speaker, ["recommendations"]), (review) => (_.includes(_.get(review, ["flags_arrayed"], []), "VIRTUAL_EVENT")))
  })

  __onToggleReviews = () => (
    this.setState({
      show_reviews: !this.state.show_reviews
    })
  )

  render() {
    let {
          speaker,
          virtual_reviews,
          show_reviews
        } = this.state;
    return (
      (_.isEmpty(virtual_reviews)) ? null : <React.Fragment>
        <h4 className="header legend">
          {_.get(speaker, ["firstname"])}{_.get(speaker, ["firstname"], "") !== "" ? "'s" : null}{__(" Reviews")}
          <div className={"small pull-right link"} onClick={this.__onToggleReviews}>{show_reviews ? "hide" : "show"}{" "}reviews</div>
        </h4>
        <p>
          {_.get(speaker, ["firstname"])} has {_.size(virtual_reviews)} reviews from virtual events.
        </p>
        {show_reviews && _.map(virtual_reviews, (review, i) => (
          <SpeakerReview key={i} review={review}/>
        ))}
      </React.Fragment>
    );
  }
}