import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { gettext as __ } from "../../../../utils/Trans";
import CustomerService from "../../../../components/CustomerService";
import PostingType from "../PostingType";
import { AddCandidateButton, CancelEventButton, ColleagueFeedbackButton, EditEventButton, PauseEventButton, ResumeEventButton } from "../Buttons";

class EventActions extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            event,
            is_paused,
            allow_event_changes,
            whitelabel
        } = this.props;

        return (
            <div>
                <h4 className=" text-muted">
                    {__("EVENT ACTIONS")}
                </h4>
                <div className="row">
                    {allow_event_changes && <div className="col-xs-12 col-sm-6 col-md-12">
                        <AddCandidateButton {...this.props} event={event} />
                    </div>
                    }
                    <div className="col-xs-12 col-sm-6 col-md-12">
                        <ColleagueFeedbackButton {...this.props} event={event} />
                    </div>
                    {allow_event_changes && <div className="col-xs-12 col-sm-6 col-md-12">
                        <EditEventButton {...this.props} event={event} />
                    </div>
                    }
                    {allow_event_changes &&
                    <div className="col-xs-12 col-sm-6 col-md-12">
                        {is_paused ?
                            <ResumeEventButton {...this.props} event={event} />
                            :
                            <PauseEventButton {...this.props} event={event} />
                        }
                    </div>
                    }
                    {allow_event_changes &&
                    <div className="col-xs-12 col-sm-6 hidden-lg hidden-md">
                        <PostingType event={event} whitelabel={whitelabel} />
                    </div>
                    }
                    {allow_event_changes && <div className="col-xs-12 col-sm-6 col-md-12">
                        <CancelEventButton {...this.props} event={event} />
                    </div>
                    }
                </div>
                <div style={{height:"2em"}}/>
                <CustomerService {...this.props} whitelabel={whitelabel} />
            </div>
        );
    }
}

EventActions.propTypes = {
    event: PropTypes.object,
    allow_event_changes: PropTypes.bool
};

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {
    let is_paused = _.get(ownProps.shortlist, ["flags_as_map", "is_event_paused", "is_set"], false);

    return {
        is_paused: is_paused
    };
};

var mapDispatchToProps = function () {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(EventActions);
