import _ from "lodash";
import React from "react";
import moment from "moment";
import DatePicker from "react-bootstrap-date-picker";

class DatePickerField extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            dateFormat: props.dateFormat || "MM/DD/YYYY"
        };
    }

    render() {
        let {
            input,
            defaultValue = "",
            placeholder,
            label,
            help_text,
            meta: {
                touched,
                error,
                warning
            }
        } = this.props;

        // Cast the value to a string
        if (!_.isString(input.value)) {
            if (input.value instanceof Date) {
                input.value = input.value.toISOString();
            } else if (input.value instanceof moment) {
                input.value = input.value.toISOString();
            }
        }

        return (
            <div>
                <div className={"form-group " + (touched && error ? "has-error " : touched && warning ? "has-warning " : "")}>
                    {label && <label className="control-label">{label}</label>}
                    <DatePicker
                        {...input}
                        dateFormat={this.state.dateFormat}
                        minDate={this.props.minDate}
                        maxDate={this.props.maxDate}
                        placeholder={placeholder}
                        defaultValue={defaultValue}
                        onChange={(value) => input.onChange(value)}
                        showClearButton={false}
                    />
                    {help_text && <div className="help-block">{help_text}</div>}
                    {touched &&
                    ((error && <div className="text-danger"><i className="fa fa-times" /> {error}</div>) ||
                        (warning && <div className="text-danger"><i className="fa fa-exclamation-triangle" /> {warning}</div>))}
                </div>
            </div>
        );
    }
}

export default DatePickerField;