import _ from "lodash";

export default function(state = {}, action){
    switch(action.type){
        case "SHORTLISTPAGE_TOGGLE_EVENT_ACTIONS": {
            let is_event_actions_open = !!_.get(state, ["is_event_actions_open"], false);
            return _.assign({}, state, {
                is_event_actions_open: !is_event_actions_open
            });
        }
        case "SHORTLISTPAGE_MESSAGE_SIDEBAR_OPEN":
            return _.assign({}, state, {
                is_message_sidebar_open: true
            });
        case "SHORTLISTPAGE_MESSAGE_SIDEBAR_CLOSE":
            return _.assign({}, state, {
                is_message_sidebar_open: false
            });
        case "SHORTLISTPAGE_DEPOSIT_DECLINED":
            return _.assign({}, state, {
                is_deposit_payment_received: false,
                is_deposit_payment_declined: true,
                deposit_declined_message: action.error
            });
        case "SHORTLISTPAGE_DEPOSIT_RECEIVED":
            return _.assign({}, state, {
                is_deposit_payment_received: true
            });
        case "SHORTLISTPAGE_CLEAR_DEPOSIT_FLAGS":
            return _.assign({}, state, {
                is_deposit_payment_received: false,
                is_deposit_payment_declined: false,
                deposit_declined_message: null
            });
        case "SHORTLISTPAGE_BALANCE_DECLINED":
            return _.assign({}, state, {
                is_balance_payment_received: false,
                is_balance_payment_declined: true,
                balance_declined_message: action.error
            });
        case "SHORTLISTPAGE_BALANCE_RECEIVED":
            return _.assign({}, state, {
                is_balance_payment_received: true
            });
        case "SHORTLISTPAGE_CLEAR_BALANCE_FLAGS":
            return _.assign({}, state, {
                is_balance_payment_received: false,
                is_balance_payment_declined: false,
                balance_declined_message: null
            });
        default:
            return state;
    }
}
