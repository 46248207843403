import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import { Col, Row, Well } from "react-bootstrap";
import moment from "moment";

class EventDetails extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    __onShare = () => {
        if (_.isFunction(this.props.onShare)) {
            this.props.onShare();
        }
    }

    __onViewCollaborators = () => {
        if (_.isFunction(this.props.onViewCollaborators)) {
            this.props.onViewCollaborators();
        }
    }

    render() {
        let {
            event
        } = this.props;

        const formatVenue = (venue) => {
            let v = "";
            let city = _.get(event, ["Venues", 0, "city"]);
            let state = _.get(event, ["Venues", 0, "st"]);
            let country = _.get(event, ["Venues", 0, "country"]);
            if (!_.isNull(city)) {
              v += city + ", ";
            }
            if (!_.isNull(state)) {
              v += state + " ";
            }
            if (!_.isNull(country)) {
              v += country;
            }
            return v;
        };

        let name = _.get(event, ["organization"], "Shared Event");
        let notes = _.get(event, ["Shortlists", 0, "notes"]);
        let location = formatVenue(_.get(event, ["Venues", 0]));
        let starttime = _.get(event, ["Stagetimes", 0, "starttime"]);

        return (
            <div>
                <div className="pull-right" style={{marginTop:"20px"}}>
                    <div className="btn btn-default" onClick={this.__onShare}><i className="fa fa-users" />{" "}Share with others</div>
                    <div className="small text-center"><span className="link" onClick={this.__onViewCollaborators}>Sharing with {_.size(_.get(event, ["Shortlists", 0, "collaborators"]))} collaborators</span></div>
                </div>
                <h1 className=" hidden-xs hidden-sm" style={{marginTop: "10px"}}>
                    <span className="small">{__("Collaborate on")}</span>
                    <div>{name}</div>
                </h1>
                <h3 className=" visible-xs visible-sm">
                    <span className="small">{__("Collaborate on")}</span>
                    <div>{name}</div>
                </h3>
                <Well>
                    <Row>
                        <Col sm={(location || moment(starttime).isValid()) ? 10 : 12}>
                            {notes}
                        </Col>
                        {(location || starttime) &&
                        <Col sm={2} className="text-center" style={{borderLeft: "2px groove #E8E8E8"}}>
                            {location &&
                            <div>
                                <i className="fa fa-map-marker fa-2x text-muted" />
                                <div>{location}</div>
                            </div>
                            }
                            {moment(starttime).isValid() && <div>{moment(starttime).format("MMMM DD, YYYY")}</div>}
                        </Col>
                        }
                    </Row>
                </Well>
            </div>
        );
    }
}

EventDetails.propTypes = {
    event: PropTypes.object.isRequired,
    onShare: PropTypes.func,
    onViewCollaborators: PropTypes.func
};

export default EventDetails;
