import _ from "lodash";

export default function(state = {}, action){
    switch(action.type) {
        case "SPEAKER_FULL_PROFILES_WAITING":
            return _.assign({}, state, {
                waiting: true,
                timestamp_sent: action.timestamp
            });
        case "SPEAKER_FULL_PROFILES_RESPONSE":
            let sid = _.get(action, ["sid"]);
            let new_state = _.cloneDeep(state);
            _.set(new_state, [sid, "profile"], action.data);
            return _.assign({}, state, new_state);
        default:
            return state;
    }
}
