import React from "react";
import PropTypes from "prop-types";

import "./IconButton.scss";

class IconButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            icon,
            onClick,
            className,
            icon_only,
            children
        } = this.props;

        if (!className) { className = "btn-primary"; }

        if (icon_only) {
            return (
                <div
                   className={"IconButton btn IconButton-icon-only " + className}
                   onClick={onClick}
                >
                    <span
                        className={"fa fa-" + icon}
                    />
                    <span className="sr-only">
                        {children}
                    </span>
                </div>
            );
        }

        return (
            <div className={"IconButton btn btn-block " + className} onClick={onClick}>
                <span
                    className={"fa fa-" + icon}
                />
                <span className="button-text">
                    {children}
                </span>
            </div>
        );
    }
}

IconButton.propTypes = {
    icon: PropTypes.string,
    icon_only: PropTypes.bool,
    onClick: PropTypes.func,
    className: PropTypes.string
};

export default IconButton;