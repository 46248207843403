import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { gettext as __ } from "../../utils/Trans";
import formatPhone from "../../utils/formatPhone";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { DateAvailability, Message, Phone, Text } from "../../components/FormComponents";
import InlineError from "../../components/InlineError";
import LoadingSubmit from "../../components/LoadingSubmit";
import StepHeader from "../../components/StepHeader";
import ReCAPTCHA from "react-google-recaptcha";
import speakerDirectMessage from "../../actions/Speaker/speakerDirectMessage";
import RecaptchaPassed from "../../actions/Recaptcha/Recaptcha";
import ClearRecaptcha from "../../actions/Recaptcha/ClearRecaptcha";
import speakerAvailabilityForDate from "../../actions/BookMeNowPage/speakerAvailabilityForDate";

import "./BookMeNow.scss";

class BookMeNowForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            send_result: null
        };
    }

    handleSubmit = (values, dispatch, props) => {

        if (!props.captchapassed) return;

        let message_data = {
            event_name: values.event_name,
            event_date: values.startdate,
            event_location: values.location,
            message: values.meeting_goals,
            name: values.contact_name,
            email: values.contact_email,
            phone: values.contact_phone,
            captcha: {
                key: "recaptcha",
                input: props.captchavalue
            } //the key is a magic value, don't change
        };

        return dispatch(speakerDirectMessage({
                is_bookmenow: true,
                message_data: JSON.stringify(message_data),
                sid: values.sid,
                bureau_id: values.bureau_id
            }))
            .then((response) => {
                dispatch(ClearRecaptcha());
                return response;
            })
            .then((response) => {
                this.setState({
                    send_result: {
                        type: "SPEAKER_DIRECTMESSAGE",
                        data: "Inquiry sent!"
                    }
                });
                return response;
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    __checkAvailability = (e, new_value) => {
        this.props.checkAvailability(new_value);
    }

    render() {
        let {
            speaker,
            handleSubmit,
            submitting,
            error,
            captchapassed,
            datecheck_waiting,
            datecheck_result,
            setCaptchaPassed,
            token
        } = this.props;

        let submitClasses = ["btn-success", "btn-block", "btn-lg"];
        if (!captchapassed) submitClasses.push("disabled");

        if (!_.isEmpty(_.get(this.state, ["send_result"]))) {
            return (
                <div>
                    <i className="fa fa-3x fa-envelope-o pull-left"/> <h2 style={{paddingLeft: "1.5em"}}>{this.state.send_result.data}</h2>
                    <p style={{marginTop: "2em"}}>{__("Watch your inbox for a reply from")}{" "}{_.get(speaker, ["name_first"])}.</p>
                    <p style={{marginTop: "2em"}}>{__("While you're waiting, check out")}{" "}
                        <Link to={"/profile/" + _.get(speaker, ["sid"]) + "?bookmenow_full_profile=true&bookmenow=" + token}>{_.get(speaker, ["name_first"])}&apos;s {__("full profile")} »</Link>
                    </p>
                </div>
            );
        }

        // ELSE
        return (
            <Form className="BMN-EventForm mp-style-form" onSubmit={handleSubmit(this.handleSubmit)}>
                {error && <InlineError error={_.get(error, ["message"])}/>}
                <StepHeader n={__("Tell") + " " + _.get(speaker, ["name_first"]) + " " + __("about your event")}/>
                <div className="row">
                    <div className="col-sm-4">
                        <Field
                            name="event_name"
                            placeholder={__("Widget Association Annual Sales Conference")}
                            label={__("Event Name")}
                            help_text={__("The meeting or conference title, including the session name if applicable.")}
                            component={Text}
                        />
                    </div>
                    <div className="col-sm-4">
                        <Field
                            name="startdate"
                            label={__("Event Date")}
                            help_text={__("The start date of your event. Don't know it yet? Just pick something close.")}
                            component={DateAvailability}
                            onChange={this.__checkAvailability}
                            availability={datecheck_result}
                            checkingAvailability={datecheck_waiting}
                        />
                    </div>
                    <div className="col-sm-4">
                        <Field
                            name="location"
                            label={__("Event Location")}
                            placeholder={__("City, State")}
                            help_text={__("The city to which the presenter will travel for your meeting, or \"online\" for webinar.")}
                            component={Text}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-12">
                        <Field
                            name="meeting_goals"
                            label={__("Purpose or goals of your meeting")}
                            placeholder={__("What should the speaker's presentation accomplish for your audience?")}
                            help_text={__("Providing details about your event helps the speaker do their best work for you. Helpful information includes program theme, organization goals, etc.")}
                            component={Message}
                            rows={5}
                        />
                    </div>
                </div>

                <StepHeader n={(__("How should") + " " + _.get(speaker, ["name_first"]) + " " + __("reply to you?"))}/>
                <div className="row">
                    <div className="col-sm-4">
                        <Field
                            name="contact_name"
                            label={__("Your Name")}
                            component={Text}
                        />
                    </div>
                    <div className="col-sm-4">
                        <Field
                            name="contact_email"
                            label={__("Email")}
                            component={Text}
                        />
                    </div>
                    <div className="col-sm-4">
                        <Field
                            name="contact_phone"
                            label={__("Phone")}
                            placeholder="+1 (212) 121-1212"
                            component={Phone}
                            normalize={formatPhone}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-8">
                        <ReCAPTCHA
                            ref="recaptcha"
                            sitekey="6Ld6CAMTAAAAAIKCRhPspREtTRBxhBN79f6R7pVE"
                            onChange={setCaptchaPassed}
                        />
                    </div>
                    <div className="col-sm-4">
                        <br />
                        <LoadingSubmit is_saving={submitting} save_text={__("Sending Message...")} className={submitClasses.join(" ")} disabled={!captchapassed}>
                            {__("CONTACT")}{" "}{_.upperCase(_.get(speaker, ["name_first"]))}
                        </LoadingSubmit>
                    </div>
                </div>
            </Form>
        );
    }
}

BookMeNowForm.propTypes = {
    error: PropTypes.string,
    sid: PropTypes.number,
    firstname: PropTypes.string,
    captchapassed: PropTypes.bool,
    simplesite_url: PropTypes.string
};


////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {
    let ajax_datecheck = _.get(state, ["balboa3", "speakerAvailabilityForDate"], {});

    return {
        datecheck_waiting: ajax_datecheck.waiting,
        datecheck_result: _.get(state, ["BookMeNowPage", "speakerAvailabilityForDate"], null),

        captchapassed: _.get(state, ["Recaptcha", "captchapassed"], false),
        captchavalue: _.get(state, ["Recaptcha", "captchavalue"], false),

        initialValues: {
            sid: _.get(ownProps.speaker, ["sid"]),
            bureau_id: _.get(ownProps.whitelabel, ["bid"])
        }
    };
};


var mapDispatchToProps = function (dispatch, ownProps) {
    let sid = _.get(ownProps.speaker, ["sid"]);
    return {
        sendToSpeaker: function (payload) {
            dispatch(speakerDirectMessage(payload))
                .catch((err) => {
                    if (_.get(err, ["name"]) === "AuthorizationException") {
                        ownProps.history.push("/error");
                    }
                });
        },
        setCaptchaPassed: function (captchavalue) {
            dispatch(RecaptchaPassed(captchavalue));
        },
        checkAvailability: function (checkthisdate) {
            dispatch(speakerAvailabilityForDate(sid, checkthisdate));
        }
    };
};

BookMeNowForm = reduxForm({
    form: "speaker_bookmenow_form",
    validate: function(values) {
        const errors = {};
        if (!values.event_name) {
            errors.event_name = __("Please enter a name for your event");
        }
        if (!values.contact_name) {
            errors.contact_name = __("Please let us know who you are");
        }
        if (!values.meeting_goals) {
            errors.meeting_goals = __("Please describe your meeting and how you would like the speaker to contribute");
        }
        if (!values.contact_email) {
            errors.contact_email = __("Please enter a valid email address to the presenter can contact you");
        }
        if (!values.contact_phone) {
            errors.contact_phone = __("Please enter a valid phone number to the presenter can contact you");
        }
        return errors;
    }
})(BookMeNowForm);

export default connect(mapStateToProps, mapDispatchToProps)(BookMeNowForm);