import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import Speaker from "../../actions/Speaker/Speaker";
import LoadingIndicator from "../../components/LoadingIndicator";
import formatVideoUrl from "../../utils/formatVideoUrl";
import { DefaultPlayer as Video } from "react-html5video";
import queryString from "query-string";

import "./VideoPlayerPage.scss";

require ("../../../node_modules/react-html5video/dist/styles.css");

class VideoPlayerPage extends React.Component {

    componentDidMount() {
        if (!this.props.speaker) {
            let query = queryString.parse(window.location.search);
            let sid = _.get(query, ["sid"]);
            this.props.loadProfile(sid);
        }
    }

    render() {
        let {
            is_loading,
            video,
            whitelabel
        } = this.props;

        let video_urls = formatVideoUrl(_.get(video, ["url"]));
        let url = _.has(video_urls, ["mp4"]) ? _.get(video_urls, ["mp4"]) : _.has(video_urls, ["webm"]);
        let video_poster = _.get(video, ["img"]);

        let foreground = _.get(whitelabel, ["mp_keyvalues", "COLOR_HEADER_FOREGROUND"], "#FFF");
        let background = _.get(whitelabel, ["mp_keyvalues", "COLOR_HEADER_BACKGROUND"], "#000");

        if (is_loading) {
            return (<LoadingIndicator />);
        }

        return (
            <div className="MP-VideoPlayer" style={{ color:foreground, backgroundColor:background }}>
                <Video autoPlay
                       controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
                       poster={video_poster}
                       src={url}
                />
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {

    let query = queryString.parse(window.location.search);
    let sid = _.toNumber(_.get(query, ["sid"]));
    let aid = _.toString(_.get(query, ["aid"]));
    let video;

    let speaker = _.get(state, ["Speakers", sid]);
    if (speaker) {
        let videos = _.keyBy(_.get(speaker, ["videos"]), "aid");
        video = _.get(videos, [aid]);
    }

    return {
        is_loading: (_.isUndefined(speaker) || _.isUndefined(video)),
        speaker: speaker,
        video: video
    };
};

var mapDispatchToProps = function (dispatch, ownProps) {
    return {
        loadProfile: function (sid) {
            return dispatch(Speaker.get(sid));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoPlayerPage);