import _ from "lodash";
import React from "react";
import { Redirect } from "react-router-dom";
import { gettext as __ } from "../../utils/Trans";
import Constants from "../../utils/Constants";

import mapmarker from "./map-marker.gif";

export default class SearchNearbyPage extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      location: null,
      error: null
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    fetch("https://api.ipstack.com/check?output=json&access_key=" + Constants.IPSTACK_ACCESS_KEY)
    .then((response) => response.json())
    .then((responseJson) => {
      let location = [];
      if (!_.isEmpty(_.get(responseJson, "city"))) {
        location.push(_.get(responseJson, "city"));
      }
      if (!_.isEmpty(_.get(responseJson, "region_code"))) {
        location.push(_.get(responseJson, "region_code"));
      }
      if (!_.isEmpty(_.get(responseJson, "country_code"))) {
        location.push(_.get(responseJson, "country_code"));
      }

      this.setState({
        location: {
          location: _.join(location, ", "),
          city: _.get(responseJson, "city"),
          state: _.get(responseJson, "region_code"),
          country: _.get(responseJson, "country_code"),
          lat: _.get(responseJson, "latitude"),
          lng: _.get(responseJson, "longitude")
        }
      });
    })
    .catch((error) => {
      this.setState({
        error: _.get(error, ["message"])
      });
    });
  }

  render() {
    if (this.state.location) { // redux store saved last search
      return (<Redirect to={"/search?sort=distance&location=" + JSON.stringify(this.state.location)}/>);
    } else if (this.state.error) {
      return (
        <div className="text-center">
          <div style={{margin: "15vh auto"}}>
            <h2>Oops! Looks like there was a problem finding your location!</h2>
            <img src={mapmarker} alt="missing speaker" className="img-responsive center-block"/>
            <div>
              <div className="text-danger">
                {this.state.error}
              </div>
              <div>
                <p>{__("Oops!  Looks like there was a problem finding your location!")}</p>
                <a href="/marketplace/search"><b>{__("Let's just go to the search page")}</b></a>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return (
        <div className="text-center">
          <div style={{margin: "15vh auto"}}>
            <h2>Finding your location...</h2>
            <img src={mapmarker} alt="map marker" className="img-responsive center-block"/>
          </div>
        </div>
      );
    }
  }
}