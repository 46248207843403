import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { gettext as __ } from "../../../../utils/Trans";
import MpCollaborator from "../../../../actions/ShortList/MpCollaborator";

class CollaboratorModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            confirm_delete_collaborator: null,
            is_deleting: false
        };
    }

    __onDelete = () => {
        if (_.isFunction(this.props.onDeleteCollaborator)) {
            this.setState({
                is_deleting: true
            },
                () => {
                    return this.props.onDeleteCollaborator(this.state.confirm_delete_collaborator)
                            .then(() => {
                                this.setState({
                                    is_deleting: false
                                });
                            });
                }
            );
        }
    }

    __onConfirmDelete = (collaborator) => {
        this.setState({
            confirm_delete_collaborator: collaborator
        });
    }

    render() {
        let {
            shortlist,
            onClose
        } = this.props;

        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("Sharing with %s collaborators", _.size(_.get(shortlist, ["collaborators"])))}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    You are sharing this shortlist with these collaborators:
                    <table className="table">
                        <thead>
                        <tr>
                            <td>Name</td>
                            <td className="text-center">Review</td>
                            <td className="text-center">Modify</td>
                            <td className="text-center">Full</td>
                            <td className="text-center">&nbsp;</td>
                        </tr>
                        </thead>
                        <tbody>
                        {_.map(_.get(shortlist, ["collaborators"]), (collaborator, i) => {
                            return (
                                <React.Fragment key={i}>
                                    <tr key={i}>
                                        <td>{_.get(collaborator, ["email"])}</td>
                                        <td className="text-center">{((_.get(collaborator, ["permission"]) & 1) > 0) ? <i className="fa fa-check" /> : ""}</td>
                                        <td className="text-center">{((_.get(collaborator, ["permission"]) & 4) > 0) ? <i className="fa fa-check" /> : ""}</td>
                                        <td className="text-center">{((_.get(collaborator, ["permission"]) & 8) > 0) ? <i className="fa fa-check" /> : ""}</td>
                                        <td className="text-center">
                                            <div onClick={() => this.__onConfirmDelete(collaborator)} style={{cursor: "pointer"}}>
                                                <i className="fa fa-trash" />
                                            </div>
                                        </td>
                                    </tr>
                                    {_.get(this.state.confirm_delete_collaborator, ["id"]) === _.get(collaborator, ["id"]) &&
                                    <tr>
                                        <td colSpan={5} className="small bg-danger">
                                            <p>Are you sure you want to remove <i>{_.get(collaborator, ["email"])}</i> from this shortlist?</p>
                                            <div className="text-right">
                                                <Button onClick={this.__onDelete} disabled={this.state.is_deleting} bsStyle="danger" bsSize="small">
                                                    {this.state.is_deleting
                                                        ? <span><i className="fa fa-spin fa-circle-o-notch"/>{" "}Removing collaborator</span>
                                                        : <span>Yes, remove this collaborator</span>
                                                    }
                                                </Button>{" "}
                                                <Button onClick={this.__onConfirmDelete} disabled={this.state.is_deleting} bsStyle="default" bsSize="small">Nevermind</Button>
                                            </div>
                                        </td>
                                    </tr>
                                    }
                                </React.Fragment>
                            );
                        })}
                        </tbody>
                    </table>
                    <div>
                        <i>If you need to change the permissions of a collaborator, you will need to first remove them and then send them a new invitation with updated permissions.</i>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn btn-default btn-block-xs" onClick={onClose}>
                        {__("Close")}
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {
    return {};
};

var mapDispatchToProps = function (dispatch, ownProps) {
    return {
        onDeleteCollaborator: function(collaborator) {
            return dispatch(MpCollaborator.remove(_.get(collaborator, ["id"])))
                .then((resp) => {
                    return dispatch({
                        type: "AFFILIATE_EVENT_COLLABORATOR_DELETE",
                        id: _.get(ownProps.affiliate_event, ["id"]),
                        data: _.get(resp, ["data"])
                    });
                });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollaboratorModal);