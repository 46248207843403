import _ from "lodash";
import React from "react";
import md5 from "md5";
import PropTypes from "prop-types";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { gettext as __ } from "../../../../utils/Trans";
import ProfileContactInfo from "../ProfileContactInfo";
import ProfileSocialButtons from "../ProfileSocialButtons";
import ProfileLanguagePicker from "../ProfileLanguagePicker";
import PricingTable from "../PricingTable";
import SpeakerHeadshot from "../SpeakerHeadshot";
import Quote from "../Quote";
import ExpertList from "../ExpertList";
import ExperienceList from "../ExperienceList";
import FeaturedVideo from "../FeaturedVideo";
import SpeakerTabs from "../SpeakerTabs";
import QuickStats from "../QuickStats";
import Analytics from "../../../../utils/Analytics";
import { Button, Row, Col } from "react-bootstrap";

class SpeakerProfile extends React.Component {

    sendClickEvent(sid) {
        Analytics.sendEvent("Profile", "click", sid, "search result photo");
    }

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speaker,
            whitelabel,
            user,
            is_shortlisted,
            profile_language,
            back_to_search_text = "BACK TO SEARCH",
            back_to_search_close = false,
            topic_id,
            is_preview_mode,
            back_to_search,
            book_me_now_mode,
            simple_site_mode,
            headless_mode,
            directory_mode,
            onVideoClick,
            selected_tab,
            onInquire,
            onShortlistAdd,
            onShortlistRemove,
            onUpdateLanguage,
            onShowTab,
            tabRef,
            sitelists
        } = this.props;

        let sid = _.get(speaker, ["sid"]);
        let book_me_now_token = md5(sid).substring(0, 4);
        let back_btn;

        //on mobile, the 'get free quote' 2nd navbar line obscures the 'back to search' button if not lowered
        if (back_to_search) {
            back_btn = (
                <div>
                    <div className="col-xs-4 text-center visible-xs">
                        <Link to={back_to_search} className="visible-xs" style={{marginRight: "10px", marginTop: "2.5em"}}>
                            <Button bsStyle="default"><i className="fa fa-arrow-circle-left"/>{" "}{__(back_to_search_text)}</Button>
                        </Link>
                    </div>
                    <Link to={back_to_search} className="hidden-xs" style={{marginRight: "10px"}}>
                        <Button bsStyle="default" bsSize="large"><i className="fa fa-arrow-circle-left"/>{" "}{__(back_to_search_text)}</Button>
                    </Link>
                </div>
            );
        }

        if (back_to_search_close && back_to_search_text) {
            back_btn = (
                <div>
                    <div className="col-xs-4 text-center visible-xs">
                        <div onClick={back_to_search_close} className="visible-xs" style={{marginRight: "10px", marginTop: "2.5em"}}>
                            <Button bsStyle="default"><i className="fa fa-arrow-circle-left"/>{" "}{__(back_to_search_text)}</Button>
                        </div>
                    </div>
                    <div onClick={back_to_search_close} className="hidden-xs" style={{marginRight: "10px"}}>
                        <Button bsStyle="default" bsSize="large"><i className="fa fa-arrow-circle-left"/>{" "}{__(back_to_search_text)}</Button>
                    </div>
                </div>
            );
        }

        if (book_me_now_mode) {
            back_btn = (
                <Link to={"/bookmenow/" + sid + "/" + md5(sid).substring(0, 4)} className="btn btn-default btn-lg" style={{marginRight: "10px"}}>
                    <i className="fa fa-arrow-circle-left"/>{" "}{__("BACK TO INQUIRY")}
                </Link>
            );
        }

        // Only show contact info if the speaker says so (and is in BookMeNow mode)
        // - OR - the whitelabel says so AND the flag is also set in backstage
        let show_contact_info = (_.get(whitelabel, ["flags", "MP_SHOW_SPKR_CONTACT_INFO"], false) && _.get(whitelabel, ["flags", "SHOW_SPKR_CONTACT_INFO"], false)) || (book_me_now_mode && _.get(speaker, ["simplesiteShowContactInfo"], false));

        let is_custom_domain = !_.isEmpty(_.get(whitelabel, "whitelabel_domain"));

        let action_btn;
        let action_btn2;

        if (directory_mode || (_.get(user, ["role"], "guest") === "publisher")) {
            action_btn = (
                <div>
                    <Link to={"/direct_message/" + sid + "?returntourl=" + encodeURIComponent(window.location.href) + (simple_site_mode ? "&simplesite=1" : "")} className="btn btn-success btn-lg btn-block">
                        {__(_.get(whitelabel, "is_bureau_mode", false) ? "INQUIRE" : "CONTACT")}
                    </Link>
                </div>
            );
        } else if (book_me_now_mode || simple_site_mode) {
            action_btn = (
                <div>
                    <Link to={"/bookmenow/" + sid + "/" + book_me_now_token + "?returntourl=" + encodeURIComponent(window.location.href) + (simple_site_mode ? "&simplesite=1" : "") + (book_me_now_mode ? "&bookmenow=1" : "")} className="btn btn-success btn-lg btn-block">
                        {__("INQUIRE")}
                    </Link>
                </div>
            );
        } else {
            if (is_shortlisted) {
                action_btn = (
                    <div>
                        <button className="btn btn-lg btn-block mp-secondary" onClick={onShortlistRemove}>
                            <i className="fa fa-heart"/>{" "}{__("FAVORITED")}
                        </button>
                    </div>
                );
            } else {
                action_btn = (
                    <div>
                        <button className="btn btn-success btn-lg btn-block" onClick={onShortlistAdd}>
                            <i className="fa fa-heart-o"/>{" "}{__("FAVORITE")}
                        </button>
                    </div>
                );
            }

            action_btn2 = (
                <div>
                    <p/>
                    {/*<Link to={"/direct_message/" + sid + "?returntourl=" + encodeURIComponent(window.location.href) + (simple_site_mode ? "&simplesite=1" : "")} className="btn btn-default btn-lg btn-block">*/}
                    {/*{__("Inquire")}*/}
                    {/*</Link>*/}
                    <button className="btn btn-default btn-lg btn-block" onClick={onInquire}>
                        {__("INQUIRE")}
                    </button>
                </div>
            );
        }

        return (
            <div className="mp-profile-page">
                {is_preview_mode &&
                    <div className="alert alert-info">
                        Close this window when you're done previewing your profile.
                    </div>
                }
                {!simple_site_mode &&
                <Row>
                    <Col md={12} className="bottom-edge" style={{display: "table"}}>
                        <p/>
                        {back_btn}
                    </Col>
                </Row>
                }
                <Row>
                    <Col md={10}>
                        <Row>
                            <Col md={4} className="text-center" id="profileLeftSidebar">
                                <SpeakerHeadshot speaker={speaker} onShowTab={onShowTab} />
                                {!headless_mode && action_btn}
                                {!headless_mode && action_btn2}
                                {!is_custom_domain &&
                                <ProfileSocialButtons speaker={speaker}/>
                                }
                                {(show_contact_info) &&
                                <ProfileContactInfo speaker={speaker}/>
                                }
                            </Col>
                            <Col md={8}>
                                <h1>
                                    {_.toUpper(_.get(speaker, ["name"]))}
                                    <br/>
                                    <small>{_.get(speaker, ["credentials"])}</small>
                                </h1>
                                <Quote speaker={speaker} topic_id={topic_id} profile_language={profile_language}/>
                                <br/>
                                <Row>
                                    <Col md={4}>
                                        <ExpertList speaker={speaker} whitelabel={whitelabel} />
                                    </Col>
                                    <Col md={4}>
                                        <ExperienceList speaker={speaker} whitelabel={whitelabel} />
                                    </Col>
                                    <Col md={4}>
                                        {speaker && <FeaturedVideo speaker={speaker} onVideoClick={onVideoClick}/>}
                                    </Col>
                                </Row>

                                {!_.get(whitelabel, ["flags", "MP_HIDE_FEES"]) &&
                                <PricingTable speaker={speaker}/>
                                }

                            </Col>
                        </Row>
                        <div ref={tabRef} style={{padding: "20px"}}>&nbsp;</div>
                        <Row>
                            <Col md={12} id="speakerTabs">
                                <SpeakerTabs speaker={speaker} onVideoClick={onVideoClick} selected_tab={selected_tab} onShowTab={onShowTab} profile_language={profile_language} topic_id={topic_id}/>
                            </Col>
                        </Row>
                    </Col>
                    <Col md={2}>
                        <ProfileLanguagePicker speaker={speaker} profile_language={profile_language} languages={_.get(sitelists, ["universal", "languages"])} onUpdateLanguage={onUpdateLanguage}/>
                        <QuickStats speaker={speaker} whitelabel={whitelabel} onShowTab={onShowTab}/>
                    </Col>
                </Row>
                <div className="text-muted" style={{marginTop:"5em", fontStyle:"italic", borderTop:"thin silver dotted"}}>Information on this page may only be used in consideration of engaging services for hire.<br/>You may not harvest this information for marketing purposes per the <a href="https://www.espeakers.com/legal/privacy-policy/">eSpeakers.com privacy policy</a>.</div>
            </div>
        );
    }
}

SpeakerProfile.propTypes = {
    speaker: PropTypes.object.isRequired,
    whitelabel: PropTypes.object.isRequired,
    user: PropTypes.object,
    selected_tab: PropTypes.string,
    is_preview_mode: PropTypes.bool,
    book_me_now_mode: PropTypes.bool,
    simple_site_mode: PropTypes.bool,
    is_shortlisted: PropTypes.bool,
    onShortlistAdd: PropTypes.func,
    onShortlistRemove: PropTypes.func,
    onUpdateLanguage: PropTypes.func,
    onShowTab: PropTypes.func
};

export default withRouter(SpeakerProfile);