import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import img_pro from "./img/membersince_pro.png";
import img_plus from "./img/membersince_plus.png";
import img_basic from "./img/membersince_basic.png";

export default class MembersinceQuickStats extends React.Component {

  static propTypes = {
    speaker: PropTypes.object.isRequired
  }

  state = {
    year: null,
    img: img_basic
  }

  static getDerivedStateFromProps = (props) => ({
    version: _.get(props.speaker, ["member_since", "package"], 7),
    year: _.get(props.speaker, ["member_since", "year"]),
    img: _.get(props.speaker, ["member_since", "package"], 7) === 5
        ? img_pro
        : (_.get(props.speaker, ["member_since", "package"], 7) === 6
            ? img_plus
            : img_basic
        )
  })

  render() {
    let {
      version,
      year,
      img
    } = this.state;

    if (version === 7) {
      // don't show anything on a BASIC account
      return null;
    }

    return (
      <div className="quickStatsBox">
        <div style={{ backgroundImage:`url(${img})`, backgroundSize:"contain", backgroundRepeat:"no-repeat", position:"relative", width:"14rem", marginLeft:"auto", marginRight:"auto", height:"5.25rem"}}>
          <div style={{position:"absolute", transform:"rotate(0deg)", top:"2.0rem", left:"5.6rem", fontWeight:"bold", fontSize:"2.1rem"}}>{year}</div>
        </div>
      </div>
    );
  }
}