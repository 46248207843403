import _ from "lodash";
import React from "react";
import Promise from "es6-promise";
import { connect } from "react-redux";
import { Prompt, Redirect, withRouter } from "react-router";
import { Field, Form, formValueSelector, reduxForm, SubmissionError, submit } from "redux-form";
import { gettext as __ } from "../../../../utils/Trans";
import CookiePostAJob from "../../../../utils/CookiePostAJob";
import { DateRangePickerField, EmailConsent, Location, Message, Text } from "../../../../components/FormComponents";
import { Alert } from "react-bootstrap";
import PostAJob_SavePendingJob from "../../../../actions/PostAJobPage/PostAJob_SavePendingJob";
import Analytics from "../../../../utils/Analytics";
import moment from "moment/moment";
import StepNumber from "../../../../components/StepNumber";
import Constants from "../../../../utils/Constants";
import JobBoardModal from "../../modals/JobBoardModal";
import JobPublicDetailsModal from "../../modals/JobPublicDetailsModal";
import JobConfirmModal from "../../modals/JobConfirmModal";
import isValidEmail from "../../../../utils/isValidEmail";

require("./PostAffiliateJobForm.css");

class PostAffiliateJobForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            min_date: moment().subtract(1, "d").format("MM/DD/Y"),
            is_ready_to_submit: false,
            show_job_board_modal: false,
            show_confirmation_modal: false,
            show_job_details_modal: false
        };
    }

    handleSubmit = (values, dispatch) => {
        Analytics.sendEvent("Private Job", "submit", 0, "");
        return new Promise((resolve) => {
            let params = _.mapValues(values, function (item) {
                return _.get(item, ["value"], item);
            });
            resolve(dispatch(PostAJob_SavePendingJob(params)));
        }).then((response) => {
            this.setState({
                is_ready_to_submit: true
            });
            return response;
        }).catch((err) => {
            throw new SubmissionError({_error: err});
        });
    }

    __onLocationChange = (location) => {
        this.props.change("city", _.get(location, ["city"]));
        this.props.change("state", _.get(location, ["state"]));
        this.props.change("country_short", _.get(location, ["country_short"]));
    }

    __onChangeDate = (e, value) => {
        if (_.isFunction(this.props.onChangeDate)) {
            return this.props.onChangeDate(value);
        }
    }

    __onToggleJobBoardModal = () => {
        this.setState({
            show_job_details_modal: false,
            show_confirmation_modal: false,
            show_job_board_modal: !this.state.show_job_board_modal
        });
    }

    __onToggleJobDetailsModal = () => {
        this.setState({
            show_job_board_modal: false,
            show_confirmation_modal: false,
            show_job_details_modal: !this.state.show_job_details_modal
        });
    }

    __onToggleConfirmationModal = () => {
        //skip the confirmation modal. Take the code out later if we decide this is permanent
        return this.props.onSubmit();


        // this.setState({
        //     show_job_board_modal: false,
        //     show_job_details_modal: false,
        //     show_confirmation_modal: !this.state.show_confirmation_modal
        // });
    }

    __onToggleOffsite = (e) => {
        if (_.get(e, ["currentTarget", "checked"])) {
            this.props.change("delivery", 2);
        } else {
            this.props.change("delivery", 1);
        }
    }

    render() {
        let {
            handleSubmit,
            submitSucceeded,
            submitting,
            error,
            dirty,
            affiliate_event,
            location,
            topics,
            presenter_types,
            budget_ranges,
            is_onsite,
            stepnumberstart = 1,
            hide_paj_modal
        } = this.props;

        return (
            <Form className="MP-PostAffiliateJobForm mp-style-form" onSubmit={handleSubmit(this.handleSubmit)}>
                <Prompt
                    when={!this.state.is_ready_to_submit && dirty}
                    message={location =>
                        __("Are you sure you want to leave before posting your job?")
                    }
                />

                <div>
                    <StepNumber n={stepnumberstart}>
                        <h3 className="text-left text-success">
                            {__("Confirm session location")}
                        </h3>
                    </StepNumber>
                    <Alert bsStyle="info">{__("A few details about your meeting will help these presenters provide a useful response to your inquiry.")}</Alert>
                </div>

                <div className="row">
                    <div className="col-sm-7 col-xs-12">
                        <Field
                            name="name"
                            className="form-control"
                            label={__("Speaking To")}
                            placeholder={__("Annual Training Conference")}
                            component={Text}
                        />
                    </div>
                    <div className="col-sm-5 col-xs-12">
                        <Field
                            name="meeting_name"
                            className="form-control"
                            label={__("Session")}
                            placeholder={__("Opening Keynote")}
                            component={Text}
                        />
                    </div>
                </div>


                <div className="row">
                    <div className="col-xs-12 col-sm-7">
                        <div className="row">
                            <div className="col-xs-12 col-sm-10">
                                <Field
                                    name="location"
                                    className="form-control"
                                    label={__("Location")}
                                    component={Location}
                                    disabled={!is_onsite}
                                    onChange={this.__onLocationChange}
                                />
                            </div>
                        </div>
                        <label>
                            <Field
                                name="offsite"
                                component="input"
                                type="checkbox"
                                value={2}
                                onChange={this.__onToggleOffsite}
                            />
                            {" "}
                            {__("My event will be done via phone or video-conference.")}
                        </label>
                    </div>
                    <div className="col-xs-12 col-sm-5">
                        <Field
                            name="startdate"
                            className="form-control"
                            label={__("Event Date")}
                            type="text"
                            ranges={false}
                            autoApply={true}
                            opens="left"
                            component={DateRangePickerField}
                            onChange={this.__onChangeDate}
                            help_text={<span className="small">{__("You can always finalize your dates after inquiring.")}</span>}
                        />
                    </div>
                </div>

                <StepNumber n={++stepnumberstart}>
                    <h3 className="text-success text-left">
                        {__("Invitation message from you")}
                    </h3>
                </StepNumber>

                <Field
                    name="message"
                    className="form-control"
                    rows={6}
                    component={Message}
                />
                <StepNumber n={++stepnumberstart}>
                    <h3 className="text-success text-left">
                        {__("Confirm your information")}
                    </h3>
                </StepNumber>
                <div className="row">
                    <div className="col-sm-7 col-xs-12">
                        <Field
                            name="email"
                            className="form-control"
                            label={__("Email Address")}
                            placeholder={__("user@company.com")}
                            component={Text}
                        />
                    </div>
                </div>
                <label>
                    <Field
                        label={__("I agree to receive email related to my event and to this service.")}
                        name="email_consent"
                        type="checkbox"
                        component={EmailConsent}
                    />
                </label>

                <div className="form-group text-center" style={{margin:"3em 0"}}>
                    <button type="button" className="hidden-sm hidden-xs btn btn-success btn-lg" disabled={submitting || (_.size(_.get(this.props, "shortlist_sids")) === 0)} onClick={hide_paj_modal ? this.__onToggleConfirmationModal : this.__onToggleJobBoardModal}>
                        {submitting ? <i className="fa fa-spin fa-circle-o-notch"/> : <i/>} {__("REQUEST FREE INFORMATION FROM %s %s", [_.size(_.get(this.props, "shortlist_sids")), (_.size(_.get(this.props, "shortlist_sids")) === 1 ? "SPEAKER" : "SPEAKERS")])}
                    </button>
                    <button type="button" className="visible-sm visible-xs hidden-md btn btn-success" disabled={submitting || (_.size(_.get(this.props, "shortlist_sids")) === 0)} onClick={hide_paj_modal ? this.__onToggleConfirmationModal : this.__onToggleJobBoardModal}>
                        {submitting ? <i className="fa fa-spin fa-circle-o-notch"/> : <i/>} {__("REQUEST FREE INFORMATION")}
                    </button>
                    {submitSucceeded && <span className="text-success"> <i className="fa fa-check"/> {__("Sending your inquiry to the speakers...")}</span>}
                    {this.state.is_ready_to_submit && submitSucceeded &&
                    <Redirect to={{
                        pathname: "/collab/postajob/affiliate",
                        state: {
                            redirect: "/collab/postajob/affiliate",
                            onboard: "affiliate",
                            btst: "BACK TO INQUIRY",
                            bts: "/collab/affiliate-paj/" + _.get(affiliate_event, ["id"]) + _.get(location, ["search"])
                        }
                    }} />
                    }
                    {error && <span className="text-danger"> <i className="fa fa-times"/> {_.get(error, ["message"])}</span>}
                </div>
                {this.state.show_job_board_modal && <JobBoardModal onShowJobDetailsModal={this.__onToggleJobDetailsModal} onShowJobConfirmModal={this.__onToggleConfirmationModal} onHide={this.__onToggleJobBoardModal} />}
                {this.state.show_job_details_modal && <JobPublicDetailsModal topics={topics} budget_ranges={budget_ranges} presenter_types={presenter_types} onShowJobBoardModal={this.__onToggleJobBoardModal} onShowJobConfirmModal={this.__onToggleConfirmationModal} onHide={this.__onToggleJobBoardModal} />}
                {this.state.show_confirmation_modal && <JobConfirmModal submitting={submitting} onSubmit={this.props.onSubmit} onHide={this.__onToggleConfirmationModal} />}
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {

    // Pull out the pending job from the localStorage or Cookie (private browsing)
    let pending_job = CookiePostAJob.get();

    // Selectors to filter the form
    let selector = formValueSelector("postanaffiliatejob");
    let selected_delivery_method = _.toNumber(selector(state, "delivery")); // used to show/hide the location

    // Page Properties
    let hide_paj_modal = (_.get(ownProps.whitelabel, ["mp_keyvalues", "MP_DISABLE_PUBLIC_JOB_BOARD"], false) || _.get(ownProps.whitelabel, ["mp_keyvalues", "MP_PAJ_HIDE_PUBLIC_JOB_BOARD"], false));
    let topics = _.get(ownProps.sitelists, ["topics"]);
    let deliverymethods = _.get(ownProps.sitelists, ["universal", "deliverymethods"]);
    let presenter_types = _.get(ownProps.sitelists, ["universal", "presenter_types"]);
    let budget_ranges = _.get(ownProps.sitelists, ["feeranges"]);
    return {
        is_loading: _.isUndefined(deliverymethods) || _.isUndefined(presenter_types) || _.isUndefined(topics) || _.isUndefined(budget_ranges),
        hide_paj_modal: hide_paj_modal,
        topics: topics,
        deliverymethods: deliverymethods,
        presenter_types: presenter_types,
        budget_ranges: budget_ranges,
        is_onsite: (selected_delivery_method === 1), // on-site
        initialValues: {
            date_searched: _.get(pending_job, ["date_searched"], false),
            offsite: (_.get(pending_job, ["delivery"], _.get(ownProps.affiliate_event, ["delivery_method"], 1)) !== 1),
            shortlist_sids: _.get(pending_job, ["shortlist_sids"], _.map(_.get(ownProps.affiliate_event, ["shortlist", "speakers"]), (spkr) => {
                return _.get(spkr, ["speaker_id"]);
            })),
            eid: -1,
            bureau_id: _.get(pending_job, ["bid"], _.get(ownProps.affiliate_event, ["bureau_id"], _.get(ownProps.whitelabel, ["bid"], Constants.TRUNK_BUREAU_ID))),
            name: _.get(pending_job, ["name"], _.get(ownProps.affiliate_event, ["organization"], null)),
            meeting_name: _.get(pending_job, ["meeting_name"], _.get(ownProps.affiliate_event, ["meeting_name"], null)),
            startdate: _.get(pending_job, ["date_range"], moment(_.get(ownProps.affiliate_event, ["startdate"], moment().format("MM/DD/YYYY")), "MM/DD/YYYY").format("MM/DD/YYYY")),
            date_tentative: false, // TODO get from event object
            delivery: _.get(pending_job, ["delivery"], _.get(ownProps.affiliate_event, ["delivery_method"], 1)),
            city: _.get(pending_job, ["city"], _.get(ownProps.affiliate_event, ["city"])),
            state: _.get(pending_job, ["state"], _.get(ownProps.affiliate_event, ["state"])),
            country: _.get(pending_job, ["country"], _.get(ownProps.affiliate_event, ["country"], "US")),
            budget: _.get(pending_job, ["budget"], ("range_" + _.get(ownProps.affiliate_event, ["budget"]))),
            location: _.get(_.get(pending_job, ["location"], _.get(ownProps.affiliate_event, ["location"])), ["location"], _.get(pending_job, ["location"], _.get(ownProps.affiliate_event, ["location"]))),
            topic: _.get(pending_job, ["topic"], _.get(ownProps.affiliate_event, ["topic_ids"])),
            language: _.get(pending_job, ["language"], "en"),
            presenter_type: _.get(pending_job, ["presenter_type"], _.get(ownProps.affiliate_event, ["presenter_type"], 1)),
            meeting_goals: _.get(pending_job, ["meeting_goals"], _.get(ownProps.affiliate_event, ["meeting_goals"])),
            message: _.get(pending_job, ["message"], __("Hello,") + "\n\n" + __("I'd like to invite you to apply for my speaking engagement.  Please review these details and let me know if you are available and would be a good match for our needs.")),
            post_public: _.get(ownProps.affiliate_event, ["job_board"]), // if we are hiding the job board, don't post to it by default
            affiliate_event_id: _.get(ownProps.affiliate_event, ["id"]),
            email: _.get(ownProps.affiliate_event, ["lead", "email"]),
            email_consent: true
        }
    };
};

var mapDispatchToProps = function (dispatch, ownProps) {
    return {
        onSubmit: function() {
            return dispatch(submit("postanaffiliatejob"))
        }
    };
};

PostAffiliateJobForm = reduxForm({
    form: "postanaffiliatejob",
    validate: function (values) {
        const errors = {};
        if (!values.name) {
            errors.name = __("Please enter a name for your event.");
        }
        if (!values.meeting_name) {
            errors.meeting_name = __("Please enter your session name");
        }
        if (!values.message) {
            errors.message = __("Please enter a message to send to the speakers");
        }
        if (!values.startdate) {
            errors.startdate = __("Please enter a date for your event.");
        }
        if (!moment(values.startdate, "MM/DD/YYYY").isValid()) {
            errors.startdate = __("Please enter a date for your event.");
        }
        if (values.delivery === "1") {
            if (!values.city) {
                errors.city = __("Please enter a city.");
            }
            if (!values.country) {
                errors.country = __("Please enter a country.");
            }
        }
        if (!values.email) {
            errors.email = __("Please enter your email address.");
        }
        if (!isValidEmail(values.email)) {
            errors.email = __("Please enter a valid email address.");
        }
        if (!values.email_consent) {
            errors.email_consent = __("Email communication is an essential part of hiring a speaker. Please consent to receive email to continue.");
        }

        return errors;
    }
})(PostAffiliateJobForm);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostAffiliateJobForm));
