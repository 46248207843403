import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import ThumbnailSquare from "../ThumbnailSquare";
import Speaker from "../../actions/Speaker/Speaker";

class SpeakerThumbnail extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (_.isUndefined(this.props.speaker)) {
            this.props.loadData();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (_.isUndefined(nextProps.speaker) && (nextProps.sid !== this.props.sid)) {
            nextProps.loadData();
        }
    }

    render() {
        let {
            speaker,
            dont_link_to_profile,
            dont_show_name_on_hover,
            inline = false,
            ...props
        } = this.props;

        let is_loading = _.isUndefined(speaker);

        return (
            <ThumbnailSquare
                loading={is_loading}
                tooltip={dont_show_name_on_hover ? null : _.get(speaker, ["speakername"])}
                href={dont_link_to_profile ? null : _.get(speaker, ["url"])}
                img_url={_.get(speaker, ["thumb_url"], _.get(speaker, ["mainPhotoURL"]))}
                inline={inline}
                {...props}
            />
        );
    }
}

SpeakerThumbnail.propTypes = {
    speaker: PropTypes.object,
    sid: PropTypes.number,
    dont_show_name_on_hover: PropTypes.bool,
    dont_link_to_profile: PropTypes.bool,
    inline: PropTypes.bool,
    //These get passed onto ThumbnailSquare
    size: PropTypes.number,
    style: PropTypes.object,
    className: PropTypes.string,
    n_unread: PropTypes.number,
    has_offer: PropTypes.bool,
    has_offer_accepted: PropTypes.bool
};

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function(state, ownProps){
    let speaker = ownProps.speaker;
    if (!speaker) {
        speaker = _.get(state, ["Speakers", _.get(ownProps.speaker, ["sid"], ownProps.sid)]);
    }
    return {
        speaker: speaker
    };
};

var mapDispatchToProps = function(dispatch, ownProps){
    return {
        loadData: function() {
            if (_.isUndefined(ownProps.speaker) && !_.isUndefined(ownProps.sid)) {
                return dispatch(Speaker.get(ownProps.sid));
            } else if (!_.isUndefined(ownProps.speaker) && !_.has(ownProps.speaker, ["thumb_url"]) && !_.has(ownProps.speaker, ["mainPhotoURL"])) {
                return dispatch(Speaker.get(_.get(ownProps.speaker, ["sid"], ownProps.sid)));
            }
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpeakerThumbnail);
