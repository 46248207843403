import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../utils/Trans";
import HelpTooltip from "../HelpTooltip";

export default class SpeakerReview extends React.Component {
  
  state = {}
  
  static propTypes = {
    review: PropTypes.object.isRequired
  }
  
  static getDerivedStateFromProps = (props) => ({
    review: props.review,
    is_marketplace_job: _.includes(_.get(props.review, ["flags_arrayed"]), "MP_VERIFIED"),
    is_marketplace_verified: _.includes(_.get(props.review, ["flags_arrayed"]), "MARKETPLACE_VERIFIED"),
    is_verified: _.includes(_.get(props.review, ["flags_arrayed"]), "VERIFIED"),
    is_virtual: _.includes(_.get(props.review, ["flags_arrayed"]), "VIRTUAL_EVENT"),
    would_not_hire_again: _.includes(_.get(props.review, ["flags_arrayed"]), "MP_WOULD_NOT_HIRE_AGAIN"),
    verified_class: _.includes(_.get(props.review, ["flags_arrayed"]), "MARKETPLACE_VERIFIED") ? "marketplaceVerified" : _.includes(_.get(props.review, ["flags_arrayed"]), "VERIFIED") ? "externalVerified" : "unverified",
    recommender_name: _.get(props.review, ["initials"]),
    recommender_email: _.get(props.review, ["email"]),
    recommender_domain: "@" + _.last(_.split(_.get(props.review, ["email"]), "@"))
  })
  
  render() {
    let {
      review,
      verified_class,
      would_not_hire_again,
      is_marketplace_job,
      recommender_name,
      recommender_email,
      recommender_domain,
      is_verified,
      is_virtual
    } = this.state;
  
    let review_html = <span>{recommender_name}</span>;
    if (is_verified && (recommender_email !== "")) {
      review_html = (
            <span>
                <span className="fa fa-check externalVerified" />{" "}
                <span>{recommender_name}{" "}
                  <span className="verified_domain">({__("Verified %s", recommender_domain)})</span>
                  {" "}
                  <HelpTooltip
                    title={__("Verified Reviewer")}
                    content={__("We confirmed this review comes from a person with an email address %s.", recommender_domain)}
                    placement="right"
                    trigger={["focus","hover"]}
                  />
                </span>
            </span>
      );
    }
    
    return (
      <React.Fragment>
        <div className={"singleReviewBox clearfix langsensitive " + verified_class} data-lang={_.get(review, ["lang"])}>
          <div className="padWrap">
            <div className="topInfo">
              <span className={would_not_hire_again ? "fa fa-thumbs-down fa-2x text-success" : "fa fa-thumbs-up fa-2x text-success"}></span>
            </div>
            <p className="recommendation">{_.get(review, ["testimony"])}</p>
            {is_marketplace_job && <p className="marketplaceReview"><span className="fa fa-check"></span> {__("Hired Through Marketplace")}</p>}
            <div className="reviewer">
              {review_html}
            </div>
            {is_virtual && <div><i className="small text-muted"><i className={"fa fa-desktop"} />{" "}This event was presented virtually</i></div>}
          </div>
        </div>
      </React.Fragment>
    );
  }
  
}