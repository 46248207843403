import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import SpeakerAddress from "../../../../components/SpeakerAddress";
import SpeakerThumbnail from "../SpeakerThumbnail";
import { gettext as __ } from "../../../../utils/Trans";

class SpeakerContact extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speaker
        } = this.props;

        let speaker_name = _.get(speaker, ["name"]);
        let speaker_email =  _.get(speaker, ["primaryemail"]);
        let speaker_phone =  _.get(speaker, ["phone"]);

        return (
            <div>
                <div className="row">
                    <div className="col-sm-3 col-md-3 text-center">
                        <SpeakerThumbnail
                            speaker={speaker}
                            size={123}
                            dont_show_name_on_hover={true}
                            className="img-responsive"
                        />
                    </div>
                    <div className="col-sm-9 col-md-9">
                        <h3 style={{margin: "0 0 10px 0", fontSize: "18px", letterSpacing: 0}}>{__("Best Ways To Contact")}{" "}{speaker_name}</h3>
                        <SpeakerAddress
                            address={_.get(speaker, ["address"])}
                            city={_.get(speaker, ["city"])}
                            state={_.get(speaker, ["state"])}
                            zip={_.get(speaker, ["zip"])}
                            country={_.get(speaker, ["country"])}
                        />
                        <p />
                        <div><i className="fa fa-envelope-square"></i>{" "}{__("Email")}: <a href={"mailto:" + speaker_email}>{speaker_email}</a></div>
                        <div><i className="fa fa-phone-square"></i>{" "}{__("Phone")}: <a href={"tel:" + speaker_phone}>{speaker_phone}</a></div>
                    </div>
                </div>
            </div>
        );
    }
}

SpeakerContact.propTypes = {
    speaker: PropTypes.object
};

export default SpeakerContact;