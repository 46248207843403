import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { AsyncCreatable } from "react-select";
import balboa3 from "../../utils/Balboa3";
import { gettext as __ } from "../../utils/Trans";
import debounce from "debounce-promise";
import Constants from "../../utils/Constants";

import "./MarketplaceSearch.scss";

class MarketplaceSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            is_open: false,
            search: null,
            options: [],
            is_searching: false,
            throttle_fn: null,
            inputValue: ''
        };
    }

    __handleBlur = (event) => {
        if (this.selector && this.selector.select && this.selector.select.state.inputValue && _.isFunction(this.selector.select.selectValue)) {
          this.selector.select.selectValue({
            label: this.selector.select.state.inputValue,
            value: ("free_" + this.selector.select.state.inputValue),
            type: "freeform"
          });
        }
        event.stopPropagation();
    }

    __onDoSearch = debounce((search) => {
        if (search === "") {
            return new Promise((resolve) => {
                return resolve({options: []});
            });
        }

        return balboa3({
            // base_url: "https://local.espeakers.com/balboa3" /* uncomment for storybook testing */
        }).get("/marketplace/search", {
            bureau_id: _.get(this.props.whitelabel, ["bid"], Constants.TRUNK_BUREAU_ID),
            q: search
        })
            .then((response) => {
                let events = [];
                let speakers = [];
                let topics = [];
                let industries = [];
                let presenters = [];
                let chapters = [];
                let cmp_programs = [];
                if (true) {
                    industries = _.map(_.get(response, ["data", "industries"], []), function (item) {
                        return {
                            label: _.get(item, ["label"]),
                            type: "industry",
                            value: "industry_" + _.toString(_.get(item, ["value"]))
                        };
                    });
                }
                if (true) {
                    topics = _.map(_.get(response, ["data", "topics"], []), function (item) {
                        return {
                            label: _.get(item, ["label"]),
                            type: "topic",
                            value: "topic_" + _.toString(_.get(item, ["value"]))
                        };
                    });
                }
                if (true) {
                    speakers = _.map(_.get(response, ["data", "speakers"], []), function (item) {
                        return {
                            label: _.get(item, ["label"]),
                            type: "speaker",
                            value: "sid_" + _.toString(_.get(item, ["value"]))
                        };
                    });
                }
                if (true) {
                    presenters = _.map(_.get(response, ["data", "presenters"], []), function (item) {
                        return {
                            label: _.get(item, ["label"]),
                            type: "presenter",
                            value: "pres_" + _.toString(_.get(item, ["value"]))
                        };
                    });
                }
                if (true) {
                    chapters = _.map(_.get(response, ["data", "chapters"], []), function (item) {
                        if (!_.includes(_.get(item, ["label"]), "Chapter")) {
                            return {
                                label: _.get(item, ["label"]) + " " + __("Chapter"),
                                type: "chapter",
                                value: "ch_" + _.toString(_.get(item, ["value"]))
                            };
                        } else {
                            return {
                                label: _.get(item, ["label"]),
                                type: "chapter",
                                value: "ch_" + _.toString(_.get(item, ["value"]))
                            };
                        }
                    });
                }
                if (true) {
                    cmp_programs = _.map(_.get(response, ["data", "cmp_programs"], []), function (item) {
                        return {
                            label: _.get(item, ["label"]),
                            type: "cmp_program",
                            value: "cmp_" + _.toString(_.get(item, ["value"]))
                        };
                    });
                }
                let options = _.concat(
                    topics,
                    speakers,
                    events,
                    presenters,
                    industries,
                    chapters,
                    cmp_programs
                );
                return {
                    options: options
                };
            });
    }, 500)

    render() {
        let {
            input,
            style              = {},
            containerClassName = "",
            className          = "",
            arrowRenderer,
            placeholder,
            button             = null,
            optionRenderer,
            autoload           = false,
            autosize           = false,
            multi              = true,
            large              = false,
            search_prefix,
            onChange,
            onSelect
        } = this.props;

        if (!arrowRenderer) {
            arrowRenderer = () => <span/>;
        }

        if (!placeholder) {
            placeholder = <span><i className="fa fa-search fa-fw" style={{display: "inline-block", marginRight: "1em"}}/>{" "}{placeholder}</span>;
        }

        if (!optionRenderer) {
            optionRenderer = (option) => {
                if (_.get(option, ["type"]) === "topic") {
                    return (
                        <div key={_.get(option, ["value"])}>
                            <span className="text-muted">{__("Speaks on")}{" "}</span><b>{_.get(option, ["label"])}</b>
                        </div>
                    );
                } else if (_.get(option, ["type"]) === "industry") {
                    return (
                        <div key={_.get(option, ["value"])}>
                            <span className="text-muted">{__("Experience in")}{" "}</span><b>{_.get(option, ["label"])}</b>
                        </div>
                    );
                } else if (_.get(option, ["type"]) === "presenter") {
                    return (
                        <div key={_.get(option, ["value"])}>
                            <span className="text-muted">{__("Presents as a")}{" "}</span><b>{_.get(option, ["label"])}</b>
                        </div>
                    );
                } else if (_.get(option, ["type"]) === "chapter") {
                    return (
                        <div key={_.get(option, ["value"])}>
                            <b>{_.get(option, ["label"])}</b>
                        </div>
                    );
                } else if (_.get(option, ["type"]) === "cmp_program") {
                    return (
                        <div key={_.get(option, ["value"])}>
                            <b>{_.get(option, ["label"])}</b>
                        </div>
                    );
                } else {
                    return (
                        <div key={_.get(option, ["value"])}>
                            {_.get(option, ["label"])}
                        </div>
                    );
                }
            };
        }

        if (!search_prefix) {
            search_prefix = __("Search for");
        }

        if (!onChange) {
            onChange = input.onChange;
        }

        if (!onSelect) {
            onSelect = input.onChange;
        }

        let default_style = {
            textAlign: "left"
        };

        return (
            <div className={containerClassName}>
                <AsyncCreatable
                    {...input}
                    arrowRenderer={arrowRenderer}
                    placeholder={placeholder}
                    labelKey="label"
                    valueKey="value"
                    autoload={autoload}
                    autosize={autosize}
                    multi={multi}
                    loadOptions={this.__onDoSearch}
                    selectValue={onSelect}
                    onChange={onChange}
                    filterOptions={(options, filter, currentValues) => {
                        // Do no filtering, just return all options
                        return options.filter((o) => {
                            return !/search_prefix/i.test(o.value) && (_.findIndex(currentValues, {value: o.value}) === -1);
                        });
                    }}
                    clearable={false}
                    onBlurResetsInput={false}
                    onCloseResetsInput={false}
                    onBlur={this.__handleBlur}
                    optionRenderer={optionRenderer}
                    className={className + " " + (large ? "input-lg" : "")}
                    promptTextCreator={(label) => {
                        return (search_prefix + " \"" + label + "\"");
                    }}
                    style={_.assign({}, default_style, style)}
                    newOptionCreator={({label}) => {
                        let option = {
                            label: label,
                            value: ("free_" + label),
                            type: "freeform",
                        };
                        return option;
                    }}
                    ref={s => this.selector = s}
                />
                {button}
            </div>
        );
    }

}

MarketplaceSearch.propTypes = {
    input: PropTypes.object.isRequired,
    style: PropTypes.object,
    containerClassName: PropTypes.string,
    className: PropTypes.string,
    arrowRenderer: PropTypes.func,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    button: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    optionRenderer: PropTypes.func,
    autoload: PropTypes.bool,
    autosize: PropTypes.bool,
    multi: PropTypes.bool,
    cache: PropTypes.oneOfType([PropTypes.bool, PropTypes.array]),
    large: PropTypes.bool,
    search_prefix: PropTypes.string,
    onChange: PropTypes.func,
    onSelect: PropTypes.func
};

export default MarketplaceSearch;