import React from "react";
import { gettext as __ } from "../../utils/Trans";
import EventsBooked from "./components/EventsBooked";

import "./AboutUsPage.scss";

export class AboutUsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        window.scrollTo(0, 0);
    }

    onClickQuestion = (e) => {
        var d = document.getElementById(e.target.dataset.link);
        if (d) {
            d.scrollIntoView();
        }
    }

    render() {
        return (
            <div className="about-us-page">
                <h1>{__("ABOUT ESPEAKERS MARKETPLACE")}</h1>
                <div className="row">
                    <div className="col-md-9">
                        <ul style={{fontSize:"13pt", listStyle: "none", margin: "0px", padding: "0px", lineHeight: "26pt"}}>
                            <li><span className={"link"} data-link="faq_1" onClick={this.onClickQuestion}>{__("WHAT'S ESPEAKERS MARKETPLACE ALL ABOUT?")}</span></li>
                            <li><span className={"link"} data-link="faq_2" onClick={this.onClickQuestion}>{__("WHO'S BEHIND ESPEAKERS?")}</span></li>
                            <li><span className={"link"} data-link="faq_3" onClick={this.onClickQuestion}>{__("HOW DOES ESPEAKERS HAVE SUCH A BIG CATALOG?")}</span></li>
                            <li><span className={"link"} data-link="faq_4" onClick={this.onClickQuestion}>{__("I'M A SPEAKER...HOW CAN I GET LISTED AND HIRED ON YOUR SITE?")}</span></li>
                            <li><span className={"link"} data-link="faq_5" onClick={this.onClickQuestion}>{__("HOW DOES ESPEAKERS MAKE MONEY?")}</span></li>
                            <li><span className={"link"} data-link="faq_6" onClick={this.onClickQuestion}>{__("HOW CAN I TALK TO SOMEONE AT ESPEAKERS?")}</span></li>
                        </ul>
                        <hr />
                        <div>
                            <div id="faq_1">
                                <h4>Q: {__("WHAT'S ESPEAKERS MARKETPLACE ALL ABOUT?")}</h4>
                                <div>
                                    <span style={{fontSize:"1.3em", fontWeight:"bold"}}>A:</span> {__("Here at eSpeakers, we believe that the right speaker in front of the right audience can bring real change. We want to make it easy for event organizers to find and hire the perfect speaker every time.  eSpeakers Marketplace is the spot where people who hire speakers access a huge catalog of the best speakers in the world, find the perfect match for their event, connect with the speaker, and hire them — all in a safe environment that takes the risk out of such a significant investment.")}
                                </div>
                            </div>
                            <hr />
                            <div id="faq_2">
                                <h4>Q: {__("WHO'S BEHIND ESPEAKERS?")}</h4>
                                <div>
                                    <span style={{fontSize:"1.3em", fontWeight:"bold"}}>A:</span> {__("eSpeakers was started in 1999 when Art Berg (a professional speaker) and Dave Reed (a tech-savvy entrepreneur) joined forces to create Fortune 500-quality tools for the speaking industry.")}
                                    <p>{__("When Art passed away in 2002, Joe Heaps came on board to oversee marketing and sales.")}</p>
                                    <p>{__("eSpeakers benefits from an extraordinary advisory board of long-time industry professionals. Their guidance is invaluable as the company seeks to navigate the changing waters of the speaking industry and the changing tides of the economy, and also to be an agent of that change by distilling best practices into simple web sites that bring those benefits to everyone.")}</p>
                                </div>
                            </div>
                            <hr />
                            <div id="faq_3">
                                <h4>Q: {__("HOW DOES ESPEAKERS HAVE SUCH A BIG CATALOG?")}</h4>
                                <div>
                                    <span style={{fontSize:"1.3em", fontWeight:"bold"}}>A:</span> {__("More than a decade in the business has brought us a lot of partners who bring qualified, professional speakers into the eSpeakers directory:")}
                                    <ul>
                                        <li>Global Speakers Federation</li>
                                        <li>Canadian Association of Professional Speakers</li>
                                        <li>Meeting Professionals International</li>
                                        <li>National Speakers Association (US)</li>
                                        <li>{__("many leading speakers bureaus")}</li>
                                        <li>{__("and many more")}...</li>
                                    </ul>
                                    <p>{__("These relationships provide a steady inflow of the best emerging talent as well as the most experienced pros. They also provide a natural filtering mechanism that brings in professionals who are serious about their business and aren't just hobbyists.")}</p>
                                </div>
                            </div>
                            <hr />
                            <div id="faq_4">
                                <h4>Q: {__("I'M A SPEAKER...HOW CAN I GET LISTED AND HIRED ON YOUR SITE?")}</h4>
                                <div>
                                    <span style={{fontSize:"1.3em", fontWeight:"bold"}}>A:</span> {__("If you're a member of a national speakers association, a basic profile is likely included as a part of your membership.")}
                                    <p>{__("Otherwise,")}{" "}<a href="/speakers/plans-pricing/">{__("click here")}</a>{" "}{__("to get your own eSpeakers account, fill out your profile, and become available!")}</p>
                                </div>
                            </div>
                            <hr />
                            <div id="faq_5">
                                <h4>Q: {__("HOW DOES ESPEAKERS MAKE MONEY?")}</h4>
                                <div>
                                    <span style={{fontSize:"1.3em", fontWeight:"bold"}}>A:</span> {__("eSpeakers Marketplace is completely free to the event planners who hire speakers / trainers / coaches through the system. So how do we keep the lights on?")}
                                    <p>{__("eSpeakers invoices the supplier (speaker/trainer/coach) for a small percentage of the transaction fee when they're hired through eSpeakers Marketplace.")}</p>
                                    <p>{__("The professionals in our catalog love getting work through the eSpeakers Marketplace and find the transaction fee a great value for the ease and security it brings to the hiring process.")}</p>
                                </div>
                            </div>
                            <hr />
                            <div id="faq_6">
                                <h4>Q: {__("HOW CAN I TALK TO SOMEONE AT ESPEAKERS?")}</h4>
                                <div>
                                    <span style={{fontSize:"1.3em", fontWeight:"bold"}}>A:</span> {__("We'd love to hear from you. This")}{" "}<a href="/about-us/contact/">{__("contact page")}</a>{" "}{__("has our email address and phone number on it.")}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3">
                        <EventsBooked />
                        <blockquote className="text-muted">
                            &quot;
                            {__("With eSpeakers, I found two great speakers who supported our professional development message. The amazing local search helped me find many speakers in our price range. It was hard for the committee to choose only two.")}
                            &quot;
                            <footer>Andrea C, <cite>Utah State Office of Education</cite></footer>
                        </blockquote>
                    </div>
                </div>
            </div>
        );
    }

}

export default AboutUsPage;