import _ from "lodash";
import React from "react";
// import { Link } from "react-router-dom";
import SpeakerTile from "../../components/SpeakerTile";
import { gettext as __ } from "../../utils/Trans";

export default class TrendingResults extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speakers,
            shortlist,
            awards,
            languages,
            is_debug = false,
            onProfile,
            onFavorite,
            onViewPrograms,
            onViewSchedule,
            onWatchVideo
        } = this.props;


        let results = _.map(speakers, function(speaker, i) {

            return (
                <div key={i} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                    <SpeakerTile
                        awards={awards}
                        languages={languages}
                        speaker={speaker}
                        onProfile={onProfile}
                        onViewPrograms={onViewPrograms}
                        onViewSchedule={onViewSchedule}
                        onWatchVideo={onWatchVideo}
                        onFavorite={onFavorite}
                        is_debug={is_debug}
                        is_shortlisted={(_.indexOf(shortlist, _.get(speaker, ["sid"])) >= 0)}
                    />
                </div>
            );
        });

        if (_.isEmpty(results)) {
            return (
                <div className="container search-page-results">
                    <div className="center-block well" style={{marginTop: "2em"}}>
                        <h4 className="text-center">{__("No matching speakers...")}</h4>
                        <div className="row">
                            <div className="col-xs-2">
                                <i className="fa fa-search-minus" style={{fontSize: "5em"}}></i>
                            </div>
                            <div className="col-xs-9 text-left">
                                <p>{__("Try adjusting the filters or broaden the search terms to find more speakers.")}</p>
                                <p>{__("Suggestions")}:</p>
                                <ul>
                                    <li>{__("Make sure words are spelled correctly")}</li>
                                    <li>{__("Try using fewer topics or keyword criteria")}</li>
                                    <li>{__("Try more general keywords")}</li>
                                </ul>
                                <p>{__("You can also just")} <a href="/marketplace/search">{" "}{__("show everyone")}{" "}<i className="fa fa-search-plus"/></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div className="container search-page-results">
                <div className="row speaker-tile-wrapper">
                    {results}
                </div>
            </div>
        );
    }
}