import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Field, Form, reduxForm } from "redux-form";
import { Modal } from "react-bootstrap";
import { gettext as __ } from "../../../../utils/Trans";
import LoadingSubmit from "../../../../components/LoadingSubmit";
import { Message, Rating } from "../../../../components/FormComponents";
import MpShortlistRating from "../../../../actions/ShortList/MpShortlistRating";

class SpeakerReviewForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch, props) {
        return dispatch(MpShortlistRating.save(values))
            .then((resp) => {
                return dispatch({
                    type: "AFFILIATE_EVENT_RATING_POST",
                    id: _.get(props.affiliate_event, ["id"]),
                    mp_shortlist_id: _.get(props.affiliate_event, ["mp_shortlist_id"]),
                    data: _.get(resp, ["data"])
                });
            })
            .then(() => {
                if (_.isFunction(props.onClose)) {
                    props.onClose();
                }
            });
    }

    render() {
        let {
            submitting,
            handleSubmit,
            speaker,
            onClose
        } = this.props;

        return (
            <Form onSubmit={handleSubmit(this.handleSubmit)}>
                <Modal.Body>
                    <div className="help-block" style={{marginBottom:"1em"}}>{__("Help you and your team select the best candidate. The speaker cannot see this.")}</div>
                    {/*<div className="help-block"><b>{__("Collaborator (You): ")}</b>{" "}{_.get(collaborator, ["email"])}</div>*/}
                    {/*<div className="help-block"><b>{__("Speaker: ")}</b>{" "}{_.get(speaker, ["name"])}</div>*/}
                    <Field
                        name="rating"
                        component={Rating}
                        size="fa-3x"
                        count={5}
                        label={__("Compared to other candidates, how much do you like %s for this session?", [_.get(speaker, ["name"])])}
                    />
                    <Field
                        name="comments"
                        component={Message}
                        rows={3}
                        label={__("Why?")}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <LoadingSubmit is_saving={submitting} save_text={__("Saving...")} className="btn-success btn-block-xs">
                        {__("Save Team Comment")}
                    </LoadingSubmit>
                    {" "}
                    <div className="btn btn-default btn-block-xs" disabled={submitting} onClick={onClose}>
                        {__("Cancel")}
                    </div>
                </Modal.Footer>
            </Form>

        );
    }
}

SpeakerReviewForm = reduxForm({
    form: "speaker_review_form"
})(SpeakerReviewForm);

function mapStateToProps(state, ownProps) {

    let ratings = _.keyBy(_.get(ownProps.shortlist, ["speakers", _.get(ownProps.speaker, ["sid"]), "ratings"]), "mp_collaborator_id");
    let review = _.get(ratings, [_.get(ownProps.collaborator, ["id"])]);

    return {
        initialValues: {
            mp_shortlist_id: _.get(ownProps.shortlist, ["id"]),
            mp_collaborator_id: _.get(ownProps.collaborator, ["id"]),
            mp_shortlist_speakers_id: _.get(ownProps.shortlist, ["speakers", _.get(ownProps.speaker, ["sid"]), "id"]),
            rating: _.get(review, ["rating"], 0),
            comments: _.get(review, ["comments"], ""),
        }
    };
}

function mapDispatchToProps(/*dispatch, ownProps*/) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SpeakerReviewForm);