import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import ShareShortlistModal from "../../modals/ShareShortlistModal";
import ActionButton from "../../../../components/ActionButton";

class ColleagueFeedbackButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_modal: false
        };
    }

    __toggleModal = () => {
        this.setState({
            show_modal: !this.state.show_modal
        });
    }

    render() {
        let {
            className = "btn btn-default btn-flat"
        } = this.props;

        return (
            <div>
                <ActionButton
                    icon="star"
                    btn_text={__("Colleague Feedback")}
                    help_text={__("Need some opinions? Send information about these candidates to your colleagues and ask for their feedback.")}
                    onClick={this.__toggleModal}
                    className={className}
                />
                {this.state.show_modal && <ShareShortlistModal {...this.props} onClose={this.__toggleModal} />}
            </div>
        );
    }
}

ColleagueFeedbackButton.propTypes = {
    event: PropTypes.object.isRequired,
    candidates: PropTypes.array,
    className: PropTypes.string
};

export default ColleagueFeedbackButton;