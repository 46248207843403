import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { MenuItem, OverlayTrigger } from "react-bootstrap";
import PopoverMenu from "../PopoverMenu";
import { gettext as __ } from "../../utils/Trans";
import formatFeeRange from "../../utils/formatFeeRange";
import HighlightTextMatches from "../../utils/HighlightTextMatches";
import Constants from "../../utils/Constants";
import classnames from "classnames";


import "./SpeakerTile.scss";

export default class SpeakerTile extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      is_shortlisted: props.is_shortlisted,
      show_seo_score: true
    };
  }

  componentDidUpdate(prevProps) {
    if (this.state.is_shortlisted !== this.props.is_shortlisted) {
      this.setState({
        is_shortlisted: this.props.is_shortlisted
      });
    }
  }

  onWatchVideo = () => {
    if (this.props.onWatchVideo) {
      return this.props.onWatchVideo(this.props.speaker);
    }
  }

  onViewSchedule = () => {
    if (this.props.onViewSchedule) {
      return this.props.onViewSchedule(this.props.speaker);
    }
  }

  onViewPrograms = () => {
    if (this.props.onViewPrograms) {
      return this.props.onViewPrograms(this.props.speaker);
    }
  }

  onProfile = () => {
    if (this.props.onProfile) {
      return this.props.onProfile(this.props.speaker);
    }
  }

  onReview = () => {
    if (this.props.onReview) {
      return this.props.onReview(this.props.speaker);
    }
  }

  onViewRatings = () => {
    if (this.props.onViewRatings) {
      return this.props.onViewRatings(this.props.speaker);
    }
  }

  __onToggleSeoScore = () => (
    this.setState({
      show_seo_score: !this.state.show_seo_score
    })
  )

  onMenu = (e) => {
    // Since the image is clickable to the profile, we stopPropagation when the menu opens
    // otherwise the menu would open AND we would redirect to the profile
    e.stopPropagation();
  }

  onFavorite = (e) => {
    this.setState({
      is_shortlisted: !this.state.is_shortlisted
    }, () => {
      if (this.props.onFavorite) {
        this.props.onFavorite(this.props.speaker, this.state.is_shortlisted);
      }
    });
    // Since the image is clickable to the profile, we stopPropagation when the favorite
    // button is clicked otherwise the menu would open AND we would redirect to the profile
    e.stopPropagation();
  }

  onFeature = () => {
    if (this.props.onFeature) {
      this.props.onFeature(this.props.speaker);
    }
  }

  render() {
    let {
      awards,
      speaker,
      is_featured,
      is_showcased,
      is_virtual_vetted,
      is_virtual_host,
      is_virtual_master,
      hide_fees,
      free_form_text_tags,
      show_virtual_fees = false,
      is_debug = false
    } = this.props;

    let {
      show_seo_score
    } = this.state;

    let rating = _.meanBy(_.get(speaker, ["ratings"]), "rating");
    if (_.isNaN(rating)) {
      rating = false;
    }

    let speaker_awards = [];
    _.forEach(awards, function (award) {
      if ((_.get(speaker, ["awards"], 0) & _.get(award, ["id"], 0)) > 0) {
        speaker_awards.push(award.tag);
      }
    });

    let Highlighter = (function () {
      let h = HighlightTextMatches(free_form_text_tags);
      return function (text) {
        return _.map(h(text), function (c, i) {
          if (_.has(c, "highlight")) {
            return <span key={i} style={{backgroundColor: "#CDE69C"}}>{c.highlight}</span>;
          }
          return c;
        });
      };
    }());

    // let text_excerpts = _.slice(_.filter(_.map(_.flattenDeep([
    //     _.get(speaker, ["bio_match_text"]),
    //     _.get(speaker, ["businessname"]),
    //     _.get(speaker, ["bio_oneline"]),
    //     _.get(speaker, ["bio_oneline_topic"])
    // ]), Highlighter), function (m) {
    //     return _.size(m) > 1;//only show these excerpts if there is a match
    // }), 0, 1); // only return up to 2 excerpts
    // //always show the oneline bio. And show it first
    // text_excerpts.unshift(Highlighter(_.get(speaker, ["bio_oneline"])));
    //
    // text_excerpts = _.map(text_excerpts, function (e, i) {
    //     return <p key={i}>{e}</p>;
    // });
    let bio_oneline = !_.isNull(_.get(speaker, ["bio_oneline_topic"], null))
      ? _.last(_.split(_.get(speaker, ["bio_oneline_topic"]), "|", 2))
      : _.get(speaker, ["bio_oneline"]);

    let assets_cols = ((!hide_fees) ? 1 : 0) + ((_.get(speaker, ["num_recommendations"], 0) > 0) ? 1 : 0) + ((_.get(speaker, ["num_videos"], 0) > 0) ? 1 : 0);
    assets_cols = (assets_cols > 0) ? (12 / assets_cols) : 12;
    let fee_display = formatFeeRange(_.get(speaker, ["fee_low"]), _.get(speaker, ["fee_high"]), false); //(_.get(speaker, ["fee_low"], 0) > 0) ? formatNumber(_.get(speaker, ["fee_low"], 0)) : (<span style={{fontSize: "0.85em"}}>{__("request")}</span>);
    if (show_virtual_fees) {
      fee_display = formatFeeRange(_.get(speaker, ["virtual_fee_low"]), _.get(speaker, ["virtual_fee_high"]), false);
    }

    let popover_menu = <PopoverMenu>
      {_.isFunction(this.props.onProfile) && <MenuItem onClick={this.onProfile}><i className="fa fa-user"/>{" "}{__("View Profile")}</MenuItem>}
      {_.get(speaker, ["num_videos"], 0) > 0 && _.isFunction(this.props.onWatchVideo) && <MenuItem onClick={this.onWatchVideo}><i className="fa fa-youtube-play"/>{" "}{__("Watch Video")}</MenuItem>}
      {_.get(speaker, ["num_programs"], 0) > 0 && _.isFunction(this.props.onViewPrograms) && <MenuItem onClick={this.onViewPrograms}><i className="fa fa-list"/>{" "}{__("See Programs")}</MenuItem>}
      {_.get(speaker, ["has_calendar_data"], false) && _.isFunction(this.props.onViewSchedule) && <MenuItem onClick={this.onViewSchedule}><i className="fa fa-calendar"/>{" "}{__("See Schedule")}</MenuItem>}
      {_.isFunction(this.props.onReview) && <MenuItem onClick={this.onReview}><i className="fa fa-star"/>{" "}{__("Review Speaker")}</MenuItem>}
      {_.isFunction(this.props.onFeature) && <MenuItem onClick={this.onFeature}><i className="fa fa-trash"/>{" "}{__("Remove Speaker")}</MenuItem>}
    </PopoverMenu>;

    let speaker_thumb_style = {
      backgroundImage: "url(\"" + _.get(this.props.speaker, ["thumb_url"]) + "\")"
    };
    if (_.includes(_.get(Constants, "NSAUS_BUREAU_ID", []), _.get(this.props.whitelabel, "bid", 0))) {
      _.set(speaker_thumb_style, "backgroundSize", "contain"); //Cere and Jean at NSA requested speaker photos not be cut off in phone call Sep 21, 2018
    }


    return (
      <div className="speaker-tile" id={"sid" + _.get(speaker, ["sid"])}>
        <div className="speaker-thumb" style={speaker_thumb_style} onClick={this.onProfile}>
          <div className="row">
            <div className="col-xs-8 text-left">
              {_.get(speaker, ["available_on"]) &&
              <div className="speaker-availability">
                {(_.get(speaker, ["available_status"], 0) === 0) &&
                <span><i className="fa fa-circle mp-tertiary"/> {__("Message for availability")}</span>
                }
                {(_.get(speaker, ["available_status"], 0) === 1) &&
                <span><i className="fa fa-circle text-warning"/> {__("Message about")}{" "}{_.get(speaker, ["available_on"], "")}</span>
                }
                {(_.get(speaker, ["available_status"], 0) === 2) &&
                <span><i className="fa fa-circle mp-primary"/> {__("Available")}{" "}{_.get(speaker, ["available_on"], "")}</span>
                }
              </div>
              }
              {(_.get(speaker, ["distance"]) > -1) && (_.get(speaker, ["distance"]) < 500) &&
              <div className="speaker-distance">{_.get(speaker, ["distance"], 0)}{" "}{__("miles away")}</div>
              }
            </div>
            <div className="col-xs-4 text-right speaker-top-actions">
              {_.isFunction(this.props.onFavorite) &&
              <i className={"fa fa-heart fa-fw " + (this.state.is_shortlisted ? "mp-color-secondary" : "")} onClick={this.onFavorite}/>
              }
              <OverlayTrigger rootClose={true} trigger="click" placement="bottom" overlay={popover_menu} onClick={this.onMenu}>
                <i className="fa fa-ellipsis-h fa-fw"/>
              </OverlayTrigger>
            </div>
          </div>
        </div>
        {is_featured && <div className="speaker-featured">{__("Featured")}</div>}
        {is_showcased && !is_featured && <div className="speaker-showcased">{__("Showcased")}</div>}
        {is_virtual_vetted && !is_showcased && !is_featured && <div className="speaker-virtual"><i className={"fa fa-desktop"} />{" "}{__("Virtual Certified")}</div>}
        {is_virtual_vetted && <img
            className={"link virtual-badge"}
            onClick={this.onProfile}
            src="https://streamer.espeakers.com/static/marketplace/certified_virtual_256.png"
            alt="Certified Virtual Presenter"
        />}
        {is_virtual_master && <img
          className={"link virtual-badge second-badge"}
          onClick={this.onProfile}
          src="https://streamer.espeakers.com/static/marketplace/virtual_master_256.png"
          alt="Virtual Master Presenter"
        />}
        {is_virtual_host && !is_virtual_master && <img
            className={"link virtual-badge second-badge"}
            onClick={this.onProfile}
            src="https://streamer.espeakers.com/static/marketplace/certified_host_256.png"
            alt="Certified Virtual Host"
        />}
        <div className={classnames(["speaker-details", {"featured": is_featured}, {"showcased": is_showcased}, {"virtual_vetted": is_virtual_vetted}])}>
          <div className="speaker-name">{Highlighter(_.get(speaker, ["speakername"]))}</div>
          {rating &&
          <div>
            {_.map([1, 2, 3, 4, 5], function (i) {
              return (<i key={i} className={"fa fa-star" + (rating < i ? "-o" : "")}/>);
            })}
            {" "}
            <span className="link small" onClick={this.onViewRatings}>{__("view ratings")}</span>
          </div>
          }
          <div className="row" style={{marginTop: "15px"}}>
            <div className={classnames(["col-xs-12", {"col-sm-12": (_.size(speaker_awards) === 0)}, {"col-sm-6": (_.size(speaker_awards) > 0)}])}>
              <div className="speaker-location">
                <i className="fa fa-map-marker mp-tertiary-background"/>{" "}
                {!_.isNull(_.get(speaker, ["travels_from_state"]))
                  ? _.get(speaker, ["travels_from_state"])
                  : _.get(speaker, ["state"])
                }
                {!_.isNull(!_.isNull(_.get(speaker, ["travels_from_state"])) ? _.get(speaker, ["travels_from_state"]) : _.get(speaker, ["state"])) && <span>{", "}</span>}
                {!_.isNull(_.get(speaker, ["travels_from_country"]))
                  ? _.get(speaker, ["travels_from_country"])
                  : _.get(speaker, ["country"])
                }
              </div>
            </div>
            <div className="col-sm-6 col-xs-12">
              <div className="speaker-awards">
                {_.size(speaker_awards) > 0 && <i className="fa fa-trophy mp-tertiary-background"/>}
                {_.map(speaker_awards, function (item, i) {
                  return (<span key={i}>{" "}{item}</span>);
                })}
              </div>
            </div>
          </div>
          <div className="speaker-oneline text-left">
            <p>
              <div dangerouslySetInnerHTML={{__html: bio_oneline}} />
            </p>
            {(_.trim(bio_oneline, '.') !== _.trim(_.get(speaker, ["bio_match_text"]), '.')) &&
              Highlighter(_.get(speaker, ["bio_match_text"]))
            }
          </div>
          <div className="speaker-assets">
            <div className="row">
              {(_.get(speaker, ["num_recommendations"], 0) > 0) &&
              <div className={"col-xs-" + assets_cols}>
                <div className="speaker-asset">
                  <i className="fa fa-star mp-tertiary-background text-center"/> <span
                  className="asset-value">{_.get(speaker, ["num_recommendations"], 0)}</span>
                  <div className="asset-name">{__("Reviews")}</div>
                </div>
              </div>
              }
              {(_.get(speaker, ["num_videos"], 0) > 0) &&
              <div className={"col-xs-" + assets_cols}>
                <div className="speaker-asset">
                  <i className="fa fa-youtube-play mp-tertiary-background text-center"/> <span
                  className="asset-value">{_.get(speaker, ["num_videos"], 0)}</span>
                  <div className="asset-name">{__("Videos")}</div>
                </div>
              </div>
              }
              {!hide_fees &&
              <div className={"col-xs-" + assets_cols}>
                {(_.get(speaker, ["fee_low"], 0) === 0) && (_.get(speaker, ["fee_high"], 0) === 0)
                  ?
                  <div className="speaker-asset">
                    <span className="asset-value">{__("Contact for fees")}</span>
                    <div className="asset-name">{__("Fee (USD)")}</div>
                  </div>
                  :
                  <div className="speaker-asset">
                    <i className="fa fa-usd mp-tertiary-background text-center" style={{marginRight: "0"}}/>
                    <span className="asset-value"> {fee_display}</span>
                    <div className="asset-name">{__("Fee (USD)")}</div>
                  </div>
                }
              </div>
              }
            </div>
          </div>
          {is_debug &&
          <div className="speaker-assets">
            <div className="row">
              <div className="col-xs-4 col-sm-4">
                <div className="speaker-asset link" onClick={this.__onToggleSeoScore}>
                  {show_seo_score
                      ? <React.Fragment>
                        <span className="asset-value">{_.get(speaker, ["sortscore"], 0)}</span>
                        <div className="asset-name"><i className="fa fa-sort-amount-desc mp-tertiary-background text-center"/>{" "}Sort Score</div>
                      </React.Fragment>
                      : <React.Fragment>
                        <span className="asset-value">{_.get(speaker, ["bio_match_rank"], 0)}</span>
                        <div className="asset-name"><i className="fa fa-sort-amount-desc mp-tertiary-background text-center"/>{" "}Search Score</div>
                      </React.Fragment>
                  }
                </div>
              </div>
              <div className="col-xs-4 col-sm-4">
                <div className="speaker-asset">
                  <span className="asset-value">{_.get(speaker, ["accepted_mp_agreement"], false) ? "Yes" : <span className="text-danger">No</span>}</span>
                  <div className="asset-name"><i className="fa fa-check mp-tertiary-background text-center"/>{" "}Agreement</div>
                </div>
              </div>
              <div className="col-xs-4 col-sm-4">
                <div className="speaker-asset">
                  <span className="asset-value">{_.get(speaker, ["sid"], 0)}</span>
                  <div className="asset-name"><i className="fa fa-user mp-tertiary-background text-center"/>{" "}SID</div>
                </div>
              </div>
            </div>
          </div>
          }
          <div className="speaker-actions">
            <div className="row">
              {_.isFunction(this.props.onProfile) &&
              <div className={"text-center " + (_.isFunction(this.props.onFavorite) ? "col-xs-6" : "col-xs-12")}>
                <div className="btn btn-flat mp-primary btn-block" onClick={this.onProfile}>
                  <span className="hidden-xs hidden-sm">{__("View Profile")}</span><span className="visible-xs visible-sm">{__("Profile")}</span>
                </div>
              </div>
              }
              {_.isFunction(this.props.onFavorite) &&
              <div className={"text-center " + (_.isFunction(this.props.onProfile) ? "col-xs-6" : "col-xs-12")}>
                {this.state.is_shortlisted
                  ?
                  <div className="btn btn-flat mp-secondary btn-block" onClick={this.onFavorite}>
                    <i className="fa fa-heart"/><span className="hidden-xs hidden-sm">{" "}{__("Favorite")}</span>
                  </div>
                  :
                  <div className="btn btn-flat btn-default btn-block" onClick={this.onFavorite}>
                    <i className="fa fa-heart"/><span className="hidden-xs hidden-sm">{" "}{__("Favorite")}</span>
                  </div>
                }
              </div>
              }
              {_.isFunction(this.props.onReview) &&
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                <div className="btn btn-flat mp-secondary btn-block" onClick={this.onReview}>
                  <i className="fa fa-star"/><span className="hidden-xs hidden-sm">{" "}{__("Review")}</span>
                </div>
              </div>
              }
              {_.isFunction(this.props.onFeature) &&
              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 text-center">
                <div className="btn btn-flat btn-danger btn-block-sm" onClick={this.onFeature}>
                  <i className="fa fa-trash"/><span className="hidden-xs hidden-sm">{" "}{__("Remove")}</span>
                </div>
              </div>
              }
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SpeakerTile.propTypes = {
  mpsitelists: PropTypes.object,
  speaker: PropTypes.object,
  is_featured: PropTypes.bool,
  is_virtual_vetted: PropTypes.bool,
  is_virtual_host: PropTypes.bool,
  is_virtual_master: PropTypes.bool,
  is_shortlisted: PropTypes.bool,
  is_debug: PropTypes.bool,
  onFavorite: PropTypes.func,
  onProfile: PropTypes.func,
  onWatchVideo: PropTypes.func,
  onViewSchedule: PropTypes.func,
  onViewPrograms: PropTypes.func,
  onReview: PropTypes.func,
  whitelabel: PropTypes.object
};