import _ from "lodash";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { gettext as __ } from "../../../../utils/Trans";

export default class SpeakerRatingModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    __onReview = () => {
        if (_.isFunction(this.props.onReview)) {
            this.props.onReview(this.props.speaker);
        }
    }

    render() {
        let {
            speaker,
            onClose
        } = this.props;

        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("Team Comments for ") + _.get(speaker, "name")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p className="help-block" style={{marginBottom: "2em"}}>Comments are private to you and your team and are helpful for selecting the best candidate. <br/><b>The speaker cannot see these.</b></p>
                    {_.map(_.get(speaker, ["ratings"]), function (rating, i) {
                        return (
                            <div className="row" key={i}>
                                <div className="col-xs-10 col-xs-offset-1 text-left">
                                    {_.map([1, 2, 3, 4, 5], function (i) {
                                        return (<i key={i} className={"text-primary fa fa-star" + (_.get(rating, ["rating"], 0) < i ? "-o" : "")} style={{Scolor:"orange"}}/>);
                                    })}
                                    <span style={{marginLeft: "1em", opacity: "0.5"}}>{_.get(rating, ["collaborator", "email"])}</span>
                                    {!_.isEmpty(_.get(rating, ["comments"])) && <p style={{fontStyle: "italic"}}>"{_.get(rating, ["comments"])}"</p>
                                    }
                                </div>
                            </div>
                        );
                    })}
                    {this.props.onReview &&
                    <div className="text-right" style={{marginTop:"1em", paddingTop:"1em", borderTop:"thin lightgrey solid"}}>
                        <Button className="btn-success btn-outline" onClick={this.__onReview}>
                            {__("Post team comment")}
                        </Button>
                        <Button className="btn-outline" style={{marginLeft:"1em"}} onClick={onClose}>
                            {__("Close")}
                        </Button>
                    </div>
                    }
                </Modal.Body>
            </Modal>
        );
    }
}