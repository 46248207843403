import _ from "lodash";
import React from "react";
import { Collapse } from "react-bootstrap";
import { connect } from "react-redux";
import FlashMessage from "../../../../FlashMessage";

class AlertCollapse extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    __onClearAlerts = () => {
        if (_.isFunction(this.props.onClearAlerts)) {
            this.props.onClearAlerts();
        }
    }

    render() {
        let {
            alerts,
            className = "alert-success"
        } = this.props;

        let items = _.map(alerts, function(alert, i) {
            if (_.isString(alert)) {
                return (<div key={i}>{alert}</div>);
            } else if (_.has(alert, ["message"])) {
                return (<div key={i}>{_.get(alert, ["message"])}</div>);
            } else if (_.isObject(alert)) {
                let alert_msgs = [];
                alert = _.reduce(alert, function (msg, alert_msgs) {
                    if (_.isString(msg)) {
                        return alert_msgs.push(msg);
                    } else if (_.has(msg, ["message"])) {
                        return alert_msgs.push(_.get(msg, ["message"]));
                    }

                }, alert_msgs);
                return (<div key={i}>{_.join(alert_msgs, ", ")}</div>);
            }
        });

        return (
            <div>
                <Collapse in={!_.isEmpty(alerts)}>
                    <div>
                        <FlashMessage className={className} onClick={this.__onClearAlerts}>
                            {items}
                        </FlashMessage>
                    </div>
                </Collapse>
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function(state){
    return {};
};

var mapDispatchToProps = function(dispatch, ownProps) {
    return {
        onClearAlerts: function() {
            if (ownProps.clearKey) {
                return dispatch({
                    type: ownProps.clearKey
                });
            }
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AlertCollapse);
