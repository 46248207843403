import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { gettext as __ } from "../../../../utils/Trans";
import SpeakerThumbnail from "../../../../components/SpeakerThumbnail";
import SpeakerName from "../../../../components/SpeakerName";

import "./CandidateListItem.scss";

export default class CandidateListItem extends React.Component {

    render() {
        let {
            has_offer = false,
            candidate
        } = this.props;

        return (
            <div className={"candidate-list-item " + (has_offer && !_.get(candidate, ["offer_accepted"], false) ? "not-selected" : "")}>
                <div>
                    {candidate && _.get(candidate, ["sid"]) && <SpeakerThumbnail speaker={candidate} size={32} dont_link_to_profile={true} dont_show_name_on_hover={true} />}
                </div>
                <div>
                    {candidate && _.get(candidate, ["sid"]) && <SpeakerName sid={_.get(candidate, ["sid"])} speaker={candidate} />}
                    {!_.get(candidate, ["offer_accepted"], false) && _.get(candidate, ["has_offer"], false) && <div className="text-muted small"><i className="fa fa-check" />{" "}{__("Pending Offer")}</div>}
                    {_.get(candidate, ["offer_accepted"], false) && <div className="text-success small"><i className="fa fa-check" />{" "}{__("Hired")}</div>}
                </div>
            </div>
        );
    }
}

CandidateListItem.propTypes = {
    candidate: PropTypes.object.isRequired
};