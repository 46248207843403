import _ from "lodash";
import React from "react";
import Promise from "es6-promise";
import { connect } from "react-redux";
import { Prompt, Redirect, withRouter } from "react-router";
import { Field, Form, formValueSelector, isDirty, reduxForm, SubmissionError } from "redux-form";
import { gettext as __ } from "../../../../utils/Trans";
import CookiePostAJob from "../../../../utils/CookiePostAJob";
import { DateRangePickerField, Message, Select, Selectize, Text, Toggle } from "../../../../components/FormComponents";
import StepNumber from "../../../../components/StepNumber";
import PostAJob_SavePendingJob from "../../../../actions/PostAJobPage/PostAJob_SavePendingJob";
import Analytics from "../../../../utils/Analytics";
import moment from "moment/moment";
import Constants from "../../../../utils/Constants";

class PostAJobForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            min_date: moment().subtract(1, "d").format("MM/DD/Y"),
            is_ready_to_submit: false
        };
    }

    handleSubmit = (values, dispatch) => {
        return new Promise((resolve) => {
                let params = _.mapValues(values, function(item) {
                    return _.get(item, ["value"], item);
                });
                resolve(dispatch(PostAJob_SavePendingJob(params)));
            }).then((response) => {
                Analytics.sendEvent("Public Job", "submit", 0, "");
                this.setState({
                    is_ready_to_submit: true
                });
                return response;
            }).catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
            handleSubmit,
            submitSucceeded,
            submitting,
            error,
            dirty,
            topics,
            countrylist,
            statelist,
            deliverymethods,
            presenter_types,
            budget_ranges,
            is_onsite,
            is_virtual
        } = this.props;

        return (
            <Form className="MP-PostAPublicJobForm mp-style-form" onSubmit={handleSubmit(this.handleSubmit)}>
                <Prompt
                    when={!this.state.is_ready_to_submit && dirty}
                    message={location =>
                        __("Are you sure you want to leave before posting your job?")
                    }
                />
                <StepNumber n={1}/>
                <Field
                    name="message"
                    className="form-control"
                    label={__("Message")}
                    rows={5}
                    component={Message}
                />
                <div className="row">
                    <div className="col-sm-6">
                        <Field
                            name="presenter_type"
                            className="form-control"
                            label={__("What I Need")}
                            component={Selectize}
                            placeholder={__("Select a type of presentation...")}
                            options={_.map(presenter_types, function (item) {
                                return {label: _.get(item, ["description"]), value: _.get(item, ["id"])};
                            })}
                        />
                    </div>
                    <div className="col-sm-6">
                        <Field
                            name="topic"
                            className="form-control"
                            label={__("Speaking On")}
                            component={Selectize}
                            placeholder={__("Choose a topic...")}
                            options={_.map(topics, function (item) {
                                return {label: _.get(item, ["name"]), value: _.get(item, ["id"])};
                            })}
                        />
                    </div>
                </div>
                <Field
                    name="name"
                    className="form-control"
                    label={__("Speaking To")}
                    placeholder={__("Annual Training Conference")}
                    component={Text}
                />
                <StepNumber n={2}/>
                <div className="row">
                    <div className="col-sm-6">
                        <Field
                            label={__("Event Date")}
                            component={DateRangePickerField}
                            style={{width:"100%"}}
                            name="startdate"
                            placeholder="mm/dd/yyyy"
                            singleDatePicker={true}
                            autoApply={true}
                            autoUpdateInput={true}
                            showCustomRangeLabel={false}
                            opens="center"
                            ranges={false}
                            type="text"
                            className="form-control"
                            minDate={this.state.min_date}
                        />
                    </div>
                    <div className="col-sm-6">
                        <div className="form-group">
                            <label className="control-label">&nbsp;</label>
                            <Field
                                name="date_tentative"
                                className="form-control"
                                label={__("Date tentative")}
                                component={Toggle}
                            />
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-6">
                        <Field
                            name="delivery"
                            className="form-control"
                            label={__("Type of Event")}
                            component={Selectize}
                            placeholder={__("Select type of event...")}
                            options={_.map(deliverymethods, function (item) {
                                return {label: _.get(item, ["description"]), value: _.get(item, ["id"])};
                            })}
                        />
                    </div>
                </div>
                {is_virtual &&
                <div className="row">
                    <div className="col-sm-12">
                        <Field
                            name="zoomUrl"
                            className="form-control"
                            label={__("Meeting URL")}
                            component={Message}
                            rows={3}
                        />
                    </div>
                </div>
                }
                {is_onsite &&
                <div className="row">
                    <div className="col-sm-4">
                        <Field
                            name="city"
                            className="form-control"
                            label={__("City")}
                            component={Text}
                        />
                    </div>
                    <div className="col-sm-4">
                        {_.size(statelist) > 0 ?
                            <Field
                                name="state"
                                className="form-control"
                                label={__("State")}
                                component={Selectize}
                                options={_.map(statelist, function (item, i) {
                                    return {label: _.startCase(item), value: i};
                                })}
                            />
                            :
                            <Field
                                name="state"
                                className="form-control"
                                label={__("State")}
                                component={Text}
                            />
                        }
                    </div>
                    <div className="col-sm-4">
                        <Field
                            name="country"
                            className="form-control"
                            label={__("Country")}
                            component={Select}
                        >
                            <optgroup label="Common Countries">
                                {_.map(_.pickBy(countrylist, function (item) {
                                    return (
                                        item === "United States" ||
                                        item === "Canada" ||
                                        item === "Mexico"
                                    );
                                }), function (item, i) {
                                    return (
                                        <option key={i} value={i}>{item}</option>
                                    );
                                })}
                            </optgroup>
                            <optgroup label="All Countries">
                                {_.map(_.pickBy(countrylist, function (item) {
                                    return (
                                        item !== "United States" &&
                                        item !== "Canada" &&
                                        item !== "Mexico"
                                    );
                                }), function (item, i) {
                                    return (
                                        <option key={i} value={i}>{item}</option>
                                    );
                                })}
                            </optgroup>
                        </Field>
                    </div>
                </div>
                }
                <StepNumber n={3}/>
                <div className="row">
                    <div className="col-sm-6">
                        <Field
                            name="budget"
                            className="form-control"
                            label={__("Budget")}
                            component={Selectize}
                            placeholder={__("Select your budget...")}
                            options={_.map(budget_ranges, function(item, i) {
                                return {label: _.get(item, ["label"]), value: ("range_" + i)};
                            })}
                        />
                    </div>
                </div>

                <Field
                    name="meeting_goals"
                    className="form-control"
                    label={__("Goals")}
                    placeholder={__("What should the speaker's presentation accomplish for your audience?")}
                    rows={5}
                    component={Message}
                />

                <div className="form-group text-center">
                    <button type="submit" className="btn btn-success btn-lg" disabled={submitting}>
                        {submitting ? <i className="fa fa-spin fa-circle-o-notch"/> : <i/>} {__("CREATE JOB")}
                    </button>
                    {submitSucceeded && <span className="text-success"> <i className="fa fa-check" /> {__("Everything checks out!  One moment while we post your job to the public job board...")}</span>}
                    {this.state.is_ready_to_submit && submitSucceeded && <Redirect to={"/postajob/?onboard=postajob"} />}
                    {error && <span className="text-danger"> <i className="fa fa-times" /> {_.get(error, ["message"])}</span>}
                </div>
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state) {

    // Pull out the pending job from the localStorage or Cookie (private browsing)
    let pending_job = CookiePostAJob.get();

    // Selectors to filter the form
    let selector = formValueSelector("postapublicjob");
    let selected_country = selector(state, "country"); // used to filter the states
    let selected_delivery_method = selector(state, "delivery"); // used to show/hide the location

    // Page Properties
    let topics = _.get(state, ["Whitelabel", "sitelists", "topics"]);
    let deliverymethods = _.get(state, ["Whitelabel", "sitelists", "universal", "deliverymethods"]);
    let presenter_types = _.get(state, ["Whitelabel", "sitelists", "universal", "presenter_types"]);
    let budget_ranges = _.get(state, ["Whitelabel", "sitelists", "feeranges"]);

    //get the longest name for each country
    let countrylist = _.mapValues(_.groupBy(_.get(state, ["Whitelabel", "sitelists", "universal", "countrylist"], []), "sc"), function (list) {
        return _.last(_.sortBy(_.map(list, "lc"), _.size));
    });

    let statelist = _.mapValues(_.groupBy(_.pickBy(_.get(state, ["Whitelabel", "sitelists", "universal", "statelist"], []), function (item) {
        return item.sc === selected_country;
    }), "ss"), function (list) {
        return _.last(_.sortBy(_.map(list, "ls"), _.size));
    });

    return {
        is_loading: _.isUndefined(countrylist) || _.isUndefined(statelist) || _.isUndefined(deliverymethods) || _.isUndefined(presenter_types) || _.isUndefined(topics) || _.isUndefined(budget_ranges),
        topics: topics,
        countrylist: countrylist,
        statelist: statelist,
        deliverymethods: deliverymethods,
        presenter_types: presenter_types,
        budget_ranges: budget_ranges,
        is_onsite: (selected_delivery_method === 1), // on-site
        is_virtual: (selected_delivery_method === 2), // virtual
        initialValues: {
            eid: -1,
            bureau_id: _.get(state, ["Whitelabel", "whitelabel", "bid"], Constants.TRUNK_BUREAU_ID),
            name: _.get(pending_job, ["name"], null),
            startdate: _.get(pending_job, ["startdate"], null),
            date_tentative: false, // TODO get from event object
            delivery: _.get(pending_job, ["delivery"], 1),
            city: _.get(pending_job, ["city"]),
            state: _.get(pending_job, ["state"]),
            country: _.get(pending_job, ["country"], "US"),
            budget: _.get(pending_job, ["budget"]),
            topic: _.get(pending_job, ["topic"]),
            language: _.get(pending_job, ["language"], "en"),
            presenter_type: _.get(pending_job, ["presenter_type"], 1),
            meeting_goals: _.get(pending_job, ["meeting_goals"], ""),
            zoomUrl: _.get(pending_job, ["zoomUrl"], ""),
            message: _.get(pending_job, ["message"], __("Hello,") + "\n\n" + __("I'd like to invite you to apply for my speaking engagement.  Please review these details and let me know if you are available and would be a good match for our needs.")),
            post_public: true
        }
    };
};

var mapDispatchToProps = function (state, ownProps) {
    return {
        isFormDirty: isDirty("postapublicjob")(state)
    };
};

PostAJobForm = reduxForm({
    form: "postapublicjob",
    validate: function (values) {
        const errors = {};
        if (!values.name) {
            errors.name = __("Please enter a name for your event.");
        }
        if (!values.meeting_goals) {
            errors.meeting_goals = __("Please enter your Meeting Goals");
        }
        if (!values.startdate) {
            errors.startdate = __("Please enter a date for your event.");
        }
        if (!moment(values.startdate, "MM/DD/YYYY").isValid()) {
            errors.startdate = __("Please enter a date for your event.");
        }
        if (!values.budget) {
            errors.budget = __("Please choose your budget.");
        }
        if (_.isNull(values.delivery)) {
            errors.delivery = __("Please choose a delivery method.");
        }
        if (values.delivery === 1) {
            if (!values.city) {
                errors.city = __("Please enter a city.");
            }
            if (!values.country) {
                errors.country = __("Please enter a country.");
            }
        }

        return errors;
    }
})(PostAJobForm);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostAJobForm));
