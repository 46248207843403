import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";

class WorkedInCountriesQuickStats extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speaker
        } = this.props;

        let worked_in_countries = _.get(speaker, ["worked_in_countries"], []);

        return (
            <div className="quickStatsBox worked_in_countries">
                <h5 className="header">{__("WORKED IN")}</h5>
                <div>
                    {_.join(worked_in_countries, ", ")}
                </div>
            </div>
        );
    }
}

WorkedInCountriesQuickStats.propTypes = {
    speaker: PropTypes.object.isRequired
};


export default WorkedInCountriesQuickStats;