import _ from "lodash";
import React from "react";
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete";

import "./SearchLocation.scss";
import Constants from "../../../utils/Constants";

class SearchLocation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      address: props.input.value,
      lat: null,
      lng: null,
      city: null,
      state: null,
      country: null,
      country_short: null,
      loading: false,
      value: null
    };
  }

  __findLocation = () => {
    this.setState({
      loading: true
    });
    fetch("https://api.ipstack.com/check?output=json&access_key=" + Constants.IPSTACK_ACCESS_KEY)
    .then((response) => response.json())
    .then((responseJson) => {
      return this.__geocodeByAddress(_.get(responseJson, "city") + ", " + _.get(responseJson, "region_code") + ", " + _.get(responseJson, "country_code"));
    })
    .catch(() => {
      //console.error(error);
    });
  }

  handleSelect = (address) => {
    if (_.trim(address) === "") {
      this.setState({
        lat: null,
        lng: null,
        loading: false,
        value: {
          lat: null,
          lng: null,
          state: null,
          country: null,
          country_short: null,
          location: null,
          use_lat_lng: false
        }
      }, () => {
        if (_.isFunction(this.props.input.onChange)) {
          this.props.input.onChange(this.state.value);
        }
      });
    } else {
      return this.__geocodeByAddress(address);
    }
  }

  __geocodeByAddress = (address) => {
    if (!_.isString(address)) {
      return null;
    }
    this.setState({
      address,
      loading: true
    });

    geocodeByAddress(address)
    .then((results) => {
      let use_lat_lng = false;
      let city = _.get(_.head(_.filter(_.get(results, [0, "address_components"], []), function (addr) {
        return (_.includes(_.get(addr, ["types"], []), "locality"));
      })), ["long_name"]);
      let country = _.get(_.head(_.filter(_.get(results, [0, "address_components"], []), function (addr) {
        return (_.includes(_.get(addr, ["types"], []), "country") ||
          _.includes(_.get(addr, ["types"], []), "continent"));
      })), ["long_name"]);

      let country_short = _.get(_.head(_.filter(_.get(results, [0, "address_components"], []), function (addr) {
        return (_.includes(_.get(addr, ["types"], []), "country") ||
          _.includes(_.get(addr, ["types"], []), "continent"));
      })), ["short_name"]);

      let state;
      if (country_short === "GB") {
        state = _.get(_.head(_.filter(_.get(results, [0, "address_components"], []), function (addr) {
          return _.includes(_.get(addr, ["types"], []), "administrative_area_level_2");
        })), ["short_name"]);
      } else {
        state = _.get(_.head(_.filter(_.get(results, [0, "address_components"], []), function (addr) {
          return _.includes(_.get(addr, ["types"], []), "administrative_area_level_1");
        })), ["short_name"]);
      }

      if (_.includes(_.get(results, [0, "types"], []), "locality")) {
        // We found a locality (i.e. city), so search based on the lat/lng
        use_lat_lng = true;
      }

      this.setState({
        city: city,
        state: state,
        country: country,
        country_short: country_short,
        use_lat_lng: use_lat_lng
      });
      return getLatLng(results[0]);
    })
    .then(({lat, lng}) => {
      this.setState({
        lat: lat,
        lng: lng,
        loading: false,
        value: {
          lat: lat,
          lng: lng,
          city: this.state.city,
          state: this.state.state,
          country: this.state.country,
          country_short: this.state.country_short,
          location: this.state.address,
          use_lat_lng: this.state.use_lat_lng
        }
      }, () => {
        if (_.isFunction(this.props.input.onChange)) {
          this.props.input.onChange(this.state.value);
        }
      });
    })
    .catch(() => {
      this.setState({
        loading: false
      });
    });
  }

  handleChange = (address) => {
    this.setState({
      address,
      lat: null,
      lng: null,
      city: null,
      state: null,
      country: null,
      value: {}
    });
  }

  render() {
    let {
          input,
          disabled
        } = this.props;
    let {
          address
        } = this.state;

    return (
      <div style={{position: "relative"}}>
        <div className="input-group">
          <PlacesAutocomplete
            onChange={this.handleChange}
            value={(_.isEmpty(address)) ? "" : address}
            onSelect={this.handleSelect}
            onError={this.handleError}
            shouldFetchSuggestions={(!_.isEmpty(address) && (address.length > 2))}
          >
            {({getInputProps, suggestions, getSuggestionItemProps}) => {
              return (
                <div className="Location__search-bar-container">
                  <div className="Location__search-input-container">
                    <input
                      {...getInputProps({
                        placeholder: "Search Places...",
                        className: "form-control",
                      })}
                    />
                  </div>
                  {suggestions.length > 0 && (
                    <div className="Location__autocomplete-container">
                      {suggestions.map((suggestion, i) => {
                        let className = "Location__suggestion-item";
                        if (suggestion.active) {
                          className += " Location__suggestion-item--active";
                        }
                        return (
                          /* eslint-disable react/jsx-key */
                          <div
                            {...getSuggestionItemProps(suggestion, {className})}
                            key={i}
                          >
                            <strong>
                              {suggestion.formattedSuggestion.mainText}
                            </strong>{' '}
                            <small>
                              {suggestion.formattedSuggestion.secondaryText}
                            </small>
                          </div>
                        );
                        /* eslint-enable react/jsx-key */
                      })}
                    </div>
                  )}
                </div>
              );
            }}
          </PlacesAutocomplete>
          <span className="input-group-btn">
                        <button className={"btn btn-default btn-flat " + (disabled ? "btn-disabled" : "")} type="button" disabled={disabled} onClick={this.__findLocation}>
                            {this.state.loading ? <i className="fa fa-spin fa-circle-o-notch"/> : <i className="fa fa-map-marker"/>}
                        </button>
                    </span>

        </div>
      </div>
    );
  }
}

export default SearchLocation;