import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../utils/Trans";
import {Panel } from "react-bootstrap";
import moment from "moment/moment";

import "./Message.scss";

class Message extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            sender_name,
            is_you,
            is_unread,
            message,
            content,
            timestamp,
            justified = true,
            is_marketplace_message
        } = this.props;

        if (message && !timestamp) { timestamp = new Date(_.get(message, ["created"])); }
        if (message && !content) { content = _.get(message, ["content"]); }

        let received_date = moment(timestamp).calendar(null, {
            sameDay: '[Today at] h:mm a',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[Yesterday at] h:mm a',
            lastWeek: '[Last] dddd [at] h:mm a',
            sameElse: 'MMM D, YYYY [at] h:mm a'
        });

        if (is_marketplace_message) {
            return (
                <div className="row MP-Message">
                    <div className="col-xs-8 col-xs-offset-2">
                        <div className="strike">
                            <div className="mp">
                                <div>{content}</div>
                                <div className="footer">
                                    <span>{received_date}</span>
                                    {is_unread && !is_you &&
                                    <span className="label label-danger" style={{marginLeft: "1em"}}>{__("NEW")}</span>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        // let popover = <Popover id={"contact_popover_" + _.get(message, ["id"])} title="Redacted Contact Information">
        //     <p>Did you notice the ░░░'s? The message contains direct contact information that was removed from the message.</p>
        //     <p>We encourage direct contact after an offer has been agreed to and a deposit paid, but until then we ask that you use only our messaging to communicate with the buyer.</p>
        //     <p>Direct communication prior to an agreement tends to take jobs out of the system and hurts everyone.</p>
        //     <p style={{fontWeight:"bold"}}><i>Once you select a speaker, we will provide you with their phone number and email so you can coordinate event details directly.</i></p>
        // </Popover>;

        return (
            <div className="row MP-Message">
                <div className={"col-xs-12 " + (justified ? "col-sm-11 " + (is_you ? "col-sm-offset-1" : "") : "")}>
                    <Panel className={is_you ? "me" : "spkr"}>
                        <Panel.Body>
                            <div style={{whiteSpace: "pre-wrap"}} className="text-left">
                                {content}
                            </div>
                        </Panel.Body>
                        <Panel.Footer>
                            {is_you
                                ? <i>{__("Sent by you")}{" "}{received_date}</i>
                                : <i>{__("Sent from")}{" "}{sender_name}{" "}{received_date}</i>
                            }
                            {is_unread && !is_you &&
                            <span className="label label-danger" style={{marginLeft: "1em"}}>{__("NEW")}</span>
                            }
                        </Panel.Footer>
                        {/*{_.get(message, ["flags_as_map", "contact_info_found", "is_set"], false) &&*/}
                        {/*<Panel.Footer>*/}
                        {/*    <OverlayTrigger*/}
                        {/*        trigger="click"*/}
                        {/*        rootClose*/}
                        {/*        placement="bottom"*/}
                        {/*        overlay={popover}*/}
                        {/*    >*/}
                        {/*        <span style={{cursor:"pointer"}} className="text-warning">Why does this message have redacted info in it{" "}<i className="fa fa-question-circle" /></span>*/}
                        {/*    </OverlayTrigger>*/}
                        {/*</Panel.Footer>*/}
                        {/*}*/}
                    </Panel>
                </div>
            </div>
        );
    }
}

Message.propTypes = {
    sender_name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    is_you: PropTypes.bool,
    is_unread: PropTypes.bool,
    content: PropTypes.string,
    message: PropTypes.object,
    is_marketplace_message: PropTypes.bool,
    timestamp: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.instanceOf(Date)])
};

export default Message;