import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Field, Form, reduxForm } from "redux-form";
import { Modal } from "react-bootstrap";
import { gettext as __ } from "../../../../utils/Trans";
import LoadingSubmit from "../../../../components/LoadingSubmit";
import { Message, Rating } from "../../../../components/FormComponents";
import MpShortlistRating from "../../../../actions/ShortList/MpShortlistRating";

class SpeakerReviewForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch, props) {
        return dispatch(MpShortlistRating.save(values))
            .then((resp) => {
                return dispatch({
                    type: "EVENT_RATING_POST",
                    id: _.get(props.shortlist, ["event_id"]),
                    mp_shortlist_id: _.get(props.shortlist, ["id"]),
                    data: _.get(resp, ["data"])
                });
            })
            .then(() => {
                if (_.isFunction(props.onClose)) {
                    props.onClose();
                }
            });
    }

    render() {
        let {
            submitting,
            handleSubmit,
            speaker,
            collaborator,
            onClose
        } = this.props;

        return (
            <Form onSubmit={handleSubmit(this.handleSubmit)}>
                <Modal.Body>
                    <div className="help-block">{__("Rate this speaker and submit your thoughts about them.  These will be shared with your collaborator.")}</div>
                    <div className="help-block"><b>{__("Collaborator (You): ")}</b>{" "}{_.get(collaborator, ["email"])}</div>
                    <div className="help-block"><b>{__("Speaker: ")}</b>{" "}{_.get(speaker, ["name"])}</div>
                    <hr />
                    <Field
                        name="rating"
                        component={Rating}
                        size="fa-3x"
                        count={5}
                        label={__("Please rate %s:", [_.get(speaker, ["name"])])}
                    />
                    <Field
                        name="comments"
                        component={Message}
                        rows={5}
                        label={__("Please add any additional comments on your rating:")}
                    />
                </Modal.Body>
                <Modal.Footer>
                    <LoadingSubmit is_saving={submitting} save_text={__("Saving Review...")} className="btn-success btn-block-xs">
                        {__("Save Review")}
                    </LoadingSubmit>
                    {" "}
                    <div className="btn btn-default btn-block-xs" disabled={submitting} onClick={onClose}>
                        {__("Not Yet")}
                    </div>
                </Modal.Footer>
            </Form>

        );
    }
}

SpeakerReviewForm = reduxForm({
    form: "speaker_review_form"
})(SpeakerReviewForm);

function mapStateToProps(state, ownProps) {

    let speaker_shortlist = _.find(_.get(ownProps.shortlist, ["speakers"], []), { speaker_id: _.get(ownProps.speaker, ["sid"])});

    return {
        initialValues: {
            mp_shortlist_id: _.get(ownProps.shortlist, ["id"]),
            mp_collaborator_id: _.get(ownProps.collaborator, ["id"]),
            mp_shortlist_speakers_id: _.get(speaker_shortlist, ["id"]),
            rating: 0,
            comments: ""
        }
    };
}

function mapDispatchToProps(/*dispatch, ownProps*/) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SpeakerReviewForm);