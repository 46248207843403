import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import ResumeEventModal from "../../modals/ResumeEventModal";
import ActionButton from "../../../../components/ActionButton";

class ResumeEventButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_modal: false
        };
    }

    __toggleModal = () => {
        this.setState({
            show_modal: !this.state.show_modal
        });
    }

    render() {
        let {
            className = "btn btn-warning btn-flat"
        } = this.props;

        return (
            <div>
                <ActionButton
                    icon="play-circle-o"
                    btn_text={__("Resume this Job")}
                    help_text={__("Re-activate this job. We will let your candidates know that it is back in action.")}
                    onClick={this.__toggleModal}
                    className={className}
                />
                {this.state.show_modal && <ResumeEventModal {...this.props} onClose={this.__toggleModal} />}
            </div>
        );
    }
}

ResumeEventButton.propTypes = {
    event: PropTypes.object.isRequired,
    className: PropTypes.string
};

export default ResumeEventButton;