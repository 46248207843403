import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import Speaker from "../../actions/Speaker/Speaker";

class SpeakerName extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (_.isUndefined(this.props.speaker)) {
            this.props.loadData();
        }
    }

    render() {
        let {
            speaker,
            style = {},
            className = ""
        } = this.props;

        let default_style = {
            display: "inline-block"
        };

        return (
            <div className={className} style={_.assign({}, default_style, style)}>{_.get(speaker, ["name"])}</div>
        );
    }

}

SpeakerName.propTypes = {
    sid: PropTypes.number.isRequired,
    style: PropTypes.object,
    speaker: PropTypes.object
};

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function(state, ownProps) {
    let speaker = ownProps.speaker;
    if (!speaker) {
        speaker = _.get(state, ["Speakers", _.get(ownProps.speaker, ["sid"], ownProps.sid)]);
    }
    return {
        speaker: speaker
    };
};

var mapDispatchToProps = function(dispatch, ownProps){
    return {
        loadData: function() {
            if (_.isUndefined(ownProps.speaker) && !_.isUndefined(ownProps.sid)) {
                dispatch(Speaker.get(ownProps.sid));
            } else if (!_.isUndefined(ownProps.speaker) && !_.has(ownProps.speaker, ["thumb_url"])) {
                dispatch(Speaker.get(_.get(ownProps.speaker, ["sid"], ownProps.sid)));
            }
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpeakerName);