import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import queryString from "query-string";
import md5 from "md5";
import PostAJob_SavePendingJob from "../../actions/PostAJobPage/PostAJob_SavePendingJob";
import SharedShortlist from "./components/SharedShortlist";
import AccessDenied from "./components/AccessDenied";
import moment from "moment";
// import NeedMoreSpeakers from "./components/NeedMoreSpeakers";
import SpeakerReviewModal from "./modals/SpeakerReviewModal";
import SpeakerRatingModal from "./modals/SpeakerRatingModal";
import SpeakerRemoveModal from "./modals/SpeakerRemoveModal";
import ShareShortlistModal from "./modals/ShareShortlistModal";
import CollaboratorModal from "./modals/CollaboratorModal";
import LoadingIndicator from "../../components/LoadingIndicator";
// import CollaboratorAccess from "./components/CollaboratorAccess";
import ReferralDetails from "./components/ReferralDetails";
import VideoPlayer from "../ProfilePage/components/VideoPlayer";
import AffiliateEvent from "../../actions/Affiliate/AffiliateEvent";
import Speaker from "../../actions/Speaker/Speaker";

import { gettext as __ } from "../../utils/Trans";
import CollaboratorAccess from "./components/CollaboratorAccess";
import "./CollabReferralPage.scss";


export class CollabReferralPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_rating_modal: false,
            show_review_modal: false,
            show_share_shortlist_modal: false,
            show_collaborator_modal: false,
            show_video_modal: false,
            show_remove_speaker_modal: false,
            speaker: null
        };
    }

    componentDidMount() {
        let eid = _.get(this.props.match, ["params", "id"]);
        if (_.isUndefined(this.props.affiliate_event)) {
            this.props.loadReferral(eid);
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let old_id = _.get(this.props.match, ["params", "id"]);
        let id = _.get(nextProps.match, ["params", "id"]);
        if (old_id !== id) {
            this.props.loadReferral(id);
        }
    }

    __onToggleReviewModal = (speaker) => {
        this.setState({
            speaker: speaker,
            show_review_modal: !this.state.show_review_modal,
            show_rating_modal: false
        });
    }

    __onToggleViewRatings = (speaker) => {
        this.setState({
            speaker: speaker,
            show_rating_modal: !this.state.show_rating_modal,
            show_review_modal: false
        });
    }

    __onToggleShareShortlist = (speaker) => {
        this.setState({
            show_share_shortlist_modal: !this.state.show_share_shortlist_modal
        });
    }

    __onToggleCollaborator = () => {
        this.setState({
            show_collaborator_modal: !this.state.show_collaborator_modal
        });
    }

    __onToggleVideoPlayer = (speaker, video) => {
        this.setState({
            speaker: speaker,
            show_video_modal: !this.state.show_video_modal
        });
    }

    __onToggleRemoveSpeaker = (speaker) => {
        this.setState({
            speaker: speaker,
            show_remove_speaker_modal: !this.state.show_remove_speaker_modal
        });
    }

    render() {
        let {
            is_loading,
            affiliate_event,
            collaborator,
            shortlist,
            whitelabel
        } = this.props;

        if (!is_loading && !collaborator) {
            return (<AccessDenied />);
        }

        if (is_loading) {
            return (<LoadingIndicator />);
        }

        return (
            <div>
                {collaborator && <CollaboratorAccess collaborator={collaborator} />}
                {!is_loading && <ReferralDetails {...this.props} onShare={this.__onToggleShareShortlist} onViewCollaborators={this.__onToggleCollaborator} />}
                {!is_loading && <SharedShortlist {...this.props} onShare={this.__onToggleShareShortlist} onRemove={this.__onToggleRemoveSpeaker} onWatchVideo={this.__onToggleVideoPlayer} onReview={this.__onToggleReviewModal} onViewRatings={this.__onToggleViewRatings} />}
                {/*<NeedMoreSpeakers {...this.props} />*/}

                {this.state.show_review_modal && <SpeakerReviewModal speaker={this.state.speaker} shortlist={shortlist} collaborator={collaborator} affiliate_event={affiliate_event} onClose={this.__onToggleReviewModal} />}
                {this.state.show_rating_modal && <SpeakerRatingModal speaker={this.state.speaker} onReview={this.__onToggleReviewModal} onClose={this.__onToggleViewRatings} />}
                {this.state.show_share_shortlist_modal && <ShareShortlistModal {...this.props} onClose={this.__onToggleShareShortlist} />}
                {this.state.show_collaborator_modal && <CollaboratorModal me={collaborator} affiliate_event={affiliate_event} shortlist={shortlist} onClose={this.__onToggleCollaborator} />}
                {this.state.show_video_modal && <VideoPlayer whitelabel={whitelabel} speaker={this.state.speaker} onVideoClose={this.__onToggleVideoPlayer} />}
                {this.state.show_remove_speaker_modal && <SpeakerRemoveModal whitelabel={whitelabel} speaker={this.state.speaker} affiliate_event={affiliate_event} onClose={this.__onToggleRemoveSpeaker} />}
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {

    let qs = queryString.parse(_.get(ownProps.location, ["search"]), {arrayFormat: "bracket"});

    let auth_email = _.get(qs, ["ae"]);

    let id = _.get(ownProps.match, ["params", "id"]);
    // event objects

    let affiliate_event = _.get(state, ["AffiliateEvent", id]);
    let event = _.get(affiliate_event, ["event"]);
    let shortlist = _.get(affiliate_event, ["shortlist"]);

    let speakers = _.get(state, ["Speakers"]);

    // event/speaker objects
    let sitelists = _.get(ownProps.whitelabel, ["sitelists"]);

    let collaborator = _.head(_.filter(_.get(shortlist, ["collaborators"]), function(collaborator) {
        return md5(_.get(collaborator, ["email"], "")) === auth_email;
    }));

    return {
        is_loading: (_.isUndefined(ownProps.user) || _.isUndefined(shortlist)) || _.isUndefined(speakers),
        // event objects
        affiliate_event: affiliate_event,
        event: event,
        shortlist: shortlist,
        collaborator: collaborator,
        speakers: speakers,
        // global lists
        awards: _.get(sitelists, ["awards_to_display"]),
        languages: _.get(sitelists, ["languages"]),
        qs: qs,
        auth_email: auth_email,

        is_espeakers: !!(ownProps.whitelabel && _.get(ownProps.whitelabel, ["is_espeakers"]))
    };
};

var mapDispatchToProps = function (dispatch, ownProps) {
    return {
        onShowCollaboratorAccess: function() {
            return dispatch({
                type: "NOTIFICATION_CREATE",
                message: __("You have been invited to collaborate on this shortlist.  You can add notes and review the candidates below. ")
            });
        },
        onProfile: function(speaker) {
            ownProps.history.push({
                pathname: "/profile/" + _.get(speaker, ["sid"]) + "/headless",
                state: {
                    btst: "BACK TO SHORTLIST",
                    bts: "/collab/collab-referral/" + _.get(ownProps.match, ["params", "id"]) + _.get(ownProps.location, ["search"])
                }
            });
        },
        onCreateJob: function(affiliate_event) {
            return dispatch(PostAJob_SavePendingJob({
                    date_range: _.join([
                        moment(_.get(affiliate_event, ["starttime"]), "YYYYMMDD").isValid() ? moment(_.get(affiliate_event, ["starttime"]), "YYYYMMDD").format("M/D/Y") : moment().format("M/D/Y"),
                        moment(_.get(affiliate_event, ["starttime"]), "YYYYMMDD").isValid() ? moment(_.get(affiliate_event, ["starttime"]), "YYYYMMDD").format("M/D/Y") : moment().format("M/D/Y")
                    ], " - "),
                    startdate: moment(_.get(affiliate_event, ["starttime"]), "YYYYMMDD").isValid() ? moment(_.get(affiliate_event, ["starttime"]), "YYYYMMDD").format("M/D/Y") : moment().format("M/D/Y"),
                    enddate: moment(_.get(affiliate_event, ["starttime"]), "YYYYMMDD").isValid() ? moment(_.get(affiliate_event, ["starttime"]), "YYYYMMDD").format("M/D/Y") : moment().format("M/D/Y"),
                    shortlist_sids: _.map(_.get(affiliate_event.shortlist, ["speakers"]), (spkr) => {
                        return _.get(spkr, ["speaker_id"]);
                    }),
                    affiliate_event_id: _.get(affiliate_event, ["id"])
                }))
                .then(() => {
                    return ownProps.history.push({
                        pathname: "/collab/affiliate-paj/" + _.get(ownProps.match, ["params", "id"]),
                        search: _.get(ownProps.location, ["search"]),
                        state: {
                            btst: "BACK TO SHORTLIST",
                            bts: "/collab/collab-referral/" + _.get(ownProps.match, ["params", "id"]) + _.get(ownProps.location, ["search"])
                        }
                    });
                });
        },
        loadReferral: function(id) {
            return dispatch(AffiliateEvent.get(id))
                .then((data) => {
                    let shortlist = _.get(data, ["data", "shortlist"]);

                    let active_speakers = _.filter(_.get(shortlist, ["speakers"]), function(speaker) {
                        return _.get(speaker, ["active"], 0) > 0;
                    });
                    let sids = _.map(active_speakers, function(spkr) {
                        return _.get(spkr, ["speaker_id"]);
                    });
                    dispatch({
                        type: "EMBEDDED_SHORTLIST_ADD_ALL",
                        sids: sids
                    });
                    if (_.size(sids) > 0) {
                        return dispatch(Speaker.find({
                            ids: sids,
                            nocache: false
                        }));
                    }
                });
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CollabReferralPage));