import _ from "lodash";

export default function (state = {}, action) {
    switch (action.type) {
        case "RECAPTCHA_PASSED":
            return _.assign({}, state, {
                captchapassed: (action.value !== null),
                captchavalue: action.value
            });
        case "RECAPTCHA_CLEAR":
            return _.assign({}, state, {
                captchapassed: false,
                captchavalue: null
            });
        default:
            return state;
    }
}