import _ from "lodash";
import Balboa from "../Global/Balboa";
import Rest from "../Global/Rest";

export default _.assign({}, Rest, {

    /**
     * Retrieves a list of records.  Pass in search parameters in {params}
     * @param params
     * @returns {Function}
     */
    find: function(params) {
        let key = ["getCollaborators"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/marketplace/mp-collaborator", params)
                    .then(function (response) {
                        dispatch({
                            type: "SHORTLIST_COLLABORATOR_GET_ALL",
                            shortlist_id: _.get(params, ["mp_shortlist_id"]),
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Retrieves a single record.
     * @param params
     * @returns {Function}
     */
    get: function(id) {
        let key = ["getCollaborator", id];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/marketplace/mp-collaborator/" + id)
                    .then(function (response) {
                        dispatch({
                            type: "SHORTLIST_COLLABORATOR_GET",
                            id: id,
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Inserts a record.  Leave {params.id} 0, otherwise it will be updated
     * @param params
     * @returns {Function}
     */
    add: function(params) {
        let key = ["AddCollaborator"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/marketplace/mp-collaborator", params)
                    .then(function (response) {
                        dispatch({
                            type: "SHORTLIST_COLLABORATOR_POST",
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Updates a record.  Pass in {params.id} to specify the record to update, otherwise it will be added
     * @param params
     * @returns {Function}
     */
    update: function(params) {
        let key = ["UpdateCollaborator", _.get(params, ["id"])];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .put(key, "/marketplace/mp-collaborator/", params)
                    .then(function (response) {
                        dispatch({
                            type: "SHORTLIST_COLLABORATOR_PUT",
                            id: _.get(params, ["id"]),
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Removes a record.  Pass in {params.id} to specify the record to remove
     * @param params
     * @returns {Function}
     */
    remove: function(id) {
        let key = ["DeleteCollaborator", id];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .del(key, "/marketplace/mp-collaborator/" + id)
                    .then(function (response) {
                        dispatch({
                            type: "SHORTLIST_COLLABORATOR_DELETE",
                            id: id,
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    }
});
