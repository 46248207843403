import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { gettext as __ } from "../../../../utils/Trans";
import { Message } from "../../../../components/FormComponents";
import LoadingSubmit from "../../../../components/LoadingSubmit";
import InlineError from "../../../../components/InlineError";
import ShortList from "../../../../actions/ShortList/ShortList";

class PauseEventForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch, props) {
        return dispatch(ShortList.pause(values))
            .then(() => {
                if (_.isFunction(props.onClose)) {
                    props.onClose();
                }
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
            handleSubmit,
            submitting,
            error,
            onClose
        } = this.props;

        return (
            <Form role="form" onSubmit={handleSubmit(this.handleSubmit)}>
                <div className="modal-body">
                    {error && <InlineError error={_.get(error, ["message"])} />}
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="alert alert-warning">
                                {__("When you pause this event, we will retract any pending offers and notify the speakers on the shortlist that they should not expect any further action until you resume the event. If the event is an Open Call it will be de-listed until you resume.")}
                            </div>
                            <p>
                                {__("Please enter a brief message that we can send to the candidate speakers on this shortlist explaining what they should expect for this job.")}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <Field
                                name="message"
                                type="textarea"
                                className="form-control"
                                placeholder={__("I need to put this event on hold for now because ...")}
                                component={Message}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <LoadingSubmit is_saving={submitting} save_text={__("Pausing...")} className="btn-warning btn-block-xs">
                        {__("YES, Pause this Event")}
                    </LoadingSubmit>
                    {" "}
                    <div className="btn btn-default btn-block-xs" disabled={submitting} onClick={onClose}>
                        {__("Never Mind")}
                    </div>
                </div>
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

PauseEventForm.propTypes = {
    event: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

function mapStateToProps(state, ownProps) {
    return {
        initialValues: {
            message: "",
            eid: _.get(ownProps.event, ["eid"]),
        }
    };
}

function mapDispatchToProps() {
    return {

    };
}

PauseEventForm = reduxForm({
    form: "shortlist_page_pause_event",
    validate: function(values) {
        const errors = {};
        if (!values.message) {
            errors.message = __("Please enter a message about why you are pausing this event.");
        }
        return errors;
    }
})(PauseEventForm);

export default connect(mapStateToProps, mapDispatchToProps)(PauseEventForm);