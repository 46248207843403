import _ from "lodash";
import Balboa from "../Global/Balboa";
import SetStripeCustomer from "./SetStripeCustomer";
import {gettext as __} from "../../utils/Trans";

export default function(public_token, account_id){
    return (dispatch, getState) => {
        var key = ["connectBuyerWithPlaid"];

        return Balboa(dispatch, getState)
            .post(key, "/stripe/connectbuyerwithplaid/", {
                public_token: public_token,
                account_id: account_id
            }, {cache_ttl: -1})
            .then(function(response) {
                // reload the user details
                dispatch(SetStripeCustomer(_.get(response, ["data", "stripe_customer"])));
                return response;
            })
            .then(function(response) {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            })
            .then(() => {
                dispatch({
                    type: "NOTIFICATION_CREATE",
                    message: __("Your bank has been saved to your account and has been set as the default payment method.")
                });
            });
    };
};
