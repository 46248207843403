import _ from "lodash";

export default function (state = {}, action) {
    switch (action.type) {
        case "BOOKMENOWPAGE_SENDNOTIFICATION":
            return _.assign({}, state, {
                message: action.message,
                eid: action.eid,
                date_tentative: action.date_tentative || false
            });
        case "BOOKMENOWPAGE_SPEAKER_AVAILABILITY_FOR_DATE":
            return _.assign({}, state, {
                speakerAvailabilityForDate: action.data
            });
        default:
            return state;
    }
}