import _ from "lodash";
import Balboa from "../Global/Balboa";

export default function(eid, clear_cache = false){
    return (dispatch, getState) => {
        var key = ["MPShortlist", eid];
        var cache_ttl = clear_cache ? -1 : 30 * 60 * 1000;
        return Balboa(dispatch, getState)
            .post(key, "/mpshortlist/read/" + eid, {}, {cache_ttl: cache_ttl})
            .then(function(response) {
                // Save the user to the state
                dispatch({
                    type: "SHORTLIST_LOAD",
                    data: {
                        [eid]: _.get(response, ["data"])
                    }
                });
                return response;
            })
            .then(function(response) {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            });
    };
};
