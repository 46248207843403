import _ from "lodash";
import url from "url";
import Cookies from "js-cookie";
import toMpURL from "./toMpURL";
import toShortlistURL from "./toShortlistURL";


let parts = url.parse(toShortlistURL("/"));

let save = function(data){
    Cookies.set("myInpageList_speakers", data, {
        expires: 30,//days from now
    });
};

//Smash any old cookies stored the wrong way
//if you don't do this, there may be duplicate cookies and you'll likely read the wrong one
let smashCookies = function(){
    _.each([
        {},
        {path: "/"},
        {path: toMpURL("/")},
        {path: toShortlistURL("/")},
        {path: parts.pathname},
        {path: parts.pathname, domain: parts.hostname}
    ], function(data){
        Cookies.remove("myInpageList_speakers", data);
        data.secure = true;
        Cookies.remove("myInpageList_speakers", data);
    });
};
//read the active cookie
let sl_data = Cookies.getJSON("myInpageList_speakers");
smashCookies();
//now save it the right way
let new_data = {};
_.each(sl_data, function(s){
    if(_.has(s, "sid")){
        new_data[s.sid] = s;
    }
});
save(new_data);

export default {
    getSIDs: function(){
        return _.map(Cookies.getJSON("myInpageList_speakers"), "sid");
    },
    add: function(spkr){
        let sl = Cookies.getJSON("myInpageList_speakers");
        if (_.isUndefined(sl)) {
            sl = {};
        }
        sl[spkr.sid] = spkr;
        save(sl);
    },
    remove: function(sid){
        let sl = Cookies.getJSON("myInpageList_speakers");
        if(_.has(sl, sid)){
            save(_.omit(sl, sid));
        }
    },
    clear: function(){
        smashCookies();
    }
};