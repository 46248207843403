import Balboa from "../Global/Balboa";
import readRecommendation from "./readRecommendation";
import {gettext as __} from "../../utils/Trans";

export default function(params){
    return (dispatch, getState) => {
        var key = ["saveRecommendation", params.event_id, params.speaker_id];

        return Balboa(dispatch, getState)
            .post(key, "/speaker/recommendation/-1/save", {
                event_id:        params.event_id,
                speaker_id:      params.speaker_id,
                testimony:       params.testimony,
                would_recommend: params.would_recommend ? 1 : 0
            })
            .then(function(response) {
                dispatch(readRecommendation({
                    event_id:   params.event_id,
                    speaker_id: params.speaker_id
                }));
                return response;
            })
            .then(function(response) {
                // reload the user details
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            })
            .then(function(response) {
                dispatch({
                    type: "NOTIFICATION_CREATE",
                    message: __("Thank you for your feedback.  Reviews help make this the best place to find the right speaker on the web.")
                });
                return response;
            });
    };
};
