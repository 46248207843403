import _ from "lodash";

export default function(state = {}, action){
    let o = {};
    switch(action.type){
        case "DROPDOWN_TOGGLE":
            o[action.id] = !state[action.id];
            return _.assign({}, state, o);
        case "DROPDOWN_OFF":
            o[action.id] = false;
            return _.assign({}, state, o);
        case "DROPDOWN_ON":
            o[action.id] = true;
            return _.assign({}, state, o);
        default:
            return state;
    }
}
