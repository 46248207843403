import React from "react";
import PropTypes from "prop-types";
// import dateformat from "dateformat";
import moment from "moment";

import "./Calendar.scss";

class Calendar extends React.Component {

    render() {
        let {
            date,
            style = {}
        } = this.props;

        if (!(date instanceof Date)) {
            return null;
        }

        // Reformat the date to UTC and strip off the time
        let str_date = moment(moment.utc(date).format("MM-DD-YYYY"), "MM-DD-YYYY");

        let month = moment(str_date, "MM-DD-YYYY").format("MMM");
        if (moment(str_date, "MM-DD-YYYY").year() !== moment().year()) {
            month = moment(str_date, "MM-DD-YYYY").format("MMM YYYY");
        }

        return (
            <time dateTime={moment(str_date, "MM-DD-YYYY").format("MM-DD-YYYY")} className="icon" style={style}>
                <em>{moment(str_date, "MM-DD-YYYY").format("dddd")}</em>
                <strong>{month}</strong>
                <span>{moment(str_date, "MM-DD-YYYY").format("D")}</span>
            </time>
        );
    }
}

Calendar.propTypes = {
    date: PropTypes.object,
    style: PropTypes.object
};

export default Calendar;