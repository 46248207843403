import _ from "lodash";
import escape from "escape-regexp";

export default function (match_tags) {
  if (_.isArray(match_tags)) {
    match_tags = _.map(match_tags, (tag) => (
        _.has(tag, ["label"]) ? _.get(tag, ["label"]) : tag
    ))
  }
  //split multiple words apart, so it can highlight individual words out of order
  let split_match_tags = _.flatten(_.map(match_tags, function (tag) {
      if ("string" !== typeof tag) { return tag; }
      return tag.split(" ");
    })
  );
  let regexps = _.map(split_match_tags, function (tag) {
    return new RegExp("(" + escape(String(tag).trim().toLowerCase()) + ")", "gi");
  });

  return function (text) {
    return _.reduce(regexps, function (memo, rg) {
      return _.filter(_.flattenDeep(_.map(memo, function (text) {
        if (!_.isString(text)) {
          return text;
        }
        return _.map(text.split(rg), function (c, i) {
          return i % 2 !== 0
            ? {highlight: c}
            : c;
        });
      })), function (c) {
        return !_.isEmpty(
          _.has(c, "highlight")
            ? c.highlight
            : c
        );
      });
    }, [text]);
  };
};