import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { gettext as __ } from "../../../utils/Trans";
import AgreementInfo from "../components/AgreementInfo";
import Recommendation from "../components/Recommendation";
import { PayBalanceButton, ViewInvoiceButton, ShareInvoiceButton } from "../components/Buttons";
import readRecommendation from "../../../actions/Speaker/readRecommendation";

// import { OFFER_STATII } from "../../../utils/getEventOfferStatusFromState";

class PostEventStep extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (!this.props.recommendation) {
            this.props.loadRecommendation();
        }
    }

    render() {

        let {
            event,
            speaker,
            agreement,
            payment,
            recommendation
        } = this.props;

        let total_unpaid = _.reduce(payment, function (sum, item) {
            if (!_.get(item, ["paid"], false)) {
                return sum + parseFloat(_.get(item, ["fee"], 0));
            }
            return sum;
        }, 0);

        return (
            <div>
                {total_unpaid > 0 &&
                <div>
                    <div className="alert alert-info" style={{margin: "1em 0em"}}>
                        <div className="row">
                            <div className="col-sm-9">
                                <i className="fa fa-exclamation-triangle"/>{" "}
                                {__("You have a balance due.  Pay it today to fulfill your contract with the speaker.")}
                            </div>
                            <div className="col-sm-3 text-right">
                                <PayBalanceButton event={event} speaker={speaker} agreement={agreement} payment={payment} {...this.props} />
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>
                }

                <Recommendation
                    event={event}
                    speaker={speaker}
                    recommendation={recommendation}
                />

                {/*<WhenIsYourNextEvent />*/}

                {/*<hr style={{margin: "2em 0"}}/>*/}



                <hr style={{margin: "2em 0"}}/>

                <AgreementInfo
                    event={event}
                    speaker={speaker}
                    agreement={agreement}
                    payment={payment}
                    show_fees_in_area={true}
                />

                <div className="row">
                    <div className="col-sm-9">
                        {total_unpaid > 0 && <PayBalanceButton event={event} speaker={speaker} agreement={agreement} payment={payment} {...this.props} />}
                        {" "}
                        <ViewInvoiceButton {...this.props} />
                        {total_unpaid > 0 && <div className="alert alert-warning" style={{margin: "1em 0em"}}>
                            {__("You have a balance due.  Pay it today to fulfill your contract with the speaker.")}
                        </div>}
                    </div>
                    <div className="col-sm-3 text-right">
                        <ShareInvoiceButton {...this.props} />
                    </div>
                </div>

                <hr style={{margin: "2em 0"}}/>
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

const mapStateToProps = (state, ownProps) => {
    let recommendation = _.get(state, ["BuyerEvents", _.get(ownProps.event, ["eid"]), "recommendation", _.get(ownProps.speaker, ["sid"])])

    return {
        recommendation: recommendation
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        loadRecommendation: function() {
            return dispatch(readRecommendation({
                event_id: _.get(ownProps.event, ["eid"]),
                speaker_id: _.get(ownProps.speaker, ["sid"])
            }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostEventStep);