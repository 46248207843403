import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { gettext as __ } from "../../../../utils/Trans";
import { Message } from "../../../../components/FormComponents";
import LoadingSubmit from "../../../../components/LoadingSubmit";
import InlineError from "../../../../components/InlineError";
import cancelEvent from "../../../../actions/ShortList/cancelEvent";
import { withRouter } from "react-router-dom";

class CancelEventForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch, props) {
        return dispatch(cancelEvent(values))
            .then(() => {
                dispatch({
                    type: "NOTIFICATION_CREATE",
                    message: "Your event has been canceled and removed."
                });
                props.history.push("/event");
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
                handleSubmit,
                submitting,
                error,
                onClose
            } = this.props;

        return (
            <Form role="form" onSubmit={handleSubmit(this.handleSubmit)}>
                <div className="modal-body">
                    {error && <InlineError error={_.get(error, ["message"])}/>}
                    <div className="row">
                        <div className="col-sm-12">
                            <p>
                                {__("We will let the candidates know that the event is not happening. A short explanation of why is a courtesy to them:")}
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <Field
                                name="message"
                                type="textarea"
                                className="form-control"
                                placeholder={__("I am canceling this speaking job due to ...")}
                                component={Message}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <LoadingSubmit is_saving={submitting} save_text={__("Cancelling...")} className="btn-danger btn-block-xs">
                        {__("YES, Cancel this Job")}
                    </LoadingSubmit>
                    {" "}
                    <div className="btn btn-default btn-block-xs" disabled={submitting} onClick={onClose}>
                        {__("Never Mind")}
                    </div>
                </div>
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

CancelEventForm.propTypes = {
    event: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

function mapStateToProps(state, ownProps) {
    return {
        initialValues: {
            message: "",
            event_id: _.get(ownProps.event, ["eid"]),
            bureau_id: _.get(ownProps.event, ["bureauID"])
        }
    };
}

function mapDispatchToProps() {
    return {};
}

CancelEventForm = reduxForm({
    form: "shortlist_page_cancel_event",
    validate: function (values) {
        const errors = {};
        if (!values.message) {
            errors.message = __("Please enter a message about why you are cancelling this event.");
        }
        return errors;
    }
})(CancelEventForm);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(CancelEventForm));