import _ from "lodash";
import phpEncode from "urlencode-for-php";
import Oauth from "./Oauth";
import Balboa from "../../Global/Balboa";
import {sessionService} from "redux-react-session";
import Constants from "../../../utils/Constants";

// const client_id = "317853371658";
// const secret = "0ca7451e045d40b5e72baa74ae13c193";
const client_id = "898916891969328";
const secret = "dff7ade0b7d5312d5b28f3a37a3e960d";

export default _.assign({}, Oauth, {

    signin: function(code, state, callback_url) {
        let self = this;
        return (dispatch, getState) => {
            let key = ["Facebook_Oauth"];
            return self.exchange(code, callback_url)
                .then((access_token) => {
                    return self.profile(access_token);
                })
                .then((profile) => {
                    let params = {
                        service: "facebook",
                        access_token: _.get(profile, ["access_token"]),
                        state: state,
                        id: _.get(profile, ["id"]),
                        first_name: _.get(profile, ["first_name"]),
                        last_name: _.get(profile, ["last_name"]),
                        email: _.get(profile, ["email"]),
                        gender: _.get(profile, ["gender"]),
                        auth_only_user_type: 'buyer'
                    };

                    return Balboa(dispatch, getState)
                        .post(key, "/oauth2/signinoauth", params, {cache_ttl: -1})
                        .then(function(response) {
                            dispatch({
                                type: "BALBOA3_CLEAR_KEY",
                                key: key
                            });
                            return response;
                        })
                        .then(function(response) {
                            sessionService.saveSession(_.get(response, ["data", "token"]));
                            sessionService.saveUser(_.assign({}, _.get(response, ["data", "user"]), {token: _.get(response, ["data", "token"])}));
                            // return response;
                        });
                });
        };
    },

    /**
     * Convert the initiate token into an authorization token
     * @param params
     * @returns {string}
     */
    initiate: function(redirect_url, whitelabel) {
        let params = {
            response_type: "code",
            client_id: client_id,
            redirect_uri: this.getCallbackUrl("facebook", whitelabel),
            state: this.getOauthState(_.get(whitelabel, ["bid"], Constants.TRUNK_BUREAU_ID), redirect_url),
            scope: "email,public_profile"
        };
        return "https://www.facebook.com/dialog/oauth?" + phpEncode(params);
    },

    /**
     * Convert the initiate token into an authorization token
     * @param callback_url
     * @param code
     * @returns {Promise}
     */
    exchange: function(access_token, callback_url) {
        let default_params = {
            client_id: client_id,
            client_secret: secret,
            redirect_uri: callback_url,
            code: access_token
        };

        let url = "https://graph.facebook.com/oauth/access_token?" + phpEncode(default_params);

        return fetch(
            url,
            {
                method: "GET",
                headers: {
                    // "Content-Type": "application/x-www-form-urlencoded"
                },
                // body: phpEncode(default_params),
            })
            .then((resp) => {
                return resp.json();
            })
            .then((resp) => {
                if (_.has(resp, ["error"])) {
                    throw new Error(_.get(resp, ["error", "message"], "Invalid response from FB.Oauth"));
                }
                if (!_.has(resp, ["access_token"])) {
                    throw new Error("Invalid token from FB.Oauth");
                }
                return _.get(resp, ["access_token"]);
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    },

    /**
     * Shortcut to either add or update a record based on whether the id is > 0
     * @param params
     * @returns {Function}
     */
    profile: function(access_token) {
        return fetch(
            "https://graph.facebook.com/me?fields=id,first_name,last_name,email&access_token=" + access_token,
            {
                method: "GET",
                headers: {
                    // "Content-Type": "application/x-www-form-urlencoded",
                    // "Authorization": "Bearer " + access_token
                }
            })
            .then((resp) => {
                return resp.json();
            })
            .then((json) => {
                return _.assign({}, json, {access_token: access_token});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }
});