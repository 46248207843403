import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import getSpeakerAvailability from "../../../../actions/Speaker/getSpeakerAvailability";
import LoadingBar from "../../../../components/LoadingBar/LoadingBar";
import moment from "moment";

class AvailabilityCalendarTable extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            calendar
        } = this.props;

        let rows = _.get(calendar, ["rows"], []);
        let cols = _.get(calendar, ["cols"], []);
        let titles = _.get(calendar, ["titles"], []);

        if (_.size(rows) === 0) {
            return (
                <div>
                    {__("Inquire for availability.")}
                </div>
            );
        }

        return (
            <table className="table">
                <thead>
                    <tr>
                        {_.map(cols, function (col, i) {
                            if (col === "STATUS") { return null; }
                            if (col === "DATE") {
                                return (<th key={i} style={{width:"125px"}}>{_.upperCase(_.get(titles, [col]))}</th>);
                            } else if (col === "TIME") {
                                return (<th key={i} style={{width:"90px"}}>{_.upperCase(_.get(titles, [col]))}</th>);
                            } else {
                                return (<th key={i}>{_.upperCase(_.get(titles, [col]))}</th>);
                            }
                        })}
                    </tr>
                </thead>
                <tbody>
                {_.map(_.get(calendar, ["rows"]), function (event, i) {
                    return (
                        <tr key={i}>
                            {_.map(_.get(calendar, ["cols"]), function (col, j) {
                                if (col === "STATUS") { return null; }
                                if (col === "DATE") {
                                    if (_.has(event, ["ENDDATE"]) && (_.get(event, col) !== _.get(event, "ENDDATE"))) {
                                        return (
                                            <td key={j}>
                                                {moment(_.get(event, col), "MM/DD/YYYY").format(moment(_.get(event, col), "MM/DD/YYYY").isSame(moment(), "year") ? "MMM D" : "MMM D, Y")}{" - "}
                                                {moment(_.get(event, "ENDDATE"), "MM/DD/YYYY").format(moment(_.get(event, "ENDDATE"), "MM/DD/YYYY").isSame(moment(), "year") ? "MMM D" : "MMM D, Y")}
                                            </td>
                                        );
                                    } else {
                                        return (<td key={j}>{moment(_.get(event, col), "MM/DD/YYYY").format(moment(_.get(event, col), "MM/DD/YYYY").isSame(moment(), "year") ? "MMM D" : "MMM D, Y")}</td>);
                                    }
                                }
                                return (<td key={j}>{_.get(event, col)}</td>);
                            })}
                        </tr>
                    );
                })}
                </tbody>
            </table>
        );
    }
}

AvailabilityCalendarTable.propTypes = {
    calendar: PropTypes.object
};

class Availability extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            is_loading: true,
            profile_calendar: null
        };
    }

    UNSAFE_componentWillMount() {
        let self = this;
        if (_.isNull(this.state.profile_calendar)) {
            this.props.loadAvailability()
                .then((response) => {
                    self.setState({
                        profile_calendar: _.get(response, ["data", "calendar"]),
                        is_loading: false
                    });
                });
        }
    }

    UNSAFE_componentWillReceiveProps = () => {
        let self = this;
        if (_.isNull(this.state.profile_calendar)) {
            this.props.loadAvailability()
                .then((response) => {
                    self.setState({
                        profile_calendar: _.get(response, ["data", "calendar"]),
                        is_loading: false
                    });
                });
        }
    }

    render() {
        return (
            <div>
                <h3 className="header">{__("Calendar")}</h3>
                {this.state.is_loading &&
                    <LoadingBar />
                }
                {!this.state.is_loading && !this.state.profile_calendar &&
                    <div>{__("Direct message for availability")}</div>
                }

                {!this.state.is_loading && this.state.profile_calendar &&
                <AvailabilityCalendarTable calendar={this.state.profile_calendar}/>
                }
            </div>
        );
    }
}

Availability.propTypes = {
    speaker: PropTypes.object
};


////////////////////////////////////////////////////////////////////////////////
//Connect it up

let mapStateToProps = function() {
    return {
    };
};

var mapDispatchToProps = function(dispatch, ownProps){
    let sid = _.get(ownProps.speaker, ["sid"]);
    return {
        loadAvailability: function() {
            return dispatch(getSpeakerAvailability(sid));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Availability);