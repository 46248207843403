import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { gettext as __ } from "../../utils/Trans";
import formatPhone from "../../utils/formatPhone";
import User from "../../actions/User/User";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { EmailConsent, Password, Text, Username } from "../../components/FormComponents";
import isValidEmail from "../../utils/isValidEmail";
import InlineError from "../../components/InlineError";
import OauthButtons from "../../components/OauthButtons";
import CustomerService from "../../components/CustomerService";
import Analytics from "../../utils/Analytics";
import ReCAPTCHA from "react-google-recaptcha";
import RecaptchaPassed from '../../actions/Recaptcha/Recaptcha';

let or_img = require("./or.png");

class RegisterForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch, props) {

        if (!props.captchapassed) return;

        return dispatch(User.signup(values))
            .then(() => {
                props.history.push(props.redir || "/event");
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }


    LoginOnClick()
    {
        Analytics.sendEvent("BuyerLogin", "click", 0, "signup_form");
    }


    render() {
        let {
            handleSubmit,
            submitting,
            error,
            location,
            whitelabel,
            setCaptchaPassed,
            captchapassed
        } = this.props;

        return (
            <div>
                <Form className="mp-style-form" onSubmit={handleSubmit(this.handleSubmit)}>
                    <div className="row">
                        <div className="col-sm-6">
                            <Field
                                label={__("First name")}
                                name="firstname"
                                type="text"
                                component={Text}
                            />
                        </div>
                        <div className="col-sm-6">
                            <Field
                                label={__("Last name")}
                                name="lastname"
                                type="text"
                                component={Text}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <Field
                                label={__("Company")}
                                name="company"
                                type="text"
                                component={Text}
                            />
                        </div>
                        <div className="col-sm-6">
                            <Field
                                label={__("Job Title")}
                                name="job_title"
                                type="text"
                                component={Text}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <Field
                                label={__("Phone")}
                                name="phone_number"
                                type="text"
                                component={Text}
                                format={formatPhone}
                            />
                        </div>
                        <div className="col-sm-6">
                            <Field
                                label={__("Email")}
                                name="email"
                                type="text"
                                component={Username}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <Field
                                label={__("Password")}
                                name="password"
                                component={Password}
                            />
                        </div>
                        <div className="col-sm-6">
                            <Field
                                label={__("Confirm Password")}
                                name="confirm_password"
                                component={Password}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-8">
                            <ReCAPTCHA
                                ref="recaptcha"
                                sitekey="6Ld6CAMTAAAAAIKCRhPspREtTRBxhBN79f6R7pVE"
                                onChange={setCaptchaPassed}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <Field
                                label={__("I agree to receive email related to my event and to this service.")}
                                name="email_consent"
                                type="checkbox"
                                component={EmailConsent}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-4">
                            <button type="submit" className="btn btn-success btn-block btn-lg" disabled={submitting || !captchapassed}>
                                {submitting ? <i className="fa fa-spin fa-circle-o-notch" /> : <i/>} {__("SIGN UP")}
                            </button>
                        </div>
                        <div className="col-sm-8" style={{lineHeight:"46px"}}>
                            <Link to={_.assign({}, location, {pathname: "/signin"})} onClick={this.LoginOnClick.bind(this)}>{__("Already have an account?  Sign in")}</Link>
                        </div>
                    </div>
                    {error && <InlineError error={_.get(error, ["message"])} />}
                    {/*{submitSucceeded && <Redirect to={_.get(location, ["state", "redirect"], "/event")} />}*/}
                </Form>
                {_.get(whitelabel, ["boosters", "MP_USE_OAUTH"], false) && <>
                    <div className="text-center">
                        <img
                          src={or_img}
                          style={{
                              height: "3em",
                              width: "auto",
                              marginTop: 40,
                              marginBottom: 40
                          }}
                          alt="or"
                        />
                    </div>
                    <div className="">
                        <OauthButtons {...this.props} />
                    </div>
                </>}
                <div style={{height: "2em"}}/>
                {whitelabel && <CustomerService whitelabel={whitelabel}/>}
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

let mapStateToProps = function (state, ownProps) {
    return {
        redir: _.get(ownProps.location, ["state", "redirect"]),
        captchapassed: _.get(state, ["Recaptcha", "captchapassed"], false),
        captchavalue: _.get(state, ["Recaptcha", "captchavalue"], false),
        initialValues: {
            redir: _.get(ownProps.location, ["state", "redirect"]),
            bureau_id: _.get(ownProps.whitelabel, ["bid"])
        }
    };
};

let mapDispatchToProps = function (dispatch) {
    return {
        setCaptchaPassed: function (captchavalue) {
            dispatch(RecaptchaPassed(captchavalue));
        },
    };
};

RegisterForm = reduxForm({
    form: "signuppage_register",
    validate: function(values) {
        const errors = {};
        if (!values.firstname) {
            errors.firstname = __("Please enter your first name.");
        }
        if (!values.lastname) {
            errors.lastname = __("Please enter your last name.");
        }
        if (!values.email) {
            errors.email = __("Please enter your email address.");
        }
        if (!isValidEmail(values.email)) {
            errors.email = __("Please enter a valid email address.");
        }
        if (!values.password) {
            errors.password = __("Please enter your password.");
        }
        if (!values.job_title) {
            errors.job_title = __("Please enter your job title.");
        }
        if (!values.company) {
            errors.company = __("Please enter your company name.");
        }
        if (!values.phone_number) {
            errors.phone_number = __("Please enter your phone number so the speaker can contact you.");
        }
        if (!values.email_consent) {
            errors.email_consent = __("Email communication is an essential part of hiring a speaker. Please consent to receive email to continue.");
        }
        if (values.password && values.confirm_password && (values.password !== values.confirm_password)) {
            errors.confirm_password = __("Passwords do not match.");
        }

        let min_password_length = 5;
        if (values.password && values.password.length < min_password_length) {
            errors.password = __("You must enter a password that is at least %s characters long.", min_password_length);
        }

        let min_phone_length = 5;
        if (values.phone_number) {
            let cleaned = values.phone_number.replace(/\D+/g, "");
            if (cleaned.length < min_phone_length) {
                errors.phone_number = __("Please enter a valid phone number.");
            }
        }
        return errors;
    }
})(RegisterForm);

export default connect(mapStateToProps, mapDispatchToProps)(RegisterForm);
