import _ from "lodash";
import React from "react";
import Helmet from "react-helmet";
import moment from "moment";
import { gettext as __ } from "../../utils/Trans";
import classnames from "classnames";

export default class MlkPage extends React.Component {

    state = {
        share_url: "<p><a href=\"https://www.espeakers.com/marketplace/ihaveadream\"><img src=\"https://streamer.espeakers.com/static/marketplace/i_have_a_dream.jpg\" width=\"750\" border=\"0\" alt=\"I have a dream speech\"/></a></p>",
        years_ago: _.toNumber(moment().format("Y")) - 1963,
        copied: false
    }

    componentDidMount() {
        setTimeout( () => {
            var addthisScript = document.createElement("script");
            addthisScript.setAttribute("src", "//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-521bbdcb4df58261");
            if (document.body) { document.body.appendChild(addthisScript); }
        });
    }

    __copyToClipboard = () => {
        // Copy the share url to the clipboard
        navigator.clipboard.writeText(this.state.share_url);
        // Set the copy state
        this.setState({
            copied: true
        });
        // Reset the copy state after 10 seconds
        setTimeout(() => (
          this.setState({
              copied: false
          })
        ), 10000)
    }

    render() {
        const {
          share_url,
          years_ago,
          copied
        } = this.state;

        return (
            <div className="row">
                <Helmet>
                    <title>{"Celebrating  " + years_ago + " years of the \"I Have A Dream Speech\" Infographic | eSpeakers"}</title>
                </Helmet>
                <div className="col-sm-10 col-xs-12">
                    <h1 className="page-title">
                        DR. MARTIN LUTHER KING, JR.
                        <div className="small">{"Celebrating  " + years_ago + " years of the \"I Have A Dream Speech\" Infographic"}</div>
                    </h1>
                    <hr />
                    <p>{years_ago}{" "}{__("years ago, Martin Luther King Jr. gave one of the most famous and influential speeches in American history. The \"I Have a Dream\" speech was effective not just for its words, but also for Dr. King's impassioned delivery. It represented the feelings of millions of people fighting for civil liberties. The speech, given by a lesser man in a lesser setting may not have earned the same attention. Dr. King knew if he were to truly help bring about change, he would need a speech and setting that would inspire. The March on Washington and \"I Have a Dream\" speech caught the attention of a nation, and brought it closer to the much-needed change.")}</p>
                    <p>{__("eSpeakers believes in the power of great speeches like the \"I Have a Dream\" speech, and in great speakers like Dr. Martin Luther King, Jr. To honor his speech given 55 years ago, eSpeakers has created an infographic in commemoration of that great moment in American history. You can view the infographic below.")}</p>
                    <p>{__("To find great and inspiring speakers for your own event, consider searching")}{" "}<a href="/marketplace/search">{__("eSpeakers Marketplace.")}</a></p>
                    <div className="well well-default text-center">
                        <img src="https://streamer.espeakers.com/static/marketplace/i_have_a_dream.jpg" alt="martin luther king jr I have a dream infographic" className="img-responsive center-block" />
                    </div>
                    <hr />
                    <h3>SHARE THIS IMAGE ON YOUR SITE</h3>
                    <div className="panel panel-default" style={{padding: "20px 20px", fontFamily: "Courier New"}}>
                        &lt;p&gt;&lt;a href="https://www.espeakers.com/marketplace/ihaveadream"&gt;&lt;img src="https://streamer.espeakers.com/static/marketplace/i_have_a_dream.jpg" width='750' border='0' alt="I have a dream speech" /&gt;&lt;/a&gt;&lt;/p&gt;
                    </div>
                    <div className={classnames(['btn', {'btn-default': !copied}, {'btn-success': copied}])} style={{margin: "0 0 1rem 0"}} onClick={this.__copyToClipboard}>{copied ? "Copied" : "Copy HTML"}</div>
                </div>
                <div className="col-sm-2 hidden-xs">
                    <h4>SHARE THIS IMAGE ON YOUR SITE</h4>
                    <textarea rows="20" readonly="readonly" style={{fontFamily: "Courier New"}} className="form-control" defaultValue={share_url} />
                    <div className={classnames(['btn', {'btn-default': !copied}, {'btn-success': copied}])} style={{margin: "1rem 0 1rem 0"}} onClick={this.__copyToClipboard}>{copied ? "Copied" : "Copy HTML"}</div>
                    <div className="span2">
                        <div className="addthis_toolbox addthis_floating_style addthis_counter_style">
                            <span class="addthis_counter" />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}