import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

import "./FlashMessage.scss";

class FlashMessage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            is_visible: false
        };
    }

    onClick = () => {
        this.setState({is_visible: false});
        if (_.isFunction(this.props.onClick)) {
            this.props.onClick();
            if (this.hide_timer) {
                window.clearInterval(this.hide_timer);
            }
        }
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        let self = this;
        if (!_.isEmpty(nextProps.children)) {
            this.setState({is_visible: true});

            // Reset the auto-hide timer
            window.clearInterval(this.hide_timer);
            this.hide_timer = _.delay(function () {
                self.onClick();
            }, 10000);
        } else {
            this.setState({is_visible: false});
        }
    }

    componentDidMount = () => {
        // After the component mounts, then show it
        let self = this;
        this.setState({is_visible: true});
        if (!_.isEmpty(this.props.children)) {
            this.hide_timer = _.delay(function () {
                self.onClick();
            }, 10000);
        }
    }

    componentWillUnmount = () => {
        window.clearInterval(this.hide_timer);
    }

    render() {
        let {
            className,
            children
        } = this.props;

        let show_me = this.state.is_visible && !_.isEmpty(children) ? "flash-show" : "";

        if (_.isEmpty(children)) {
            return  null;
        }

        return (
            <div className={"flash-wrapper " + show_me}>
                <div className="flash-message" onClick={this.onClick}>
                    <div className={"alert " + className}>
                        <button type="button" className="close" aria-label="Close" onClick={this.onClick}><span aria-hidden="true">&times;</span></button>
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

FlashMessage.propTypes = {
    position: PropTypes.string,
    className: PropTypes.string,
    children: PropTypes.node
};

export default FlashMessage;