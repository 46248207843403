import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import { connect } from "react-redux";

class CollaboratorAccess extends React.Component {

    componentDidMount() {
        this.props.onShowCollaboratorAccess();
    }

    render() {
        return null;
    }
}

CollaboratorAccess.propTypes = {
    collaborator: PropTypes.object
};

////////////////////////////////////////////////////////////////////////////////
//Connect it up

const mapStateToProps = function (state, ownProps) {
    return {};
}

const mapDispatchToProps = function (dispatch, ownProps) {
    return {
        onShowCollaboratorAccess: function() {
            if (_.includes(_.get(ownProps.collaborator, ["permission"]), "read")) {
                let message = __("You have been invited to collaborate on this shortlist.  You can add notes and review the candidates below. ");
                if (_.includes(_.get(ownProps.collaborator, ["permission"]), "control")) {
                    message +=
                        <span>{__("You can also share this shortlist with others and message the speakers about their availability.")}</span>;
                }

                return dispatch({
                    type: "NOTIFICATION_CREATE",
                    message: message
                });
            }
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(CollaboratorAccess);