import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import SubAuthed from "../SubAuthed";
import FlashError from "../FlashError";
import FlashNotification from "../FlashNotification";
import { Panel } from "react-bootstrap";
import { Link } from "react-router-dom";
import { gettext as __ } from "../../../utils/Trans";

import "./CollabLayout.scss";

class CollabLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            user,
            whitelabel,
            children,
            use_panel = true,
            back_to_search,
            back_to_search_text
        } = this.props;

        let back_btn;
        if (back_to_search) {
            back_btn = (
                <div className="row row-fluid" style={{margin: "20px 0px"}}>
                    <div className="col-sm-12 text-left visible-xs visible-sm">
                        <Link to={back_to_search} className="btn btn-default btn-link">
                            <i className="fa fa-chevron-left"/>{" "}{__(back_to_search_text)}
                        </Link>
                    </div>
                    <Link to={back_to_search} className="btn btn-link btn-default hidden-xs hidden-sm"
                          style={{marginRight: "10px"}}>
                        <i className="fa fa-chevron-left"/>{" "}{__(back_to_search_text)}
                    </Link>
                </div>
            );
        }

        return (
            <div style={{backgroundColor:"#E8E8E8", height:"100%"}}>
                <Helmet>
                    <title>The Right Speaker, Every Time | {_.get(whitelabel, ["bureauName"], "eSpeakers")}</title>
                </Helmet>
                <FlashNotification />
                <FlashError />
                <SubAuthed user={user} />
                <div className={"container" + ((_.get(whitelabel, ["is_bureau_mode"], false)) ? " bureaumode" : "")}>
                    {back_to_search && back_btn}
                    {use_panel
                        ?
                        <Panel>
                            <Panel.Body>
                                {children}
                            </Panel.Body>
                        </Panel>
                        : <div>{children}</div>
                    }
                </div>
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up
var mapStateToProps = function(state, ownProps){

    let back_to_search = _.get(ownProps.location, ["state", "bts"]);
    let back_to_search_text = _.get(ownProps.location, ["state", "btst"]);

    // Page Properties
    return {
        whitelabel: (_.get(state, "whitelabel", {})),
        user: (_.get(state, "User", {})),
        back_to_search: back_to_search,
        back_to_search_text: back_to_search_text
    };
};

export default connect(mapStateToProps)(CollabLayout);
