import _ from "lodash";
import Balboa from "./Balboa";

export default function (params) {
  let key = "Sitelists_" + _.get(params, ["bcode"]);
  const CACHE_TTL_MINUTES = 480;

  return function (dispatch, getState) {
    return new Promise((resolve /*, reject */) => {

      // Attempt to load the sitelists from the localStorage first, if the cached copy is fresh enough
      if (window.localStorage && _.has(window.localStorage, [key]) && ((_.now() - _.get(window.localStorage, [key + "_tstamp"], 0)) < (1000 * 60 * CACHE_TTL_MINUTES))) {
        let sitelists;
        try {
          sitelists = JSON.parse(_.get(window.localStorage, [key]));
          return resolve(_.get(sitelists, ["sitelists"], sitelists));
        } catch (e) { /* parse the local storage and return it early.  if it fails, just do a balboa call */
        }
      }

      //no recently cached copy, so let's fetch a new one
      return Balboa(dispatch, getState).get(key, "/marketplace/sitelist", params, {cache_ttl: (1000 * 60 * CACHE_TTL_MINUTES)})
      .then(function (response) {
        resolve(_.get(response, ["data"]));
      });
    })
    .then(function (response) {
      dispatch({
        type: "SITELISTS_LOAD",
        data: response
      });
      return response;
    })
    .then(function (response) {
      // save the site lists into localstorage for faster retrieval
      if (window.localStorage) {
        window.localStorage[key] = JSON.stringify(response);
        window.localStorage[key + "_tstamp"] = _.now();
      }
    })
    .then(function (response) {
      dispatch({
        type: "BALBOA3_CLEAR_KEY",
        key: key
      });
      return response;
    });
  };
};