import React from "react";
import Counter from "../../../../components/Counter";
import { gettext as __ } from "../../../../utils/Trans";

class EventsBooked extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            onShowDirectories
        } = this.props;

        return (
            <div className="well well-default">
                <h3>
                    {__("EVENTS BOOKED")}
                    <div className="small">{__("THROUGH eSpeakers SOFTWARE IN 2016")}</div>
                </h3>
                <center>
                    <Counter num="021890" version={1} />
                </center>
                {/*<div className="smallCounterWrap">*/}
                    {/*<div>*/}
                        {/*<div className="cntDigitSmall" style={{marginTop: "0px"}}></div>*/}
                        {/*<div className="cntDigitSmall" style={{marginTop: "-462px"}}></div>*/}
                        {/*<div className="cntDigitSmall" style={{marginTop: "-231px"}}></div>*/}
                        {/*<div className="cntDigitSmall" style={{marginTop: "-1848px"}}></div>*/}
                        {/*<div className="cntDigitSmall" style={{marginTop: "-2079px"}}></div>*/}
                        {/*<div className="cntDigitSmall" style={{marginTop: "0px"}}></div>*/}
                    {/*</div>*/}
                {/*</div>*/}
                <div className="clearfix" />
                <h3>
                    <span>{__("FEATURING")}</span>
                    <br />
                    <span>{__("SPEAKERS FROM")}</span>
                </h3>
                <ul className="icons associations" style={{
                    listStyle:"none",
                    lineHeight: "1.4em",
                    textIndent: "0",
                    paddingLeft: "0px",
                    paddingBottom: "5px",
                    position: "relative"
                }}>
                    <li>
                        <i className="fa fa-plus fa-fw"></i> National Speakers Association (USA)
                    </li>
                    <li>
                        <i className="fa fa-plus fa-fw"></i> Canadian Association of Professional Speakers
                    </li>
                    <li>
                        <i className="fa fa-plus fa-fw"></i> Professional Speakers Australia
                    </li>
                    <li>
                        <i className="fa fa-plus fa-fw"></i><span className="link" onClick={onShowDirectories}>{__("And Many More...")}</span>
                    </li>
                </ul>
            </div>
        );
    }
}

export default EventsBooked;