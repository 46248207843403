import _ from "lodash";

export default function(state = {}, action){
    switch(action.type) {
        case "DISPLAY_LIST_LOAD": {
            let new_state = _.cloneDeep(state);
            _.set(new_state, "displaylists", action.data);
            return _.assign({}, state, new_state);
        }
        case "MARKETPLACE_SITELIST_LOAD": {
            let new_state = _.cloneDeep(state);
            _.set(new_state, "mpsitelists", action.data);
            return _.assign({}, state, new_state);
        }
        case "MARKETPLACE_METATOPICS_LOAD": {
            let new_state = _.cloneDeep(state);
            _.set(new_state, "metatopics", action.data);
            return _.assign({}, state, new_state);
        }
        case "TIPTEXT_LOAD": {
            let new_state = _.cloneDeep(state);
            _.set(new_state, "tiptext", action.data);
            return _.assign({}, state, new_state);
        }
        default:
            return state;
    }
}