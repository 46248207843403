import _ from "lodash";
import React from "react";
import SpeakerRow from "../../components/SpeakerRow";

export default class SharedShortlist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_collaborator_alert: true
        };
    }

    __onDismissCollaboratorAlert = () => {
        this.setState({
            show_collaborator_alert: false
        });
    }

    __onCreateJob = () => {
        if (_.isFunction(this.props.onCreateJob)) {
            return this.props.onCreateJob();
        }
    }

    __onShare = () => {
        if (_.isFunction(this.props.onShare)) {
            return this.props.onShare();
        }
    }

    render() {
        let {
                // affiliate_event,
                collaborator,
                shortlist,
                speakers,
                awards,
                languages,
                onWatchVideo,
                onProfile,
                onReview,
                onRemove,
                onViewRatings
            } = this.props;

        let collaborators = _.get(shortlist, ["collaborators"]);
        let ratings = _.keyBy(_.map(_.get(shortlist, ["speakers"]), function (spkr) {
            let ratings = _.map(_.get(spkr, ["ratings"]), function (rating) {
                let collaborator_id = _.get(rating, ["mp_collaborator_id"]);
                let collaborator = _.head(_.filter(collaborators, {id: collaborator_id}));
                return _.assign({}, rating, {collaborator: collaborator});
            });
            return _.assign({}, spkr, {ratings: ratings});
        }), "speaker_id");

        let shortlisted_speakers = _.reverse(_.sortBy(_.map(_.get(shortlist, ["speakers"]), function (item) {
            return _.assign({}, item, {
                rank: _.get(_.head(_.filter(_.get(item, ["ratings"]), {mp_collaborator_id: _.get(collaborator, ["id"])})), ["rating"], 0),
                rating: _.head(_.filter(_.get(item, ["ratings"]), {mp_collaborator_id: _.get(collaborator, ["id"])}))
            });
        }), "rank"));

        if ((_.get(collaborator, ["permission"], 0) & 1) === 0) {
            // collaborator doesn't have permission to review speakers, so don't show that option
            onReview = false;
        }
        if ((_.get(collaborator, ["permission"], 0) & 4) === 0) {
            // collaborator doesn't have permission to remove speakers, so don't show that option
            onRemove = false;
        }

        let shortlist_div = _.map(shortlisted_speakers, function (spkr) {
            let profile = _.get(speakers, [_.get(spkr, ["speaker_id"])]);
            let speaker = _.assign({}, profile, {
                ratings: _.get(ratings, [_.get(spkr, ["speaker_id"]), "ratings"]),
                my_rating: _.get(spkr, ["rating"]),
                shortlist: spkr
            });
            if (!profile) {
                return null;
            }
            return (
                <div key={_.get(spkr, ["id"])} className="col-xs-10 col-xs-offset-1">
                    <SpeakerRow
                        awards={awards}
                        languages={languages}
                        speaker={speaker}
                        onProfile={onProfile}
                        onViewPrograms={() => {
                            return false;
                        }}
                        onViewSchedule={() => {
                            return false;
                        }}
                        onWatchVideo={onWatchVideo}
                        onAddComments={() => {
                            return false;
                        }}
                        onReview={onReview}
                        onViewRatings={onViewRatings}
                        onRemove={onRemove}
                        is_debug={false}
                        is_shortlisted={true}
                    />
                </div>
            );
        });

        if (_.size(speakers) === 0) {
            shortlist_div = <div className="text-center col-xs-12" style={{margin: "40px auto"}}>
                <i className="fa fa-spin fa-circle-o-notch fa-4x"/>
                <div>Loading speakers...</div>
            </div>;
        }

        return (
            <div>
                {((_.get(collaborator, ["permission"], 0) & 8) > 0) &&
                <div>
                    <div className="row">
                        <div className="text-center col-sm-12">
                            <b>What to do next?</b> If you like these suggestions, you should message the speakers regarding
                                                    their availability for your event.
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-6">
                            <div className="btn btn-success btn-lg" onClick={this.__onCreateJob}>Check Speaker Availability{" "}<i
                                className="fa fa-chevron-right"/></div>
                        </div>
                    </div>
                </div>
                }
                {((_.get(collaborator, ["permission"], 0) & 8) === 0) && ((_.get(collaborator, ["permission"], 0) & 4) > 0) &&
                <div>
                    <div className="row">
                        <div className="text-center col-sm-12">
                            <b>What to do next?</b> If you like these suggestions, you should share them with your team and
                                                    ask for their feedback.
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="btn btn-success btn-lg" onClick={this.__onShare}>Share with Team{" "}<i
                                className="fa fa-chevron-right"/></div>
                        </div>
                    </div>
                </div>
                }
                <div className="row speaker-row-wrapper">
                    {shortlist_div}
                </div>
            </div>
        );
    }
}