import _ from "lodash";
import Balboa from "../Global/Balboa";

export default function (params) {
    return (dispatch, getState) => {
        var key = ["cancelEvent", _.get(params, ["event_id"])];

        return Balboa(dispatch, getState)
            .put(key, "/marketplace/mp-shortlist-cancel/" + _.get(params, ["event_id"]), params)
            .then(function (response) {
                // remove event from list of buyer's events
                dispatch({
                    type: "BUYER_EVENT_DELETE",
                    eid: _.get(params, ["event_id"])
                });
                return response;
            })
            .then(function(response) {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            })
            .then(function(response) {
                // Clear out the buyer events so they are re-pulled from the server
                // @todo just remove the event from the cache
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: ["BuyerEvents"]
                });
                return response;
            });
    };
};
