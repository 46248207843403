import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import dateformat from "dateformat";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { gettext as __ } from "../../../../utils/Trans";
import assertBalboa from "../../../../utils/assertBalboa";
import { Message, Selectize } from "../../../../components/FormComponents";
import LoadingSubmit from "../../../../components/LoadingSubmit";
import InlineError from "../../../../components/InlineError";
import sendMPMessage from "../../../../actions/ShortList/sendMPMessage";
import RefreshMPEvent from "../../../../actions/Event/RefreshMPEvent";


class MessageAllForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillUnmount() {
        this.props.clearResp();
    }

    handleSubmit(values, dispatch, props) {
        return dispatch(sendMPMessage.send({
                content: values.message,
                event_id: values.event_id,
                to_sids: values.to
            }))
            .then(() => {
                dispatch(RefreshMPEvent(props.eid));
            })
            .then(() => {
                if (_.isFunction(props.onClose)) {
                    props.onClose();
                }
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
            handleSubmit,
            submitting,
            error,
            speakers,
            onClose
        } = this.props;

        return (
            <Form role="form" className="MP-MessageAllModal mp-style-form" onSubmit={handleSubmit(this.handleSubmit)}>
                <div className="modal-body">
                    {error && <InlineError error={_.get(error, ["message"])} />}
                    <div className="row">
                        <div className="col-xs-12">
                            <Field
                                name="to"
                                type="select"
                                className="form-control"
                                label={__("To:")}
                                labelKey={"label"}
                                valueKey={"value"}
                                multi={true}
                                options={speakers}
                                component={Selectize}
                            />
                            <div className="text-muted" style={{margin: ".5em 0 1.5em 0"}}>
                                {__("Recipients do not see other recipients listed on the email; they see only themselves.")}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <Field
                                name="message"
                                type="textarea"
                                rows={10}
                                className="form-control"
                                label={__("Message:")}
                                placeholder={__("Enter your message to the speakers...")}
                                component={Message}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <LoadingSubmit is_saving={submitting} save_text={__("Sending...")} className="btn-success btn-block-xs">
                        {__("Send")}
                        {" "}
                        <i className="fa fa-paper-plane" />
                    </LoadingSubmit>
                    {" "}
                    <div className="btn btn-default btn-block-xs" disabled={submitting} onClick={onClose}>
                        {__("Never Mind")}
                    </div>
                </div>
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

MessageAllForm.propTypes = {
    event: PropTypes.object.isRequired,
    candidates: PropTypes.array.isRequired,
    onClose: PropTypes.func
};

function mapStateToProps(state, ownProps) {

    let speakers = _.map(ownProps.candidates, function(c){
        return {
            label: c.name,
            value: c.sid
        };
    });

    let event_name = _.get(ownProps.event, ["meetingname"]);
    let event_date_str = dateformat(_.get(ownProps.event, ["Stagetimes", 0, "starttime"], new Date()), "mmmm d, yyyy");

    // Saving Properties
    let saving_ajax = assertBalboa(state, [
        ["MPshortlist_email_shortlist", _.get(ownProps.event, ["eid"])]
    ]);

    return {
        event_name: event_name,
        event_date_str: event_date_str,
        sent_resp: saving_ajax.data,
        speakers: speakers,
        initialValues: {
            message: "",
            to: _.map(speakers, "value"),
            subject: __("Speakers for %s - %s", [event_name, event_date_str]),
            event_id: _.get(ownProps.event, ["eid"])
        }
    };
}

function mapDispatchToProps(dispatch) {
    return {
        clearResp: function(){
            dispatch(sendMPMessage.clear());
        }
    };
}

MessageAllForm = reduxForm({
    form: "shortlist_page_message_all",
    validate: function(values) {
        const errors = {};
        if (!values.message) {
            errors.message = __("Please enter a message to send to the speakers.");
        }
        if (!values.to) {
            errors.to = __("Please select at least one speaker to message.");
        }
        return errors;
    }
})(MessageAllForm);

export default connect(mapStateToProps, mapDispatchToProps)(MessageAllForm);