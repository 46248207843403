import _ from "lodash";
import Rest from "../Global/Rest";
import Balboa from "../Global/Balboa";
import {gettext as __} from "../../utils/Trans";
import {sessionService} from "redux-react-session";

export default _.assign({}, Rest, {

    /**
     * Signs a user in via Oauth
     * @param params
     * @returns {Function}
     */
    connectStripe: function(token) {
        let key = ["ConnectBuyerWithStripe"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/stripe/connectbuyerwithstripe/", {
                        token: token.id
                    })
                    .then((response) => {
                        dispatch({
                            type: "USER_STRIPE_LOAD",
                            data: _.get(response, ["data", "stripe_customer"])
                        });
                        return response;
                    })
                    .then((response) => {
                        return sessionService.loadUser()
                            .then((user) => {
                                _.set(user, ["stripe_cust_token"], _.get(response, ["data", "stripe_customer", "id"]));
                                return sessionService.saveUser(user);
                            });
                    })
                    .then((response) => {
                        dispatch({
                            type: "NOTIFICATION_CREATE",
                            message: __("Your card has been saved to your account and has been set as the default payment method.")
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Signs a user in via Oauth
     * @param params
     * @returns {Function}
     */
    connectPlaid: function(params) {
        let key = ["ConnectBuyerWithPlaid"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/stripe/connectbuyerwithplaid/", params)
                    .then((response) => {
                        dispatch({
                            type: "USER_STRIPE_LOAD",
                            data: _.get(response, ["data", "stripe_customer"])
                        });
                        return response;
                    })
                    .then((response) => {
                        return sessionService.loadUser()
                            .then((user) => {
                                _.set(user, ["stripe_cust_token"], _.get(response, ["data", "stripe_customer", "id"]));
                                return sessionService.saveUser(user);
                            });
                    })
                    .then((response) => {
                        dispatch({
                            type: "NOTIFICATION_CREATE",
                            message: __("Your bank has been saved to your account and has been set as the default payment method.")
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Retrieves a single record.
     * @param params
     * @returns {Function}
     */
    get: function() {
        let key = ["GetStripeCustomer"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/stripe/getstripecustomer/")
                    .then((response) => {
                        dispatch({
                            type: "USER_STRIPE_LOAD",
                            data: _.get(response, ["data", "stripe_customer"])
                        });
                        return response;
                    })
            );
        };

    },

    /**
     * Inserts a record.  Leave {params.id} 0, otherwise it will be updated
     * @param params
     * @returns {Function}
     */
    add: function(params) {
        return this.signup(params);
    },

    /**
     * Updates a record.  Pass in {params.id} to specify the record to update, otherwise it will be added
     * @param params
     * @returns {Function}
     */
    update: function(/* params */) {
        console.error("Not implemented yet");
    },

    /**
     * Removes a record.  Pass in {params.id} to specify the record to remove
     * @param params
     * @returns {Function}
     */
    remove: function(params) {
        let key = ["ClearStripe"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/stripe/disconnectbuyer/", params)
                    .then((response) => {
                        dispatch({
                            type: "USER_STRIPE_CLEAR"
                        });
                        return response;
                    })
                    .then((response) => {
                        return sessionService.loadUser()
                            .then((user) => {
                                _.set(user, ["stripe_cust_token"], null);
                                return sessionService.saveUser(user);
                            });
                    })
                    .then((response) => {
                        dispatch({
                            type: "NOTIFICATION_CREATE",
                            message: __("Your default payment method has been removed from your account.")
                        });
                        return response;
                    })
            );
        };
    }

});