import React from "react";
import PropTypes from "prop-types";
import HelpTooltip from "../HelpTooltip";
import IconButton from "../IconButton";

class ActionButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            icon,
            btn_text,
            help_text,
            onClick,
            className
        } = this.props;

        return (
            <div>
                <div className="hidden-xs hidden-sm" style={{marginBottom: "10px"}}>
                    <div style={{float: "right", height: "32px", lineHeight: "32px", margin: "5px"}}>
                        <HelpTooltip title={btn_text} content={help_text} />
                    </div>
                    <IconButton icon={icon} onClick={onClick} className={className}>
                        {btn_text}
                    </IconButton>
                </div>
                <div className="hidden-lg hidden-md text-center" style={{margin: "5px"}}>
                    <IconButton icon={icon} icon_only={false} onClick={onClick} className={className}>
                        {btn_text}
                    </IconButton>
                </div>
            </div>
        );

    }
}

ActionButton.propTypes = {
    icon: PropTypes.string,
    btn_text: PropTypes.string,
    help_text: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string
};

export default ActionButton;