import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import dateformat from "dateformat";
import { Field, Form, formValueSelector, reduxForm, SubmissionError } from "redux-form";
import { gettext as __ } from "../../../../utils/Trans";
import formatCurrency from "../../../../utils/formatCurrency";
import formatNumber from "../../../../utils/formatNumber";
import { FileUpload, Initials, Message, OfferAmount, Reason } from "../../../../components/FormComponents";
import LoadingSubmit from "../../../../components/LoadingSubmit";
import InlineError from "../../../../components/InlineError";
import makeOffer from "../../../../actions/ShortList/makeOffer";
import moment from "moment";

class MakeOfferForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_additional_fees: false
        };
    }

    __toggleAdditionalFees = () => {
        this.setState({
            show_additional_fees: !this.state.show_additional_fees
        });
    }

    handleSubmit(values, dispatch, props) {
        return dispatch(makeOffer(values))
            .then(() => {
                if (_.isFunction(props.onClose)) {
                    props.onClose();
                }
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
            is_custom_contract,
            initial_name,
            initial_date,
            speaker_name,
            speaker_low_fee,
            speaker_high_fee,
            handleSubmit,
            submitting,
            error,
            is_espeakers,
            onClose
        } = this.props;

        return (
            <Form role="form" className="mp-style-form" onSubmit={handleSubmit(this.handleSubmit)}>
                <div className="modal-body">
                    {error && <InlineError error={_.get(error, ["message"])} />}
                    <Field
                        name="fee"
                        type="text"
                        className="form-control"
                        placeholder={formatNumber(speaker_high_fee)}
                        component={OfferAmount}
                        label={__("Amount I Will Pay %s", [speaker_name])}
                        help_text={__("%s normally charges %s to %s.  Any travel costs the speaker adds will be shown separately from the speaking fee.", [speaker_name, speaker_low_fee, speaker_high_fee])}
                        normalize={normalizeAmount}
                        format={formatAmount}
                    />
                    {!this.state.show_additional_fees &&
                    <div className="help-block instruction">
                        {__("What about travel and other costs?")}{"  "}
                        <span className={"link"} onClick={this.__toggleAdditionalFees}>{__("Click here to show additional fees")}</span>
                    </div>
                    }
                    {this.state.show_additional_fees &&
                        <div className="row">
                            <div className="col-sm-6">
                                <Field
                                    name="travel_fee"
                                    type="text"
                                    className="form-control"
                                    placeholder={0.00}
                                    component={OfferAmount}
                                    label={__("Travel", [speaker_name])}
                                    normalize={normalizeAmount}
                                    format={formatAmount}
                                />
                            </div>
                            <div className="col-sm-6">
                                <Field
                                    name="material_fee"
                                    type="text"
                                    className="form-control"
                                    placeholder={0.00}
                                    component={OfferAmount}
                                    label={__("Materials", [speaker_name])}
                                    normalize={normalizeAmount}
                                    format={formatAmount}
                                />
                            </div>
                        </div>
                    }

                    <Field
                        name="terms"
                        type="textarea"
                        rows="6"
                        className="form-control"
                        component={Message}
                        label={__("What Will %s Do", [speaker_name])}
                    />

                    <label className="control-label">Agreement</label>

                    <Field name="contract_standard_or_custom" type="radio" component={Reason} value="standard" label={__("Standard terms apply.")} />
                    <div style={{margin:"0em 2em"}} className="small text-muted">Read the standard terms at <a target="_blank" href="https://www.espeakers.com/legal/engagement-contract/" rel="noopener noreferrer">https://www.espeakers.com/legal/engagement-contract/</a></div>

                    <Field name="contract_standard_or_custom" type="radio" component={Reason} value="custom" label={__("Use your own PDF agreement.")} />
                    {is_custom_contract &&
                        <div style={{margin:"0em 1em"}}>
                            <Field name="custom_contract" component={FileUpload} value="" placeholder={__("Browse or drag and drop a PDF here to upload.")} />
                        </div>
                    }

                    <hr />

                    <div className="row">
                        <div className="col-sm-12">
                            {(is_espeakers) || <div className="help-block instruction">{__("Payment and contract services are powered by eSpeakers.com.")}</div>}
                            <p>{__("This offer becomes my agreement with the speaker if they accept it, and I agree to pay the deposit and balance on schedule.")}</p>
                            <p>{__("This transaction is covered by the ")} <b><a target="_blank" href="https://www.espeakers.com/legal/marketplace-terms-and-conditions/" rel="noopener noreferrer">{__("eSpeakers Marketplace Guarantee")}</a></b>.</p>
                        </div>
                    </div>

                    <div className="row">
                        <div className="col-sm-12">
                            <p>
                                {__("By clicking \"MAKE OFFER\", I agree to the above and the ")} <a target="_blank" href="https://www.espeakers.com/legal/marketplace-terms-and-conditions/" rel="noopener noreferrer">{__("Terms of Service")}</a>.
                            </p>
                        </div>
                    </div>

                    <Field
                        name="initials"
                        type="text"
                        className="form-control"
                        placeholder="XXX"
                        component={Initials}
                        initial_date={initial_date}
                        initial_name={initial_name}
                    />
                </div>
                <div className="modal-footer">
                    <LoadingSubmit is_saving={submitting} save_text={__("Sending Offer...")} className="btn-success btn-block-xs">
                        {__("Make Offer")}
                    </LoadingSubmit>
                    {" "}
                    <div className="btn btn-default btn-block-xs" disabled={submitting} onClick={onClose}>
                        {__("Not Yet")}
                    </div>
                </div>
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

MakeOfferForm.propTypes = {
    is_espeakers: PropTypes.bool,
    event: PropTypes.object,
    speaker: PropTypes.object,
    onClose: PropTypes.func
};

function mapStateToProps(state, ownProps) {

    let u_lists = _.get(state, ["Whitelabel", "sitelists", "universal"], {});

    let selector = formValueSelector("shortlist_page_make_offer");
    let contract_standard_or_custom = selector(state, "contract_standard_or_custom");

    return {
        is_custom_contract: (contract_standard_or_custom === "custom"),
        speaker_name: _.get(ownProps.speaker, ["name"]),
        speaker_low_fee: formatCurrency(_.get(ownProps.speaker, ["fee_low"])),
        speaker_high_fee: formatCurrency(_.get(ownProps.speaker, ["fee_high"])),
        initial_name: _.get(ownProps.user, ["firstname"]) + " " + _.get(ownProps.user, ["lastname"]),
        initial_date: dateformat(new Date(), "m/dd/yyyy"),
        initialValues: {
            initial_name: (_.get(ownProps.user, ["firstname"]) + " " + _.get(ownProps.user, ["lastname"])),
            initial_date: dateformat(new Date(), "m/dd/yyyy"),
            terms: formatTerms(ownProps.event, u_lists),
            contract_standard_or_custom: "standard",
            fee: _.get(ownProps.speaker, ["fee_high"]),
            travel_fee: 0.00,
            material_fee: 0.00,
            initials: "",
            event_id: _.get(ownProps.event, ["eid"]),
            speaker_id: _.get(ownProps.speaker, ["sid"]),
            plus_travel: 0
        }
    };
}

function mapDispatchToProps() {
    return {

    };
}

MakeOfferForm = reduxForm({
    form: "shortlist_page_make_offer",
    validate: function(values) {
        const errors = {};
        if (!values.initials) {
            errors.initials = __("Please initial your acceptance of this agreement.");
        }
        if (!values.amount) {
            errors.amount = __("Please enter an amount to offer this speaker.");
        }
        if (values.amount && _.isString(values.amount) && (values.amount.replace(/[^\d]/g, "") < 1)) {
            errors.amount = __("Please enter at least $1 to offer this speaker.");
        }
        if (!values.terms) {
            errors.terms = __("Please enter what you want the speaker to provide.");
        }
        if (!values.contract_standard_or_custom) {
            errors.terms = __("Please select which type of contract you want to use.");
        }
        return errors;
    }
})(MakeOfferForm);

export default connect(mapStateToProps, mapDispatchToProps)(MakeOfferForm);

/////////////////////////////////////////////////////////////////////////
// Helper Functions
/////////////////////////////////////////////////////////////////////////

const formatTerms = function (event, u_lists) {
    let venue = _.get(event, ["Venues", 0]);
    let stagetime = _.get(event, ["Stagetimes", 0]);

    let presenter_type = _.upperFirst(
        _.get(
            _.fromPairs(
                _.map(u_lists.presenter_types, function (d) {
                    return [d.id, d.description];
                })
            ), _.get(_.first(stagetime), "presenter_type", 1), "Keynote"
        )
    );
    let delivery_method = _.get(
        _.fromPairs(
            _.map(u_lists.deliverymethods, function (d) {
                return [d.id, d.description];
            })
        ), _.get(event, ["deliveryMethod"]), "live"
    );
    let delivery_method_text = _.get(event, ["deliveryMethod"], 0) === 1 ? delivery_method : "via " + delivery_method;
    let location = _.get(event, ["deliveryMethod"], 0) === 1 ? " in " + _.get(venue, "city", "") + ", " + _.get(venue, "st", "") : "";
    let topic = _.get(event, ["topics"], "TBD");
    let startdate = moment(_.get(event, ["Stagetimes", 0, "starttime"], new Date())).format("MMM D, Y");
    let t = "%s up to 90 minutes presented %s%s on %s. Topic to be related to %s.";

    return __(t, [presenter_type, delivery_method_text, location, startdate, topic]);
};

const formatAmount = (value) => {
    if (value) {
        // if (_.toNumber(_.toString(value).replace(/[^\d]/g, "")) === 0) {
        //     return "";
        // }
        return formatNumber(value);
    }
    return value;
};

const normalizeAmount = (value) => {
    if (value) {
        return _.toString(value).replace(/[^\d]/g, "");
    }
    return value;
};