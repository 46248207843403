import _ from "lodash";
import React from "react";
import SearchBanner from "./SearchBanner";
import SearchForm from "./SearchForm";
import {gettext as __} from "../../../utils/Trans";
import MetaTag from "../../../containers/HomePage/components/MetaTag.js";

export default class DefaultSearchWrapper extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
          whitelabel
        } = this.props;

    let is_directory_mode = _.get(whitelabel, ["is_directory_mode"], false);

    return (
      <div>
        {(_.get(whitelabel, "is_espeakers", false)) && <MetaTag whitelabel={whitelabel}/>}
        <SearchBanner {...this.props} />

        {is_directory_mode
          ? <h1>{__("Search our Speakers")}</h1>
          : <h1>{__("Find a Speaker")}</h1>
        }
        <SearchForm {...this.props} />
      </div>
    );
  }
}