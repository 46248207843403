import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Form, reduxForm } from "redux-form";
import { Modal } from "react-bootstrap";
import { gettext as __ } from "../../../../utils/Trans";
import LoadingSubmit from "../../../../components/LoadingSubmit";
import MpShortlistSpeaker from "../../../../actions/ShortList/MpShortlistSpeaker";

class SpeakerRemoveForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch, props) {
        return dispatch(MpShortlistSpeaker.remove(_.get(values, ["id"])))
            .then((resp) => {
                return dispatch({
                    type: "AFFILIATE_EVENT_SPEAKER_DELETE",
                    id: _.get(props.affiliate_event, ["id"]),
                    data: _.get(resp, ["data"])
                });
            })
            .then(() => {
                if (_.isFunction(props.onClose)) {
                    props.onClose();
                }
            });
    }

    render() {
        let {
            submitting,
            handleSubmit,
            speaker,
            onClose
        } = this.props;

        return (
            <Form onSubmit={handleSubmit(this.handleSubmit)}>
                <Modal.Body>
                    <div className="help-block">
                        {__("You are choosing to remove")}
                        {" "}<b>{_.get(speaker, ["name"])}</b>{" "}
                        {__("from this shortlist.  Please confirm that you want to do this.")}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <LoadingSubmit is_saving={submitting} save_text={__("Removing Speaker...")} className="btn-danger btn-block-xs">
                        {__("Remove Speaker")}
                    </LoadingSubmit>
                    {" "}
                    <div className="btn btn-default btn-block-xs" disabled={submitting} onClick={onClose}>
                        {__("Nevermind")}
                    </div>
                </Modal.Footer>
            </Form>

        );
    }
}

SpeakerRemoveForm = reduxForm({
    form: "speaker_review_form"
})(SpeakerRemoveForm);

function mapStateToProps(state, ownProps) {
    return {
        initialValues: {
            id: _.get(ownProps.speaker, ["shortlist", "id"])
        }
    };
}

function mapDispatchToProps(/*dispatch, ownProps*/) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(SpeakerRemoveForm);