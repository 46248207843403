import _ from "lodash";

export default function(video_url) {
    if (!_.isUndefined(video_url)) {
        let video_urls = {};
        let ext = video_url.substring((video_url.lastIndexOf(".") + 1));
        let extensions = _.split(ext, "|");
        _.each(extensions, function (extension) {
            _.set(video_urls, [extension], _.replace(video_url, ext, extension));
        });
        return video_urls;
    }
    return {"mp4": video_url};
}