import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";

class AssociationsQuickStats extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speaker
        } = this.props;

        let associations = _.get(speaker, ["associations"], []);

        if (_.size(associations) === 0) {
            return null;
        }

        return (
            <div className="quickStatsBox associations">
                <h5 className="header">{__("ASSOCIATIONS")}</h5>
                {_.map(associations, function (item, i) {
                    return (
                        <div key={i} title={_.get(item, ["alt"])}>
                            <img src={_.get(item, ["src"])} alt={_.get(item, ["alt"])} title={_.get(item, ["alt"])}/>
                            {_.get(item, ["chapter"]) &&
                                <div style={{marginBottom:"10px", marginTop:"4px"}}>
                                    {_.get(item, ["chapter"])} Chapter
                                </div>
                            }
                            <br />
                        </div>
                    );
                })}
            </div>
        );
    }
}

AssociationsQuickStats.propTypes = {
    speaker: PropTypes.object.isRequired
};

export default AssociationsQuickStats;