import React from "react";
import { Route } from "react-router";
import SpeakerSandboxedLayout from "../SpeakerSandboxedLayout";

export default class SpeakerSandboxedLayoutRoute extends React.Component {

    render() {
        return (
            <SpeakerSandboxedLayout {...this.props}>
                <Route {...this.props} />
            </SpeakerSandboxedLayout>
        );
    }

}