import _ from "lodash";
import Constants from "./Constants";

export default function(url, whitelabel, absolute = false, force_redirect = true) {
    let return_url = "/shortlist" + url;
    if (whitelabel && _.get(whitelabel, ["whitelabel_domain"]) && (_.toInteger(_.get(whitelabel, ["bid"], Constants.TRUNK_BUREAU_ID)) !== Constants.TRUNK_BUREAU_ID)) {
        return_url = url;
        if (!absolute && force_redirect && (_.get(whitelabel, ["whitelabel_domain"]) !== window.location.hostname)) {
            absolute = true;
        }
    } else if (whitelabel && (_.toInteger(_.get(whitelabel, ["bid"], Constants.TRUNK_BUREAU_ID)) !== Constants.TRUNK_BUREAU_ID)) {
        return_url = "/s/" + _.get(whitelabel, ["bcode"]) + url;
    } else if (whitelabel && (_.toInteger(_.get(whitelabel, ["bid"], Constants.TRUNK_BUREAU_ID)) === Constants.TRUNK_BUREAU_ID)) {
        return_url = "/shortlist" + url;
    }
    if (absolute) {
        let protocol = window.location.protocol;
        let hostname = window.location.hostname;
        if (hostname === "localhost") {
            hostname += ":" + window.location.port;
        }
        if (whitelabel && _.get(whitelabel, ["whitelabel_domain"]) && (_.toInteger(_.get(whitelabel, ["bid"], Constants.TRUNK_BUREAU_ID)) !== Constants.TRUNK_BUREAU_ID)) {
            if (!_.includes(hostname, "localhost")) {
                protocol = "https:";
            }
            hostname = _.get(whitelabel, ["whitelabel_domain"]);
        }
        return protocol + "//" + hostname + return_url;
    }
    return return_url;
}

// let MP = window.MP || {};
// if (_.isUndefined(MP.toMpURL)) {
//     MP.toMpURL = function (url, bureau_id, absolute) {
//         let hostname = window.location.hostname;
//         if (hostname === "localhost") {
//             hostname = "local.espeakers.com";
//         }
//         if (bureau_id === 0) {
//             if (absolute) {
//                 return "https://" + hostname + "/marketplace" + url;
//             } else {
//                 return "/marketplace" + url;
//             }
//         }
//         if (absolute) {
//             return "https://" + hostname + "/marketplace" + url;
//         } else {
//             return "/marketplace" + url;
//         }
//     };
// }
//
// export default MP.toMpURL;