import _ from "lodash";
import prevDflt from "wrap-prevent-default";

const CloseModal = prevDflt(
        function() {
            // To close a modal, simply remove the last part of the url (which is the modal name)
            var path_parts = _.split(window.location.hash, "/");
            window.location.hash = _.dropRight(path_parts).join("/");
        }
    );


export default CloseModal;