/**
 * Import this file to gain access to system-wide constants.
 *
 */
export default {
    TRUNK_BUREAU_ID: 1375,
    MPI_BUREAU_ID: [1339, 1574],
    NSAUS_BUREAU_ID: [304],
    ESPEAKERS_ANALYTICS_ID: "G-4XEQN5S87N",
    ESPEAKERS_FACEBOOK_PIXEL_ID: "964500293708050",
    GOOGLE_MAPS_API_KEY: "AIzaSyBMD0XeuBe9UwfD1X7l0Mw_R0yY-LHX-4U",
    NO_RECORD_HIT_STRING: "norecord=true", //put this on a URL query to prevent search results and profile views from being recorded (as with monitoring)
    IPSTACK_ACCESS_KEY: "be8db73cd4152dab40396715baaf88e0" //used for the 'near me' geolocation
};
