import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import dateformat from "dateformat";
import { gettext as __ } from "../../../../utils/Trans";
import formatEventVenue from "../../../../utils/formatEventVenue";
import FeesAndPayment from "../FeesAndPayment";
import { FeesAndPaymentButton } from "../Buttons";
import InfoPair from "./InfoPair";

class AgreementInfo extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            event,
            speaker,
            agreement,
            payment,
            dont_show_fees_button,
            show_fees_in_area,
            deliverymethods
        } = this.props;

        let event_datetime_str;
        if (_.isDate(_.get(event, ["Stagetimes", 0, "starttime"]))) {
            if (
                dateformat(
                    _.get(event, ["Stagetimes", 0, "starttime"]),
                    "h:MM TT"
                ) === "12:00 AM"
            ) {
                //don't show time if it's the default (12:00 AM)
                event_datetime_str = dateformat(
                    _.get(event, ["Stagetimes", 0, "starttime"]),
                    "mmmm d, yyyy"
                );
            } else {
                event_datetime_str = dateformat(
                    _.get(event, ["Stagetimes", 0, "starttime"]),
                    "mmmm d, yyyy 'at' h:MM TT 'local time'"
                );
            }
        }

        let event_venue_str;
        if (_.get(event, ["deliveryMethod"]) === 1) {
            var venue = _.first(_.get(event, ["Venue"])) || {};
            event_venue_str = formatEventVenue(venue);
        } else if (_.has(deliverymethods, _.get(event, ["deliveryMethod"]))) {
            event_venue_str = deliverymethods[_.get(event, ["deliveryMethod"])];
        }

        return (
            <div>
                {!(dont_show_fees_button || show_fees_in_area) && (
                    <span className="pull-right">
                        <FeesAndPaymentButton {...this.props} />
                    </span>
                )}
                <h4 style={{ margin: "10px 0 20px 0" }}>
                    {__("Your agreement with %s", [_.get(speaker, ["name"])])}
                </h4>

                {event_datetime_str ? (
                    <InfoPair label={__("Event Date:")}>
                        {event_datetime_str}
                    </InfoPair>
                ) : null}

                {event_venue_str ? (
                    <InfoPair label={__("Venue:")}>{event_venue_str}</InfoPair>
                ) : null}

                <InfoPair label={__("Terms:")}>
                    <div
                        style={{
                            whiteSpace: "pre-wrap",
                            marginBottom: "10px"
                        }}>
                        {_.get(agreement, ["terms"])}
                    </div>
                    <div>
                        <a
                            href={_.get(agreement, ["contract_url"])}
                            className="text-muted">
                            <i className="fa fa-paperclip" /> View Contract
                        </a>
                    </div>
                </InfoPair>

                {show_fees_in_area && (
                    <FeesAndPayment
                        agreement={agreement}
                        event={event}
                        payment={payment}
                        {...this.props}
                    />
                )}
            </div>
        );
    }
}

AgreementInfo.propTypes = {
    event: PropTypes.object.isRequired,
    speaker: PropTypes.object.isRequired,
    agreement: PropTypes.object.isRequired,
    payment: PropTypes.object,
    dont_show_fees_button: PropTypes.bool,
    show_fees_in_area: PropTypes.bool
};

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {
    let deliverymethods = _.get(
        ownProps.sitelists,
        ["universal", "deliverymethods"],
        {}
    );

    deliverymethods = _.fromPairs(
        _.map(deliverymethods, function (d) {
            return [d.id, d.description];
        })
    );

    return {
        deliverymethods: deliverymethods
    };
};

var mapDispatchToProps = function () {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(AgreementInfo);

export { AgreementInfo };
