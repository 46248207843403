import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";


class MetaTags extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  /**
   * Replace any placeholder codes in the string with the right replacement value.
   * For instance:
   *  "Hire [speakername] from [publisher] to speak on [topic]"
   *  => "Hire Googlin Sam from eSpeakers Marketplace to speak on Googling".
   *
   *  Placeholder codes I understand:
   *      [speakername]
   *      [publisher]
   *      [oneline]
   *      [topic] (each time found, fills in the next topic starting from the top)
   *
   *
   * @param s
   * @param speaker
   * @param whitelabel
   * @returns {*}
   */
  replacePlaceholders = (s, speaker, whitelabel) => {
    //get the oneline bio in case we need it for a replacement
    let profile_language = "en";
    let primary_bio = _.first(_.filter(_.get(this.props.speaker, ["bios"], []), function (item) {
      return (item.lang === profile_language && (item.contents !== ""));
    }));
    if (!primary_bio) {
      primary_bio = _.first(_.filter(_.get(this.props.speaker, ["bios"], []), function (item) {
        return ((item.lang === "en" || _.isNull(item.lang)) && (item.contents !== ""));
      }));
    }
    let oneline = _.get(primary_bio, ["oneline"], "");

    //these are the template replacements I understand
    s = s.replace(/\[speakername\]/g, _.get(speaker, "name", "professional speaker"));
    s = s.replace(/\[publisher\]/g, _.get(whitelabel, "name", "this website"));
    s = s.replace(/\[oneline\]/g, oneline);
    _.forEach(_.get(speaker, ["topics"], []), function (topic) {
      s = s.replace(/\[topic\]/, topic); //this only replaces the first one it finds
    });

    return s;
  }

  render() {
    let {
          speaker,
          whitelabel,
          canonical_url
        } = this.props;

    //prevent title from flashing before we have speaker & whitelabel data
    if (_.isEmpty(speaker) || _.isEmpty(whitelabel)) return null;

    //assemble the meta values
    let title = !_.isEmpty(_.get(whitelabel, ["mp_keyvalues", "PROFILE_TITLE_TEMPLATE"])) ? _.get(whitelabel, ["mp_keyvalues", "PROFILE_TITLE_TEMPLATE"]) : "[speakername] at [publisher]";
    let description = !_.isEmpty(_.get(whitelabel, ["mp_keyvalues", "PROFILE_DESCRIPTION_TEMPLATE"])) ? _.get(whitelabel, ["mp_keyvalues", "PROFILE_DESCRIPTION_TEMPLATE"]) : "Hire [speakername] at [publisher]. [oneline]";

    title = this.replacePlaceholders(title, speaker, whitelabel);
    description = this.replacePlaceholders(description, speaker, whitelabel);

    let oneyear = new Date();
    oneyear.setFullYear(oneyear.getFullYear() + 1);

    let structured_data = {
      "@context": "https://schema.org/",
      "@type": "Product",
      "name": _.get(speaker, "name"),
      "image": _.map(_.filter(_.get(speaker, "assets", []), {"type": "PHOTO"}), "url"),
      "description": "Hire " + _.get(speaker, "name") + " at " + _.get(whitelabel, "name") + ". " + _.get(speaker, ["bios", 0, "oneline"]),
      "sku": "es" + _.get(speaker, "sid"),
      "mpn": "es" + _.get(speaker, "sid"),
      "brand": {
        "@type": "Organization",
        "name": _.get(whitelabel, "bureauName")
      },
      "review":
        _.map(_.get(speaker, "recommendations", []), function (recommendation) {
          return {
            "@type": "Review",
            "positiveNotes": _.get(recommendation, "testimony"),
            "reviewRating": {
              "@type": "Rating",
              "ratingValue": "5",
              "bestRating": "5"
            },
            "author": {
              "@type": "Person",
              "name": _.get(recommendation, "initials", "").replace(/^(.{80}[^\s]*).*/, "$1") //max 100 characters
            }
          };
        }),
      "offers": {
        "@type": "Offer",
        "url": window.location.href,
        "priceCurrency": "USD",
        "price": _.get(speaker, "low_fee"),
        "priceValidUntil": oneyear.toLocaleDateString("en-CA"), //"2020-11-20",
        "itemCondition": "https://schema.org/NewCondition",
        "availability": "https://schema.org/InStock"
      }
    };

    if (_.values(_.get(speaker, "recommendations", [])).length > 0) {
      structured_data["aggregateRating"] = {
        "@type": "AggregateRating",
        "ratingValue": "5.0",
        "reviewCount": _.values(_.get(speaker, "recommendations", [])).length
      };
    }

    return (
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
        <meta charSet="utf-8"/>
        <title>{title}</title>
        <meta name="description" content={description}/>
        {((!_.get(whitelabel, ["is_espeakers"], false)))
          && <link rel="canonical" href={canonical_url}/>
        }
        <script type="application/ld+json">
          {JSON.stringify(structured_data)}
        </script>
      </Helmet>
    );
  }
}

MetaTags
  .propTypes = {
  speaker: PropTypes.object,
  whitelabel: PropTypes.object,
  canonical_url: PropTypes.string
};

export default MetaTags;