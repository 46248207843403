import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Modal } from "react-bootstrap";
import queryString from "query-string";
import { gettext as __ } from "../../../utils/Trans";

export default class TopicModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    __buildLink = (item) => {
        let topic_qs = {label: _.get(item, ["name"]), type: "topic", value: "topic_" + _.get(item, ["id"])};

        if (true) {
            // This will replace the entire search with just the topic
            let reset_search = {
                available_on: "",
                awards: "",
                budget: "0,8",
                language: "",
                location: "",
                require: "",
                page: 0,
                q: JSON.stringify(topic_qs)
            };
            return "/search?" + queryString.stringify(reset_search);
        } else {
            // This will merge the topic with the existing search
            let q = [];
            try {
                q = JSON.parse(_.get(this.props.qs, ["q"], ""));
            } catch (e) { /* nothing to do here */
            }
            // if you don't concat the q with topic_qs, you can replace the topic, but keep the rest of the search params
            let new_q = _.concat(q, topic_qs);
            // let link = _.assign({}, this.props.qs, {q: JSON.stringify(new_q)});

            return "/search?" + queryString.stringify({q: JSON.stringify(new_q)});
        }
    }

    render() {
        let {
            topics,
            onHide
        } = this.props;

        let buildLink = this.__buildLink;

        return (
            <Modal show={true} onHide={onHide} bsSize="large">
                <Modal.Header closeButton>
                    <Modal.Title>{__("Browse All Topics")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{overflow: "auto", maxHeight: "50vh"}}>
                        <h5>{__("Click any topic to see amazing experts who speak about it", [_.size(topics)])}<i className="fa fa-arrow-down" style={{marginLeft: "1rem"}}/> </h5>
                        {_.map("ABCDEFGHIJKLMNOPQRSTUVWXYZ", function(alpha, i) {
                            let alpha_topics = _.filter(topics, function (item) {
                                return _.startsWith(item.name, alpha);
                            });
                            if (_.size(alpha_topics) === 0) {
                                return null;
                            }
                            return (
                                <div key={i}>
                                    <h3>{alpha}</h3>
                                    <div className="row">
                                        {_.map(alpha_topics, function(item, k) {
                                            return (
                                                <div key={k} className="col-xs-12 col-sm-6 col-md-4">
                                                    <Link to={buildLink(item)} onClick={onHide}>{_.get(item, ["name"])}</Link>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

TopicModal.propTypes = {
    qs: PropTypes.object,
    topic: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    onHide: PropTypes.func
};