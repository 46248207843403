import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import UnreadBadge from "../UnreadBadge";
import OnlineBadge from "../OnlineBadge";

require("./ThumbnailSquare.css");

class ThumbnailSquare extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            size,
            tooltip,
            n_unread,
            inline = false,
            img_url,
            style = {},
            children,
            loading,
            className = "",
            has_offer_accepted,
            has_offer
        } = this.props;

        if (!_.isNumber(size)) { size = 50; }
        if (!_.isNumber(n_unread)) { n_unread = 0; }
        if (children && children.length === 0) { children = null; }

        let tooltip_text = _.isString(tooltip) ? tooltip : "";
        style = _.extend({width: size + "px", height: size + "px"}, _.isObject(style) ? style : {});

        if (inline) {
            style.display = "inline-block";
            style.verticalAlign = "middle";
        }

        if (loading){
            style.backgroundImage = "url('//d1wyxf4lkw4102.cloudfront.net/ui/ajax-loader.gif')";
            style.backgroundPosition = "50% 50%";
        } else if(_.isString(img_url)) {
            style.backgroundSize = "cover";
            style.backgroundImage = "url('" + img_url + "')";
        } else {
            style.border = "1px dashed #ccc";
            style.background = "#FAFAFA";
        }

        //circle image
        style.borderRadius = size + "px";

        return (
            <span className={"ThumbnailSquare img-responsive relative " + (className) + (n_unread > 0 ? " unread" : "")} style={style}>
                {children ? <span className="content-area">
                    <span className=" text-center">
                        {children}
                    </span>
                </span> : null}

                {!tooltip_text ? null :
                    <div className="tooltip bottom" style={{
                        top: size + "px",
                        width: "100px",
                        left: (size - 100)/2
                    }}>
                        <div className="tooltip-inner">
                            {tooltip_text}
                        </div>
                        <div className="tooltip-arrow" />
                    </div>
                }

                {n_unread > 0 && <UnreadBadge n_unread={n_unread} />}
                {(has_offer) &&
                <OnlineBadge online={has_offer_accepted} away={has_offer} offline={false}>
                    {has_offer_accepted ? "Hired"
                        : has_offer && size >= 80 ? "Pending Offer"
                            : has_offer && size < 80 ? "Offer"
                                : " "
                    }
                </OnlineBadge>
                }
            </span>
        );
    }
}

ThumbnailSquare.propTypes = {
    size: PropTypes.number,
    style: PropTypes.object,
    className: PropTypes.string,

    inline: PropTypes.bool,

    img_url: PropTypes.string,

    loading: PropTypes.bool,

    n_unread: PropTypes.number,
    tooltip: PropTypes.string,

    has_offer: PropTypes.bool,
    has_offer_accepted: PropTypes.bool
};

export default ThumbnailSquare;