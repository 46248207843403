import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classnames from "classnames";

import "./ThumbnailButton.scss";

export default class ThumbnailButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            title,
            src,
            size = "md",
            href,
            is_external_href,
            tooltip,
            placement = "bottom"
        } = this.props;

        if (_.isNull(src)) {
            return (
                <div className={classnames(["thumbnail-button", "empty", size])}>
                    <div className={classnames(["background", size])} style={{
                        minHeight: "150px",
                        marginBottom: "0px"
                    }}></div>
                </div>
            );
        }

        if (is_external_href) {
            if (_.isUndefined(title)) {
                return (
                    <div className={classnames(["thumbnail-button", size])}>
                        <a href={href} target={"_blank"} rel="noopener noreferrer" title={tooltip}>
                            <div className={classnames(["background", size])} style={{
                                marginBottom: "0px",
                                backgroundImage: "url('" + src + "')"
                            }}></div>
                        </a>
                    </div>
                );
            }

            return (
                <div className={classnames(["thumbnail-button", size])}>
                    <a href={href} target={"_blank"} rel="noopener noreferrer" title={tooltip}>
                        <div className={classnames(["background-blur", size])} style={{
                            backgroundImage: "url('" + src + "')",
                        }}></div>
                        <div className={classnames(["background-darken", size, placement])}></div>
                        <div className={classnames(["background", size, placement])} style={{
                            backgroundImage: "url('" + src + "')"
                        }}></div>
                        <div className={classnames(["title", size, placement])}>
                            {title}
                        </div>
                    </a>
                </div>
            );
        } else {
            if (_.isUndefined(title)) {
                return (
                    <div className={classnames(["thumbnail-button", size])}>
                        <Link to={href} title={tooltip}>
                            <div className={classnames(["background", size])} style={{
                                marginBottom: "0px",
                                backgroundImage: "url('" + src + "')"
                            }}></div>
                        </Link>
                    </div>
                );
            }

            return (
                <div className={classnames(["thumbnail-button", size])}>
                    <Link to={href} title={tooltip}>
                        <div className={classnames(["background-blur", size])} style={{
                            backgroundImage: "url('" + src + "')",
                        }}></div>
                        <div className={classnames(["background-darken", size, placement])}></div>
                        <div className={classnames(["background", size, placement])} style={{
                            backgroundImage: "url('" + src + "')"
                        }}></div>
                        <div className={classnames(["title", size, placement])}>
                            {title}
                        </div>
                    </Link>
                </div>
            );
        }


    }
}

ThumbnailButton.propTypes = {
    title: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
    href: PropTypes.string,
    tooltip: PropTypes.string
};