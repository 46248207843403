import _ from "lodash";
import Balboa from "../Global/Balboa";

export default function (params) {
    return (dispatch, getState) => {
        var key = ["speakerDirectMessage", params.sid];

        return Balboa(dispatch, getState)
            .post(key, "/speaker/directmessage/" + params.sid, params)
            .then(function(response) {
                dispatch({
                    type: "SPEAKER_DIRECTMESSAGE",
                    data: _.get(response, ["data"], {})
                });
                return response;
            })
            .then(function(response) {
                // clear the balboa request
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            });
    };

};

