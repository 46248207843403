import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import {Col, Row} from "react-bootstrap";
import VirtualReviews from "./VirtualReviews";
import VirtualFees from "./VirtualFees";
import VirtualPrograms from "./VirtualPrograms";
import VirtualVideoProof from "./VirtualVideoProof";
import VirtualCvpReport from "./VirtualCvpReport";
import VirtualCvpCertificate from "./VirtualCvpCertificate";
import VirtualCvpHeader from "./VirtualCvpHeader";

export default class VirtualWithCvp extends React.Component {

  state = {}

  static propTypes = {
    speaker: PropTypes.object.isRequired
  }

  static getDerivedStateFromProps = (props) => ({
    speaker: props.speaker,
    is_cvp: _.get(props.speaker, ["flags_as_map", "virtual_certified", "is_set"], false),
    show_cvp_video: _.get(props.speaker, ["assets_virtual_proof", "url"]),
    show_fees: (_.get(props.speaker, ["v_low_fee"], 0) + _.get(props.speaker, ["v_high_fee"], 0) > 0),
    show_virtual_programs: _.size(_.filter(_.get(props.speaker, ["programs"]), (program) => (_.get(program, "presenter_type") & 128))) > 0,
    show_reviews: _.size(_.filter(_.get(props.speaker, ["recommendations"]), (review) => (_.includes(_.get(review, ["flags_arrayed"], []), "VIRTUAL_EVENT")))) > 0
  })

  __onShowTab = (tab) => {
    if (_.isFunction(this.props.onShowTab)) {
      return this.props.onShowTab(null, tab);
    }
  }
  render() {
    let {
      speaker,
      is_cvp,
      show_cvp_video,
      show_fees,
      show_virtual_programs,
      show_reviews
    } = this.state;
    
    // console.log(show_virtual_programs)
    return (
      <React.Fragment>
        {is_cvp && <VirtualCvpHeader speaker={speaker}/>}

        <Row style={{padding: "20px"}}>
          {show_cvp_video && <Col sm={12} md={4} lg={4}><VirtualVideoProof speaker={speaker}/></Col>}
          {show_cvp_video && <Col sm={1}>&nbsp;</Col>}
          <Col sm={12} md={7} lg={7}>
            <Row>
              {show_fees && <VirtualFees speaker={speaker}/>}
              {show_virtual_programs && <div style={{marginTop: "4rem"}}><VirtualPrograms speaker={speaker} onShowTab={this.__onShowTab}/></div>}
              {show_reviews && <div style={{marginTop: "4rem"}}><VirtualReviews speaker={speaker}/></div>}
            </Row>
          </Col>
        </Row>

        {is_cvp && <VirtualCvpReport speaker={speaker}/>}
        {is_cvp && <VirtualCvpCertificate speaker={speaker}/>}
      </React.Fragment>
    );
  }
}

