import _ from "lodash";
import Speaker from "./Speaker";
import Balboa from "../Global/Balboa";

export default _.assign({}, Speaker, {

    /**
     * Retrieves a single record.
     * @param params
     * @returns {Function}
     */
    get: function(id, params) {
        let key = ["getSpeakerProfile", id];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/marketplace/speaker-profile/" + id, params)
                    .then((response) => {
                        dispatch({
                            type: "SPEAKER_FULL_PROFILES_RESPONSE",
                            sid: id,
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
                    .then((resp) => {
                        if (_.has(resp, ["data", "shortprofile"])) {
                            dispatch({
                                type: "SPEAKER_GET",
                                sid: id,
                                data: _.get(resp, ["data", "shortprofile", id]),
                            });
                        }
                    })
                    .catch(function (err) {
                        if (err.name.includes("SpeakerMissingException")) {
                            throw err;
                        } else if (err.name === "AuthorizationException") {
                            dispatch({
                                type: "ERROR_CREATE",
                                source: _.join(key, "_"),
                                error: err
                            });
                            throw err;
                        } else {
                            throw err;
                        }
                    })
            );
        };
    }

});