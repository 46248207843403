import _ from "lodash";


// var FIX_toISO8601 = function(obj, fields){
    /* This function doesn't honor timezones, so it breaks dates, so we just skip it now - MH 02/19/2020 */
    // _.each(_.filter(_.flattenDeep([fields]), _.isString), function(field){
    //     if(_.has(obj, field)){
    //         obj[field + "_ISO8601"] = _.isDate(obj[field]) ?
    //                                     returnISO8601(obj[field]) :
    //                                     (_.isString(obj[field]) ? moment(obj[field], "M/D/Y").toISOString(true) : null);
    //     }
    // });
    // return obj;
// };

var flagsAsMap_to_Flags = function(flags_as_map){
    return _.reduce(flags_as_map, function(total, f) {
        return total + (f.is_set ? f.intval : 0);
    }, 0);
};

var StoredFile_JStoBalboa3 = function(StoredFile){
    // if(_.isDate(StoredFile.postdate)){
    //     StoredFile.postdate_iso8601 = returnISO8601(StoredFile.postdate);
    // }
    return StoredFile;
};

export default function(event){
    if(!_.isPlainObject(event) || !_.has(event, "eid")){
        return null;
    }
    // event = FIX_toISO8601(event, "created");
    // event = FIX_toISO8601(event, "ccDateTime");
    // event.Stagetime = _.map(event.Stagetime, function(stagetime){
    //     return FIX_toISO8601(stagetime, ["starttime", "stoptime", "avchecktime"]);
    // });
    // event.Note = _.map(event.Note, function(note){
    //     return FIX_toISO8601(note, "datetime");
    // });
    // event.Flight = _.map(event.Flight, function(flight){
    //     flight.legs = _.map(flight.legs, function(leg){
    //         return FIX_toISO8601(leg, ["legarrive", "legleave"]);
    //     });
    //     return flight;
    // });
    // event.Hotel = _.map(event.Hotel, function(hotel){
    //     return FIX_toISO8601(hotel, ["checkindate", "checkoutdate"]);
    // });
    // event.Ground = _.map(event.Ground, function(ground){
    //     return FIX_toISO8601(ground, "starttime");
    // });
    // event.EventTodo = _.map(event.EventTodo, function(todo){
    //     todo.vieworder = toInt(todo.vieworder);
    //     return FIX_toISO8601(todo, "statusdate");
    // });
    event.storedfiles = _.map(event.storedfiles, StoredFile_JStoBalboa3);
    event.todolistids = _.map(_.filter(event.todolistids, function(todolist){
        return _.isNumber(todolist.sid) && !_.isNaN(todolist.sid) && todolist.sid > 0 && _.isNumber(todolist.listid) && !_.isNaN(todolist.listid);
    }),function(todolist){
        return todolist.sid + "|" + todolist.listid;
    }).join(",");

    _.each(event.VideoCoaching, function(p){
        p.sessions = _.map(p.sessions, function(s){
            s.flags = flagsAsMap_to_Flags(s.flags_as_map);
            return s;
            // return FIX_toISO8601(s, "startdate");
        });
    });

    if(_.has(event, "flags_as_map")){
        event.flags = flagsAsMap_to_Flags(event.flags_as_map);
    }

    event.isPersonal = !!event.isPersonal;
    // if(_.has(event, "isPersonal_ISO8601")){
    //     delete event.isPersonal_ISO8601;
    // }
    return event;
}
