import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../utils/Trans";

class CopyUrl extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_success: false,
            show_error: false
        };
    }

    selectAll = (e) => {
        e.preventDefault();
        this.input.select();
    }

    copyToClipboard = (e) => {
        e.preventDefault();

        var succeeded;
        try {
            this.input.select();
            succeeded = document.execCommand("copy");
        }catch(err){
            succeeded = false;
        }
        this.setState({
            show_success: succeeded,
            show_error: !succeeded
        });
    }

    setInputRef = (input) => {
        this.input = input;
    }

    render() {
        let {
            url,
            btn_text,
            msg_copied,
            msg_failed_to_copy
        } = this.props;

        let {
            show_success,
            show_error
        } = this.state;

        if (show_success && !msg_copied) {
            msg_copied = __("Copied!");
        } else if(show_error && !msg_failed_to_copy) {
            msg_failed_to_copy = __("Failed to copy, use CTRL+C instead");
        }

        return (
            <div>
                <div className="input-group">
                    <input
                        type="text"
                        className="form-control"
                        defaultValue={url}
                        readOnly={true}
                        onClick={this.selectAll}
                        ref={this.setInputRef}
                        style={{background: "#FFF"}}
                    />
                    <span className="input-group-btn">
                        <button
                            className="btn btn-default"
                            type="button"
                            style={{height:"34px"}}
                            onClick={this.copyToClipboard}
                        >
                            <i className="fa fa-clipboard" />
                            {btn_text && (" " + btn_text)}
                        </button>
                    </span>
                </div>
                {msg_copied ? <div className="text-success">
                    {msg_copied}
                </div> : null}
                {msg_failed_to_copy ? <div className="text-danger">
                    {msg_failed_to_copy}
                </div> : null}
            </div>
        );
    }
}

CopyUrl.propTypes = {
    url: PropTypes.string.isRequired,
    btn_text: PropTypes.string,
    msg_copied: PropTypes.string,
    msg_failed_to_copy: PropTypes.string
};

export default CopyUrl;