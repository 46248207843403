import React from "react";

class Amount extends React.Component {


    render() {
        let {
            input,
            placeholder,
            label,
            help_text,
            type,
            meta: {
                touched,
                error,
                warning
            }
        } = this.props;

        return (
            <div>
                <div className={"form-group " + (touched && error ? "has-error " : touched && warning ? "has-warning " : "")}>
                    {label && <label className="control-label">{label}</label>}
                    <div className="input-group">
                        <span className="input-group-addon">$</span>
                        <input {...input} placeholder={placeholder} type={type} className="form-control" />
                    </div>
                    {help_text && <div className="help-block">{help_text}</div>}
                    {touched &&
                    ((error && <div className="text-danger"><i className="fa fa-times" /> {error}</div>) ||
                        (warning && <div className="text-danger"><i className="fa fa-exclamation-triangle" /> {warning}</div>))}
                </div>
            </div>
        );
    }

}

export default Amount;