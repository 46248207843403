import React from "react";
import _ from "lodash";
import {DefaultPlayer as Video} from "react-html5video";
import {gettext as __} from "../../../../../utils/Trans";

export default class VirtualVideoProof extends React.Component {

  state = {
    video: null,
    speaker: null
  }

  static getDerivedStateFromProps = (props) => ({
    speaker: props.speaker,
    video: _.get(props.speaker, ["assets_virtual_proof"]),
  })

  render() {
    let {
          speaker,
          video
        } = this.state;

    return (
      <React.Fragment>
        <h4 className="header legend">{_.get(speaker, ["firstname"])}{_.get(speaker, ["firstname"], "") !== "" ? "'s" : null}{__(" Virtual Proof Video")}</h4>
        <Video
          controls={["PlayPause", "Seek", "Time", "Volume", "Fullscreen"]}
          src={_.get(video, ["url"])}
          poster={_.get(video, ["img"])}
          onCanPlayThrough={() => {
            // Do stuff
          }}>
        </Video>
        <p className="text-muted" style={{marginTop: "1rem"}}>
          This video was recorded during the certification session and it represents what the presenter delivers remotely.
        </p>
      </React.Fragment>
    );
  }
}