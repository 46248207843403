import _ from "lodash";
import React from "react";
import { gettext as __ } from "../../../../utils/Trans";
import SpeakerAvailabilityForm from "../SpeakerAvailabilityForm";

export default class SpeakerAvailabilitySection extends React.Component {

    __onChangeShortlist = (sids) => {
        if (_.isFunction(this.props.onChangeShortlist)) {
            return this.props.onChangeShortlist(sids);
        }
    }

    __onChangeDate = (value) => {
        if (_.isFunction(this.props.onChangeDate)) {
            return this.props.onChangeDate(value);
        }
    }

    __onSearchSuccess = (value) => {
        if (_.isFunction(this.props.onSearchSuccess)) {
            return this.props.onSearchSuccess(value);
        }
    }

    render() {
        let {
            shortlist_sids,
            date_range,
            speakers,
            selected_sids
        } = this.props;

        return (
            <div>
                <div className="row">
                    <h3 className="text-success text-left">
                        {__("Free inquiry, no obligation")}
                    </h3>
                    <p className="help-block">{__("Make a free inquiry to candidates to find out who will be the best fit. There's never any obligation.")}</p>
                </div>
                <div className="row">
                    <div className="col-md-11 col-sm-12 col-xs-12">
                        {(_.size(shortlist_sids) > 0) &&
                        <div id="speaker_availability_table">
                            <SpeakerAvailabilityForm
                                date_range={date_range}
                                speakers={speakers}
                                shortlist_sids={shortlist_sids}
                                selected_sids={selected_sids}
                                onChangeShortlist={this.__onChangeShortlist}
                                onSearchSuccess={this.__onSearchSuccess}
                                onChangeDate={this.__onChangeDate}
                            />
                        </div>
                        }
                    </div>
                </div>
            </div>
        );
    }
}
