import _ from "lodash";
import Balboa from "../Global/Balboa";
import {sessionService} from "redux-react-session";

export default function(params){
    return (dispatch, getState) => {
        let key = ["tokenauth"];
        return Balboa(dispatch, getState)
            .post(key, "/oauth2/signintoken", params, {cache_ttl: -1})
            .then(function(response) {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            })
            .then(function(response) {
                if (_.has(response, ["data", "token"])) {
                    sessionService.saveSession(_.get(response, ["data", "token"]));
                }
                sessionService.saveUser(_.assign({}, _.get(response, ["data", "user"]), {token: _.get(response, ["data", "token"])}));
                // if (_.has(response, ["data", "redir"]) && !_.isUndefined(_.get(response, ["data", "redir"]))) {
                //     window.location.href = _.get(response, ["data", "redir"]);
                // } else if (_.has(params, ["redir"]) && !_.isUndefined(_.get(params, ["redir"]))) {
                //     window.location.href = toShortlistURL(_.get(params, ["redir"]));
                // } else {
                //     window.location.reload();
                // }
                return response;
            });
    };
};
