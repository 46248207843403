import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";
import ThumbnailButton from "../../../components/ThumbnailButton";
import "./ResultSection.scss";

export default class ResultSection extends React.Component {

    static propTypes = {
        speakers: PropTypes.array,
        see_more_link: PropTypes.string.isRequired,
        title: PropTypes.string.isRequired,
        subtitle: PropTypes.string.isRequired
    }

    static getDerivedStateFromProps = (props, state) => ({
        speakers: props.speakers,
        title: props.title,
        subtitle: props.subtitle,
        see_more_link: props.see_more_link
    })

    state = {
        speakers: [],
        title: "Popular Speakers",
        subtitle: "Check out these great speakers!",
        see_more_link: null
    }

    render() {
        let {
            title,
            subtitle,
            see_more_link,
            speakers
        } = this.state;

        return (
            <React.Fragment>
                <div className={"search-result-section"}>
                    <Row>
                        <Col xs={see_more_link ? 10 : 12}>
                            <h3>
                                {title}
                                <div className={"small text-muted"} style={{textTransform: "none"}}>{subtitle}</div>
                            </h3>
                        </Col>
                        {see_more_link && <Col xs={2}>
                            <div className={"text-right"} style={{marginTop: "30px", marginBottom: "10px"}}>
                                <Link to={see_more_link} className={"small text-muted align-baseline"}>See more{" "}<i className={"fa fa-angle-right"}/></Link>
                            </div>
                        </Col>}
                    </Row>
                    {_.size(speakers) === 0 &&
                        <Row>
                            {_.map([1, 2, 3, 4, 5, 6, 7, 8], (box, i) => (
                                <Col key={i} xs={12} sm={6} md={3}>
                                    <ThumbnailButton
                                        size={"lg"}
                                        src={''}
                                        title={''}
                                        href={'#'}
                                    />
                                </Col>
                            ))}
                        </Row>
                    }
                    {_.size(speakers) > 0 &&
                        <React.Fragment>
                            <Row>
                                {_.map(_.slice(speakers, 0, 4), (speaker, i) => (
                                    <Col key={i} xs={12} sm={6} md={3}>
                                        <ThumbnailButton
                                            size={"lg"}
                                            src={_.get(speaker, ["thumb_url"])}
                                            title={_.get(speaker, ["speakername"])}
                                            href={_.has(speaker, ["external_link"]) ? _.get(speaker, ["external_link"]) : "/profile/" + _.get(speaker, ["sid"])}
                                            is_external_href={_.has(speaker, ["external_link"])}
                                        />
                                        <div className='result-bio' dangerouslySetInnerHTML={{__html: _.get(speaker, ['bio_oneline'])}}/>

                                    </Col>
                                ))}
                            </Row>
                            <Row>
                                {_.map(_.slice(speakers, 4, 8), (speaker, i) => (
                                    <Col key={i} xs={12} sm={6} md={3}>
                                        <ThumbnailButton
                                            size={"lg"}
                                            src={_.get(speaker, ["thumb_url"])}
                                            title={_.get(speaker, ["speakername"])}
                                            href={_.has(speaker, ["external_link"]) ? _.get(speaker, ["external_link"]) : "/profile/" + _.get(speaker, ["sid"])}
                                            is_external_href={_.has(speaker, ["external_link"])}
                                        />
                                     <div className='result-bio' dangerouslySetInnerHTML={{__html: _.get(speaker, ['bio_oneline'])}}/>

                                    </Col>
                                ))}
                            </Row>
                        </React.Fragment>
                    }
                </div>
            </React.Fragment>
        )
    }

}