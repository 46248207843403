import _ from "lodash";
import CookieEmbeddedShortlist from "../../utils/CookieEmbeddedShortlist";

export default function(spkr) {
    return function(dispatch) {
        if (spkr){
            CookieEmbeddedShortlist.add({
                sid: _.toInteger(_.get(spkr, ["sid"], spkr))
            });
        }

        dispatch({
            type: "EMBEDDED_SHORTLIST_ADD",
            sid: _.toInteger(_.get(spkr, ["sid"], spkr))
        });
    };
};
