import _ from "lodash";
import React from "react";
import MarketplaceFooter from "./Footers/MarketplaceFooter";
import WhitelabelFooter from "./Footers/WhitelabelFooter";

import "./NavFooter.scss";

export default class NavFooter extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            whitelabel
        } = this.props;

        return (
            <div>
                {_.get(whitelabel, "is_espeakers", false)
                    ? <MarketplaceFooter whitelabel={whitelabel}/>
                    : <WhitelabelFooter whitelabel={whitelabel}/>
                }
            </div>
        );
    }
}