import _ from "lodash";
import dateformat from "dateformat";

//These types keys correspond to the event object's
let itinerary_types = {
    Flight: function(flight){
        return {
            date: _.head(_.map(flight.legs, "legleave").sort()),

            parts: [
                {
                    description:  flight.description,
                }
            ].concat(_.flattenDeep(_.map(_.sortBy(flight.legs, "legleave"), function(leg){
                return [
                    {
                        date: leg.legleave,
                        description:  "depart " + leg.legfrom
                    },
                    {
                        date: leg.legarrive,
                        description:  "arrive " + leg.legto
                    }
                ];
            })))
        };
    },
    Hotel: function(hotel){
        return [
            {
                date: hotel.checkindate,
                parts: [{
                    date: hotel.checkindate,
                    description: "Check-in " + hotel.name
                }]
            },
            {
                date: hotel.checkoutdate,
                parts: [{
                    date: hotel.checkoutdate,
                    description: "Check-out " + hotel.name
                }]
            }
        ];
    },
    Ground: function(ground){
        return {
            date: ground.starttime,
            parts: [{
                date: ground.starttime,
                description: ground.description
            }]
        };
    }
};

export default function(event){
    return _.map(_.groupBy(_.sortBy(_.filter(_.flattenDeep(_.map(itinerary_types, function(toItinerary, type){
        return _.map(_.get(event, type), function(obj){
            let conv = toItinerary(obj);
            return _.map(_.flattenDeep([conv]), function(item){
                item.id = obj.id;
                item.type = type;
                return item;
            });
        });
    })), function(item){
        return (-631134000 === item.date) || _.isDate(item.date);
    }), "date"), function(item){
        return dateformat(item.date, "yyyy-mm-dd"); //group by day
    }), function(legs){
        var day = -631134000 === legs[0].date
            ? void 0//no date set
            : legs[0].date;
        return {
            date: day,
            legs: _.sortBy(legs, "date")
        };
    });
}
