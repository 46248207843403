import _ from "lodash";

export default function(state = {}, action) {
    switch (action.type) {
        /*
         * EVENT CASES
         */
        case "EVENT_GET": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action.data, ["eid"]);
            _.setWith(new_state, ["events", id], action.data, Object);
            return new_state;
        }
        case "EVENT_GET_ALL": {
            let new_state = _.cloneDeep(state);
            _.each(action.data, function (item) {
                let id = _.get(item, ["eid"]);
                _.setWith(new_state, ["events", id], item, Object);
            });
            return new_state;
        }
        case "EVENT_DELETE": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            _.unset(new_state, ["events", id]);
            return new_state;
        }
        case "EVENT_PUT": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action.data, ["eid"]);
            _.setWith(new_state, ["events", id], action.data, Object);
            return new_state;
        }
        case "EVENT_POST": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action.data, ["eid"]);
            _.setWith(new_state, ["events", id], action.data, Object);
            return new_state;
        }

        /*
         * Other actions used to update the affiliate event
         */
        case "SHORTLIST_UPDATE_PAUSE_EVENT": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["eid"]);
            _.setWith(new_state, ["events", id, "Shortlists", 0], action.data, Object);
            return new_state;
        }
        case "SHORTLIST_UPDATE_RESUME_EVENT": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["eid"]);
            _.setWith(new_state, ["events", id, "Shortlists", 0], action.data, Object);
            return new_state;
        }
        case "EVENT_SHORTLIST_DISMISS_SPEAKER": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["eid"]);
            _.setWith(new_state, ["events", id, "Shortlists", 0], action.data, Object);
            return new_state;
        }
        case "EVENT_AGREEMENT_PUT": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            let speaker_id = _.get(action.data, ["speaker_id"]);
            _.setWith(new_state, ["events", id, "Agreements", speaker_id], action.data, Object);
            return new_state;
        }
        case "EVENT_AGREEMENT_POST": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            let speaker_id = _.get(action.data, ["speaker_id"]);
            _.setWith(new_state, ["events", id, "Agreements", speaker_id], action.data, Object);
            return new_state;
        }
        case "EVENT_AGREEMENT_GET": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            let speaker_id = _.get(action.data, ["speaker_id"]);
            _.setWith(new_state, ["events", id, "Agreements", speaker_id], action.data, Object);
            return new_state;
        }
        case "EVENT_AGREEMENT_DELETE": {
            let new_state = _.cloneDeep(state);
            let eid = _.get(action, ["eid"]);
            let sid = _.get(action, ["sid"]);
            let agreements = _.get(new_state, ["events", eid, "Agreements"]);
            agreements = _.omit(agreements, sid);
            _.setWith(new_state, ["events", eid, "Agreements"], agreements, Object);
            return new_state;
        }
        case "EVENT_COLLABORATOR_POST": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            let collaborators = _.get(new_state, ["events", id, "Shortlists", 0, "collaborators"], []);
            collaborators = _.concat(collaborators, action.data);
            _.setWith(new_state, ["events", id, "Shortlists", 0, "collaborators"], collaborators, Object);
            return new_state;
        }
        case "EVENT_COLLABORATOR_DELETE": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            let collaborators = _.filter(_.get(new_state, ["events", id, "Shortlists", 0, "collaborators"], []), (item) => {
                return (_.get(item, ["id"]) !== _.get(action.data, ["id"]));
            });
            _.setWith(new_state, ["events", id, "Shortlists", 0, "collaborators"], collaborators, Object);
            return new_state;
        }
        case "EVENT_RATING_POST": {
            let new_state = _.cloneDeep(state);
            let eid = _.get(action, ["id"]);
            let speaker_id = _.get(action.data, ["speaker_id"]);
            let mp_collaborator_id = _.get(action.data, ["id"]);
            _.setWith(new_state, ["events", eid, "Shortlists", 0, "speakers", speaker_id, "ratings", mp_collaborator_id], action.data, Object);

            return new_state;
        }
        case "EVENT_SPEAKER_DELETE": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            let speakers = _.filter(_.get(new_state, ["events", id, "Shortlists", 0, "speakers"], []), (item) => {
                return (_.get(item, ["id"]) !== _.get(action.data, ["id"]));
            });
            _.setWith(new_state, ["events", id, "Shortlists", 0, "speakers"], speakers, Object);
            return new_state;
        }
        default: {
            return state;
        }
    }
}