import React from "react";
import PropTypes from "prop-types";
import { Button } from "react-bootstrap";
import { gettext as __ } from "../../../utils/Trans";
import MarketplaceSearch from "../../MarketplaceSearch";

import "./HomePageSearch.scss";

class HomePageSearch extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            input
        } = this.props;

        return (
            <MarketplaceSearch
                input={input}
                containerClassName="home-page-search"
                button={
                    <div className="btn-search">
                        <Button type="submit" className="btn hidden-xs btn-success">{__("search speakers")}</Button>
                        <Button type="submit" className="btn visible-xs btn-success"><i className="fa fa-search" /></Button>
                    </div>
                }
            />
        );
    }
}

HomePageSearch.propTypes = {
    whitelabel: PropTypes.object,
    input: PropTypes.object
};

export default HomePageSearch;