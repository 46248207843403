import _ from "lodash";
import Rest from "../Global/Rest";
import Balboa from "../Global/Balboa";
import md5 from "md5";

export default _.assign({}, Rest, {

    /**
     * Retrieves a list of records.  Pass in search parameters in {params}
     * @param params
     * @returns {Function}
     */
    loadMore: function (params) {
        let key_hash = md5(JSON.stringify(params));
        let key = ["search", key_hash];
        let self = this;
        return function (dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/speaker/search", params)
                    .then(function (response) {
                        return _.get(response, ["data"]);
                    })
                    .then(function (data) {
                        dispatch({
                            type: "SEARCHPAGE_MORE_RESULTS",
                            data: data,
                        });
                        return data;
                    })
                    .then((data) => {
                        dispatch({
                            type: "SEARCHPAGE_SAVE_HASH",
                            sids: data,
                            hash: key_hash
                        });
                        return data;
                    })
                    .then((data) => {
                        dispatch({
                            type: "SPEAKER_SHORT_PROFILES_SEARCH_RESULTS",
                            data: _.get(data, ["results"])
                        });
                        return data;
                    })
            );
        };
    },

    /**
     * Retrieves a list of records.  Pass in search parameters in {params}
     * @param params
     * @returns {Function}
     */
    searchshowcase: function (params) {
        let key_hash = md5(JSON.stringify(params));
        let key = ["search", key_hash];
        let self = this;
        return function (dispatch, getState) {
            let state = getState();
            if (_.has(state, ["SearchPage", "search", key_hash], false)) {
                return new Promise((resolve /*, reject */) => {
                    return resolve(_.get(getState(), ["SearchPage", "search", key_hash]));
                })
                    .then((data) => {
                        dispatch({
                            type: "SPEAKERS_SHOWCASE_RESULTS",
                            data: data,
                        });
                        return data;
                    });
            } else {
                return self.api(
                    dispatch,
                    key,
                    Balboa(dispatch, getState)
                        .get(key, "/speaker/searchshowcase", params)
                        .then(function (response) {
                            return _.get(response, ["data"]);
                        })
                        .then(function (data) {
                            dispatch({
                                type: "SPEAKERS_SHOWCASE_RESULTS",
                                data: data,
                            });
                            return data;
                        })
                        .then((data) => {
                            dispatch({
                                type: "SEARCHPAGE_SAVE_HASH",
                                sids: data,
                                hash: key_hash
                            });
                            return data;
                        })
                        .then((data) => {
                            dispatch({
                                type: "SPEAKER_SHORT_PROFILES_SEARCH_RESULTS",
                                data: data
                            });
                            return data;
                        })
                );
            }
        };
    },

    /**
     * Retrieves a list of records.  Pass in search parameters in {params}
     * @param params
     * @returns {Function}
     */
    searchtrending: function (params) {
        let key_hash = md5(JSON.stringify(params));
        let key = ["search", key_hash];
        let self = this;
        return function (dispatch, getState) {
            let state = getState();
            if (_.has(state, ["SearchPage", "search", key_hash], false)) {
                return new Promise((resolve /*, reject */) => {
                    return resolve(_.get(getState(), ["SearchPage", "search", key_hash]));
                })
                    .then((data) => {
                        dispatch({
                            type: "SPEAKERS_TRENDING_RESULTS",
                            data: data,
                        });
                        return data;
                    });
            } else {
                return self.api(
                    dispatch,
                    key,
                    Balboa(dispatch, getState)
                        .get(key, "/speaker/searchtrending", params)
                        .then(function (response) {
                            return _.get(response, ["data"]);
                        })
                        .then(function (data) {
                            dispatch({
                                type: "SPEAKERS_TRENDING_RESULTS",
                                data: data,
                            });
                            return data;
                        })
                        .then((data) => {
                            dispatch({
                                type: "SEARCHPAGE_SAVE_HASH",
                                sids: data,
                                hash: key_hash
                            });
                            return data;
                        })
                        .then((data) => {
                            dispatch({
                                type: "SPEAKER_SHORT_PROFILES_SEARCH_RESULTS",
                                data: data
                            });
                            return data;
                        })
                );
            }
        };
    },

    /**
     * Retrieves a list of records.  Pass in search parameters in {params}
     * @param params
     * @returns {Function}
     */
    searchvirtual: function (params) {
        let key_hash = md5(JSON.stringify(params));
        let key = ["search", key_hash];
        let self = this;
        return function (dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/marketplace/speaker-search-virtual", params)
                    .then((response) => (
                        _.get(response, ["data"])
                    ))
            );
        };
    },

    /**
     * For the speakers on the displayed page of results, record an impression.
     *
     * Call this from the search() action in this file for the initial (page 0) page of results.
     * Call from SearchMore.js submit() to get the additional page loads (page 1+)
     *
     * @param params
     * @returns {function(*=, *=): *}
     */
    saveProfileImpressions: function (params) {
        let impression_speaker_ids = _.get(params, 'speaker_ids', []);

        let key_hash = md5(JSON.stringify(impression_speaker_ids));

        let key = ["search_save_profile_impressions", key_hash];
        let self = this;
        return function (dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/speaker/recordimpressions", {
                        speaker_ids: impression_speaker_ids,
                        bureau_id: _.get(params, 'bureau_id', 0),
                        norecord: _.get(params, 'norecord', false)
                    })
            );
        };
    },

    /**
     * Retrieves a list of records.  Pass in search parameters in {params}
     * @param params
     * @returns {Function}
     */
    search: function (params) {
        let key_hash = md5(JSON.stringify(params));
        let key = ["search", key_hash];
        let self = this;
        return function (dispatch, getState) {
            let state = getState();
            if (_.has(state, ["SearchPage", "search", key_hash], false)) {
                return new Promise((resolve /*, reject */) => {
                    return resolve(_.get(getState(), ["SearchPage", "search", key_hash]));
                })
                    .then((data) => {
                        dispatch({
                            type: "SEARCHPAGE_RESULTS",
                            data: data,
                        });
                        return data;
                    });
            } else {
                return self.api(
                    dispatch,
                    key,
                    Balboa(dispatch, getState)
                        .get(key, "/speaker/search", params)
                        .then(function (response) {
                            return _.get(response, ["data"]);
                        })
                        .then((data) => {
                            let page = parseInt(_.get(params, "page", 0), 0);
                            let per_page = parseInt(_.get(params, "items_per_page", 12), 0);
                            let speaker_ids = _.map(_.get(data, "entries", []), "sid").slice(page * per_page, (page + 1) * per_page);
                            let norecord = _.get(params, 'norecord', false);

                            if (!norecord) {
                                dispatch(self.saveProfileImpressions({
                                    norecord: false,
                                    bureau_id: _.get(params, "bureau_id", 0),
                                    speaker_ids: speaker_ids
                                }));
                            }
                            return data;
                        })
                        .then(function (data) {
                            dispatch({
                                type: "SEARCHPAGE_RESULTS",
                                data: data,
                            });
                            return data;
                        })
                        .then((data) => {
                            dispatch({
                                type: "SEARCHPAGE_SAVE_HASH",
                                sids: data,
                                hash: key_hash
                            });
                            return data;
                        })
                        .then((data) => {
                            dispatch({
                                type: "SEARCHPAGE_SAVE_LAST",
                                sids: data,
                                hash: key_hash,
                                params: params
                            });
                            return data;
                        })
                        .then((data) => {
                            dispatch({
                                type: "SPEAKER_SHORT_PROFILES_SEARCH_RESULTS",
                                data: data
                            });
                            return data;
                        })
                );
            }
        };
    }
});