import _ from "lodash";

export default function(state = {}, action){
    switch(action.type){
        case "SHORTLIST_CREATE": {
            let new_state = _.cloneDeep(state);
            _.set(new_state, [action.eid], action.data);
            return _.assign({}, state, new_state);
        }
        case "SHORTLIST_UPDATE": {
            let new_state = _.cloneDeep(state);
            _.set(new_state, [action.eid], action.data);
            return _.assign({}, state, new_state);
        }
        case "SHORTLIST_UPDATE_PAUSE_EVENT": {
            let new_state = _.cloneDeep(state);
            let eid = action.eid;
            let new_flags = _.concat(_.get([eid, "flags"], []), ["is_event_paused"]);
            _.set(new_state, [eid, "flags"], new_flags);
            return _.assign({}, state, new_state);
        }
        case "SHORTLIST_UPDATE_RESUME_EVENT": {
            let new_state = _.cloneDeep(state);
            let eid = action.eid;
            let new_flags = _.without(_.get([eid, "flags"], []), "is_event_paused");
            _.set(new_state, [eid, "flags"], new_flags);
            return _.assign({}, state, new_state);
        }
        case "SHORTLIST_REMOVE_SPEAKER": {
            let new_state = _.cloneDeep(state);
            let eid = action.eid;
            let new_speakers = _.filter(_.get(new_state, [eid, "speakers"]), function (speaker) {
                return _.get(speaker, ["speaker_id"]) !== action.sid;
            });
            _.set(new_state, [eid, "speakers"], new_speakers);
            return _.assign({}, state, new_state);
        }
        case "SHORTLIST_DELETE": {
            let new_state = _.cloneDeep(state);
            return _.without(new_state, action.eid);
        }
        case "SHORTLIST_LOAD": {
            return _.assign({}, state, action.data);
        }
        case "SHORTLIST_SHARED_LOAD": {
            let new_state = _.cloneDeep(state);
            _.setWith(new_state, ["shared", action.id], action.data, Object);
            return _.assign({}, state, new_state);
        }

        /*
         * COLLABORATOR CASES
         */
        case "SHORTLIST_COLLABORATOR_GET": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            let shortlist_id = _.get(action.data, ["mp_shortlist_id"]);
            _.setWith(new_state, [shortlist_id, "collaborators", id], action.data, Object);
            return new_state;
        }
        case "SHORTLIST_COLLABORATOR_GET_ALL": {
            let new_state = _.cloneDeep(state);
            if (!_.isEmpty(action.data)) {
                _.each(action.data, function (item) {
                    let id = _.get(item, ["id"]);
                    let shortlist_id = _.get(action.data, ["mp_shortlist_id"]);
                    _.setWith(new_state, [shortlist_id, "collaborators", id], item, Object);
                });
            } else {
                _.setWith(new_state, [action.shortlist_id, "collaborators"], {}, Object);
            }
            return new_state;
        }
        case "SHORTLIST_COLLABORATOR_DELETE": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            let shortlist_id = _.get(action.data, ["mp_shortlist_id"]);
            let collaborators = _.get(new_state, [shortlist_id, "collaborators"]);
            _.omit(collaborators, id);
            _.setWith(new_state, [shortlist_id, "collaborators"], collaborators, Object);
            return new_state;
        }
        case "SHORTLIST_COLLABORATOR_PUT": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            let shortlist_id = _.get(action.data, ["mp_shortlist_id"]);
            _.setWith(new_state, [shortlist_id, "collaborators", id], action.data, Object);
            return new_state;
        }
        case "SHORTLIST_COLLABORATOR_POST": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action, ["id"]);
            let shortlist_id = _.get(action.data, ["mp_shortlist_id"]);
            _.setWith(new_state, [shortlist_id, "collaborators", id], action.data, Object);
            return new_state;
        }

        /*
         * RATING CASES
         */
        case "SHORTLIST_RATING_GET": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action.data, ["id"]);
            let shortlist_id = action.mp_shortlist_id;
            let shortlist_speakers_id = _.get(action.data, ["mp_shortlist_speakers_id"]);
            let mp_collaborator_id = _.get(action.data, ["mp_collaborator_id"]);
            _.setWith(new_state, [shortlist_id, "ratings", id], action.data, Object);

            // Also update the rating in the speakers object
            let spkr_index = _.findIndex(_.get(new_state, [shortlist_id, "speakers"]), {
                mp_shortlist_speakers_id: shortlist_speakers_id
            });
            let spkr_ratings = _.get(new_state, [shortlist_id, "speakers", spkr_index, "ratings"]);
            let rating_index = _.findIndex(spkr_ratings, {
                mp_collaborator_id: mp_collaborator_id
            });
            if (rating_index >= 0) {
                _.setWith(new_state, [shortlist_id, "speakers", spkr_index, "ratings", rating_index], action.data, Object);
            } else {
                spkr_ratings = _.concat({}, spkr_ratings, [action.data]);
                _.setWith(new_state, [shortlist_id, "speakers", spkr_index, "ratings"], spkr_ratings, Object);
            };

            return new_state;
        }
        case "SHORTLIST_RATING_GET_ALL": {
            let new_state = _.cloneDeep(state);
            if (!_.isEmpty(action.data)) {
                _.each(action.data, function (item) {
                    let id = _.get(action.data, ["id"]);
                    let shortlist_id = action.mp_shortlist_id;
                    _.setWith(new_state, [shortlist_id, "ratings", id], item, Object);
                });
            } else {
                _.setWith(new_state, [action.shortlist_id, "ratings"], {}, Object);
            }

            // Also update the rating in the speakers object
            _.each(action.data, function (item) {
                let shortlist_id = action.mp_shortlist_id;
                let shortlist_speakers_id = _.get(action.data, ["mp_shortlist_speakers_id"]);
                let mp_collaborator_id = _.get(action.data, ["mp_collaborator_id"]);
                let spkr_index = _.findIndex(_.get(new_state, [shortlist_id, "speakers"]), {
                    mp_shortlist_speakers_id: shortlist_speakers_id
                });
                let spkr_ratings = _.get(new_state, [shortlist_id, "speakers", spkr_index, "ratings"]);
                let rating_index = _.findIndex(spkr_ratings, {
                    mp_collaborator_id: mp_collaborator_id
                });
                if (rating_index >= 0) {
                    _.setWith(new_state, [shortlist_id, "speakers", spkr_index, "ratings", rating_index], action.data, Object);
                } else {
                    spkr_ratings = _.concat({}, spkr_ratings, [action.data]);
                    _.setWith(new_state, [shortlist_id, "speakers", spkr_index, "ratings"], spkr_ratings, Object);
                };

            });

            return new_state;
        }
        case "SHORTLIST_RATING_DELETE": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action.data, ["id"]);
            let shortlist_id = action.mp_shortlist_id;
            let shortlist_speakers_id = _.get(action.data, ["mp_shortlist_speakers_id"]);
            let mp_collaborator_id = _.get(action.data, ["mp_collaborator_id"]);
            let ratings = _.get(action.data, ["ratings"]);
            _.omit(ratings, id);
            _.setWith(new_state, [shortlist_id, "ratings"], ratings, Object);

            // Also update the rating in the speakers object
            let spkr_index = _.findIndex(_.get(new_state, [shortlist_id, "speakers"]), {
                mp_shortlist_speakers_id: shortlist_speakers_id
            });
            let spkr_ratings = _.get(new_state, [shortlist_id, "speakers", spkr_index, "ratings"]);
            let rating_index = _.findIndex(spkr_ratings, {
                mp_collaborator_id: mp_collaborator_id
            });
            _.omit(spkr_ratings, rating_index);
            _.setWith(new_state, [shortlist_id, "speakers", spkr_index, "ratings"], spkr_ratings, Object);

            return new_state;
        }
        case "SHORTLIST_RATING_PUT": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action.data, ["id"]);
            let shortlist_id = action.mp_shortlist_id;
            let shortlist_speakers_id = _.get(action.data, ["mp_shortlist_speakers_id"]);
            let mp_collaborator_id = _.get(action.data, ["mp_collaborator_id"]);
            _.setWith(new_state, [shortlist_id, "ratings", id], action.data, Object);

            // Also update the rating in the speakers object
            let spkr_index = _.findIndex(_.get(new_state, [shortlist_id, "speakers"]), {
                mp_shortlist_speakers_id: shortlist_speakers_id
            });
            let spkr_ratings = _.get(new_state, [shortlist_id, "speakers", spkr_index, "ratings"]);
            let rating_index = _.findIndex(spkr_ratings, {
                mp_collaborator_id: mp_collaborator_id
            });
            if (spkr_index >= 0 && rating_index >= 0) {
                _.setWith(new_state, [shortlist_id, "speakers", spkr_index, "ratings", rating_index], action.data, Object);
            }
            return new_state;
        }
        case "SHORTLIST_RATING_POST": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action.data, ["id"]);
            let shortlist_id = action.mp_shortlist_id;
            let shortlist_speakers_id = _.get(action.data, ["mp_shortlist_speakers_id"]);
            let mp_collaborator_id = _.get(action.data, ["mp_collaborator_id"]);
            _.setWith(new_state, [shortlist_id, "ratings", id], action.data, Object);

            // Also update the rating in the speakers object
            let spkr_index = _.findIndex(_.get(new_state, [shortlist_id, "speakers"]), {
                mp_shortlist_speakers_id: shortlist_speakers_id
            });
            let spkr_ratings = _.get(new_state, [shortlist_id, "speakers", spkr_index, "ratings"]);
            let rating_index = _.findIndex(spkr_ratings, {
                mp_collaborator_id: mp_collaborator_id
            });
            if (spkr_index >= 0) {
                if (rating_index >= 0) {
                    _.setWith(new_state, [shortlist_id, "speakers", spkr_index, "ratings", rating_index], action.data, Object);
                } else {
                    let ratings = _.concat(_.get(new_state, [shortlist_id, "speakers", spkr_index, "ratings"]), action.data);
                    _.setWith(new_state, [shortlist_id, "speakers", spkr_index, "ratings"], ratings, Object);
                }
            }
            return new_state;
        }

        /*
         * SPEAKER CASES
         */
        case "SHORTLIST_SPEAKER_GET": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action.data, ["id"]);
            let shortlist_id = _.get(action.data, ["mp_shortlist_id"]);
            _.setWith(new_state, [shortlist_id, "speakers", id], action.data, Object);
            return new_state;
        }
        case "SHORTLIST_SPEAKER_GET_ALL": {
            let new_state = _.cloneDeep(state);
            // Also update the rating in the speakers object
            _.each(action.data, function (item) {
                let id = _.get(item, ["id"]);
                let shortlist_id = _.get(item, ["mp_shortlist_id"]);
                _.setWith(new_state, [shortlist_id, "speakers", id], action.data, Object);
                return new_state;

            });
            return new_state;
        }
        case "SHORTLIST_SPEAKER_DELETE": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action.data, ["id"]);
            let shortlist_id = _.get(action.data, ["mp_shortlist_id"]);
            let speakers = _.get(new_state, [shortlist_id, "speakers"]);
            _.omit(speakers, id);
            _.setWith(new_state, [shortlist_id, "speakers"], speakers, Object);
            return new_state;
        }
        case "SHORTLIST_SPEAKER_PUT": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action.data, ["id"]);
            let shortlist_id = _.get(action.data, ["mp_shortlist_id"]);
            _.setWith(new_state, [shortlist_id, "speakers", id], action.data, Object);
            return new_state;
        }
        case "SHORTLIST_SPEAKER_POST": {
            let new_state = _.cloneDeep(state);
            let id = _.get(action.data, ["id"]);
            let shortlist_id = _.get(action.data, ["mp_shortlist_id"]);
            _.setWith(new_state, [shortlist_id, "speakers", id], action.data, Object);
            return new_state;
        }

        default:
            return state;
    }
}
