import _ from "lodash";
import ReactGA from "react-ga4";
import ReactPixel from "react-facebook-pixel";
import Constants from "./Constants";

export default {
    debug: false,
    analyticsProperties: {},

    /**
     * Initialize Google Analytics.
     *
     * Should only be done once per page, so somewhere like constructor() is good
     *
     *
     */
    initialize: function (whitelabel) {
        //GOOGLE ANALYTICS

        //eSpeakers own tracker
        this.analyticsProperties = [
            {
                trackingId: Constants.ESPEAKERS_ANALYTICS_ID,
                gaOptions: {},
                gtagOptions: {}
            }
        ];

        //optional customer tracker
        if (
            whitelabel &&
            Constants.TRUNK_BUREAU_ID !== _.get(whitelabel, "bid", 0) &&
            !_.isEmpty(_.get(whitelabel, ["mp_keyvalues", "GA_PROPERTY_ID"]))
        ) {
            this.analyticsProperties.push({
                trackingId: _.get(whitelabel, [
                    "mp_keyvalues",
                    "GA_PROPERTY_ID"
                ]),
                gaOptions: {},
                gtagOptions: {}
            });
        }

        ReactGA.initialize(this.analyticsProperties);
        if (this.debug) {
            console.log("GA initialized with ", this.analyticsProperties);
        }

        //FACEBOOK
        const fb_options = {
            autoConfig: true,
            debug: this.debug
        };
        ReactPixel.init(Constants.ESPEAKERS_FACEBOOK_PIXEL_ID, {}, fb_options);
    },

    /**
     * Log page views.
     * ASSUMES that the initialize() has already been done somewhere (like /index.js).
     *
     * Debounce this to prevent double-reporting when a App re-renders quickly
     *
     * @param path
     */
    logPageView: _.debounce(function (path) {
        path = _.isEmpty(path)
            ? window.location.pathname + window.location.search
            : path;

        //GOOGLE ANALYTICS
        let debug = this.debug;

        if (ReactGA.isInitialized) {
            ReactGA.send({ hitType: "pageview" });
            if (debug) {
                console.log("GA sent pageview [" + path + "]");
            }
        } else {
            if (debug) {
                console.log("GA not initialized");
            }
        }

        //FACEBOOK
        ReactPixel.pageView();
    }, 500),

    /**
     * Send an event to Google Analytics.
     * ASSUMES that the initialize() has already been done somewhere (like /index.js).
     *
     *
     * @param action
     * @param value
     * @param label
     */
    sendEvent: function (category, action, value, label) {
        value = _.parseInt(value, 10); //accepts only numbers
        value = isNaN(value) ? null : value;

        let debug = this.debug;

        if (ReactGA.isInitialized) {
            ReactGA.event({
                category: category,
                action: action,
                label: label,
                value: value
            });
            if (debug) {
                console.log("GA sent event " + action);
            }
        } else {
            if (debug) {
                console.log("GA not initialized");
            }
        }
    }
};
