import _ from "lodash";
import React from "react";
import ReactToggle from "react-toggle";

export default class Toggle extends React.Component {

    render() {
        let {
            input,
            label,
            name,
            help_text,
            hide_errors,
            meta: {
                touched,
                error,
                warning
            }
        } = this.props;

        return (
            <div className={(touched && error ? "has-error " : touched && warning ? "has-warning " : "")}>
                <ReactToggle
                    {...input}
                    value={_.toString(input.value)}
                    checked={!!input.value}
                />
                <label htmlFor={name} onClick={() => {input.onChange(!input.value);}} style={{verticalAlign: "middle"}}>
                    {label}
                    {help_text && <span className="small">{help_text}</span>}
                </label>
                {!hide_errors && touched &&
                ((error && <div className="text-danger"><i className="fa fa-times" /> {error}</div>) ||
                    (warning && <div className="text-danger"><i className="fa fa-exclamation-triangle" /> {warning}</div>))}
            </div>
        );
    }

}