import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Link, Redirect } from "react-router-dom";
import { gettext as __ } from "../../utils/Trans";
import InlineError from "../../components/InlineError";
import LoadingIndicator from "../../components/LoadingIndicator";
import BookMeNowForm from "./BookMeNowForm";
import Speaker from "../../actions/Speaker/Speaker";
import queryString from "query-string";

export class BookMeNowPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    UNSAFE_componentWillMount() {
        if (!this.props.speaker) {
            this.props.loadSpeaker();
        }
    }

    render() {
        let {
            errors,
            is_waiting,
            token,
            speaker,
            whitelabel
        } = this.props;

        let query = queryString.parse(window.location.search);


        // Redirect to the direct message page if we aren't a PLUS or PRO speaker
        if (speaker && (_.get(speaker, ["version"], 7)) > 6) {
            return (<Redirect to={"/direct_message/" + _.get(speaker, ["sid"]) + "?" + queryString.stringify(query)} />);
        }

        return (
            <div>
                <h1 className="page-header">
                    {__("Instant Inquiry")}
                </h1>
                {_.map(errors, function (error, i) {
                    return <InlineError key={i} error={error}/>;
                })}
                <div className="row" style={{marginBottom: "3em"}}>
                    <div className="col-md-3">
                        <div className="well">
                            <img src={_.get(speaker, ["thumb_url"])} alt={_.get(speaker, ["speakername"])} className="img-responsive img-rounded hidden-sm hidden-xs"/>
                            <h2>{_.get(speaker, ["speakername"])}</h2>
                            <br />
                            <div dangerouslySetInnerHTML={{__html: _.get(speaker, ['bio_oneline'])}}/>
                            <br />

                            <p>
                                <Link to={"/profile/" + _.get(speaker, ["sid"]) + "/bookmenow_full_profile?bookmenow=" + token}><b>{__("View full profile")} »</b></Link>
                            </p>
                        </div>
                    </div>
                    <div className="col-md-8 col-md-offset-1">
                        <div>
                            {speaker && <BookMeNowForm speaker={speaker} token={token} whitelabel={whitelabel} />}
                        </div>
                    </div>
                </div>
                {is_waiting && <LoadingIndicator />}
            </div>
        );
    }
}


////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {
    let sid = _.get(ownProps.match, ["params", "sid"]);
    let token = _.get(ownProps.match, ["params", "token"], "");

    // Page Properties
    let speaker = _.get(state, ["Speakers", sid]);

    return {
        is_waiting: _.isUndefined(speaker),
        token: token,
        speaker: speaker
    };
};

var mapDispatchToProps = function (dispatch, ownProps) {
    let sid = _.get(ownProps.match, ["params", "sid"]);
    return {
        loadSpeaker: function() {
            dispatch(Speaker.get(sid));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(BookMeNowPage);