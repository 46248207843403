import _ from "lodash";
import React from "react";
import ReactDOM from "react-dom";
import {applyMiddleware, compose, createStore} from "redux";
import {sessionService} from "redux-react-session";
import thunk from "redux-thunk";
import promise from "redux-promise";
import {createLogger} from "redux-logger";
import {Provider} from "react-redux";
import queryString from "query-string";
import Whitelabel from "./Whitelabel";
import registerServiceWorker from "./registerServiceWorker";
import CookieEmbeddedShortlist from "./utils/CookieEmbeddedShortlist";
import toShortlistURL from "./utils/toShortlistURL";
import rootReducer from "./reducers";
import moment from "moment";
import ErrorPage from "./containers/ErrorPage";

try {
    let qs = queryString.parse(window.location.search);
    let search_setup = _.get(qs, ["setup"], false);
    let search_tags = _.get(qs, ["searchtags[]"], false);

    let initial_state = {};

    ////////////////////////////////////////////////////////////////////////////////
    //
    // Configure the various middlewares and the redux store
    //
    let enhancer;
    const middlewares = [thunk, promise];
    if (process.env.NODE_ENV !== "production") {
        const logger = createLogger({
            level: "error",
            collapsed: (getState, action, logEntry) => !logEntry.error,
            duration: true
        });
        middlewares.push(logger);
        const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
        enhancer = composeEnhancers(
            applyMiddleware(...middlewares)
        );
    } else {
        enhancer = applyMiddleware(...middlewares);
    }

    const store = createStore(rootReducer, initial_state, enhancer);


    ////////////////////////////////////////////////////////////////////////////////
    //
    // Initiate the session (from the cookie)
    //
    const validateSession = (session) => {
        // check if your session is still valid by checking the timestamp of the token
        if (_.has(_.split(session, ["$"]), [1])) {
            let now = _.get(_.split(session, ["$"]), [1]);
            return ((moment().format("X") - now) < 2160000);
        } else {
            return false;
        }
    }

    sessionService.initSessionService(store, {
        refreshOnCheckAuth: false,
        redirectPath: "/signin",
        driver: "COOKIES",
        validateSession
    })
        .then((session) => {
            // verify the token is valid, otherwise, invalidate the session
        })
        .catch(() => {
            // Redux React Session is ready and there is no session in your storage
        });

    ////////////////////////////////////////////////////////////////////////////////
    //
    // Handle redirects of hashbang
    //
    if (window.location.pathname === toShortlistURL("/v3") && window.location.hash !== "") {
        window.location.replace(toShortlistURL(_.replace(window.location.hash, "#", "")));
    }

    ////////////////////////////////////////////////////////////////////////////////
    //
    // Handle unhandled errors
    //
    // NOTE: we seem to get a lot of false positives on this error handling, so just comment it out for now
    //
    window.addEventListener("unhandledrejection", (e) => {
        e.preventDefault();
        console.log(e);
        let reason = e.toString();
        if (reason.indexOf("PromiseRejectionEvent") === -1) {
          ReactDOM.render(<Provider store={store} key="provider">
            <ErrorPage error={_.get(e, ["reason"])}/>
          </Provider>, document.getElementById("root"));
        } else {
            console.log("PromiseRejectionEvent skipped: ", e);
        }
    });

    ////////////////////////////////////////////////////////////////////////////////
    //
    // Dispatch some basic actions
    //
    try {
        if (typeof (Storage) !== "undefined") {
            if (window.localStorage.getItem("last_search_params")) {
                let params;
                try {
                    params = JSON.parse(window.localStorage.getItem("last_search_params"));
                } catch (e) { /* no need to catch an error here */
                }
                if (_.isEmpty(params)) {
                    store.dispatch({
                        type: "SEARCHPAGE_SET_LAST_SEARCH_PARAMS",
                        search_params: params
                    });
                }
            }

            if (window.localStorage.getItem("pending_job")) {
                let params;
                try {
                    params = JSON.parse(window.localStorage.getItem("pending_job"));
                } catch (e) { /* no need to catch an error here */
                }
                if (params) {
                    store.dispatch({
                        type: "POSTAJOBPAGE_SAVEPENDINGJOB",
                        data: params
                    });
                }
            }
        }
    } catch (e) { /* no need to catch an error here */
    }

    try {
        _.each(CookieEmbeddedShortlist.getSIDs(), function (sid) {
            store.dispatch({
                type: "EMBEDDED_SHORTLIST_ADD",
                sid: sid
            });
        });
    } catch (e) { /* no need to catch an error here */
    }

    try {
        if (search_setup) {
            let params;
            try {
                params = JSON.parse(decodeURIComponent(search_setup));
            } catch (e) { /* no need to catch an error here */
            }
            if (params) {
                store.dispatch({
                    type: "SEARCHPAGE_SET_LAST_SEARCH_PARAMS",
                    search_params: params
                });
            }
        }

        if (search_tags) {
            let params;
            try {
                params = {free_form_text_tags: search_tags};
            } catch (e) { /* no need to catch an error here */
            }
            if (params) {
                store.dispatch({
                    type: "SEARCHPAGE_SET_LAST_SEARCH_PARAMS",
                    search_params: params
                });
            }
        }
    } catch (e) { /* no need to catch an error here */
    }

    ReactDOM.render(
        <Provider store={store} key="provider">
            <Whitelabel/>
        </Provider>, document.getElementById("root")
    );
    registerServiceWorker();

} catch (err) {
    // if (store) {
    //     console.error("Notifying support about error!!!");
    //     store.dispatch(DebugTrace({
    //         message: _.get(err, ["message"]),
    //         file: "marketplace/index.js",
    //         line: 164,
    //         stack: _.get(err, ["stack"]),
    //         referrer: _.get(document, ["referrer"])
    //     }));
    // } else {

    console.log(err);
    console.error("Cannot notify support about error, check that store exists!!!");
    // }
}
