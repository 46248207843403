import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import {Tabs, Tab} from "react-bootstrap";
import { gettext as __ } from "../../../../utils/Trans";
import About from "./About";
import Programs from "./Programs";
import Availability from "./Availability";
// import Social from "./Social";
import Reviews from "./Reviews";
import Files from "./Files";
import Courses from "./Courses";
import {
  Virtual,
  VirtualWithCvp
} from "./Virtual";

export default class SpeakerTabs extends React.Component {

  static propTypes = {
    speaker: PropTypes.object.isRequired,
    onVideoClick: PropTypes.func.isRequired,
    onShowTab: PropTypes.func.isRequired,
    selected_tab: PropTypes.string,
    profile_language: PropTypes.string
  }

  state = {
    selected_tab: "about"
  };

  static getDerivedStateFromProps = (props) => ({
    selected_tab: props.selected_tab,
    tabs: [
      {show: true, tab: "about", name: "About", component: <About speaker={props.speaker} profile_language={props.profile_language} topic_id={props.topic_id} onVideoClick={props.onVideoClick} />},
      {show: (_.size(_.get(props.speaker, ["programs"])) > 0), tab: "programs", name: "Programs", component: <Programs speaker={props.speaker} profile_language={props.profile_language} topic_id={props.topic_id} onVideoClick={props.onVideoClick} />},
      {show: (_.get(props.speaker, ["has_calendar_data"], false)), tab: "availability", name: "Availability", component: <Availability speaker={props.speaker} profile_language={props.profile_language} topic_id={props.topic_id} onVideoClick={props.onVideoClick} />},
      {show: (_.size(_.get(props.speaker, ["recommendations"], [])) > 0), tab: "recommendations", name: "Reviews", component: <Reviews speaker={props.speaker} profile_language={props.profile_language} topic_id={props.topic_id} onVideoClick={props.onVideoClick} />},
      // {show: (_.get(props.speaker, ["has_social_data"], false)), tab: "social", name: "Social", component: <Social speaker={props.speaker} profile_language={props.profile_language} topic_id={props.topic_id} onVideoClick={props.onVideoClick} />},
      {show: (_.size(_.get(props.speaker, ["assets"], [])) > 0), tab: "files", name: "Materials", component: <Files speaker={props.speaker} profile_language={props.profile_language} topic_id={props.topic_id} onVideoClick={props.onVideoClick} />},
      {show: (_.get(props.speaker, ["has_courses"])), tab: "courses", name: "Online Courses", component: <Courses speaker={props.speaker} profile_language={props.profile_language} topic_id={props.topic_id} onVideoClick={props.onVideoClick} />},
      {show: (_.get(props.speaker, ["flags_as_map", "virtual_certified", "is_set"], false)), tab: "virtual", name: "Virtual", component: <VirtualWithCvp speaker={props.speaker} profile_language={props.profile_language} topic_id={props.topic_id} onShowTab={props.onShowTab} onVideoClick={props.onVideoClick} />},
      {show: (!_.get(props.speaker, ["flags_as_map", "virtual_certified", "is_set"], false) && _.get(props.speaker, ["has_virtual_programs"], false)), tab: "virtual", name: "Virtual", component: <Virtual speaker={props.speaker} profile_language={props.profile_language} topic_id={props.topic_id} onShowTab={props.onShowTab} onVideoClick={props.onVideoClick} />}
    ]
  })

  __handleSelect = (key) => {
    if (_.isFunction(this.props.onShowTab)) {
      return this.props.onShowTab(null, key);
    }
  }

  render() {
    let {
      tabs,
      selected_tab
    } = this.state;

    if (_.isEmpty(tabs)) {
      return null;
    }

    return (
      <div>
          <Tabs
            activeKey={selected_tab}
            onSelect={this.__handleSelect}
            id="profileTabs"
          >
            {_.map(_.filter(tabs, "show"), (item, i) => (
                <Tab key={i} eventKey={_.get(item, ["tab"])} title={__(_.get(item, ["name"]))}>
                  {_.get(item, ["component"])}
                </Tab>
            ))}
          </Tabs>
      </div>
    );
  }
}