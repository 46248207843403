import _ from "lodash";
import React from "react";
import { Modal } from "react-bootstrap";
import { gettext as __ } from "../../../../../utils/Trans";
import { Link } from "react-router-dom";

export default class LoadingStripe extends React.Component {
    render() {
        let { error, onClose } = this.props;

        if (_.includes(error, "You do not have a saved payment method")) {
            return (
                <Modal show={true}>
                    <Modal.Header closeButton onHide={onClose}>
                        <Modal.Title>
                            {__("Cannot Process Payment")}
                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div style={{ minHeight: "200px" }}>
                            <p>
                                You do not have a saved payment method on your
                                account. You will need to add one before you can
                                proceed.
                            </p>
                            <p>
                                You can add a credit card or bank account{" "}
                                <Link to={"/user#payment"}>here</Link>.
                            </p>
                        </div>
                    </Modal.Body>
                </Modal>
            );
        }

        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("Cannot Process Payment")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{ minHeight: "200px" }}>{error}</div>
                </Modal.Body>
            </Modal>
        );
    }
}
