import _ from "lodash";
import phpEncode from "urlencode-for-php";
import Oauth from "./Oauth";
import Balboa from "../../Global/Balboa";
import {sessionService} from "redux-react-session";
import Constants from "../../../utils/Constants";

const client_id = "Lzdb0zuEVfyOvgaGqBXMD9UegS4UYtJpI0PXKMOfSZpVfekGenhBLqI1qECydOoX";
// const secret = "BorA4CuQf-FZgPLv3Obhvs-erSJTWWRyYbePFzlZe21KBj5o9vjkam1zOcgeMr-Q";

export default _.assign({}, Oauth, {

    signin: function(code, state, callback_url) {
        return (dispatch, getState) => {
            let key = ["LinkedIn_Oauth"];
            let params = {
                service: "linkedin",
                code: code,
                callback_url: callback_url
            };
            return Balboa(dispatch, getState)
                .post(key, "/oauth2/exchange", params, {cache_ttl: -1})
                .then((profile) => {
                    let params = {
                        service: "linkedin",
                        state: state,
                        id: _.get(profile, ["data", "profile", "id_for_service"]),
                        access_token: _.get(profile, ["data", "profile", "access_token"]),
                        first_name: _.get(profile, ["data", "profile", "first_name"]),
                        last_name: _.get(profile, ["data", "profile", "last_name"]),
                        email: _.get(profile, ["data", "profile", "email"]),
                        gender: _.get(profile, ["data", "profile", "gender"]),
                        auth_only_user_type: 'buyer'
                    };

                    return Balboa(dispatch, getState)
                        .post(key, "/oauth2/signinoauth", params, {cache_ttl: -1})
                        .then(function(response) {
                            dispatch({
                                type: "BALBOA3_CLEAR_KEY",
                                key: key
                            });
                            return response;
                        })
                        .then(function(response) {
                            sessionService.saveSession(_.get(response, ["data", "token"]));
                            sessionService.saveUser(_.assign({}, _.get(response, ["data", "user"]), {token: _.get(response, ["data", "token"])}));
                            // return response;
                        })
                        .catch(function(err) {
                            dispatch({
                                type: "ERROR_CREATE",
                                source: _.join(key, "_"),
                                error: err
                            });
                            throw err;
                        });
                });
        };
    },

    /**
     * Convert the initiate token into an authorization token
     * @param params
     * @returns {string}
     */
    initiate: function(redirect_url, whitelabel) {
        let params = {
            response_type: "code",
            client_id: client_id,
            redirect_uri: this.getCallbackUrl("linkedin", whitelabel),
            state: this.getOauthState(_.get(whitelabel, ["bid"], Constants.TRUNK_BUREAU_ID), redirect_url),
            scope: "r_basicprofile r_emailaddress"
        };
        return "https://www.linkedin.com/uas/oauth2/authorization?" + phpEncode(params);
    },

    /**
     * Convert the initiate token into an authorization token
     * @param callback_url
     * @param code
     * @returns {Promise}
     */
    exchange: function(code /*, whitelabel */) {
        let params = {
            service: "linkedin",
            code: code
        };
        return (dispatch, getState) => {
            let key = ["LinkedIn_Exchange"];
            return Balboa(dispatch, getState)
                .post(key, "/oauth2/exchange", params, {cache_ttl: -1})
                .then((resp) => {
                    return resp.json();
                })
                .then((resp) => {
                    if (_.has(resp, ["error"])) {
                        throw new Error(_.get(resp, ["error", "message"], "Invalid response from LI.Oauth"));
                    }
                    if (!_.has(resp, ["profile"])) {
                        throw new Error("Invalid token from LI.Oauth");
                    }
                    return _.get(resp, ["profile"]);
                })
                .catch((err) => {
                    console.log(err);
                    throw err;
                });
        };
    },

    /**
     * Shortcut to either add or update a record based on whether the id is > 0
     * @param params
     * @returns {Function}
     */
    profile: function(access_token) {
        return fetch(
            "https://api.linkedin.com/v1/people/~:(id,first-name,email-address,last-name,picture-url,phone-numbers,positions:(id,title,company,is-current))?format=json&oauth2_access_token=" + access_token,
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                }
            })
            .then((resp) => {
                return resp.json();
            })
            .then((json) => {
                return _.assign({}, json, {access_token: access_token});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }
});