import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { NavItem, OverlayTrigger, Popover } from "react-bootstrap";
import { gettext as __ } from "../../../../../utils/Trans";
import SpeakerThumbnail from "../../../../SpeakerThumbnail";
import SpeakerName from "../../../../SpeakerName";

export default class NavShortlist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pulse: false
        };
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (_.size(this.props.shortlist) !== _.size(nextProps.shortlist)) {
            this.setState({
                pulse: true
            }, () => {
                setTimeout(() => {
                    this.setState({
                        pulse: false
                    });
                }, 500);
            });
        }
    }

    render() {
        let {
            shortlist,
            onRemoveFromShortlist,
            onProfile
        } = this.props;

        const shortlist_overlay = <Popover id="my_shortlist_topbar" style={{fontFamily: "Open Sans", fontWeight: "100"}}>
            <div>
                <h5 style={{borderBottom: "1px solid #EEE"}}>{__("My Favorites")}</h5>
                {_.size(shortlist) === 0 &&
                    <div className="help-block">{__("Look for the")}{" "}<i className="fa fa-heart fa-fw"/>{" "}{__("to create a shortlist of your favorite candidates, then come back here to connect with them.")}</div>
                }
                {_.map(shortlist, function (item, i) {
                    return (
                        <div key={i}>
                            {onRemoveFromShortlist && <i onClick={() => onRemoveFromShortlist(item)} className="fa fa-trash fa-fw pull-right" style={{lineHeight: "36px", verticalAlign: "middle", color: "#646464", cursor: "pointer"}}/>}
                            {onProfile && <i onClick={() => onProfile(item)} className="fa fa-search fa-fw pull-right" style={{lineHeight: "36px", verticalAlign: "middle", color: "#646464", cursor: "pointer"}}/>}
                            <SpeakerThumbnail className="center-block" sid={item} inline={true} style={{width: "24px", height: "24px", lineHeight: "36px", verticalAlign: "middle"}} dont_link_to_profile={true} dont_show_name_on_hover={true}/>{" "}
                            <SpeakerName style={{lineHeight: "36px", paddingLeft: "5px"}} sid={item}/>{" "}
                        </div>
                    );
                })}
                <div className="text-center" style={{paddingTop: "10px"}}>
                    {_.size(shortlist) > 0 &&
                    <Link to="/postaprivatejob">
                        <div className="btn btn-success btn-sm">{__("REQUEST FREE QUOTE")}</div>
                    </Link>
                    }
                    {_.size(shortlist) === 0 &&
                    <div className="btn btn-default btn-sm disabled">{__("REQUEST FREE QUOTE")}</div>
                    }
                </div>
            </div>
        </Popover>;

        return (
            <OverlayTrigger placement="bottom" trigger="click" rootClose={true} overlay={shortlist_overlay}>
                <NavItem className={"hidden-xs mp-shortlist-topbar mp-text-header " + (this.state.pulse ? "pulse" : "")}><i className={"fa fa-heart fa-fw " + (_.size(shortlist) > 0 ? "mp-secondary " : " ")}/>{" "}{_.size(shortlist)}</NavItem>
            </OverlayTrigger>
        );
    }
}

NavShortlist.propTypes = {
    shortlist: PropTypes.array.isRequired,
    onRemoveFromShortlist: PropTypes.func,
    onProfile: PropTypes.func
};