import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { gettext as __ } from "../../utils/Trans";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { Password, Username } from "../../components/FormComponents";
import InlineError from "../../components/InlineError";
import User from "../../actions/User/User";
import OauthButtons from "../../components/OauthButtons";
import CustomerService from "../../components/CustomerService";
import Analytics from "../../utils/Analytics";

class LoginForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch, props) {
        return dispatch(User.signin(values))
            .then(() => {
                props.history.push(props.redir || "/event");
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }


    SignupOnclick() {
        Analytics.sendEvent("BuyerSignup", "click", 0, "signin_form");
    }


    render() {
        let {
            handleSubmit,
            submitting,
            error,
            location,
            whitelabel
        } = this.props;

        // let or_img = require("./or.png");

        return (
            <div>
                <Form className="mp-style-form" onSubmit={handleSubmit(this.handleSubmit)}>
                    <Field
                        name="username"
                        type="text"
                        className="form-control"
                        label="Event Organizer Email address"
                        component={Username}
                    />
                    <Field
                        name="password"
                        className="form-control"
                        label="Password"
                        component={Password}
                        help_text={<div className="small"><Link to={_.assign({}, location, {pathname: "/forgot-password"})}>{__("Forgot your password?")}</Link></div>}
                    />
                    <div className="row">
                        <div className="col-sm-4">
                            <button type="submit" className="btn btn-success btn-block btn-lg" disabled={submitting}>
                                {submitting ? <i className="fa fa-spin fa-circle-o-notch" /> : <i/>} {__("SIGN IN")}
                            </button>
                        </div>
                        <div className="col-sm-8" style={{lineHeight:"46px"}}>
                            <Link to={_.assign({}, location, {pathname: "/signup"})} onClick={this.SignupOnclick.bind(this)}>{__("Need an account?  Register today")}</Link>
                        </div>
                    </div>
                    {error && <InlineError error={_.get(error, ["message"])} />}
                    {/*{submitSucceeded && <Redirect to={_.get(location, ["state", "redirect"], "/event")} />}*/}
                </Form>
                {_.get(whitelabel, ["boosters", "MP_USE_OAUTH"], false) && <>
                    <OauthButtons {...this.props} />
                </>}
                <div style={{height: "2em"}}/>
                <CustomerService whitelabel={whitelabel}/>
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

let mapStateToProps = function(state, ownProps){

    return {
        redir: _.get(ownProps.location, ["state", "redirect"]),
        initialValues: {
            username: "",
            password: "",
            redir: _.get(ownProps.location, ["state", "redirect"]),
            bureau_id: _.get(ownProps.whitelabel, ["bid"])
        }
    };
};

let mapDispatchToProps = function(){
    return {

    };
};

LoginForm = reduxForm({
    form: "signinpage_login",
    validate: function(values) {
        const errors = {};
        if (!values.username) {
            errors.username = __("Please enter your email address.");
        }
        // if (!isValidEmail(values.email)) {
        //     errors.email = __("Please enter a valid email address.");
        // }
        if (!values.password) {
            errors.password = __("Please enter your password.");
        }
        return errors;
    }
})(LoginForm);

export default connect(mapStateToProps, mapDispatchToProps)(LoginForm);
