import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { gettext as __ } from "../../utils/Trans";
import EventTabs from "./components/EventTabs";
import EventList from "./components/EventList";
import Event from "../../actions/Event/Event";
import getEventOfferStatusFromState from "../../utils/getEventOfferStatusFromState";
import CustomerService from "../../components/CustomerService";
import LoadingIndicator from "../../components/LoadingIndicator";
import moment from "moment";

export class MyEventsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            is_loading: false,
            selected_tab: "pending"
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        // if (!this.props.events) {
            this.setState({
                is_loading: true
            }, () => {
                return this.props.loadData()
                    .then(() => {
                        this.setState({
                            is_loading: false
                        });
                    });
            });
        // }
    }

    __onSelectTab = (tab) => {
        this.setState({
           selected_tab: tab
        });
    }

    render() {
        let {
            events,
            notifications,
            whitelabel
        } = this.props;

        if (this.state.is_loading) { return (<LoadingIndicator />);}

        let filtered_events = _.filter(events, (event) => {
            if (this.state.selected_tab === "pending") {
                return moment(_.get(event, ["Stagetimes", 0, "starttime"]) * 1000).isSameOrAfter(moment());
            } else if (this.state.selected_tab === "complete") {
                return moment(_.get(event, ["Stagetimes", 0, "starttime"]) * 1000).isBefore(moment());
            }
            return event;
        });

        let instructionText = (!_.isEmpty(events))
            ? <p>{__("These are events in progress for you. Click on an event to")}{" "}
                <strong>{__("communicate")}</strong>{" "}{__("with candidates,")}{" "}
                <strong>{__("hire one")}</strong>{__(", and")}{" "}
                <strong>{__("review")}</strong>{" "}{__("them after the engagement.")}</p>
            :
            <div><p>{__("When you begin working with candidates for your event, those events will be listed here so that you can")}{" "}
                <strong>{__("communicate")}</strong>{" "}{__("with the candidates and")}{" "}
                <strong>{__("hire")}</strong>{" "}{__("one.")}</p><p><strong>{__("  Get started")}</strong>{" "}{__("by using the")}{" "}
                <span>{__("search")}</span>{" "}{__("button in the main menu at top to select some candidates.")}</p></div>;

        let img = require("./education-accountable.jpg");

        return (
            <div>
                <Link to="/postapublicjob">
                    <div className="btn btn-success pull-right">
                        <i className="fa fa-plus"></i>
                        {" "}
                        {__("NEW JOB")}
                    </div>
                </Link>
                <h1 className="page-header" style={{marginBottom: "40px"}}>
                    {__("My Jobs")}
                </h1>
                <div className="row">
                    <div className="col-md-8">
                        <div className="help-block instruction hidden-bureaumode">
                            {instructionText}
                            <p>{__("Want to put out a new")}{" "}
                                <strong>{__("call for speakers")}</strong>{" "}{__("to over 10,000 presenters? Use the")}{" "}
                                <Link to="/postapublicjob" style={{textDecoration: "none"}}><span className="btnName">{__("new job")}</span></Link>{" "}{__("button above.")}</p>
                        </div>
                        <EventTabs onChange={this.__onSelectTab} />
                        {(_.size(filtered_events) > 0) &&
                        <div>
                            <EventList events={filtered_events} notifications={notifications} />
                        </div>}
                        {!(_.size(filtered_events) > 0) &&
                        <div className="text-center">
                            <div className="alert alert-warning">{__("Looks like you don't have any events yet!")}</div>
                        </div>}
                    </div>
                    <div className="col-md-4 hidden-bureaumode">
                        <img src={img} alt="accountable" className="img-responsive img-rounded"/>
                        <h2>{__("Hold me accountable!")}</h2>
                        <br />
                        <h4 className="help-block hidden-bureaumode">
                            {__("Hiring your presenter through")}{" "}{_.get(whitelabel, ["name"], "eSpeakers Marketplace")}{" "}{__("gives you a powerful free tool to reduce your risk.")}
                        </h4>
                        <br />
                        <div className="help-block hidden-bureaumode">
                            <p>
                                {_.get(whitelabel, ["name"], "eSpeakers Marketplace")}{" "}{__("gives you an advantage you can't get anywhere else: the power of a public review of your presenter after the engagement. This potent tool holds your presenter accountable to do a fantastic job for you.")}
                            </p>
                            <p>
                                {__("The presenters love doing business here, too! The online contracting and payment system saves them time and hassle and lets them do more of what they love to do: get in front of an audience.")}
                            </p>
                        </div>
                        <CustomerService whitelabel={whitelabel} />
                    </div>
                </div>
            </div>
        );
    }
}


////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state) {

    // Page Properties
    let events = _.get(state, ["Events", "events"]);
    let notifications = _.get(state, ["Messages", "notifications"]);

    //add in each event's offer_status
    if (!_.isEmpty(events)) {
        events = _.map(events, (event) => {
            return _.assign(event, {
                "offer_status": getEventOfferStatusFromState(state, _.get(event, ["eid"], 0))
            });
        });

        events = _.sortBy(events, [function (o) {
            return _.get(o, ["Stagetimes", 0, "starttime"]);
        }]);
    }

    return {
        notifications: notifications,
        events: events
    };
};

var mapDispatchToProps = function (dispatch, ownProps) {
    return {
        loadData: function() {
            return dispatch(Event.find({
                bureau_id: _.get(ownProps.whitelabel, ["bid"])
            }));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyEventsPage);