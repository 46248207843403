import _ from "lodash";

export default function(state = {}, action){
    switch(action.type){
        case "USER_PAGE_PASSWORD_UPDATED":
            return _.assign({}, state, action.data);
        default:
            return state;
    }
}
