import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
// import { Link } from "react-router-dom";
import queryString from "query-string";
import { Field, Form, reduxForm } from "redux-form";
import { gettext as __ } from "../../../utils/Trans";
import formatNumber from "../../../utils/formatNumber";
import { CheckboxDropdown, DateRangePickerField, SearchLocation, SearchSort, SliderDropdown, Toggle, TopicSearch, Selectize } from "../../../components/FormComponents";
import moment from "moment";
import { Button, Popover } from "react-bootstrap";
import Search from "../../../actions/Search/Search";
import TopicModal from "./TopicModal";
import Constants from "../../../utils/Constants";
// import Tour from 'reactour';
import new_icon from "./new.png";

class SearchForm extends React.Component {

  static propTypes = {
    submit: PropTypes.func.isRequired,
    whitelabel: PropTypes.object.isRequired,
    qs: PropTypes.object,
    num_results: PropTypes.number.isRequired,
    languages: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    topics: PropTypes.array,
    awards: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    local_search: PropTypes.object,
    is_location_search: PropTypes.bool
  }

  constructor(props) {
    super(props);
    this.state = {
      show_topic_modal: false,
      show_tour: false,
      show_virtual_popover: true,
      hide_total: true
    };
  }

  __closeTour = () => (
      this.setState({
        show_tour: !this.state.show_tour
      })
  )

  __closeVirtualPopover = () => (
      this.setState({
        show_virtual_popover: false
      })
  )

  componentDidMount() {
    if (!_.get(this.props.whitelabel, ["flags", "MP_HIDE_DEFAULT_RESULTS"], false)) {
      this.props.submit();
    }
  }


  componentDidUpdate(prevProps) {
    if (JSON.stringify(this.props.qs) !== JSON.stringify(prevProps.qs)) {
      // Parse and format the location
      let new_location = _.get(this.props.qs, ["location"], {});
      let old_location = _.get(prevProps.qs, ["location"], {});

      try {
        if (_.isString(old_location)) {
          // Try parsing the q as a json object
          old_location = JSON.parse(old_location);
        }
      } catch (e) { /* Nothing to do here */
      }
      try {
        if (_.isString(new_location)) {
          // Try parsing the q as a json object
          new_location = JSON.parse(new_location);
        }
      } catch (e) { /* Nothing to do here */
      }

      // Parse and format the query string
      let new_q = _.get(this.props.qs, ["q"], []);
      let old_q = _.get(prevProps.qs, ["q"], []);

      try {
        if (_.isString(old_q)) {
          // Try parsing the q as a json object
          old_q = JSON.parse(old_q);
        }
      } catch (e) { /* Nothing to do here */
      }
      try {
        if (_.isString(new_q)) {
          // Try parsing the q as a json object
          new_q = JSON.parse(new_q);
        }
      } catch (e) { /* Nothing to do here */
      }

      if (!_.isUndefined(_.get(this.props.qs, ["items_per_page"])) && _.get(this.props.qs, ["items_per_page"]) !== _.get(prevProps.qs, ["items_per_page"])) {
        this.props.change("items_per_page", _.toInteger(_.get(this.props.qs, ["items_per_page"], 12)));
      }
      if (!_.isUndefined(_.get(this.props.qs, ["distance"])) && _.get(this.props.qs, ["distance"]) !== _.get(prevProps.qs, ["distance"])) {
        this.props.change("distance", _.toInteger(_.get(this.props.qs, ["distance"], 100)));
      }
      if (!_.isUndefined(_.get(this.props.qs, ["language"])) && _.get(this.props.qs, ["language"]) !== _.get(prevProps.qs, ["language"])) {
        this.props.change("language", _.get(this.props.qs, ["language"]));
      }
      if (!_.isUndefined(_.get(this.props.qs, ["presenter_type"])) && _.get(this.props.qs, ["presenter_type"]) !== _.get(prevProps.qs, ["presenter_type"])) {
        this.props.change("presenter_type", _.get(this.props.qs, ["presenter_type"], ""));
      }
      if (!_.isUndefined(_.get(this.props.qs, ["require"])) && _.get(this.props.qs, ["require"]) !== _.get(prevProps.qs, ["require"])) {
        this.props.change("require", _.get(this.props.qs, ["require"]));
      }
      if (!_.isUndefined(_.get(this.props.qs, ["video"])) && _.get(this.props.qs, ["video"]) !== _.get(prevProps.qs, ["video"])) {
        this.props.change("video", _.get(this.props.qs, ["video"], "") === "true");
      }
      if (!_.isUndefined(_.get(this.props.qs, ["virtual"])) && _.get(this.props.qs, ["virtual"]) !== _.get(prevProps.qs, ["virtual"])) {
        this.props.change("virtual", _.get(this.props.qs, ["virtual"], "") === "true");
      }
      if (!_.isUndefined(_.get(this.props.qs, ["fee"])) && _.get(this.props.qs, ["fee"]) !== _.get(prevProps.qs, ["fee"])) {
        this.props.change("fee", _.get(this.props.qs, ["fee"], "") === "true");
      }
      if (!_.isUndefined(_.get(this.props.qs, ["review"])) && _.get(this.props.qs, ["review"]) !== _.get(prevProps.qs, ["review"])) {
        this.props.change("review", _.get(this.props.qs, ["review"], "") === "true");
      }
      if (!_.isUndefined(_.get(this.props.qs, ["awards"])) && _.get(this.props.qs, ["awards"]) !== _.get(prevProps.qs, ["awards"])) {
        this.props.change("awards", _.get(this.props.qs, ["awards"]));
      }
      if (!_.isUndefined(_.get(this.props.qs, ["budget"])) && _.get(this.props.qs, ["budget"]) !== _.get(prevProps.qs, ["budget"])) {
        this.props.change("budget", _.get(this.props.qs, ["budget"], _.get(this.props.whitelabel, ["mp_keyvalues", "SEARCH_BUDGET"], "1,9")));
      }
      if (JSON.stringify(old_location) !== JSON.stringify(new_location)) {
        this.props.change("location", new_location);
        if (!_.isNull(_.get(new_location, ["lat"], null)) && !_.isNull(_.get(new_location, ["lng"], null))) {
          // only change sort filter if we have a lat/lng
          this.props.change("sort", "distance");
        }
      }
      if (!_.isUndefined(_.get(this.props.qs, ["available_on"])) && _.get(this.props.qs, ["available_on"]) !== _.get(prevProps.qs, ["available_on"])) {
        this.props.change("available_on", _.get(this.props.qs, ["available_on"]));
      }
      if (JSON.stringify(old_q) !== JSON.stringify(new_q)) {
        this.props.change("q", new_q);
      }
      if (!_.isUndefined(_.get(this.props.qs, ["sort"])) && _.get(this.props.qs, ["sort"]) !== _.get(prevProps.qs, ["sort"])) {
        this.props.change("sort", _.get(this.props.qs, ["sort"]));
      }

      if (false) {
        console.log("New Q: ", _.isEmpty(new_q));
        console.log("New Location: ", _.isEmpty(new_location));
        console.log("Available On: ", _.isEmpty(_.get(this.props.qs, ["available_on"], "")));
        console.log("Require: ", _.isEmpty(_.get(this.props.qs, ["require"], "")));
        console.log("Awards: ", _.isEmpty(_.get(this.props.qs, ["awards"], "")));
        console.log("Presenter Type: ", _.isEmpty(_.get(this.props.qs, ["presenter_type"], "")));
        console.log("Language: ", _.isEmpty(_.get(this.props.qs, ["language"], "")));
        console.log("Video: ", (_.get(this.props.qs, ["video"], "false") === "false"));
        console.log("Virtual: ", (_.get(this.props.qs, ["virtual"], "false") === "false"));
        console.log("Review: ", (_.get(this.props.qs, ["review"], "false") === "false"));
        console.log("Fee: ", (_.get(this.props.qs, ["fee"], "false") === "false"));
        console.log("Budget: ", (_.get(this.props.qs, ["budget"], _.get(this.props.whitelabel, ["mp_keyvalues", "SEARCH_BUDGET"], "0,10")) === _.get(this.props.whitelabel, ["mp_keyvalues", "SEARCH_BUDGET"], "0,10")));
        console.log("Budget Input: ", _.get(this.props.qs, ["budget"], ""));
        console.log("Budget Whitelabel: ", _.get(this.props.whitelabel, ["mp_keyvalues", "SEARCH_BUDGET"], "0,10"));
        console.log("Budget Whitelabel: ", this.props.whitelabel);
      }

      // Hide the number of search results if we are submitting a default form per pivotal task #172207236
      if (_.isEmpty(new_q) &&
          _.isEmpty(new_location) &&
          _.isEmpty(_.get(this.props.qs, ["available_on"], "")) &&
          _.isEmpty(_.get(this.props.qs, ["require"], "")) &&
          _.isEmpty(_.get(this.props.qs, ["awards"], "")) &&
          _.isEmpty(_.get(this.props.qs, ["presenter_type"], "")) &&
          _.isEmpty(_.get(this.props.qs, ["language"], "")) &&
          (_.get(this.props.qs, ["video"], "false") === "false") &&
          (_.get(this.props.qs, ["virtual"], "false") === "false") &&
          (_.get(this.props.qs, ["review"], "false") === "false") &&
          (_.get(this.props.qs, ["fee"], "false") === "false") &&
          (_.get(this.props.whitelabel, ["mp_keyvalues", "SEARCH_BUDGET"], "0,10") === null || (_.get(this.props.qs, ["budget"], _.get(this.props.whitelabel, ["mp_keyvalues", "SEARCH_BUDGET"], "0,10")) === _.get(this.props.whitelabel, ["mp_keyvalues", "SEARCH_BUDGET"], "0,10")))
      ) {
        this.setState({
          hide_total: true
        });
      } else {
        this.setState({
          hide_total: false
        });
      }
      if (!_.isUndefined(_.get(this.props.qs, ["auto"])) && (_.get(this.props.qs, ["auto"], false) !== _.get(prevProps.qs, ["auto"], false)) && (_.get(this.props.qs, ["auto"], false) === "true")) {
        this.props.submit();
      }
    }
  }

  __toggleTopicModal = () => {
    this.setState({
      show_virtual_popover: false,
      show_topic_modal: !this.state.show_topic_modal
    });
  }

  handleSubmit(values, dispatch, props) {
    let q = _.get(values, ["q"]);
    // If we are searching for a speaker (and only one), then go to the profile page
    if (_.size(q) === 1 && _.get(_.head(q), ["type"]) === "speaker") {
      let sid = _.replace(_.get(_.head(q), ["value"]), "sid_", "");
      props.history.push("/profile/" + sid);

    } else {
      let params = _.assign({}, values);
      params.q = JSON.stringify(params.q);
      if (params.location !== '') {
        params.location = JSON.stringify(params.location);
      }
      if (_.isArray(params.budget)) {
        params.budget = _.join(params.budget, ",");
      }
      props.history.push(_.get(props.match, ["url"]) + "?" + queryString.stringify(params));
      return dispatch(Search.search(params));
    }
  }

  render() {
    let {
      whitelabel,
      handleSubmit,
      submitting,
      submitSucceeded,
      num_results,
      languages,
      topics,
      awards,
      local_search,
      is_location_search,
      qs
    } = this.props;

    let {
      show_virtual_popover,
      hide_total
      // show_tour
    } = this.state;

    /* Filter options can be enabled/disabled by a whitelabel, so push the appropriate ones to the filter_options */
    let filter_options = [];
    if (!_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_VIDEOS"], false)) {
      filter_options.push({label: __("Videos"), value: "video", className: "col-sm-12"});
    }
    if (!_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_VIRTUAL"], false)) {
      filter_options.push({label: __("Certified Virtual Presenter"), value: "virtual", className: "col-sm-12"});
    }
    // if (!_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_VIRTUAL_HOST"], false)) {
    //   filter_options.push({label: __("Certified Virtual Host"), value: "virtual_host", className: "col-sm-12"});
    // }
    if (!_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_VIRTUAL_HOST"], false)) {
      filter_options.push({label: __("Virtual Master Presenter"), value: "virtual_master", className: "col-sm-12"});
    }
    if (!_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_REVIEWS"], false)) {
      filter_options.push({label: __("Customer Reviews"), value: "reviews", className: "col-sm-12"});
    }
    if (!_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_FILTER_FEE"], false)) {
      filter_options.push({label: __("Listed Fee"), value: "listed_fee", className: "col-sm-12"});
    }

    /* Since we can show 1,2,3 or 4 toggles, we calculate if we want col-sm-3, col-sm-4, col-sm-6, or col-sm-12 */
    let toggle_cols = 4 - ((_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_VIDEOS"], false) ? 1 : 0) +
        (_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_REVIEWS"], false) ? 1 : 0) +
        (_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_FILTER_FEE"], false) ? 1 : 0) +
        (_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_VIRTUAL"], false) ? 1 : 0)
    );

    /* Since we can show 1,2,3 or 4 filters, we calculate if we want col-sm-3, col-sm-4, col-sm-6, or col-sm-12 */
    let filter_cols = (4 - (((_.get(whitelabel, ["flags", "MP_HIDE_FEES"], false) || _.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_BUDGET"], false)) ? 1 : 0) +
        (_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_AWARDS"], _.isEmpty(awards)) ? 1 : 0) +
        (_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_FILTER"], _.isEmpty(filter_options)) ? 1 : 0) +
        (_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_LANGUAGE"], false) ? 1 : 0)));

    /* Sort options can be enabled/disabled by a whitelabel, so push the appropriate ones to the sort_options */
    let sort_options = [];
    sort_options.push({label: __("Name"), value: "speakername", icon: "fa-sort-alpha-asc"});
    sort_options.push({label: __("Best Match"), value: "sortscore", icon: "fa-sort-amount-desc"});
    if (!_.get(whitelabel, ["flags", "MP_HIDE_FEES"], false)) {
      sort_options.push({label: __("Fee (Low to High)"), value: "fee_low", icon: "fa-sort-numeric-asc"});
      sort_options.push({label: __("Fee (High to Low)"), value: "fee_high", icon: "fa-sort-numeric-desc"});
    }
    sort_options.push({label: __("Distance (Near to Far)"), value: "distance", icon: "fa-map-marker"});
    sort_options.push({label: __("Trending (High to Low)"), value: "trending", icon: "fa-sort-amount-asc"});

    /* if we are only showing location OR availability, move it to the top with the topic search */
    let show_location_availability_on_top = (_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_LOCATION"], false) ? !_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_AVAILABILITY"], false) : _.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_AVAILABILITY"], false));

    let form_top_classes = [
      "search-form-top"
    ];

    if (submitting) { form_top_classes.push("busy"); }

    return (
        <div className="container search-form">
          {submitting && <div className="busypane hidden-sm hidden-xs"><span className="mp-secondary pulse">searching..<span className="blink_me">.</span></span></div>}

          <Form onSubmit={handleSubmit(this.handleSubmit)} onFocus={this.__closeVirtualPopover}>

            <div className={form_top_classes.join(" ")}>

              <div className="row search-form-secondary">
                <div className={"col-xs-12 search-form-topic second-step " + (show_location_availability_on_top ? "col-sm-8 search-form-topic-shared" : "col-sm-12")}>
                  <label className="mp-primary text-left">{__("Name, topic, keyword")}</label>
                  <Field
                      component={TopicSearch}
                      name="q"
                      whitelabel={whitelabel}
                      placeholder={__("Topic, Name, Industry, or Expertise...")}
                      normalize={(v) => {
                        if (_.isArray(v)) {
                          return v;
                        } else {
                          return [v];
                        }
                      }}
                  />
                  <div style={{cursor: "pointer"}} className="small" onClick={this.__toggleTopicModal}>
                    {__("Can't find what you want?")}{" "}
                    <span style={{textDecoration: "underline"}}>{__("Browse the complete topic list")}</span>
                  </div>
                </div>
                {show_location_availability_on_top &&
                <div>
                  {!_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_AVAILABILITY"], false) &&
                  <div className={"col-xs-12 col-sm-4 search-form-available search-form-no-location third-step"}>
                    <label className="mp-primary text-left">{__("Available on event date")}</label>
                    <Field
                        component={DateRangePickerField}
                        style={{width: "100%"}}
                        name="available_on"
                        placeholder={__("Search Availability")}
                        startDate={moment()}
                        endDate={moment()}
                        singleDatePicker={true}
                        autoApply={true}
                        autoUpdateInput={true}
                        showCustomRangeLabel={false}
                        opens="center"
                        ranges={false}
                        type="text"
                    />
                  </div>
                  }
                  {!_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_LOCATION"], false) &&
                  <div className={"col-xs-12 col-sm-4 search-form-location search-form-no-available fourth-step"}>
                    <label className="mp-primary text-left">{__("Near event location")}</label>
                    <Field
                        disabled={!_.isUndefined(local_search)}
                        component={SearchLocation}
                        name="location"
                        placeholder={__("Near Me")}
                        withRef={true}
                        format={(value) => {
                          let val = _.get(value, ["location"], value);
                          if (val === "undefined") {
                            val = "";
                          }
                          return val;
                        }}
                    />
                    {!_.isUndefined(local_search) &&
                    <div className="small"><a
                        href={"/marketplace/search?" + queryString.stringify(qs)}>{__("customize your search location")}</a>
                    </div>
                    }
                  </div>
                  }
                </div>
                }
              </div>
              {!_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_LOCATION"], false) && !_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_AVAILABILITY"], false) && !show_location_availability_on_top &&
              <div className="row search-form-secondary">
                <div className={"col-xs-12 col-sm-3 col-sm-offset-1 search-form-type first-step"}>
                  <label className="mp-primary text-left">{__("Type of Event")}{" "}<img height={16} src={new_icon} alt={"new feature"} /></label>
                  <Field
                      component={Selectize}
                      style={{width: "100%"}}
                      name="presenter_type"
                      placeholder={__("On-Site/Virtual")}
                      onFocus={this.__closeVirtualPopover}
                      options={[
                        {label: <span><i className={"fa fa-user"}/>{" "}On-Site</span>, value: "onsite"},
                        {label: <span><i className={"fa fa-desktop"}/>{" "}Virtual</span>, value: "virtual"},
                      ]}
                  />
                </div>
                <div className="col-xs-12 col-sm-3 search-form-available third-step">
                  <label className="mp-primary text-left">{__("Available on event date")}</label>
                  <Field
                      component={DateRangePickerField}
                      style={{width: "100%"}}
                      name="available_on"
                      placeholder={__("Search Availability")}
                      startDate={moment()}
                      endDate={moment()}
                      singleDatePicker={true}
                      autoApply={true}
                      autoUpdateInput={true}
                      showCustomRangeLabel={false}
                      opens="center"
                      ranges={false}
                      type="text"
                  />
                </div>
                <div className="col-xs-12 col-sm-4 search-form-location fourth-step">
                  <label className="mp-primary text-left">{__("Near event location")}</label>
                  <Field
                      disabled={!_.isUndefined(local_search)}
                      component={SearchLocation}
                      name="location"
                      placeholder={__("Near Me")}
                      withRef={true}
                      format={(value) => {
                        let val = _.get(value, ["location"], value);
                        if (val === "undefined") {
                          val = "";
                        }
                        return val;
                      }}
                  />
                  {!_.isUndefined(local_search) &&
                  <div className="small"><a
                      href={"/marketplace/search?" + queryString.stringify(qs)}>{__("customize your search location")}</a>
                  </div>
                  }
                </div>
                {show_virtual_popover && <Popover
                    onClick={this.__closeVirtualPopover}
                    className={"virtual-popover"}
                    placement="bottom"
                    positionTop={232}
                    positionLeft={200}
                    id={"virtual_popover"}
                >
                  Want to see speakers who present virtually?
                </Popover>}
              </div>
              }
              {_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_ALTERNATE_FILTERS"], false) &&
              <div>
                <div className="row search-form-tertiary">
                  {(!_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_VIDEOS"], false)) &&
                  <div
                      className={"col-xs-" + (12 / toggle_cols)}>
                    <Field
                        component={Toggle}
                        name="video"
                        label={__("Hide speakers with no videos")}
                        disabled={submitting}
                    />
                  </div>
                  }
                  {(!_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_FILTER_FEE"], false)) &&
                  <div
                      className={"col-xs-" + (12 / toggle_cols)}>
                    <Field
                        component={Toggle}
                        name="fee"
                        label={__("Hide speakers with no fee")}
                        disabled={submitting}
                    />
                  </div>
                  }
                  {(!_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_VIRTUAL"], false)) &&
                  <div
                      className={"col-xs-" + (12 / toggle_cols)}>
                    <Field
                        component={Toggle}
                        name="virtual"
                        label={__("Hide speakers without virtual programs")}
                        disabled={submitting}
                    />
                  </div>
                  }
                  {(!_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_REVIEWS"], false)) &&
                  <div
                      className={"col-xs-" + (12 / toggle_cols)}>
                    <Field
                        component={Toggle}
                        name="review"
                        label={__("Hide speakers with no reviews")}
                        disabled={submitting}
                    />
                  </div>
                  }
                </div>
              </div>
              }
              <div className={"search-form-submit row " + (_.get(this.props.whitelabel, ["flags", "MP_HIDE_DEFAULT_RESULTS"], false) ? "" : "visible-xs")}>
                <div className="text-center col-sm-12">
                  <Button type="submit" className="btn mp-primary btn-lg" disabled={submitting}>{__("Search Speakers")}</Button>
                </div>
              </div>
              <div className="row">
                <div className={"col-sm-" + (filter_cols * 2 + 2) + " col-sm-offset-" + (5 - filter_cols)}>
                  <div className="row">
                    {!(_.get(whitelabel, ["flags", "MP_HIDE_FEES"], false) || _.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_BUDGET"], false)) &&
                    <div className={"col-xs-6 col-sm-" + (12 / filter_cols)}>
                      <Field
                          className="search-form-dropdown"
                          component={SliderDropdown}
                          name="budget"
                          placeholder={__("- Budget -")}
                          any_placeholder={__("- Any Budget -")}
                          min={0}
                          max={10}
                          step={10}
                          dots={true}
                          normalize={(value) => {
                            if (_.isArray(value)) {
                              return _.join(value, ",");
                            }
                            return value;
                          }}
                          marks={{
                            0: __("Unlisted"),
                            1: __("Free"),
                            2: "$1,000",
                            3: "$2,500",
                            4: "$5,000",
                            5: "$7,500",
                            6: "$10,000",
                            7: "$15,000",
                            8: "$20,000",
                            9: "$30,000",
                            10: "$30,000+"
                          }}
                      />
                    </div>
                    }
                    {!_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_AWARDS"], false) && !_.isEmpty(awards) &&
                    <div
                        className={"col-xs-6 col-sm-" + (12 / filter_cols)}>
                      {<Field
                          className="search-form-dropdown"
                          component={CheckboxDropdown}
                          name="awards"
                          placeholder={__("- Award -")}
                          any_placeholder={__("— Any Award —")}
                          options={_.map(_.sortBy(awards, 'tag'), function (award) {
                            return {
                              label: <span>{_.get(award, ["tag"])}<br /><div className={"small"} style={{marginLeft: "17px", fontWeight:"normal"}}>{_.get(award, ["description"])}</div></span>,
                              value: _.get(award, ["search_code"]),
                              className: "col-xs-6 col-sm-12"
                            };
                          })}
                      />}
                    </div>
                    }
                    {!_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_FILTER"], false) && !_.isEmpty(filter_options) &&
                    <div
                        className={"col-xs-6 col-sm-" + (12 / filter_cols)}>
                      <Field
                          className="search-form-dropdown"
                          component={CheckboxDropdown}
                          name="require"
                          placeholder={__("- Filter -")}
                          any_placeholder={__("— Any Filter —")}
                          options={filter_options}
                      />
                    </div>
                    }
                    {!_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_HIDE_LANGUAGE"], false) &&
                    <div
                        className={"col-xs-6 col-sm-" + (12 / filter_cols)}>
                      {languages && <Field
                          className="search-form-dropdown"
                          component={CheckboxDropdown}
                          name="language"
                          placeholder={__("- Language -")}
                          any_placeholder={__("Any Language")}
                          options={_.map(languages, function (language, lang) {
                            return {
                              label: __(language),
                              value: lang,
                              className: "col-xs-6 col-sm-12"
                            };
                          })}
                      />}
                    </div>
                    }
                  </div>
                </div>
              </div>
            </div>

            {((submitSucceeded && _.get(whitelabel, ["flags", "MP_HIDE_DEFAULT_RESULTS"], false)) || (!_.get(whitelabel, ["flags", "MP_HIDE_DEFAULT_RESULTS"], false))) &&
            <div className="search-form-bottom row">
              <div className="col-xs-6 text-left">
                {submitSucceeded && !submitting && <div className="num-results-text">
                  {!hide_total &&
                  <React.Fragment>
                    <div className="visible-xs visible-sm">
                          <span className={"mp-secondary pulse"}>{formatNumber(
                              num_results)}</span>{" "}{__("matches")}
                    </div>
                    <div className="hidden-xs hidden-sm">
                      <span className={"mp-secondary pulse"}>{formatNumber(
                          num_results)}</span>{" "}{__("matching speakers")}
                      {" "}
                      {is_location_search &&
                      <span>
                            {__("within")}
                        {" "}
                        <Field
                            className="distance-filter"
                            component={SearchSort}
                            name="distance"
                            options={[
                              {label: "50 miles", value: "50"},
                              {label: "100 miles", value: "100"},
                              {label: "200 miles", value: "200"},
                              {label: "300 miles", value: "300"},
                              {label: "400 miles", value: "400"}
                            ]}
                        />
                          </span>
                      }
                    </div>
                  </React.Fragment>
                  }
                </div>
                }
                {submitting && <div className="searching-text">
                  <span><i className="fa fa-spin fa-circle-o-notch mp-secondary"/>{" "}{__("Searching")}...</span>
                </div>
                }
              </div>

              <div className="col-xs-6 text-right">
                {submitSucceeded &&
                <div>
                  <Field
                      icon_className="mp-primary"
                      className="sort-filter"
                      component={SearchSort}
                      name="sort"
                      options={sort_options}
                  />
                </div>
                }
              </div>
            </div>
            }
          </Form>
          {this.state.show_topic_modal && <TopicModal topics={topics} qs={qs} onHide={this.__toggleTopicModal}/>}
          {/*<Tour*/}
          {/*    steps={steps}*/}
          {/*    isOpen={show_tour}*/}
          {/*    onRequestClose={this.__closeTour}*/}
          {/*/>*/}
        </div>
    );
  }
}

// /* These steps are used for the Tour component */
// const steps = [
//     {
//         selector: '.second-step',
//         content: 'Enter a speaker\'s name, select a topic, or search through our list of thousands of presenters.',
//     },
//     {
//         selector: '.first-step',
//         content: 'You can find speakers that can present virtually over video conference or prefer on-site presentations.',
//     },
//     {
//         selector: '.third-step',
//         content: 'Enter your meeting date to include speaker\'s availability in your search.',
//     },
//     {
//         selector: '.fourth-step',
//         content: 'Let us know where your event will be held and we can help you save money on travel costs by finding speakers that live close or will be traveling nearby',
//     }
// ];

////////////////////////////////////////////////////////////////////////////////
//Connect it up

SearchForm = reduxForm({
  form: "search_form",
  onChange: _.debounce((values, dispatch, props) => {
    if (!props.submitSucceeded && _.get(props.whitelabel, ["flags", "MP_HIDE_DEFAULT_RESULTS"], false) && !props.auto_submit) {
      return false;
    }
    let params = _.assign({}, values);
    params.q = JSON.stringify(params.q);
    if (_.get(params.location, ["lat"]) === null &&
        _.get(params.location, ["lng"]) === null &&
        _.get(params.location, ["state"]) === null &&
        _.get(params.location, ["country"]) === null &&
        _.get(params.location, ["location"]) === null
    ) {
      params.location = '';
    }
    params.location = JSON.stringify(params.location);
    if (_.isArray(params.budget)) {
      params.budget = _.join(params.budget, ",");
    }
    params.page = 0;
    props.history.push(_.get(props.match, ["url"]) + "?" + queryString.stringify(params));
    props.submit();
  }, 125)
})(SearchForm);

let mapStateToProps = function (state, ownProps) {

  let q = _.get(ownProps.qs, ["q"], []);
  try {
    if (_.isString(q)) {
      q = JSON.parse(q);
    }
  } catch (e) {/* Nothing to do here */
  }

  if (_.has(ownProps.qs, ["searchtags"])) {
    let searchtags = _.get(ownProps.qs, ["searchtags"], "");
    try {
      if (_.isString(searchtags)) {
        q = {label: searchtags, value: "free_" + searchtags, type: "freeform"};
      } else if (_.isArray(searchtags)) {
        q = _.map(searchtags, function (tag) {
          return {label: tag, value: "free_" + tag, type: "freeform"};
        });
      }
    } catch (e) {/* Nothing to do here */
    }
  }

  let location = _.get(ownProps.qs, ["location"], []);
  try {
    if (_.isString(location)) {
      location = JSON.parse(location);
    }
    if (_.get(location, ["location"], "") === "") {
      location = "";
    }
  } catch (e) {/* Nothing to do here */
  }

  let budget = _.get(ownProps.qs, ["budget"], _.get(ownProps.whitelabel, ["mp_keyvalues", "SEARCH_BUDGET"], "0,10"));
  try {
    if (_.isString(budget)) {
      budget = _.map(_.split(budget, ","), function (val) {
        return _.toNumber(val);
      });
    }
    if (_.isArray(budget) && _.size(budget) === 1) {
      let min = _.head(budget);
      budget = [_.toNumber(min), _.toNumber(min)];
    }
    if (!_.isArray(budget) || _.size(budget) !== 2) {
      budget = [1, 10];
    }
  } catch (e) {/* Nothing to do here */
  }

  let sort = "sortscore";
  if (_.has(ownProps.qs, ["sort"]) && _.get(ownProps.qs, ["sort"], "") !== "") {
    sort = _.get(ownProps.qs, ["sort"], "sortscore");
  } else if (ownProps.local_search) {
    sort = "distance";
  } else if (_.get(ownProps.whitelabel, ["flags", "MP_SORT_SPEAKERS_ALPHA_ALWAYS"], false)) {
    sort = "speakername";
  }

  let norecord = _.get(ownProps, 'norecord', _.includes(_.get(ownProps, 'location.search'), Constants.NO_RECORD_HIT_STRING));

  return {
    is_location_search: (!_.isNull(_.get(location, ["lat"], null)) && !_.isNull(_.get(location, ["lng"], null))),
    initialValues: {
      items_per_page: _.get(ownProps.qs, ["items_per_page"], _.get(ownProps.whitelabel, ["mp_keyvalues", "MP_SEARCH_ITEMS_PER_PAGE"], 12)),
      page: 0,
      bureau_id: _.get(ownProps.whitelabel, ["bid"]),
      language: _.get(ownProps.qs, ["language"], _.get(ownProps.whitelabel, ["mp_keyvalues", "SEARCH_LANGUAGE"], "")),
      require: _.get(ownProps.qs, ["require"], _.get(ownProps.whitelabel, ["mp_keyvalues", "SEARCH_FILTER"], "")),
      presenter_type: _.get(ownProps.qs, ["presenter_type"], ""),
      video: _.get(ownProps.qs, ["video"], "") === "true",
      virtual: _.get(ownProps.qs, ["virtual"], "") === "true",
      review: _.get(ownProps.qs, ["review"], "") === "true",
      fee: _.get(ownProps.qs, ["fee"], "") === "true",
      awards: _.get(ownProps.qs, ["award"], _.get(ownProps.whitelabel, ["mp_keyvalues", "SEARCH_AWARD"], "")),
      distance: _.get(ownProps.qs, ["distance"], _.get(ownProps.whitelabel, ["mp_keyvalues", "SEARCH_DISTANCE"], "100")),
      available_on: _.get(ownProps.qs, ["available_on"], ""),
      nt: _.get(ownProps.qs, ["nt"], 0),
      budget: budget,
      location: location,
      q: q,
      sort: sort,
      norecord: norecord
    }
  };
};

let mapDispatchToProps = function (/* dispatch, ownProps */) {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchForm));