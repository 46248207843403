import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { gettext as __ } from "../../../../utils/Trans";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { Message, Reason } from "../../../../components/FormComponents";
import saveRecommendation from "../../../../actions/Speaker/saveRecommendation";

class RecommendationForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch) {
        _.set(values, ["would_recommend"], (values.would_recommend !== "no"));

        return dispatch(saveRecommendation(values))
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
            speaker,
            handleSubmit,
            submitSucceeded,
            submitting,
            error
        } = this.props;

        let spkr_firstname = _.get(speaker, ["name"]);

        return (
            <div>
                <h4>
                    {__("Tell us how %s did", [spkr_firstname])}
                </h4>
                <Form onSubmit={handleSubmit(this.handleSubmit)}>

                    <Field name="reason" component={Reason} type="radio" value="yes" hide_errors={true} label={__("I would recommend %s", [spkr_firstname])} />

                    <Field name="reason" component={Reason} type="radio" value="no" hide_errors={true} label={__("I would NOT recommend %s", [spkr_firstname])} />

                    <div style={{margin: "0em 0em 1em 1em"}}>
                        <Field
                            name="testimony"
                            type="textarea"
                            className="form-control"
                            placeholder={__("Why did you choose that answer?")}
                            component={Message}
                        />
                    </div>

                    <div className="form-group">
                        <button type="submit" className="btn btn-success btn-block-xs" disabled={submitting}>
                            {submitting ? <i className="fa fa-spin fa-circle-o-notch" /> : <i/>} {__("Submit Feedback")}
                        </button>
                        {submitSucceeded && <span className="text-success"> <i className="fa fa-check" /> {__("Thank you, your review has been received.")}</span>}
                        {error && <span className="text-danger"> <i className="fa fa-times" /> {_.get(error, ["message"])}</span>}
                    </div>
                </Form>
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up


function mapStateToProps(state, ownProps) {
    return {
        initialValues: {
            testimony: "",
            would_recommend: null,
            event_id: _.get(ownProps.event, ["eid"]),
            speaker_id: _.get(ownProps.speaker, ["sid"])
        }
    };
}

function mapDispatchToProps() {
    return {

    };
}

RecommendationForm = reduxForm({
    form: "shortlist_page_recommendation",
    validate: function(values) {
        const errors = {};
        if (!values.would_recommend) {
            errors.would_recommend = __("Please select a rating for this speaker.");
        }
        if (!values.message) {
            errors.message = __("Please enter your recommendation for this speaker.");
        }
        return errors;
    }
})(RecommendationForm);

export default connect(mapStateToProps, mapDispatchToProps)(RecommendationForm);
