import _ from "lodash";
import url from "url";
import Cookies from "js-cookie";
import toShortlistURL from "./toShortlistURL";

let parts = url.parse(toShortlistURL("/"));

export default {
    get: function(){
        try {
            let pending_job = window.localStorage.getItem("pending_job");
            if (!_.isEmpty(pending_job)) {
                return JSON.parse(pending_job);
            }
        } catch (e) {
            // Local storage is not accessible
        }
        try {
            // Return from the cookie
            return Cookies.getJSON("pending_job");
        } catch (e) {
            // cookie is not accessible
            return {};
        }
    },
    add: function(data, use_cookie = false) {
        try {
            // Set the Cookie
            window.localStorage.setItem("pending_job", JSON.stringify(data));
        } catch (e) {
            // Local storage is not accessible, so set the cookie
            Cookies.set("pending_job", data, {
                expires: 5,// days from now
                path: parts.pathname // unique per whitelabel
            });
        }
        if (use_cookie) {
            // Local storage is not accessible, so set the cookie
            Cookies.set("pending_job", data, {
                expires: 5,// days from now
                path: parts.pathname // unique per whitelabel
            });
        }
    },
    remove: function() {
        try {
            // Remove from local storage
            window.localStorage.removeItem("pending_job");
        } catch (e) {
            // Local storage is not accessible
        }
        try {
            // Remove the cookie
            Cookies.remove("pending_job");
        } catch (e) {
            // cookie is not accessible
            return false;
        }
    }
};