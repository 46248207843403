import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router";
import CreateEvent from "../../actions/Event/CreateEvent";
import CreateEventStep2 from "../../actions/Event/CreateEventStep2";
import convertHtmlEventToJSEvent from "../../utils/convertHtmlEventToJSEvent";
import convertJSEventToBalboa3Event from "../../utils/convertJSEventToBalboa3Event";
import CookiePostAJob from "../../utils/CookiePostAJob";
import SendingIndicator from "./components/SendingIndicator";

export class PostAJobPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            is_sending: false,
            is_ready_to_redirect: false,
            is_empty_job: false, // If we are missing the pending job, go back to the post a job page
            eid: 0
        };
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        if (!this.state.is_sending && !this.props.is_loading) {
            this.setState({is_sending: true}, () => {
                this.submitJob();
            });
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (!this.state.is_sending && !nextProps.is_loading) {
            this.setState({is_sending: true}, () => {
                // this.submitJob();
            });
        }
    }

    submitJob = _.throttle(function() {
        let {
            pending_job,
            feeranges
        } = this.props;

        if (_.isEmpty(pending_job)) {
            // If the pending_job is empty, then we need to go back to the post a job page
            this.setState({
                is_empty_job: true
            });
        } else {
            try {
                let event_b = convertJSEventToBalboa3Event(convertHtmlEventToJSEvent(pending_job, feeranges));
                if (_.get(event_b, ["eid"], -1) > 0) {
                    // If we have an eid, then we are posting to an existing job and can bypass event creation
                    return this.props.createEventStep2(event_b)
                        .then((response) => {
                            this.setState({
                                eid: _.get(response, ["eid"]),
                                is_ready_to_redirect: true,
                                is_sending: false
                            });
                            return response;
                        });
                } else {
                    // Normally we will create an event, then send the invites and forward to the shortlist page
                    return this.props.createEvent(event_b)
                        .then((event) => {
                            let new_event = _.assign({}, event_b, {eid: _.get(event, ["eid"])});
                            return this.props.createEventStep2(new_event);
                        })
                        .then((response) => {
                            this.setState({
                                eid: _.get(response, ["eid"]),
                                is_ready_to_redirect: true,
                                is_sending: false
                            });
                            return response;
                        });
                }
            } catch (e) {
                throw e;
            }
        }
    }, 100);

    render() {
        let {
            is_empty_job,
            is_ready_to_redirect,
            eid
        } = this.state;

        return (
            <div>
                <SendingIndicator is_ready_to_redirect={is_ready_to_redirect} />
                {is_empty_job && <Redirect to={"/postapublicjob"} />}
                {is_ready_to_redirect && eid > 0 && <Redirect to={"/shortlist/" + eid} />}
                {is_ready_to_redirect && eid < 0 && <Redirect to={"/event/"} />}
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {

    // Pull out the pending job from the localStorage or Cookie (private browsing)
    let pending_job = CookiePostAJob.get();

    return {
        pending_job: pending_job,
        feeranges: _.get(ownProps.sitelists, ["feeranges"])
    };
};

var mapDispatchToProps = function (dispatch) {
    return {
        createEvent: function (event) {
            return dispatch(CreateEvent(event))
                .then(function(response) {
                    return _.get(response, ["data"]);
                });
        },
        createEventStep2: function (event) {
            return dispatch(CreateEventStep2(event))
                .then(function(response) {
                    return _.get(response, ["data"]);
                });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PostAJobPage);