import _ from "lodash";

export default function(state, dependencies) {
    var ret_val = {
        waiting: false,
        errors: []
    };

    _.each(dependencies, function(key){
        ret_val.waiting = ret_val.waiting || _.get(state, ["balboa3", ...key, "waiting"], false);
        var error = _.get(state, ["balboa3", ...key, "error"], false);
        if (error) {
            ret_val.errors.push(_.get(error,"message"));
        }
        // Check the cache and reload it if needed
        /* @todo check the cache, invalidate it and reload the data */
    });

    return ret_val;
}