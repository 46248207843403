import React from "react";
import PropTypes from "prop-types";

import "./StepNumber.scss";

class StepNumber extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            n,
            children = <hr/>
        } = this.props;

        return (
            <div className="row">
                <div className="StepNumber" id={"step" + n}>
                    <div className="col-xs-1">
                        <div className="StepNumber-n">
                            {n}
                        </div>
                    </div>
                    <div className="col-xs-10">
                        {children}
                    </div>
                </div>
            </div>
        );
    }
}

StepNumber.propTypes = {
    n: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default StepNumber;