import _ from "lodash";
import React from "react";
import { Col, Row } from "react-bootstrap";
import virtual_certified from "../../VirtualCertified/virtual_certified.png";
import moment from "moment";

export default class VirtualCvpCertificate extends React.Component {

  state = {
    speaker: null
  }

  static getDerivedStateFromProps = (props) => ({
    speaker: props.speaker
  })

  render() {
    let {
      speaker
    } = this.state;

    return (
      <React.Fragment>
        <div style={{padding: "40px 0px"}}>
          <div style={{padding: "40px"}}>
            <div className={"text-center thumbnail"} style={{padding: "40px 0px"}}>
              <img src={virtual_certified} alt={"Virtual Certification for " + _.get(speaker, ["speaker_name"])} style={{height: "144px"}} />
              <div style={{padding: "40px"}}>
                <b>{_.get(speaker, ["name"])}</b>{" was certified on "}<b>{moment(_.get(speaker, ["cvp", "timestamp"])).format("MM/DD/YYYY")}</b>{" by "}<b>{_.toUpper(_.get(speaker, ["cvp", "certifier"], ""))}</b>
                <p className={"small"}><a href={"/certified/virtual/" + _.get(speaker, ["sid"])}>Download Certification Report</a></p>
              </div>
              {/*<Row>*/}
              {/*    <Col lg={6} className={"text-center"}>*/}
              {/*        <img src={jheaps_signature} alt={"Joseph Heaps Signature"} style={{height:"64px"}} />*/}
              {/*        <div>Joseph Heaps - Director of Sales</div>*/}
              {/*    </Col>*/}
              {/*    <Col lg={6} className={"text-center"}>*/}
              {/*        <img src={dreed_signature} alt={"David Reed Signature"} style={{height:"64px"}} />*/}
              {/*        <div>Dave Reed - President</div>*/}
              {/*    </Col>*/}
              {/*</Row>*/}
              <hr />
              <p className={"small"}>
                Certified Virtual Presenter is supported by these industry organizations.
              </p>
              <br />
              <Row>
                <Col sm={6} lg={3} className={"text-center"}><img src={"https://streamer.espeakers.com/static/logos/mpi_logo_300.png"} alt={"mpi logo"} style={{height: "32px", filter: "grayscale(1)"}} /></Col>
                <Col sm={6} lg={3} className={"text-center"}><img src={"https://streamer.espeakers.com/static/logos/espeakers_horizontal_200h.png"} alt={"espeakers logo"} style={{height: "24px", filter: "grayscale(1)"}} /></Col>
                <Col sm={6} lg={3} className={"text-center"}><img src={"https://streamer.espeakers.com/static/logos/smart_meetings_logo.jpg"} alt={"smart meetings logo"} style={{height: "24px", filter: "grayscale(1)"}} /></Col>
                <Col sm={6} lg={3} className={"text-center"}><img src={"https://streamer.espeakers.com/static/logos/spin_logo.jpg"} alt={"spin logo"} style={{height: "32px", filter: "grayscale(1)"}} /></Col>
              </Row>
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}