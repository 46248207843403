import _ from "lodash";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { gettext as __ } from "../../../../utils/Trans";

/**
 * DEPRECATED -- do we need this?
 */

export default class JobConfirmModal extends React.Component {

    __onSubmit = () => {
        if (_.isFunction(this.props.onSubmit)) {
            return this.props.onSubmit();
        }
    }

    render() {
        let {
            submitting,
            onHide
        } = this.props;


        return (
            <Modal show={true} onHide={onHide}>
                <Modal.Header>
                    <Modal.Title>{__("Ready to contact speakers")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="help-block">{__("We have everything we need to contact your speakers.")}</div>
                    <div className="text-center">
                        <Button bsStyle="success" bsSize="lg" type="submit" onClick={this.__onSubmit} disabled={submitting}>{submitting ? <i className="fa fa-spin fa-circle-o-notch"/> : <i/>}{" "}{__("Contact Speakers")}{" >"}</Button>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }

}