import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Field, Form, reduxForm } from "redux-form";
import { NavbarSearch } from "../FormComponents";
import { gettext as __ } from "../../utils/Trans";
import toMpURL from "../../utils/toMpURL";

class SearchForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit = (values) => {
        if (_.isUndefined(_.get(values, ["q"]))) {
            window.location = toMpURL("/search?q=", this.props.whitelabel);
        } else {
            window.location = toMpURL("/search?q=" + JSON.stringify([_.get(values, ["q"])]), this.props.whitelabel);
        }
    }

    render() {
        let {
            whitelabel,
            handleSubmit
        } = this.props;

        return (
            <Form onSubmit={handleSubmit(this.handleSubmit)}>
                <Field
                    className="text-muted"
                    component={NavbarSearch}
                    whitelabel={whitelabel}
                    name="q"
                    placeholder={__("Search by topic, expertise or name...")}
                    btnClassName="btn-search"
                />
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

let mapStateToProps = function() {
    return {};
};

let mapDispatchToProps = function() {
    return {};
};

SearchForm = reduxForm({
    form: "navbar_search",
    validate: function() {
        const errors = {};
        return errors;
    }
})(SearchForm);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchForm));