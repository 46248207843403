import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import toMpURL from "../../utils/toMpURL";

class Brand extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let { whitelabel } = this.props;

        //console.log("whitelabel", whitelabel);
        let logo_url = _.get(
            whitelabel,
            ["mp_keyvalues", "LOGO_URL"],
            toMpURL(
                "/whitelabel/" + _.get(whitelabel, ["id"]) + "/img/logo.png"
            )
        );
        logo_url = _.replace(
            logo_url,
            "S3:",
            "https://streamer.espeakers.com/"
        );
        let logo_href = _.isEmpty(_.get(whitelabel, "website"))
            ? toMpURL("/", whitelabel)
            : _.get(whitelabel, "website");

        if (_.get(whitelabel, ["is_espeakers"])) {
            return (
                <span style={{ lineHeight: "50px" }}>
                    <a href={"/marketplace"}>
                        <img
                            src={logo_url}
                            style={{
                                margin: "5px 5px 5px 5px",
                                maxHeight: "40px",
                                lineHeight: "50px"
                            }}
                            className="img-responsive hidden-xs"
                            alt={_.get(whitelabel, ["name"])}
                        />
                        <img
                            src={logo_url}
                            style={{
                                margin: "8px 8px 8px 8px",
                                maxWidth: "50%",
                                verticalAlign: "middle",
                                maxHeight: "40px",
                                lineHeight: "50px"
                            }}
                            className="img-responsive visible-xs"
                            alt={_.get(whitelabel, ["name"])}
                        />
                    </a>
                </span>
            );
        } else {
            return (
                <span style={{ lineHeight: "50px" }}>
                    <a href={logo_href}>
                        <img
                            src={logo_url}
                            style={{
                                margin: "5px 5px 5px 5px",
                                maxHeight: "40px",
                                lineHeight: "50px"
                            }}
                            className="img-responsive hidden-xs"
                            alt={_.get(whitelabel, ["name"])}
                        />
                        <img
                            src={logo_url}
                            style={{
                                margin: "8px 8px 8px 8px",
                                maxWidth: "50%",
                                verticalAlign: "middle",
                                maxHeight: "40px",
                                lineHeight: "50px"
                            }}
                            className="img-responsive visible-xs"
                            alt={_.get(whitelabel, ["name"])}
                        />
                    </a>
                </span>
            );
        }
    }
}

Brand.propTypes = {
    whitelabel: PropTypes.object
};

export default Brand;
