import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import formatCurrency from "../../../../utils/formatCurrency";
import FeeLine from "./FeeLine";

class AgreementFees extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            agreement
        } = this.props;

        return (
            <div>
                <table className="table table-responsive">
                    <thead>
                    <tr>
                        <th>{__("Description")}</th>
                        <th className="text-right">{__("Amount")}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                        _.map(_.get(agreement, ["fees"], []), function (item, i) {
                            return <FeeLine key={i} fee={item}/>;
                        })
                    }
                    </tbody>
                    <tfoot>
                    <tr>
                        <td colSpan={1}>
                            {__("TOTAL")}
                        </td>
                        <td className="text-right">
                            {
                                formatCurrency(
                                    _.reduce(_.get(agreement, ["fees"], []), function (sum, fee) {
                                        return sum + parseFloat(_.get(fee, ["fee"], 0), 2);
                                    }, 0)
                                    , 2)
                            }
                        </td>
                    </tr>
                    </tfoot>
                </table>
            </div>
        );
    }
}

AgreementFees.propTypes = {
    agreement: PropTypes.object.isRequired
};

export default AgreementFees;
