import _ from "lodash";
import Balboa from "../Global/Balboa";
import toInt from "../../utils/toInt";

var fmtMsg = function(orig){
    var msg = {};
    _.each(orig, function(value, key){
        if((key === "id" || /_id$/.test(key)) && _.isString(value)){
            msg[key] = toInt(value);
        }else if(/_ISO8601$/.test(key) && _.has(orig, key.replace(/_ISO8601$/, ""))){
            var base = key.replace(/_ISO8601$/, "");
            msg[base] = new Date(value); //we want the iso8601 conversion _with_ tz offset, otherwise the 'ago' values are screwed up
        }else if(!_.has(msg, key)){
            msg[key] = value;
        }
    });
    return msg;
};

export default {
    send: function(params){
        return function(dispatch, getState){
            var key = ["MPMessageSend", params.event_id];
            return Balboa(dispatch, getState)
                .post(
                    key,
                    "/mpmessage/sendtospeakers",
                    params,
                    {cache_ttl: -1}
                )
                .then(function(response) {
                    dispatch({
                        type: "MESSAGE_CREATE",
                        eid: params.event_id,
                        data: fmtMsg(_.first(_.get(response, ["data", "messages"], {})))
                    });
                    return response;
                })
                .then(function(response) {
                    dispatch({
                        type: "BALBOA3_CLEAR_KEY",
                        key: key
                    });
                    return response;
                });
        };
    },
    clear: function(){
        return function(dispatch, getState){//This is a `thunk` that "redux-thunk" handles
            var state = getState();
            _.each(state.balboa3, function(v, key){
                if(key.indexOf("MPMessageSend") === 0){
                    dispatch({
                        type: "BALBOA3_CLEAR_KEY",
                        key: key
                    });
                }
            });
        };
    }
};
