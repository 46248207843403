import _ from "lodash";
import Balboa from "../Global/Balboa";
import {gettext as __} from "../../utils/Trans";

export default function(params) {
    return (dispatch, getState) => {
        var key = ["makeOffer", params.event_id, params.speaker_id];
        return Balboa(dispatch, getState)
            .post(key, "/mpagreement/createdbybuyer", params, {cache_ttl: -1})
            .then(function(response) {
                dispatch({
                    type: "EVENT_AGREEMENT_POST",
                    id: params.event_id,
                    data: _.get(response, ["data"])
                });
                return response;
            })
            .then(function (response) {
                dispatch({
                    type: "NOTIFICATION_CREATE",
                    message: __("Success!  We have sent your offer to the speaker.  Wait for them to respond or send you a counter-offer.")
                });
                return response;
            });
    };
};
