import _ from "lodash";
import React from "react";
import SearchBanner from "./SearchBanner";
import SearchForm from "./SearchForm";

export default class LocalSearchWrapper extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            qs: null
        };
    }

    componentDidMount() {
        let location = [];
        if (_.has(this.props.local_search, ["city"])) {
            location.push(_.get(this.props.local_search, ["city"]));
        }
        if (_.has(this.props.local_search, ["state"])) {
            location.push(_.get(this.props.local_search, ["state"]));
        }
        if (_.has(this.props.local_search, ["country_name"])) {
            location.push(_.get(this.props.local_search, ["country_name"]));
        }

        this.setState({
            qs: _.assign({}, this.props.qs, {
                location: {
                    location: _.join(location, ", "),
                    city: _.get(this.props.local_search, ["city"]),
                    state: _.get(this.props.local_search, ["state"]),
                    country: _.get(this.props.local_search, ["country"], "United States"),
                    country_short: _.get(this.props.local_search, ["country_short"], "US"),
                    lat: _.get(this.props.local_search, ["latitude"]),
                    lng: _.get(this.props.local_search, ["longitude"]),
                    use_lat_lng: _.get(this.props.local_search, ["use_lat_lng"], _.has(this.props.local_search, ["city"]))
                }
            })
        });
    }

    render() {
        let {
            local_search
        } = this.props;

        return (
            <div className="text-center" style={{
                backgroundImage: "linear-gradient(rgba(0, 0, 0, 0.75), rgba(0, 0, 0, 0.75)), url(" + _.get(local_search, ["background"]) + ")",
                position: "relative",
                marginTop: "-20px",
                top: 0,
                left: 0,
                minHeight: "375px",
                width: "100%",
                padding: "40px",
                borderRadius: "0px",
                backgroundColor: "#000",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "50% 0",
                backgroundSize: "cover"
            }}>
                <h1 style={{
                    color: "#fff"
                }}>{_.get(local_search, ["name"])}<div className="small">{_.get(local_search, ["description"])}</div></h1>
                <div className="container"><SearchBanner {...this.props} /></div>
                {local_search && this.state.qs &&
                    <SearchForm
                        whitelabel={this.props.whitelabel}
                        match={this.props.match}
                        user={this.props.user}
                        num_results={this.props.num_results}
                        languages={this.props.languages}
                        awards={this.props.awards}
                        local_search={local_search}
                        qs={this.state.qs}
                    />
                }
            </div>
        );
    }
}