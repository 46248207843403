import _ from "lodash";
import React from "react";
import { Button, Modal } from "react-bootstrap";
import { gettext as __ } from "../../../../utils/Trans";

export default class SpeakerRatingModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    __onReview = () => {
        if (_.isFunction(this.props.onReview)) {
            this.props.onReview(this.props.speaker);
        }
    }

    render() {
        let {
            speaker,
            onClose
        } = this.props;

        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("Candidate Reviews")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {_.map(_.get(speaker, ["ratings"]), function(rating, i) {
                        return (
                            <div key={i}>
                                <div>
                                    {_.map([1,2,3,4,5], function(i) {
                                        return (<i key={i} className={"fa fa-star" + (_.get(rating, ["rating"], 0) < i ? "-o" : "")} />);
                                    })}
                                </div>
                                <div>
                                    {_.get(rating, ["comments"])}
                                </div>
                                <div className="small"><i>Posted by {_.get(rating, ["collaborator", "email"])}</i></div>
                                <hr />
                            </div>
                        );
                    })}
                    {this.props.onReview &&
                    <div className="text-center">
                        <Button onClick={this.__onReview}>
                            {__("Post your own review")}
                        </Button>
                    </div>
                    }
                </Modal.Body>
            </Modal>
        );
    }
}