import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import SpeakerThumbnail from "../../../../components/SpeakerThumbnail";
import SpeakerName from "../../../../components/SpeakerName";
import StepNumber from "../../../../components/StepNumber";
import { gettext as __ } from "../../../../utils/Trans";

export default class SpeakerShortlist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {

        };
    }

    render() {
        let {
            onRemoveSpeakerFromShortlist,
            shortlist_sids
        } = this.props;

        return (
            <div style={{marginBottom:"2em"}}>
                <div>
                    <StepNumber n="1">
                        <h3 className="text-success text-left">
                            {__("Inquiring about these speakers")}
                        </h3>
                    </StepNumber>
                </div>

                <div style={{display:"flex", flexDirection: "row", justifyContent: "start", flexWrap: "wrap"}}>
                    {_.map(shortlist_sids, function(sid, i) {
                        return (
                            <div className="text-center" key={i} style={{margin:"1em 1em"}}>
                                <div><SpeakerThumbnail sid={sid} dont_show_name_on_hover={true} className="center-block" size={50}/></div>
                                <SpeakerName style={{width:"6em"}} sid={sid} />
                                <div className="text-center"><span className="link small text-danger" onClick={() => onRemoveSpeakerFromShortlist(sid)}>{__("remove")}</span></div>
                            </div>
                        );
                    })}
                    <div style={{paddingTop:"2em"}}>
                        <a href="/marketplace/search">
                            <i className="fa fa-plus fa-2x fa-fw"/>
                            <div style={{display:"inline-block", verticalAlign:"top"}} className="text-muted">{__("add another")}</div>
                        </a>
                    </div>

                </div>
            </div>
        );
    }
}

SpeakerShortlist.propTypes = {
    shortlist_sids: PropTypes.array,
    onRemoveSpeakerFromShortlist: PropTypes.func
};