import _ from "lodash";
import Balboa from "../Global/Balboa";
import Rest from "../Global/Rest";

export default _.assign({}, Rest, {

    /**
     * Retrieves a list of records.  Pass in search parameters in {params}
     * @param params
     * @returns {Function}
     */
    find: function(params) {
        let key = ["findWhitelabel"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/marketplace/whitelabel", params)
                    .then(function (response) {
                        dispatch({
                            type: "WHITELABEL_GET_ALL",
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Retrieves a single record.
     * @param params
     * @returns {Function}
     */
    get: function(id) {
        let key = ["getWhitelabel", id];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/marketplace/whitelabel/" + id)
                    .then(function (response) {
                        dispatch({
                            type: "WHITELABEL_GET",
                            id: id,
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Inserts a record.  Leave {params.id} 0, otherwise it will be updated
     * @param params
     * @returns {Function}
     */
    add: function(params) {
        let key = ["AddWhitelabel"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .post(key, "/marketplace/whitelabel", params)
                    .then(function (response) {
                        dispatch({
                            type: "WHITELABEL_POST",
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Updates a record.  Pass in {params.id} to specify the record to update, otherwise it will be added
     * @param params
     * @returns {Function}
     */
    update: function(params) {
        let key = ["UpdateWhitelabel", _.get(params, ["id"])];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .put(key, "/marketplace/whitelabel/", params)
                    .then(function (response) {
                        dispatch({
                            type: "WHITELABEL_PUT",
                            id: _.get(params, ["id"]),
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    },

    /**
     * Removes a record.  Pass in {params.id} to specify the record to remove
     * @param params
     * @returns {Function}
     */
    remove: function(id) {
        let key = ["DeleteWhitelabel", id];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .del(key, "/marketplace/whitelabel/" + id)
                    .then(function (response) {
                        dispatch({
                            type: "WHITELABEL_DELETE",
                            id: id,
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
            );
        };
    }
});
