import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";

class RecommendationsQuickStats extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speaker,
            onShowTab
        } = this.props;

        let recommendations = _.get(speaker, ["recommendations"]);
        let verified_count = 0;

        verified_count = _.size(_.filter(recommendations, function (review) {
            return (_.includes(_.get(review, ["flags_arrayed"], []), "MARKETPLACE_VERIFIED") || _.includes(_.get(review, ["flags_arrayed"], []), "VERIFIED"));
        }));

        //TODO: positive/vs is deprecated; we ought to reorganize the display to remove
        let positive_reviews = _.filter(recommendations, function (item) {
            return _.parseInt(_.get(item, ["rating"])) < 0;
        });
        let percent = 0;

        if (_.size(recommendations) > 0) {
            percent = _.round((_.size(positive_reviews) / _.size(recommendations)) * 100);
            percent = 100
        }

        return (
            <div className="quickStatsBox ratingSmall">
                <h5 className="header">{__("REVIEWS")}</h5>
                <h4><a data-tab="recommendations" onClick={onShowTab} href="#recommendations">{_.size(recommendations)}</a></h4>
                {(_.size(recommendations) > 0) &&
                <div className="progress">
                    <div className="progress-bar progress-bar-success" style={{width: (percent + "%")}}></div>
                    <div className="progress-bar progress-bar-danger" style={{width: (100 - percent + "%")}}></div>
                </div>
                }
                {(_.size(recommendations) > 0) && (verified_count > 0) && <div className="text-center"><a data-tab="recommendations" onClick={onShowTab} href="#recommendations">{verified_count + " " + __("verified")} &raquo;</a></div>}
                {(_.size(recommendations) > 0) && (verified_count < 1) && <div className="text-center"><a data-tab="recommendations" onClick={onShowTab} href="#recommendations">{__("read") + " " + __("reviews")} &raquo;</a></div>}

            </div>
        );
    }
}

RecommendationsQuickStats.propTypes = {
    speaker: PropTypes.object.isRequired,
    onShowTab: PropTypes.func
};

export default RecommendationsQuickStats;