import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

class ProfileSocialButtons extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        _.delay(() => {
            let addthisScript = document.createElement("script");
            // addthisScript.setAttribute("src", "//s7.addthis.com/js/300/addthis_widget.js#username=xa-4ba12f6261f80cd7");
            addthisScript.setAttribute("src", "//s7.addthis.com/js/300/addthis_widget.js#pubid=ra-5ba140c019d9a0a7");
            if (document.body) {
                document.body.appendChild(addthisScript);
            }
        });
    }

    render() {
        let {
            speaker
        } = this.props;

        let primary_bio = _.first(_.get(speaker, ["bios"], []));
        let oneline = _.get(primary_bio, ["oneline"], "");
        return (
            <div className="addthis_toolbox" data-url={window.location.href} data-title={_.get(speaker, ["name"])} data-description={oneline} data-media={_.get(speaker, ["mainPhotoURL"])} />
        );
    }
}

ProfileSocialButtons.propTypes = {
    speaker: PropTypes.object
};

export default ProfileSocialButtons;