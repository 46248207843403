import _ from "lodash";
import Balboa from "../Global/Balboa";
import Event from "../Event/Event";
import {gettext as __} from "../../utils/Trans";

export default function(params){
    return (dispatch, getState) => {
        var key = ["acceptOffer", params.event_id, params.speaker_id];

        return Balboa(dispatch, getState)
            .post(key, "/mpagreement/acceptasbuyer", params)
            .then(function(response) {
                // accepting an agreement changes a lot (event, shortlist, agreement, so it's easier to just reload the event)
                return dispatch(Event.get(_.get(params, ["event_id"])));
            })
            .then(function(response) {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            })
            .then(function (response) {
                dispatch({
                    type: "NOTIFICATION_CREATE",
                    message: __("Congratulations!  You have accepted the offer and now have a speaker! PAY DEPOSIT to lock the dates on your speaker's calendar.")
                });
                return response;
            });
    };
};
