import _ from "lodash";

export default function(state = {}, action){
    switch(action.type) {
        case "MESSAGE_CREATE": {
            let new_state = _.cloneDeep(state);
            let new_msg = action.data;
            let msgs = _.get(new_state, ["messages", "all"], []);
            let match = _.find(msgs, function (o) {
                return new_msg.id === o.id;
            });
            if (match) {
                let index = _.indexOf(msgs, match);
                msgs.splice(index, 1, new_msg);
            } else {
                msgs.push(new_msg);
            }
            _.setWith(new_state, ["messages", "all"], msgs, Object);
            return _.assign({}, state, new_state);
        }
        case "MESSAGES_CREATE": {
            let new_state = _.cloneDeep(state);
            let messages = _.get(action, ["data"], []);
            let msgs = _.get(new_state, ["messages", "all"], []);
            _.each(messages, function(new_msg) {
                let match = _.find(msgs, function (o) {
                    return new_msg.id === o.id;
                });
                if (match) {
                    let index = _.indexOf(msgs, match);
                    msgs.splice(index, 1, new_msg);
                } else {
                    msgs.push(new_msg);
                }
            });
            _.setWith(new_state, ["messages", "all"], msgs, Object);
            return _.assign({}, state, new_state);
        }
        case "MESSAGE_MARK_AS_READ": {
            let new_state = _.cloneDeep(state);
            let msg_ids = action.ids;
            let msgs = _.get(new_state, ["messages", "all"], []);
            _.each(msg_ids, function(msg_id) {
                let match = _.find(msgs, function (o) {
                    return msg_id === o.id;
                });
                if (match) {
                    let index = _.indexOf(msgs, match);
                    _.set(match, ["receiver_opened"], new Date().getTime());
                    msgs.splice(index, 1, match);
                }
            });
            _.setWith(new_state, ["messages", "all"], msgs, Object);
            return _.assign({}, state, new_state);
        }
        case "MESSAGE_UPDATE": {
            let new_state = _.cloneDeep(state);
            let new_msg = action.data;
            let msgs = _.get(new_state, ["messages", "all"], []);
            let match = _.find(msgs, function (o) {
                return new_msg.id === o.id;
            });
            if (match) {
                let index = _.indexOf(msgs, match);
                msgs.splice(index, 1, new_msg);
            } else {
                msgs.push(new_msg);
            }
            _.setWith(new_state, ["messages", "all"], msgs, Object);
            return _.assign({}, state, new_state);
        }
        case "MESSAGE_DELETE": {
            let new_state = _.cloneDeep(state);
            let msgs = _.get(new_state, ["messages", "all"], []);
            msgs = _.without(msgs, {id: action.eid});
            _.setWith(new_state, ["messages", "all"], msgs, Object);
            return new_state;
        }
        case "MESSAGE_DELETE_EVENT": {
            let new_state = _.cloneDeep(state);
            let msgs = _.get(new_state, ["messages", "all"], []);
            msgs = _.without(msgs, {event_id: action.eid});
            _.setWith(new_state, ["messages", "all"], msgs, Object);
            return new_state;
        }
        case "MESSAGE_DELETE_EVENT_SPEAKER": {
            let new_state = _.cloneDeep(state);
            let msgs = _.get(new_state, ["messages", "all"], []);
            msgs = _.without(msgs, {event_id: action.eid, sender_speaker_id: action.sid});
            _.setWith(new_state, ["messages", "all"], msgs, Object);
            return new_state;
        }
        case "MESSAGE_LOAD": {
            let new_state = _.cloneDeep(state);
            if (_.size(_.get(action, ["data"], [])) > 0) {
                let msgs = _.get(new_state, ["messages", "all"], []);
                _.forEach(_.get(action, ["data"], []), function (item) {
                    let match = _.find(msgs, function (o) {
                        return item.id === o.id;
                    });
                    if (match) {
                        let index = _.indexOf(msgs, match);
                        msgs.splice(index, 1, item);
                    } else {
                        msgs.push(item);
                    }
                });
                _.setWith(new_state, ["messages", "all"], msgs, Object);
            } else {
                _.setWith(new_state, ["messages", "all"], [], Object);
            }
            return _.assign({}, state, new_state);
        }
        case "MESSAGE_LOAD_ALL": {
            let new_state = _.cloneDeep(state);
            if (_.size(_.get(action, ["data"], [])) > 0) {
                let msgs = _.get(new_state, ["messages", "all"], []);
                _.forEach(_.get(action, ["data"], []), function (item) {
                    let match = _.find(msgs, function (o) {
                        return item.id === o.id;
                    });
                    if (match) {
                        let index = _.indexOf(msgs, match);
                        msgs.splice(index, 1, item);
                    } else {
                        msgs.push(item);
                    }
                });
                _.setWith(new_state, ["messages", "all"], msgs, Object);
            }
            return _.assign({}, state, new_state);
        }
        case "NOTIFICATION_LOAD": {
            let new_state = _.cloneDeep(state);
            let eid_then_sid = action.data;
            let total = 0;
            _.setWith(new_state, ["notifications"], {}, Object);
            _.each(eid_then_sid, function (by_sid, eid) {
                _.each(by_sid, function (n_unread, sid) {
                    total += n_unread;
                    _.setWith(new_state, ["notifications", "eids", eid, "sids", sid, "total"], n_unread, Object);
                });
                _.setWith(new_state, ["notifications", "eids", eid, "total"], _.reduce(by_sid, function (sum, n_unread) {
                    return sum + n_unread;
                }, 0), Object);
            });
            _.setWith(new_state, ["notifications", "total"], total, Object);
            return _.assign({}, state, new_state);
        }
        default:
            return state;
    }
}
