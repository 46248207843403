import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";

class ExperienceList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            whitelabel,
            speaker
        } = this.props;

        let industries = _.get(speaker, ["industries", _.get(whitelabel, ["bid"])], _.get(speaker, ["industries", 0], _.get(speaker, ["industries"], [])));

        if (_.size(industries) === 0) {
            return null;
        }

        return (
            <div>
                <h5 className="header legend">{__("Experience In")}</h5>
                <ul>
                    {_.map(industries, function(item, i) {
                        if (_.isArray(item)) {
                            return _.map(item, (subitem, k) => {
                                return (<li key={i + "." + k}>{subitem}</li>);
                            });
                        } else {
                            return (
                                <li key={i}>{item}</li>
                            );
                        }
                    })}
                </ul>
            </div>
        );
    }
}

ExperienceList.propTypes = {
    speaker: PropTypes.object.isRequired,
    whitelabel: PropTypes.object.isRequired
};

export default ExperienceList;