import React from "react";
import { gettext as __ } from "../../../../utils/Trans";
import LoginForm from "../../LoginForm";

let mpi_logo = require("../../mpi_200.png");

class Login extends React.Component {

    render() {

        return (
            <div>
                <h1 className=" page-header">
                    {__("Sign in with your MPI account")}
                </h1>
                <div className="row">
                    <div className="col-sm-6">
                        <LoginForm />
                    </div>
                    <div className="col-sm-6 text-center">
                        <p className="text-muted">
                            {__("Your mpiweb.org account gives you full access here.")}
                        </p>
                        <img src={mpi_logo} alt="mpi logo" />
                    </div>
                </div>
                <hr />
                <div className="row">
                    <div className="col-sm-5">
                        <p className="alert alert-warning" role="alert">
                            <span className="headline">
                                {__("Are you a presenter? Manage jobs and events on your")}{" "}<a className="text-warning" href="https://www.espeakers.com/myevents">{__("EventCX calendar")}.</a>
                            </span>
                        </p>
                    </div>
                </div>
                <br/>
                <br/>
                <br/>
            </div>
        );
    }
}

export default Login;
