import _ from "lodash";
import React from "react";
import Promise from "es6-promise";
import { connect } from "react-redux";
import { Prompt, Redirect, withRouter } from "react-router";
import moment from "moment";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { gettext as __ } from "../../../../utils/Trans";
import { Message, Selectize } from "../../../../components/FormComponents";
import LoadingIndicator from "../../../../components/LoadingIndicator";
import PostAJob_SavePendingJob from "../../../../actions/PostAJobPage/PostAJob_SavePendingJob";
import Constants from "../../../../utils/Constants";

class PostExistingJobForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            min_date: new Date().toISOString(),
            is_ready_to_submit: false
        };
    }

    handleSubmit = (values, dispatch) => {
        let params = values;
        _.set(params, ["eid"], _.get(values, ["eid"]));

        return new Promise((resolve) => {
            resolve(dispatch(PostAJob_SavePendingJob(params)));
        }).then((response) => {
            this.setState({
                is_ready_to_submit: true
            });
            return response;
        }).catch((err) => {
            throw new SubmissionError({_error: err});
        });
    }

    render() {
        let {
            handleSubmit,
            submitSucceeded,
            submitting,
            error,
            dirty,
            is_loading,
            events,
            whitelabel,
            onShowAllEvents
        } = this.props;

        return (
            <Form className="mp-style-form" onSubmit={handleSubmit(this.handleSubmit)}>
                <Prompt
                    when={!this.state.is_ready_to_submit && dirty}
                    message={location =>
                        __("Are you sure you want to leave before posting your job?")
                    }
                />
                <Field
                    name="eid"
                    className="form-control"
                    label={__("Selected event")}
                    component={Selectize}
                    placeholder={__("Select an existing event...")}
                    options={_.map(events, function(item) {
                        return {label: (_.get(item, ["organization"]) + " on " + moment.utc(new Date(_.get(item, ["Stagetimes", 0, "starttime"], 0) * 1000)).format("dddd, MMMM Do YYYY")), value: _.toNumber(_.get(item, ["eid"]))};
                    })}
                />
                <div className="text-muted small text-center">
                    <div className="link" onClick={onShowAllEvents}>choose a different event </div>
                </div>

                {!_.get(whitelabel, ["is_bureau_mode"], false) &&
                    <Field
                        name="message"
                        className="form-control"
                        label={__("Message to Speakers")}
                        rows={10}
                        component={Message}
                    />
                }

                <div className="form-group text-center">
                    <button type="submit" className="btn btn-success btn-lg" disabled={submitting}>
                        {submitting ? <i className="fa fa-spin fa-circle-o-notch" /> : <i/>} {__("ADD SPEAKERS TO JOB")}
                    </button>
                    {submitSucceeded && <span className="text-success"> <i className="fa fa-check" /> {__("Everything checks out!  One moment while we post your job to the speakers...")}</span>}
                    {submitSucceeded && <Redirect to={"/postajob/?onboard=postajobexisting"} />}
                    {error && <span className="text-danger"> <i className="fa fa-times" /> {_.get(error, ["message"])}</span>}
                </div>
                {is_loading && <LoadingIndicator />}
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function(state, ownProps){

    // Page Properties
    let pending_job = _.get(state, ["PostAJobPage", "pending"], {});

    let events = _.filter(_.get(state, ["Events", "events"], []), function(item) {
        return (
            (moment(_.get(item, ["starttime"], new Date())).isSameOrAfter(new Date()))
        );
    });

    return {
        is_loading: _.isUndefined(events),
        events: events,
        initialValues: {
            bureau_id: _.get(state, ["Whitelabel", "whitelabel", "bid"], Constants.TRUNK_BUREAU_ID),
            shortlist_sids: ownProps.shortlist_sids,
            eid: ownProps.eid,
            message: _.get(pending_job, ["message"], __("Hello,") + "\n\n" + __("I'd like to invite you to apply for my speaking engagement.  Please review these details and let me know if you are available and would be a good match for our needs."))
        }
    };
};

var mapDispatchToProps = function(/* dispatch, ownProps */){
    return {

    };
};

PostExistingJobForm = reduxForm({
    form: "postanexistingjob",
    enableReinitialize: true,
    validate: function(values) {
        const errors = {};
        if (!values.eid) {
            errors.eid = __("Please select an event.");
        }
        // if (!values.message) {
        //     errors.message = __("Please enter a message inviting the speakers to your event.");
        // }
        return errors;
    }
})(PostExistingJobForm);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostExistingJobForm));
