import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";


class PodcastList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    static getDerivedStateFromProps = (props) => ({
        files: _.orderBy(props.files, ['vieworder', 'aid'], ['desc', 'asc'])
    })

    render() {
        let {
            title,
        } = this.props;

        let { files } = this.state

        
        if (_.isEmpty(files)) {
            return null;
        }

        return (
            <div >
                <h5>{title}</h5>
                {_.map(files, (file, i) => (
                    <div style={{ width: '450px', margin: '10px 0 0 0'}} key={i} dangerouslySetInnerHTML={{__html: _.get(file, ['search1'])}} className="langsensitive" data-lang={_.get(file, ["lang"])}>
                    </div>
                ))}
            </div>
        );
    }
}

class FileList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    static getDerivedStateFromProps = (props) => ({
        files: _.orderBy(props.files, ['vieworder', 'aid'], ['desc', 'asc'])
    })

    render() {
        let {
            title,
            icon,
            // files,
            onVideoClick
        } = this.props;

        let { files } = this.state
        
        if (_.isEmpty(files)) {
            return null;
        }

        return (
            <ul>
                <h5>{title}</h5>
                {_.map(files, (file, i) => (
                    <li key={i} className="langsensitive" data-lang={_.get(file, ["lang"])}>
                        {_.get(file, ["type"]) === "VIDEO" &&
                            <span className={"link"} onClick={() => onVideoClick(file)}>
                                <i className={icon} />{" "}
                                {_.get(file, ["description"])}
                            </span>
                        }
                        {_.get(file, ["type"]) !== "VIDEO" &&
                        <a rel="noopener noreferrer" href={_.get(file, ["url"])} target="_blank">
                            <i className={icon} />{" "}
                            {_.get(file, ["description"])}
                        </a>
                        }
                    </li>
                ))}
            </ul>
        );
    }
}

FileList.propTypes = {
    speaker: PropTypes.object.isRequired,
    title: PropTypes.string,
    files: PropTypes.array,
    icon: PropTypes.string,
    onVideoClick: PropTypes.func
};

class Files extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speaker,
            profile_language = "en",
            onVideoClick
        } = this.props;

        return (
            <div>
                <h3 className="header">{__("Materials")}</h3>
                <div style={{columns: "300px 2", breakAfter: "avoid"}}>
                    <FileList
                        speaker={speaker}
                        title="Video"
                        icon="fa fa-play-circle-o"
                        onVideoClick={onVideoClick}
                        files={_.filter(_.get(speaker, ["assets"]), function(item) {
                            return _.get(item, ["type"]) === "VIDEO" &&
                                    (_.get(item, ["lang"], "") === profile_language || _.get(item, ["lang"], "") === "") 
                                    && (_.get(item, ['flags']) & 32) === 0;
                        })}
                    />
                    <FileList
                        speaker={speaker}
                        title="Photo"
                        icon="fa fa-photo"
                        files={_.filter(_.get(speaker, ["assets"]), function(item) {
                            return _.get(item, ["type"]) === "PHOTO" &&
                                    (_.get(item, ["lang"], "") === profile_language || _.get(item, ["lang"], "") === "");
                        })}
                    />
                    <FileList
                        speaker={speaker}
                        title="Audio"
                        icon="fa fa-headphones"
                        files={_.filter(_.get(speaker, ["assets"]), function(item) {
                            return _.get(item, ["type"]) === "AUDIO" &&
                                (_.get(item, ["lang"], "") === profile_language || _.get(item, ["lang"], "") === "");
                        })}
                    />
                    <FileList
                        speaker={speaker}
                        title="Documents"
                        icon="fa fa-file-alt"
                        files={_.filter(_.get(speaker, ["assets"]), function(item) {
                            return _.get(item, ["type"]) === "DOC" &&
                                (_.get(item, ["lang"], "") === profile_language || _.get(item, ["lang"], "") === "");
                        })}
                    />
                    <FileList
                        speaker={speaker}
                        title="Testimonials"
                        icon="fa fa-book"
                        files={_.filter(_.get(speaker, ["assets"]), function(item) {
                            return _.get(item, ["type"]) === "TESTIMONIAL" &&
                                (_.get(item, ["lang"], "") === profile_language || _.get(item, ["lang"], "") === "");
                        })}
                    />
                    <FileList
                        speaker={speaker}
                        title="Covers & Jackets"
                        icon="fa fa-file-alt"
                        files={_.filter(_.get(speaker, ["assets"]), function(item) {
                            return _.get(item, ["type"]) === "JACKET" &&
                                (_.get(item, ["lang"], "") === profile_language || _.get(item, ["lang"], "") === "");
                        })}
                    />
                </div>
                    <PodcastList
                        speaker={speaker}
                        title="Podcasts"
                        icon="fa fa-podcast"
                        files={_.filter(_.get(speaker, ["assets"]), function(item) {
                            return _.get(item, ["type"]) === "PODCAST" &&
                                (_.get(item, ["lang"], "") === profile_language || _.get(item, ["lang"], "") === "");
                        })}
                    />
            </div>
        );
    }
}

Files.propTypes = {
    speaker: PropTypes.object.isRequired,
    profile_language: PropTypes.string,
    onVideoClick: PropTypes.func
};

export default Files;