import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../utils/Trans";

class PaymentMethodBox extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            is_loading,
            stripe,
            size
        } = this.props;

        let fa_size = (size !== "small") ? " fa-3x" : " ";

        let payment_method_type = _.get(stripe, ["object"], "");
        var is_bank_account = (payment_method_type === "bank_account");
        var is_credit_card = (payment_method_type === "card");
        var payment_method_brand = _.get(stripe, ["brand"], "Card");
        var last4 = _.get(stripe, ["last4"], "***");
        var bank_name = _.get(stripe, ["bank_name"], "");
        var routing_number = _.get(stripe, ["routing_number"], "");
        var exp_month = _.get(stripe, ["exp_month"], "01");
        var exp_year = _.get(stripe, ["exp_year"], "2017");

        // Figure out the card logo to make it easier than put an entire if...else block in the HTML markup
        var card_brand = "fa-cc-stripe";
        if (payment_method_brand === "Visa") {
            card_brand = "fa-cc-visa";
        } else if (payment_method_brand === "MasterCard") {
            card_brand = "fa-cc-mastercard";
        } else if (payment_method_brand === "Discover") {
            card_brand = "fa-cc-discover";
        } else if (payment_method_brand === "American Express") {
            card_brand = "fa-cc-amex";
        }

        return (
            <div className="panel panel-default">
                <div className="panel-body">
                    {is_loading &&
                    <div className="media">
                        <div className="media-left">
                            <i className={"fa fa-spin fa-circle-o-notch " + fa_size} />
                        </div>
                        <div className="media-body">
                            <h4 className="media-heading">{__("Loading...")}</h4>
                            {__("Please wait while we load your payment method")}
                        </div>
                    </div>
                    }
                    {!is_loading && is_bank_account &&
                    <div className="media">
                        <div className="media-left">
                            <i className={"fa fa-bank " + fa_size}></i>
                        </div>
                        <div className="media-body">
                            <h4 className="media-heading">{bank_name}{" "}{__("ending in")}{" "}***{last4}</h4>
                            {__("Routing")} #{routing_number}
                        </div>
                        <div className="media-right">
                            <div className="label label-success"><i className="fa fa-check"></i>{" "}{__("Verified")}</div>
                        </div>
                    </div>
                    }
                    {!is_loading && is_credit_card &&
                    <div className="media">
                        <div className="media-left">
                            <i className={"fa " + card_brand + fa_size}></i>
                        </div>
                        <div className="media-body">
                            <h4 className="media-heading">{payment_method_brand}{" "}{__("ending in")}{" "}***{last4}</h4>
                            {__("Expires")} {exp_month}/{exp_year}
                        </div>
                        <div className="media-right">
                            <div className="label label-success"><i className="fa fa-check"></i>{" "}{__("Verified")}</div>
                        </div>
                    </div>
                    }
                </div>
            </div>
        );
    }
}

PaymentMethodBox.propTypes = {
    stripe: PropTypes.object,
    size: PropTypes.string,
    is_loading: PropTypes.bool
};

export default PaymentMethodBox;
