import React from "react";
import { connect } from "react-redux";
import { gettext as __ } from "../../../utils/Trans";
import AgreementInfo from "../components/AgreementInfo";
import SpeakerContact from "../components/SpeakerContact";
import TravelPlans from "../components/TravelPlans";

// const EventPromoInfo = function(props){
//     var eid = props.eid;
//
//     var url = "https://espeakers.com/promotion/" + eid;
//
//     return (
//         <div>
//             <h4 style={{margin: "0 0 15px 0"}}>
//                 {__("Event promotion site")}
//             </h4>
//             <p className="text-warning">
//                 <i className="fa fa-info-circle" />
//                 {" "}
//                 {__("Advertise this URL to attendees to generate excitement about your event.")}
//             </p>
//             <CopyUrl
//                 url={url}
//                 btn_text={__("Copy")}
//                 msg_copied={__("Copied!")}
//                 msg_failed_to_copy={__("Failed to copy, use CTRL+C instead")}
//             />
//         </div>
//     );
// };

class PreEventStep extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            event,
            speaker,
            agreement,
            payment
        } = this.props;

        return (
            <div>
                <h2 className=" text-center">{__("You are ready for your event!")}</h2>
                <hr style={{margin: "2em 0"}}/>

                <SpeakerContact speaker={speaker} {...this.props} />

                <hr style={{margin: "2em 0"}} />

                <AgreementInfo
                    event={event}
                    speaker={speaker}
                    agreement={agreement}
                    payment={payment}
                    {...this.props}
                />

                <hr style={{margin: "2em 0"}}/>

                {/*<EventPromoInfo eid={eid}/>*/}

                <TravelPlans
                    event={event}
                    speaker={speaker}
                />
                {/*<hr style={{margin: "2em 0"}}/>*/}
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function() {
    return {

    };
};

var mapDispatchToProps = function(dispatch, ownProps){
    return {
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PreEventStep);
