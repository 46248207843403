import formatNumber from "./formatNumber";

export default function (fee_low, fee_high, add_dollar_signs = false) {
  if (fee_low === 0 && fee_high === 0) {
    return "request quote";
  } else if (fee_low === 0 && fee_high > 0) {
    return "up to " + ((add_dollar_signs) ? "$" : "") + formatNumber(fee_high, 0, true);
  } else {
    let mink = ((add_dollar_signs) ? "$" : "") + formatNumber(fee_low, 0, true);
    mink += ((fee_high > fee_low) || (fee_high > (mink * 1000))) ? "+" : "";
    return mink;
  }
}
