import _ from "lodash";
import toInt from "../../utils/toInt";
import Balboa from "../Global/Balboa";
import convertFromISO8601 from "../../utils/convertFromISO8601";

var formatAgreement = function(data){
    var agreement = {};
    _.each(data, function(value, key){
        if((key === "id" || /_id$/.test(key)) && _.isString(value)) {
            agreement[key] = toInt(value);
        } else if(/_ISO8601$/.test(key) && _.has(data, key.replace(/_ISO8601$/, ""))) {
            var base = key.replace(/_ISO8601$/, "");
            agreement[base] = convertFromISO8601(value);
        } else if(_.isObject(value)) {
            agreement[key] = formatAgreement(value);
        } else if (!_.has(agreement, key)) {
            agreement[key] = value;
        }
    });

    return agreement;
};

var formatData = function(data){
    return _.mapValues(data, formatAgreement);
};

export default function(eid, clear_cache = false) {
    var cache_ttl = clear_cache ? -1 : 30 * 60 * 1000;

    return (dispatch, getState) => {
        var key = ["MPAgreement", eid];

        return Balboa(dispatch, getState)
            .post(key, "/mpagreement/readAllOnEvent/", {
                event_id: eid
            }, {cache_ttl: cache_ttl})
            .then(function(response) {
                // Save the user to the state
                return dispatch({
                    type: "BUYER_EVENT_AGREEMENT_LOAD",
                    eid: eid,
                    data: formatData(_.get(response, ["data"]))
                });
            })
            .then(function(response) {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            });
    };
};
