import _ from "lodash";
import React from "react";
import { gettext as __ } from "../../../utils/Trans";
import SpeakerTile from "../../../components/SpeakerTile";
import AdSpeed from "../../../components/AdSpeed";
// import { Link } from "react-router-dom";

let imgArrow = require("./img/arrow_nw.png");

export default class SearchResults extends React.PureComponent {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            whitelabel,
            speakers,
            shortlist,
            awards,
            languages,
            free_form_text_tags,
            show_virtual_fees,
            is_debug              = false,
            search_form_submitted = false,
            onProfile,
            onFavorite,
            onViewPrograms,
            onViewSchedule,
            onWatchVideo
        } = this.props;

        let results = _.map(speakers, function (speaker, i) {
            return (
                <div key={i} className="col-xs-12 col-sm-6 col-md-4 col-lg-3">
                    <SpeakerTile
                        whitelabel={whitelabel}
                        awards={awards}
                        languages={languages}
                        speaker={speaker}
                        show_virtual_fees={show_virtual_fees}
                        free_form_text_tags={free_form_text_tags}
                        onProfile={onProfile}
                        onViewPrograms={onViewPrograms}
                        onViewSchedule={onViewSchedule}
                        onWatchVideo={onWatchVideo}
                        onFavorite={(_.get(whitelabel, ["is_directory_mode"], false) ? null : onFavorite)}
                        is_debug={is_debug}
                        hide_fees={_.get(whitelabel, ["flags", "MP_HIDE_FEES"], false)}
                        is_shortlisted={_.includes(shortlist, _.get(speaker, ["sid"]))}
                        is_featured={!_.get(whitelabel, ["flags", "MP_STEALTHY_FEATURES"], false) && _.get(speaker, ["featured"], false)}
                        is_virtual_vetted={_.get(speaker, ["virtual_vetted"], false)}
                        is_virtual_host={_.get(speaker, ["virtual_host"], false)}
                        is_virtual_master={_.get(speaker, ["virtual_master"], false)}
                        is_showcased={!_.get(whitelabel, ["flags", "MP_STEALTHY_FEATURES"], false) && _.get(speaker, ["showcased"], false)}
                    />
                </div>
            );
        });

        if (_.get(whitelabel, ["mp_keyvalues", "AD_INRESULT_ZONE"])) {
            for (let i = ((_.toInteger(_.size(results) / 4) * 4) - 8); i > 3; i -= 12) {
                results.splice(
                    i,
                    0,
                    <div key={"ad" + i} className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <AdSpeed ad_number={0} whitelabel={whitelabel}/>
                    </div>
                );
            }
        }


        if (_.isEmpty(results) && search_form_submitted) {
            return (
                <div className="container search-page-results" >
                    <div className="center-block well" style={{marginTop: "2em"}}>
                        <h4 className="text-center">{__("No matching speakers...")}</h4>
                        <div className="row">
                            <div className="col-xs-2">
                                <i className="fa fa-search-minus" style={{fontSize: "5em"}}></i>
                            </div>
                            <div className="col-xs-9 text-left">
                                <p>{__("Try adjusting the filters or broaden the search terms to find more speakers.")}</p>
                                <p>{__("Suggestions")}:</p>
                                <ul>
                                    <li>{__("Make sure words are spelled correctly")}</li>
                                    <li>{__("Try using fewer topics or keyword criteria")}</li>
                                    <li>{__("Try more general keywords")}</li>
                                </ul>
                                <p>{__("You can also just")} <a href={"/search?auto=1"}>{" "}{__("show everyone")}{" "}<i className="fa fa-search-plus"/></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        if (_.isEmpty(results) && !search_form_submitted && _.get(whitelabel, ["flags", "MP_HIDE_DEFAULT_RESULTS"], false)) {
            return (
                <div className="container search-page-results" >
                    <div className="text-center center-block well" style={{marginTop: "2em"}}>

                        <div className="row">
                            <div className="col-xs-2">
                                <img src={imgArrow} style={{width: "3em", height: "auto"}} alt="look up"/>
                            </div>
                            <div className="col-xs-10">
                                <div className="text-center">
                                    <h4>{__("Search above to find great presenters")}</h4>
                                    <p>{__("Type in a topic or name to get started.")}</p>
                                </div>
                                <p>{__("You can also just")} <a href={"/marketplace/search?auto=true"}>{" "}{__("show everyone")}{" "}<i className="fa fa-search-plus"/></a></p>
                            </div>
                        </div>
                    </div>
                </div>
            );
        }

        return (
            <div>
                <div className="container hidden-xs search-page-results" >
                    <div className="row speaker-tile-wrapper">
                        {results}
                    </div>
                </div>
                <div className="visible-xs row search-page-results" >
                    <div className="row speaker-tile-wrapper">
                        {results}
                    </div>
                </div>
            </div>
        );
    }
}