import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import CalendarIcon from "../../../../components/CalendarIcon";
import CandidateList from "../CandidateList";
import { gettext as __ } from "../../../../utils/Trans";

import "./Event.scss";

class Event extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    __onEvent = () => {
        if (_.isFunction(this.props.onEvent)) {
            this.props.onEvent(this.props.event);
        }
    }

    render() {
        let {
            event,
            notification,
            speakers
        } = this.props;

        let offer_status = (!_.isEmpty(_.get(event, "offer_status", null))) ?
            <div className="label label-info" style={{width:"100%"}}>
                {__(_.get(event, "offer_status", null))}
            </div> : null;

        let is_public_job = _.get(event, ["flags_as_map", "mp_job_board", "is_set"], false);
        let has_venue_location = (_.get(event, ["deliveryMethod"]) === 1);
        let venue = _.get(event, ["Venues", 0]);

        let speakers_with_offers = _.filter(speakers, {"offer_accepted": true});
        let has_offer = (_.size(speakers_with_offers) > 0);
        let needs_payment = (_.size(_.filter(_.get(event, ["Services"]), {'paid': false})) > 0);

        let url = "/shortlist/" + _.get(event, ["eid"]);
        if (has_offer) {
            let speaker = _.head(speakers_with_offers);
            url += "/" + _.get(speaker, ["sid"]);
        } else {
            let speaker = _.head(speakers);
            url += "/" + _.get(speaker, ["sid"]);
        }

        return (
            <div className="panel panel-default panel-event text-left">
                <div className="panel-body">
                    <div className="media">
                        <div className="media-left">
                            <CalendarIcon
                                notifications={notification}
                                speaker_count={_.size(speakers)}
                                is_public={is_public_job}
                                event={event}
                            />
                        </div>
                        <div className="media-body">
                            <div className="pull-right">
                                {has_offer && needs_payment &&
                                <Link
                                        to={url}>
                                        <div className="btn btn-success">
                                            {__("Pay Now")}
                                        </div>
                                    </Link>
                                }
                                {" "}
                                <Link to={url}>
                                    <div className="btn btn-default btn-flat">
                                        {__("Details")}
                                    </div>
                                </Link>
                            </div>
                            <Link to={url} style={{textDecoration: "none"}}>
                                <h4 className="media-title">
                                    {_.get(event, ["organization"])}
                                    <div className="small">
                                        {_.get(event, ["meeting_name"])}{" "}
                                        <span className="small text-muted">(#{_.get(event, ["eid"])})</span>
                                    </div>
                                </h4>
                            </Link>
                            {has_venue_location &&
                            <div>
                                <i className="fa fa-map-marker fa-fw"></i> {_.get(venue, ["city"])}, {_.get(venue, ["st"])}
                            </div>
                            }
                            <hr />
                            <div>
                                <div className="pull-right hidden-sm hidden-xs">
                                    {offer_status}
                                </div>
                                <div className="visible-sm visible-xs">
                                    <div className="clearfix" style={{padding:"3px"}}>
                                        <div className="pull-left">
                                            <i className="fa fa-users" style={{fontSize:"20px"}} />
                                        </div>
                                        <div className="pull-left text-muted" style={{marginLeft:"10px"}}>
                                            {_.size(speakers)}{" "}{_.size(speakers) === 1 ? __("Speaker") : __("Speakers")}
                                        </div>
                                    </div>
                                </div>
                                <div className="hidden-sm hidden-xs">
                                    <CandidateList candidates={speakers} has_offer={has_offer} />
                                </div>
                            </div>
                            {_.size(speakers) === 0 && !is_public_job &&
                            <div className="label label-warning" style={{fontWeight: "normal"}}>
                                <i className="fa fa-exclamation-circle"></i>
                                {" "}
                                {__("You should invite some presenters to apply.")}
                            </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

Event.propTypes = {
    event: PropTypes.object,
    notification: PropTypes.number,
    speakers: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

export default Event;