import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { gettext as __ } from "../../utils/Trans";
import Message from "../../actions/Messages/Message";
import ShortList from "../../actions/ShortList/ShortList";
import LoadingIndicator from "../../components/LoadingIndicator";
import MessagePreview from "./components/MessagePreview";
import SelectToGetStarted from "./components/SelectToGetStarted";
import MessageScrollList from "../../components/MessageScrollList";

import "./MessagesPage.scss";

class MessagesPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.loadMessages();
    }

    render() {
        let {
            user,
            whitelabel,
            is_loading,
            message_list,
            thread,
            markMessageAsRead,
            addSpeakerToShortlist
        } = this.props;

        if (is_loading) {
            return (
                <div className="MP-Messages-Page" style={{margin: "30px 0"}}>
                    <h1 className="page-header">
                        {__("MY MESSAGES")}
                    </h1>
                    <p className="text-muted">{__("Please wait while we load your messages...")}</p>
                    <LoadingIndicator />
                </div>
            );
        }

        if (!is_loading && _.isEmpty(message_list)) {
            return (
                <div className="MP-Messages-Page" style={{margin: "30px 0"}}>
                    <h1 className="page-header">
                        {__("MY MESSAGES")}
                    </h1>
                    <p className="text-muted">{__("Looks like you don't have any messages yet!")}</p>
                    <p className="text-muted">{__("You can create an event, add speakers to it and send them messages.  When a speaker replies to you, you can communicate with them on the event or from this page.")}</p>
                </div>
            );
        }

        return (
            <div className="MP-Messages-Page" style={{margin: "30px 0"}}>
                <h1 className="page-header">
                    {__("MY MESSAGES")}
                </h1>
                <div className="row">
                    <div className="col-xs-4">
                        {message_list &&
                            <MessageScrollList messages={message_list} use_threads={true} onSelect={this.__onSelect}/>
                        }
                    </div>
                    <div className="col-xs-8">
                        {thread && _.size(thread) > 0 &&
                            <MessagePreview thread={thread} onMarkAsRead={markMessageAsRead} onAddSpeakerToShortlist={addSpeakerToShortlist} user={user} whitelabel={whitelabel} />
                        }
                        {!thread &&
                            <SelectToGetStarted />
                        }
                    </div>
                </div>
                {is_loading && <LoadingIndicator />}
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

const mapStateToProps = function (state, ownProps) {

    let messages = _.filter(_.get(state, ["Messages", "messages", "all"], []), function(item) {
        return (!_.includes(_.get(item, ["flags_as_array"], []), "system_log"));
    });

    let message_list = _.filter(messages, function(item) {
        return (_.get(item, ["sender_speaker_id"]) > 0) || (_.get(item, ["receiver_speaker_id"]) > 0);
    });

    let thread;
    if (_.get(ownProps.match, ["params", "eid"]) && _.get(ownProps.match, ["params", "sid"])) {
        let eid = _.toInteger(_.get(ownProps.match, ["params", "eid"]));
        let sid = _.toInteger(_.get(ownProps.match, ["params", "sid"]));
        thread = _.filter(messages, function(item) {
            return ((_.get(item, ["sender_speaker_id"]) === sid || _.get(item, ["receiver_speaker_id"]) === sid) &&
                (_.get(item, ["event_id"]) === eid));
        });

        message_list = _.map(message_list, function(item) {
            if ((_.get(item, ["receiver_speaker_id"], 0) === sid || _.get(item, ["sender_speaker_id"], 0) === sid) && _.get(item, ["event_id"], 0) === eid) {
                _.set(item, ["is_selected"], true);
            } else {
                _.set(item, ["is_selected"], false);
            }
            return item;
        });
    }

    return {
        is_loading: _.isUndefined(_.get(state, ["Messages", "messages", "all"])),
        messages: messages,
        message_list: message_list,
        thread: thread
    };
};

const mapDispatchToProps = function (dispatch, ownProps) {
    return {
        loadMessages: function() {
            return dispatch(Message.find({
                bureau_id: _.get(ownProps.whitelabel, ["bid"])
            }))
                .catch((err) => {
                    if (_.get(err, ["name"]) === "AuthorizationException") {
                        ownProps.history.push("/error");
                    }
                });
        },
        markMessageAsRead: function(ids) {
            return dispatch(Message.markAsRead(ids));
        },
        addSpeakerToShortlist: function(params) {
            return dispatch(ShortList.addspeaker(params))
                .then((resp) => {
                    // refresh the messages for the event/speaker
                    return dispatch(Message.findByEvent(_.get(resp, ["event_id"])));
                })
                .catch((err) => {
                    if (_.get(err, ["name"]) === "AuthorizationException") {
                        ownProps.history.push("/error");
                    }
                });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MessagesPage);