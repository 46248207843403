import React from "react";
import PropTypes from "prop-types";
import md5 from "md5";
import { OverlayTrigger, Popover } from "react-bootstrap";

class HelpTooltip extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            title = "",
            content = "",
            icon = "fa fa-question-circle",
            placement = "top",
            trigger = "click"
        } = this.props;

        return (
            <OverlayTrigger trigger={trigger} placement={placement} overlay={
                <Popover id={"popover_" + md5(title)} title={title}>
                    {content}
                </Popover>
            }>
                <i className={icon} />
            </OverlayTrigger>
        );
    }
}

HelpTooltip.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    placement: PropTypes.string,
    trigger: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    icon: PropTypes.string
};

export default HelpTooltip;