import React from "react";
import { connect } from "react-redux";
import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import queryString from "query-string";
import App from "./App";

class Whitelabel extends React.Component {

    render() {
        let {
            qs
        } = this.props;

        return (
            <BrowserRouter>
                <div>
                    <Switch>
                        {/* These pages can only be accessed if the user is logged in */}
                        <Route path="/s/:bcode/en" render={(props) => (<App {...props} lang={"en"} qs={qs} />)} />
                        <Route path="/s/:bcode/es" render={(props) => (<App {...props} lang={"es"} qs={qs} />)} />
                        <Route path="/s/:bcode/pt" render={(props) => (<App {...props} lang={"pt"} qs={qs} />)} />
                        <Route path="/s/:bcode" render={(props) => (<App {...props} lang={"en"} qs={qs} />)} />
                        <Route path="/marketplace/en" render={(props) => (<App {...props} lang={"en"} qs={qs} />)} />
                        <Route path="/marketplace/es" render={(props) => (<App {...props} lang={"es"} qs={qs} />)} />
                        <Route path="/marketplace/pt" render={(props) => (<App {...props} lang={"pt"} qs={qs} />)} />
                        <Route path="/marketplace" render={(props) => (<App {...props} lang={"en"} qs={qs} />)} />
                        <Route render={(props) => (<App {...props} lang={"en"} qs={qs} />)} />
                    </Switch>
                </div>
            </BrowserRouter>
        );


    }

}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function () {
    let qs = queryString.parse(window.location.search, {arrayFormat: "bracket"});

    return {
        qs: qs
    };
};

var mapDispatchToProps = function () {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Whitelabel);