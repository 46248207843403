import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import formatFeeRange from "../../../../utils/formatFeeRange";
import { gettext as __ } from "../../../../utils/Trans";

class PriceRangeQuickStats extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
          speaker
        } = this.props;

    let fee_low = _.get(speaker, ["low_fee"], 0); //_.min([_.get(speaker, ["low_fee"], 0), _.get(speaker, ["v_low_fee"], 100000)]);
    let fee_high = _.get(speaker, ["high_fee"], 0); //_.max([_.get(speaker, ["high_fee"], 0), _.get(speaker, ["v_high_fee"], 0)]);
    let v_fee_low = _.get(speaker, ["v_low_fee"], 0);
    let v_fee_high = _.get(speaker, ["v_high_fee"], 0);
    return (
      <div className="quickStatsBox">
        <h5 className="header">{__("PRICE RANGE")}</h5>
        <div className="text-center">
          {(fee_low <= 0) && (fee_high <= 0) && (v_fee_low <= 0) && (v_fee_high <= 0) && <div className="priceRange">inquire for fees</div>}
          <div className="priceRange">
            {formatFeeRange(fee_low, fee_high, true)}
            <h6 className="header">{__("ON-SITE")}</h6>
          </div>
        </div>
        <div className="text-center">
          {((v_fee_low > 0) || (v_fee_high > 0)) && <div className="priceRange">
            {formatFeeRange(v_fee_low, v_fee_high, true)}
            <h6 className="header">{__("VIRTUAL")}</h6>
          </div>}
        </div>
      </div>
    );
  }
}

PriceRangeQuickStats.propTypes = {
  speaker: PropTypes.object.isRequired
};

export default PriceRangeQuickStats;