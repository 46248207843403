import _ from "lodash";
import React from "react";
import { LinkContainer } from "react-router-bootstrap";
import { Button, NavItem, Popover } from "react-bootstrap";
import { Overlay } from "react-overlays";
import { gettext as __ } from "../../../../../utils/Trans";

export default class NavContactFavorites extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            container: null,
            show: true,
            hide_timer: null
        };
        this.setFavoritesRef = element => {
            this.favoritesRef = element;
        };
    }

    componentDidMount() {
        this.setState({
            hide_timer: window.setTimeout(this.__hidePopover, 5000)
        });
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (this.state.hide_timer) {
            window.clearTimeout(this.state.hide_timer);
        };
        this.setState({
            show: (_.size(nextProps.shortlist) > _.size(this.props.shortlist)),
            container: nextProps.container,
            hide_timer: window.setTimeout(this.__hidePopover, 5000)
        });
    }

    componentWillUnmount() {
        if (this.state.timer) {
            window.clearTimeout(this.state.hide_timer);
        }
    }

    __showPopover = () => {
        this.setState({
            show: true
        });
    }

    __hidePopover = () => {
        this.setState({
            show: false
        });
    }

    render() {
        let {
            shortlist
        } = this.props;

        if (_.isEmpty(shortlist)) {
            return null;
        }

        return (
            <LinkContainer to={"/postaprivatejob"} className="hidden-sm">
                <NavItem className="mp-nav-button"><Button ref={this.setFavoritesRef} className="btn btn-success mp-secondary btn-sm navbar-btn">{__("Contact Favorites")}</Button>
                    <Overlay
                        rootClose={true}
                        onHide={() => {}}
                        show={this.state.show}
                        placement="bottom"
                        // container={this.favoritesRef}
                        target={props => this.favoritesRef}
                    >
                        <Popover id="favorites_popover">
                            <div className="text-center"><i className="text-muted fa fa-heart fa-2x"/></div>
                            {__("We added the speaker to your favorites.  Click here to contact them.")}
                        </Popover>
                    </Overlay>
                </NavItem>
            </LinkContainer>
        );
    }

}