import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import queryString from "query-string";

export class SearchResumePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            redirect_to_search: false
        };
    }

    render() {
        let {
            last_hash,
            last_search,
            last_search_params
        } = this.props;

        let url;
        if (last_search && _.get(last_search, ["params"])) {
            url = "/search?" + queryString.stringify(_.get(last_search, ["params"]));
        } else if (last_search_params) {
            url = "/search?" + queryString.stringify(last_search_params);
        }
        if (url && last_hash) {
            url += ("#sid" + last_hash);
        }

        if (url) { // redux store saved last search
            return (<Redirect to={url}/>);
        } else {
            return (<Redirect to="/search" />);
        }
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function(state) {
    let last_search = _.get(state, ["SearchPage", "last"]);
    let last_search_params = _.get(window.localStorage, ["last_search_params"]);
    if (!_.isUndefined(last_search_params) && _.isString(last_search_params)) {
        try {
            last_search_params = JSON.parse(last_search_params);
        } catch (e) {
            // silently ignore errors
        }
    }

    let qs = queryString.parse(_.get(window.location, ["search"]));
    let last_hash = _.get(qs, ["sid"]);

    return {
        last_hash: last_hash,
        last_search: last_search, // state saved last search
        last_search_params: last_search_params //local storage last search
    };
};

var mapDispatchToProps = function() {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResumePage);