import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";

/**
 * To add a homepage meta title and description, add a value to MP_KEYVALUE for [HOMEPAGE_META_TITLE, HOMEPAGE_META_DESCRIPTION] respectively.
 */
class MetaTag extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
          whitelabel,
        } = this.props;
    //prevent title from flashing before we have speaker & whitelabel data
    if (_.isEmpty(whitelabel)) return null;

    //assemble the meta values
    let title = !_.isEmpty(_.get(whitelabel, ["mp_keyvalues", "HOMEPAGE_META_TITLE"])) ? _.get(whitelabel, ["mp_keyvalues", "HOMEPAGE_META_TITLE"]) : _.get(whitelabel, ["bureauName"], "eSpeakers");
    let description = !_.isEmpty(_.get(whitelabel, ["mp_keyvalues", "HOMEPAGE_META_DESCRIPTION"])) ? _.get(whitelabel, ["mp_keyvalues", "HOMEPAGE_META_DESCRIPTION"]) : ("Hire speakers on " + _.get(whitelabel, ["bureauName"], "eSpeakers"));
    let structured_data = (_.get(whitelabel, "is_espeakers", false)) ? {
      "@context": "https://schema.org",
      "@graph": [
        {
          "@type": "Organization",
          "@id": "https://www.espeakers.com/marketplace/#organization",
          "name": "eSpeakers Marketplace",
          "url": "https://www.espeakers.com/marketplace",
          "logo": {
            "@type": "ImageObject",
            "@id": "https://www.espeakers.com/marketplace/#logo",
            "inLanguage": "en-US",
            "url": "https://streamer.espeakers.com/static/logos/marketplace_logo_trans_780x170.png",
            "contentUrl": "https://streamer.espeakers.com/static/logos/marketplace_logo_trans_780x170.png",
            "width": 784,
            "height": 171,
            "caption": "eSpeakers Marketplace"
          },
          "image": {
            "@id": "https://www.espeakers.com/marketplace/#logo"
          }
        },
        {
          "@type": "WebSite",
          "@id": "https://espeakers.com/marketplace/#website",
          "url": "https://espeakers.com/marketplace",
          "name": "eSpeakers Marketplace",
          "description": "eSpeakers Marketplace - hire speakers, trainers, coaches and consultants.",
          "potentialAction": [
            {
              "@type": "SearchAction",
              "target": {
                "@type": "EntryPoint",
                "urlTemplate": "https://espeakers.com/marketplace/search?q={search_term_string}"
              },
              "query-input": "required name=search_term_string"
            }
          ],
          "inLanguage": "en"
        },
        {
          "@type": "WebPage",
          "@id": "https://espeakers.com/marketplace/#webpage",
          "url": "https://espeakers.com/marketplace/",
          "name": "eSpeakers Marketplace: Hire speakers, trainers, coaches and consultants.",
          "isPartOf": {
            "@id": "https://espeakers.com/marketplace/#website"
          },
          "datePublished": "2015-11-18T20:12:54+00:00",
          "dateModified": "2020-06-25T16:05:08+00:00",
          "description": "Capture the power that happens when the right expert presents the right message to your organization. Hire speakers, trainers, coaches and consultants through the eSpeakers Marketplace.",
          "breadcrumb": {
            "@id": "https://espeakers.com/marketplace/#breadcrumb"
          },
          "inLanguage": "en",
          "potentialAction": [
            {
              "@type": "ReadAction",
              "target": [
                "https://espeakers.com/marketplace"
              ]
            }
          ]
        },
        {
          "@type": "BreadcrumbList",
          "@id": "https://espeakers.com/marketplace/#breadcrumb",
          "itemListElement": [
            {
              "@type": "ListItem",
              "position": 1,
              "name": "eSpeakers Marketplace - hire speakers, trainers, coaches and consultants.",
              "item": "https://www.espeakers.com/marketplace"
            },
            {
              "@type": "ListItem",
              "position": 2,
              "name": "Search the eSpeakers Marketplace",
              "item": "https://www.espeakers.com/marketplace/search"
            }

          ]
        }
      ]
    } : [];

    return (
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={description}/>
        <script type="application/ld+json">
          {JSON.stringify(structured_data)}
        </script>
        meta name="homer" content="simpson" />
      </Helmet>
    );
  }
}

MetaTag.propTypes = {
  whitelabel: PropTypes.object,
};

export default MetaTag;