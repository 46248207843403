import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import onPageScroll from "../../utils/onPageScroll";

class LoadMoreOnScrollBottom extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount(){
        this.__is_mounted = true;
        this.__turnOffScrollListener = onPageScroll(_.bind(function(t, b){
            if(this.__is_mounted){
                if(b < this.props.buffer){
                    this.props.fetch();
                }
            }
        }, this));
    }

    componentWillUnmount(){
        this.__is_mounted = false;
        this.__turnOffScrollListener();
    }

    render() {
        return <span />;
    }
}

LoadMoreOnScrollBottom.propTypes = {
    buffer: PropTypes.number,
    fetch: PropTypes.func
};

export default LoadMoreOnScrollBottom;