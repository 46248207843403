import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import FlashMessage from "../../components/FlashMessage";

class FlashNotification extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            notifications,
            clearNotifications
        } = this.props;

        let items = _.map(notifications, function(notification, i) {
            return (
                <div key={i}>{notification}</div>
            );
        });

        return (
            <FlashMessage className="alert-success" onClick={clearNotifications}>
                {items}
            </FlashMessage>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function(state){
    var notifications = _.get(state, ["App", "notifications"], []);
    return {
        notifications: notifications
    };
};

var mapDispatchToProps = function(dispatch){
    return {
        clearNotifications: function() {
            return dispatch({
                type: "NOTIFICATION_CLEAR"
            });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(FlashNotification);
