import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { Collapse } from "react-bootstrap";
import { gettext as __ } from "../../../../../utils/Trans";

export default class NavShortlistJobMobile extends React.Component {

    render() {
        let {
            shortlist
        } = this.props;

        return (
            <div className="visible-xs">
                <Collapse in={!_.isEmpty(shortlist)}>
                    <div className="nav-shortlist-job-mobile" style={{margin:"10px"}}>
                        <Link to="/postaprivatejob">
                            <div className="btn btn-success mp-secondary btn-block">{__("GET FREE QUOTE")}</div>
                        </Link>
                    </div>
                </Collapse>
            </div>
        );
    }
}