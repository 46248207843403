import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { gettext as __ } from "../../utils/Trans";
import formatPhone from "../../utils/formatPhone";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { DateRangePickerField, Message, Phone, Text } from "../../components/FormComponents";
import InlineError from "../../components/InlineError";
import LoadingSubmit from "../../components/LoadingSubmit";
import StepHeader from "../../components/StepHeader";
import ReCAPTCHA from "react-google-recaptcha";
import RecaptchaPassed from "../../actions/Recaptcha/Recaptcha";
import ClearRecaptcha from "../../actions/Recaptcha/ClearRecaptcha";
import speakerDirectMessage from "../../actions/Speaker/speakerDirectMessage";

import "./SpeakerDirectMessage.scss";

class SpeakerDirectMessageForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            send_result: null
        };
    }

    handleSubmit = (values, dispatch, props) => {

        if (!props.captchapassed) return;

        let message_data = {
            event_name: values.event_name,
            event_date: values.event_date,
            event_location: values.event_location,
            message: values.message,
            name: values.name,
            email: values.email,
            phone: values.phone,
            captcha: {
                key: "recaptcha",
                input: props.captchavalue
            } //the key is a magic value, don't change
        };

        return dispatch(speakerDirectMessage({
            is_bookmenow: false,
            message_data: JSON.stringify(message_data),
            sid: values.sid,
            bureau_id: values.bureau_id
        }))
            .then((response) => {
                dispatch(ClearRecaptcha());
                return response;
            })
            .then((response) => {
                this.setState({
                    send_result: {
                        type: "SPEAKER_DIRECTMESSAGE",
                        data: "Inquiry sent!"
                    }
                });
                return response;
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
                speaker,
                handleSubmit,
                submitting,
                error,
                captchapassed,
                setCaptchaPassed,
                whitelabel
            } = this.props;

        let profile_url = "/profile/" + _.get(speaker, ["sid"]) + (_.get(whitelabel, ["is_bureau_mode"], false)) ? "" : "?simplesite=1";


        let submitClasses = ["btn-success", "btn-block", "btn-lg"];
        if (!captchapassed) submitClasses.push("disabled");

        if (!_.isEmpty(_.get(this.state, ["send_result"]))) {
            return (<div>
                <i className="fa fa-3x fa-envelope-o pull-left"/> <h2 style={{paddingLeft: "1.5em"}}>{_.get(this.props, ["send_result", "data"], "Sent!")}</h2>
                <p style={{marginTop: "2em"}}>{__("Watch your inbox for a reply")}{_.get(whitelabel, ["is_bureau_mode"], false) ? "" : (" from " + _.get(speaker, ["name_first"]))}.</p>
                <p style={{marginTop: "2em"}}>{__("While you're waiting, check out")} <Link to={profile_url}>{_.get(speaker, ["name_first"])}&apos;s {__("full profile")} »</Link></p>
            </div>);
        }

        return (
            <Form className="BMN-EventForm mp-style-form" onSubmit={handleSubmit(this.handleSubmit)}>
                {error && <InlineError error={_.get(error, ["message"])}/>}
                <StepHeader n={(_.get(whitelabel, ["is_bureau_mode"], false) ? __("Tell us about your event") : __("Tell %s about your event", [_.get(speaker, ["name_first"])]))}/>
                <div className="row">
                    <div className="col-sm-4">
                        <Field
                            name="event_name"
                            placeholder={__("Widget Association Annual Sales Conference")}
                            label={__("Event Name")}
                            help_text={__("The meeting or conference title, including the session name if applicable.")}
                            component={Text}
                        />
                    </div>
                    <div className="col-sm-4">
                        <Field
                            name="event_date"
                            label={__("Event Date")}
                            help_text={__("The start date of your event. Don't know it yet? Just pick something close.")}
                            component={DateRangePickerField}
                            singleDatePicker={true}
                            ranges={false}
                            type="text"
                        />
                    </div>
                    <div className="col-sm-4">
                        <Field
                            name="event_location"
                            label={__("Event Location")}
                            placeholder={__("City, State")}
                            help_text={__("The city to which the presenter will travel for your meeting, or \"online\" for webinar.")}
                            component={Text}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-sm-12">
                        <Field
                            name="message"
                            label={__("Purpose or goals of your meeting")}
                            placeholder={__("What should the speaker's presentation accomplish for your audience?")}
                            help_text={__("Providing details about your event helps the speaker do their best work for you. Helpful information includes program theme, organization goals, etc.")}
                            component={Message}
                            rows={3}
                        />
                    </div>
                </div>
                <StepHeader n={(_.get(whitelabel, ["is_bureau_mode"], false) ? __("How should we reply to you?") : __("How should %s reply to you?", [_.get(speaker, ["name_first"])]))}/>
                <div className="row">
                    <div className="col-sm-4">
                        <Field
                            name="name"
                            label={__("Your Name")}
                            component={Text}
                        />
                    </div>
                    <div className="col-sm-4">
                        <Field
                            name="email"
                            label={__("Email")}
                            component={Text}
                        />
                    </div>
                    <div className="col-sm-4">
                        <Field
                            name="phone"
                            label={__("Phone")}
                            placeholder="+1 (212) 121-1212"
                            component={Phone}
                            normalize={formatPhone}
                        />
                    </div>
                </div>

                <div className="row">
                    <div className="col-sm-8">
                        <ReCAPTCHA
                            ref={"recaptcha"}
                            sitekey="6Ld6CAMTAAAAAIKCRhPspREtTRBxhBN79f6R7pVE"
                            onChange={setCaptchaPassed}
                        />
                    </div>
                    <div className="col-sm-4">
                        <br/>
                        <LoadingSubmit is_saving={submitting} save_text={__("Sending...")} className={submitClasses.join(" ")} disabled={!captchapassed}>
                            {_.get(whitelabel, "is_bureau_mode", false) ? __("Inquire") : __("Connect with %s", [_.get(speaker, ["name_first"])])}
                        </LoadingSubmit>
                    </div>
                </div>
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {
    return {
        captchapassed: _.get(state, ["Recaptcha", "captchapassed"], false),
        captchavalue: _.get(state, ["Recaptcha", "captchavalue"], false),
        initialValues: {
            bureau_id: _.get(ownProps.whitelabel, ["bid"]),
            sid: _.get(ownProps.speaker, ["sid"])
        }
    };
};


var mapDispatchToProps = function (dispatch) {
    return {
        setCaptchaPassed: function (captchavalue) {
            dispatch(RecaptchaPassed(captchavalue));
        }
    };
};

SpeakerDirectMessageForm = reduxForm({
    form: "speaker_direct_message_form",
    validate: function (values) {
        const errors = {};
        if (!values.event_name) {
            errors.event_name = __("Please enter a name for your event");
        }
        if (!values.name) {
            errors.name = __("Please let us know who you are");
        }
        if (!values.message) {
            errors.message = __("Please describe your meeting and how you would like the speaker to contribute");
        }
        if (!values.email) {
            errors.email = __("Please enter a valid email address to the presenter can contact you");
        }
        if (!values.phone) {
            errors.phone = __("Please enter a valid phone number to the presenter can contact you");
        }
        return errors;
    }
})(SpeakerDirectMessageForm);


export default connect(mapStateToProps, mapDispatchToProps)(SpeakerDirectMessageForm);
