import React from "react";

class PaymentRow extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            label,
            value
        } = this.props;

        return (
            <div className="row">
                <div className="col-sm-7">
                    <div className="row">
                        <div className="col-xs-6">
                            <b style={{
                                display: "inline-block",
                                minWidth: "166px"
                            }}>
                                {label}
                            </b>
                        </div>
                        <div className="col-xs-6 text-right">
                            {value}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentRow;