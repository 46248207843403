import _ from "lodash";
import Balboa from "../Global/Balboa";

export default function() {
    return function(dispatch, getState) {
        var key = ["NUnreadMessages_Poll"];
        return Balboa(dispatch, getState)
            .post(key, "/mpmessage/numunread", {get_by_eid_then_sid: true}, {cache_ttl: -1})
            .then(function(response) {
                // Save the user to the state
                dispatch({
                    type: "NOTIFICATION_LOAD",
                    data: _.get(response, ["data"])
                });
                return response;
            })
            .catch(function(/* err */) {
                // Silently ignore errors because we are polling
            });
    };
};
