import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { gettext as __ } from "../../utils/Trans";
import PostNewJobForm from "./components/PostNewJobForm";
import PostExistingJobForm from "./components/PostExistingJobForm";
import EmbeddedShortlist_refresh from "../../actions/ShortList/EmbeddedShortlist_refresh";
import EmbeddedShortlist_remove from "../../actions/ShortList/EmbeddedShortlist_remove";
import Event from "../../actions/Event/Event";
import SpeakerShortlist from "./components/SpeakerShortlist";
import EventSearch from "./components/EventSearch";
import CustomerService from "../../components/CustomerService";
import InfoBox from "../../components/InfoBox";
import moment from "moment/moment";
import StepNumber from "../../components/StepNumber";
import { Collapse } from "react-bootstrap";

import "./PostAPrivateJobPage.scss";

export class PostAPrivateJobPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_new_job_form: false,
            show_existing_job_form: false,
            eid: -1
        };
    }

    onHideNewJobForm = () => {
        this.setState({
            show_new_job_form: false,
            show_existing_job_form: true,
            eid: -1
        });
    }

    onShowNewJobForm = () => {
        this.setState({
            show_new_job_form: true,
            show_existing_job_form: false,
            eid: -1
        });
    }

    __onChooseEvent = (eid) => {
        this.setState({
            eid: _.toNumber(eid)
        });
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let is_logged_in = this.props.user && _.get(this.props.user, ["role"], "guest") !== "guest";
        if (is_logged_in && _.size(this.props.events) === 0) {
            this.__onLoadEvents();
        }

        window.addEventListener('focus', this.__scrollListener);
    }

    componentWillUnmount() {
        window.removeEventListener('focus', this.__scrollListener);
    }

    __scrollListener = () => {
        if (_.isFunction(this.props.refreshEmbeddedShortlist)) {
            this.props.refreshEmbeddedShortlist();
        }
    }

    __onLoadEvents = () => {
        this.setState({
            is_loading: true
        }, () => {
            if (_.isFunction(this.props.loadEvents)) {
                this.props.loadEvents()
                    .then(() => {
                        return this.setState({
                            is_loading: false
                        });
                    });
            }
        })
    }

    render() {
        let {
            user,
            events,
            whitelabel,
            sitename,
            tiptexts,
            shortlist_sids,
            onRemoveSpeakerFromShortlist
        } = this.props;

        return (
            <div className="paj-private-page">
                <div className="visible-xs-block" style={{marginTop:"4.5em"}}></div>
                <h1 className="page-header" style={{marginBottom: "40px"}}>
                    {!_.get(whitelabel, "is_bureau_mode", false) && ((shortlist_sids.length > 1) ? __("Tell Us About Your Job") : __("Tell Us About Your Job"))}
                    {_.get(whitelabel, "is_bureau_mode", false) && ((shortlist_sids.length > 1) ? __("Inquire about presenters") : __("Inquire about presenter"))}
                </h1>
                <div className="row">
                    <div className="col-md-3 hidden-sm hidden-xs">
                        <div className="hidden-bureaumode">
                            <strong>{__("This is easy!")}</strong>
                            {" "}
                            {(shortlist_sids.length > 1)
                                ? __("These presenters will see your job information and will let you know if they are available and how they can help. We'll send you an email with their responses.")
                                : __("This presenter will see your job information and will let you know if they are available and how they can help. We'll send you an email with their response.")
                            }
                            <p style={{marginTop: "2em"}}><strong>{__("There is never an obligation.")}</strong></p>
                            <CustomerService whitelabel={whitelabel}/>
                            <InfoBox path="\mp\postajob\left" tiptexts={tiptexts} sitename={sitename} whitelabel={whitelabel} startexpanded={true}/>
                        </div>
                    </div>


                    <div className="col-sm-12 col-md-8 col-md-offset-1">
                        <SpeakerShortlist shortlist_sids={shortlist_sids} onRemoveSpeakerFromShortlist={onRemoveSpeakerFromShortlist}/>
                        {this.state.is_loading &&
                        <div className="text-center" style={{marginTop: "2em"}}>
                            <i className="fa fa-spin fa-circle-o-notch fa-3x fa-fw mp-secondary"/>
                        </div>
                        }

                        <Collapse in={(!this.state.is_loading && (this.state.show_new_job_form || _.size(events) === 0 || _.isEmpty(user)))}>
                            <div>
                                <div>
                                    <StepNumber n="2">
                                        <h3 className="text-left text-success">
                                            {__("Tell us about your event")}
                                        </h3>
                                    </StepNumber>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <PostNewJobForm {...this.props} stepnumberstart={3}/>
                                    </div>
                                </div>
                                <br/><br/>
                                {_.size(events) > 0 && !_.isEmpty(user) &&
                                <div className="text-muted text-center">
                                    Or add these speakers <span className="link" onClick={this.onHideNewJobForm}>to an existing event </span>
                                </div>
                                }
                            </div>
                        </Collapse>

                        <Collapse in={(!this.state.is_loading && !this.state.show_new_job_form && this.state.eid < 0 && _.size(events) > 0)}>
                            <div style={{marginTop: "3em"}} className="text-center">
                                <div>
                                    <StepNumber n="2">
                                        <h3 className="text-left text-success">
                                            {__("Quickly add these presenters to one of your events below")}
                                        </h3>
                                    </StepNumber>
                                </div>

                                <EventSearch events={events} onChooseEvent={this.__onChooseEvent} onReload={this.__onLoadEvents} />
                                <br/><br/>
                                <div className="text-muted text-center">
                                    Or <span className="link" onClick={this.onShowNewJobForm}>create a new event</span> for these speakers
                                </div>
                            </div>
                        </Collapse>

                        <Collapse in={(!this.state.is_loading && this.state.eid > 0)}>
                            <div>
                                <div>
                                    <StepNumber n="2">
                                        <h3 className="text-left text-success">
                                            {__("Invite these speakers to your event")}
                                        </h3>
                                    </StepNumber>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <PostExistingJobForm {...this.props} eid={this.state.eid} onShowAllEvents={this.onHideNewJobForm}/>
                                    </div>
                                </div>
                                <br/><br/>
                                <div className="text-muted text-center">
                                    Or <span className="link" onClick={this.onShowNewJobForm}>create a new event</span> for these speakers
                                </div>
                            </div>
                        </Collapse>
                    </div>
                </div>
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {

    let events = _.filter(_.get(state, ["Events", "events"], []), function (item) {
        return (
            (moment(_.get(item, ["starttime"], new Date())).isSameOrAfter(new Date()))
        );
    });

    return {
        events: events,
        sitename: _.get(ownProps.whitelabel, ["name"]),
        tiptexts: _.get(state, ["GlobalLists", "displaylists", "tiptext"], {}),
        shortlist_sids: _.get(state, ["EmbeddedShortlist", "sids"], [])
    };
};

var mapDispatchToProps = function (dispatch, ownProps) {
    return {
        loadEvents: function () {
            return dispatch(Event.find({
                bureau_id: _.get(ownProps.whitelabel, ["bid"])
            }));
        },
        onRemoveSpeakerFromShortlist: function (sid) {
            return dispatch(EmbeddedShortlist_remove(sid));
        },
        refreshEmbeddedShortlist: function() {
            return dispatch(EmbeddedShortlist_refresh());
        }
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PostAPrivateJobPage);