import Balboa from "../Global/Balboa";
import Event from "../Event/Event";

export default function(params){
    return (dispatch, getState) => {
        var key = ["chargeBuyerBalance", params.eid, params.sid];

        return Balboa(dispatch, getState)
            .post(key, "/stripe/chargebuyerbalance/", params, {cache_ttl: -1})
            .then((response) => {
                return dispatch(Event.get(params.eid));
            })
            .then(function(response) {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            });
    };
};
