import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import { gettext as __ } from "../../../../utils/Trans";
import RegisterForm from "../../RegisterForm";
import Analytics from "../../../../utils/Analytics";

class Register extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    LoginOnClick() {
        Analytics.sendEvent("BuyerLogin", "click", 0, "signup page");
    }


    render() {
        let {
            location
        } = this.props;

        return (
            <div>
                <h1 className="hidden-xs page-header">
                    {__("Marketplace Sign up")}
                    {" "}
                    <span className="small" style={{fontSize: "14px"}}>{__("Already have an account?")} <Link to={_.assign({}, location, {pathname: "/signin"})} onClick={this.LoginOnClick.bind(this)}>{__("Sign In")}</Link></span>
                </h1>
                <div className="row">
                    <div className="col-md-6">
                        <RegisterForm {...this.props} />
                    </div>
                    <div className="col-sm-1" />
                    <div className="col-sm-4">
                        <div className="alert alert-warning" role="alert">
                            <h4>{__("Are you a presenter or speaker?")}</h4>
                            <hr />
                            <p>
                                {__("Event organizers search our Marketplace Directory every day looking for the perfect presenter.")}
                            </p>
                            <p>
                               <a className="text-warning" href="/speakers/plans-pricing/"> {__("Choose a membership package")}</a>{" "}{__(" and be listed with over 10,000 other professionals.")}
                            </p>
                            <br />
                            <div className="text-center">
                                <a className="btn btn-warning" href="/speakers/plans-pricing/">Presenter Signup</a>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-1" />
                </div>
                <br/>
                <br/>
                <br/>
            </div>
        );
    }
}

export default Register;
