import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

import digitsImage from "./digitsSmall.png";

class CounterV1 extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timer: false,
            incrementer: 0
        };
    }

    componentDidMount() {
        this.setState({
            timer: window.setInterval(this.incrementCounter, 50)
        });
    }

    componentWillUnmount() {
        if (this.state.timer) {
            window.clearInterval(this.state.timer);
        }
    }

    incrementCounter = () => {
        this.setState({
            incrementer: this.state.incrementer + 1231
        });
    }

    render() {
        let {
            num,
            className,
            style
        } = this.props;

        let digits = this.state.incrementer < _.toInteger(num) ? _.padStart(this.state.incrementer, _.size(_.split(num, "")), "0") : num;

        let counter = _.map(_.split(digits, ""), function(digit, i) {
            let max_offset = (digit * -231);
            // let current_offset = (incrementer * -46);

            let new_style = _.assign({}, {
                height: "38px",
                backgroundPosition: "0px " + (max_offset) + "px",
                backgroundImage: `url(${digitsImage})`,
                width: "27px",
                display: "table-cell"
            }, style);

            return (
                <li key={i} style={new_style}>
                    &nbsp;
                </li>
            );
        });

        return (
            <div className={className}>
                <ul style={{listStyle:"none", margin: "0px", padding: "0px"}}>
                {counter}
                </ul>
            </div>
        );
    }
}

CounterV1.propTypes = {
    num: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    className: PropTypes.string,
    style: PropTypes.object
};

export default CounterV1;