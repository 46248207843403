import React from "react";
import _ from "lodash";

export default class VirtualCvpHeader extends React.Component {

  render() {

    let {
          speaker
        } = this.props;

    let virtual_host = (_.get(speaker, ["flags_as_map", "virtual_host_certified", "is_set"], false));
    let virtual_master = (_.get(speaker, ["flags_as_map", "virtual_master_certified", "is_set"], false));

    return (
      <React.Fragment>
        <div style={{padding: "0px 0px 40px 0px"}}>
          <h3 className="header">
            Certified Virtual Presenter {virtual_host && " + Host"} {virtual_master && " + Master Presenter"}
          </h3>
          <div >
            <div className={"pull-left"}><img src={"https://streamer.espeakers.com/static/marketplace/certified_virtual_256.png"} style={{height: "8rem", width: "auto", padding: "0px 10px"}} alt={"certified virtual presenter"}/></div>
            {virtual_host && <div className={"pull-left"}><img src={"https://streamer.espeakers.com/static/marketplace/certified_host_256.png"} style={{height: "8rem", width: "auto", padding: "0px 10px"}} alt={"certified virtual presenter"}/></div>}
            {virtual_master && <div className={"pull-left"}><img src={"https://streamer.espeakers.com/static/marketplace/virtual_master_256.png"} style={{height: "8rem", width: "auto", padding: "0px 10px"}} alt={"virtual master presenter"}/></div>}
            <div >
              <p>This presenter has passed a rigorous assessment of their equipment, environment, and skills presenting remotely using tools like Zoom, WebEx or GotoMeeting. You can be assured that your virtual event will run smoothly with this Certified Virtual Presenter.</p>
              {virtual_host && <p>This presenter has also attended and passed the Certified Virtual Host course. This is an 8-hour live class plus assessment that teaches presenters how to lead online groups in a way that engages and inspires.</p>}
              {virtual_master && <p>This presenter has also attended and passed the Virtual Master Presenter course. This is a 5-day live class plus assessment that teaches presenters how to host virtual meetings in a way that engages and inspires.</p>}
            </div>
          </div>
        </div>
      </React.Fragment>
    );
  }
}