import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import { Modal } from "react-bootstrap";
import MessageAllForm from "./MessageAllForm";

class MessageAllModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            event,
            candidates,
            onClose
        } = this.props;

        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("Group Message")}</Modal.Title>
                </Modal.Header>
                <MessageAllForm
                    event={event}
                    candidates={candidates}
                    onClose={onClose}
                />
            </Modal>
        );
    }
}

MessageAllModal.propTypes = {
    event: PropTypes.object.isRequired,
    candidates: PropTypes.array.isRequired,
    onClose: PropTypes.func
};

export default MessageAllModal;
