import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { gettext as __ } from "../../utils/Trans";
import ShortlistSidebar from "../ShortlistSidebar";
import ShortlistEmbedded from "../ShortlistEmbedded";
import Sidebar from "./components/Sidebar";
import PostAnExistingJobForm from "./components/PostAnExistingJobForm";
import getBuyerEvents from "../../actions/Global/getBuyerEvents";

export class PostAnExistingJobPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount () {
        window.scrollTo(0, 0);
        this.props.loadData();
    }

    render() {
        let {
            eid,
            user,
            whitelabel,
            sitename,
            tiptexts,
            shortlist_sids
        } = this.props;

        return (
            <div>
                <div className="visible-xs visible-sm">
                    <ShortlistEmbedded url={"/search"} url_text={__("Back to Search")} />
                </div>
                <h1 className="page-header" style={{marginBottom: "40px"}}>
                    {!_.get(whitelabel, "is_bureau_mode", false) && ((shortlist_sids.length > 1) ? __("Connect with presenters") : __("Connect with presenter"))}
                    {_.get(whitelabel, "is_bureau_mode", false) && ((shortlist_sids.length > 1) ? __("Inquire about presenters") : __("Inquire about presenter"))}
                </h1>

                <div className="row">
                    <div className="col-md-3">
                        <div className="hidden-xs hidden-sm">
                            <ShortlistSidebar removeSid={true}/>
                        </div>
                        <p />
                        <Sidebar tiptexts={tiptexts} whitelabel={whitelabel} sitename={sitename} user={user} />
                    </div>
                    <div className="col-sm-12 col-md-8 col-md-offset-1">
                        <div style={{marginBottom: "2em"}}>
                            <div style={{color: "#8a6d3b"}} className="hidden-bureaumode">
                                <strong>{__("This is easy!")}</strong>
                                {" "}
                                {(shortlist_sids.length > 1)
                                    ? __("These presenters will see your job information and will let you know if they are available and how they can help. We'll send you an email with their responses.")
                                    : __("This presenter will see your job information and will let you know if they are available and how they can help. We'll send you an email with their response.")
                                }
                            </div>
                        </div>
                        <PostAnExistingJobForm eid={eid} {...this.props} />
                    </div>
                </div>
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {
    return {
        eid: _.get(ownProps.match, ["params", "eid"], -1),
        sitename: _.get(ownProps.whitelabel, ["name"]),
        tiptexts: _.get(state, ["GlobalLists", "displaylists", "tiptext"], {}),
        shortlist_sids: _.get(state, ["EmbeddedShortlist", "sids"], [])
    };
};

var mapDispatchToProps = function (dispatch) {
    return {
        loadData: function() {
            dispatch(getBuyerEvents());
        }
    };
};


export default connect(mapStateToProps, mapDispatchToProps)(PostAnExistingJobPage);