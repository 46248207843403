import _ from "lodash";
import Balboa from "../Global/Balboa";
import convertBalboa3EventToJSEvent from "../../utils/convertBalboa3EventToJSEvent";
import PostAJob_RemovePendingJob from "../PostAJobPage/PostAJob_RemovePendingJob";
import EmbeddedShortlist_clear from "../ShortList/EmbeddedShortlist_clear";

function format(data) {
    return convertBalboa3EventToJSEvent(data);
}

export default function(event){
    var eid = _.get(event, ["eid"]);
    return (dispatch, getState) => {
        var key = ["createMPEventStep2", eid];

        return Balboa(dispatch, getState)
            .post(key, "/event/setmpeventposting/" + eid, {
                shortlist_sids: _.get(event, ["shortlist_sids"], []),
                message: _.get(event, ["message"], ""),
                meeting_goals: _.get(event, ["meeting_goals"], ""),
                wanna_corporate_sponsor: false,
                gender_mix: false,
                audience_mix: false,
                audience_size: 10
            }, {cache_ttl: -1})
            .then(function(response) {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            })
            .then(function(response) {
                // Job was created, so clear the cookie
                dispatch(PostAJob_RemovePendingJob());
                return response;
            })
            .then(function(response) {
                // Remove the shortlist from the session
                dispatch(EmbeddedShortlist_clear());
                return response;
            })
            .then(function(response) {
                // Save the new event into buyer events
                if (_.get(response, ["data", "eid"], -1) > 0) {
                    let event = format(_.get(response, ["data"]));
                    dispatch({
                        type: "BUYER_EVENT_UPDATE",
                        data: event,
                        eid: _.get(event, ["eid"])
                    });
                }
                return response;
            })
            .then(function(response) {
                // Save the new event into buyer events
                if (_.get(response, ["data", "eid"], -1) > 0) {
                    let event = format(_.get(response, ["data"]));
                    dispatch({
                        type: "SHORTLIST_UPDATE",
                        data: _.get(event, ["MPshortlist"]),
                        eid: _.get(event, ["eid"])
                    });
                }
                return response;
            });
    };
};
