/**
 * Normally, you would extend a class like this:
 *
 * class SearchPageB extends SearchPageA {}
 *
 * But, we don't want to do that here because SearchPageA is connected through
 * redux, so we also want that code to run.  If we just extended the un-connected class, we
 * would have to duplicate all the connected code (i.e. mapStateToProps and mapDispatchToProps)
 *
 * For refererence, check out ProfilePage and HomePage examples.
 */
import React from "react";
import SearchPageA from "./SearchPageA";

export default class SearchPageB extends React.Component {
    render() {
        return (<SearchPageA {...this.props} />);
    }
}