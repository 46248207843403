import _ from "lodash";

export default function(state = {}, action){
    switch(action.type){
        case "PROFILEPAGE_SET_LANGUAGE":
            return _.assign({}, state, {
                profile_language: action.lang
            });
        case "PROFILEPAGE_SELECT_SPEAKER_TAB":
            return _.assign({}, state, {
                selected_tab: action.tab
            });
        default:
            return state;
    }
}
