import React from "react";
import PropTypes from "prop-types";

class SpeakerAddress extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            // address,
            // address2,
            city,
            state,
            // zip,
            country
        } = this.props;

        return (
            <div>
                {/*<div>{address}</div>*/}
                {/*{address2 && <div>{address2}</div>}*/}
                <div>
                    {city}
                    {city && ","} {state}
                </div>
                <div>{country}</div>
            </div>
        );
    }
}

SpeakerAddress.propTypes = {
    // address: PropTypes.string,
    // address2: PropTypes.string,
    city: PropTypes.string,
    state: PropTypes.string,
    zip: PropTypes.string,
    country: PropTypes.string
};

export default SpeakerAddress;
