import React from "react";
import PropTypes from "prop-types";

import "./StepHeader.scss";

class StepHeader extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            n
        } = this.props;

        return (
            <div className="row">
                <div className="StepHeader">
                    <div className="col-xs-12">
                        <div className="StepHeader-n">
                            {n}
                        </div>
                        <hr />
                    </div>
                </div>
            </div>
        );
    }
}

StepHeader.propTypes = {
    n: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
};

export default StepHeader;