import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import Shortlist from "./Shortlist";
import LoadingIndicator from "../../components/LoadingIndicator";
import Event from "../../actions/Event/Event";
import getMPShortlist from "../../actions/ShortList/getMPShortlist";
import getMPAgreementsForEvent from "../../actions/Event/getMPAgreementsForEvent";
import getMPPaymentsForEvent from "../../actions/Event/getMPPaymentsForEvent";
import Message from "../../actions/Messages/Message";
import readRecommendation from "../../actions/Speaker/readRecommendation";
import Speaker from "../../actions/Speaker/Speaker";
import GetStripeCustomer from "../../actions/User/GetStripeCustomer";
import cleanupCandidates from "./utils/cleanupCandidates";
import cleanupEventStatus from "./utils/cleanupEventStatus";
import { OFFER_STATII } from "../../utils/getEventOfferStatusFromState";
import qs from "querystring";
import toMpURL from "../../utils/toMpURL";

class ShortlistPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            is_loading: false
        }
    }

    componentDidMount() {
        window.scrollTo(0, 0);
        let eid = _.get(this.props.match, ["params", "eid"]);
        let sid = _.get(this.props.match, ["params", "sid"]);

        if (_.isUndefined(this.props.shortlist)) {
            this.setState({
                is_loading: true
            }, () => {
                return this.props.loadEvent(eid)
                    .then(() => {
                        this.setState({
                            is_loading: false
                        })
                    });
            });
        }
        // if (_.isUndefined(this.props.payment)) {
        //     this.props.loadPayments(eid);
        // }
        // if (_.isUndefined(this.props.agreements)) {
        //     this.props.loadAgreements(eid);
        // }
        if (_.isUndefined(this.props.messages)) {
            this.props.loadMessages(eid, sid);
        }
        if (!_.isNull(_.get(this.props.user, ["stripe_cust_token"], null))) {
            this.props.loadStripeCustomer();
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (_.get(this.props.match, ["params", "eid"]) !== _.get(nextProps.match, ["params", "eid"])) {
            this.props.loadData(_.get(nextProps.match, ["params", "eid"]), _.get(nextProps.match, ["params", "sid"]));
        }
    }

    render() {
        let {
            candidates,
            user,
            speaker,
            event,
            agreements,
            payment,
            messages,
            shortlist,
            shortlist_speaker
        } = this.props;

        return (
            <div>
                <section>
                    {!this.state.is_loading &&
                    <Shortlist
                        user={user}
                        candidates={candidates}
                        speaker={speaker}
                        event={event}
                        shortlist={shortlist}
                        agreements={agreements}
                        payment={payment}
                        messages={messages}
                        shortlist_speaker={shortlist_speaker}
                        {...this.props}
                    />
                    }
                    {this.state.is_loading && <LoadingIndicator />}
                </section>
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {

    let eid = _.get(ownProps.match, ["params", "eid"]);
    let sid = _.toNumber(_.get(ownProps.match, ["params", "sid"]));

    // event objects
    let event = _.get(state, ["Events", "events", eid]);
    let agreements = _.get(event, ["Agreements"]);
    let payment = _.get(event, ["Services"]);
    let recommendations = _.get(event, ["Recommendations"]);
    let shortlist = _.get(event, ["Shortlists", 0]);
    let shortlist_speaker = _.get(_.keyBy(_.get(shortlist, ["speakers"]), "speaker_id"), [sid]);

    // event/speaker objects
    let recommendation = _.get(recommendations, [sid]);
    let agreement = _.get(agreements, [sid]);
    let messages = _.get(state, ["Messages", "messages", eid, sid]);
    let speakers = _.get(state, ["Speakers"]);


    // calculated objects
    let candidates;
    let speaker;
    if (event && shortlist && speakers) {
        candidates = cleanupCandidates(event, shortlist, messages, agreements, payment, speakers, ownProps.user, sid);
        if (_.has(speakers, [sid])) {
            // Pull the speaker from the candidates (candidate properties merged with speaker properties)
            speaker = _.head(_.filter(candidates, {sid: sid}));
        }
    }

    let event_status = cleanupEventStatus(event, agreements, payment);
    let event_has_an_accepted_offer = _.indexOf(OFFER_STATII, event_status) >= _.indexOf(OFFER_STATII, "offer accepted");

    return {
        is_loading: (_.isUndefined(ownProps.user) || _.isUndefined(event) || _.isUndefined(agreements) || _.isUndefined(payment) || _.isUndefined(shortlist) || (!_.isNaN(sid) && _.isUndefined(candidates) && _.has(candidates, {sid: sid}) && _.isUndefined(speaker))),
        event_status: event_status,
        event_has_an_accepted_offer: event_has_an_accepted_offer,
        // event objects
        candidates: candidates,
        recommendations: recommendations,
        agreements: agreements,
        payment: payment,
        event: event,
        shortlist: shortlist,
        shortlist_speaker: shortlist_speaker,
        // event/speaker objects
        speaker: speaker,
        messages: messages,
        agreement: agreement,
        recommendation: recommendation
    };
};

var mapDispatchToProps = function (dispatch, ownProps) {
    return {
        onProfile: function(speaker) {
            window.open(toMpURL("/profile/" + _.get(speaker, ["sid"]) + "?" + qs.encode({
                btsc: 1,
                btst: "BACK TO SHORTLIST",
                bts: "/shortlist/" + _.get(ownProps.match, ["params", "eid"]) + "/" + _.get(speaker, ["sid"]) + _.get(ownProps.location, ["search"])
            }), ownProps.whitelabel), "profile_" + _.get(speaker, ["sid"]));
            // ownProps.history.push({
            //     pathname: "/profile/" + _.get(speaker, ["sid"]),
            //     state: {
            //         btst: "BACK TO SHORTLIST",
            //         bts: "/shortlist/" + _.get(ownProps.match, ["params", "eid"]) + "/" + _.get(speaker, ["sid"]) + _.get(ownProps.location, ["search"])
            //     }
            // });
        },
        loadStripeCustomer: function () {
            try {
                return dispatch(GetStripeCustomer())
                    .catch(() => {});
            } catch (e) {}
        },
        loadEvent: function (eid) {
            return dispatch(Event.get(eid))
                .then((response) => {
                    let shortlist = _.get(response, ["data", "Shortlists", 0]);

                    let sids = _.map(_.get(shortlist, ["speakers"]), function(spkr) {
                        return _.get(spkr, ["speaker_id"]);
                    });
                    sids = _.uniq(_.concat(sids, _.get(response, ["data", "sids"])));

                    if (_.size(sids) > 0) {
                        return dispatch(Speaker.find({
                            ids: sids
                        }));
                    }
                });
        },
        loadAgreements: function (eid) {
            return dispatch(getMPAgreementsForEvent(eid));
        },
        loadPayments: function (eid) {
            return dispatch(getMPPaymentsForEvent(eid));
        },
        loadMessages: function (eid, sid) {
            if (sid) {
                return dispatch(Message.findByEventAndSpeaker(eid, sid));
            } else {
                return dispatch(Message.findByEvent(eid));
            }
        },
        loadShortlist: function (eid) {
            return dispatch(getMPShortlist(eid))
                .then((response) => {
                    let sids = _.map(_.get(response, ["data", "speakers"]), function(spkr) {
                        return _.get(spkr, ["speaker_id"]);
                    });
                    return dispatch(Speaker.find({
                        ids: sids
                    }));
                });
        },
        loadData: function (eid, sid) {
            if (!_.isNull(_.get(ownProps.user, ["stripe_cust_token"], null))) {
                try {
                    dispatch(GetStripeCustomer())
                        .catch(() => {
                        });
                } catch (e) {}
            }
            return dispatch(Event.get(eid))
                .then((response) => {
                    let sids = _.map(_.get(response, ["data", "speakers"]), function(spkr) {
                        return _.get(spkr, ["speaker_id"]);
                    });
                    if (_.size(sids) > 0) {
                        return dispatch(Speaker.find({
                            ids: sids
                        }));
                    }
                })
                .then((response) => {
                    _.each(_.get(response, ["data", "sids"]), function(sid) {
                        return dispatch(readRecommendation({
                            event_id: eid,
                            speaker_id: sid
                        }));
                    });
                    // dispatch(getMPAgreementsForEvent(eid));
                    // dispatch(getMPPaymentsForEvent(eid));
                    if (sid) {
                        dispatch(Message.findByEventAndSpeaker(eid, sid));
                    } else {
                        dispatch(Message.findByEvent(eid));
                    }
                    return response;
                });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortlistPage);
