import _ from "lodash";
import React from "react";
import dateformat from "dateformat";

import "./travel-icons/travel-icons.scss";

const renderLegTime = function(d) {
    if(!_.isDate(d)){
        return null;
    }
    var time = dateformat(d, "h:MM TT");
    return time === "12:00 AM"
        ? null
        : <span className="label label-default label-flat">{time}</span>;
};

class TravelLeg extends React.Component {

    render() {
        let {
            leg
        } = this.props;

        let parts = _.map(leg.parts, function (part, i) {
            return <div key={i}>
                {renderLegTime(part.date)} {part.description}
            </div>;
        });

        let icon;
        if (leg.type === "Flight") {
            icon = <i className="travel-icon travel-icon-flight"/>;
        } else if (leg.type === "Hotel") {
            icon = <i className="travel-icon travel-icon-building"/>;
        } else if (leg.type === "Ground") {
            icon = <i className="travel-icon travel-icon-cab"/>;
        }

        return (
            <div style={{paddingLeft: "1em", margin: "0 0 1em 0"}}>
                <div style={{
                    float: "left",
                    width: "2em",
                    fontSize: "18px",
                    textAlign: "center"
                }}>
                    {icon}
                </div>
                <div style={{float: "left"}}>
                    {parts}
                </div>
                <div className="clearfix"/>
            </div>
        );
    }
}

export default TravelLeg;