import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import { Row, Col, Nav, NavItem } from "react-bootstrap";
import SpeakerReview from "../../../../components/SpeakerReview";

export default class Reviews extends React.Component {
  
  state = {
    selected_tab: "all",
    recommendations: [],
    percent: 0
  }
  
  static propTypes = {
    speaker: PropTypes.object.isRequired
  }
  
  static getDerivedStateFromProps = (props, state) => ({
    recommendations: _.orderBy(_.get(props.speaker, ['recommendations']), [(o) => _.toInteger(o.vieworder), 'id'], ['desc', 'asc']),
    // recommendations: _.sortBy(_.get(props.speaker, ["recommendations"], []), function (review) {
    //   let score = (_.parseInt(_.get(review, ["vieworder"], 0)) * -1);
    //   score -= (_.includes(_.get(review, ["flags_arrayed"], []), "MARKETPLACE_VERIFIED")) ? 1000 : 0;
    //   score -= (_.includes(_.get(review, ["flags_arrayed"], []), "VERIFIED")) ? 100 : 0;
    //   return score;
    // }),
    filtered_recommendations: _.orderBy(_.filter(_.get(props.speaker, ["recommendations"], []), (review) => {
      if (state.selected_tab === 'virtual') {
        return _.includes(_.get(review, ["flags_arrayed"], []), 'VIRTUAL_EVENT');
      } else if (state.selected_tab === "onsite") {
        return !_.includes(_.get(review, ["flags_arrayed"], []), 'VIRTUAL_EVENT');
      } else {
        return true;
      }
    }), [(o) => _.toInteger(o.vieworder), 'id'], ['desc', 'asc']
    // function (review) {
    //   let score = (_.parseInt(_.get(review, ["vieworder"], 0)) * -1);
    //   score -= (_.includes(_.get(review, ["flags_arrayed"], []), "MARKETPLACE_VERIFIED")) ? 1000 : 0;
    //   score -= (_.includes(_.get(review, ["flags_arrayed"], []), "VERIFIED")) ? 100 : 0;
    //   return score;
    // }
   ),
    percent: _.round((_.size(_.filter(_.get(props.speaker, ["recommendations"], []), (item) => (_.parseInt(_.get(item, ["rating"])) < 0))) / _.size(_.get(props.speaker, ["recommendations"], []))) * 100)
  })
  
  __onSelectTab = (tab) => (
    this.setState({
      selected_tab: tab
    })
  )
  
  render() {
    let {
      filtered_recommendations,
      recommendations,
      // percent,
      selected_tab
    } = this.state;
    
    return (
      <React.Fragment>
        <div className="reviewProgressBar">
          <Row>
            <Col md={6}>
              <h4 className="review-total good text-center">{_.size(recommendations)}</h4>
              {(_.size(recommendations) > 0) &&
              <div className="progress" style={{marginTop: "10px", height: "5px", marginBottom: "4px"}}>
                <div className="progress-bar progress-bar-success" style={{width: (100 + "%")}}></div>
                {/* <div className="progress-bar progress-bar-danger" style={{width: (100 - percent + "%")}}></div> */}
              </div>
              }
              <h5 className="review-recommend-this-speaker">{__("Recommend This Speaker")}</h5>
              <p className="review-post">{__("Post your own verified review when you hire this speaker through eSpeakers.")}</p>
            </Col>
          </Row>
        </div>
        <Row>
          <Col lg={8} lgOffset={2}>
            <Nav bsStyle="pills" justified activeKey={selected_tab} onSelect={this.__onSelectTab}>
              <NavItem eventKey={"all"}>All Reviews</NavItem>
              <NavItem eventKey={"onsite"}>Only On-Site Events</NavItem>
              <NavItem eventKey={"virtual"}>Only Virtual Events</NavItem>
            </Nav>
          </Col>
        </Row>
        {_.map(filtered_recommendations, (item, i) => (<SpeakerReview key={i} review={item} />))}
      </React.Fragment>
    );
  }
}