/**
 * Return a true boolean interpretation of the input
 *
 * @param incoming
 * @returns Boolean
 */

export default function(incoming){
    let r = incoming;
    if ("boolean" !== typeof(incoming)) {
        //if not a boolean already we'll assume it's a string or an int
        r = ("true" === incoming) || (1 === incoming);
    }
    return r;
}
