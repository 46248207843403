import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { gettext as __ } from "../../../../utils/Trans";
import MessagePreview from "../MessagePreview";
import Message from "../../../../actions/Messages/Message";

import "./ShortlistMessages.scss";

class ShortlistMessages extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            user,
            whitelabel,
            thread,
            event,
            speaker,
            markMessageAsRead
        } = this.props;

        let speakername = _.get(speaker, ["name"]);

        return (
            <div>
                <h4 style={{margin: "0 0 20px 0"}}>
                    {__("Messages with %s", [speakername])}
                </h4>
                <div className="MP-Messages">
                    <MessagePreview thread={thread} speaker={speaker} event={event} user={user} whitelabel={whitelabel} onMarkAsRead={markMessageAsRead} show_go_to_event={false} />
                </div>
            </div>
        );
    }
}

ShortlistMessages.propTypes = {
    event: PropTypes.object,
    speaker: PropTypes.object,
    is_sending: PropTypes.bool,
    send_error: PropTypes.string,
    is_in_sidebar: PropTypes.bool,
    messages: PropTypes.array
};

////////////////////////////////////////////////////////////////////////////////
//Connect it up
var mapStateToProps = function (state, ownProps) {
    let messages = _.get(state, ["Messages", "messages", "all"], []);

    let thread;
    let eid = _.toInteger(_.get(ownProps.event, ["eid"]));
    let sid = _.toInteger(_.get(ownProps.speaker, ["sid"]));
    thread = _.filter(messages, function(item) {
        return ((_.get(item, ["sender_speaker_id"]) === sid || _.get(item, ["receiver_speaker_id"]) === sid) &&
            (_.get(item, ["event_id"]) === eid));
    });

    return {
        thread: thread
    };
};

var mapDispatchToProps = function (dispatch) {
    return {
        markMessageAsRead: function(ids) {
            return dispatch(Message.markAsRead(ids));
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortlistMessages);