import _ from "lodash";
import React from "react";
//import VirtualCertifiedModal from "./VirtualCertifiedModal";
import "./VirtualCertified.scss";

export default class VirtualCertified extends React.Component {

  state = {
    //show_virtual_modal: false //we don't use this anymore in lieu of taking them to the VIRTUAL tab
  }

  // __onToggleVirtualModal = () => {
  //   this.setState({
  //     show_virtual_modal: !this.state.show_virtual_modal
  //   });
  // }

  __onShowTab = () => {
    if (_.isFunction(this.props.onShowTab)) {
      return this.props.onShowTab(null, "virtual", true);
    }
  }

  render() {

    let {
      speaker
    } = this.props;

    return (
      <React.Fragment>
        {(_.get(speaker, ["flags_as_map", "virtual_certified", "is_set"], false)) &&
        <img
          data-tab="virtual"
          className={"link virtual_badge"}
          onClick={this.__onShowTab}
          src="https://streamer.espeakers.com/static/marketplace/certified_virtual_256.png"
          alt="Certified Virtual Presenter"
        />
        }
        {(_.get(speaker, ["flags_as_map", "virtual_master_certified", "is_set"], false)) &&
          <img
            data-tab="virtual"
            className={"link virtual_badge second_badge"}
            onClick={this.__onShowTab}
            src="https://streamer.espeakers.com/static/marketplace/virtual_master_256.png"
            alt="Virtual Master Presenter"
          />
        }
        {(_.get(speaker, ["flags_as_map", "virtual_host_certified", "is_set"], false) && !_.get(speaker, ["flags_as_map", "virtual_master_certified", "is_set"], false)) &&
        <img
          data-tab="virtual"
          className={"link virtual_badge second_badge"}
          onClick={this.__onShowTab}
          src="https://streamer.espeakers.com/static/marketplace/certified_host_256.png"
          alt="Certified Virtual Host"
        />
        }
        {/*{show_virtual_modal && <VirtualCertifiedModal show={show_virtual_modal} speaker={speaker} onHide={this.__onToggleVirtualModal}/>}*/}
      </React.Fragment>
    );
  }
}