import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Modal } from "react-bootstrap";
import { gettext as __ } from "../../../../../utils/Trans";
import formatCurrency from "../../../../../utils/formatCurrency";
import assertBalboa from "../../../../../utils/assertBalboa";
import LoadingIndicator from "../../../../../components/LoadingIndicator";
import PaymentMethodBox from "../../../../../components/PaymentMethodBox";
import connectBuyerWithStripe from "../../../../../actions/User/connectBuyerWithStripe";
import connectBuyerWithPlaid from "../../../../../actions/User/connectBuyerWithPlaid";
import StripeCheckout from "react-stripe-checkout";
import PlaidLink from "react-plaid-link";
import { PLAID_API_KEY, PLAID_ENVIRONMENT, STRIPE_API_KEY } from "../../../../../utils/ApiKeys";
import { MailPaperCheckButton } from "../../../components/Buttons";

class ChoosePaymentMethodForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    onStripeToken = (token) => {
        this.props.connectBuyerWithStripe(token);
    }

    onPlaidToken = (token, metadata) => {
        this.props.connectBuyerWithPlaid(token, metadata.account_id);
    }

    render() {
        let {
            onClose,
            balance_amount,
            buyer_email,
            primary_payment_method,
            onClickToPayNow,
            is_saving
        } = this.props;

        return (
            <Modal show={true} bsSize="large">
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("Choose Payment Method")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="help-block">
                        {__("Pay your balance and have one less thing to worry about.")}
                    </div>
                    <h4>
                        <span className="pull-right">{formatCurrency(balance_amount, 2)}</span>
                        {__("Amount Charged Today")}
                    </h4>
                    <hr />
                    {primary_payment_method &&
                    <div>
                        <p>{__("Your current payment method on your account is shown below.")}</p>
                        <PaymentMethodBox stripe={primary_payment_method}/>
                    </div>
                    }
                    <div className="help-block">
                        {__("eSpeakers Marketplace allows you to pay your speakers securely!  You have the choice of Credit Card, Bank Account, or even mailing a paper check.  To get started, simply choose your preferred method of payment.")}
                    </div>
                    <hr />
                    <div className="row">
                        <div className="col-sm-4">
                            <div className="well well-default text-center">
                                <i className="fa fa-2x fa-cc-visa"></i> <i
                                className="fa fa-2x fa-cc-mastercard"></i> <i
                                className="fa fa-2x fa-cc-discover"></i>
                                <h4>{__("Credit Card")}</h4>
                                <ul className="list-group">
                                    <li className="list-group-item">{__("Speaker Keeps Less")}</li>
                                    <li className="list-group-item">{__("Instant Setup")}</li>
                                    <li className="list-group-item">{__("Secure Transactions")}</li>
                                </ul>
                                <StripeCheckout
                                    name="eSpeakers Marketplace"
                                    description={__("Connect Automatic Payments")}
                                    ComponentClass="div"
                                    panelLabel={__("Connect")}
                                    amount={0}
                                    token={this.onStripeToken}
                                    email={buyer_email}
                                    stripeKey={STRIPE_API_KEY}
                                >
                                    <div className="btn btn-info">{__("Connect")}</div>
                                </StripeCheckout>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="well well-default text-center">
                                <i className="fa fa-2x fa-bank"></i>
                                <h4>{__("Bank Account")}</h4>
                                <ul className="list-group">
                                    <li className="list-group-item">{__("Speaker Keeps More")}</li>
                                    <li className="list-group-item">{__("Instant Setup")}</li>
                                    <li className="list-group-item">{__("Secure Transactions")}</li>
                                </ul>
                                <PlaidLink
                                    publicKey={PLAID_API_KEY}
                                    product="auth"
                                    apiVersion="v2"
                                    env={PLAID_ENVIRONMENT}
                                    selectAccount={true}
                                    clientName="eSpeakers Marketplace"
                                    onSuccess={this.onPlaidToken}
                                    className="btn btn-info"
                                    style={{}}
                                >
                                    {__("Connect")}
                                </PlaidLink>
                            </div>
                        </div>
                        <div className="col-sm-4">
                            <div className="well well-default text-center">
                                <i className="fa fa-2x fa-envelope"></i>
                                <h4>{__("Paper Check")}</h4>
                                <ul className="list-group">
                                    <li className="list-group-item">{__("Speaker Keeps More")}</li>
                                    <li className="list-group-item">{__("Takes More Time")}</li>
                                    <li className="list-group-item">{__("Less Secure")}</li>
                                </ul>
                                <MailPaperCheckButton {...this.props} className="btn btn-info" />
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                {primary_payment_method &&
                <Modal.Footer>
                    <div className="btn btn-success btn-block-xs" onClick={onClickToPayNow}>
                        {__("Continue To Payment")}{" "}&gt;
                    </div>
                </Modal.Footer>
                }
                {is_saving && <LoadingIndicator is_modal={true} />}
            </Modal>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

ChoosePaymentMethodForm.propTypes = {
    event: PropTypes.object.isRequired,
    speaker: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    primary_payment_method: PropTypes.object,
    balance_amount: PropTypes.number,
    buyer_email: PropTypes.string,
    onClickToPayNow: PropTypes.func
};

function mapStateToProps(state) {
    // Page Properties
    var saving_ajax = assertBalboa(state, [
        ["connectBuyerWithStripe"],
        ["connectBuyerWithPlaid"]
    ]);

    return {
        is_saving: saving_ajax.waiting
    };
}

function mapDispatchToProps(dispatch) {
    return {
        connectBuyerWithStripe: function (token) {
            dispatch(connectBuyerWithStripe(token));
        },
        connectBuyerWithPlaid: function (token, account_id) {
            dispatch(connectBuyerWithPlaid(token, account_id));
        },
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ChoosePaymentMethodForm);