import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import { OpenModal } from "../../../../components/Modal";

class SpecialOfferQuickStats extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speaker
        } = this.props;

        let sid = _.get(speaker, ["sid"]);

        return (
            <div className="specialSmall quickStatsBox">
                <h5 className="header">{__("DISCOUNT")}</h5>
                <div>
                    <div className="link modalTrigger" onClick={OpenModal("/speaker/specialoffer/" + sid)}>
                        <i className="icon-tag good"></i><span className="dollar">$</span>
                        <p>{__("Special Offer")} &raquo;</p>
                    </div>
                </div>
            </div>
        );
    }
}

SpecialOfferQuickStats.propTypes = {
    speaker: PropTypes.object.isRequired
};


export default SpecialOfferQuickStats;