import Promise from "es6-promise";
import CookiePostAJob from "../../utils/CookiePostAJob";

export default function() {
    return function(dispatch) {
        return new Promise((resolve, reject) => {
            // Save the job to the cookie
            CookiePostAJob.remove();
            // Also save to the state
            return resolve(dispatch({
                type: "POSTAJOBPAGE_REMOVEPENDINGJOB"
            }));
        });
    }
};
