import _ from "lodash";
import toInt from "./toInt";

/**
 * Take a balboa ISO8601 datetime and turn it into a js date
 * @param timestamp
 */
export default function(timestamp){
    var day = _.map(timestamp.split(/\D/), toInt);
    day[1] -= 1;
    var d = new Date(Date.UTC.apply(Date, day));
    return new Date(d.getTime() + d.getTimezoneOffset() * 60000);
}
