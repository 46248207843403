import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
// import { Link } from "react-router-dom";
import HeroImage from "../../components/HeroImage";
import { gettext as __ } from "../../utils/Trans";

export default class TopicsPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // Scroll the page to the top
        window.scrollTo(0, 0);
    }

    render() {
        let {
            sitelists
        } = this.props;

        let topics = _.get(sitelists, ["topics"], []);

        return (
            <div className="topics-page">
                <div>
                    <HeroImage />
                </div>
                <div className="container">
                    <h1 className="text-center">{__("Browse By Topic")}</h1>
                </div>
                <div className="container">
                    {_.map("ABCDEFGHIJKLMNOPQRSTUVWXYZ", function(alpha, i) {
                        let alpha_topics = _.filter(topics, function (item) {
                            return _.startsWith(_.toUpper(item.name), alpha);
                        });
                        if (_.size(alpha_topics) === 0) {
                            return null;
                        }
                        return (
                            <div key={i}>
                                <h3>{alpha}</h3>
                                <div className="row">
                                    {_.map(alpha_topics, function(item, k) {
                                        return (
                                            <div key={k} className="col-sm-3">
                                                <a href={"/marketplace/search?q=" + JSON.stringify({label: _.get(item, ["name"]), type: "topic", value: "topic_" + _.get(item, ["id"])})}>{_.get(item, ["name"])}</a>
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

TopicsPage.propTypes = {
    sitelists: PropTypes.object
};