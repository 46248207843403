import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { gettext as __ } from "../../../../utils/Trans";
import ActionButton from "../../../../components/ActionButton";

class AddCandidateButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            onAddCandidate,
            className = "btn btn-default btn-flat"
        } = this.props;

        return (
            <div>
                <ActionButton
                    icon="plus"
                    btn_text={__("Add Presenters")}
                    help_text={__("Want more selection?  Add additional presenters to this job and send them offers.")}
                    onClick={onAddCandidate}
                    className={className}
                />
            </div>
        );
    }
}

AddCandidateButton.propTypes = {
    event: PropTypes.object.isRequired,
    className: PropTypes.string
};

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {
    return {};
};

var mapDispatchToProps = function (dispatch, ownProps) {
    return {
        onAddCandidate: function() {
            return new Promise((resolve) => {
                return resolve(dispatch({
                    type: "POSTAJOBPAGE_ADD_SPEAKERS_TO_EVENT",
                    eid: _.get(ownProps.event, ["eid"])
                }));
            }).then(() => {
                return window.location = `/s/${_.get(ownProps.whitelabel, ["bcode"])}/search`;
            });
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddCandidateButton));
