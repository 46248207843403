import _ from "lodash";
import React from "react";
import dateformat from "dateformat";
import { gettext as __ } from "../../../../utils/Trans";
import formatCurrency from "../../../../utils/formatCurrency";

class FeeLine extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            fee
        } = this.props;

        let duedate = (fee.due ? new Date(fee.due * 1000) : new Date()) || new Date();
        let paiddate = (parseInt(_.get(fee, ["payment", "last_payment","created"], 0), 10) > 0 ? new Date(parseInt(_.get(fee, ["payment", "last_payment","created"], 0), 10) * 1000) : null);
        let description = fee.description || __("No Description");
        let amount = fee.fee || 0.00;

        return (
            <tr>
                <td>
                    <div>{description}</div>
                    <div className="text-muted small">{__("Due on")}{" "}{dateformat(duedate, "mmm d, yyyy")}</div>
                </td>
                <td className="text-right">
                    {formatCurrency(amount, 2)}
                    {fee.paid &&
                    <div className="text-success small">{__("Paid")}{" "}
                        {paiddate &&
                        <span>
                            {" on "}
                            {dateformat(paiddate, "mmm d, yyyy")}
                        </span>
                        }
                    </div>
                    }
                </td>
            </tr>
        );
    }
}

export default FeeLine;