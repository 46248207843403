import _ from "lodash";
import React from "react";
import ForgotPasswordAffiliate from "./onboard/affiliate";
import ForgotPasswordDefault from "./onboard/default";

export class ForgotPasswordPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            location
        } = this.props;

        let onboard = _.get(location, ["state", "onboard"], "default");

        if (onboard === "affiliate") {
            return (
                <ForgotPasswordAffiliate {...this.props}  />
            );
        } else {
            return (
                <ForgotPasswordDefault {...this.props} />
            );
        }
    }

}

export default ForgotPasswordPage;
