import React from "react";
import { gettext as __ } from "../../../../utils/Trans";

export default class SelectToGetStarted extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        return (
            <div>
                <div style={{margin: "100px 0 0 5em"}} className="hidden-sm hidden-xs">
                    <div style={{marginBottom: "20px"}}>
                        <i
                            className="fa fa-share text-success pull-left"
                            style={{
                                transform: "scale(-1, 1)",
                                fontSize: "3em"
                            }}
                        />
                        <div className="text-muted" style={{
                            fontSize: "24px",
                            width: "175px",
                            display: "inline-block",
                            paddingLeft: "1em"
                        }}>
                            {__("select a message to continue")}
                        </div>
                    </div>
                </div>
                <div className="visible-sm visible-xs" style={{marginTop: "3em"}}>
                    <div className="row">
                        <div className="col-xs-offset-1 col-xs-10">
                            <i
                                className="fa fa-angle-double-up pull-left text-muted"
                                style={{
                                    fontSize: "3em"
                                }}
                            /> {__("Touch a message for details.")}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}