import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { gettext as __ } from "../../../../utils/Trans";
import { Message, Reason } from "../../../../components/FormComponents";
import LoadingSubmit from "../../../../components/LoadingSubmit";
import InlineError from "../../../../components/InlineError";
import ShortList from "../../../../actions/ShortList/ShortList";

const preset_reasons = {
    topics: __("Your topics weren't a perfect match for our meeting goals"),
    slow_communication: __("I needed faster/better communication from you"),
    profile: __("Your profile needs more information"),
    budget: __("Price out of budget"),
    someone_else: __("We’ve selected another speaker for this event")
};

class DismissSpeakerForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch, props) {
        let params = _.assign({}, values);
        if (_.get(params, ["reason"]) === "other") {
            _.set(params, ["message"], _.get(values, ["custom_reason"]));
        } else if (_.has(preset_reasons, _.get(params, ["reason"]))) {
            _.set(params, ["message"], _.get(preset_reasons, [_.get(params, ["reason"])]));
        } else {
            _.set(params, ["message"], _.get(params, ["reason"]));

        }
        return dispatch(ShortList.removespeaker(params))
            .then(() => {
                if (_.isFunction(props.onClose)) {
                    props.onClose();
                }
            })
            .then(() => {
                // redirect to the event, since the speaker is now gone
                // window.location.replace(toMpURL("/v3/shortlist/" + _.get(props.event, ["eid"])));
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
            speaker,
            handleSubmit,
            submitting,
            error,
            onClose
        } = this.props;

        let speaker_name = _.get(speaker, ["name"]);

        return (
            <Form role="form" onSubmit={handleSubmit(this.handleSubmit)}>
                <div className="modal-body">
                    {error && <InlineError error={_.get(error, ["message"])} />}
                    <div className="row">
                        <div className="col-sm-12">
                            <h4>{__("Dismissing ")}</h4>
                            {speaker_name}
                            <h4>{__("Reason")}</h4>
                            <div className="text-muted">
                                {__("Presenters know they won't be picked for every engagement, and they love a little feedback to help them improve.")}
                            </div>
                        </div>
                    </div>
                    <div style={{margin: "1em 1em 2em 2em"}}>
                        {_.map(preset_reasons, function(item, i) {
                            return (
                                <Field key={i} name="reason" component={Reason} type="radio" value={i} hide_errors={true} label={item} />
                            );
                        })}
                        <Field name="reason" component={Reason} type="radio" value="other" hide_errors={false} label={__("Other")}/>
                        <div style={{margin: "0em 0em 1em 1em"}}>
                            <Field
                                name="custom_reason"
                                type="textarea"
                                className="form-control"
                                placeholder={__("I am dismissing you because...")}
                                component={Message}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <LoadingSubmit is_saving={submitting} save_text={__("Dismissing...")} className="btn-warning btn-block-xs">
                        {__("Dismiss Speaker")}
                    </LoadingSubmit>
                    {" "}
                    <div className="btn btn-default btn-block-xs" disabled={submitting} onClick={onClose}>
                        {__("Nevermind")}
                    </div>
                </div>
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

DismissSpeakerForm.propTypes = {
    event: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    speaker: PropTypes.object.isRequired,
    shortlist_speaker: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

function mapStateToProps(state, ownProps) {
    return {
        initialValues: {
            custom_reason: "",
            reason: null,
            message: null,
            id: _.get(ownProps.shortlist_speaker, ["id"]),
            mp_shortlist_id: _.get(ownProps.shortlist_speaker, ["mp_shortlist_id"]),
            eid: _.get(ownProps.event, ["eid"])
        }
    };
}

function mapDispatchToProps() {
    return {

    };
}

DismissSpeakerForm = reduxForm({
    form: "shortlist_page_dismiss_speaker",
    validate: function(values) {
        const errors = {};
        if (!values.reason) {
            errors.reason = __("Please select a reason why you are dismissing this speaker.");
        }
        if (values.reason === "other" && !values.message) {
            errors.message = __("Please enter a reason why you are dismissing this speaker.");
        }
        return errors;
    }
})(DismissSpeakerForm);

export default connect(mapStateToProps, mapDispatchToProps)(DismissSpeakerForm);