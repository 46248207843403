import _ from "lodash";
import xhr from "xhr";
import toShortlistURL from "../../utils/toShortlistURL";
import phpEncode from "urlencode-for-php";

/* THIS_IS_FOR_MPI don't remove this comment, it's so one can quickly find all the MPI custom code */
/** Any changes to this visible to the consumer should be reported in advance to:
 * achild@mpiweb.org
 * mcrumrine@mpiweb.org
 *
 *
 */

export default function(params){

    let key = "mpiSignIn";

    return function(dispatch, getState){//This is a `thunk` that "redux-thunk" handles
        var state = getState();
        if(_.get(state, ["balboa3", key, "waiting"]) === true){
            //already running
            return;
        }
        dispatch({
            type: "BALBOA3_ACTION_WAITING",
            key: key,
            timestamp: _.now()
        });
        xhr({
            method: "POST",
            url: toShortlistURL("/ssompi/login"),
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            },
            body: phpEncode({
                username: params.username,
                password: params.password
            })
        }, function(err, resp, body){
            if(!err){
                var data = body;
                if(_.isString(body)){
                    try{
                        data = JSON.parse(body);
                    }catch(err){
                        data = body;
                    }
                }
                if(!_.has(data, "success") || (data.success !== true)){
                    var err_msg = _.isString(data && data.message) && data.message.length > 0
                        ? data.message
                        : "unknown cause";
                    err = new Error(err_msg);
                }
            }


            if(!err){
                //easiest/most reliable way to start the session
                window.location.reload();
                return;
            }
            dispatch({
                type: "BALBOA3_ACTION_RESPONSE",
                key: key,
                error: err,
                timestamp: _.now()
            });
        });
    };
};
