import _ from "lodash";

export default function(state = {}, action){
    switch(action.type){
        case "SPEAKER_COURSES": {
            let new_state = _.cloneDeep(state);
            let id = action.id;
            _.setWith(new_state, [id], action.data, Object);
            return new_state;
        }
        default:
            return state;
    }
}
