import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import MessageAllModal from "../../modals/MessageAllModal";

class MessageAllButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_modal: false
        };
    }

    __toggleModal = () => {
        this.setState({
            show_modal: !this.state.show_modal
        });
    }

    render() {
        let {
            className = "btn btn-default btn-sm Shortlist-msg-all"
        } = this.props;

        return (
            <div>
                <div
                    className={className}
                    onClick={this.__toggleModal}
                >
                    {__("Message all")}
                </div>
                {this.state.show_modal && <MessageAllModal {...this.props} onClose={this.__toggleModal} />}
            </div>
        );
    }
}

MessageAllButton.propTypes = {
    event: PropTypes.object.isRequired,
    className: PropTypes.string
};

export default MessageAllButton;