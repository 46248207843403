import _ from "lodash";
import phpEncode from "urlencode-for-php";
import Oauth from "./Oauth";
import Balboa from "../../Global/Balboa";
import {sessionService} from "redux-react-session";
import Constants from "../../../utils/Constants";

const client_id = "391868415854-iogcbfdp7b45p7ftnt8as4iktt0rdemd.apps.googleusercontent.com";
// const secret    = 'hrkoFM0yCVQyTrXANKb9sOrO';

export default _.assign({}, Oauth, {

    signin: function(code, state, callback_url) {
        let self = this;
        return (dispatch, getState) => {
            let key = ["Google_Oauth"];
            return self.exchange(code, callback_url)
                .then((access_token) => {
                    return self.profile(access_token);
                })
                .then((profile) => {
                    let params = {
                        service: "google",
                        state: state,
                        access_token: _.get(profile, ["access_token"]),
                        id: _.get(profile, ["id"]),
                        first_name: _.get(profile, ["given_name"]),
                        last_name: _.get(profile, ["family_name"]),
                        email: _.get(profile, ["email"]),
                        photo_url: _.get(profile, ["picture"]),
                        profile_url: _.get(profile, ["link"]),
                        gender: _.get(profile, ["gender"]),
                        auth_only_user_type: 'buyer'
                    };

                    return Balboa(dispatch, getState)
                        .post(key, "/oauth2/signinoauth", params, {cache_ttl: -1})
                        .then(function(response) {
                            dispatch({
                                type: "BALBOA3_CLEAR_KEY",
                                key: key
                            });
                            return response;
                        })
                        .then(function(response) {
                            sessionService.saveSession(_.get(response, ["data", "token"]));
                            sessionService.saveUser(_.assign({}, _.get(response, ["data", "user"]), {token: _.get(response, ["data", "token"])}));
                            return response;
                        });
                });
        };
    },

    /**
     * Convert the initiate token into an authorization token
     * @param params
     * @returns {string}
     */
    initiate: function(redirect_url, whitelabel, lang) {
        let params = {
            response_type: "token",
            access_type: "online",
            client_id: client_id,
            redirect_uri: this.getCallbackUrl("google", whitelabel, lang),
            state: this.getOauthState(_.get(whitelabel, ["bid"], Constants.TRUNK_BUREAU_ID), redirect_url),
            scope: "profile email"
        };
        return "https://accounts.google.com/o/oauth2/v2/auth?" + phpEncode(params);
    },

    /**
     * Convert the initiate token into an authorization token
     * @param callback_url
     * @param code
     * @returns {Promise}
     */
    exchange: function(access_token/*, callback_url*/) {
        let default_params = {
            access_token: access_token
        };

        return fetch(
            "https://www.googleapis.com/oauth2/v3/tokeninfo",
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded"
                },
                body: phpEncode(default_params),
            })
            .then(() => {
                return access_token;
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    },

    /**
     * Shortcut to either add or update a record based on whether the id is > 0
     * @param params
     * @returns {Function}
     */
    profile: function(access_token) {
        return fetch(
            "https://www.googleapis.com/oauth2/v1/userinfo",
            {
                method: "GET",
                headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                    "Authorization": "Bearer " + access_token
                }
            })
            .then((resp) => {
                return resp.json();
            })
            .then((json) => {
                return _.assign({}, json, {access_token: access_token});
            })
            .catch((err) => {
                console.log(err);
                throw err;
            });
    }
});