import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import PauseEventModal from "../../modals/PauseEventModal";
import ActionButton from "../../../../components/ActionButton";

class PauseEventButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_modal: false
        };
    }

    __toggleModal = () => {
        this.setState({
            show_modal: !this.state.show_modal
        });
    }

    render() {
        let {
            className = "btn btn-default btn-flat"
        } = this.props;

        return (
            <div>
                <ActionButton
                    icon="pause-circle-o"
                    btn_text={__("Pause this Job")}
                    help_text={__("Need to slow things down? Put this job on hold and we will let candidates know you do not want to communicate about it right now.")}
                    onClick={this.__toggleModal}
                    className={className}
                />
                {this.state.show_modal && <PauseEventModal {...this.props} onClose={this.__toggleModal} />}
            </div>
        );
    }
}

PauseEventButton.propTypes = {
    event: PropTypes.object.isRequired,
    className: PropTypes.string
};

export default PauseEventButton;