import React from "react";
import { Panel } from "react-bootstrap";
import { gettext as __ } from "../../../../utils/Trans";

export default class AccessDenied extends React.Component {

    render() {
        return (
            <div className="text-center" style={{margin:"40px 0px"}}>
                <Panel>
                    <Panel.Heading>
                        <Panel.Title><i className="fa fa-exclamation-triangle"/>{" "}{__("Oops!  Looks like you don't have permission to view this shortlist")}</Panel.Title>
                    </Panel.Heading>
                    <Panel.Body>
                        <p>
                            You are trying to access a shortlist that is no longer available or that you do not
                            have permission to view.
                        </p>
                        <p>Ask the shortlist owner to add you as a collaborator and try again.</p>
                    </Panel.Body>
                </Panel>
            </div>
        );
    }

}