import _ from "lodash";

export default function (state = {}, action) {
    switch (action.type) {
        // Event Handlers
        case "BUYER_EVENT_CREATE": {
            let eid = _.get(action, ["eid"], -1);
            let new_state = _.cloneDeep(state);
            _.set(new_state, [eid, "event"], action.data);
            if (!_.isUndefined(_.get(action.data, ["MPAgreement"]))) {
                _.setWith(new_state, [eid, "agreement"], _.get(action.data, ["MPAgreement"]), Object);
            }
            if (!_.isUndefined(_.get(action.data, ["MPServices"]))) {
                let total = _.reduce(_.get(action.data, ["MPServices"]), function (sum, item) {
                    return sum + _.get(item, ["fee"], 0);
                }, 0);
                let all_paid = _.reduce(_.get(action.data, ["MPServices"]), function (paid, item) {
                    return paid && _.get(item, ["paid"], false);
                }, true);

                let payment = {
                    success: true,
                    v: "3.1",
                    services: _.get(action.data, ["MPServices"]),
                    allpaid: all_paid,
                    total: total
                };
                _.setWith(new_state, [eid, "payment"], payment, Object);
            }
            return _.assign({}, state, new_state);
        }
        case "BUYER_EVENT_UPDATE": {
            let eid = _.get(action, ["eid"], -1);
            let new_state = _.cloneDeep(state);
            _.setWith(new_state, [eid, "event"], action.data, Object);
            if (!_.isUndefined(_.get(action.data, ["MPAgreement"]))) {
                _.setWith(new_state, [eid, "agreement"], _.get(action.data, ["MPAgreement"]), Object);
            }
            if (!_.isUndefined(_.get(action.data, ["MPServices"]))) {
                let total = _.reduce(_.get(action.data, ["MPServices"]), function (sum, item) {
                    return sum + _.get(item, ["fee"], 0);
                }, 0);
                let all_paid = _.reduce(_.get(action.data, ["MPServices"]), function (paid, item) {
                    return paid && _.get(item, ["paid"], false);
                }, true);

                let payment = {
                    success: true,
                    v: "3.1",
                    services: _.get(action.data, ["MPServices"]),
                    allpaid: all_paid,
                    total: total
                };
                _.setWith(new_state, [eid, "payment"], payment, Object);
            }
            return _.assign({}, state, new_state);
        }
        case "BUYER_EVENT_DELETE": {
            let eid = _.get(action, ["eid"], -1);
            let new_state = _.cloneDeep(state);
            _.unset(new_state, [eid]);
            return new_state;
        }
        case "BUYER_EVENT_LOAD": {
            let new_state = _.cloneDeep(state);
            _.forEach(action.data, function (item, key) {
                _.setWith(new_state, [key, "event"], item, Object);
                if (!_.isUndefined(_.get(item, ["MPAgreement"]))) {
                    _.setWith(new_state, [key, "agreement"], _.get(item, ["MPAgreement"]), Object);
                }
                if (!_.isUndefined(_.get(item, ["MPServices"]))) {
                    let total = _.reduce(_.get(item, ["MPServices"]), function (sum, service) {
                        return sum + _.get(service, ["fee"], 0);
                    }, 0);
                    let all_paid = _.reduce(_.get(item, ["MPServices"]), function (paid, service) {
                        return paid && _.get(service, ["paid"], false);
                    }, true);

                    let payment = {
                        success: true,
                        v: "3.1",
                        services: _.get(item, ["MPServices"]),
                        allpaid: all_paid,
                        total: total
                    };
                    _.setWith(new_state, [key, "payment"], payment, Object);
                }
            });
            return _.assign({}, state, new_state);
        }
        // Agreement Handlers
        case "BUYER_EVENT_AGREEMENT_CREATE": {
            let eid = _.get(action, ["eid"], -1);
            let new_state = _.cloneDeep(state);
            _.set(new_state, [eid, "agreement"], action.data);
            return _.assign({}, state, new_state);
        }
        case "BUYER_EVENT_AGREEMENT_UPDATE": {
            let eid = _.get(action, ["eid"], -1);
            let new_state = _.cloneDeep(state);
            let sid = _.get(action, ["sid"], -1);
            _.setWith(new_state, [eid, "agreement", sid], action.data, Object);
            return _.assign({}, state, new_state);
        }
        case "BUYER_EVENT_AGREEMENT_DELETE": {
            return _.assign({}, state, _.without(state, action.eid));
        }
        case "BUYER_EVENT_AGREEMENT_LOAD": {
            let eid = _.get(action, ["eid"], -1);
            let new_state = _.cloneDeep(state);
            _.setWith(new_state, [eid, "agreement"], {}, Object);
            _.forEach(action.data, function (item, key) {
                _.setWith(new_state, [eid, "agreement", key], item, Object);
            });
            return _.assign({}, state, new_state);
        }
        // Services Handlers
        case "BUYER_EVENT_SERVICES_CREATE": {
            let eid = _.get(action, ["eid"], -1);
            let new_state = _.cloneDeep(state);
            _.set(new_state, [eid, "services"], action.data);
            return _.assign({}, state, new_state);
        }
        case "BUYER_EVENT_SERVICES_UPDATE": {
            let eid = _.get(action, ["eid"], -1);
            let new_state = _.cloneDeep(state);
            let sid = _.get(action, ["sid"], -1);
            _.setWith(new_state, [eid, "services", sid], action.data, Object);
            return _.assign({}, state, new_state);
        }
        case "BUYER_EVENT_SERVICES_DELETE": {
            return _.assign({}, state, _.without(state, action.eid));
        }
        case "BUYER_EVENT_SERVICES_LOAD": {
            let eid = _.get(action, ["eid"], -1);
            let new_state = _.cloneDeep(state);
            _.setWith(new_state, [eid, "services"], {}, Object);
            _.forEach(action.data, function (item, key) {
                _.setWith(new_state, [eid, "services", key], item, Object);
            });
            return _.assign({}, state, new_state);
        }
        // Payment Handlers
        case "BUYER_EVENT_PAYMENT_CREATE": {
            let eid = _.get(action, ["eid"], -1);
            let new_state = _.cloneDeep(state);
            _.set(new_state, [eid, "payment"], action.data);
            return _.assign({}, state, new_state);
        }
        case "BUYER_EVENT_PAYMENT_UPDATE": {
            let eid = _.get(action, ["eid"], -1);
            let new_state = _.cloneDeep(state);
            _.setWith(new_state, [eid, "payment"], action.data, Object);
            return _.assign({}, state, new_state);
        }
        case "BUYER_EVENT_PAYMENT_DELETE": {
            return _.assign({}, state, _.without(state, action.eid));
        }
        case "BUYER_EVENT_PAYMENT_LOAD": {
            let eid = _.get(action, ["eid"], -1);
            let new_state = _.cloneDeep(state);
            _.forEach(action.data, function (item, key) {
                _.setWith(new_state, [eid, "payment", key], item, Object);
            });
            return _.assign({}, state, new_state);
        }
        // Review Handlers
        case "BUYER_EVENT_REVIEW_CREATE": {
            let eid = _.get(action, ["eid"], -1);
            let new_state = _.cloneDeep(state);
            _.set(new_state, [eid, "recommendation", action.sid], action.data);
            return _.assign({}, state, new_state);
        }
        case "BUYER_EVENT_REVIEW_UPDATE": {
            let eid = _.get(action, ["eid"], -1);
            let new_state = _.cloneDeep(state);
            _.setWith(new_state, [eid, "recommendation", action.sid], action.data, Object);
            return _.assign({}, state, new_state);
        }
        case "BUYER_EVENT_REVIEW_DELETE": {
            return _.assign({}, state, _.without(state, action.eid, action.sid));
        }
        case "BUYER_EVENT_REVIEW_LOAD": {
            let eid = _.get(action, ["eid"], -1);
            let new_state = _.cloneDeep(state);
            if (!_.isEmpty(action.data)) {
                _.setWith(new_state, [eid, "recommendation", action.sid], action.data, Object);
            }
            return _.assign({}, state, new_state);
        }
        case "BUYER_EVENT_INVOICE_LINK": {
            let eid = _.get(action, ["eid"], -1);
            let new_state = _.cloneDeep(state);
            _.setWith(new_state, [eid, "shared", "invoice"], action.data);
            return _.assign({}, state, new_state);
        }
        default:
            return state;
    }
}
