import _ from "lodash";

export default function (state = {}, action) {
    switch (action.type) {
        case "EMBEDDED_SHORTLIST_ADD": {
          let sids = _.isArray(state.sids) ? state.sids : [];
          return _.assign({}, state, {
            sids: _.union(sids, [action.sid]),
            lookatme: true
          });
        }
        case "EMBEDDED_SHORTLIST_ADD_ALL": {
          let sids = _.isArray(state.sids) ? state.sids : [];
          if (_.isArray(action.sids)) {
            return _.assign({}, state, {
              sids: _.union(sids, action.sids),
              lookatme: true
            });
          } else {
            return _.assign({}, state, {
              sids: _.union(sids, [action.sids]),
              lookatme: true
            });
          }
        }
        case "EMBEDDED_SHORTLIST_LOAD": {
          return _.assign({}, state, {
            sids: action.sids,
            lookatme: false
          });
        }
        case "EMBEDDED_SHORTLIST_REMOVE": {
          let sids = _.isArray(state.sids) ? state.sids : [];
          return _.assign({}, state, {
            sids: _.without(sids, action.sid)
          });
        }
        case "EMBEDDED_SHORTLIST_CLEAR": {
          return _.assign({}, state, {
            sids: []
          });
        }
        case "EMBEDDED_SHORTLIST_CLEAR_LOOKATME": {
          return _.assign({}, state, {
            lookatme: false
          });
        }
        default:
          return state;
    }
}