import _ from "lodash";
import React from "react";
import moment from "moment";
import { Button } from "react-bootstrap";
import DateRangePicker from "react-bootstrap-daterangepicker";

// import "bootstrap-daterangepicker/daterangepicker.scss";

class DateRangePickerField extends React.Component {

    constructor (props) {
        super(props);

        let dates = _.map(_.split(_.get(props, ["input", "value"]), "-"), (date) => {
            if (moment(_.trim(date), _.get(props, ["dateFormat"], "MM/DD/YYYY")).isValid()) {
                return moment(_.trim(date), _.get(props, ["dateFormat"], "MM/DD/YYYY"));
            } else {
                return moment();
            }
        });

        this.state = {
            value: props.input.value || "",
            startDate: props.startDate || _.head(dates).format(_.get(props, ["dateFormat"], "MM/DD/YYYY")),
            endDate: props.endDate || _.last(dates).format(_.get(props, ["dateFormat"], "MM/DD/YYYY")),
            opens: props.opens || "right",
            dateFormat: props.dateFormat || "MM/DD/YYYY"
        };
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (_.get(nextProps, ["input", "value"]) !== _.get(this.props, ["input", "value"])) {
            let dates = _.map(_.split(_.get(nextProps, ["input", "value"]), "-"), (date) => {
                if (moment(_.trim(date), _.get(nextProps, ["dateFormat"], "MM/DD/YYYY")).isValid()) {
                    return moment(_.trim(date), _.get(nextProps, ["dateFormat"], "MM/DD/YYYY"));
                } else {
                    return moment();
                }
            });
            this.setState({
                value: _.get(nextProps, ["input", "value"]),
                startDate: _.head(dates).format(_.get(nextProps, ["dateFormat"], "MM/DD/YYYY")),
                endDate: _.last(dates).format(_.get(nextProps, ["dateFormat"], "MM/DD/YYYY"))
            });
        }
    }

    __handleEvent = (event, picker) => {
        let value = this.state.value;

        if (picker.startDate.format(this.state.dateFormat) !== picker.endDate.format(this.state.dateFormat)) {
            value = picker.startDate.format(this.state.dateFormat) + " - " + picker.endDate.format(this.state.dateFormat);
        } else {
            value = picker.startDate.format(this.state.dateFormat);
        }

        this.setState({
            value: value,
            startDate: picker.startDate,
            endDate: picker.endDate
        }, () => {
            if (_.isFunction(this.props.input.onChange)) {
                this.props.input.onChange(this.state.value);
            }
        });
    }

    __handleChange = (e) => {
        let value = e.target.value;

        if (value !== "") {
            let date_parts = _.split(value, " - ");
            let startDate = moment(date_parts[0], this.state.dateFormat).isValid() ? moment(date_parts[0], this.state.dateFormat) : moment();
            let endDate = moment(date_parts[0], this.state.dateFormat).isValid() ? moment(date_parts[0], this.state.dateFormat) : moment();
            if (_.get(date_parts, [1], false)) {
                endDate = moment(date_parts[1], this.state.dateFormat).isValid() ? moment(date_parts[1], this.state.dateFormat) : moment();
            }

            this.setState({
                value: value,
                startDate: startDate,
                endDate: endDate
            }, () => {
                if (_.isFunction(this.props.input.onChange)) {
                    this.props.input.onChange(this.state.value);
                }
            });
        } else {
            this.setState({
                value: "",
                startDate: moment(),
                endDate: moment()
            }, () => {
                if (_.isFunction(this.props.input.onChange)) {
                    this.props.input.onChange(this.state.value);
                }
            });
        }
    }

    render() {
        let {
            startDate,
            endDate
        } = this.state;

        let {
            input,
            ranges,
            placeholder,
            label,
            help_text,
            type = "button",
            meta: {
                touched,
                error,
                warning
            }
        } = this.props;

        if (_.isUndefined(ranges)) {
            ranges = {
                "Today": [moment(), moment()],
                "Yesterday": [moment().subtract(1, "days"), moment().subtract(1, "days")],
                "Last 7 Days": [moment().subtract(6, "days"), moment()],
                "Last 30 Days": [moment().subtract(29, "days"), moment()],
                "This Month": [moment().startOf("month"), moment().endOf("month")],
                "Last Month": [moment().subtract(1, "month").startOf("month"), moment().subtract(1, "month").endOf("month")]
            };
        }

        let inputProps = _.assign({}, this.props, {
            startDate: startDate,
            endDate: endDate,
            dateFormat: this.state.dateFormat,
            placeholder: placeholder,
            ranges: ranges,
            onApply: this.__handleEvent,
            containerStyles: {
                display: "block"
            }
        });

        if (_.has(this.props, ["ranges"]) && !this.props.ranges && _.has(inputProps, ["ranges"])) {
            _.unset(inputProps, ["ranges"]);
        }

        return (
            <div className={"form-group " + (touched && error ? "has-error " : touched && warning ? "has-warning " : "")}>
                {label && <label className="control-label">{label}</label>}
                <DateRangePicker {...inputProps}>
                    {type === "button" &&
                        <Button className="form-control btn btn-flat" style={{
                            width: "100%",
                            overflow: "hidden",
                            position: "relative"
                        }} disabled={this.props.disabled}>
                            <div className="pull-right"><i className="fa fa-calendar"/></div>
                            {" "}
                            <span style={{marginRight: "10px"}}>
                                {this.state.value}
                                {!this.state.value && <span className="text-muted">{placeholder}</span>}
                            </span>
                        </Button>
                    }
                    {type === "text" &&
                        <input type="text" value={this.state.value} onChange={this.__handleChange} placeholder={placeholder} className="form-control" style={{
                            width: "100%",
                            overflow: "hidden",
                            position: "relative"
                        }} disabled={this.props.disabled} />
                    }
                </DateRangePicker>
                {help_text && <div className="help-block">{help_text}</div>}
                {touched &&
                ((error && <div className="text-danger"><i className="fa fa-times" /> {error}</div>) ||
                (warning && <div className="text-danger"><i className="fa fa-exclamation-triangle" /> {warning}</div>))}
            </div>
        );
    }
}

export default DateRangePickerField;