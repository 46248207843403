import React from "react";
import PropTypes from "prop-types";

class DollarCircle extends React.PureComponent {

    render() {
        let {
            backgroundColor
        } = this.props;

        return (
            <div style={{
                position: "absolute",
                left: "-12px",
                width: "21px",
                height: "21px",
                fontSize: "10px",
                lineHeight: "18px",
                textAlign: "center",
                border: "2px solid #E9E9E9",
                borderRadius: "21px",
                color: "#FFF",
                backgroundColor: backgroundColor
            }}>$</div>
        );
    }
};

DollarCircle.propTypes = {
  backgroundColor: PropTypes.string
};

export default DollarCircle;