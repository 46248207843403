import React from "react";
import { Route } from "react-router";
import ErrorLayout from "../ErrorLayout";

export default class ErrorLayoutRoute extends React.Component {

    render() {
        return (
            <ErrorLayout {...this.props}>
                <Route {...this.props} />
            </ErrorLayout>
        );
    }

}