import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Field, Form, reduxForm } from "redux-form";
import { Col, Modal, Row } from "react-bootstrap";
import { gettext as __ } from "../../../../utils/Trans";
import LoadingSubmit from "../../../../components/LoadingSubmit";
import { Message, Selectize } from "../../../../components/FormComponents";
import MpCollaborator from "../../../../actions/ShortList/MpCollaborator";

class ShareShortlistForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch, props) {
        let params = _.assign({}, values);
        // convert the permission array to a single value
        _.set(params, ["permission"], _.reduce(_.get(params, ["permission"]), function(accum, value, key) {
            if (value) {
                return accum | key;
            }
            return accum;
        }, 0));

        return dispatch(MpCollaborator.save(params))
            .then((resp) => {
                return dispatch({
                    type: "AFFILIATE_EVENT_COLLABORATOR_POST",
                    id: _.get(props.affiliate_event, ["id"]),
                    data: _.get(resp, ["data"])
                });
            })
            .then(() => {
                if (_.isFunction(props.onClose)) {
                    props.onClose();
                }
            });
    }

    __onChangePermission = (e) => {
        if (e.currentTarget.name === "permission[8]") {
            this.props.change("permission[8]", false);
            this.props.change("permission[6]", true);
            this.props.change("permission[1]", true);
        } else if (e.currentTarget.name === "permission[6]") {
            this.props.change("permission[8]", false);
            this.props.change("permission[6]", true);
            this.props.change("permission[1]", true);
        } else if (e.currentTarget.name === "permission[1]") {
            this.props.change("permission[8]", false);
            this.props.change("permission[6]", false);
            this.props.change("permission[1]", true);
            e.preventDefault();
        }
    }

    render() {
        let {
            collaborator,
            submitting,
            handleSubmit,
            onClose
        } = this.props;

        return (
            <Form onSubmit={handleSubmit(this.handleSubmit)}>
                <Modal.Body>
                    <div className="help-block">{__("You can share this shortlist with your colleagues and other collaborators.  Just enter their email address and a brief note.")}</div>
                    <hr />
                    <Field
                        name="to"
                        component={Selectize}
                        creatable={true}
                        multi={true}
                        options={[]}
                        label={__("Share with email address:")}
                    />
                    <Field
                        name="message"
                        component={Message}
                        rows={5}
                        label={__("Please enter a note to include with the invitation:")}
                    />
                    <p>
                        <b>Select the permissions you would like to grant to these collaborators:</b>
                    </p>
                    <Row>
                        <Col sm={4} className="text-center">
                            <label style={{fontWeight:"normal"}}>
                                <Field
                                    disabled={((_.get(collaborator, ["permission"], 0) & 1) === 0)}
                                    name="permission[1]"
                                    component="input"
                                    type="checkbox"
                                    value="1"
                                    onChange={this.__onChangePermission}
                                />
                                {" "}Review Speakers
                            </label>
                        </Col>
                        <Col sm={4} className="text-center">
                            <label style={{fontWeight:"normal"}}>
                                <Field
                                    disabled={((_.get(collaborator, ["permission"], 0) & 6) === 0)}
                                    name="permission[6]"
                                    component="input"
                                    type="checkbox"
                                    value="6"
                                    onChange={this.__onChangePermission}
                                />
                                {" "}Add/Remove Speakers
                            </label>
                        </Col>
                        <Col sm={4} className="text-center">
                            <label style={{fontWeight:"normal"}}>
                                <Field
                                    disabled={((_.get(collaborator, ["permission"], 0) & 8) === 0)}
                                    name="permission[8]"
                                    component="input"
                                    type="checkbox"
                                    value="8"
                                    onChange={this.__onChangePermission}
                                />
                                {" "}Create Event
                            </label>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <LoadingSubmit is_saving={submitting} save_text={__("Sending Invitation(s)...")} className="btn-success btn-block-xs">
                        {__("Send Invitation(s)")}
                    </LoadingSubmit>
                    {" "}
                    <div className="btn btn-default btn-block-xs" disabled={submitting} onClick={onClose}>
                        {__("Not Yet")}
                    </div>
                </Modal.Footer>
            </Form>

        );
    }
}

ShareShortlistForm = reduxForm({
    form: "referral_share_shortlist_form",
    validate: function(values) {
        const errors = {};
        if (!values.message) {
            errors.message = "Please enter a message to send to your collaborators";
        }
        if (!values.permission) {
            errors.permission = "Please select at least one permission for your collaborators";
        }
        if (!values.permission) {
            errors.permission = "Please select at least one permission for your collaborators";
        }
        return errors;
    }
})(ShareShortlistForm);

function mapStateToProps(state, ownProps) {
    return {
        initialValues: {
            mp_shortlist_id: _.get(ownProps.shortlist, ["id"]),
            affiliate_event_id: _.get(ownProps.affiliate_event, ["id"]),
            bureau_id: _.get(ownProps.whitelabel, ["bid"]),
            permission: {
                "1": true
            }
        }
    };
}

function mapDispatchToProps(/*dispatch, ownProps*/) {
    return {};
}

export default connect(mapStateToProps, mapDispatchToProps)(ShareShortlistForm);