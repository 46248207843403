import _ from "lodash";
import Rest from "../Global/Rest";
import Balboa from "../Global/Balboa";

export default _.assign({}, Rest, {

    /**
     * Retrieves a single record.
     * @param params
     * @returns {Function}
     */
    get: function(id, params) {
        let key = ["getSpeakerAvailability", id];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/marketplace/speaker-availability/" + id, params)
                    .then((response) => {
                        dispatch({
                            type: "SPEAKER_AVAILABILITY",
                            id: id,
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
                    .catch(function (err) {
                        if (err.name.includes("SpeakerMissingException")) {
                            throw err;
                        } else if (err.name === "AuthorizationException") {
                            dispatch({
                                type: "ERROR_CREATE",
                                source: _.join(key, "_"),
                                error: err
                            });
                            throw err;
                        }
                    })
            );
        };
    },

    /**
     * Retrieves a single record.
     * @param params
     * @returns {Function}
     */
    find: function(params) {
        let key = ["getSpeakerAvailability"];
        let self = this;
        return function(dispatch, getState) {
            return self.api(
                dispatch,
                key,
                Balboa(dispatch, getState)
                    .get(key, "/marketplace/speaker-availability", params)
                    .then((response) => {
                        dispatch({
                            type: "SPEAKER_AVAILABILITY_ALL",
                            data: _.get(response, ["data"])
                        });
                        return response;
                    })
                    .catch(function (err) {
                        if (err.name.includes("SpeakerMissingException")) {
                            throw err;
                        } else if (err.name === "AuthorizationException") {
                            dispatch({
                                type: "ERROR_CREATE",
                                source: _.join(key, "_"),
                                error: err
                            });
                            throw err;
                        }
                    })
            );
        };
    }
});