import React from "react";
import _ from "lodash";
// import { Label } from "react-bootstrap";
import {gettext as __} from "../../../../../utils/Trans";

export default class VirtualPrograms extends React.Component {
  state = {
    virtual_programs: [],
    speaker: null,
  }

  static getDerivedStateFromProps = (props) => ({
    speaker: props.speaker,
    virtual_programs: _.filter(_.get(props.speaker, ["programs"]), (program) => (_.get(program, "presenter_type") & 128))
  });


  __onShowTab = () => {
    if (_.isFunction(this.props.onShowTab)) {
      return this.props.onShowTab("programs");
    }
  };

  render() {
    let {
          virtual_programs,
          // speaker,
        } = this.state;
    return (
      (_.isEmpty(virtual_programs)) ? null : <React.Fragment>
        <h4 className="header legend">{__(" Virtual Programs")}</h4>
        <p className="text-muted">
          These programs are virtual-ready for live delivery over video to your audience.
        </p>
        <ul style={{listStyle:"none"}}>
          {_.map(virtual_programs, (program, i) => (<li key={i} style={{marginLeft:"-4.0rem"}}>
            <h5 className="pull-left" style={{marginBottom: "0.1rem", fontWeight: "bold"}}>&ldquo;{_.get(program, ["title"])}&rdquo; </h5>
            {/*<div className="pull-left">*/}
            {/*  {_.map(_.get(program, ["tags"]), (tag, j) => (<React.Fragment key={j}><span style={{marginRight:"1rem"}} className="badge">{_.get(tag, ["DESCRIPTION"])}</span></React.Fragment>))}*/}
            {/*</div>*/}
            <span style={{display: "inline-block", marginTop: "0.95rem", paddingLeft: "2rem", cursor: "pointer"}} className="small text-primary" onClick={this.__onShowTab}>details »</span>
            <br style={{clear: "both"}}/>
          </li>))}
        </ul>
      </React.Fragment>
    );
  }
}