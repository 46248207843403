import Balboa from "../Global/Balboa";
import {gettext as __} from "../../utils/Trans";

export default function (eid, sid, use_agreement) {
    return (dispatch, getState) => {
        var key = ["sendInvoiceToBuyer", eid, sid];

        return Balboa(dispatch, getState)
            .post(key, "/mpagreement/sendinvoicetobuyer/" + eid, {
                speaker_id: sid,
                use_agreement: use_agreement
            })
            .then(function (response) {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            })
            .then(function (response) {
                dispatch({
                    type: "NOTIFICATION_CREATE",
                    message: __("Invoice Sent!  Check your inbox for your invoice to include with your paper check.")
                });
                return response;
            });
    };
};
