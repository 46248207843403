import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import dateformat from "dateformat";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { gettext as __ } from "../../../../utils/Trans";
import { Initials } from "../../../../components/FormComponents";
import LoadingSubmit from "../../../../components/LoadingSubmit";
import InlineError from "../../../../components/InlineError";
import acceptOffer from "../../../../actions/ShortList/acceptOffer";

class AcceptOfferForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch, props) {
        return dispatch(acceptOffer(values))
            .then(() => {
                if (_.isFunction(props.onClose)) {
                    props.onClose();
                }
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
            initial_name,
            initial_date,
            handleSubmit,
            submitting,
            error,
            is_espeakers,
            onClose
        } = this.props;

        return (
            <Form role="form" onSubmit={handleSubmit(this.handleSubmit)}>
                <div className="modal-body">
                    {error && <InlineError error={_.get(error, ["message"])} />}
                    <div className="row">
                        <div className="col-sm-12">
                            {(is_espeakers) || <div className="help-block instruction">{__("Payment services are powered by eSpeakers.com.")}</div>}
                            <p>
                                {__("This offer becomes my agreement with the speaker, and I agree to pay the deposit and balance on schedule.")}
                            </p>
                            <p>
                                {__("This transaction is covered by the")}{" "}<b><a target="_blank" href="https://www.espeakers.com/legal/marketplace-terms-and-conditions/" rel="noopener noreferrer">{__("eSpeakers Marketplace Guarantee")}</a></b>.
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12">
                            <p>
                                {__("By clicking \"ACCEPT OFFER\", I agree to the above and the")}{" "}<a target="_blank" href="https://www.espeakers.com/legal/marketplace-terms-and-conditions/" rel="noopener noreferrer">{__("Terms of Service")}</a>.
                            </p>
                        </div>
                    </div>
                    <Field
                        name="initials"
                        type="text"
                        className="form-control"
                        placeholder="XXX"
                        component={Initials}
                        initial_date={initial_date}
                        initial_name={initial_name}
                    />
                </div>
                <div className="modal-footer">
                    <LoadingSubmit is_saving={submitting} save_text={__("Accepting...")} className="btn-success btn-block-xs">
                        {__("Accept Offer")}
                    </LoadingSubmit>
                    {" "}
                    <div className="btn btn-default btn-block-xs" disabled={submitting} onClick={onClose}>
                        {__("Not Yet")}
                    </div>
                </div>
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

AcceptOfferForm.propTypes = {
    user: PropTypes.object
};

function mapStateToProps(state, ownProps) {
    return {
        initial_name: _.get(ownProps.user, ["firstname"]) + " " + _.get(ownProps.user, ["lastname"]),
        initial_date: dateformat(new Date(), "m/dd/yyyy"),
        initialValues: {
            initial_name: (_.get(ownProps.user, ["firstname"]) + " " + _.get(ownProps.user, ["lastname"])),
            initial_date: dateformat(new Date(), "m/dd/yyyy"),
            initials: "",
            event_id: _.get(ownProps.event, ["eid"]),
            speaker_id: _.get(ownProps.speaker, ["sid"])
        }
    };
}

function mapDispatchToProps() {
    return {

    };
}

AcceptOfferForm = reduxForm({
    form: "shortlist_page_accept_offer",
    validate: function(values) {
        const errors = {};
        if (!values.initials) {
            errors.initials = __("Please initial your acceptance of this agreement.");
        }
        return errors;
    }
})(AcceptOfferForm);

export default connect(mapStateToProps, mapDispatchToProps)(AcceptOfferForm);