import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { sessionService } from "redux-react-session";
import User from "../../../../actions/User/User";
import AffiliateEvent from "../../../../actions/Affiliate/AffiliateEvent";
import queryString from "query-string";
import CookiePostAJob from "../../../../utils/CookiePostAJob";
import { gettext as __ } from "../../../../utils/Trans";

let img_gif = require("./sending_speakers.gif");

class PostAJobAffiliate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (_.isFunction(this.props.authWithAffiliateLead)) {
            this.props.authWithAffiliateLead();
        }
    }

    render() {
        return (
            <div>
                <div>
                    <div className="text-center">
                        <h2>{__("Almost there!")}</h2>
                        <img src={img_gif} alt="sending" className="img-responsive center-block"/>
                        <h2>{__("We are verifying your account ...")}</h2>
                    </div>
                </div>
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

let mapStateToProps = function(state, ownProps) {
    return {

    };
};

let mapDispatchToProps = function(dispatch, ownProps){
    return {
        authWithAffiliateLead: function() {
            // Pull out the pending job from the localStorage or Cookie (private browsing)
            let pending_job = CookiePostAJob.get();
            let qs = queryString.parse(window.location.search);
            let redir = _.get(qs, ["redir"], "/collab/postajob");
            return dispatch(AffiliateEvent.get(_.get(pending_job, ["affiliate_event_id"])))
                .then((response) => {
                    // Sign up the user using just their email address
                    let affiliate_event = _.get(response, ["data"]);
                    return dispatch(User.signup({
                        firstname: _.get(affiliate_event, ["lead", "firstname"]),
                        lastname: _.get(affiliate_event, ["lead", "lastname"]),
                        job_title: _.get(affiliate_event, ["lead", "job_title"]),
                        company: _.get(affiliate_event, ["lead", "company"]),
                        email: _.get(pending_job, ["email"], _.get(affiliate_event, ["lead", "email"])),
                        phone_number: _.get(affiliate_event, ["lead", "phone"]),
                        password: _.get(pending_job, ["email"], _.get(affiliate_event, ["lead", "email"])),
                        generate_password: true,
                        signin_on_duplicate: false,
                        email_consent: true,
                        bureau_id: _.get(affiliate_event, ["bureau_id"], _.get(ownProps.whitelabel, ["bid"])),
                    }))
                    .then(() => {
                        if (_.startsWith(redir, "http")) {
                            window.location.href = redir;
                            return true;
                        } else {
                            return ownProps.history.push(redir);
                        }
                    })
                    .catch((err) => {
                        if (_.get(err, ["name"]) === "TokenException" || _.get(err, ["name"]) === "AuthenticationException") {
                            // Invalidate the session/force login screen
                            return sessionService.deleteSession()
                                .then(() => {
                                    return sessionService.deleteUser();
                                })
                                .then(() => {
                                    return ownProps.history.push({
                                        pathname: "/collab/signin",
                                        state: _.get(ownProps.location, ["state"])
                                    });
                                });
                        } else if (_.get(err, ["name"]) === "AuthorizationException") {
                            // Handled on the each mapStateToProps with .catch
                            return ownProps.history.push("/error", {error: _.get(err, ["message"])});
                        } else if (_.get(err, ["name"]) === "SpeakerMissingException") {
                            // Handled on the profile page
                            return ownProps.history.push("/error", {error: _.get(err, ["message"])});
                        } else {
                            return ownProps.history.push("/error", {error: _.get(err, ["message"])});
                        }
                    });
            });
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(PostAJobAffiliate));