import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../utils/Trans";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import "./SpeakerRating.scss";

export default class SpeakerRating extends React.Component {

    __toggleReviewModal = () => {
        if (_.isFunction(this.props.onToggleReviewModal)) {
            return this.props.onToggleReviewModal();
        }
    }

    render() {
        let {
            shortlist_speaker,
            collaborator,
            collaborators,
            sort = "average"
        } = this.props;

        collaborators = _.keyBy(collaborators, "id");
        let ratings = _.keyBy(_.get(shortlist_speaker, ["ratings"]), "mp_collaborator_id");

        let rating = _.toInteger(_.mean(_.map(ratings, (rating) => {
            return _.get(rating, ["rating"], 0);
        })));

        let my_collaborator = collaborator;
        if (_.isString(collaborator)) {
            my_collaborator = _.head(_.filter(collaborators, (item) => {
                return (_.get(item, ["email"]) === collaborator);
            }));
        }

        let my_rating = _.get(ratings, [_.get(my_collaborator, ["id"])]);

        let virgin = (sort === "average") ? (!rating) : (!_.get(my_rating, ["rating"]));

        return (
            (virgin) ?
                <div className="text-center speaker-rating">
                    {/*<i className="fa fa-ellipsis-h fa-3x text-center" style={{opacity: 0.5}}></i>*/}
                    <p className="link" style={{color: "white"}} onClick={this.__toggleReviewModal}>private comment <i className="fa fa-chevron-right"/></p>
                </div>
                :
                <div className="speaker-rating" onClick={this.__toggleReviewModal}>
                    <div className="rating-number-label">{(sort === "average" ? __("My Team's Rank") : (<div className="link clickable" onClick={this.__toggleReviewModal}>{__("My Rank")}</div>))}</div>
                    <div style={{marginTop: "0.5em", color: "silver", cursor: "pointer", textAlign: "center"}}></div>
                    <div className="rating-number">
                        {("average" === sort)
                            ? rating ? rating :  <i className="fa fa-ellipsis-h text-center" style={{opacity: 0.5}}></i>
                            : _.get(my_rating, "rating", <i className="fa fa-ellipsis-h text-center" style={{opacity: 0.5}}></i>)
                        }
                        {/*<i className="fa fa-star" style={{verticalAlign:"middle", marginTop:"-1.5em"}}/>*/}
                    </div>


                    {(_.size(_.get(shortlist_speaker, ["ratings"])) > 0) &&
                    <div style={{marginTop:"1em"}}>
                        {sort === "average"
                            ? <div>{__("my rank")}{": "}{_.get(my_rating, ["rating"]) ? <span className="clickable">{_.get(my_rating, ["rating"])}</span> : <i className="fa fa-ellipsis-h text-center" style={{opacity: 0.5}}></i>}</div>
                            : <div>{__("team's rank")}:{" "}{rating ? rating : <i className="fa fa-ellipsis-h text-center" style={{opacity: 0.5}}></i>}</div>
                        }
                    </div>
                    }
                    {true && <div>
                        {_.map(_.reverse(_.sortBy(_.get(shortlist_speaker, ["ratings"]), "rating")), (rank, i) => {
                            return (
                                <OverlayTrigger key={i} placement="bottom" overlay={<Tooltip placement="bottom" className="in" id={"rank_tooltip_" + _.get(rank, ["id"])}>
                                    <div>{_.map([1, 2, 3, 4, 5], (k) => {
                                        return (<i key={k} className={"fa fa-star" + ((_.toNumber(_.get(rank, ["rating"], 0)) >= k) ? "" : "-o")}/>);
                                    })}</div>
                                    <div>{_.get(rank, ["comments"])}</div>
                                    <div><i>{_.get(collaborators, [_.get(rank, ["mp_collaborator_id"]), "email"])}</i></div>
                                </Tooltip>}>
                                    <span><i className={"fa fa-square rating-review-box rating-review-box-" + _.get(rank, ["rating"])}/>{" "}</span>
                                </OverlayTrigger>
                            );
                        })}
                    </div>
                    }
                    {(_.size(_.get(shortlist_speaker, ["ratings"], [])) > 0) &&
                    <div onClick={this.__toggleReviewModal} ><span className="clickable">{(_.size(_.get(shortlist_speaker, ["ratings"], [])))}</span>{" "}private comment{_.size(_.get(shortlist_speaker, ["ratings"], [])) !== 1 ? "s" : ""}</div>
                    }
                </div>
        )
            ;
    }
}

SpeakerRating.propTypes = {
    shortlist_speaker: PropTypes.object,
    collaborator: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    collaborators: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    ratings: PropTypes.array,
    sort: PropTypes.string
};