import _ from "lodash";
import moment from "moment";
import sha512 from "js-sha512";

export default function BalboaToken(username, password, auth_type = "UB") {
    let now = moment().format("X");
    let secret = "n08øNdq23ubæAaubsåelKSvo9";

    let hash = sha512(_.join([
        secret,
        username,
        password,
        now
    ], "."));

    let token = _.join([
        auth_type,
        now,
        btoa(username),
        hash
    ], "$");

    return token;
}