import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";

class LocationQuickStats extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speaker
        } = this.props;

        let state = _.get(speaker, ["travelsFromState"]);
        let country = _.get(speaker, ["travelsFromCountry"]);

        return (
            <div className="quickStatsBox">
                <h5 className="header">{__("TRAVELS FROM")}</h5>
                <div className="text-center">
                    <div>{state}{state ? ", " : ""}{country}</div>
                </div>
            </div>
        );
    }
}

LocationQuickStats.propTypes = {
    speaker: PropTypes.object.isRequired
};

export default LocationQuickStats;