import _ from "lodash";

export default function(state = {}, action) {
    switch (action.type) {
        // Event Handlers
        case "ERROR_CREATE": {
            let new_state = _.cloneDeep(state);
            let source = _.get(action, ["source"], ["api"]);
            if (typeof source === "string") {
                source = [source];
            }
            let key = ["errors", ...source];
            let err_msg = _.get(action.error, ["error", "message"], action.error);

            _.setWith(new_state, [...key], err_msg, Object);
            return _.assign({}, state, new_state);
        }
        case "ERROR_CLEAR": {
            return _.assign({}, state, {
                errors: {}
            });
        }
        case "NOTIFICATION_CREATE": {
            let new_state = _.cloneDeep(state);
            _.set(new_state, ["notifications"], [action.message]);
            return _.assign({}, state, new_state);
        }
        case "NOTIFICATION_CLEAR": {
            return _.assign({}, state, {
                notifications: []
            });
        }
        default:
            return state;
    }
}