import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";

class ProfileLanguagePicker extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    __onUpdateLanguage = (e) => {
        if (_.isFunction(this.props.onUpdateLanguage)) {
            this.props.onUpdateLanguage(e.target.value);
        }
    }

    render() {
        let {
            profile_language = "en",
            speaker,
            languages
        } = this.props;

        let lang_bio = _.map(
            _.filter(_.get(speaker, ["bios"]), function(item) {
                return (item.contents !== "");
            }),
            "lang"
        );
        let lang_programs = _.map(
            _.filter(_.get(speaker, ["programs"]), function(item) {
                return (item.description !== "");
            }),
            "lang"
        );
        let languages_used = _.uniq(lang_bio, lang_programs);

        if (languages_used.length < 2) {
            return null;
        }

        return (
            <div>
                <select name="lang" className="form-control" style={{width: "100%"}} onChange={this.__onUpdateLanguage} value={profile_language}>
                    <option value="">{__("choose language")}</option>
                    {_.map(languages_used, function (lang, i) {
                        return (
                            <option key={i} value={lang}>{_.get(languages, [lang], lang)}</option>
                        );
                    })}
                </select>
                <p />
            </div>
        );
    }
}

ProfileLanguagePicker.propTypes = {
    speaker: PropTypes.object.isRequired,
    onUpdateLanguage: PropTypes.func.isRequired,
    languages: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    profile_language: PropTypes.string
};

export default ProfileLanguagePicker;