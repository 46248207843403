import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import UserStripe from "../../../../actions/User/UserStripe";
import formatCurrency from "../../../../utils/formatCurrency";
import ChoosePaymentMethodForm from "./forms/ChoosePaymentMethodForm";
import NoDepositForm from "./forms/NoDepositForm";
import PayDepositForm from "./forms/PayDepositForm";
import LoadingStripe from "./forms/LoadingStripe";
import ErrorLoadingStripe from "./forms/ErrorLoadingStripe";
import moment from "moment";

class PayDepositModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show_choose_payment_method: false,
            error_loading: null
        };
    }

    componentDidMount() {
        if (!this.props.stripe && this.props.cust_stripe_token) {
            return this.props.loadStripe().catch(e =>
                this.setState({
                    error_loading: _.get(e, ["message"])
                })
            );
        }
    }

    __onToggleChoosePaymentMethodPane = () => {
        this.setState({
            show_choose_payment_method: !this.state.show_choose_payment_method
        });
    };

    render() {
        let {
            is_loading,
            event,
            speaker,
            user,
            whitelabel,
            stripe,
            meeting_name,
            buyer_email,
            total_fees,
            deposit_amount,
            balance_amount,
            event_date_str,
            primary_payment_method,
            is_espeakers,
            onClose
        } = this.props;
        let { error_loading } = this.state;

        if (error_loading) {
            return (
                <ErrorLoadingStripe error={error_loading} onClose={onClose} />
            );
        }

        if (is_loading) {
            return <LoadingStripe onClose={onClose} />;
        }

        if (
            !primary_payment_method ||
            this.state.show_choose_payment_method ||
            !this.props.cust_stripe_token
        ) {
            return (
                <ChoosePaymentMethodForm
                    onClose={onClose}
                    buyer_email={buyer_email}
                    stripe={stripe}
                    primary_payment_method={primary_payment_method}
                    user={user}
                    event={event}
                    whitelabel={whitelabel}
                    deposit_amount={deposit_amount}
                    onClickToPayNow={this.__onToggleChoosePaymentMethodPane}
                    {...this.props}
                />
            );
        }

        if (deposit_amount === 0) {
            // Show a different modal if there is no deposit
            return (
                <NoDepositForm
                    onClose={onClose}
                    primary_payment_method={primary_payment_method}
                    user={user}
                    event={event}
                    speaker={speaker}
                    total_fees={formatCurrency(total_fees, 2)}
                    event_date_str={event_date_str}
                    last4={_.get(primary_payment_method, ["last4"])}
                />
            );
        } else {
            return (
                <PayDepositForm
                    onChangePaymentMethod={
                        this.__onToggleChoosePaymentMethodPane
                    }
                    onClose={onClose}
                    event_date_str={event_date_str}
                    last4={_.get(primary_payment_method, ["last4"])}
                    event={event}
                    speaker={speaker}
                    deposit_amount={deposit_amount}
                    balance_amount={balance_amount}
                    description={"Balance for " + meeting_name}
                    is_espeakers={is_espeakers}
                />
            );
        }
    }
}

PayDepositModal.propTypes = {
    event: PropTypes.object.isRequired,
    speaker: PropTypes.object.isRequired,
    agreement: PropTypes.object.isRequired,
    payment: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired,
    onClose: PropTypes.func
};
////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {
    // Loading Properties
    let cust_stripe_token = _.get(ownProps.user, ["stripe_cust_token"]);

    let stripe = _.get(state, ["Stripe", "STRIPE"]);

    let default_source = _.get(stripe, ["default_source"]);
    let stripe_sources = _.get(stripe, ["sources", "data"]);

    let stripe_primary_pm = _.find(stripe_sources, function (o) {
        return o.id === default_source;
    });

    let total_fees = _.reduce(
        ownProps.payment,
        function (sum, item) {
            return sum + parseFloat(item.fee);
        },
        0
    );

    let deposit_amount = _.reduce(
        ownProps.payment,
        function (sum, item) {
            if (
                _.get(item, ["flags_as_map", "is_deposit", "is_set"], false) &&
                !_.get(item, ["paid"], false)
            ) {
                return sum + parseFloat(item.fee);
            }
            return sum;
        },
        0
    );

    let balance_amount = total_fees - deposit_amount;
    let event_date_str = moment
        .unix(_.get(ownProps.event, ["Stagetimes", 0, "starttime"]))
        .format("MMMM D, Y");

    return {
        is_loading: !stripe && cust_stripe_token,
        cust_stripe_token: cust_stripe_token,
        stripe: stripe,
        primary_payment_method: stripe_primary_pm,

        buyer_email: _.get(ownProps.user, ["email"]),
        meeting_name: _.get(ownProps.event, ["meetingname"]),

        total_fees: total_fees,
        balance_amount: balance_amount,
        deposit_amount: deposit_amount,
        event_date_str: event_date_str,

        is_espeakers: !!(
            ownProps.whitelabel && ownProps.whitelabel.is_espeakers
        )
    };
};

var mapDispatchToProps = function (dispatch, ownProps) {
    return {
        loadStripe: function () {
            return dispatch(UserStripe.get());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PayDepositModal);
