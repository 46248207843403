import React from "react";

export default class AgreementText extends React.Component {

    render() {
        return (
            <div style={{pageBreakBefore: "always"}}>
                <div className="entry-content">
                    <h1>Standard&nbsp;Engagement&nbsp;Agreement</h1>
                    <p style={{textAlign: "right"}}><span style={{color: "#808080"}}>Version 0.4 | March&nbsp;2017</span></p>
                    <p>This Engagement Agreement (the “<b>Agreement</b>”) is made between each speaker, trainer, consultant, coach, or similar individual (“<b>Presenter</b>”) and the individual or entity who either finds the Presenter or retains his or her services using the eSpeakers platform (“<b>Company</b>”). &nbsp;It is binding upon the parties unless they use their own agreement and submit that executed agreement using the eSpeakers platform. &nbsp;
                    </p>
                    <p>The engagement form on the eSpeakers platform (“<b>Engagement Form</b>”) will set forth the specific terms of the engagement and should specify:</p>
                    <ul className="bullets">
                        <li>the date and time the Services will be performed</li>
                        <li>the location in which the Services should be performed, or if not onsite, details of the delivery method (webinar, phone call, etc).</li>
                        <li>the fees and expenses that the Company&nbsp;will pay the&nbsp;Presenter</li>
                        <li>the group for whom Services are provided (e.g. the audience)</li>
                    </ul>
                    <div className="legal">
                        <ol>
                            <li><strong>SERVICES AND COMPENSATION</strong>
                                <ol>
                                    <li><em>Services</em>. Subject to the terms and conditions of this Agreement, the Presenter will perform for Company the presentation services (<strong>“Services”</strong>) described and detailed in the Engagement Form.</li>
                                    <li><em>Compensation</em>. Company promises to pay certain fees and expenses to Presenter. Unless otherwise agreed to via the eSpeakers platform, the Company&nbsp;will pay the fees and expenses according to the following: (1) 50% of the agreed upon fee (<strong>“Engagement Fee</strong>”) is due at the time an offer is accepted, and is collected by the eSpeakers platform. Once collected, the Presenter considers the engagement confirmed and will turn down other
                                        conflicting offers. Because of this, this Engagement Fee is not refundable. (2) The balance (50%) of the Engagement Fee is due on the day the engagement begins. It will be collected automatically by the eSpeakers platform on that day, if possible. (3) Any agreed-to travel expenses or materials costs are due on the day the engagement begins. These will be collected automatically by the eSpeakers platform on that day, if possible.
                                    </li>
                                    <li><em>Other Payment.</em> If the eSpeakers platform is unable to collect the Engagement Fees or expenses from the Company&nbsp;automatically, the Company&nbsp;is responsible to make other arrangements with eSpeakers to deliver the due payment.</li>
                                </ol>
                            </li>
                            <li><strong>TERM AND TERMINATION</strong>
                                <ol>
                                    <li><em>Term; Termination.</em> This Agreement will continue until the date the engagement ends. Company may terminate this Agreement by giving written notice to Presenter, but in consideration of the opportunities foregone by Presenter by reason of its engagement with Company, upon such notice, Company will forfeit any amounts paid prior to termination. If Company terminates this Agreement or requests that Presenter not perform the Services within
                                        forty-five&nbsp;days (45) before the date set for the engagement, the entire amount of the Engagement Fee will be due as if the Services had been fully rendered. Company may terminate this Agreement immediately with prior notice if Presenter refuses to or is unable to perform the Services. If Presenter does not render the Services for any reason other than force majeure, Presenter will not be entitled to any compensation or reimbursement of expenses, and
                                        Presenter must refund any fees already paid by Company.
                                    </li>
                                    <li><em>Survival.</em> Upon termination, all rights and duties of the parties toward each other cease except that: (a) within 30 days of the effective date of termination, Company will pay all amounts owing to Presenter&nbsp;for Services or Presenter will return to Company any amount paid to Presenter as a retainer that is not owed against Services; and (b) Sections 2, 3, 4, 5, 6, 7 and 8 survive termination of this Agreement.</li>
                                </ol>
                            </li>
                            <li><strong>CONFIDENTIALITY</strong><br />
                                <em>Definition.</em> “Confidential Information” means any nonpublic information regarding a party to this Agreement. Each party agrees to hold any Confidential Information it receives in the course of this engagement in confidence and not to use any such information for any purpose other than to perform its obligations under this Agreement.</li>
                            <li><strong>OWNERSHIP</strong><br />
                                Each party shall remain the sole and exclusive owner of any intellectual property it owns or licenses and discloses to the other during the Term. Neither party transfers, licenses, or conveys any of its intellectual property to the other without express written agreement. Unless otherwise stated in the Engagement Form, the Presenter’s presentation(s) may not be recorded or broadcast. All performances and materials provided are copyrighted by the Provider.</li>
                            <li><strong>PRESENTER WARRANTIES AND COVENANTS</strong><br />
                                As an inducement to Company entering into and consummating this Agreement, Presenter represents, warrants, and covenants that (1) it will comply with Company’s safety and conduct policies (2) it will perform the Services in a professional manner, and (3) that nothing contained in the Services will infringe, violate, or misappropriate any intellectual property rights of any third party.</li>
                            <li><strong>GOVERNING LAW AND JURISDICTION</strong>
                                <ol>
                                    <li><em>Governing Law.</em> The laws of the state in which the Services are rendered shall govern any dispute arising out of this Agreement.</li>
                                    <li><em>Jurisdiction.</em> The parties agree that any dispute arising out of this Agreement shall be exclusively heard in the courts of the state in which the Services are rendered, and the parties do hereby submit to the exclusive personal jurisdiction of those courts.</li>
                                </ol>
                            </li>
                            <li><strong>INDEPENDENT CONTRACTOR; BENEFITS</strong>
                                <ol>
                                    <li><em>Independent Contractor.</em> It is the express intention of the parties that Presenter perform the Services as an independent contractor. Nothing in this Agreement will in any way be construed to constitute Presenter as an agent, employee, or representative of Company. Presenter is obligated to report as income all compensation received by Presenter under this Agreement, and to pay all self employment and other taxes thereon.</li>
                                    <li><em>Benefits.</em> Presenter acknowledges that neither Presenter nor Presenter’s employees will receive benefits from Company either as a presenter or employee.</li>
                                </ol>
                            </li>
                            <li><strong>MISCELLANEOUS</strong>
                                <ol>
                                    <li><em>Non-assignment, Sub-Contractors.</em> Neither this Agreement nor any rights under this Agreement may be assigned or otherwise transferred by Presenter, in whole or in part, whether voluntarily or by operation of law, without the prior written consent of Company. Presenter may not utilize a subcontractor Presenter or other third party to perform its duties under this Agreement without the prior written consent of Company.</li>
                                    <li><em>Notices.</em> Any notice required or permitted under the terms of this Agreement or required by law must be in writing and must be: delivered in person, sent by first class registered mail, or airmail, as appropriate, or sent by overnight air courier, in each case properly posted and fully prepaid to the appropriate address as set forth below. Either party may change its address for notices by notice to the other party given in accordance with this
                                        Section. Notices will be deemed given at the time of actual delivery in person, three business days after deposit in the mail as set forth above, or one day after delivery to an overnight air courier service.
                                    </li>
                                    <li><em>Waiver.</em> Failure, neglect, or delay by a party to enforce the provisions of this Agreement or its rights or remedies at any time, will not be construed as a waiver of the party’s rights under this Agreement and will not in any way affect the validity of the whole or any part of this Agreement or prejudice the party’s right to take subsequent action. Exercise or enforcement by either party of any right or remedy under this Agreement will not
                                        preclude the enforcement by the party of any other right or remedy under this Agreement or that the party is entitled by law to enforce.
                                    </li>
                                    <li><em>Severability.</em> If any term, condition, or provision in this Agreement is found to be invalid, unlawful, or unenforceable to any extent, the parties will endeavor in good faith to agree to amendments that will preserve, as far as possible, the intentions expressed in this Agreement.</li>
                                    <li><em>Miscellaneous.</em> Headings are used in this Agreement for reference only and will not be considered when interpreting this Agreement. This Agreement and all exhibits contain the entire agreement of the parties with respect to the subject matter of this Agreement and supersede all previous communications, representations, understandings, and agreements, either oral or written, between the parties with respect to said subject matter. No terms,
                                        provisions, or conditions of any other business form that either party may use in connection with the transactions contemplated by this Agreement will have any effect on the rights, duties, or obligations of the parties under, or otherwise modify, this Agreement, regardless of any failure of a receiving party to object to these terms or conditions. This Agreement may be amended only by a writing signed by both parties.
                                    </li>
                                    <li><em>Relationship to eSpeakers.com, Inc.</em> The parties acknowledge that eSpeakers.com, Inc. or its affiliates or partners (<strong>“eSpeakers”</strong>) has provided the contract form for this Agreement for the convenience of the parties, but that it is not a party to this Agreement and that eSpeakers makes no representations regarding the quality or availability of the Services. Rather, it is simply a platform for connecting presenters with groups who
                                        may want to engage their services and for making arrangements for those services. The parties agree that eSpeakers shall have no liability whatsoever with respect to this contract form or with respect to the provision of this form. Each party agrees and acknowledges that it has had opportunity to seek independent counsel and that it is not required to use the form provided by eSpeakers in order to use the eSpeakers platform. Each party agrees to consult
                                        its own independent legal counselors and to defend, indemnify, and hold harmless eSpeakers from and against any loss, damages, claims, settlements, judgments, or other liability that may arise out of or related to this Agreement, including attorney fees and court costs that eSpeakers may accrue in connection with this Agreement, except as may be provided in separate agreements directly between each party to this Agreement and eSpeakers. The parties also
                                        acknowledge that, although eSpeakers may assist the parties in making payment arrangements to each other, eSpeakers is not in any way liable for any obligations related to such payment, including without limitation the obligation to collect payment.
                                    </li>
                                </ol>
                            </li>
                        </ol>
                    </div>
                </div>
            </div>
        );
    }
}