import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, reduxForm, SubmissionError } from "redux-form";
import { gettext as __ } from "../../../../utils/Trans";
import LoadingSubmit from "../../../../components/LoadingSubmit";
import InlineError from "../../../../components/InlineError";
import ShortList from "../../../../actions/ShortList/ShortList";

class ResumeEventForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch, props) {
        return dispatch(ShortList.resume(values))
            .then(() => {
                if (_.isFunction(props.onClose)) {
                    props.onClose();
                }
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
            handleSubmit,
            submitting,
            error,
            onClose
        } = this.props;

        return (
            <Form role="form" onSubmit={handleSubmit(this.handleSubmit)}>
                <div className="modal-body">
                    {error && <InlineError error={_.get(error, ["message"])} />}
                    <div className="row">
                        <div className="col-sm-12">
                            <p>
                                {__("When you resume this event, we will let the candidate speakers on your shortlist know that you are ready to work toward hiring your presenter.  If the event is an Open Call, it will be re-listed for applicants to see.")}
                            </p>
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <LoadingSubmit is_saving={submitting} save_text={__("Resuming...")} className="btn-success btn-block-xs">
                        {__("Resume Event")}
                    </LoadingSubmit>
                    {" "}
                    <div className="btn btn-default btn-block-xs" disabled={submitting} onClick={onClose}>
                        {__("Never Mind")}
                    </div>
                </div>
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

ResumeEventForm.propTypes = {
    event: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

function mapStateToProps(state, ownProps) {
    return {
        initialValues: {
            eid: _.get(ownProps.event, ["eid"])
        }
    };
}

function mapDispatchToProps() {
    return {

    };
}

ResumeEventForm = reduxForm({
    form: "shortlist_page_resume_event"
})(ResumeEventForm);

export default connect(mapStateToProps, mapDispatchToProps)(ResumeEventForm);