import _ from "lodash";

let getPageHeight = function () {
    return Math.max(
        document.body.scrollHeight,
        document.body.offsetHeight,
        document.documentElement.clientHeight,
        document.documentElement.scrollHeight,
        document.documentElement.offsetHeight
    );
};
let getViewPortHeight = function () {
    return Math.max(
        document.documentElement.clientHeight,
        window.innerHeight || 0
    );
};

export default function(onScroll){
    if ("undefined" === typeof window) {
        return _.noop;
    }

    var last_vals = [];
    let broadcast = _.throttle(function(){
        onScroll(last_vals[0], last_vals[1]);
    }, 100, {leading: true});

    let listener = function () {
        let scrollY;
        if ( isNaN(window.scrollY) ) {
            scrollY = document.documentElement.scrollTop;
        } else {
            scrollY = window.scrollY;
        }
        var vals = [
            scrollY,
            getPageHeight() - scrollY - getViewPortHeight()
        ];
        if (!_.isEqual(last_vals, vals)) {
            last_vals = vals;
            broadcast();
        }
    };

    if (window.addEventListener) {
        window.addEventListener("scroll", listener, false);
    } else if (window.attachEvent) {
        window.attachEvent("onscroll", listener);
    }
    return function(){
        if(window.removeEventListener){
            window.removeEventListener("scroll", listener, false);
        }else if(window.detachEvent){
            window.detachEvent("onscroll", listener);
        }
    };
}
