import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../utils/Trans";

class LoadingSubmit extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            save_text = __("Saving..."),
            hide_spinner = false,
            className = "btn-success",
            is_saving,
            disabled,
            children = __("Save")
        } = this.props;

        let body = children;

        let spinner = <i className="fa fa-spin fa-circle-o-notch"></i>;

        //NOTE using lots of ternary operators on purpose. Why? so you don't un-mount then remount the children frequently (causes forms to reset to the initial state when saving)
        return (
            <button type="submit" className={"btn " + (className)} disabled={is_saving || disabled ? " disabled" : ""}>
                {is_saving && !hide_spinner ? spinner : ""}
                {" "}
                {is_saving ? save_text : body}
            </button>
        );
    }
}

LoadingSubmit.propTypes = {
    save_text: PropTypes.string,
    hide_spinner: PropTypes.bool,
    className: PropTypes.string,
    is_saving: PropTypes.bool,
    body: PropTypes.node
};

export default LoadingSubmit;