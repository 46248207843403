import _ from "lodash";
import React from "react";
import dateformat from "dateformat";
import { gettext as __ } from "../../../utils/Trans";
import SpeakerSummary from "../components/SpeakerSummary";
import AgreementFees from "../components/AgreementFees";
import { AcceptOfferButton, DeclineOfferButton, ProposeDifferentTermsButton, WithdrawOfferButton } from "../components/Buttons";

class OfferStep extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speaker,
            event,
            agreement,
            event_has_an_accepted_offer
        } = this.props;

        let speakername = _.get(speaker, ["name"]);
        let contract_url = _.get(agreement, ["contract_url"]);
        let is_contract_custom = _.get(agreement, ["is_contract_custom"], false);
        let display_message = _.get(agreement, ["flags_as_map", "initiated_by_buyer", "is_set"])
            ? (speakername + " " + __("has your offer. Watch your inbox for a notice that they have ACCEPTED your offer or PROPOSED DIFFERENT TERMS. You can also propose different terms, or withdraw the offer."))
            : (speakername + " " + __("has sent you an offer and is waiting for your answer. You should ACCEPT the offer or PROPOSE DIFFERENT TERMS."));

        return (
            <div>
                <div style={{
                    marginBottom: "30px",
                    paddingBottom: "30px",
                    borderBottom: "1px solid #EAEAEA"
                }}>
                    <SpeakerSummary
                        speaker={speaker}
                        event_has_an_accepted_offer={event_has_an_accepted_offer}
                        speaker_has_an_accepted_offer={_.get(speaker, ["offer_accepted"])}
                        display_message={display_message}
                        {...this.props}
                    />
                </div>
                <h4>
                    {_.get(agreement, ["flags_as_map", "initiated_by_speaker", "is_set"])
                        ? __("%s has made you an offer:", [speakername])
                        : __("Here is your offer to %s:", [speakername])}
                </h4>
                <br />
                <div className="row">
                    <div className="col-md-12">
                        <h5>
                            {__("Terms")}
                        </h5>
                        <div className="panel panel-default">
                            <div className="panel-body">
                                <div style={{whiteSpace: "pre-wrap"}}>
                                    {_.get(agreement, ["terms"], "")}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row" style={{
                    marginBottom: "30px",
                    paddingBottom: "30px",
                    borderBottom: "1px solid #EAEAEA"
                }}>
                    <div className="col-xs-12 col-sm-12 col-md-7 col-lg-7">
                        <div className="help-block instruction">
                            {__("Pay the presenter's fee securely via credit card, eCheck, or mailed paper check.")}
                        </div>
                        <h5>
                            {__("Fee")}
                        </h5>
                        <div>
                            <AgreementFees event={event} speaker={speaker} agreement={agreement} />
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-5 col-lg-5">
                        <h5>
                            {__("Actions")}
                        </h5>
                        <div>
                            <div className="text-muted" style={{margin: "1.5em 0"}}>
                                {
                                    _.get(agreement, ["flags_as_map", "initiated_by_buyer", "is_set"])
                                        ?
                                        __("You made this offer to %s on %s.  Wait for them to accept it or propose different terms.", [
                                            speakername,
                                            dateformat(new Date(_.get(agreement, "created", new Date().getTime() / 1000) * 1000), "mmm d 'at' h:MM TT ")
                                        ])
                                        :
                                        __("%s made this offer to you on %s.  You can accept it or propose different terms.", [
                                            speakername,
                                            dateformat(new Date(_.get(agreement, "created", new Date().getTime() / 1000) * 1000), "mmm d 'at' h:MM TT ")
                                        ])
                                }
                            </div>
                            {
                                _.get(agreement, ["flags_as_map", "initiated_by_buyer", "is_set"])
                                    ?
                                    <div>
                                        <ProposeDifferentTermsButton {...this.props} />
                                        <br />
                                        <WithdrawOfferButton {...this.props} />
                                        <br />
                                        {is_contract_custom &&
                                        <div className="alert alert-warning small">
                                            {__("You uploaded your own contract.  You can review it below.")}
                                        </div>
                                        }
                                        <a href={contract_url} target="_blank" rel="noopener noreferrer" className="text-muted">
                                            <i className="fa fa-paperclip"/>
                                            {" "}
                                            {__("view contract")}
                                        </a>
                                    </div>
                                    :
                                    <div>
                                        <AcceptOfferButton {...this.props} />
                                        <br />
                                        <ProposeDifferentTermsButton {...this.props} />
                                        <br />
                                        <DeclineOfferButton {...this.props} />

                                        <br />

                                        {is_contract_custom &&
                                        <div className="alert alert-warning small">
                                            {__("The speaker uploaded their own contract.  We recommend you review it before accepting their offer.")}
                                        </div>
                                        }
                                        <a href={contract_url} target="_blank" rel="noopener noreferrer" className="text-muted">
                                            <i className="fa fa-paperclip"/>
                                            {" "}
                                            {__("view contract")}
                                        </a>
                                    </div>
                            }
                        </div>
                    </div>
                </div>
                {/*<div>*/}
                    {/*{event && speaker && <Messages messages={messages} speaker={speaker} event={event} {...this.props} />}*/}
                {/*</div>*/}
            </div>
        );
    }
}

export default OfferStep;
