import _ from "lodash";
import Balboa from "../Global/Balboa";
import {gettext as __} from "../../utils/Trans";

export default function (params) {
    return (dispatch, getState) => {
        var key = ["withdrawOffer", params.event_id, params.speaker_id];

        return Balboa(dispatch, getState)
            .post(key, "/mpagreement/archivedbybuyer", {
                event_id: params.event_id,
                speaker_id: params.speaker_id
            })
            .then((response) => {
                dispatch({
                    type: "EVENT_AGREEMENT_DELETE",
                    eid: params.event_id,
                    sid: params.speaker_id
                });
                return response;
            })
            .then((response) => {
                dispatch({
                    type: "NOTIFICATION_CREATE",
                    message: _.get(response, ["data", "message"], __("The offer has been withdrawn and we have notified the speaker."))
                });
                return response;
            })
            .then((response) => {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            });
    };
};
