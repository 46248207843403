import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";

class Programs extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speaker,
            profile_language = "en",
            topic_id
        } = this.props;

        let topic_programs;

        let programs = _.orderBy(_.filter(_.get(speaker, ["programs"]), function(item) {
            return (
                (_.isNull(_.get(item, ["lang"])) || _.get(item, ["lang"], "en") === profile_language) &&
                (_.get(item, ["description"]) !== "")
            );
        }), ['vieworder', 'id'], ['desc', 'asc']);

        if (_.isEmpty(programs)) {
            programs = _.orderBy(_.filter(_.get(speaker, ["programs"]), function(item) {
                return (
                    (_.isNull(_.get(item, ["lang"])) || _.get(item, ["lang"], "en") === "en") &&
                    (_.get(item, ["description"], "") !== "")
                );
            }), ['vieworder', 'id'], ['desc', 'asc']);
        }

        if (topic_id >= 0) {
            topic_programs = _.orderBy(_.filter(programs, function(item) {
                return (_.get(item, ["topic"]) === topic_id);
            }), ['vieworder', 'id'], ['desc', 'asc']);


            // If we have topic programs, then use them, otherwise show everything
            if (!_.isEmpty(topic_programs)) {
                topic_programs = _.map(topic_programs, function (program, i) {
                    let tags = _.get(program, ["tags"]);
                    return (
                        <div key={i}>
                            <div className="programInfoWrap langsensitive" data-lang={_.get(program, ["lang"])}>
                                <h4 className={"header legend"}>
                                    {_.get(program, ["title"])}
                                    {((_.get(program, ["presenter_type"], 0) & 128) > 0) && <div className={"pull-right"}><i className={"text-muted small"}><i className={"fa fa-desktop"} />{" "}Offered as a Virtual Program</i></div>}
                                </h4>
                                <div className="expandable-readmore">
                                    <div style={{display: "block"}} dangerouslySetInnerHTML={{__html: _.get(program, ["description"])}}/>
                                    <div className="row-fluid grayLabels" style={{padding: "20px 0px"}}>
                                        {_.map(tags, (tag, k) => (
                                            <span key={k}>
                                                <React.Fragment><span className="label label-default">{_.get(tag, ["DESCRIPTION"])}</span>{" "}</React.Fragment>
                                            </span>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                });
            }
        }

        programs = _.map(programs, function(program, i) {
            let tags = _.get(program, ["tags"]);
            return (
                <div key={i}>
                    <div className="programInfoWrap langsensitive" data-lang={_.get(program, ["lang"])}>
                        <h4 className={"header legend"}>
                            {_.get(program, ["title"])}
                            {((_.get(program, ["presenter_type"], 0) & 128) > 0) && <div className={"pull-right"}><i className={"text-muted small"}><i className={"fa fa-desktop"} />{" "}Offered as a Virtual Program</i></div>}
                        </h4>
                        <div className="expandable-readmore">
                            <div style={{display: "block"}} dangerouslySetInnerHTML={{__html: _.get(program, ["description"])}} />
                            <div className="row-fluid grayLabels" style={{padding: "20px 0px"}}>
                                {_.map(tags, (tag, k) => (
                                    <span key={k}>
                                        <React.Fragment><span className="label label-default">{_.get(tag, ["DESCRIPTION"])}</span>{" "}</React.Fragment>
                                    </span>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            );
        });

        if (!_.isEmpty(topic_programs)) {
            return (
                <div>
                    <h3 className="header">{__("Program Information")}</h3>
                    {topic_programs}
                    <hr />
                    <h3 className="header">{__("All Programs Offered By")}{" "}{_.get(speaker, ["name"])}</h3>
                    {programs}
                </div>
            );
        }

        return (
            <div>
                <h3 className="header">{__("Program Information")}</h3>
                {programs}
            </div>
        );
    }
}

Programs.propTypes = {
    speaker: PropTypes.object.isRequired
};

export default Programs;