import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { gettext as __ } from "../../../../utils/Trans";
import { Message } from "../../../../components/FormComponents";
import LoadingSubmit from "../../../../components/LoadingSubmit";
import InlineError from "../../../../components/InlineError";
import isValidEmail from "../../../../utils/isValidEmail";
import emailInvoice from "../../../../actions/ShortList/emailInvoice";
import moment from "moment";

const strToEmailList = function(str){
    str = _.isString(str) ? str : "";

    return _.uniq(_.filter(_.map(str.split(/(\s|,)/), function(part){
        return part.trim();
    }), isValidEmail));
};

class ShareInvoiceForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentWillUnmount() {
        this.props.clearResp();
    }

    handleSubmit(values, dispatch, props) {
        return dispatch(emailInvoice.send({
                event_id: values.event_id,
                emails: strToEmailList(values.emails),
                message: values.message
            }))
            .then(() => {
                if (_.isFunction(props.onClose)) {
                    props.onClose();
                }
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
            handleSubmit,
            submitSucceeded,
            submitting,
            error,
            event_name,
            event_date_str,
            onClose
        } = this.props;

        if (submitSucceeded) {
            return (
                <div className="text-success">
                    <h4>{__("Message Sent!")}</h4>
                </div>
            );
        }

        return (
            <Form role="form" className="mp-style-form" onSubmit={handleSubmit(this.handleSubmit)}>
                <div className="modal-body">
                    {error && <InlineError error={_.get(error, ["message"])} />}
                    <div className="row">
                        <div className="col-xs-12">
                            <label className="control-label">Subject</label>
                            <div className="form-control-static">
                                {__("Please review and pay this invoice for %s on %s", [event_name, event_date_str])}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <Field
                                name="emails"
                                type="textarea"
                                rows={2}
                                className="form-control"
                                label={__("To")}
                                placeholder={__("Enter a list of emails i.e. bob@gmail.com, sue@yahoo.com...")}
                                component={Message}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-xs-12">
                            <Field
                                name="message"
                                type="textarea"
                                rows={10}
                                className="form-control"
                                label={__("Message")}
                                placeholder={__("Please review this invoice and make a payment...")}
                                component={Message}
                            />
                        </div>
                    </div>
                </div>
                <div className="modal-footer">
                    <LoadingSubmit is_saving={submitting} save_text={__("Sending...")} className="btn-success btn-block-xs">
                        {__("Send")}
                        {" "}
                        <i className="fa fa-paper-plane" />
                    </LoadingSubmit>
                    {" "}
                    <div className="btn btn-default btn-block-xs" disabled={submitting} onClick={onClose}>
                        {__("Never Mind")}
                    </div>
                </div>
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

ShareInvoiceForm.propTypes = {
    event: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

function mapStateToProps(state, ownProps) {

    let event_name = _.get(ownProps.event, ["organization"]);
    let event_date_str = moment(_.get(ownProps.event, ["Stagetimes", 0, "starttime"]) * 1000).format("MMMM D, Y");

    return {
        event_name: event_name,
        event_date_str: event_date_str,
        initialValues: {
            message: "",
            emails: "",
            subject: __("Please review and pay this invoice for %s - %s", [event_name, event_date_str]),
            event_id: _.get(ownProps.event, ["eid"])
        }
    };
}

function mapDispatchToProps(dispatch) {
    return {
        clearResp: function(){
            dispatch(emailInvoice.clear());
        }
    };
}

ShareInvoiceForm = reduxForm({
    form: "shortlist_page_share_invoice",
    validate: function(values) {
        const errors = {};
        if (!values.message) {
            errors.message = __("Please enter a message to send to your colleagues.");
        }
        if (!values.emails) {
            errors.to = __("Please enter to who you want to send this message.");
        }
        return errors;
    }
})(ShareInvoiceForm);

export default connect(mapStateToProps, mapDispatchToProps)(ShareInvoiceForm);