import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import getSpeakerCourses from "../../../../actions/Speaker/getSpeakerCourses";
import LoadingBar from "../../../../components/LoadingBar/LoadingBar";
import "./Courses.scss";

import thinkific_logo from "./img/thinkific_logo.png";

class CoursesTable extends React.Component {

  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    let {
          course_data
        } = this.props;

    return (
      <div className="row row-margin-bottom">
        {_.map(course_data, function (course, i) {
          return (
            <div key={i}>
              <div className="col-md-5 no-padding lib-item" data-category="view">
                <div className="lib-panel">
                  <div className="row box-shadow">
                    <div className="col-md-6">
                      <img className="lib-img-show" src={_.get(course, "image_url", "http://lorempixel.com/850/850/?random=123")} alt="Course"/>
                    </div>
                    <div className="col-md-6">
                      <div className="lib-row lib-header">
                        {_.get(course, "name", "[course name]")}
                        <div className="lib-header-seperator"></div>
                      </div>
                      <div className="lib-row lib-desc">
                        {_.get(course, "description", "[course description]")}
                      </div>
                      <div className="lib-row lib-button">
                        <a href={_.get(course, "url", "#")} target="_blank" rel="noopener noreferrer" className="btn btn-primary btn-sm btn-block btn-outline-primary">Learn More</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-1"></div>
            </div>
          )
        })
        }
      </div>
    );
  }
}

CoursesTable.propTypes = {
  course_data: PropTypes.array
};

class Courses extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      is_loading: true,
      course_data: null
    };
  }

  UNSAFE_componentWillMount() {
    let self = this;
    if (_.isEmpty(this.state.course_data)) {
      this.props.loadCourses()
      .then((response) => {
        console.log(response)
        self.setState({
          course_data: _.get(response, ["data", "courses"]),
          is_loading: false
        });
      });
    }
  }

  UNSAFE_componentWillReceiveProps = () => {
    this.UNSAFE_componentWillMount();
  };

  render() {
    return (
      <div>
        <h3 className="header">{__("Online Courses")}
          <img className="pull-right" style={{width: "150px", height: "auto"}} src={thinkific_logo} alt="powered by Thinkific"/>
        </h3>
        {this.state.is_loading &&
        <LoadingBar/>
        }
        {!this.state.is_loading && !this.state.course_data &&
        <div>{__("No online courses available.")}</div>
        }

        {!this.state.is_loading && this.state.course_data &&
        <CoursesTable course_data={this.state.course_data}/>
        }
      </div>
    );
  }
}

Courses.propTypes = {
  speaker: PropTypes.object
};


////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function () {
  return {
    speaker: _.get(this.state, "speaker")
  };
};

var mapDispatchToProps = function (dispatch, ownProps) {
  let sid = _.get(ownProps.speaker, ["sid"]);
  return {
    loadCourses: function () {
      return dispatch(getSpeakerCourses(sid));
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Courses);