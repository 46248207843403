import _ from "lodash";

export default function (state = {}, action) {
    switch (action.type) {
        case "BALBOA3_ACTION_WAITING": {
            let o = {};
            let key = action.key;
            let first_key = _.head(key);
            // Use _.setWith so that numeric keys aren't treated as an Array, but as an Object key
            _.set(o, first_key, _.get(state, first_key, {}));
            _.setWith(o, [...key], {
                waiting: true,
                timestamp_sent: action.timestamp,
                promise: action.promise
            }, Object);
            return _.assignIn({}, state, o);
        }
        case "BALBOA3_ACTION_RESPONSE": {
            let o = {};
            let key = action.key;
            let first_key = _.head(key);
            let existing = _.get(state, [...key], {});
            // Use _.setWith so that numeric keys aren't treated as an Array, but as an Object key
            _.set(o, first_key, _.get(state, first_key, {}));
            _.setWith(o, [...key], _.assign({}, existing || {}, {
                waiting: false,
                error: action.error,
                data: action.data,
                timestamp_received: action.timestamp,
                xfer_time: action.timestamp - existing.timestamp_sent
            }), Object);
            return _.assignIn({}, state, o);
        }
        case "BALBOA3_CLEAR_KEY": {
            let new_state = _.cloneDeep(state);
            new_state = _.omit(new_state, [...action.key]);
            return new_state;
        }
        case "BALBOA3_CLEAR_KEYS": {
            let new_state = _.cloneDeep(state);
            _.each(action.keys, function (k) {
                new_state = _.omit(new_state, k);
            });
            return new_state;
        }
        default:
            return state;
    }
}