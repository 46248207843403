import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import { Modal } from "react-bootstrap";
import FeesAndPayment from "../../components/FeesAndPayment";
import { PayBalanceButton, ViewInvoiceButton } from "../../components/Buttons";

class FeesAndPaymentModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            whitelabel,
            payment,
            onClose
        } = this.props;

        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("Fees and Payment")}</Modal.Title>
                </Modal.Header>
                <div className="modal-body">
                    <FeesAndPayment payment={payment} />
                    <hr />
                    <div className="help-block">
                        <p>{__("We will automatically debit your payment method on the day of the event for any outstanding balance.")}</p>
                        <p>{__("You also have the option to pay the balance at any time before the event begins or print an invoice and send payment in the mail.")}</p>
                    </div>
                </div>
                <div className="modal-footer">
                    {!_.get(whitelabel, ["flags", "MP_DISABLE_PAYMENT_AND_CONTRACT"], false) &&
                        <PayBalanceButton {...this.props} />
                    }
                    {" "}
                    {!_.get(whitelabel, ["flags", "MP_DISABLE_PAYMENT_AND_CONTRACT"], false) &&
                        <ViewInvoiceButton {...this.props} />
                    }
                    {" "}
                    <div className="btn btn-default btn-block-xs" onClick={onClose}>
                        {__("Close")}
                    </div>
                </div>
            </Modal>
        );
    }
}

FeesAndPaymentModal.propTypes = {
    event: PropTypes.object.isRequired,
    speaker: PropTypes.object.isRequired,
    payment: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

export default FeesAndPaymentModal;
