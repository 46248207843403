import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { gettext as __ } from "../../../../utils/Trans";
import CopyUrl from "../../../../components/CopyUrl";
import { Modal } from "react-bootstrap";
import { ShareInvoiceButton } from "../../components/Buttons";
import getSharedInvoiceLink from "../../../../actions/ShortList/getSharedInvoiceLink";

class ViewInvoiceModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (!this.props.shared_link) {
            this.props.loadInvoiceLink();
        }
    }

    render() {
        let {
            shared_link,
            is_espeakers,
            onClose
        } = this.props;

        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("View Invoice")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {(!is_espeakers) && <div className="help-block instruction">{__("Payment services are powered by eSpeakers.com.")}</div>}
                    <p>{__("You can view the invoice and make payments anytime using the link below.  You can even forward the link to a colleague, accounting department or 3rd party biller.")}</p>
                    <br />
                    <a href={shared_link} target="_blank" rel="noopener noreferrer" className="btn btn-success btn-block btn-lg">View Invoice</a>
                    <p className="help-block instruction"><strong>{__("Your date is not confirmed until payment is received.")}</strong>{"  "}{__("Communicate with the presenter if you expect it to take longer than 10 days for your check to arrive.")}</p>
                    <hr />
                    <p>{__("If you need someone else to pay this invoice (such as an accounting department or 3rd party biller), you can share a secure payment link with them by clicking the button below.")}</p>
                    <br />
                    <ShareInvoiceButton className="btn btn-info btn-block" {...this.props} />
                    <br />
                    {shared_link &&
                        <div>
                            <p>{__("Or copy this link and include it in your own email correspondence.")}</p>
                            <CopyUrl url={shared_link} />
                        </div>
                    }
                </Modal.Body>
                <Modal.Footer>
                    <div className="btn btn-default btn-block-xs" onClick={onClose}>
                        {__("Close")}
                    </div>
                </Modal.Footer>
            </Modal>
        );
    }
}

ViewInvoiceModal.propTypes = {
    event: PropTypes.object.isRequired,
    speaker: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

////////////////////////////////////////////////////////////////////////////////
//Connect it up
//

function mapStateToProps(state, ownProps) {
    let shared_link = _.get(state, ["BuyerEvents", _.get(ownProps.event, ["eid"]), "shared", "invoice", "url"]);
    return {
        shared_link: shared_link
    };
}

function mapDispatchToProps(dispatch, ownProps) {
    return {
        loadInvoiceLink: function() {
            return dispatch(getSharedInvoiceLink(_.get(ownProps.event, ["eid"])));
        }
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(ViewInvoiceModal);
