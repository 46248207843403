import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import SpeakerThumbnail from "../SpeakerThumbnail";

class SpeakerSummary extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    __onProfile = () => {
        if (_.isFunction(this.props.onProfile)) {
            return this.props.onProfile(this.props.speaker);
        }
    }

    render() {
        let {
            speaker,
            hide_profile_button,
            event_has_an_accepted_offer,
            speaker_has_an_accepted_offer,
            display_message,
            status_tag
        } = this.props;

        let speakername = _.get(speaker, ["name"]);
        if (!_.isEmpty(display_message)) {
            display_message = <div className="help-block instruction row" style={{marginBottom:"1em"}}>{display_message}</div>;
        }

        return (
            <div style={{margin: "15px"}}>
                {event_has_an_accepted_offer && !speaker_has_an_accepted_offer &&
                    <div className="help-block instruction row text-warning">{__("You have already accepted an offer with another speaker for this job.")}</div>
                }
                {display_message}
                <div className="row">
                    <div className="col-sm-3 col-md-3 text-center">
                        <SpeakerThumbnail
                            speaker={speaker}
                            size={123}
                            dont_show_name_on_hover={true}
                            className="img-responsive"
                        />
                    </div>
                    <div className="col-sm-9 col-md-9">
                        <h3 style={{margin: "0 0 10px 0", fontSize: "18px", letterSpacing: 0}}>
                            {!hide_profile_button &&
                                <div onClick={this.__onProfile}
                                   className="btn btn-default btn-sm btn-flat pull-right hidden-sm hidden-xs"
                                   target="__blank">
                                    {__("View Profile")}
                                </div>
                            }
                            {speakername}{" "}{status_tag}
                        </h3>
                        <div style={{margin: '0px 0px 20px 0px'}} dangerouslySetInnerHTML={{__html: _.get(speaker, ['bio_oneline'])}}/>

                    </div>
                </div>
            </div>
        );
    }
}

SpeakerSummary.propTypes = {
    speaker: PropTypes.object.isRequired,
    event_has_an_accepted_offer: PropTypes.bool,
    speaker_has_an_accepted_offer: PropTypes.bool,
    display_message: PropTypes.node,
};

export default SpeakerSummary;
