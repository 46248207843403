import React from "react";
import { Route } from "react-router";
import StandardLayout from "../StandardLayout";

export default class StandardLayoutRoute extends React.Component {

    render() {
        return (
            <StandardLayout {...this.props}>
                <Route {...this.props} />
            </StandardLayout>
        );
    }

}