import _ from "lodash";

// var nUnread = function(messages){
//     return _.size(_.filter(messages, function(msg){
//         return (msg.receiver_buyer_id > 0) && !msg.receiver_opened;
//     }));
// };
//
// var getNUnreadBySID = function(state, eid){
//     var messages = _.get(state, ["Messages", "messages", eid], []);
//
//     var r = {};
//     return _.mapValues(_.groupBy(messages, function(msg){
//         //groupBy sid
//         if(msg.sender_speaker_id > 0){
//             return msg.sender_speaker_id;
//         }
//         return msg.receiver_speaker_id;
//     }), nUnread);
// };

export default function (
    event,
    shortlist,
    messages,
    agreements,
    services,
    shortprofiles,
    user,
    selected_sid
) {
    let shortlist_speakers = _.filter(
        _.get(shortlist, ["speakers"]),
        function (speaker) {
            return (
                !_.get(
                    speaker,
                    ["flags_as_map", "banned_from_shortlist", "is_set"],
                    false
                )
            );
        }
    );

    let candidates = _.compact(
        _.map(shortlist_speakers, function (candidate) {
            let sid = _.get(candidate, ["speaker_id"]);
            let agreement = _.get(agreements, [sid]);
            let speaker = _.get(shortprofiles, [sid]);
            let n_unread = _.get(messages, ["sids", sid, "total"], 0);
            let has_offer =
                (_.get(agreement, ["flags"], 0) & 1) === 1 ||
                (_.get(agreement, ["flags"], 0) & 2) === 2 ||
                (_.get(agreement, ["flags"], 0) & 8) === 8;
            let offer_accepted =
                _.get(agreement, ["is_accepted"]) &&
                _.includes(_.map(_.get(event, ["sids"], []), _.toInteger), sid);
            let deposit_paid = _.includes(
                _.map(_.get(event, ["sids"], []), _.toInteger),
                sid
            );
            let is_selected = _.toInteger(sid) === _.toInteger(selected_sid);

            // Payments determine if the buyer has accepted an offer and paid the deposit to the speaker
            // let deposits = _.filter(_.get(payment, ["services"]), function(item) {
            //     return _.includes(_.get(item, ["flags"]), "IS_DEPOSIT");
            // });

            let deposits = _.filter(services, function (item) {
                return _.get(
                    item,
                    ["flags_as_map", "is_deposit", "is_set"],
                    false
                );
            });

            let deposit_amount = _.reduce(
                deposits,
                function (sum, item) {
                    return sum + parseFloat(item.fee);
                },
                0
            );

            if (deposit_amount > 0) {
                deposit_paid = _.reduce(
                    deposits,
                    function (deposit_paid, item) {
                        return deposit_paid && !!_.get(item, ["paid"], false);
                    },
                    deposit_paid
                );
            } else if (deposit_amount === 0) {
                deposit_paid =
                    _.includes(
                        _.map(_.get(event, ["sids"], []), _.toInteger),
                        sid
                    ) && _.get(user, ["stripe_cust_token"]);
            }

            // Determine if the event has past...then go to the Post Event Screen
            /* @todo Determine if the event has past...then go to the Post Event Screen */

            return _.assign({}, speaker, {
                sid: sid,
                name: _.get(speaker, ["name"]),
                is_selected: is_selected,
                n_unread: n_unread,
                offer_accepted: offer_accepted,
                has_offer: has_offer,
                deposit_paid: deposit_paid
            });
        })
    );

    candidates.sort(function (a, b) {
        //offer_accepted
        if (a.offer_accepted) {
            return -1;
        } else if (b.offer_accepted) {
            return 1;
        }
        //has_offer
        if (a.has_offer) {
            return -1;
        } else if (b.has_offer) {
            return 1;
        }
        //n_unread
        if (a.n_unread > b.n_unread) {
            return -1;
        } else if (b.n_unread > a.n_unread) {
            return 1;
        }
        //name
        var name_a = _.isString(a.name) ? a.name.trim().toLowerCase() : "";
        var name_b = _.isString(b.name) ? b.name.trim().toLowerCase() : "";
        if (name_a > name_b) {
            return 1;
        } else if (name_b > name_a) {
            return -1;
        }
        return 0;
    });
    return candidates;
}
