import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { gettext as __ } from "../../../../utils/Trans";
import { Message as MessageComponent } from "../../../../components/FormComponents";
import MessageSendButton from "../../../../components/MessageSendButton";
import Message from "../../../../actions/Messages/Message";

class MessageForm extends React.Component {

    handleSubmit = (values, dispatch, props) => {
        return dispatch(Message.add(values))
            .then(() => {
                return props.reset();
            })
            .then(() => {
                if (_.isFunction(props.onAfterSubmit)) {
                    return props.onAfterSubmit();
                }
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
            handleSubmit,
            submitSucceeded,
            submitting,
            error,
            speaker
        } = this.props;

        let recipient_name = _.get(speaker, ["name"], "the speaker");

        return (
            <Form className="MP-MessageSendForm mp-style-form" onSubmit={handleSubmit(this.handleSubmit)}>
                <div className="row">
                    <div className="col-xs-12 col-md-12">
                        <div style={{position: "relative"}}>
                            <Field
                                name="content"
                                placeholder={__("Reply to") + " " + recipient_name + "..."}
                                rows={10}
                                component={MessageComponent}
                            />
                            <MessageSendButton type="submit" is_sending={submitting} />
                        </div>
                        {submitSucceeded && <span className="text-success"> <i className="fa fa-check" /> {__("Message Sent")}</span>}
                        {error && <span className="text-danger"> <i className="fa fa-times" /> {_.get(error, ["message"])}</span>}
                    </div>
                </div>
            </Form>
        );
    }
}

MessageForm.propTypes = {
    thread: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
    event: PropTypes.object,
    speaker: PropTypes.object,
    onAfterSubmit: PropTypes.func
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

let mapStateToProps = function(state, ownProps){
    let eid = _.get(ownProps.event, ["eid"]);
    let sid = _.get(ownProps.speaker, ["sid"]);

    return {
        initialValues: {
            bureau_id: _.get(ownProps.whitelabel, ["bid"]),
            content: "",
            event_id: eid,
            to_sids: [sid]
        }
    };
};

let mapDispatchToProps = function(){
    return {

    };
};

MessageForm = reduxForm({
    form: "messagepage_sendmessageform",
    enableReinitialize: true,
    validate: function(values) {
        const errors = {};
        if (!values.content) {
            errors.content = __("Please enter a message to send.");
        }
        return errors;
    }
})(MessageForm);

export default connect(mapStateToProps, mapDispatchToProps)(MessageForm);