import _ from "lodash";
import PropTypes from "prop-types";
import React from "react";
import Helmet from "react-helmet";
import { Route } from "react-router";
import NavFooter from "../../../components/NavFooter";
import FlashError from "../FlashError";
import FlashNotification from "../FlashNotification";

class HeadlessLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            whitelabel,
            children,
            className = "container text-center"
        } = this.props;

        return (
            <div>
                <Helmet>
                    <title>The Right Speaker, Every Time | {_.get(whitelabel, ["bureauName"], "eSpeakers")}</title>
                </Helmet>
                <div className="layout-wrapper">
                    <FlashNotification />
                    <FlashError />
                    <div className={className + (_.get(whitelabel, ["is_bureau_mode"], false) ? " bureaumode" : "")}>
                        {children}
                    </div>
                </div>
                <Route path="/" render={() => {
                    //Analytics.logPageView(); //lots of multiple hits per page when we use this here
                    // if (typeof window.ga === "function") {
                    //     window.ga("set", "page", location.pathname + location.search);
                    //     window.ga("send", "pageview");
                    // }
                    return null;
                }} />
                <NavFooter whitelabel={whitelabel} />
            </div>
        );
    }
}

HeadlessLayout.propTypes = {
    className: PropTypes.string,
    children: PropTypes.node
};

export default HeadlessLayout;