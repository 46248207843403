import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";

export default class ErrorAlert extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            error
        } = this.props;

        return (
            <div className="panel panel-default" style={{width:"100%"}}>
                <div className="panel-heading">
                    <h3 className="panel-title text-danger"><i className="fa fa-exclamation-circle"></i> {__("Information about this error")}</h3>
                </div>
                <div className="panel-body">
                    <div className="text-danger">
                        {error
                            ? error
                            : __("An unknown error occurred, but we are checking the servers just in case.")
                        }
                    </div>
                    <hr />
                    {__("What you can do:")}
                    <ul>
                        <li>{__("Click the back button and try your request again.")}</li>
                        <li>{__("Make sure you are logged in with the proper credentials.")}</li>
                        <li><a href="/about-us/contact/">{__("Contact us")}</a>{__(" and let us know what you were doing before this happened.")}</li>
                    </ul>
                </div>
            </div>
        );
    }
}

ErrorAlert.propTypes = {
    error: PropTypes.string
};