import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import LoadingIndicator from "../../components/LoadingIndicator";
import queryString from "query-string";
import {Link} from "react-router-dom";
import { Facebook, Google, LinkedIn, Mpi } from "../../actions/Login/Oauth";
import Whitelabel from "../../actions/Global/Whitelabel";
import toShortlistURL from "../../utils/toShortlistURL";

export class OauthCallbackPage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            oauth_error: null
        };
    }

    componentDidMount() {
        this.props.signinOauth()
          .catch((e) => (
            this.setState({
                oauth_error: _.get(e, ["message"])
            })
          ))
    }

    render() {

        return (
            <div>
                <div className="row">
                    {_.isNull(this.state.oauth_error) && <LoadingIndicator />}
                    {this.state.oauth_error && <React.Fragment>
                        <div className="alert alert-danger">{this.state.oauth_error}</div>
                        <Link to={"/signin"}>&lt;&lt; Back to login</Link>
                    </React.Fragment>}
                </div>
            </div>
        );
    }

}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

let mapStateToProps = function(){
    return {};
};

let mapDispatchToProps = function(dispatch, ownProps){
    return {
        signinOauth: function() {
            if (_.get(ownProps.match, ["params", "service_id"]) === "google") {
                let qs = queryString.parse(window.location.hash);
                let state_parts = _.split(_.get(qs, ["state"]), "[$]");
                let redir = _.last(state_parts);
                // let account_type = _.get(state_parts, [0]);
                let bureau_id = _.get(state_parts, [1]);
                if (redir === "") { redir = "/event"; }
                return dispatch(Google.signin(_.get(qs, ["access_token"]), _.get(qs, ["state"]), Google.getCallbackUrl("google", ownProps.whitelabel)))
                    .then(function (response) {
                        if (bureau_id === _.get(ownProps.whitelabel, ["bid"])) {
                            ownProps.history.push(redir);
                        } else {
                            dispatch(Whitelabel.get(bureau_id))
                            .then((wl) => {
                                window.location.href = toShortlistURL(redir, _.get(wl, ["whitelabel"]));
                            });
                        }
                    })
                    .catch((err) => {
                        console.log("ERROR", err);
                        throw err;
                    });
            } else if (_.get(ownProps.match, ["params", "service_id"]) === "facebook") {
                let qs = queryString.parse(window.location.search);
                let state_parts = _.split(_.get(qs, ["state"]), "[$]");
                let redir = _.last(state_parts);
                // let account_type = _.get(state_parts, [0]);
                let bureau_id = _.get(state_parts, [1]);
                if (redir === "") { redir = "/event"; }
                return dispatch(Facebook.signin(_.get(qs, ["code"]), _.get(qs, ["state"]), Facebook.getCallbackUrl("facebook", ownProps.whitelabel)))
                    .then(function (response) {
                        if (bureau_id === _.get(ownProps.whitelabel, ["bid"])) {
                            ownProps.history.push(redir);
                        } else {
                            dispatch(Whitelabel.get(bureau_id))
                                .then((wl) => {
                                    window.location.href = toShortlistURL(redir, _.get(wl, ["whitelabel"]));
                                });
                        }
                    })
                    .catch((err) => {
                        console.log("ERROR", err);
                    });
            } else if (_.get(ownProps.match, ["params", "service_id"]) === "linkedin") {
                let qs = queryString.parse(window.location.search);
                let state_parts = _.split(_.get(qs, ["state"]), "[$]");
                let redir = _.last(state_parts);
                // let account_type = _.get(state_parts, [0]);
                let bureau_id = _.get(state_parts, [1]);
                if (redir === "") {
                    redir = "/event";
                }
                return dispatch(LinkedIn.signin(_.get(qs, ["code"]), _.get(qs, ["state"]), LinkedIn.getCallbackUrl("linkedin", ownProps.whitelabel)))
                    .then(function (response) {
                        if (bureau_id === _.get(ownProps.whitelabel, ["bid"])) {
                            ownProps.history.push(redir);
                        } else {
                            dispatch(Whitelabel.get(bureau_id))
                                .then((wl) => {
                                    window.location.href = toShortlistURL(redir, _.get(wl, ["whitelabel"]));
                                });
                        }
                    })
                    .catch((err) => {
                        console.log("ERROR", err);
                    });
            } else if (_.get(ownProps.match, ["params", "service_id"]) === "mpi") {
                let qs = queryString.parse(window.location.search);
                let state_parts = _.split(_.get(qs, ["state"]), "[$]");
                let redir = _.last(state_parts);
                // let account_type = _.get(state_parts, [0]);
                let bureau_id = _.get(state_parts, [1]);
                if (redir === "") {
                    redir = "/event";
                }
                return dispatch(Mpi.signin(_.get(qs, ["code"]), _.get(qs, ["state"]), Mpi.getCallbackUrl("mpi", ownProps.whitelabel)))
                    .then(function (response) {
                        if (bureau_id === _.get(ownProps.whitelabel, ["bid"])) {
                            ownProps.history.push(redir);
                        } else {
                            dispatch(Whitelabel.get(bureau_id))
                                .then((wl) => {
                                    window.location.href = toShortlistURL(redir, _.get(wl, ["whitelabel"]));
                                });
                        }
                    })
                    .catch((err) => {
                        console.log("ERROR", err);
                    });
            } else {
                return false;
            }
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(OauthCallbackPage);
