import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import { Modal } from "react-bootstrap";
import CancelEventForm from "./CancelEventForm";

class CancelEventModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            event,
            onClose
        } = this.props;

        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("Cancel Event")}</Modal.Title>
                </Modal.Header>
                <CancelEventForm
                    event={event}
                    onClose={onClose}
                />
            </Modal>
        );
    }
}

CancelEventModal.propTypes = {
    event: PropTypes.object.isRequired,
    onClose: PropTypes.func
};

export default CancelEventModal;
