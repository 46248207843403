import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { Form, reduxForm, SubmissionError } from "redux-form";
import { gettext as __ } from "../../../../utils/Trans";
import LoadingSubmit from "../../../../components/LoadingSubmit";
import InlineError from "../../../../components/InlineError";
import bureaumodePickMe from "../../../../actions/ShortList/bureaumodePickMe";
import RefreshMPEvent from "../../../../actions/Event/RefreshMPEvent";

class BureauPickMeForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch, props) {
        return dispatch(bureaumodePickMe(values))
            .then(() => {
                return dispatch(RefreshMPEvent(_.get(props.event, ["eid"])));
            })
            .then(() => {
                if (_.isFunction(props.onClose)) {
                    props.onClose();
                }
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    render() {
        let {
            speaker_name,
            handleSubmit,
            submitting,
            error,
            onClose
        } = this.props;

        return (
            <Form role="form" onSubmit={handleSubmit(this.handleSubmit)}>
                <div className="modal-body">
                    {error && <InlineError error={_.get(error, ["message"])} />}
                    <div className="modal-body">
                        <p>{__("Confirm that you'd like to select %s as your presenter for this meeting?", [speaker_name])}</p>
                    </div>

                </div>
                <div className="modal-footer">
                    <LoadingSubmit is_saving={submitting} save_text={__("Alerting Staff...")} className="btn-success btn-block-xs">
                        {__("Select")}
                    </LoadingSubmit>
                    {" "}
                    <div className="btn btn-default btn-block-xs" disabled={submitting} onClick={onClose}>
                        {__("Not Yet")}
                    </div>
                </div>
            </Form>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

BureauPickMeForm.propTypes = {
    event: PropTypes.object.isRequired,
    speaker: PropTypes.object.isRequired
};

function mapStateToProps(state, ownProps) {
    return {
        speaker_name: _.get(ownProps.speaker, ["speakername"], "this speaker"),
        initialValues: {
            event_id: _.get(ownProps.event, ["eid"]),
            speaker_id: _.get(ownProps.speaker, ["sid"])
        }
    };
}

function mapDispatchToProps() {
    return {

    };
}

BureauPickMeForm = reduxForm({
    form: "shortlist_page_bureau_pick_me",
    validate: function(values) {
        const errors = {};
        if (!values.initials) {
            errors.initials = __("Please initial your acceptance of this agreement.");
        }
        return errors;
    }
})(BureauPickMeForm);

export default connect(mapStateToProps, mapDispatchToProps)(BureauPickMeForm);