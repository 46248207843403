import _ from "lodash";
import React from "react";

import "./index.scss";

class ProgressSteps extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            selected_i,
            steps
        } = this.props;

        steps = _.map(steps, function(step, i){
            return (
                <li key={i} className={i <= selected_i ? "active" : ""}>
                    <span className="title">{step}</span>
                    <span className="circle"></span>
                </li>
            );
        });

        return (
            <div className="progress-steps no-select">
                <ul>{steps}</ul>
            </div>
        );
    }
}

export default ProgressSteps;
