import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import CandidateListItem from "../CandidateListItem";
import { gettext as __ } from "../../../../utils/Trans";

export default class CandidateList extends React.Component {

    render() {
        let {
            has_offer = false,
            candidates
        } = this.props;

        return (
            <div className="row">
                <div className="col-sm-5">
                    {_.map(_.slice(candidates, 0, _.min([_.ceil(_.size(candidates)/2), 3])), function(candidate, i) {
                        return (<CandidateListItem key={i} candidate={candidate} has_offer={has_offer} />);
                    })}
                </div>
                <div className="col-sm-5">
                    {_.map(_.slice(candidates, _.min([_.ceil(_.size(candidates)/2), 3]), 5), function(candidate, i) {
                        return (<CandidateListItem key={i} candidate={candidate} has_offer={has_offer} />);
                    })}
                    {(_.size(candidates) > 5) &&
                    <div className="clearfix" style={{padding:"3px", opacity: (has_offer ? "0.3" : "1")}}>
                        <div className="pull-left">
                            <i className="fa fa-users" style={{fontSize:"20px"}} />
                        </div>
                        <div className="pull-left text-muted" style={{marginLeft:"10px"}}>
                            +{_.size(candidates) - 5}{" "}{__("More...")}
                        </div>
                    </div>
                    }
                </div>
            </div>
        );
    }
}

CandidateList.propTypes = {
    candidates: PropTypes.array.isRequired
};