import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../../utils/Trans";
import { Col, Row } from "react-bootstrap";
import VirtualReviews from "./VirtualReviews";
import VirtualFees from "./VirtualFees";
import VirtualPrograms from "./VirtualPrograms";

export default class Virtual extends React.Component {

  state = {
    show_virtual_programs: false,
    show_fees: false,
    show_reviews: false,
    speaker: null
  }

  static propTypes = {
    speaker: PropTypes.object.isRequired
  }

  static getDerivedStateFromProps = (props) => ({
    speaker: props.speaker,
    show_fees: (_.get(props.speaker, ["v_low_fee"], 0) + _.get(props.speaker, ["v_high_fee"], 0) > 0),
    show_virtual_programs: _.size(_.filter(_.get(props.speaker, ["programs"]), (program) => (_.get(program, "presenter_type") & 128))) > 0,
    show_reviews: _.size(_.filter(_.get(props.speaker, ["programs"]), (program) => (_.get(program, "presenter_type") & 128))) > 0
  })

  __onShowTab = (tab) => {
    if (_.isFunction(this.props.onShowTab)) {
      return this.props.onShowTab(null, tab);
    }
  }

  render() {
    let {
      speaker,
      show_fees,
      show_virtual_programs,
      show_reviews
    } = this.state;
    // console.log(show_virtual_programs)
    return (
      <React.Fragment>
        <div style={{padding: "0px 0px 40px 0px"}}>
          <h3 className="header">
            {__("Virtual Programs and Fees")}
          </h3>
          <div>
            <div>
              <p>This speaker has presentations designed for live video delivery over meeting platforms like Zoom, WebEx, and GotoMeeting.</p>
            </div>
          </div>
          <div style={{padding: "20px 20px"}}>
            <Row>
              {show_virtual_programs && <Col sm={12} md={show_fees ? 7 : 12} lg={show_fees ? 8 : 12}><VirtualPrograms speaker={speaker} onShowTab={this.__onShowTab}/></Col>}
              {show_fees && <Col sm={12} md={show_virtual_programs ? 5 : 12} lg={show_virtual_programs ? 4 : 12}><VirtualFees speaker={speaker} /></Col>}
            </Row>
            {show_reviews && <VirtualReviews speaker={speaker} />}
          </div>
        </div>
      </React.Fragment>
    );
  }
}