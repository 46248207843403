import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import WithdrawOfferModal from "../../modals/WithdrawOfferModal";

class WithdrawOfferButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_modal: false
        };
    }

    __toggleModal = () => {
        this.setState({
            show_modal: !this.state.show_modal
        });
    }

    render() {
        let {
            className = "btn btn-danger btn-flat btn-block"
        } = this.props;

        return (
            <span>
                <div
                    className={className}
                    onClick={this.__toggleModal}
                >
                    {__("Withdraw My Offer")}
                </div>
                {this.state.show_modal && <WithdrawOfferModal {...this.props} onClose={this.__toggleModal} />}
            </span>
        );
    }
}

WithdrawOfferButton.propTypes = {
    event: PropTypes.object.isRequired,
    speaker: PropTypes.object.isRequired,
    className: PropTypes.string
};

export default WithdrawOfferButton;