import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Nav, NavItem } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import toMpURL from "../../../../../utils/toMpURL";
import { gettext as __ } from "../../../../../utils/Trans";
import UnreadBadge from "../../../../UnreadBadge";
import NavShortlist from "./NavShortlist";
import NavContactFavorites from "./NavContactFavorites";
import AuthControl from "./AuthControl";
import Analytics from "../../../../../utils/Analytics";


export default class Menu extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    __SignupOnclick() {
        Analytics.sendEvent("BuyerSignup", "click", 0, "navbar");
    }

    __LoginOnClick() {
        Analytics.sendEvent("BuyerLogin", "click", 0, "navbar");
    }

    render() {
        let {
            user,
            whitelabel,
            borderColor = "#EEE",
            n_unread_messages,
            is_admin_or_manage,
            shortlist,
            onRemoveFromShortlist,
            onLogout,
            onProfile
        } = this.props;

        let is_directory_mode = _.get(whitelabel, ["is_directory_mode"]);
        let is_hide_public_jobs = _.get(whitelabel, ["mp_keyvalues", "MP_DISABLE_PUBLIC_JOB_BOARD"], false);
        let is_bureau_mode = _.get(whitelabel, ["is_bureau_mode"]);
        let is_publisher = (_.get(user, ["role"], "guest") === "publisher");
        let is_logged_in = (_.get(user, ["role"], "guest") !== "guest");
        let backstage_user_that_can_manage_this_bid = _.get(user, ["backstage_user_that_can_manage_this_bid"], false);
        let is_show_my_jobs = (!is_directory_mode && !is_bureau_mode) && !(is_publisher && backstage_user_that_can_manage_this_bid);


        if (is_admin_or_manage) {
            // Admin/manage pages need to directly reference the /marketplace pages since they are no longer relative links
            return (
                <Nav pullRight={true}>
                    <NavShortlist shortlist={shortlist} onRemoveFromShortlist={onRemoveFromShortlist} onProfile={onProfile} />
                    <NavItem href={toMpURL("/", whitelabel)} className="mp-text-header">
                        <i className="fa fa-arrow-left fa-fw mp-text-tertiary" />{" "}
                        {__("Back to Directory")}
                    </NavItem>
                    {!is_logged_in && !is_directory_mode &&
                    <LinkContainer to={"/signup"} onClick={this.__SignupOnclick}>
                        <NavItem className="mp-text-header">
                            {__("Sign Up")}
                        </NavItem>
                    </LinkContainer>
                    }
                    {!is_logged_in && !is_directory_mode &&
                    <LinkContainer to={"/signin"} onClick={this.__LoginOnClick}>
                        <NavItem className="mp-text-header">
                            {__("Log In")}
                        </NavItem>
                    </LinkContainer>
                    }
                    {/*<TransBtns/>*/}
                    {is_logged_in && !is_directory_mode &&
                        <AuthControl
                            user={user}
                            whitelabel={whitelabel}
                            onLogout={onLogout}
                            is_admin_or_manage={is_admin_or_manage}
                        />
                    }
                </Nav>
            );
        } else {
            return (
                <Nav pullRight={true} style={{borderLeft: "1px solid " + borderColor, paddingLeft: "20px"}}>
                    {!is_directory_mode &&
                        <NavShortlist
                            shortlist={shortlist}
                            onRemoveFromShortlist={onRemoveFromShortlist}
                            onProfile={onProfile}
                        />
                    }
                    {is_show_my_jobs && _.isEmpty(shortlist) && !is_hide_public_jobs &&
                    <LinkContainer to={"/postapublicjob"} className="hidden-sm">
                        <NavItem className="mp-text-header">{__("Call For Speakers")}</NavItem>
                    </LinkContainer>
                    }
                    {!is_directory_mode && !_.isEmpty(shortlist) &&
                        <NavContactFavorites shortlist={shortlist} />
                    }
                    {is_show_my_jobs && is_logged_in &&
                    <LinkContainer to={"/event"}>
                        <NavItem className="mp-text-header">
                            {__("My Jobs")}
                        </NavItem>
                    </LinkContainer>
                    }
                    {is_show_my_jobs && is_logged_in &&
                    <LinkContainer to={"/messages"}>
                        <NavItem className="mp-text-header">
                            {__("My Messages")}
                            {" "}
                            {n_unread_messages > 0 &&
                            <UnreadBadge
                                n_unread={n_unread_messages}
                                style={{position: "relative", top: "-2px", fontSize: "x-small"}}
                            />
                            }
                        </NavItem>
                    </LinkContainer>
                    }
                    {!is_logged_in && !is_directory_mode &&
                    <LinkContainer to={"/signup"}  onClick={this.__SignupOnclick}>
                        <NavItem className="mp-text-header">
                            {__("Sign Up")}
                        </NavItem>
                    </LinkContainer>
                    }
                    {!is_logged_in && !is_directory_mode &&
                    <LinkContainer to={"/signin"} onClick={this.__LoginOnClick}>
                        <NavItem className="mp-text-header">
                            {__("Log In")}
                        </NavItem>
                    </LinkContainer>
                    }
                    {/*<TransBtns/>*/}
                    {is_logged_in && !is_directory_mode &&
                        <AuthControl
                            user={user}
                            whitelabel={whitelabel}
                            n_unread_messages={n_unread_messages}
                            onLogout={onLogout}
                            is_admin_or_manage={is_admin_or_manage}
                        />
                    }
                </Nav>
            );
        }
    }
}

Menu.propTypes = {
    user: PropTypes.object.isRequired,
    whitelabel: PropTypes.object,
    onLogout: PropTypes.func,
    onProfile: PropTypes.func,
    onRemoveFromShortlist: PropTypes.func,
    n_unread_messages: PropTypes.number,
    shortlist: PropTypes.oneOfType([PropTypes.object, PropTypes.array])

};