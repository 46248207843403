import React from "react";
// import { Link } from "react-router-dom";
import { gettext as __ } from "../../utils/Trans";
import { Panel } from "react-bootstrap";

export default class SpeakerMissingPage extends React.Component {

    render() {
        return (
            <div className={"row"} style={{marginTop:"55px"}}>
                <div className={"col-sm-6 col-sm-offset-3"}>
                    <Panel>
                        <Panel.Body>
                            <div className="text-center">
                                <p>
                                    <i className={"fa fa-meh-o"} style={{fontSize: "8em", color: "#E8E8E8"}} aria-hidden="true"/>
                                </p>
                                <p style={{margin: "2em 0em"}}>
                                    {__("Hmmm...The speaker you're looking for isn't listed on this directory.")}{" "}
                                    {__("They could be exclusive to another directory or have hidden their profile.")}
                                </p>
                                <p style={{margin: "2em 0em"}}>
                                    <b>{__("That's okay!  We have many more great speakers you can view...")}</b>
                                </p>
                            </div>
                            <div className="text-center">
                                <a href="/marketplace/search"><div className="btn btn-lg btn-success">{__("Search our entire directory")}</div></a>
                            </div>
                        </Panel.Body>
                    </Panel>
                </div>
            </div>
        );
    }

}