import Promise from "es6-promise";
import CookiePostAJob from "../../utils/CookiePostAJob";

export default function(data) {
    return function(dispatch) {
        return new Promise((resolve) => {
            // Save the job to the cookie
            CookiePostAJob.add(data);
            // Also save to the state
            return resolve(dispatch({
                type: "POSTAJOBPAGE_SAVEPENDINGJOB",
                data: data
            }));
        });
    }
};
