import React from "react";
import PropTypes from "prop-types";

import "./AlertBadge.scss";

class AlertBadge extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            style = {}
        } = this.props;

        return (
            <span className="AlertBadge badge" style={style}>
                {this.props.children}
            </span>
        );
    }
}

AlertBadge.propTypes = {
    style: PropTypes.object,
    children: PropTypes.node
};

export default AlertBadge;