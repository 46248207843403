import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { change, submit } from "redux-form";
import { Alert, Button, Modal } from "react-bootstrap";
import { gettext as __ } from "../../../../utils/Trans";

class JobBoardModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    __onSelectJobBoard = () => {
        this.props.updateJobBoard(true)
          .then(() => {
            if (_.isFunction(this.props.onShowJobConfirmModal)) {
                return this.props.onShowJobConfirmModal();
            }
          });
    }

    __onSelectNoJobBoard = () => {
        this.props.updateJobBoard(false)
          .then(() => {
            if (_.isFunction(this.props.onShowJobConfirmModal)) {
                return this.props.onShowJobConfirmModal();
            }
          });
    }

    render() {
        let {
            onHide
        } = this.props;

        return (
            <Modal show={true} onHide={onHide} backdrop={true}>
                <Modal.Header closeButton>
                    <Modal.Title>{__("Would you like more options?")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="help-block">
                        <Alert bsStyle="warning">
                            <i className="fa fa-lightbulb-o fa-3x pull-left" />
                            <b>{__("Don't spend time searching for the perfect speaker; let them come to you!")}</b>{" "}
                            {__("Did you know that when you post your event to our Public Job Board, over 10,000 speaking professionals will see it today?")}
                        </Alert>
                        <p>
                            {__("Speakers traveling through your area or that live near you will be invited to review your job posting.  They may send you a message or proposal with reduced fees since they don't have to travel as far.  You can review all the speakers (including the ones you have picked) and choose the best speaker for your event.")}
                        </p>
                        <div style={{marginTop: "2em", marginLeft: "2em", marginRight: "2em"}} className="text-center hidden-xs hidden-sm">
                            <Button onClick={this.__onSelectJobBoard} bsSize="lg" bsStyle="success">{__("YES, I want to post my event to the Public Job Board!")}</Button>
                            <br />
                            <Button onClick={this.__onSelectNoJobBoard} bsStyle="link">{__("No, just message my chosen speakers")}</Button>
                        </div>
                        <div style={{marginTop: "2em", marginBottom:"2em"}} className="visible-xs visible-sm text-center">
                            <Button className="btn-block" onClick={this.__onSelectJobBoard} bsSize="lg" bsStyle="success">{__("Post to Public Job Board!")}</Button>
                            <br />
                            <Button onClick={this.__onSelectNoJobBoard} bsStyle="link">{__("Just my selected speakers")}</Button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {

    return {};
};

var mapDispatchToProps = function (dispatch, ownProps) {
    return {
        updateJobBoard: function(value) {
            return new Promise((resolve) => {
                return resolve(dispatch(change("postaprivatejob", "post_public", value)));
            });
        },
        onSubmit: function() {
            return dispatch(submit("postaprivatejob"));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobBoardModal);