import _ from "lodash";
import Balboa from "../Global/Balboa";
import convertBalboa3EventToJSEvent from "../../utils/convertBalboa3EventToJSEvent";

export default function(eid, clear_cache = false) {
    return (dispatch, getState) => {
        var key = ["getMPEvent", eid];
        var cache_ttl = clear_cache ? -1 : 30 * 60 * 1000;
        return Balboa(dispatch, getState)
            .post(key, "/event/read/" + eid, {}, {cache_ttl: cache_ttl})
            .then((response) => {
                var event = convertBalboa3EventToJSEvent(_.get(response, ["data", "event"], {}));
                // Save the user to the state
                return dispatch({
                    type: "BUYER_EVENT_UPDATE",
                    eid: eid,
                    data: event
                });
            })
            .then((response) => {
                dispatch({
                    type: "BALBOA3_CLEAR_KEY",
                    key: key
                });
                return response;
            });
    };
};
