import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";

export default class InvoiceTitle extends React.Component {

    render() {
        let {
            event
        } = this.props;

        return (
            <h1 className="text-center" style={{margin:"25px 0px"}}>
                <div>{__("Invoice for")}{" "}{_.get(event, ["organization"], "Event")}</div>
                <div className="small">{_.get(event, ["meetingname"])}{" "}(#{_.get(event, ["eid"])})</div>
            </h1>
        );
    }
}

InvoiceTitle.propTypes = {
    event: PropTypes.object.isRequired
}

