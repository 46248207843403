import _ from "lodash";

export const OFFER_STATII = [null, "offer pending", "offer accepted", "deposit paid", "paid in full", "event completed", "reviewed"]; //order of priority is important

export default function (state, eid) {

    let has_an_offer = false; // check that we have at least one offer on a speaker
    let has_an_accepted_offer = false; // check that we have at least an accepted offer on a speaker
    let deposit_paid = false; // used to check if the deposit has been paid
    let balance_paid = false; // used to check if the balance has been paid

    let offerstatus = 0; //0=no offer, 1=offer pending, 2=offer accepted, 3=deposit paid, 4=completely paid, etc.. (in order of OFFER_STATII)
    let buyer_event = _.get(state, ["Events", "events", eid], {});

    // DEPOSIT PAID
    // Payments determine if the buyer has accepted an offer and paid the deposit to the speaker
    let deposits = _.filter(_.get(buyer_event, ["Services"]), function (item) {
        return _.get(item, ["flags_as_map", "is_deposit", "is_set"], false);
    });

    deposit_paid = _.reduce(deposits, function (deposit_paid, item) {
        return deposit_paid || !!_.get(item, ["paid"], false);
    }, deposit_paid);

    // BALANCE PAID
    // Payments determine if the buyer has accepted an offer and paid the deposit to the speaker
    let balances = _.filter(_.get(buyer_event, ["Services"]), function (item) {
        return _.get(item, ["flags_as_map", "balance", "is_set"], false);
    });

    balance_paid = _.reduce(balances, function (balance_paid, item) {
        return balance_paid || !!_.get(item, ["paid"], false);
    }, balance_paid);

    let shortlist_speakers = _.get(buyer_event, ["Shortlists", 0, "speakers"], {});
    _.forEach(shortlist_speakers, function (shortlist_speaker) {
        let sid = _.get(shortlist_speaker, ["speaker_id"]);
        if (sid > 0) {
            // Check if we have at least one pending offer
            if (null !== _.get(buyer_event, ["Agreements", sid], null)) {
                has_an_offer = true;
                offerstatus = _.max([offerstatus, 1]);
            } //if there _is_ an agreement, we're at least offer-pending

            // Check if we have at least one accepted offer
            if (true === _.get(buyer_event, ["Agreements", sid, "is_accepted"], false)) {
                has_an_accepted_offer = true;
                offerstatus = _.max([offerstatus, 2]);
            }
        }
    });

    if (has_an_offer) {
        offerstatus = 1;
    }

    // Only perform these checks if we have an accepted offer
    if (has_an_accepted_offer) {
        // Check if the deposit has been paid
        if (deposit_paid) {
            offerstatus = _.max([offerstatus, 3]);
        }

        // Check if everything has been paid in full
        if (balance_paid) {
            offerstatus = _.max([offerstatus, 4]);
        }

        // Check if something has been paid and the event has already happened
        if ((deposit_paid || balance_paid) && _.get(buyer_event, ["starttime"]) < new Date()) {
            offerstatus = _.max([offerstatus, 5]);
        }
    }

    //TODO: detect reviewed
    return OFFER_STATII[offerstatus];
}

