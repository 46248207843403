import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import formatCurrency from "../../../../utils/formatCurrency";
import FeeLine from "./FeeLine";

class FeesAndPayment extends React.Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let { payment } = this.props;

        return (
            <div>
                <table className="table table-responsive">
                    <thead>
                        <tr>
                            <th>{__("Description")}</th>
                            <th className="text-right">{__("Amount")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {_.map(_.sortBy(payment, "due"), function (item, i) {
                            return <FeeLine key={i} fee={item} />;
                        })}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={1}>{__("TOTAL")}</td>
                            <td className="text-right">
                                {formatCurrency(
                                    _.reduce(
                                        payment,
                                        function (sum, fee) {
                                            return sum + parseFloat(fee.fee, 2);
                                        },
                                        0
                                    ),
                                    2
                                )}
                            </td>
                        </tr>
                    </tfoot>
                </table>
            </div>
        );
    }
}

FeesAndPayment.propTypes = {
    payment: PropTypes.object.isRequired
};

export default FeesAndPayment;
