import _ from "lodash";
import React from "react";
import { OverlayTrigger } from "react-bootstrap";
import PopoverMenu from "../../PopoverMenu";

export default class SearchSort extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            label_value: props.placeholder,
            label_icon: "",
            options: props.options,
            value: props.defaultValue,
            onChange: props.input.onChange
        };
    }

    componentDidMount() {
        let self = this;
        _.each(_.split(this.props.input.value, ","), function(val) {
            if (val !== "") {
                self.onCheckValue(val, true, false);
            }
        });
    }

    /**
     * Pass onChange up to the input
     */
    onChange = (value) => {
        if (_.isFunction(this.props.input.onChange)) {
            return this.props.input.onChange(value);
        }
    }

    /**
     * Whenever a checkbox is checked, go here to check/uncheck it in the options
     */
    onCheckValue = (value, checked, triggerChange) => {
        let selected_options = this.state.options;
        selected_options = _.forEach(selected_options, function(item) {
            _.set(item, ["selected"], false);
        });

        let obj = _.find(selected_options, {value: value});
        let index = _.findIndex(selected_options, {value: value});
        if (!_.isUndefined(obj)) {
            _.set(obj, ["selected"], checked);
            // Replace item at index using native splice
            selected_options.splice(index, 1, obj);
            return this.setState({
                options: selected_options
            }, () => {
                return this.updateValue(triggerChange);
            });
        }
        return false;
    }

    /**
     * Whenever a checkbox is checked, go here to check/uncheck it in the options
     */
    onCheckOption = (elem) => {
        if (elem.target.checked) {
            return this.onCheckValue(elem.target.value, true, true);
        } else {
            return this.onCheckValue(elem.target.value, false, true);
        }
    }

    /**
     * Updates the internal selected state, the display label, and the saved value
     */
    updateValue = (triggerChange) => {
        let selected_value = _.head(_.filter(this.state.options, {selected: true}));

        this.setState({
            label_icon: _.get(selected_value, "icon"),
            label_value: _.get(selected_value, "label"),
            value: _.get(selected_value, "value")
        }, () => {
            if (triggerChange) {
                this.onChange(this.state.value);
            }
        });
    }

    render() {
        let {
            input,
            label,
            className = "",
            icon_className = "text-success",
            trigger = "click",
            placement = "bottom",
            hide_errors,
            meta: {
                touched,
                error,
                warning
            }
        } = this.props;

        let onCheckOption = this.onCheckOption;

        let overlay = <PopoverMenu>
            <div style={{padding: "10px"}}>
                {_.map(this.state.options, function(item, i) {
                    return (<div key={i} className={_.get(item, ["className"], className)}><label><input type="radio" value={_.get(item, ["value"])} checked={_.get(item, ["selected"], false)} onChange={onCheckOption} />{" "}{_.get(item, ["label"])}</label></div>);
                })}
            </div>
        </PopoverMenu>;

        return (
            <div className={className + " " + (touched && error ? "has-error " : touched && warning ? "has-warning " : "")}>
                <label>
                    {label}
                    <OverlayTrigger rootClose={true} trigger={trigger} placement={placement} overlay={overlay}>
                        <div style={{borderBottom: "2px solid #C0C0C0"}}>
                            {this.state.label_icon &&
                                <i className={"fa fa-fw " + this.state.label_icon + " " + icon_className}/>
                            }
                            {" "}
                            {this.state.label_value}{" "}
                            <i className="fa fa-caret-down" />
                        </div>
                    </OverlayTrigger>
                </label>
                {!hide_errors && touched &&
                ((error && <div className="text-danger"><i className="fa fa-times"/> {error}</div>) ||
                    (warning && <div className="text-danger"><i className="fa fa-exclamation-triangle"/> {warning}</div>))}
            </div>
        );
    }
}

SearchSort.propTypes = {

};