import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { gettext as __ } from "../../../../utils/Trans";
import { Modal } from "react-bootstrap";
import MakeOfferForm from "./MakeOfferForm";

class MakeOfferModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            is_espeakers,
            speaker,
            event,
            user,
            onClose
        } = this.props;

        return (
            <Modal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{__("Make Offer")}</Modal.Title>
                </Modal.Header>
                {speaker && event &&
                    <MakeOfferForm
                        event={event}
                        speaker={speaker}
                        is_espeakers={is_espeakers}
                        user={user}
                        onClose={onClose}
                    />
                }
            </Modal>
        );
    }
}

MakeOfferModal.propTypes = {
    event: PropTypes.object.isRequired,
    speaker: PropTypes.object.isRequired,
    whitelabel: PropTypes.object,
    user: PropTypes.object,
    onClose: PropTypes.func
};
////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function(state, ownProps){

    return {
        is_espeakers: _.get(ownProps.whitelabel, ["is_espeakers"], false)
    };
};

var mapDispatchToProps = function(){
    return {

    };
};

export default connect(mapStateToProps, mapDispatchToProps)(MakeOfferModal);
