import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { gettext as __ } from "../../utils/Trans";
import { Field, Form, reduxForm, SubmissionError } from "redux-form";
import { Password, Username } from "../../components/FormComponents";
import InlineError from "../../components/InlineError";
import LoadingSubmit from "../../components/LoadingSubmit";
import MpiSignInLegacy from "../../actions/Login/MpiSignInLegacy";
import Analytics from "../../utils/Analytics";

/* THIS_IS_FOR_MPI don't remove this comment, it's so one can quickly find all the MPI custom code */
/** Any changes to this visible to the consumer should be reported in advance to:
 * achild@mpiweb.org
 * mcrumrine@mpiweb.org
 */

class LoginForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    handleSubmit(values, dispatch, props) {
        return dispatch(MpiSignInLegacy(values))
            .then(() => {
                props.history.push(props.redir || "/event");
            })
            .catch((err) => {
                throw new SubmissionError({_error: err});
            });
    }

    LoginOnClick() {
        Analytics.sendEvent("BuyerLogin", "click", 0, "signup page");
    }

    render() {
        let {
            handleSubmit,
            submitting,
            error
        } = this.props;

        return (
            <div>
                <Form className="mp-style-form" onSubmit={handleSubmit(this.handleSubmit)}>
                    <Field
                        name="username"
                        className="form-control"
                        label="MPI Username"
                        component={Username}
                    />
                    <Field
                        name="password"
                        className="form-control"
                        label="MPI Password"
                        component={Password}
                    />
                    <LoadingSubmit is_saving={submitting} save_text={__("Signing In...")} className="btn-success">
                        {__("Sign In")}
                    </LoadingSubmit>
                    {error && <InlineError error={_.get(error, ["message"])} />}
                </Form>
                <br/>
                <br/>
                <p>
                    <a href="https://sts.mpiweb.org/SignUp/" target="_blank" rel="noopener noreferrer">
                        {__("Get a free website account on mpiweb.org")}  &raquo;
                    </a>
                </p>
                <p>
                    <a href="https://sts.mpiweb.org/Forgot/" target="_blank" rel="noopener noreferrer">
                        {__("Forgot user MPI username or password?")}  &raquo;
                    </a>
                </p>
                <p>
                    <Link to={"/signin"}  onClick={this.LoginOnClick.bind(this)}>
                        &laquo; {__("Let me sign in another way.")}
                    </Link>
                </p>

                <p style={{fontStyle: "italic", color: "grey"}} className="text-muted">
                    {__("Note: Creating an MPIWeb.org user account does not require or provide")}
                    {" "}
                    <a href="https://sts.mpiweb.org/membership" target="_blank" rel="noopener noreferrer">
                        {__("MPI membership")}
                    </a>.
                </p>
            </div>
        );
    }
}

LoginForm.propTypes = {
    search: PropTypes.string
};

////////////////////////////////////////////////////////////////////////////////
//Connect it up

let mapStateToProps = function(){

    return {
        initialValues: {
            username: "",
            password: ""
        }
    };
};

let mapDispatchToProps = function(){
    return {

    };
};

LoginForm = reduxForm({
    form: "signinpage_login",
    validate: function(values) {
        const errors = {};
        if (!values.username) {
            errors.email = __("Please enter your MPI username.");
        }
        if (!values.password) {
            errors.password = __("Please enter your MPI password.");
        }
        return errors;
    }
})(LoginForm);

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginForm));
