import _ from "lodash";
import React from "react";
import Helmet from "react-helmet";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import SearchResults from "../components/SearchResults";
import SearchMore from "../components/SearchMore";
import LocalSearchWrapper from "../components/LocalSearchWrapper";
import DefaultSearchWrapper from "../components/DefaultSearchWrapper";
import EmbeddedShortlist_add from "../../../actions/ShortList/EmbeddedShortlist_add";
import EmbeddedShortlist_refresh from "../../../actions/ShortList/EmbeddedShortlist_refresh";
import EmbeddedShortlist_remove from "../../../actions/ShortList/EmbeddedShortlist_remove";
import LocalSearchLocation from "../../../actions/SearchPage/LocalSearchLocation";
import LoadingIndicator from "../../../components/LoadingIndicator";
import queryString from "query-string";
import Constants from "../../../utils/Constants";
import { gettext as __ } from "../../../utils/Trans";
import toMpURL from "../../../utils/toMpURL";

import "../SearchPage.css";
import mapmarker from "../map-marker.gif";

export class SearchPageA extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            free_form_text_tags: null,
            show_virtual_fees: false
        };
        this.scrollto = null;
        this.scrollToComponent = null;
    }

    componentDidMount() {
        /**
         * require this library instead of importing it because otherwise it will fail on jest tests
         * @see https://github.com/component/scroll-to/issues/9
         */
        this.scrollToComponent = require("react-scroll-to-component");

        if (this.props.url_location && !this.props.local_search) {
            this.props.loadLocalSearch();
        }

        try {
            if (_.has(this.props.qs, ["q"])) {
                let q = JSON.parse(_.get(this.props.qs, ["q"]));
                this.setState({
                    free_form_text_tags: _.compact(_.map(q, function (item, key) {
                        if (_.isObject(item) && _.has(item, ["type"]) && _.get(item, ["type"]) === "freeform") {
                            return _.get(item, ["label"]);
                        } else if (key === "label") {
                            return item;
                        }
                    })),
                    show_virtual_fees: (_.get(this.props.qs, ["presenter_type"], "on-site") === "virtual")
                });
            }
        } catch (e) { /* nothing to do here */
        }

        //start at the top of the page
        window.scrollTo(0, 0);
        window.name = "mp_search_results";

        window.addEventListener("focus", this.__scrollListener);
    }

    componentWillUnmount() {
        window.removeEventListener("focus", this.__scrollListener);
    }

    __scrollListener = () => {
        if (_.isFunction(this.props.refreshEmbeddedShortlist)) {
            this.props.refreshEmbeddedShortlist();
        }
    }

    /**
     * Set the element id that the page should scroll to when it's done loading.
     * This gets called several times potentially, and it will use the most recently
     * passed parameter, unless it got an value with "sid" in it, which it will keep and
     * ignore subsequent calls. "sidxxx" indicates scrolling to a speaker on the result page.
     *
     * @param scrollto
     * @private
     */
    __setScrollTo(scrollto) {

        //never overwrite a scrollto that's set to an sid
        if (("string" !== typeof this.scrollto) || (!this.scrollto.includes("sid"))) {
            this.scrollto = scrollto;
        }
        //this.__doScroll(this.scrollto); //DISABLED THIS UNTIL WE CAN FIGURE OUT HOW TO PREVENT IT SROLLING WHEN THE SEARCH FORM IS RESET OR CLEARED
    }

    /**
     * Execute a scroll once the page has (hopefully) finished loading.
     * Use throttle so we don't execute a scroll more than once on a page load.
     *
     * my_scrollto: element id as a string, or a reference, to scroll to
     *
     */
    __doScroll = _.throttle((my_scrollto) => {
        let anchor = null;

        if (null === my_scrollto) {
            return;
        }

        if ("string" === typeof my_scrollto) {
            anchor = document.getElementById(my_scrollto);
            if (!anchor) {
                console.log("could not find element id " + my_scrollto);
                anchor = this.results_anchor;
            }
        } else if ("object" === typeof my_scrollto) {
            anchor = my_scrollto;
        } else {
            return;
        }

        if (("object" === typeof anchor) && (null !== anchor) && _.isFunction(this.scrollToComponent)) {
            let scroller = this.scrollToComponent(anchor, {offset: -100, align: "top", duration: 1000});
            scroller.on("end", () => console.log("scrollToComponent.end", anchor));
        }

    }, 3000, {trailing: true});


    componentDidUpdate(prevProps) {
        if (this.props.qs !== prevProps.qs) {
            try {
                if (_.has(this.props.qs, ["q"])) {
                    let q = JSON.parse(_.get(this.props.qs, ["q"]));
                    this.setState({
                        free_form_text_tags: _.compact(_.map(q, function (item, key) {
                            if (_.isObject(item) && _.has(item, ["type"]) && _.get(item, ["type"]) === "freeform") {
                                return _.get(item, ["label"]);
                            } else if (key === "label") {
                                return item;
                            }
                        })),
                        show_virtual_fees: (_.get(this.props.qs, ["presenter_type"], "on-site") === "virtual")
                    });
                }
            } catch (e) { /* nothing to do here */
            }
        }

        // Always scroll to the top of the page if we don't have a speaker or result
        if (this.props.scroll_to_sid) {
            this.__setScrollTo(this.props.scroll_to_sid);
        } else {
            if (prevProps.is_searching && !this.props.is_searching) {
                this.__setScrollTo(this.results_anchor);
            }
        }

    }


    render() {
        let {
                whitelabel,
                user,
                is_searching,
                search_form_submitted,
                local_search,
                url_location,
                shortlist,
                num_results,
                awards,
                feeranges,
                languages,
                topics,
                results,
                qs,
                onProfile,
                onFavorite,
                onViewPrograms,
                onViewSchedule,
                onWatchVideo
            } = this.props;

        if (url_location && !local_search) {
            return (
                <div className="text-center">
                    <div style={{margin: "15vh auto"}}>
                        <h2>{__("Finding local speakers...")}</h2>
                        <img src={mapmarker} alt="map marker" className="img-responsive center-block"/>
                    </div>
                </div>
            );
        }

        return (
            <div className={"search-page container-fluid text-center " + (local_search ? "local-search-page" : "")}>
                {(_.includes(Constants.MPI_BUREAU_ID, _.get(whitelabel, ["bid"], Constants.TRUNK_BUREAU_ID))) &&
                <Helmet>
                    <script src={"//g.adspeed.net/ad.php?do=js&zids=" + _.get(whitelabel, ["mp_keyvalues", "AD_INRESULT_ZONE"]) + "x" + _.get(whitelabel, ["mp_keyvalues", "AD_INRESULT_FETCHQTY"]) + "&wd=-1&ht=-1&target=_blank"} type="text/javascript"/>
                </Helmet>
                }
                {is_searching && <div className="visible-xs"><LoadingIndicator/></div>}
                <div>
                    {local_search
                        ? <LocalSearchWrapper
                            whitelabel={whitelabel}
                            num_results={num_results}
                            awards={awards}
                            feeranges={feeranges}
                            languages={languages}
                            topics={topics}
                            local_search={local_search}
                            qs={qs}
                        />
                        :
                        <DefaultSearchWrapper
                            whitelabel={whitelabel}
                            num_results={num_results}
                            awards={awards}
                            feeranges={feeranges}
                            languages={languages}
                            topics={topics}
                            local_search={local_search}
                            qs={qs}
                        />
                    }
                    <div id="results_anchor" ref={(div) => {
                        this.results_anchor = div;
                    }}>
                        <SearchResults
                            ref={(section) => {
                                this.resultRef = section;
                            }}
                            whitelabel={whitelabel}
                            speakers={results}
                            shortlist={_.get(shortlist, ["sids"], [])}
                            onProfile={onProfile}
                            awards={awards}
                            languages={languages}
                            show_virtual_fees={this.state.show_virtual_fees}
                            free_form_text_tags={this.state.free_form_text_tags}
                            search_form_submitted={search_form_submitted}
                            is_debug={(_.get(user, ["role"], "guest") === "admin")}
                            onFavorite={_.get(whitelabel, ["is_directory"], false) ? null : onFavorite} // only show the favorite button if we are not a directory
                            onViewSchedule={onViewSchedule}
                            onViewPrograms={onViewPrograms}
                            onWatchVideo={onWatchVideo}
                        />
                    </div>
                    {_.size(results) < num_results &&
                    <SearchMore is_loading={is_searching} qs={qs} whitelabel={whitelabel} use_infinity_scroll={_.get(whitelabel, ["mp_keyvalues", "MP_SEARCH_INFINITY_SCROLL"], false)}/>
                    }
                </div>
            </div>
        );
    }
}

SearchPageA.propTypes = {
    search: PropTypes.object,
    shortlist: PropTypes.object,
    is_searching: PropTypes.bool
};

////////////////////////////////////////////////////////////////////////////////
//Connect it up

const mapStateToProps = function (state, ownProps) {

    let url_location = _.get(ownProps.match, ["params", "location"]); // This may or may not be in the params, if it isn't, just show the default search page
    let local_search = _.get(state, ["SearchPage", "local", url_location]);

    let qs = queryString.parse(_.get(ownProps.location, ["search"]), {arrayFormat: "bracket"});
    let scroll_to_sid = _.replace(_.get(ownProps.location, ["hash"]), "#", "");

    let feeranges = _.get(state, ["Whitelabel", "sitelists", "feeranges"], []);
    let awards = _.get(state, ["Whitelabel", "sitelists", "awards_to_display_on_results"], []);
    let languages = _.get(state, ["Whitelabel", "sitelists", "universal", "languages"], []);
    let topics = _.get(state, ["Whitelabel", "sitelists", "topics"], []);
    let results = _.slice(_.map(_.get(state, ["SearchPage", "results"], []), (result) => (result)), 0, (_.toNumber(_.get(qs, ["items_per_page"], 12)) * (_.toNumber(_.get(qs, ["page"], 0)) + 1)));
    let num_results = _.get(state, ["SearchPage", "num_results"], 0);
    let shortlist = _.get(state, ["EmbeddedShortlist"], {});

    let is_searching = (_.get(state, ["form", "search_form", "submitting"], false) || _.get(state, ["balboa3", "SearchPage_shortprofiles", "waiting"], false));
    let search_form_submitted = _.get(state, ["form", "search_form", "submitSucceeded"], false);

    return {
        awards: awards,
        languages: languages,
        feeranges: feeranges,
        topics: topics,
        results: results,
        num_results: num_results,
        is_searching: is_searching,
        shortlist: shortlist,
        qs: qs,
        scroll_to_sid: scroll_to_sid,
        search_form_submitted,
        local_search: local_search,
        url_location: url_location,
    };
};

const mapDispatchToProps = function (dispatch, ownProps) {
    return {
        onProfile: function (speaker) {
            if (_.has(speaker, ["sid"])) {
                let bio_oneline_topic_id = !_.isNull(_.get(speaker, ["bio_oneline_topic"], null))
                    ? _.head(_.split(_.get(speaker, ["bio_oneline_topic"]), "|", 2))
                    : -1;
                if (bio_oneline_topic_id > 0) {
                    window.open(toMpURL("/profile/" + _.get(speaker, ["sid"]) + "?btsc=1&topid=" + bio_oneline_topic_id, ownProps.whitelabel), "profile_" + _.get(speaker, ["sid"]));
                    // ownProps.history.push({
                    //     state: _.assign({}, _.get(ownProps.location, ["state"]), {
                    //         bts: () => { window.close(); },
                    //         btst: "BACK TO SEARCH"
                    //     })
                    // });
                    // ownProps.history.push({
                    //     pathname: "/profile/" + _.get(speaker, ["sid"]) + "?topid=" + bio_oneline_topic_id,
                    //
                    //     state: _.assign({}, _.get(ownProps.location, ["state"]), {
                    //         bts: () => { window.close(); },
                    //         btst: "BACK TO SEARCH"
                    //     })
                    // });
                } else {
                    window.open(toMpURL("/profile/" + _.get(speaker, ["sid"]) + "?btsc=1", ownProps.whitelabel), "profile_" + _.get(speaker, ["sid"]));
                    // ownProps.history.push({
                    //     pathname: "/profile/" + _.get(speaker, ["sid"]),
                    //     state: _.assign({}, _.get(ownProps.location, ["state"]), {
                    //         bts: () => { window.close(); },
                    //         btst: "BACK TO SEARCH"
                    //     })
                    // });
                }
            }
        },
        onViewPrograms: function (speaker) {
            if (_.has(speaker, ["sid"])) {
                ownProps.history.push({
                    pathname: "/profile/" + _.get(speaker, ["sid"]),
                    hash: "#programs",
                    state: _.assign({}, _.get(ownProps.location, ["state"]), {
                        bts: ownProps.location,
                        btst: "BACK TO SEARCH"
                    })
                });
            }
        },
        onViewSchedule: function (speaker) {
            if (_.has(speaker, ["sid"])) {
                ownProps.history.push({
                    pathname: "/profile/" + _.get(speaker, ["sid"]),
                    hash: "#availability",
                    state: _.assign({}, _.get(ownProps.location, ["state"]), {
                        bts: ownProps.location,
                        btst: "BACK TO SEARCH"
                    })
                });
            }
        },
        onWatchVideo: function (speaker) {
            if (_.has(speaker, ["sid"]) && _.has(speaker, ["video_id"])) {
                ownProps.history.push({
                    pathname: "/profile/" + _.get(speaker, ["sid"]),
                    search: "?opentovideo=" + _.get(speaker, ["video_id"]),
                    state: _.assign({}, _.get(ownProps.location, ["state"]), {
                        bts: ownProps.location,
                        btst: "BACK TO SEARCH"
                    })
                });
            }
        },
        onFavorite: function (speaker, add_or_remove) {
            if (_.has(speaker, ["sid"])) {
                if (add_or_remove) {
                    return dispatch(EmbeddedShortlist_add(speaker));
                } else {
                    return dispatch(EmbeddedShortlist_remove(speaker));
                }
            }
        },
        loadLocalSearch: function () {
            let location = _.get(ownProps.match, ["params", "location"]);
            return dispatch(LocalSearchLocation.get(location));
        },
        refreshEmbeddedShortlist: function() {
            return dispatch(EmbeddedShortlist_refresh());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchPageA);