import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";

import "./Rating.scss";

export default class Rating extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rating: 0
        };
    }

    onMouseEnter = (rating) => {
        this.setState({
            rating: rating
        });
    }

    onMouseLeave = () => {
        this.setState({
            rating: 0
        });
    }

    render() {
        let {
            input,
            size = "fa-1x",
            count = 5,
            label,
            help_text,
            meta: {
                touched,
                error,
                warning
            }
        } = this.props;

        let hover_rating = this.state.rating;
        let value = _.get(input, ["value"], 0);
        let onMouseEnter = this.onMouseEnter;
        let onMouseLeave = this.onMouseLeave;
        let onChange = input.onChange;

        let stars = _.map([...Array(count).keys()], function(i) {
            let rating = (i + 1); // i is 0-based
            if (hover_rating > 0) {
                if (hover_rating < rating) {
                    return (<i key={i} className={"inactive fa fa-star-o " + size} onMouseEnter={() => onMouseEnter(rating)} onMouseLeave={onMouseLeave} onClick={() => onChange(rating)}/>);
                } else {
                    return (<i key={i} className={"active fa fa-star "  + size} onMouseEnter={() => onMouseEnter(rating)} onMouseLeave={onMouseLeave} onClick={() => onChange(rating)}/>);
                }
            } else if ( value === count) {
                return (<i key={i} className={"active fa fa-star" + (value < rating ? "-o" : "") + " " + size} onMouseEnter={() => onMouseEnter(rating)} onMouseLeave={onMouseLeave} onClick={() => onChange(rating)} />);
            } else {
                return (<i key={i} className={"fa fa-star" + (value < rating ? "-o" : "") + " " + size} onMouseEnter={() => onMouseEnter(rating)} onMouseLeave={onMouseLeave} onClick={() => onChange(rating)} />);
            }
        });

        return (
            <div className={"form-group " + (touched && error ? "has-error " : touched && warning ? "has-warning " : "")}>
                {label && <label className="control-label">{label}</label>}
                <div>
                    {stars}
                </div>
                {help_text && <div className="help-block">{help_text}</div>}
                {touched &&
                ((error && <div className="text-danger"><i className="fa fa-times"/> {error}</div>) ||
                    (warning && <div className="text-danger"><i className="fa fa-exclamation-triangle"/> {warning}</div>))}
            </div>
        );
    }
}

Rating.propTypes = {
    size: PropTypes.string,
    count: PropTypes.number,
    help_text: PropTypes.string,
    label: PropTypes.string
};