import React from "react";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import EditEventModal from "../../modals/EditEventModal";
import ActionButton from "../../../../components/ActionButton";

class EditEventButton extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_modal: false
        };
    }

    __toggleModal = () => {
        this.setState({
            show_modal: !this.state.show_modal
        });
    }

    render() {
        let {
            className = "btn btn-default btn-flat"
        } = this.props;

        return (
            <div>
                <ActionButton
                    icon="pencil-square"
                    btn_text={__("Update Job Details")}
                    help_text={__("Has something changed? Update the event details here and we will let the candidates know about the change.")}
                    onClick={this.__toggleModal}
                    className={className}
                />
                {this.state.show_modal && <EditEventModal {...this.props} onClose={this.__toggleModal} />}
            </div>
        );
    }
}

EditEventButton.propTypes = {
    event: PropTypes.object.isRequired,
    className: PropTypes.string
};

export default EditEventButton;