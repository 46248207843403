import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Collapse, Panel } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import Messages from "../../../../components/Messages";
import SpeakerThumbnail from "../../../../components/SpeakerThumbnail";
import MessageForm from "./MessageForm";
import { gettext as __ } from "../../../../utils/Trans";

import "./MessagePreview.scss";

export default class MessagePreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_reply: false
        };
    }

    componentDidMount() {
        let ids = _.sortBy(_.map(_.filter(this.props.thread, {receiver_opened: false}), function(item) {
            return _.get(item, ["id"]);
        }));
        if (_.size(ids) > 0) {
            _.delay(this.__onMarkAsRead, 1000);
        }
    }

    componentDidUpdate(prevProps) {
        let ids = _.sortBy(_.map(_.filter(this.props.thread, {receiver_opened: false}), function(item) {
            return _.get(item, ["id"]);
        }));
        let prev_ids = _.sortBy(_.map(_.filter(prevProps.thread, {receiver_opened: false}), function(item) {
            return _.get(item, ["id"]);
        }));
        if (_.join(ids, "_") !== _.join(prev_ids, "_") && _.size(ids) > 0) {
            _.delay(this.__onMarkAsRead, 1000);
        }
    }

    __onShowReply = () => {
        this.setState({
            show_reply: !this.state.show_reply
        });
    }

    __onMarkAsRead = () => {
        if (_.isFunction(this.props.onMarkAsRead)) {
            let ids = _.map(_.filter(this.props.thread, {receiver_opened: false}), function(item) {
                return _.get(item, ["id"]);
            });
            if (_.size(ids) > 0) {
                return this.props.onMarkAsRead(ids);
            }
        }
    }

    __onAddSpeakerToShortlist = (event_id, speaker_id, shortlist_id) => {
        if (_.isFunction(this.props.onAddSpeakerToShortlist)) {
            return this.props.onAddSpeakerToShortlist({
                id: event_id,
                speaker_id: speaker_id,
                mp_shortlist_id: shortlist_id
            });
        }
    }

    render() {
        let {
            user,
            whitelabel,
            thread
        } = this.props;

        let message = _.head(_.orderBy(thread, "sender_speaker_id", "asc"));
        let recent_message = _.head(_.orderBy(thread, "created", "desc"));
        let event_id = _.get(message, ["event", "id"]) > 0 ? _.get(message, ["event", "id"]) : _.get(message, ["event_id"]);
        let event_name = _.get(message, ["event", "name"]);
        let sender_name = _.get(message, ["speaker", "name"]);
        let sender_id = _.get(message, ["speaker", "id"]);
        let shortlist_id = _.get(message, ["mp_shortlist_id"]);
        let event_exists = (_.get(message, ["event", "id"]) > 0);
        let is_speaker_shortlisted = _.reduce(thread, (accum, msg) => {
            return accum || (_.get(msg, ["mp_shortlist_speakers_id"], 0) > 0);
        }, false);
        let received_date = moment(_.get(recent_message, ["created"], moment().format("MM/DD/YYYY"))).calendar(null, {
            sameDay: '[Today at] h:mm a',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[Yesterday at] h:mm a',
            lastWeek: '[Last] dddd [at] h:mm a',
            sameElse: 'MMM D, YYYY [at] h:mm a'
        });

        let link_to_shortlist = "/shortlist/" + event_id;
        if (is_speaker_shortlisted) {
            link_to_shortlist += "/" + sender_id;
        }

        return (
            <div className="mp-message-preview">
                <Panel>
                    <Panel.Body>
                        <div className="pull-right">
                            <span className="sender-thumbnail"><SpeakerThumbnail sid={sender_id} /></span>
                        </div>
                        <div>
                            <div className="sender-name">{sender_name}</div>
                            <span className="pull-right received-date">{received_date}</span>
                        </div>
                        <div>
                            <Link to={link_to_shortlist}>{event_name}</Link>
                        </div>
                        <hr />
                        {event_exists &&
                          <React.Fragment>
                            <Collapse in={this.state.show_reply}>
                                <div>
                                    <MessageForm thread={thread} user={user} whitelabel={whitelabel} onAfterSubmit={this.__onShowReply} />
                                </div>
                            </Collapse>
                            <Collapse in={!this.state.show_reply}>
                                <div>
                                    <div className="pull-left">
                                        <div className="btn btn-default btn-sm" onClick={this.__onShowReply}>Reply</div>
                                    </div>
                                </div>
                            </Collapse>
                          </React.Fragment>
                        }
                    </Panel.Body>
                </Panel>
                <div className="message-preview">
                    {!event_exists &&
                      <div className="help-block instruction text-warning">
                          {__("This event no longer exists and we are showing you a read-only archive of the available messages.")}
                      </div>
                    }
                    {!is_speaker_shortlisted && event_exists &&
                    <div className="help-block instruction text-warning">
                        <span className="pull-right"><div className="btn btn-warning btn-sm" onClick={() => this.__onAddSpeakerToShortlist(event_id, sender_id, shortlist_id)}>{__("add to shortlist")}</div></span>
                        {__("This speaker is not on your event shortlist yet.  By adding them to your event shortlist, this candidate can deliver a customized offer to you and you can reply to them.")}
                    </div>
                    }
                    {is_speaker_shortlisted && event_exists &&
                        <div className="help-block instruction text-success">
                            <span className="pull-right"><Link to={link_to_shortlist}><div className="btn btn-success btn-sm">{__("go to event")}</div></Link></span>
                            {__("This speaker is on your event shortlist.  You can reply to this message, send them an offer, complete payment or remove them on your event page.")}
                        </div>
                    }
                    <Messages messages={thread} order={"desc"} />
                </div>
            </div>
        );
    }
}

MessagePreview.propTypes = {
    MessageForm: PropTypes.object,
    message: PropTypes.object,
    onMarkAsRead: PropTypes.func,
    onAddSpeakerToShortlist: PropTypes.func
};