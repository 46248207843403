import _ from "lodash";
import CookieEmbeddedShortlist from "../../utils/CookieEmbeddedShortlist";

export default function(spkr) {
    CookieEmbeddedShortlist.remove(_.get(spkr, ["sid"], spkr));
    return {
        type: "EMBEDDED_SHORTLIST_REMOVE",
        sid: _.get(spkr, ["sid"], spkr)
    };
};
