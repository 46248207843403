import _ from "lodash";
import React from "react";
import { gettext as __ } from "../../../../utils/Trans";
import Switch from "rc-switch";
import ActionButton from "../../../../components/ActionButton";
import TogglePublicPostingModal from "../../modals/TogglePublicPostingModal";

require("../../../../../node_modules/rc-switch/assets/index.css");

class PostingType extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_public_posting_modal: false
        };
    }

    __onTogglePublicPostingModal = () => {
        this.setState({
            show_public_posting_modal: !this.state.show_public_posting_modal
        });
    }

    render() {
        let {
            whitelabel,
            event
        } = this.props;

        let is_public = (true === _.get(event, ["flags_as_map", "mp_job_board", "is_set"], false));
        let is_bureau_mode = (true === _.get(whitelabel, ["is_bureau_mode"], false));
        let hide_public_job_toggle = _.get(whitelabel, ["mp_keyvalues", "MP_DISABLE_PUBLIC_JOB_BOARD"], false);

        if (hide_public_job_toggle) { return null;}
        if (is_bureau_mode) { return null; }

        return (
            <div className="hidden-bureaumode" style={{padding: "20px"}}>
                <div className="hidden-sm hidden-xs">
                    <div className="text-muted MP-PostingType">
                        <div className="row">
                            <div className="col-sm-9">
                                <b onClick={this.__onTogglePublicPostingModal}>{__("Open Call")}</b>
                            </div>
                            <div className="col-sm-3">
                                <Switch
                                    className="pull-right"
                                    checked={is_public}
                                    onClick={this.__onTogglePublicPostingModal}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-sm-12">
                                {
                                    is_public
                                        ?
                                        <div className="small help-block" onClick={this.__onTogglePublicPostingModal}>{__("All speakers can see your event on the job board and apply to this job")}</div>
                                        :
                                        <div className="small help-block" onClick={this.__onTogglePublicPostingModal}>{__("Only speakers you invite can apply to this job and communicate with you")}</div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hidden-lg hidden-md">
                    {is_public
                        ?
                        <ActionButton
                            icon="users"
                            btn_text={__("Open Call")}
                            help_text={__("Other speakers can see and apply to this job")}
                            onClick={this.__onTogglePublicPostingModal}
                            className="btn-success"
                        />
                        :
                        <ActionButton
                            icon="user"
                            btn_text={__("Private Meeting")}
                            help_text={__("Only speakers you invite can apply to this job")}
                            onClick={this.__onTogglePublicPostingModal}
                        />
                    }
                </div>
                {this.state.show_public_posting_modal && <TogglePublicPostingModal event={event} onClose={this.__onTogglePublicPostingModal} />}
            </div>
        );
    }
}

export default PostingType;