import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { gettext as __ } from "../../utils/Trans";
import toMpURL from "../../utils/toMpURL";
import InlineError from "../../components/InlineError";
import LoadingIndicator from "../../components/LoadingIndicator";
import SpeakerDirectMessageForm from "./SpeakerDirectMessageForm";
import Speaker from "../../actions/Speaker/Speaker";
import queryString from "query-string";

import "./SpeakerDirectMessage.scss";

export class SpeakerDirectMessagePage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if (!this.props.speaker) {
            this.props.loadSpeaker();
        }
    }

    render() {
        let {
            errors,
            is_waiting,
            speaker,
            returntourl,
            whitelabel
        } = this.props;

        return (
            <div>
                <h1 className="page-header">
                    {_.get(whitelabel, "is_bureau_mode", false) ? __("Inquire about %s", [_.get(speaker, ["name_first"])]) : __("Connect With %s", [_.get(speaker, ["name_first"])])}
                </h1>
                {_.map(errors, function (error, i) {
                    return <InlineError key={i} error={error}/>;
                })}
                {(null === returntourl) ? null
                    :
                    <div className="row" style={{marginBottom: "2em"}}>
                        <div className="col-sm-12">
                            <a href={decodeURIComponent(returntourl)}>
                                <i className="fa fa-arrow-circle-left"/> back
                            </a>
                        </div>
                    </div>
                }
                <div className="row" style={{marginBottom: "3em"}}>
                    <div className="col-md-3">
                        <div className="well">
                            <img src={_.get(speaker, ["thumb_url"])} alt={_.get(speaker, ["speakername"])} className="img-responsive img-rounded hidden-sm hidden-xs"/>
                            <h2>{_.get(speaker, ["speakername"])}</h2>
                            <br/>
                            <div dangerouslySetInnerHTML={{__html: _.get(speaker, ["bio_oneline"])}}/>
                            <br/>
                            <p>
                                {speaker && <Link  to={"/profile/" + _.get(speaker, ["sid"])}><b>{__("View full profile")} »</b></Link>}
                            </p>
                        </div>
                    </div>
                    <div className="col-md-8 col-md-offset-1">
                        <div>
                            {speaker && <SpeakerDirectMessageForm speaker={speaker} whitelabel={whitelabel} />}
                        </div>
                    </div>
                </div>
                {is_waiting && <LoadingIndicator />}
            </div>
        );
    }
}


////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {
    let sid = _.get(ownProps.match, ["params", "sid"]);
    let qs = queryString.parse(_.get(ownProps.location, ["search"]));
    let simple_site_mode = _.has(qs, ["simplesite"], false);
    let book_me_now_mode = _.has(qs, ["bookmenow"], false);
    let returntourl = _.get(qs, ["returntourl"], toMpURL("/profile/" + sid, ownProps.whitelabel));

    let speaker = _.get(state, ["Speakers", sid]);

    return {
        is_waiting: _.isUndefined(speaker),
        speaker: speaker,
        returntourl: returntourl,
        simple_site_mode: simple_site_mode,
        book_me_now_mode: book_me_now_mode
    };
};

var mapDispatchToProps = function (dispatch, ownProps) {
    let sid = _.get(ownProps.match, ["params", "sid"]);

    return {
        loadSpeaker: function() {
            dispatch(Speaker.get(sid));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(SpeakerDirectMessagePage);