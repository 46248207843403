import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Event from "../Event";
import { gettext as __ } from "../../../../utils/Trans";
import Speaker from "../../../../actions/Speaker/Speaker";

class EventList extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            is_loading: false
        };
    }

    componentDidMount() {
        if (this.props.sids) {
            if (!_.isEmpty(this.props.sids)) {
                this.setState({
                    is_loading: true
                }, () => {
                    return this.props.loadSpeakers(this.props.sids)
                        .then((resp) => {
                            this.setState({
                                is_loading: false
                            })
                        });
                });
            }
        }
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        let prev_sids = _.uniq(_.compact(_.reduce(this.props.events, function (sids, event) {
            let spkr_sids = _.map(_.get(event, ["Shortlists", 0, "speakers"]), function(spkr) {
                return _.get(spkr, ["speaker_id"]);
            });
            return _.concat(sids, spkr_sids);
        }, [])));

        let new_sids = _.uniq(_.compact(_.reduce(nextProps.events, function (sids, event) {
            let spkr_sids = _.map(_.get(event, ["Shortlists", 0, "speakers"]), function(spkr) {
                return _.get(spkr, ["speaker_id"]);
            });
            return _.concat(sids, spkr_sids);
        }, [])));

        let profile_sids = _.uniq(_.compact(_.map(nextProps.short_profiles, function(profile) {
            return _.get(profile, ["sid"]);
        })));

        let diff_sids = _.difference(_.difference(new_sids, prev_sids), profile_sids);
        if (diff_sids) {
            this.setState({
                is_loading: true
            }, () => {
                return nextProps.loadSpeakers(diff_sids)
                    .then((resp) => {
                        this.setState({
                            is_loading: false
                        })
                    });
            });
        }
    }

    render() {
        let {
            events,
            notifications,
            short_profiles
        } = this.props;

        let no_events =
            <div className="text-center" style={{marginBottom: "5em"}}>
                <Link to={"/postapublicjob"}>
                    <div className="btn btn-success btn-lg  hidden-sm hidden-xs">
                        {__("Get started with your first event")}
                    </div>
                </Link>
                <Link to={"/postapublicjob"}>
                    <div className="btn btn-success btn-lg visible-sm visible-xs">
                        <i className="fa fa-plus" />{" "}{__("Your first event")}
                    </div>
                </Link>
            </div>;

        let event_list = _.map(events, function (event) {
            if (_.get(event, ["eid"], -1) > 0) {
                let eid = _.get(event, ["eid"]);
                let notification = _.toNumber(_.get(notifications, ["eids", eid, "total"], 0));
                let speakers = _.filter(_.get(event, ["Shortlists", 0, "speakers"]), function(item) {
                    return (
                        !(_.get(item, ["flags_as_map", "banned_from_shortlist", "is_set"], false))
                    );
                });

                speakers = _.reverse(_.sortBy(_.map(speakers, function(shortlist_spkr) {
                    let sid = _.get(shortlist_spkr, ["speaker_id"]);
                    return _.assign({},
                       _.get(short_profiles, [sid]),
                       {
                           sid: sid,
                           has_offer: _.has(event, ["Agreements", _.toInteger(sid)]),
                           offer_accepted: _.get(event, ["Agreements", _.toInteger(sid), "is_accepted"], false)
                       }
                   );
                }), ["offer_accepted"]));

                return <Event key={eid} event={event} notification={notification} speakers={speakers} />;
            }
        });

        return (
            <div style={{marginBottom: "5em"}}>
                {!_.isEmpty(events) ?
                    event_list :
                    no_events
                }
            </div>
        );
    }
}

EventList.propTypes = {
    events: PropTypes.oneOfType([PropTypes.array, PropTypes.object]),
    notifications: PropTypes.oneOfType([PropTypes.array, PropTypes.object])
};

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {

    let sids = _.uniq(_.compact(_.reduce(ownProps.events, function (sids, event) {
        let spkr_sids = _.map(_.get(event, ["Shortlists", 0, "speakers"]), function(spkr) {
            return _.get(spkr, ["speaker_id"]);
        });
        return _.concat(sids, spkr_sids);
    }, [])));

    let short_profiles = _.keyBy(_.compact(_.map(sids, function(sid) {
        return _.get(state, ["Speakers", _.toNumber(sid)]);
    })), "sid");

    return {
        sids: sids,
        short_profiles: short_profiles,
        short_profiles_loading: (_.size(short_profiles) < _.size(sids))
    };
};

var mapDispatchToProps = function (dispatch) {
    return {
        loadSpeakers: function(sids) {
            if (!_.isEmpty(sids)) {
                return dispatch(Speaker.find({
                    ids: sids
                }));
            }
            return Promise.resolve();
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventList);