import React from "react";
import NavBar from "../../../components/Navbar";
import NavFooter from "../../../components/NavFooter";

class ErrorLayout extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            children
        } = this.props;

        return (
            <div>
                <NavBar {...this.props} />
                <div className="container">
                    {children}
                </div>
                <NavFooter />
            </div>
        );
    }
}

export default ErrorLayout;
