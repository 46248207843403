import _ from "lodash";
import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { gettext as __ } from "../../../../utils/Trans";
import SpeakerThumbnail from "../../../../components/SpeakerThumbnail";
import SpeakerName from "../../../../components/SpeakerName";

class Candidate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speaker,
            n_unread = 0,
            is_selected,
            is_at_least_one_speaker_selected,
            has_offer,
            offer_accepted,
            href,
            event_has_an_accepted_offer
        } = this.props;

        let is_disabled = (event_has_an_accepted_offer) && (!(has_offer || offer_accepted)); //if the event has an accepted offer, and it's not this candidate, then grey them out

        let label;
        if (offer_accepted) {
            label = <div className="label label-success hidden-xs hidden-sm">{__("Offer accepted")}</div>;
        } else if (has_offer) {
            label = <div className="label label-warning hidden-xs hidden-sm">{__("Offer pending")}</div>;
        }

        return (
            <li key={_.get(speaker, ["sid"])} className={"Candidate no-select " + (is_selected ? " active" : "") + (is_disabled ? " disabled" : "")}>
                <Link to={href}>
                    <div className="hidden-xs hidden-sm">
                        <div className="pull-left">
                            {speaker && <SpeakerThumbnail
                                speaker={speaker}
                                n_unread={n_unread}
                                size={32}
                                dont_show_name_on_hover={true}
                                dont_link_to_profile={true}
                            />
                            }
                        </div>
                        <div className="pull-left">
                            {speaker && <SpeakerName sid={_.get(speaker, ["sid"])} speaker={speaker} />}
                        </div>
                        <div className="pull-right">
                            {label}
                        </div>
                        <div className="clearfix" />
                    </div>
                    <div className="hidden-lg hidden-md">
                        <div className="pull-left">
                            {speaker && <SpeakerThumbnail
                                sid={_.get(speaker, ["sid"])}
                                speaker={speaker}
                                size={!is_at_least_one_speaker_selected ? 96 : 40}
                                n_unread={n_unread}
                                dont_show_name_on_hover={true}
                                dont_link_to_profile={true}
                                has_offer={has_offer}
                                has_offer_accepted={offer_accepted}
                                inline={true}
                            />}
                        </div>
                    </div>
                </Link>
            </li>
        );
    }
}

Candidate.propTypes = {
    speaker: PropTypes.object,
    n_unread: PropTypes.number,
    is_selected: PropTypes.bool,
    is_at_least_one_speaker_selected: PropTypes.bool,
    has_offer: PropTypes.bool,
    offer_accepted: PropTypes.bool,
    onClick: PropTypes.string
};

export default Candidate;