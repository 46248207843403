import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import CloseModal from "./CloseModal";
import { Modal as BsModal } from "react-bootstrap";

import "./Modal.css";

class Modal extends React.Component {

    static propTypes = {
        title: PropTypes.string,
        size: PropTypes.string,
        className: PropTypes.string,
        raw_body: PropTypes.bool,
        children: PropTypes.node
    };

    constructor(props) {
        super(props);
        this.state = {
            mounted: false,
            is_open: props.is_open
        };
    }

    componentDidMount() {
        this.setState({ mounted: true });
    }

    UNSAFE_componentWillReceiveProps = (nextProps) => {
        if (nextProps.is_open !== this.props.is_open) {
            this.setState({
                is_open: nextProps.is_open
            });
        }
    }

    __onClose = () => {
        this.setState({
            is_open: false
        });
        _.isFunction(this.props.onClose) ? this.props.onClose() : CloseModal();
    }

    render() {
        let {
            title,
            children,
            raw_body,
            onClose
        } = this.props;

        raw_body = !!raw_body;

        return (
            <BsModal show={true}>
                <Modal.Header closeButton onHide={onClose}>
                    <Modal.Title>{title}</Modal.Title>
                </Modal.Header>
                {!raw_body
                    ?
                    <Modal.Body>{children}</Modal.Body>
                    :
                    <div>{children}</div>
                }
            </BsModal>
        );
    }
}

export default Modal;