import _ from "lodash";
import React from "react";
import moment from "moment";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { gettext as __ } from "../../../utils/Trans";
import DateRangePicker from "react-bootstrap-daterangepicker";

import "bootstrap-daterangepicker/daterangepicker.css";

const Availability = (props) => {
    if (props.is_waiting) {
        return (
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{__("checking availability...")}</Tooltip>}>
                <i className="fa fa-circle-o-notch fa-spin"/>
            </OverlayTrigger>
        );
    }
    if ("free" === _.get(props.availability, ["available"])) {
        return (
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{__(props.availability.message)}</Tooltip>}>
                <i className="fa fa-check text-success" />
            </OverlayTrigger>
        );
    }
    if ("tentative" === _.get(props.availability, ["available"])) {
        return (
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{__(props.availability.message)}</Tooltip>}>
                <i className="fa fa-plane text-warning" />
            </OverlayTrigger>
        );
    }
    if ("busy" === _.get(props.availability, ["available"])) {
        return (
            <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{__(props.availability.message)}</Tooltip>}>
                <i className="fa fa-times text-danger" />
            </OverlayTrigger>
        );
    }
    return (
        <OverlayTrigger placement="top" overlay={<Tooltip id="tooltip">{__("Enter a date to check speakers' availability")}</Tooltip>}>
            <i className="fa fa-calendar-check-o" />
        </OverlayTrigger>
    );
};

class DateAvailability extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            value: props.input.value || "",
            startDate: props.startDate || props.input.value || moment(),
            endDate: props.endDate || props.input.value || moment(),
            opens: props.opens || "right",
            dateFormat: props.dateFormat || "MM/DD/YYYY"
        };
    }

    __handleEvent = (event,picker) => {
        let value = this.state.value;

        if (picker.startDate.format(this.state.dateFormat) !== picker.endDate.format(this.state.dateFormat)) {
            value = picker.startDate.format(this.state.dateFormat) + " - " + picker.endDate.format(this.state.dateFormat);
        } else {
            value = picker.startDate.format(this.state.dateFormat);
        }

        this.setState({
            value: value,
            startDate: picker.startDate,
            endDate: picker.endDate
        }, () => {
            if (_.isFunction(this.props.input.onChange)) {
                this.props.input.onChange(this.state.value);
            }
        });
    }

    __handleChange = (e) => {
        let value = e.target.value;

        if (value !== "") {
            let date_parts = _.split(value, " - ");
            let startDate = moment(date_parts[0]).isValid() ? moment(date_parts[0]) : moment();
            let endDate = moment(date_parts[0]).isValid() ? moment(date_parts[0]) : moment();
            if (_.get(date_parts, [1], false)) {
                endDate = moment(date_parts[1]).isValid() ? moment(date_parts[1]) : moment();
            }

            this.setState({
                value: value,
                startDate: startDate,
                endDate: endDate
            }, () => {
                if (_.isFunction(this.props.input.onChange)) {
                    this.props.input.onChange(this.state.value);
                }
            });
        } else {
            this.setState({
                value: "",
                startDate: moment(),
                endDate: moment()
            }, () => {
                if (_.isFunction(this.props.input.onChange)) {
                    this.props.input.onChange(this.state.value);
                }
            });
        }
    }

    render() {
        let {
            startDate,
            endDate,
            dateFormat
        } = this.state;

        let {
            input,
            placeholder,
            label,
            help_text,
            availability,
            checkingAvailability,
            meta: {
                touched,
                error,
                warning
            }
        } = this.props;

        let inputProps = _.assign({}, this.props, {
            singleDatePicker: true,
            startDate: startDate,
            endDate: endDate,
            dateFormat: dateFormat,
            placeholder: placeholder,
            onApply: this.__handleEvent,
            containerStyles: {
                display: "block"
            }
        });

        return (
            <div>
                <div className={"form-group " + (touched && error ? "has-error " : touched && warning ? "has-warning " : "")}>
                    {label && <label className="control-label">{label}</label>}
                    <div className="input-group">
                        <DateRangePicker {...inputProps}>
                            <input type="text" value={this.state.value} onChange={this.__handleChange} placeholder={placeholder} className="form-control" style={{
                                width: "100%",
                                overflow: "hidden",
                                position: "relative"
                            }} disabled={this.props.disabled} />
                        </DateRangePicker>
                        <span className="input-group-addon">
                            <Availability is_waiting={checkingAvailability} availability={availability} />
                        </span>
                    </div>
                    {help_text && <div className="help-block">{help_text}</div>}
                    {touched &&
                    ((error && <div className="text-danger"><i className="fa fa-times" /> {error}</div>) ||
                    (warning && <div className="text-danger"><i className="fa fa-exclamation-triangle" /> {warning}</div>))}
                </div>
            </div>
        );
    }
}

export default DateAvailability;