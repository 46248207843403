import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Collapse, Panel } from "react-bootstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import Messages from "../../../../components/Messages";
import SpeakerThumbnail from "../../../../components/SpeakerThumbnail";
import MessageForm from "./MessageForm";
import { gettext as __ } from "../../../../utils/Trans";

import "./MessagePreview.scss";

export default class MessagePreview extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_reply: false
        };
    }

    componentDidUpdate(prevProps) {
        let ids = _.sortBy(_.map(_.filter(this.props.thread, {receiver_opened: false}), function(item) {
            return _.get(item, ["id"]);
        }));
        let prev_ids = _.sortBy(_.map(_.filter(prevProps.thread, {receiver_opened: false}), function(item) {
            return _.get(item, ["id"]);
        }));
        if (_.join(ids, "_") !== _.join(prev_ids, "_") && _.size(ids) > 0) {
            _.delay(this.__onMarkAsRead, 1000);
        }
    }

    __onShowReply = () => {
        this.setState({
            show_reply: !this.state.show_reply
        });
    }

    __onMarkAsRead = () => {
        if (_.isFunction(this.props.onMarkAsRead)) {
            let ids = _.map(_.filter(this.props.thread, {receiver_opened: false}), function(item) {
                return _.get(item, ["id"]);
            });
            if (_.size(ids) > 0) {
                return this.props.onMarkAsRead(ids);
            }
        }
    }

    __onAddSpeakerToShortlist = () => {
        if (_.isFunction(this.props.onAddSpeakerToShortlist)) {
            let message = _.head(_.reverse(_.sortBy(this.props.thread, "sender_speaker_id")));
            return this.props.onAddSpeakerToShortlist({
                shortlist_id: _.get(message, ["mp_shortlist_id"]),
                eid: _.get(message, ["event_id"]),
                sid: _.get(message, ["sender_speaker_id"])
            });
        }
    }

    render() {
        let {
            user,
            whitelabel,
            event,
            speaker,
            thread
        } = this.props;

        let message = _.head(_.reverse(_.sortBy(thread, "created")));
        let event_name = _.get(event, ["organization"]);
        let sender_name = _.get(speaker, ["name"]);
        let sender_id = _.get(speaker, ["sid"]);
        let is_speaker_shortlisted = (_.get(message, ["mp_shortlist_speakers_id"], 0) > 0);
        let received_date = moment(_.get(message, ["created"], moment().format("MM/DD/YYYY"))).calendar(null, {
            sameDay: 'h:mm a',
            nextDay: '[Tomorrow]',
            nextWeek: 'dddd',
            lastDay: '[Yesterday at] h:mm a',
            lastWeek: '[Last] dddd [at] h:mm a',
            sameElse: 'MMM D, YYYY [at] h:mm a'
        });

        let link_to_shortlist = "/shortlist/" + _.get(message, ["event_id"]);
        if (is_speaker_shortlisted) {
            link_to_shortlist += "/" + sender_id;
        }

        return (
            <div className="mp-message-preview">
                <Panel>
                    <Panel.Body>
                        <div className="pull-right">
                            <span className="sender-thumbnail"><SpeakerThumbnail sid={sender_id} /></span>
                        </div>
                        <div>
                            <div className="sender-name">{sender_name}</div>
                            <span className="pull-right received-date">{received_date}</span>
                        </div>
                        <div>
                            <Link to={link_to_shortlist}>{event_name}</Link>
                        </div>
                        <hr />
                        <Collapse in={this.state.show_reply}>
                            <div>
                                <MessageForm event={event} speaker={speaker} user={user} whitelabel={whitelabel} onAfterSubmit={this.__onShowReply} />
                            </div>
                        </Collapse>
                        <Collapse in={!this.state.show_reply}>
                            <div>
                                <div className="pull-left">
                                    <div className="btn btn-default btn-sm" onClick={this.__onShowReply}>{__("Reply")}</div>
                                </div>
                            </div>
                        </Collapse>
                    </Panel.Body>
                </Panel>
                <div className="message-preview">
                    <Messages messages={thread} order={"desc"} />
                </div>
            </div>
        );
    }
}

MessagePreview.propTypes = {
    MessageForm: PropTypes.object,
    message: PropTypes.object,
    onMarkAsRead: PropTypes.func,
    onAddSpeakerToShortlist: PropTypes.func
};