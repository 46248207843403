import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { gettext as __ } from "../../utils/Trans";
import InfoBox from "../../components/InfoBox";
import PostingType from "./components/PostingType";
import EventDetails from "./components/EventDetails";
import EventActions from "./components/EventActions";
import CandidatesList from "./components/CandidatesList";
import CenterArea from "./components/CenterArea";
import { OFFER_STATII } from "../../utils/getEventOfferStatusFromState";
import { MessageAllButton } from "./components/Buttons";
import moment from "moment";

import "./Shortlist.scss";

class Shortlist extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_event_actions: false
        };
    }

    __toggleEventActions = () => {
        this.setState({
            show_event_actions: !this.state.show_event_actions
        });
    }

    render() {
        let {
            candidates,
            speaker,
            event,
            shortlist,
            whitelabel,

            n_candidates,
            current_step_i,
            allow_event_changes,
            event_is_open_job,
            tiptexts,
            sitename,
            offer_status
        } = this.props;

        let is_bureau_mode = _.get(whitelabel, ["is_bureau_mode"], false);
        offer_status = (_.isEmpty(offer_status)) ? "no offer" : offer_status;

        return (
            <div className="MP-Shortlist">
                <div className="row ShortlistHeader">
                    <div className="col-md-8">
                        <Link to={"/event"}>
                            <div style={{marginTop: "1em", display: "inline-block"}}>
                                <i className="fa fa-chevron-left"></i> {__("back")}
                            </div>
                        </Link>
                        {event && <EventDetails event={event} allow_event_changes={allow_event_changes} />}
                    </div>
                    <div className="col-md-4 hidden-sm hidden-xs" style={{paddingTop: "35px"}}>
                        <InfoBox path={"\\mp\\shortlist\\" + offer_status + "\\top"} tiptexts={tiptexts} sitename={sitename} whitelabel={whitelabel} />
                    </div>
                </div>
                <div className="row ShortlistMainLayout">
                    <div className="col-md-3 LeftSideBar hidden-sm hidden-xs">
                        {(n_candidates > 0) && !is_bureau_mode && event &&
                            <MessageAllButton event={event} {...this.props} />
                        }
                        <CandidatesList event={event} speaker={speaker} candidates={candidates} event_is_open_job={event_is_open_job} whitelabel={whitelabel} />
                        {n_candidates > 0 && allow_event_changes && !is_bureau_mode &&
                        <div style={{padding: "10px 10px 5px 20px"}}>
                            {event && <MessageAllButton event={event} {...this.props} />}
                        </div>
                        }
                        {allow_event_changes && <PostingType event={event} whitelabel={whitelabel} />}
                        <div style={{padding: "10px"}}>
                            {event && <EventActions event={event} shortlist={shortlist} whitelabel={whitelabel} allow_event_changes={allow_event_changes}/>}
                        </div>
                    </div>

                    {this.state.show_event_actions &&
                        <div className="col-xs-12 col-sm-12 hidden-md hidden-lg">
                            <div className="well well-default">
                                <EventActions event={event} shortlist={shortlist} whitelabel={whitelabel} allow_event_changes={allow_event_changes}/>
                            </div>
                        </div>
                    }
                    <div className="col-xs-12 col-sm-12 LeftSideBar hidden-md hidden-lg">
                        <CandidatesList event={event} speaker={speaker} candidates={candidates} />
                        <div className="clearfix"></div>
                    </div>

                    <div className="CenterArea col-xs-12 col-sm-12 col-md-9">
                        {event && <CenterArea
                            event={event}
                            speaker={speaker}
                            current_step_i={current_step_i}
                            {...this.props}
                        />}
                    </div>
                </div>
            </div>
        );
    }
}


////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {
    // Page Properties
    let current_step_i = 0; // Speaker Step
    let selected_candidate = _.find(ownProps.candidates, {sid: _.toNumber(_.get(ownProps.speaker, ["sid"]))});
    if (selected_candidate) {
        if (selected_candidate.has_offer) {
            current_step_i = 1; // Offer Step
        }
        if (selected_candidate.offer_accepted) {
            current_step_i = 2; // Pay Step
        }
        if (selected_candidate.deposit_paid) {
            current_step_i = 3; // Pre Event Step
        }
        if (selected_candidate.deposit_paid && moment(_.get(ownProps.event, ["Stagetimes", 0, "starttime"]) * 1000).isBefore(moment())) {
            current_step_i = 4; // Post Event Step
        }
    }

    return {
        //allow actions if no one has accepted an offer
        allow_event_changes: (_.indexOf(OFFER_STATII, ownProps.event_status) < _.indexOf(OFFER_STATII, "offer accepted")),
        current_step_i: current_step_i,
        n_candidates: _.size(ownProps.candidates),
        sitename: _.get(ownProps.whitelabel, ["name"]),
        tiptexts: _.get(state, ["GlobalLists", "Displaylists", "tiptext"])
    };
};

var mapDispatchToProps = function (dispatch, ownProps) {
    return {

    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Shortlist));