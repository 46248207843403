import _ from "lodash";
import React from "react";

import "./HeroImage.scss";

const HERO_IMAGES = [
    "https://streamer.espeakers.com/static/marketplace/faces_sam.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_dan.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_rizzo.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_hyken.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_arnold.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_wittenstein.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_mcdonald.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_crenshaw.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_ellis.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_byers.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_bennett.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_gilliland.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_wofford.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_gardner.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_fripp.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_fox.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_allred.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_register.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_levine.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_gruver.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_phelps.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_goodman.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_hewlett.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_silber.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_frances.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_brian.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_mcvanel.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_diggs.jpg",
    "https://streamer.espeakers.com/static/marketplace/faces_dewitt.jpg",
];

class HeroImage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            timer: null,
            images: HERO_IMAGES,
            selected_index: _.get(props, ["img_index"], _.random(0, (_.size(HERO_IMAGES) - 1))),
            cycle_images: _.get(props, ["cycle_images"], true)
        };
    }

    componentDidMount() {
        if (this.state.cycle_images) {
            this.setState({
                timer: window.setInterval(this.tick, 12000)
            });
        }
    }

    componentWillUnmount() {
        if (this.state.timer) {
            window.clearInterval(this.state.timer);
        }
    }

    tick = () => {
        this.setState({
            selected_index: _.random(0, (_.size(this.state.images) - 1))
        });
    }

    render() {
        let {
            children
        } = this.props;

        let selected_image = _.get(this.state.images, this.state.selected_index);

        return (
            <div className="container-fluid hero" style={{backgroundColor: "linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)", backgroundImage: "url(" + selected_image + ")", backgroundSize: "cover", marginTop: "-20px", transition: "background 1s"}}>
                <div className="container">
                    {children}
                </div>
            </div>
        );
    }
}

export default HeroImage;