import React from "react";
import { Route } from "react-router";
import HeadlessLayout from "../HeadlessLayout";

export default class HeadlessLayoutRoute extends React.Component {

    render() {
        return (
            <HeadlessLayout {...this.props}>
                <Route {...this.props} />
            </HeadlessLayout>
        );
    }

}