import _ from "lodash";
import React from "react";
import DollarCircle from "../../../../components/DollarCircle";
import formatCurrency from "../../../../utils/formatCurrency";
import { gettext as __ } from "../../../../utils/Trans";
import moment from "moment";

class PaymentRows extends React.Component {

    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        let {
            speaker,
            deposit_amount,
            amount_due_on_event,
            event_due_date
        } = this.props;

        let speakername = _.get(speaker, ["name"]);

        return (
            <div style={{
                position: "relative",
                margin: "20px 0 0 0",
                borderLeft: "3px solid #E9E9E9"
            }}>
                <div style={{
                    borderBottom: "1px solid #EAEAEA",
                    margin: "0 0 20px 30px",
                    paddingBottom: "20px"
                }}>
                    <DollarCircle backgroundColor="#79A451"/>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="text-success">
                                <span className="pull-right">
                                    {formatCurrency(deposit_amount, 2)}
                                </span>
                                <b>{__("Deposit due now:")}</b>
                                <div className="clearfix"/>
                            </div>
                            <div className="text-muted" style={{marginTop: ".5em"}}>
                                {deposit_amount > 0 &&
                                <span>{__("%s will lock their calendar for you when this deposit is received.", [speakername])}</span>
                                }
                                {deposit_amount === 0 &&
                                <span>{__("%s will lock their calendar for we have confirmed your payment method for travel and material costs.", [speakername])}</span>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{
                    margin: "0 0 0 30px"
                }}>
                    <DollarCircle backgroundColor="#698099"/>
                    <div className="row">
                        <div className="col-xs-12">
                            <div className="row">
                                <div className="col-xs-6 text-primary">
                                    <b>{__("Due on %s:", [moment.unix(event_due_date).format("MMMM D, Y")])}</b>
                                </div>
                                <div className="col-xs-6 text-right text-primary">
                                    {formatCurrency(amount_due_on_event, 2)}
                                </div>
                            </div>
                            <div className="text-muted" style={{marginTop: ".5em"}}>
                                {__("eSpeakers will automatically charge you this amount on this date.", [speakername])}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default PaymentRows;