import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { OverlayTrigger } from "react-bootstrap";
import PopoverMenu from "../../PopoverMenu";

export default class CheckboxDropdown extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            label_value: props.placeholder,
            options: props.options,
            value: props.input.value,
            onChange: props.input.onChange
        };
    }

    componentDidMount() {
        let self = this;
        _.each(_.split(this.props.input.value, ","), function(val) {
            if (val !== "") {
                self.onCheckValue(val, true, false);
            }
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.input.value !== prevProps.input.value) {
            let self = this;
            this.onClearOptions();
            _.each(_.split(this.props.input.value, ","), function (val) {
                if (val !== "") {
                    self.onCheckValue(val, true, true);
                }
            });
        }
    }

    /**
     * Resets the selected property on all the fields.  Used by the clear all option
     */
    onClearOptions = () => {
        return this.setState({
            options: _.forEach(this.state.options, function(option) {
                _.set(option, ["selected"], false);
            })
        }, () => {
            return this.updateValue(true);
        });
    }

    /**
     * Pass onChange up to the input
     */
    onChange = (value) => {
        if (_.isFunction(this.props.input.onChange)) {
            return this.props.input.onChange(value);
        }
    }

    /**
     * Whenever a checkbox is checked, go here to check/uncheck it in the options
     */
    onCheckValue = (value, checked, triggerChange) => {
        let selected_options = this.state.options;
        let obj = _.find(selected_options, {value: value});
        let index = _.findIndex(selected_options, {value: value});
        if (!_.isUndefined(obj)) {
            _.set(obj, ["selected"], checked);
            // Replace item at index using native splice
            selected_options.splice(index, 1, obj);
            return this.setState({
                options: selected_options
            }, () => {
                return this.updateValue(triggerChange);
            });
        }
        return false;
    }

    /**
     * Whenever a checkbox is checked, go here to check/uncheck it in the options
     */
    onCheckOption = (elem) => {
        if (elem.target.checked) {
            return this.onCheckValue(elem.target.value, true, true);
        } else {
            return this.onCheckValue(elem.target.value, false, true);
        }
    }

    /**
     * Updates the internal selected state, the display label, and the saved value
     */
    updateValue = (triggerChange) => {
        let label_value = this.state.label_value;
        let selected_values = _.filter(this.state.options, {selected: true});

        if (_.size(selected_values) === 0) {
            label_value = this.props.placeholder;
        } else if (_.size(selected_values) === 1) {
            label_value = _.get(_.head(selected_values), ["label"]);
        } else if (_.size(selected_values) === 2) {
            let first = _.get(_.head(selected_values), ["label"]);
            let second = _.get(_.last(selected_values), ["label"]);
            label_value = first + ", " + second;
        } else {
            let first = _.get(_.head(selected_values), ["label"]);
            label_value = (first + ", and " + (_.size(selected_values) - 1) + " more");
        }

        this.setState({
            label_value,
            value: _.join(_.map(selected_values, "value"), ",")
        }, () => {
            if (triggerChange) {
                this.onChange(this.state.value);
            }
        });
    }

    render() {
        let {
            input,
            any_placeholder,
            label,
            className = "",
            trigger = "click",
            placement = "bottom",
            hide_errors,
            meta: {
                touched,
                error,
                warning
            }
        } = this.props;

        let onCheckOption = this.onCheckOption;
        let onClearOptions = this.onClearOptions;

        let overlay = <PopoverMenu>
            <div style={{padding: "10px"}}>
                {any_placeholder &&
                    <div className={className}>
                        <label> <input type="checkbox" value={0} checked={(_.size(_.filter(this.state.options, {selected: true})) === 0)} onChange={onClearOptions} />{" "}{any_placeholder}</label>
                        <hr style={{margin: "2px"}} />
                    </div>
                }
                {_.map(this.state.options, function(item, i) {
                    return (<div key={i} className={_.get(item, ["className"], className)}><label><input type="checkbox" value={_.get(item, ["value"])} checked={_.get(item, ["selected"], false)} onChange={onCheckOption} />{" "}{_.get(item, ["label"])}</label></div>);
                })}
            </div>
        </PopoverMenu>;

        return (
            <div>
                <div className={className + " " + (touched && error ? "has-error " : touched && warning ? "has-warning " : "")}>
                    <label>
                        {label}
                        <OverlayTrigger rootClose={true} trigger={trigger} placement={placement} overlay={overlay}>
                            <div style={{borderBottom: "2px solid #C0C0C0"}}>{this.state.label_value}{" "}<i className="fa fa-caret-down" /></div>
                        </OverlayTrigger>
                    </label>
                    {!hide_errors && touched &&
                    ((error && <div className="text-danger"><i className="fa fa-times"/> {error}</div>) ||
                        (warning && <div className="text-danger"><i className="fa fa-exclamation-triangle"/> {warning}</div>))}
                </div>
            </div>
        );
    }
}

CheckboxDropdown.propTypes = {
    input: PropTypes.object,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    hide_errors: PropTypes.bool,
    options: PropTypes.array,
    any_placeholder: PropTypes.string
};