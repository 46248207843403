import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { gettext as __ } from "../../utils/Trans";
import ShowcaseResults from "./ShowcaseResults";
import EmbeddedShortlist_add from "../../actions/ShortList/EmbeddedShortlist_add";
import EmbeddedShortlist_remove from "../../actions/ShortList/EmbeddedShortlist_remove";
import Search from "../../actions/Search/Search";

class SearchTrendingPage extends React.Component {

    componentDidMount() {
        // Scroll the page to the top
        window.scrollTo(0, 0);
        if (!this.props.results) {
            this.props.searchShowcase();
        }
    }

    render() {
        let {
            user,
            shortlist,
            awards,
            languages,
            results,
            onProfile,
            onFavorite,
            onViewPrograms,
            onViewSchedule,
            onWatchVideo
        } = this.props;

        return (
            <div className="search-page container-fluid text-center">
                <div>
                    <h1>{__("Speaker")}{" "}<b>{__("Showcase")}</b></h1>
                </div>

                {results &&
                <ShowcaseResults
                    speakers={results}
                    shortlist={_.get(shortlist, ["sids"], [])}
                    onProfile={onProfile}
                    awards={awards}
                    languages={languages}
                    is_debug={(_.get(user, ["role"], "guest") === "admin")}
                    onFavorite={onFavorite}
                    onViewSchedule={onViewSchedule}
                    onViewPrograms={onViewPrograms}
                    onWatchVideo={onWatchVideo}
                />
                }
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

const mapStateToProps = function (state) {

    let feeranges = _.get(state, ["Whitelabel", "sitelists", "feeranges"], []);
    let awards = _.get(state, ["Whitelabel", "sitelists", "awards_to_display_on_results"], []);
    let languages = _.get(state, ["Whitelabel", "sitelists", "universal", "languages"], []);
    let topics = _.get(state, ["Whitelabel", "sitelists", "topics"], []);
    let shortlist = _.get(state, ["EmbeddedShortlist"], {});

    let num_results = _.get(state, ["SearchPage", "showcase", "num_results"], 0);
    let results = _.get(state, ["SearchPage", "showcase", "results"]);

    return {
        awards: awards,
        languages: languages,
        feeranges: feeranges,
        topics: topics,
        results: results,
        num_results: num_results,
        shortlist: shortlist,
    };
};

const mapDispatchToProps = function (dispatch, ownProps) {
    return {
        searchShowcase: function() {
            return dispatch(Search.searchshowcase({
                items_per_page: 24,
                page: 0,
                bureau_id: _.get(ownProps.whitelabel, ["bid"]),
                sort: "sortscore"
            }));
        },
        onProfile: function(speaker) {
            if (_.has(speaker, ["sid"])) {
                ownProps.history.push("/profile/" + _.get(speaker, ["sid"]));
            }
        },
        onViewPrograms: function(speaker) {
            if (_.has(speaker, ["sid"])) {
                ownProps.history.push("/profile/" + _.get(speaker, ["sid"]) + "#programs");
            }
        },
        onViewSchedule: function(speaker) {
            if (_.has(speaker, ["sid"])) {
                ownProps.history.push("/profile/" + _.get(speaker, ["sid"]) + "#availability");
            }
        },
        onWatchVideo: function(speaker) {
            if (_.has(speaker, ["sid"]) && _.has(speaker, ["video_id"])) {
                ownProps.history.push("/profile/" + _.get(speaker, ["sid"]) + "?opentovideo=" + _.get(speaker, ["video_id"]));
            }
        },
        onFavorite: function(speaker, add_or_remove) {
            if (_.has(speaker, ["sid"])) {
                if (add_or_remove) {
                    return dispatch(EmbeddedShortlist_add(speaker));
                } else {
                    return dispatch(EmbeddedShortlist_remove(speaker));
                }
            }
        }
    };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SearchTrendingPage));