import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import StripeCheckout from "react-stripe-checkout";
import PlaidLink from "react-plaid-link";
import { gettext as __ } from "../../utils/Trans";
import UserStripe from "../../actions/User/UserStripe";
import PaymentMethodBox from "../../components/PaymentMethodBox";
import PaperCheckModal from "./modals/PaperCheckModal";
import { PLAID_API_KEY, PLAID_ENVIRONMENT, STRIPE_API_KEY } from "../../utils/ApiKeys";


class PaymentForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            show_paper_check_modal: false,
            stripe_error: null
        };
    }

    UNSAFE_componentWillMount() {
        if (!this.props.stripe && this.props.has_stripe) {
            return this.props.loadData()
                .catch((err) => {
                    this.setState({
                        stripe_error: err
                    });
                })
        }
    }

    onStripeToken = (token) => {
        this.props.connectBuyerWithStripe(token)
            .then(() => {
                this.setState({
                    stripe_error: null
                });
            });
    }

    onPlaidToken = (token, metadata) => {
        this.props.connectBuyerWithPlaid(token, metadata.account_id)
            .then(() => {
                this.setState({
                    stripe_error: null
                });
            });
    }

    togglePaperCheckModal =() => {
        this.setState({show_paper_check_modal: !this.state.show_paper_check_modal})
            .then(() => {
                this.setState({
                    stripe_error: null
                });
            });
    }

    render() {
        let {
            has_stripe,
            stripe,
            stripe_primary_pm,
            user,
            disconnectBuyer
        } = this.props;

        return (
            <div>
                <h3>{__("Payment Settings")}</h3>
                <hr />
                {has_stripe && !this.state.stripe_error &&
                    <div>
                        <h4>{__("Primary Payment Method")}</h4>
                        {__("Your Marketplace account is integrated for automatic payments.")}
                        <p />
                        <PaymentMethodBox stripe={stripe_primary_pm} is_loading={!stripe} />
                        <p />
                        {__("We will charge your primary payment method when you accept an agreement on an event.")}
                        <p />
                        <hr />
                    </div>
                }
                <h4>{__("Add New Payment Method")}</h4>
                <div>{__("You can add a new primary payment method that we will use for all future payments.")}</div>
                <p />
                <div className="row">
                    <div className="col-sm-4">
                        <div className="well well-default text-center">
                            <span className="fa fa-3x fa-cc-visa"/>
                            <span className="fa fa-3x fa-cc-mastercard"/>
                            <span className="fa fa-3x fa-cc-discover"/>
                            <h4>{__("Credit Card")}</h4>
                            <ul className="list-group">
                                <li className="list-group-item">{__("Speaker Keeps Less")}</li>
                                <li className="list-group-item">{__("Instant Payment")}</li>
                                <li className="list-group-item">{__("Secure Transactions with Marketplace Guarantee")}</li>
                            </ul>
                            <StripeCheckout
                                name="eSpeakers Marketplace"
                                description={__("Connect Credit Card")}
                                ComponentClass="div"
                                panelLabel={__("Connect")}
                                amount={0}
                                token={this.onStripeToken}
                                email={_.get(user, ["email"])}
                                stripeKey={STRIPE_API_KEY}
                            >
                                <div className="btn btn-info">{__("Connect Credit Card")}</div>
                            </StripeCheckout>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="well well-default text-center">
                            <i className="fa fa-3x fa-bank"></i>
                            <h4>{__("Bank Account")}</h4>
                            <ul className="list-group">
                                <li className="list-group-item">{__("Speaker Keeps More")}</li>
                                <li className="list-group-item">{__("Instant Setup")}</li>
                                <li className="list-group-item">{__("Secure Transactions with Marketplace Guarantee")}</li>
                            </ul>
                            <PlaidLink
                                publicKey={PLAID_API_KEY}
                                product={["auth"]}
                                apiVersion="v2"
                                env={PLAID_ENVIRONMENT}
                                selectAccount={true}
                                clientName="eSpeakers Marketplace"
                                onSuccess={this.onPlaidToken}
                                className="btn btn-info"
                                style={{}}
                            >
                                {__("Connect Bank Account")}
                            </PlaidLink>
                        </div>
                    </div>
                    <div className="col-sm-4">
                        <div className="well well-default text-center">
                            <i className="fa fa-3x fa-envelope"></i>
                            <h4>{__("Paper Check")}</h4>
                            <ul className="list-group">
                                <li className="list-group-item">{__("Speaker Keeps More")}</li>
                                <li className="list-group-item">{__("Takes Longer to Process")}</li>
                                <li className="list-group-item">{__("Protected by Marketplace Guarantee")}</li>
                            </ul>
                            <div className="btn btn-info" onClick={this.togglePaperCheckModal}>{__("Learn More")}</div>
                        </div>
                    </div>
                </div>
                <hr />
                {has_stripe &&
                    <div>
                        <h4>{__("Disconnect Payment Method")}</h4>
                        <div>{__("You can disconnect your payment method from your Marketplace account.  You can always change your preferred payment method using the options above.")}</div>
                        <p />
                        <div className="text-center">
                            <div className="btn btn-danger" onClick={disconnectBuyer}>{__("Disconnect Payment Method")}</div>
                        </div>
                    </div>
                }
                {this.state.show_paper_check_modal && <PaperCheckModal onClose={this.togglePaperCheckModal}/>}
            </div>
        );
    }
}

////////////////////////////////////////////////////////////////////////////////
//Connect it up

var mapStateToProps = function (state, ownProps) {
    // Page Properties
    let has_stripe = _.get(ownProps.user, ["stripe_cust_token"]);

    let stripe = _.get(state, ["Stripe", "STRIPE"]);

    let stripe_primary_pm = _.head(_.filter(_.get(stripe, ["sources", "data"]), function (o) {
        return o.id === _.get(stripe, ["default_source"]);
    }));

    return {
        has_stripe: !_.isNull(has_stripe),
        stripe: stripe,
        stripe_primary_pm: stripe_primary_pm
    };
};

var mapDispatchToProps = function (dispatch, ownProps) {
    return {
        loadData: function () {
            return dispatch(UserStripe.get())
                .catch((err) => {
                    if (_.get(err, ["name"]) === "AuthorizationException") {
                        ownProps.history.push("/error");
                    } else {
                        throw err;
                    }
                });
        },
        disconnectBuyer: function () {
            return dispatch(UserStripe.remove())
                .catch((err) => {
                    if (_.get(err, ["name"]) === "AuthorizationException") {
                        ownProps.history.push("/error");
                    }
                });
        },
        connectBuyerWithStripe: function (token) {
            return dispatch(UserStripe.connectStripe(token))
                .catch((err) => {
                    if (_.get(err, ["name"]) === "AuthorizationException") {
                        ownProps.history.push("/error");
                    }
                });
        },
        connectBuyerWithPlaid: function (token, account_id) {
            return dispatch(UserStripe.connectPlaid(token, account_id))
                .catch((err) => {
                    if (_.get(err, ["name"]) === "AuthorizationException") {
                        ownProps.history.push("/error");
                    }
                });
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PaymentForm);