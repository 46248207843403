import _ from "lodash";
import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { gettext as __ } from "../../utils/Trans";
import ThumbnailSquare from "../../components/ThumbnailSquare";
import SpeakerThumbnail from "../../components/SpeakerThumbnail";
import EmbeddedShortlist_remove from "../../actions/ShortList/EmbeddedShortlist_remove";
import VerticalScroll from "../../components/VerticalScroll";
import SpeakerName from "../../components/SpeakerName";
import { defineSwipe, Swipeable } from "react-touch";
import { AutoAffix } from "react-overlays";
import Analytics from "../../utils/Analytics";

import "./index.scss";

/*eslint-env browser*/

// const swipe_up = defineSwipe({swipeDistance: 20});
// const swipe_down = defineSwipe({swipeDistance: 75});
const swipe_left = defineSwipe({swipeDistance: 50});

class ShortlistEmbedded extends React.Component {

    constructor(props) {
        super(props);
        this.updateDimensions = _.debounce(this.updateDimensions, 100, {
            "leading": true,
            "trailing": true
        });
        this.onAffixed = _.debounce(this.onAffixed, 100, {
            "leading": true,
            "trailing": true
        });
        this.state = {
            max_boxes: 4,
            is_expanded: false,
            remove_sid: -1,
            look_at_me: false,
            look_at_me_timer: null
        };
    }

    sendSendInquiryClickEvent = () => {
        Analytics.sendEvent("Private Job", "start", 0, "shortlist");
    };

    expandShortlist = () => {
        this.setState({is_expanded: true});
    }

    collapseShortlist = () => {
        this.setState({is_expanded: false});
    }

    confirmRemoveSid = (sid) => {
        this.setState({remove_sid: sid});
    }

    lookAtMe = () => {
        this.setState({look_at_me: !this.state.look_at_me});
    }

    onAffixed = () => {
        this.updateDimensions();
    }

    updateDimensions = () => {
        // Calculate the # of boxes based on whether we are desktop, tablet or phone size
        let e = document.getElementById("embedded_short_list_wrapper");
        if (e) {
            let max_boxes = _.clamp(Math.floor((e.offsetWidth - 150 - 16) / 60), 2, 100); // 150 = width of shortlist, 16 = width of padding + border of container
            this.setState({max_boxes: max_boxes});
        }
    }

    componentDidMount() {
        this.setState({
            look_at_me_timer: setInterval(3000, this.lookAtMe())
        });
        window.addEventListener("resize", this.updateDimensions);
        this.updateDimensions();
    }

    UNSAFE_componentWillReceiveProps(next_props) {
        if (next_props.sids && _.size(next_props.sids) !== _.size(this.props.sids)) {
            this.updateDimensions();
        }
    }

    componentWillUnmount() {
        if (this.state.look_at_me_timer) {
            clearInterval(this.state.look_at_me_timer);
        }
        window.removeEventListener("resize", this.updateDimensions);
    }

    render() {
        let {
            sids,
            removeSid,
            url      = "/postaprivatejob",
            url_text = __("SEND INQUIRY")
        } = this.props;

        let {
            look_at_me,
            is_expanded,
            max_boxes = 4
        } = this.state;

        if (_.isEmpty(sids)) {
            return null;
        }

        let containerClasses = ["MP-EmbeddedShortlist"];

        if (look_at_me) {
            containerClasses.push("pulse");
        }

        if (is_expanded) {
            containerClasses.push("expanded");
        } else {
            containerClasses.push("collapsed");
        }

        let sids_shown = sids;
        let n_more = null;
        if (_.size(sids_shown) > max_boxes) {
            sids_shown = _.take(sids, max_boxes - 1);
            n_more = <div className="MP-EmbeddedShortlist-item" onClick={this.toggleShortlist}>
                <ThumbnailSquare>
                    {__("+ %s more", [_.size(sids) - _.size(sids_shown)])}
                </ThumbnailSquare>
            </div>;
        }

        let list = _.map(sids_shown, function (sid) {
            let rm_btn;
            if (removeSid) {
                rm_btn = <span
                    className="link MP-EmbeddedShortlist-btn-rm text-danger"
                    onClick={() => removeSid(sid)}
                >
                    <span className="fa-stack">
                        <i className="fa fa-circle fa-stack-2x text-danger"></i>
                        <i className="fa fa-times fa-stack-1x"></i>
                    </span>
                </span>;
            }
            return <div key={sid} className="MP-EmbeddedShortlist-item">
                <SpeakerThumbnail sid={sid}/>
                {rm_btn}
            </div>;
        });

        var removable_sid = this.state.remove_sid;
        var confirmRemoveSid = this.confirmRemoveSid;
        let full_list = _.map(sids, function (sid) {
            return (
                <div key={sid}>
                    <Swipeable config={swipe_left} onSwipeRight={() => confirmRemoveSid(-1)} onSwipeLeft={() => confirmRemoveSid(sid)}>
                        <div className={"MP-EmbeddedShortlist-item row"}>
                            <div className="col-xs-3"><SpeakerThumbnail dont_show_name_on_hover={true} dont_link_to_profile={true} sid={sid}/></div>
                            <div className="col-xs-9" style={{lineHeight: "50px", fontWeight: "bold"}}>
                                <SpeakerName sid={sid}/>
                                <div className={(removable_sid === sid ? "pending-remove" : "hidden")} style={{display: "inline", float: "right"}}>
                                    <div className={"btn btn-sm btn-danger"} onClick={() => removeSid(sid)}>remove</div>
                                </div>
                            </div>
                        </div>
                    </Swipeable>
                </div>
            );
        });

        let shortlist_inquiry = <div style={{width: 140, marginLeft: 10}}>
            <div className="text-center">
                <h4 style={{marginTop: 4}}>
                    {__("Shortlist")}
                </h4>
                <Link to={url} className="btn btn-success btn-xs btn-block " onClick={this.sendSendInquiryClickEvent.bind(this)}>
                    {url_text}
                </Link>
            </div>
        </div>;

        if (is_expanded) {
            shortlist_inquiry = <div style={{width: "100%"}}>
                <hr/>
                <div className="text-center">
                    <Link to={url} className="btn btn-success btn-block " onClick={this.sendSendInquiryClickEvent.bind(this)}>
                        {(list.length > 1) ? __("SEND INQUIRY") : __("SEND INQUIRY")}
                    </Link>
                </div>
            </div>;
        }


        return (
            <AutoAffix autoWidth={false} offsetTop={10} topClassName="affixed" onAffixedTop={this.onAffixed}>
                <div id="embedded_short_list_wrapper" className="embedded_short_list_wrapper" style={{zIndex: 1}}>
                    <div className="hidden-xs hidden-sm">
                        <div id="embedded_short_list_div" className={containerClasses.join(" ")}>
                            <div style={{display:"table", width: "100%"}}>
                                <div style={{display:"table-cell", overflow: "hidden", width: ((_.min([_.size(sids), 6]) * 61 + 61) + "px")}}>
                                    <div className="MP-EmbeddedShortlist-left" style={{height: "55px", maxHeight:"55px"}}>
                                        {list}
                                        {n_more}
                                    </div>
                                </div>
                                <div style={{display:"table-cell", textAlign:"right", verticalAlign: "top"}}>
                                    {shortlist_inquiry}
                                </div>
                            </div>
                            <div className="clearfix"/>
                        </div>
                    </div>
                    <div className="visible-xs visible-sm">
                        {is_expanded
                            ?
                            <div id="embedded_short_list_div" className={containerClasses.join(" ")}>
                                <div>
                                    <VerticalScroll>
                                        <div className="MP-EmbeddedShortlist-top">
                                            {full_list}
                                        </div>
                                    </VerticalScroll>
                                    {shortlist_inquiry}
                                </div>
                                <div className="clearfix"/>
                                <div>
                                    <div className="text-center" style={{marginTop:"1em"}} onClick={this.collapseShortlist}><i className="fa fa-caret-up" /></div>
                                </div>
                            </div>
                            :
                            <div id="embedded_short_list_div" className={containerClasses.join(" ")}>
                                <div style={{display:"table", width: "100%"}}>
                                    <div style={{display:"table-cell", overflow: "hidden"}}>
                                        <div className="MP-EmbeddedShortlist-left" style={{height: "55px", maxHeight:"55px"}}>
                                            {list}
                                            {n_more}
                                        </div>
                                    </div>
                                    <div style={{display:"table-cell", textAlign:"right", verticalAlign: "top"}}>
                                        {shortlist_inquiry}
                                    </div>
                                </div>
                                <div className="clearfix"/>
                                <div>
                                    <div className="text-center" onClick={this.expandShortlist}><i className="fa fa-caret-down" /></div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </AutoAffix>
        );
    }
}

ShortlistEmbedded.propTypes = {
    url: PropTypes.string,
    url_text: PropTypes.string
};

////////////////////////////////////////////////////////////////////////////////
//Connect it up

let mapStateToProps = function (state) {
    return {
        post_to_existing_job: _.get(state, ["PostAJobPage", "pending", "eid"], false),
        sids: _.get(state, ["EmbeddedShortlist", "sids"], [])
    };
};

let mapDispatchToProps = function (dispatch) {
    return {
        removeSid: function (sid) {
            Analytics.sendEvent("Shortlist", "remove speaker", sid, "shortlist");
            dispatch(EmbeddedShortlist_remove(sid));
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShortlistEmbedded);
