import _ from "lodash";
import Balboa from "../Global/Balboa";

export default function(clear_cache = false){
    return (dispatch, getState) => {
        var key = ["getStripeCustomer"];
        var cache_ttl = clear_cache ? -1 : (30 * 60 * 1000);
        return Balboa(dispatch, getState)
            .post(key, "/stripe/getstripecustomer/", {}, {cache_ttl: cache_ttl})
            .then(function(response) {
                //reload the user details
                dispatch({
                    type: "USER_STRIPE_LOAD",
                    data: _.get(response, ["data", "stripe_customer"])
                });
                return response;
            })
            .then(function(response) {
                // clear the cached data
                dispatch({
                    type: "BALBOA_CLEAR_KEY",
                    key: key
                });
                return response;
            });
    };
};
