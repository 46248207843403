/**
 * Created by hobby on 4/14/17.
 */
let STRIPE_API_KEY = (process.env.NODE_ENV === "production") ? "pk_live_ImFPj9Jkz1KKtnxf6mAGYX56" : "pk_test_JUSgCf58mQej4lPwdVEH3dNi";
let PLAID_API_KEY = (process.env.NODE_ENV === "production") ? "46af08e66b28f6bf13944d9b170f57" : "46af08e66b28f6bf13944d9b170f57";
// const PLAID_ENVIRONMENT = (process.env.NODE_ENV === "production") ? "production" : "sandbox";
let PLAID_ENVIRONMENT = (process.env.NODE_ENV === "production") ? "development" : "sandbox"; // in development/free mode, only can use development

// uncomment this to enable development keys
// if (process.env.NODE_ENV !== "production") {
//     let STRIPE_API_KEY = "pk_test_JUSgCf58mQej4lPwdVEH3dNi";
//     let PLAID_API_KEY = "46af08e66b28f6bf13944d9b170f57";
//     let PLAID_ENVIRONMENT = "tartan";
// }


export {
    STRIPE_API_KEY,
    PLAID_API_KEY,
    PLAID_ENVIRONMENT
};